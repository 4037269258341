import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import rootReducer from '../reducers/rootReducer';
import rootSaga from '../sagas/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';

import * as Sentry from "@sentry/react";

const composeEnhancers = composeWithDevTools({
    // Specify here name, actionsBlacklist, actionsCreators and other options
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store)
export default { store, persistor };
