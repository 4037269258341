import { IForceFinish } from '../../interfaces/ForceFinishRequest'
import * as actions from '../actionTypes/finishRequestTypes'

export function openForceFinishView(forceFinish:any):actions.OpenForceFinishAction{
    return {
        type:actions.FORCEFINISH_OPEN_VIEW,
        forceFinish,
        
    }
}

export function closeForceFinishView():actions.CloseForceFinishAction{
    return{
        type:actions.FORCEFINISH_CLOSE_VIEW
    }
}