import React, {useState} from 'react'
import {IHostPropertyInput} from '../../../../../interfaces/Property'
import Automation from './Automation'
import Payment from './Payment'
const debounce = require('debounce')

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
  refetch: () => void
}

type TabNames = 'Automation' | 'Payment'
const tabList: TabNames[] = ['Automation', 'Payment']

const Settings: React.FC<Props> = ({property, propertyId, refetch}) => {
  const [activeTab, setActiveTab] = useState<TabNames>('Automation')

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            {tabList.map((tabItem, index) => {
              return (
                <li
                  key={'tabitem-' + index}
                  className='nav-item mt-2'
                  onClick={() => {
                    setActiveTab(tabItem)
                  }}
                >
                  <div
                    className={
                      tabItem == activeTab
                        ? 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer active'
                        : 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer'
                    }
                  >
                    {tabItem}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          {activeTab == 'Automation' && <Automation property={property} propertyId={propertyId} />}
          {activeTab == 'Payment' && (
            <Payment propertyId={propertyId} property={property} refetch={refetch} />
          )}
        </div>
      </div>
    </>
  )
}

export default Settings
