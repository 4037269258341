/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../app/redux/reducers/rootReducer'
import {useHistory} from 'react-router-dom'

export function AsideMenuMain() {
  const {
    onBoardingInfo,
    propertyComplete,
    checklistComplete,
    staffComplete,
    staffServiceComplete,
    staffVisible,
    onBoardingComplete,
  } = useSelector((state: RedusxAppState) => state.onBoarding)
  const {user} = useSelector((state: RedusxAppState) => state.user)

  return (
    <>
      {/* <AsideMenuItem
        to='/live'
        title='Live'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotone/Design/Brush.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItem
        to='/dashboard'
        title='Dashboard'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotone/Home/Home.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/jobs'
        title='Jobs'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotone/Code/Compiling.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/marketplace'
        title='Marketplace'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/General/Search.svg'
        hasBullet={onBoardingInfo.hasMarketplaceRequest}
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/turnovers'
        title='Turnovers'
        fontIcon='bi-layers'
        icon="/media/icons/duotone/Design/Layers.svg"
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItem
        to='/properties'
        title='Properties'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Home/Home.svg'
        hasBullet={!propertyComplete}
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/turnify-messenger'
        title='Turnify Messenger'
        fontIcon='bi-chat'
        icon='/media/icons/duotone/Communication/Chat.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItem
        to='/master-checklists'
        title='Checklists'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Files/File-done.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/staff-connect-hub'
        title='StaffConnect Hub'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/General/User.svg'
        hasBullet={
          staffVisible &&
          onBoardingInfo.hasMyTeamProperty &&
          (!staffComplete || !staffServiceComplete)
        }
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/pro-team'
        title='Pro Team'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Interface/Briefcase.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItem
        to='/force-finish'
        title='Finish Requests'
        fontIcon='bi-list-nested'
        icon='/media/icons/duotone/Interface/Insert.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* {user?.userSection?.roleId == 1 && (
        <>
          <AsideMenuItem
            to='/managers'
            title='Managers'
            fontIcon='bi-layers'
            icon='/media/icons/duotone/Communication/Contact1.svg'
          />
          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator'></div>
            </div>
          </div>
        </>
      )} */}
      <>
        <AsideMenuItem
          to='/payment-center'
          title='Payment Center'
          fontIcon='bi-layers'
          icon='/media/icons/duotone/Shopping/Credit-card.svg'
          hasBullet={onBoardingInfo.isStripeValid !== undefined && !onBoardingInfo.isStripeValid}
        />
        <div className='menu-item'>
          <div className='menu-content'>
            <div className='separator'></div>
          </div>
        </div>
      </>

      <AsideMenuItem
        to='/logout'
        title='Logout'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Navigation/Sign-out.svg'
      />
    </>
  )
}
