import {useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {PageTitle} from '../../../_metronic/layout/core'
import JobLateFinish from '../jobs/JobLateFinish'
import JobLateStart from '../jobs/JobLateStart'
import Live from './Live'

type TabNames = 'Live' | 'LateStart' | 'LateFinish'

const LiveTabs = () => {
  const [tab, setTabs] = useState<TabNames>('Live')

  const handleChangeTab = (key: any) => {
    setTabs(key)
  }

  return (
    <div className='row g-5 g-xl-8'>
      <PageTitle>Live</PageTitle>
      <Card className='py-5'>
        <Card.Body>
          <Live />
          {/*<Tabs defaultActiveKey={tab} onSelect={handleChangeTab}>
                              <Tab eventKey={"Live"} title={"Live"}>{tab === "Live" ? <Live /> : null}</Tab>
                              <Tab eventKey={"LateStart"} title={"Late Start"}>{tab === "LateStart" ? <JobLateStart /> : null}</Tab>
                              <Tab eventKey={"LateFinish"} title={"Late Finish"}>{tab === "LateFinish" ? <JobLateFinish /> : null}</Tab>
                        </Tabs>*/}
        </Card.Body>
      </Card>
    </div>
  )
}

export default LiveTabs
