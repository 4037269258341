import React, {useEffect, useState} from 'react'

type Props = {
  handleChange: (val: boolean) => void
  accepted: boolean
}

const Agreement: React.FC<Props> = ({accepted, handleChange}) => {
  return (
    <div>
      <div
        style={{
          boxShadow: '0 0 7px 3px rgba(0,0,0,0.1) inset',
          padding: '20px 10px',
          maxHeight: 400,
          overflowY: 'scroll',
        }}
      >
        <div>
          <p>
            Effective Date: October 1, 2023
            <br />
            <br />
            <strong>1. Introduction</strong>
            <br />
            <br />
            Welcome to Turnify+. Turnify+ is a unique service provided by Turnify Inc. This User
            Agreement (the &ldquo;Agreement&rdquo;) outlines the terms and conditions governing your
            use of the Turnify+ service (&quot;Turnify+&quot;). By engaging with Turnify+, you
            acknowledge your understanding and acceptance of this Agreement. Please read it
            thoroughly.
            <br />
            <br />
            <strong>2. Your Responsibilities</strong>
            <br />
            <br />
            <strong>a.</strong> Platform Access and Data Security: You are responsible for
            maintaining the security of your account and ensuring data integrity on the Turnify
            platform.
            <br />
            <br />
            <strong>b.</strong> Calendar Management: It is your responsibility to oversee and manage
            your job schedules and calendar entries on the Turnify platform.
            <br />
            <br />
            <strong>c.</strong> Service Provider Replacement: In the event that a service provider
            is unable to fulfill their duties, you should arrange a suitable replacement for the
            specific job. If a backup provider is assigned for the service, Turnify Management will
            handle the replacement.
            <br />
            <br />
            <strong>
              3. Turnify+ Expert Property Management Assistant&apos;s Responsibilities
            </strong>
            <br />
            <br />
            <strong>a.</strong> Job Scheduling in Alignment with Calendar Information: Your Turnify+
            Expert Property Management Assistant ensures that jobs are scheduled with the relevant
            providers for each turnover, relying on the information available in your calendar. No
            job will be scheduled unless it is seen on your calendar.
            <br />
            <br />
            <strong>b.</strong> Communication: The Turnify+ Expert Property Management Assistant
            maintains ongoing communication with service providers (or staff members) to monitor
            progress and ensure timely completion.
            <br />
            <br />
            <strong>c.</strong> Service Quality Verification: Turnify+ conducts virtual inspections
            for service quality verification (when indicated in the checklist) to ensure adherence
            to the required standards.
            <br />
            <br />
            <strong>d.</strong> Managing Authorized Staff: The Turnify+ Expert Property Management
            Assistant oversees your authorized staff, ensuring they are well-informed about their
            jobs.
            <br />
            <br />
            <strong>e.</strong> Notifications and Confirmations: They confirm staff arrivals and
            follow up when there are delays.
            <br />
            <br />
            <strong>f.</strong> Assistance to Staff: If your staff requires assistance during their
            tasks, your Turnify+ Expert Property Management Assistant will provide the necessary
            support.
            <br />
            <br />
            <strong>g.</strong> Incident Handling: They address and inform you about any unexpected
            incidents that may occur during property management.
            <br />
            <br />
            <strong>h.</strong> Timely Job Completion: The Turnify+ Expert Property Management
            Assistant ensures that all jobs are completed on schedule and within specified
            standards.
            <br />
            <br />
            <strong>i.</strong> Virtual Checklist Inspections: They conduct virtual inspections of
            checklist photos.
            <br />
            <br />
            <strong>j.</strong> Regular Property Reports: They send you regular reports about your
            property&apos;s status and any notable updates.
            <br />
            <br />
            <strong>
              4. Responsibilities Not Handled by the Turnify+ Expert Property Management Assistant
            </strong>
            <br />
            <br />
            Your Turnify+ Expert Property Management Assistant will not handle the following
            responsibilities:
            <br />
            <br />* Checking and verifying calendars.
            <br />* Adding, canceling, or altering jobs that are not reflected in the calendar
            information.
            <br />* Adding, removing, or changing the prioritization of service providers or staff
            on your behalf.
            <br />* Adding or removing services without your explicit consent.
            <br />* Involvement in any payment-related tasks over Turnify, including viewing or
            using your payment methods.
            <br />
            <br />
            <strong>5. Changes to the Agreement</strong>
            <br />
            <br />
            Turnify reserves the right to modify this Agreement at any time. Any changes will be
            communicated to you, and your continued use of Turnify+ after such modifications
            constitutes your acceptance of the changes.
            <br />
            <br />
            <strong>6. Conclusion</strong>
            <br />
            <br />
            By using Turnify+, you acknowledge that you have read, understood, and agreed to be
            bound by this Agreement. If you do not agree to these terms, please refrain from using
            Turnify+.
            <br />
            <br />
            We look forward to providing you with top-notch property management through Turnify+.
            <br />
            <br />
            Sincerely,
            <br />
            <br />
            <strong>Turnify Inc.</strong>
          </p>
        </div>
      </div>
      <div className='mt-5'>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            value='1'
            id='agreementBox'
            onChange={(e) => {
              handleChange(e.target.checked)
            }}
          />
          <label className='form-check-label fw-bold fs-5' htmlFor='agreementBox'>
            I have read and agree to the user agreement.
          </label>
        </div>
      </div>
    </div>
  )
}

export default Agreement
