import React from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useHistory } from 'react-router-dom';
import { PropertyFormProvider } from '../../contexts/PropertyFormContext';
import PropertyAddWizard from '../../umut-components/PropertyAddWizards/PropertyAddWizard';

type Props = {
    modalOpen: boolean,
    propertyId: number
}

const PropertyEditDialog: React.FC<Props> = ({ propertyId, modalOpen }) => {
    const history = useHistory();

    const closeModal = () => {
        history.push({
            pathname: '/properties'
        })
    }

    return (
        <Modal show={modalOpen} onHide={closeModal} fullscreen centered>
            <ModalHeader closeButton>
                <Modal.Title>Property Info</Modal.Title>
            </ModalHeader>
            <Modal.Body className='p-0'>
                {modalOpen ?
                    <PropertyFormProvider>
                        <PropertyAddWizard type="pms" propertyId={propertyId} editModalClose={closeModal} />
                    </PropertyFormProvider> : null}
            </Modal.Body>
        </Modal>
    )
}

export default PropertyEditDialog;