import { Dispatch, FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators';
import { Modal } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { useDispatch, useSelector } from 'react-redux';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import StaffOfferedForm from '../../../../umut-components/Forms/StaffOfferedForm';
import StaffPropertyForm from '../../../../umut-components/Forms/StaffPropertyForm';

type Props = {
}
const StaffPropertyDialog: FC<Props> = () => {
    const { staffPropertyDialog, staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const closeModal = () => {
        staffPropertyDialog.refetch();
        dispatch(staffActionCreator.closeStaffPropertyDialog());
    }

    return (
        <Modal show={staffPropertyDialog.modalOpen} onHide={closeModal} size='xl' centered>
            <ModalHeader closeButton>
                <Modal.Title>{staffPropertyDialog.formType === "allowed" ? "Assign properties" : "Add restrictions "}  for {staffDetail.staff?.firstName + ' ' + staffDetail.staff?.lastName}</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {staffPropertyDialog.modalOpen ? <StaffPropertyForm /> : null}
            </Modal.Body>
        </Modal>
    )
}

export default StaffPropertyDialog
