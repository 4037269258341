export enum FeedCategories {
  jobSchedulingIssue = 'Job Scheduling Issue',
  delayedJobFinish = 'Delayed Job Finish',
  jobCancelled = 'Job Cancelled',
  jobScheduled = 'Job Scheduled',
  delayedJobStart = 'Delayed Job Start',
  jobFinished = 'Job Finished',
  jobStarted = 'Job Started',
  jobTimeChanged = 'Job Time Changed',
  reportProblem = 'Report Problem',
}
