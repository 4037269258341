import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../redux/actionTypes/masterChecklistTypes'
import * as checklistActionCreators from '../../../redux/actionCreators/masterChecklistActionCreators'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ChecklistSectionEdit from '../../../umut-components/Forms/MasterChecklistForms/ChecklistSectionEdit'
import {decryptText} from '../../../utils/util'
import {ISection} from '../../../interfaces/Checklist'
import NewChecklistSectionEdit from '../../../umut-components/Forms/MasterChecklistForms/NewChecklistSectionEdit'

type Props = {
  section: ISection | undefined
  checklistId: number
  visible: boolean
  handleClose: () => void
}

const NewSectionEditDialog: React.FC<Props> = ({section, checklistId, visible, handleClose}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Checklist Room</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <NewChecklistSectionEdit checklistId={checklistId.toString()} section={section} />
      </Modal.Body>
    </Modal>
  )
}

export default NewSectionEditDialog
