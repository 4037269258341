import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import ReviewForm from '../../../../../umut-components/Forms/ReviewForm';

type Props = {
}

const ReviewDialog: React.FC<Props> = () => {
    const { reviewDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();

    const handleClose = () => {
        dispatch(jobActionCreator.closeReviewDialog());
    }

    return (
        <Modal show={reviewDialog.modalOpen} onHide={handleClose} size="lg" centered>
            <ModalHeader closeButton>
                <Modal.Title>Review Add</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {reviewDialog.modalOpen && <ReviewForm />}
            </Modal.Body>
        </Modal>
    )
}

export default ReviewDialog;