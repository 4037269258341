import React, {useEffect, useState} from 'react'
import {IHostPropertyInput} from '../../../../../../interfaces/Property'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import ApiCalls from '../../../../../../network/ApiCalls'
import {AxiosResponse} from 'axios'
import {IBillingInformation} from '../../../../../../interfaces/Payment'
import {useQuery} from 'react-query'
import {useDialog} from '../../../../../../contexts/DialogContext'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../../../../../umut-components/Loading/SkeletonTableLoading'
import StripeContainer from './Stripe/StripeContainer'

type Props = {
  selectedCard: string
  handleChange: (e: any) => void
}

const Payment: React.FC<Props> = ({selectedCard, handleChange}) => {
  const [addCardSection, setAddCardSection] = useState(false)
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(
    ['Get Customer Sources', user.userSection.hostId],
    () => ApiCalls.getCustomerSources(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {
    data: billingInformation,
    isLoading,
    error,
    refetch: refetchBillingInformation,
  } = useQuery<AxiosResponse<IBillingInformation>>(
    ['Get Billing Information', user.userSection.hostId],
    () => ApiCalls.getBillingInformation(),
    {cacheTime: 100000, retry: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!billingInformation?.data.stripeAccountId || error) {
      try {
        ApiCalls.postBillingInformation()
          .then((response) => {
            if (!response.status) {
              throw response
            }
          })
          .catch((error) => console.log(error))
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  const toggleAddCard = () => {
    setAddCardSection(!addCardSection)
  }

  const updateCardInfo = async (cardInfo: any) => {
    try {
      handleChange(cardInfo.id)
    } catch (error: any) {
      showFailureDialog('An error occur during update property payment info')
    }
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }

  return (
    <div className='card'>
      <div className='card-header card-header-stretch pb-0'>
        <div className='card-title'>
          <h3 className='m-0'>Payment Methods</h3>
        </div>
        <div className='card-toolbar' style={{maxWidth: '10%', minWidth: 140}}>
          <img
            src={toAbsoluteUrl('/media/svg/powered-by-stripe.png')}
            alt='stripe'
            className='w-100 object-contain'
          />
        </div>
      </div>
      <div className='card-body'>
        <div className='row gx-9 gy-6'>
          {cardList?.data && cardList?.data.length > 0 && (
            <>
              {cardList?.data.map((cl: any, i: number) => {
                return (
                  <div className='col-xl-12' key={'card-' + i}>
                    <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                      <div className='d-flex flex-column py-2'>
                        <div className='d-flex align-items-center'>
                          <CreditLogos brand={cl?.brand} />
                          <div>
                            <div className='fs-4 fw-bold'>
                              {cl?.brand} **** {cl?.last4}
                            </div>
                            <div className='fs-7 fw-semibold text-gray-500'>
                              Card expires at {cl?.exp_month + '/' + cl?.exp_year}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center py-2'>
                        {selectedCard != cl.id && (
                          <button
                            className='btn btn-sm btn-light btn-active-light-primary me-3'
                            onClick={() => {
                              updateCardInfo(cl)
                            }}
                          >
                            Use This
                          </button>
                        )}
                        {selectedCard == cl.id && (
                          <button className='btn btn-sm btn-light-success'>Selected</button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
          <div className='col-xl-12'>
            <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                <div className='mb-3 mb-md-0 fw-semibold'>
                  <h4 className='text-gray-900 fw-bold'>Add a Card</h4>
                  <div className='fs-6 text-gray-700 pe-7'>
                    Save time and track your expenses with adding your payment card.
                  </div>
                </div>
                <button
                  className='btn btn-primary px-6 align-self-center text-nowrap'
                  onClick={() => {
                    toggleAddCard()
                  }}
                >
                  + Add New Card
                </button>
              </div>
            </div>
          </div>
        </div>
        {addCardSection && (
          <div className='row gx-9 gy-6 mt-5'>
            <div className='col-md-12 col-12 ms-auto me-auto mb-4 mt-8'>
              <StripeContainer
                handleSuccess={() => {
                  setAddCardSection(false)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Payment

type CreditProps = {
  brand: string | undefined
}
const CreditLogos: React.FC<CreditProps> = ({brand}) => {
  switch (brand) {
    case 'MasterCard':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/mc.png')}
          alt='mc'
          className='me-4'
        />
      )
    case 'Visa':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/visa_electron.png')}
          alt='visa'
          className='me-4'
        />
      )
    case 'AmericanExpress':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express.png')}
          alt='ae'
          className='me-4'
        />
      )
    default:
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express_green.png')}
          alt='defaultcard'
          className='me-4'
        />
      )
  }
}
