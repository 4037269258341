import { IJob, IJobChecklistPhoto, IJobDetail, IJobIssue, IJobTask, JobTabNames } from "../../interfaces/Job";
import { IStaffOfferedService } from "../../interfaces/Staff";
import { ManualJobRequest } from "../../network/PostRequestModels/JobRequests";
import * as actions from "../actionTypes/jobTypes";

export function openJobDialog(propertyId?:number): actions.OpenJobDialog {
    return {
        type: actions.OPEN_JOB_DIALOG,
        propertyId
    }
}

export function closeJobDialog(): actions.CloseJobDialog {
    return {
        type: actions.CLOSE_JOB_DIALOG,
    }
}

export function setJobForm(jobForm: IStaffOfferedService | null): actions.SetJobForm {
    return {
        type: actions.SET_JOB_FORM,
        jobForm: jobForm
    }
}

export function openServiceAddDialog(refetch: () => void, services: []): actions.OpenServiceAddDialog {
    return {
        type: actions.OPEN_SERVICE_ADD_DIALOG,
        services,
        refetch
    }
}

export function closeServiceAddDialog(): actions.CloseServiceAddDialog {
    return {
        type: actions.CLOSE_SERVICE_ADD_DIALOG,
    }
}

export function openTaskDialog(refetch: () => void, taskData?: IJobTask): actions.OpenTaskDialog {
    return {
        type: actions.OPEN_TASK_DIALOG,
        refetch,
        taskData
    }
}

export function closeTaskDialog(): actions.CloseTaskDialog {
    return {
        type: actions.CLOSE_TASK_DIALOG,
    }
}

export function openIssueDialog(issue: IJobIssue): actions.OpenIssueMediaDialog {
    return {
        type: actions.OPEN_ISSUE_MEDIA_DIALOG,
        issue
    }
}

export function closeIssueDialog(): actions.CloseIssueMediaDialog {
    return {
        type: actions.CLOSE_ISSUE_MEDIA_DIALOG,
    }
}

export function openChecklistPhotoDialog(checklistPhoto: IJobChecklistPhoto): actions.OpenChecklistPhotoDialog {
    return {
        type: actions.OPEN_CHECKLIST_PHOTO_DIALOG,
        checklistPhoto
    }
}

export function closeChecklistPhotoDialog(): actions.CloseChecklistPhotoDialog {
    return {
        type: actions.CLOSE_CHECKLIST_PHOTO_DIALOG,
    }
}

export function openReviewDialog(refetch: () => void, serviceTypeId: number): actions.OpenReviewDialog {
    return {
        type: actions.OPEN_REVIEW_DIALOG,
        refetch,
        serviceTypeId
    }
}

export function closeReviewDialog(): actions.CloseReviewDialog {
    return {
        type: actions.CLOSE_REVIEW_DIALOG,
    }
}

export function openCancelJobDialog(refetch: () => void, job: any): actions.OpenCancelJobDialog {
    return {
        type: actions.OPEN_CANCEL_JOB_DIALOG,
        refetch,
        job
    }
}

export function closeCancelJobDialog(): actions.CloseCancelJobDialog {
    return {
        type: actions.CLOSE_CANCEL_JOB_DIALOG,
    }
}

export function setJobDetail(jobDetail: IJobDetail | null): actions.SetJobDetail {
    return {
        type: actions.SET_JOB_DETAIL,
        jobDetail
    }
}

export function setSelectedJobTab(selectedJobTab: JobTabNames): actions.SetSelectedJobTab {
    return {
        type: actions.SET_SELECTED_JOB_TAB,
        selectedJobTab
    }
}