import {exportDataGrid} from 'devextreme/excel_exporter'
import {Workbook} from 'exceljs'
import {saveAs} from 'file-saver'
import config from '../config/config.json'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import {UserAddressCreateDto} from '../network/PostRequestModels/StaffRequest'
import {EditorPreparingEvent} from 'devextreme/ui/data_grid'
var CryptoJS = require('crypto-js')

dayjs.extend(utc)
dayjs.extend(timezone)

export const onExporting = (e: any, documentName: string) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Main sheet')

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    autoFilterEnabled: true,
    customizeCell: function (options: any) {
      const excelCell = options
      excelCell.font = {name: 'Arial', size: 12}
      excelCell.alignment = {horizontal: 'left'}
    },
  }).then(function () {
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${documentName}.xlsx`)
    })
  })
  e.cancel = true
}

export const toAmazonUrl = (photoPath: string) => {
  return photoPath
}

export const formatDatetime = (dateString: any) => {
  const date = new Date(dateString)
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return date.toISOString().slice(0, 16)
}

export const getTimeFromNow = (datetime: any) => {
  dayjs.extend(relativeTime)

  return dayjs(datetime).fromNow()
}

export const convertUTCDateToLocalDate = (dateString?: string, timezone?: string) => {
  /*
    let date = new Date(dateString);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    let _date;
    if (timezone) {
        _date = dayjs(newDate).hour(hours).tz(timezone).format(config.date_time_format);
    }
    else {
        _date = dayjs(newDate).hour(hours).format(config.date_time_format);
    }
    return _date;*/
  if (!dateString) return '--'

  if (timezone) {
    /*const utcOffset = dayjs.tz(dateString, timezone).utcOffset();
        return dayjs.utc(dateString).utcOffset(utcOffset).format(config.date_time_format);*/
    return dayjs.utc(dateString).format(config.date_time_format)
  }

  return dayjs.utc(dateString).format(config.date_time_format)
}

export const toConvertedTimeString = (dateString?: string, timezone?: string) => {
  if (!dateString) return '--'

  /*const utcOffset = dayjs.tz(dateString, timezone).utcOffset();
    return dayjs.utc(dateString).utcOffset(utcOffset).format(config.date_time_format);*/
  return dayjs.utc(dateString).format(config.date_time_format)
}

export const toConvertedDate = (dateString?: string, timezone?: string) => {
  if (!dateString) return '--'

  /*const utcOffset = dayjs.tz(dateString, timezone).utcOffset();
    return new Date(dayjs.utc(dateString).utcOffset(utcOffset).format(config.date_time_format));*/
  return new Date(dayjs.utc(dateString).format(config.date_time_format))
}

export const toConvertedTime = (dateString?: string, timezone?: string) => {
  if (!dateString) return '--'

  /*const utcOffset = dayjs.tz(dateString, timezone).utcOffset();
    return new Date(dayjs.utc(dateString).utcOffset(utcOffset).format(config.date_time_format));*/
  return new Date(dayjs.utc(dateString).format(config.date_time_format))
}

export const toConvertedOnlyDate = (dateString?: string, timezone?: string) => {
  if (!dateString) return '--'

  /*const utcOffset = dayjs.tz(dateString, timezone).utcOffset();
    return new Date(dayjs.utc(dateString).utcOffset(utcOffset).format(config.date_format));*/
  return new Date(dayjs.utc(dateString).format(config.date_format))
}

export const toRequestTimeFormat = (timezone?: string, data?: string) => {
  /*const utcOffset = dayjs.tz(data, timezone).utcOffset();
    return dayjs.utc(data).utcOffset(utcOffset).format("YYYY-MM-DDTHH:mm:ss.sss");*/
  return dayjs.utc(data).format('YYYY-MM-DDTHH:mm:ss.sss')
}

export const formatDate = (date: any): string => {
  return date ? dayjs(date).format(config.date_time_format) : '--'
}

export const onlyFormatDate = (date: any): string => {
  const dt = new Date()
  const month = dt.getMonth() + 1
  const day = dt.getDate()
  const year = dt.getFullYear()
  const newDate = month + '/' + day + '/' + year
  return newDate
}

export const letterToHex = (st: string) => {
  var result = ''
  for (var i = 0; i < st.length; i++) {
    result += st.charCodeAt(i).toString(16)
  }
  return result
}

export const getStaticMap = (lat: string | number, lng: string | number) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat}, ${lng}&zoom=16&size=400x400&maptype=roadmap&markers=color:red%7C${lat}, ${lng}&key=${process.env.REACT_APP_GOOGLE_STATIC_API_KEY}`
}

export const getDateFilter = (fieldName: string) => {
  const now = new Date()
  const threeDate = new Date()
  const previous = new Date()
  const firstDay = new Date()
  const tmr = new Date()
  const lastThreeDate = new Date(new Date().setDate(threeDate.getDate() - 3))
  const tomorrow = new Date(new Date().setDate(tmr.getDate() + 1))
  const previousYear = new Date(new Date().setDate(previous.getDate() - 365))

  var firstday = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))
  var lastday = new Date(
    new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
  ).toUTCString()

  return [
    {
      text: 'Last 3 days',
      value: [[fieldName, '>=', lastThreeDate], 'and', [fieldName, '<=', now]],
    },
    {
      text: 'This week',
      value: [[fieldName, '<=', lastday], 'and', [fieldName, '>=', firstday]],
    },
    {
      text: 'Last One Year',
      value: [[fieldName, '>=', previousYear], 'and', [fieldName, '<=', tomorrow]],
    },
    {
      text: 'Future',
      value: [[fieldName, '>', now]],
    },
  ]
}

export const encryptText = (text: any) => {
  if (text) {
    var ciphertext = CryptoJS.AES.encrypt(
      text.toString(),
      process.env.REACT_APP_PRIVATE_ENCRYPT_KEY
    )
      .toString()
      .replace(/\+/g, 'p1L2u3S')
      .replace(/\//g, 's1L2a3S4h')
      .replace(/=/g, 'e1Q2u3A4l')
    return ciphertext
  }
  return undefined
}

export const decryptText = (ciphertext: any) => {
  if (ciphertext) {
    var bytes = CryptoJS.AES.decrypt(
      ciphertext
        .replace(/p1L2u3S/g, '+')
        .replace(/s1L2a3S4h/g, '/')
        .replace(/e1Q2u3A4l/g, '='),
      process.env.REACT_APP_PRIVATE_ENCRYPT_KEY
    )
    var originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
  }
  return undefined
}

export const stringToColour = (str: string) => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var colour = '#'
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export const setErrorObject = (error: any, setValidationErrors: any) => {
  if (error?.response?.data?.errors?.length === 0) return setValidationErrors({})

  let errorObj: any = {}
  error?.response?.data?.errors?.forEach((error: any) => {
    errorObj[error?.field] = error?.message
  })
  return setValidationErrors(errorObj)
}
//  autocomplete util-start
const findLongName = (res: any, typeName: any) => {
  const resultList = res.results[0].address_components
  var longName = ''
  resultList.forEach((data: any) => {
    if (data.types.includes(typeName)) {
      longName = data.long_name
    }
  })
  return longName
}

const findLocation = (res: any, type: string) => {
  const resultList = res.results[0].geometry.location
  if (type == 'latitude') {
    return resultList.lat()
  }

  if (type == 'longitude') {
    return resultList.lng()
  }
  return 0
}

const addressComponentTypes = {
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  COUNTY: 'administrative_area_level_2',
  CITY: 'locality',
  CITY2: 'sublocality',
  CITY3: 'postal_town',
  CITY4: 'administrative_area_level_2',
  ZIPCODE: 'postal_code',
}
export const getLocationByAddress = async (text: any) => {
  try {
    const geocoder = new window.google.maps.Geocoder()
    const res = await geocoder.geocode({address: text})

    if (res) {
      const changedLocation: UserAddressCreateDto = {
        address: text,
        zipCode: findLongName(res, addressComponentTypes.ZIPCODE),
        countryName: findLongName(res, addressComponentTypes.COUNTRY),
        stateName: findLongName(res, addressComponentTypes.STATE),
        cityName: findLongName(res, addressComponentTypes.CITY).length
          ? findLongName(res, addressComponentTypes.CITY)
          : findLongName(res, addressComponentTypes.CITY2).length
          ? findLongName(res, addressComponentTypes.CITY2)
          : findLongName(res, addressComponentTypes.CITY3).length
          ? findLongName(res, addressComponentTypes.CITY3)
          : findLongName(res, addressComponentTypes.CITY4),
        latitude: findLocation(res, 'latitude'),
        longitude: findLocation(res, 'longitude'),
      }
      return changedLocation
    }
  } catch (err) {
    console.log(err)
  }
}
export const getLocationByAddressForStaff = async (text: any) => {
  try {
    const geocoder = new window.google.maps.Geocoder()
    const res = await geocoder.geocode({address: text})

    if (res) {
      if (findLongName(res, addressComponentTypes.ZIPCODE)) {
        const changedLocation: UserAddressCreateDto = {
          address: text,
          zipCode: findLongName(res, addressComponentTypes.ZIPCODE),
          countryName: findLongName(res, addressComponentTypes.COUNTRY),
          stateName: findLongName(res, addressComponentTypes.STATE),
          cityName: findLongName(res, addressComponentTypes.CITY).length
            ? findLongName(res, addressComponentTypes.CITY)
            : findLongName(res, addressComponentTypes.CITY2).length
            ? findLongName(res, addressComponentTypes.CITY2)
            : findLongName(res, addressComponentTypes.CITY3).length
            ? findLongName(res, addressComponentTypes.CITY3)
            : findLongName(res, addressComponentTypes.CITY4),
          latitude: findLocation(res, 'latitude'),
          longitude: findLocation(res, 'longitude'),
        }
        return changedLocation
      } else {
        const secondRes = await geocoder.geocode({address: text + ', city hall'})
        if (secondRes) {
          const changedLocation: UserAddressCreateDto = {
            address: text,
            zipCode: findLongName(secondRes, addressComponentTypes.ZIPCODE),
            countryName: findLongName(secondRes, addressComponentTypes.COUNTRY),
            stateName: findLongName(secondRes, addressComponentTypes.STATE),
            cityName: findLongName(res, addressComponentTypes.CITY).length
              ? findLongName(res, addressComponentTypes.CITY)
              : findLongName(res, addressComponentTypes.CITY2).length
              ? findLongName(res, addressComponentTypes.CITY2)
              : findLongName(res, addressComponentTypes.CITY3).length
              ? findLongName(res, addressComponentTypes.CITY3)
              : findLongName(res, addressComponentTypes.CITY4),
            latitude: findLocation(res, 'latitude'),
            longitude: findLocation(res, 'longitude'),
          }
          return changedLocation
        }
      }
    }
  } catch (err) {
    console.log(err)
  }
}
//  autocomplete util-end

export const setDxFilterPlaceholder = (e: EditorPreparingEvent) => {
  if (e.parentType === 'filterRow') {
    e.editorOptions.placeholder === 'Start'
      ? (e.editorOptions.placeholder = 'From')
      : e.editorOptions.placeholder === 'End'
      ? (e.editorOptions.placeholder = 'To')
      : null
  }
}

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const humanDate = (dateRaw: any) => {
  const date = dayjs(dateRaw)
  if (dayjs().isSame(date)) {
    return 'Today'
  }

  if (dayjs().subtract(1, 'days').isSame(date)) {
    return 'Yesterday'
  }

  return dayjs(dateRaw).format('MMM DD, YYYY')
}

export const getFeedFilter = (type: string) => {
  if (type == 'job') {
    return ['jobStarted', 'jobCompleted', 'delayedJobStart', 'delayedJobFinish']
  }
  if (type == 'schedule') {
    return ['jobScheduled', 'jobCancelled', 'jobTimeChanged']
  }
  if (type == 'action') {
    return [
      'addProperty',
      'addStaff',
      'addChecklist',
      'addPayment',
      'calendarError',
      'propertyInfoMissing',
    ]
  }
  if (type == 'report') {
    return ['reportProblem']
  }
  if (type == 'virtual') {
    return ['photoAdded']
  }
}
