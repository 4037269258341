import React, { Dispatch } from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { IQuestion } from '../../../../interfaces/Checklist';
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton';
import * as checklistActionsCreators from '../../../../redux/actionCreators/checklistActionCreators';
import { useDispatch } from 'react-redux';
import { ChecklistAction } from '../../../../redux/actionTypes/checklistTypes';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import PhotoButton from '../../../../umut-components/Buttons/PhotoButton';
import { decryptText, toAmazonUrl } from '../../../../utils/util';
import { useDialog } from '../../../../contexts/DialogContext';
import { DeleteActions } from '../../../../redux/actionTypes/deleteDialogTypes';
import ApiCalls from '../../../../network/ApiCalls';
import { useParams } from 'react-router-dom';
import { closeDeleteDialog, openDeleteDialog } from '../../../../redux/actionCreators/deleteDialogActionCreators';
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton';

type Props = {
    index: number,
    question: IQuestion
}

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 24px 0`,
    // styles we need to apply on draggables
    ...draggableStyle
});

const SectionQuestion: React.FC<Props> = (props) => {
    let { checklistId }: any = useParams();
    checklistId = decryptText(checklistId);
    const { question, index } = props;
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const deleteDispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showSuccessDialog, showFailureDialog } = useDialog();

    const deleteSection = async () => {
        try {
            await ApiCalls.deleteSectionQuestion(question.id);
            dispatch(checklistActionsCreators.getChecklistItems(parseInt(checklistId)));
            deleteDispatch(closeDeleteDialog());
            showSuccessDialog('Checklist item deleted');
        }
        catch (err: any) {
            showFailureDialog(err?.response?.data?.message);
        }
    }

    const showDeleteDialog = async () => {
        deleteDispatch(openDeleteDialog(deleteSection, `Do you want to remove checklist item ${question.title?.en ?? question.title[Object.keys(question.title)[0]]}?`, 'Delete Checklist Item'))
    }

    return (
        <Draggable key={question.id} draggableId={question.id.toString()} index={index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    className={`bg-${snapshot.isDragging ? 'warning' : 'light'} shadow d-flex flex-row justify-content-between align-items-center`}
                >
                    <div className='d-flex flex-row align-items-center'>
                        <div className='d-flex flex-column'>
                            <small className='text-muted'>Checklist Item #{index + 1}</small>
                            <div onClick={() => { dispatch(checklistActionsCreators.openQuestionPhotoDialog(question)) }} className="border rounded me-5 position-relative">
                                <span style={{ top: 0, right: 0 }} className="position-absolute svg-icon svg-icon-primary svg-icon-x">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                                        </g>
                                    </svg>


                                </span>
                                <img style={{ width: 100, height: 100, objectFit: 'contain' }} alt="question" src={question.photos[0]?.samplePhotoURL ? toAmazonUrl(question.photos[0]?.samplePhotoURL) : toAbsoluteUrl('/media/icons/noImage.png')} />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            <strong >{question.title?.en ? question.title?.en : question.title[Object.keys(question.title)[0]]}</strong>
                            <strong className='text-muted'>{question.description?.en ? question.description?.en : question.description[Object.keys(question.description)[0]]}</strong>
                            <small className='text-muted'>{question.checklistAnswerTypeName} / {question.isPhotoMandatory ? 'Photo Mandatory' : ''}</small>
                        </div>
                    </div>
                    <div>
                        <UpdateButton updateFn={() => { dispatch(checklistActionsCreators.openQuestionEditDialog(question)) }} />
                        <DeleteButton iconWidth='20' iconHeight='20' classNames='btn btn-link btn-sm text-danger ms-6' deleteFn={showDeleteDialog} />
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default SectionQuestion;

//<PhotoButton executeFn={() => { dispatch(checklistActionsCreators.openQuestionPhotoDialog(question)) }} />
