import { put, call, all, fork, takeLatest, delay, takeEvery, select } from "redux-saga/effects";
import { IPMSProperty } from "../../interfaces/Property";
import { hostPropertyList } from "../selector/selectors";

import * as hostPropertyActionCreators from "../actionCreators/hostPropertyActionCreators";
import * as hostPropertyActionTypes from "../actionTypes/hostPropertyTypes";

function handleSetPropertyList(changedProperty: IPMSProperty, propertyList: IPMSProperty[]) {
    return propertyList.map(property => {
        if (property.pmsReferenceId === changedProperty.pmsReferenceId) {
            return { ...property, ...changedProperty }
        }
        return property;
    })
}

function* onSetProperty({ hostPropertyInput }: hostPropertyActionTypes.SetHostProperty) {
    try {
        const propertyList: IPMSProperty[] = yield select(hostPropertyList);
        const newPropertyList: IPMSProperty[] = handleSetPropertyList(hostPropertyInput, propertyList);
        yield put(hostPropertyActionCreators.setPropertySuccess(newPropertyList));
    }
    catch (error) {
        put(hostPropertyActionCreators.setPropertyFailure());
    }
}

function* watchOnSetProperty() {
    yield takeLatest(hostPropertyActionTypes.SET_HOST_PROPERTY, onSetProperty);
}

export default function* hostPropertySaga() {
    yield all([fork(watchOnSetProperty)]);
}