import React, {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useParams} from 'react-router'
import {useDialog} from '../../../contexts/DialogContext'
import {usePropertyForm} from '../../../contexts/PropertyFormContext'
import {CleaningProviders} from '../../../enums/PropertyEnum'
import {
  IHostPropertyAccess,
  IHostPropertyInput,
  IHostPropertyOther,
  IHostPropertyServiceProviderStep,
  IHostPropertyStorage,
} from '../../../interfaces/Property'
import {IQuotes} from '../../../interfaces/Quotes'
import ApiCalls from '../../../network/ApiCalls'
import {decryptText} from '../../../utils/util'
import ArrowButton from '../../Buttons/ArrowButton'
import SelectInput from '../../Inputs/SelectInput'
import SwitchBox from '../../Inputs/SwitchBox'
import {TextInput} from '../../Inputs/TextInput'

interface IValues {
  hasProQuoteRequest?: boolean
  hasPlusQuoteRequest?: boolean
  sameDayBooking?: boolean
  numberOfCleaner?: number
  missingField?: boolean
  hasStorage?: boolean
  storageDescription?: string
  storageInQuote?: boolean
  hasParking?: boolean
  parkingDescription?: string
  cleaningProviderId?: number
  hasLaundry?: boolean
  hasDryer?: boolean
  laundryInQuote?: boolean
  laundryToBeDone?: boolean
  laundryMachineCycle?: number
  laundryDryerCycle?: number
  laundryLinenNumber?: string
  hasTrashDisposal?: boolean
  trashDays?: string
  propertySize?: number
  isMarketplace?: boolean
}

type Props = {
  hostPropertyAccess?: IHostPropertyAccess
  hostPropertyStorage?: IHostPropertyStorage[]
  hostPropertyOthers?: IHostPropertyOther[]
  hasProQuoteRequest?: boolean
  hasPlusQuoteRequest?: boolean
  values?: IValues
  handleChangeHostProperty: (changedValues: IHostPropertyInput) => void
  currentStep?: number
  totalSteps?: number
  nextStep?: () => void
  previousStep?: () => void
  goToStep?: (step: number) => void
  hostProperty?: IHostPropertyInput
  editMode?: boolean
  missingField?: boolean
}

const booleanOptions = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
]

const PropertyDetailStep: React.FC<Props> = ({
  missingField,
  currentStep,
  goToStep,
  hasProQuoteRequest,
  hasPlusQuoteRequest,
  hostPropertyAccess,
  editMode,
  values,
  hostPropertyStorage,
  hostPropertyOthers,
  handleChangeHostProperty,
  nextStep,
  previousStep,
}) => {
  let {propertyId}: any = useParams()
  propertyId = decryptText(propertyId)

  if (values?.isMarketplace) {
    values.cleaningProviderId = 4
  }

  const {accessTypes, cleanerProviders, otherQuestions} = usePropertyForm()
  const [selectedValues, setSelectedValues] = useState<IValues>(
    values
      ? values
      : {
          hasStorage: false,
          storageDescription: '',
          storageInQuote: false,
          hasParking: false,
          parkingDescription: '',
          cleaningProviderId: 1,
          hasLaundry: false,
          hasDryer: false,
          laundryInQuote: false,
          laundryToBeDone: false,
          laundryMachineCycle: 0,
          laundryDryerCycle: 0,
          laundryLinenNumber: '',
          hasTrashDisposal: false,
          trashDays: '',
          propertySize: 0,
          sameDayBooking: false,
          missingField: false,
          numberOfCleaner: 1,
          hasProQuoteRequest: false,
          hasPlusQuoteRequest: false,
        }
  )
  const [selectedAccess, setSelectedAccess] = useState<IHostPropertyAccess>(
    hostPropertyAccess ? hostPropertyAccess : {}
  )
  const [selectedStorages, setSelectedStorages] = useState<IHostPropertyStorage[]>(
    hostPropertyStorage ? (hostPropertyStorage.length > 0 ? hostPropertyStorage : [{}]) : [{}]
  )
  const [selectedOthers, setSelectedOthers] = useState<IHostPropertyOther[]>(
    hostPropertyOthers ? hostPropertyOthers : []
  )
  const [otherQuestionsFiltered, setOtherQuestionFiltered] = useState<IHostPropertyStorage[]>()

  const [loading, setLoading] = useState(false)
  const [quoteLoading, setQuoteLoading] = useState(false)
  const [quoteSent, setQuoteSent] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)
  const {showSuccessDialog} = useDialog()
  const [propertySize, setPropertySize] = useState(0)

  useEffect(() => {
    if (selectedValues.propertySize != undefined) {
      setPropertySize(+selectedValues.propertySize)
    }
  }, [selectedValues])

  const getQuote = async () => {
    try {
      setQuoteLoading(true)
      setErrorMessage(null)
      await ApiCalls.requestTurnifyPro(propertyId)
      setQuoteLoading(false)
      setQuoteSent(true)
      showSuccessDialog('Requested.')
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  const onProviderChange = (value: number) => {
    setSelectedValues({...selectedValues, cleaningProviderId: value})
  }

  const numberOfCleanersChange = (value: number) => {
    setSelectedValues({...selectedValues, numberOfCleaner: value})
  }

  const unitAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, unitAccessTypeId: value})
  }

  const buildingAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, buildingAccessTypeId: value})
  }

  const unitAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, unitAccessValue: value})
  }

  const buildingAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, buildingAccessValue: value})
  }

  const storageTextChange = (value: any) => {
    setSelectedStorages([{storageDescription: value}])
  }

  const laundryMachineCycleChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryMachineCycle: +value})
  }

  const laundryDryerCycleChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryDryerCycle: +value})
  }

  const laundryLinenNumberChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryLinenNumber: value})
  }

  const trashDaysChange = (value: string) => {
    setSelectedValues({...selectedValues, trashDays: value})
  }

  const changeOtherQuestionAnswer = (id: number, value: string) => {
    let newValue: IHostPropertyOther = {
      id: id,
      hostPropertyOtherQuestionId: id,
      value,
    }
    setSelectedOthers((prevState: IHostPropertyOther[]) => [
      ...prevState.filter((question) => question.hostPropertyOtherQuestionId != id),
      newValue,
    ])
  }

  useEffect(() => {
    const filteredQuestions = otherQuestions?.filter((question: any) =>
      hostPropertyOthers?.some((d) => d.hostPropertyOtherQuestionId == question.id)
    )
    setOtherQuestionFiltered(filteredQuestions)
  }, [])

  useEffect(() => {
    handleChangeHostProperty({
      ...selectedValues,
      hostPropertyAccess: selectedAccess,
      hostPropertyStorages: selectedStorages,
      hostPropertyOthers: selectedOthers,
    })
  }, [selectedValues, selectedAccess, selectedStorages, selectedOthers])

  return (
    <form className='form w-100 mt-5'>
      <div className='row mb-6 d-flex justify-content-center'>
        {editMode ? (
          <div className='col-lg-12'>
            {(values?.cleaningProviderId != 2 ||
              (values?.cleaningProviderId == 2 && values?.isMarketplace)) && (
              <>
                <label className='form-label fs-6 fw-bolder text-dark w-100'>
                  Upgrade to TurnifyPRO
                </label>
                <small className='text-danger'>
                  Do you want to <b>auto-pilot</b> your property's <b>cleaning operations?</b> and
                  set "spotless clean" as your standard? Fill out the below and our onboarding
                  experts will review your submission, and reach out with any further questions.
                  Once completed, you can expect a quote within 1 to 2 business days.
                </small>
              </>
            )}

            <label className='form-label fs-6 fw-bolder text-dark w-100 mt-5'>
              Service Provider
            </label>

            <SelectInput
              disabled={selectedValues.cleaningProviderId == 2 && !selectedValues.isMarketplace}
              options={cleanerProviders}
              classNames='mt-3'
              onValueChanged={onProviderChange}
              defaultValue={selectedValues.cleaningProviderId}
            />
            {missingField && !selectedValues.cleaningProviderId && (
              <strong className='text-danger'>Missing Field</strong>
            )}

            {selectedValues.cleaningProviderId !== 1 && selectedValues.cleaningProviderId !== 4 && (
              <button
                type='button'
                className='btn btn-primary w-100 mt-5'
                onClick={getQuote}
                disabled={quoteLoading || quoteSent}
              >
                {quoteSent ? 'Quote Sent' : 'Get a quote'}
                {quoteLoading && <Spinner animation='border' size='sm' />}
              </button>
            )}
          </div>
        ) : (
          <>
            {/* <label className='form-label fs-6 fw-bolder text-dark w-100'>
                            This property is cleaned by: {<span style={{ color: 'red' }}>*</span>}
                        </label> */}
            {/* <SelectInput
                            disabled={true}
                            options={cleanerProviders}
                            classNames='mt-3'
                            onValueChanged={onProviderChange}
                            defaultValue={CleaningProviders.MY_TEAM}
                        /> */}
          </>
        )}
      </div>

      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Access Unit</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your unit access process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            How do your cleaners access the unit? <span className='text-danger'>*</span>
          </label>
          <small className='text-muted col-lg-12 mb-3'>
            This information will be kept strictly confidential and will not be shared with anyone
            except the assigned cleaner(s). The access information will only be made available to
            the cleaner on the day of cleaning to ensure the highest level of security.
          </small>
          <div className='row'>
            <div className='col-lg-3'>
              <SelectInput
                options={accessTypes}
                onValueChanged={unitAccessIdChange}
                defaultValue={selectedAccess?.unitAccessTypeId}
              />
              {(missingField || hasMissingField) && !selectedAccess.unitAccessTypeId && (
                <strong className='text-danger'>Missing Field</strong>
              )}
            </div>
            <div className='col-lg-9'>
              <TextInput
                onValueChange={unitAccessTextChange}
                defaultValue={selectedAccess.unitAccessValue}
              />
              {(missingField || hasMissingField) && !selectedAccess.unitAccessValue && (
                <strong className='text-danger'>Missing Field</strong>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Access Building Area */}
      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Access Building</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your building access process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            How do your cleaners access the building? <span className='text-danger'>*</span>
          </label>
          <small className='text-muted col-lg-12 mb-3'>
            This information will be kept strictly confidential and will not be shared with anyone
            except the assigned cleaner(s). The access information will only be made available to
            the cleaner on the day of cleaning to ensure the highest level of security.
          </small>
          <div className='row'>
            <div className='col-lg-3'>
              <SelectInput
                options={accessTypes}
                onValueChanged={buildingAccessIdChange}
                defaultValue={selectedAccess?.buildingAccessTypeId}
              />
              {(missingField || hasMissingField) && !selectedAccess.buildingAccessTypeId && (
                <strong className='text-danger'>Missing Field</strong>
              )}
            </div>
            <div className='col-lg-9'>
              <TextInput
                onValueChange={buildingAccessTextChange}
                defaultValue={selectedAccess.buildingAccessValue}
              />
              {(missingField || hasMissingField) && !selectedAccess.buildingAccessValue && (
                <strong className='text-danger'>Missing Field</strong>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Laundry Area */}
      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Laundry</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your laundry process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            Do you have laundry? <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-12'>
            <SelectInput
              options={booleanOptions}
              onValueChanged={(value) =>
                setSelectedValues({...selectedValues, hasLaundry: value === 1 ? true : false})
              }
              defaultValue={selectedValues.hasLaundry ? 1 : 2}
            />
          </div>
          {selectedValues.hasLaundry && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                Do you have a dryer?
              </label>
              <div className='col-lg-12'>
                <SelectInput
                  options={booleanOptions}
                  onValueChanged={(value) =>
                    setSelectedValues({...selectedValues, hasDryer: value === 1 ? true : false})
                  }
                  defaultValue={selectedValues.hasDryer ? 1 : 2}
                />
              </div>
            </>
          )}
          <>
            <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
              Do you need laundry service to be included your quote?{' '}
              <span className='text-danger'>*</span>
            </label>
            <div className='col-lg-12'>
              <SelectInput
                options={booleanOptions}
                onValueChanged={(value) =>
                  setSelectedValues({...selectedValues, laundryInQuote: value === 1 ? true : false})
                }
                defaultValue={selectedValues.laundryInQuote ? 1 : 2}
              />
            </div>
          </>
          {selectedValues.laundryInQuote && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                Do you want the laundry to be done in the promises?{' '}
                <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-12'>
                <SelectInput
                  options={booleanOptions}
                  onValueChanged={(value) =>
                    setSelectedValues({
                      ...selectedValues,
                      laundryToBeDone: value === 1 ? true : false,
                    })
                  }
                  defaultValue={selectedValues.laundryToBeDone ? 1 : 2}
                />
              </div>
            </>
          )}
          {selectedValues.laundryToBeDone && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                How many cycles does it take for your washing machine and dryer to wash your linens
                in a regular turnover? <span className='text-danger'>*</span>
              </label>
              <div className='row'>
                <div className='col-lg-6'>
                  <TextInput
                    onValueChange={laundryMachineCycleChange}
                    defaultValue={selectedValues?.laundryMachineCycle}
                    type='number'
                    placeholder='Laundry Machine (Counter)'
                  />
                  {(missingField || hasMissingField) &&
                    selectedValues.laundryToBeDone &&
                    selectedValues?.laundryMachineCycle == 0 && (
                      <strong className='text-danger'>Missing field</strong>
                    )}
                </div>
                <div className='col-lg-6'>
                  <TextInput
                    onValueChange={laundryDryerCycleChange}
                    defaultValue={selectedValues?.laundryDryerCycle}
                    type='number'
                    placeholder='Dryer (Counter)'
                  />
                  {(missingField || hasMissingField) &&
                    selectedValues.laundryToBeDone &&
                    selectedValues?.laundryDryerCycle == 0 && (
                      <strong className='text-danger'>Missing field</strong>
                    )}
                </div>
              </div>
            </>
          )}
          {selectedValues.laundryInQuote && !selectedValues.laundryToBeDone && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                Please provide the number of your linens for each turnover{' '}
                <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-6'>
                <TextInput
                  onValueChange={laundryLinenNumberChange}
                  defaultValue={selectedValues?.laundryLinenNumber}
                  type='text'
                  placeholder='King Sheet / Queen Sheet / Twin Sheet etc.'
                />
                {(missingField || hasMissingField) &&
                  !selectedValues.laundryToBeDone &&
                  selectedValues?.laundryLinenNumber == '' && (
                    <strong className='text-danger'>Missing field</strong>
                  )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Storage Area */}
      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Storage</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your storage process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            Do you have a storage? <span className='text-danger'>*</span>
          </label>
          {selectedValues.hasStorage && (
            <small className='text-muted mb-3'>
              Please give more information about the location and access details of your storage for
              the staff.
            </small>
          )}
          <div className='row'>
            <div className='col-lg-3'>
              <SelectInput
                options={booleanOptions}
                onValueChanged={(value) =>
                  setSelectedValues({...selectedValues, hasStorage: value === 1 ? true : false})
                }
                defaultValue={selectedValues.hasStorage ? 1 : 2}
              />
            </div>
            <div className='col-lg-9'>
              {selectedValues.hasStorage && (
                <TextInput
                  onValueChange={storageTextChange}
                  defaultValue={
                    selectedStorages.length > 0
                      ? selectedStorages[0].storageDescription
                      : selectedValues.storageDescription
                  }
                />
              )}
              {(missingField || hasMissingField) &&
                selectedValues.hasStorage &&
                selectedStorages.length > 0 &&
                selectedStorages[0].storageDescription === '' && (
                  <strong className='text-danger'>Missing field</strong>
                )}
            </div>
          </div>
          {selectedValues.hasStorage && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                Do you need the storage service to be included in your quote?{' '}
                <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-12'>
                <SelectInput
                  options={booleanOptions}
                  onValueChanged={(value) =>
                    setSelectedValues({
                      ...selectedValues,
                      storageInQuote: value === 1 ? true : false,
                    })
                  }
                  defaultValue={selectedValues.storageInQuote ? 1 : 2}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Trash Area */}
      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Trash Disposal</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your trash disposal process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            Do you need us to take the trash out to the curb in specific days of the week?{' '}
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-12'>
            <SelectInput
              options={booleanOptions}
              onValueChanged={(value) =>
                setSelectedValues({...selectedValues, hasTrashDisposal: value === 1 ? true : false})
              }
              defaultValue={selectedValues.hasTrashDisposal ? 1 : 2}
            />
          </div>
          {selectedValues.hasTrashDisposal && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                Which days? <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-12'>
                {/*<SelectInput
                                        options={weekDaysOptions}
                                        onValueChanged={(value) =>
                                            setTrashDays(value)
                                        }
                                        defaultValue={getTrashDays()}
                                    />*/}
                <TextInput
                  onValueChange={trashDaysChange}
                  defaultValue={selectedValues?.trashDays}
                  placeholder='Day List / Hours (Before)'
                />
                {(missingField || hasMissingField) &&
                  selectedValues.hasTrashDisposal &&
                  selectedValues?.trashDays === '' && (
                    <strong className='text-danger'>Missing field</strong>
                  )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Parking Area */}
      <div className='row mb-6 border-bottom pb-5'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Parking</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your parking process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
            Do you have parking?
          </label>
          {selectedValues.hasParking && (
            <small className='text-muted mb-3'>
              Please give more information about the parking
            </small>
          )}
          <div className='row'>
            <div className='col-lg-3'>
              <SelectInput
                options={booleanOptions}
                onValueChanged={(value) =>
                  setSelectedValues({...selectedValues, hasParking: value === 1 ? true : false})
                }
                defaultValue={selectedValues.hasParking ? 1 : 2}
              />
            </div>
            <div className='col-lg-9'>
              {selectedValues.hasParking && (
                <>
                  <TextInput
                    onValueChange={(text) =>
                      setSelectedValues({...selectedValues, parkingDescription: text})
                    }
                    defaultValue={selectedValues.parkingDescription}
                    placeholder='Please provide information on how to use the parking service (Paid or free etc.)'
                  />
                  {(missingField || hasMissingField) &&
                    selectedValues.hasParking &&
                    (!selectedValues.parkingDescription ||
                      selectedValues.parkingDescription === '') && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Property Size Area */}
      <div className='row mb-6'>
        <div className='col-lg-4 col-form-label'>
          <label className='fw-bolder text-dark fs-6'>Property Size</label>
          <div>
            <small className='text-muted'>
              Provide additional information about your property size process
            </small>
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-12 mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>Size (sqft.)</label>
              <TextInput
                onValueChange={(text) =>
                  setSelectedValues({...selectedValues, propertySize: +text})
                }
                defaultValue={selectedValues.propertySize + ''}
                placeholder='Type here'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        {otherQuestionsFiltered &&
          otherQuestionsFiltered?.length > 0 &&
          otherQuestionsFiltered?.map((question: any) => {
            return (
              <div>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  {question?.name}
                </label>
                <div className='col-lg-12'>
                  <input
                    className='form-control mb-6'
                    type='text'
                    onChange={(e) => {
                      changeOtherQuestionAnswer(question?.id, e.target.value)
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
      {editMode ? null : (
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={previousStep} />
          <ArrowButton type='forward' func={nextStep} />
        </div>
      )}
    </form>
  )
}

export default PropertyDetailStep
