import React, {Dispatch, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {PageTitle} from '../../../../_metronic/layout/core'
import {ProTeamAction} from '../../../redux/actionTypes/proTeamActionTypes'
import * as proTeamActionCreators from '../../../redux/actionCreators/proTeamActionCreators'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import ProfileTab from './Tabs/ProfileTab'
import Jobs from './Tabs/Jobs'
import PropertyTab from './Tabs/PropertyTab'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import {decryptText} from '../../../utils/util'
import Staffs from './Tabs/Staffs'

type TabNames = 'property-list' | 'jobs' | 'staffs'

const ProTeamDetail: React.FC = () => {
  let {userId}: any = useParams()
  userId = decryptText(userId)
  const [selectedTab, setSelectedTab] = useState<TabNames>('property-list')
  const dispatch = useDispatch<Dispatch<ProTeamAction>>()
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  useEffect(() => {
    dispatch(proTeamActionCreators.getProTeamDetail(parseInt(userId)))
  }, [])

  return (
    <>
      <PageTitle>Pro Team Member Detail</PageTitle>
      <div className='row g-5 g-xl-12'>
        <Card className='shadow'>
          <Card.Body className='p-0'>
            <ProfileTab modalOpen={setEditDialogOpen} />
            <Tabs
              defaultActiveKey='property-list'
              id='uncontrolled-tab-example'
              className='mb-3 mt-3'
              onSelect={handleChangeTab}
            >
              <Tab eventKey='property-list' title='Properties'>
                {selectedTab === 'property-list' && <PropertyTab />}
              </Tab>
              <Tab eventKey='jobs' title='Jobs'>
                {selectedTab === 'jobs' && <Jobs />}
              </Tab>
              <Tab eventKey='staffs' title='Staffs'>
                {selectedTab === 'staffs' && <Staffs />}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
      <DeleteDialog />
    </>
  )
}

export default ProTeamDetail
