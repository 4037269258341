import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';

type Props = {
    width?: string | number;
    height?: string | number;
    title?: string;
    classNames?: string;
    marginTop?: number | string;
}

const NoPhoto: React.FC<Props> = ({ width, height, title, classNames, marginTop }) => {
    return (
        <div style={{ marginTop: marginTop ?? 0 }} className={'d-flex align-items-center justify-content-center flex-column w-100 ' + classNames}>
            <img style={{ width: width ?? '25%', height }} alt="no-data" src={toAbsoluteUrl('/media/icons/noPhoto.png')} />
            <strong className='text-muted'>{title}</strong>
        </div>
    )
}

export default NoPhoto;