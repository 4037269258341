import React, {useEffect} from 'react'
import {useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {IServiceGroup, IServiceTypes} from '../../../interfaces/ServiceType'
import {KTSVG} from '../../../../_metronic/helpers'
import {IChecklistItem, IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ChecklistAddDialog from '../MasterChecklists/MasterChecklistAddDialog'
import SectionAddDialog from '../MasterChecklistSections/MasterSectionAddDialog'
import SectionEditDialog from '../MasterChecklistSections/MasterSectionEditDialog'
import QuestionAddDialog from '../MasterChecklistSections/MasterQuestions/MasterQuestionAddDialog'
import QuestionEditDialog from '../MasterChecklistSections/MasterQuestions/MasterQuestionEditDialog'
import QuestionPhotoDialog from '../MasterChecklistSections/MasterQuestions/MasterQuestionPhotoDialog'
import * as checklistActionCreator from '../../../redux/actionCreators/masterChecklistActionCreators'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import {openChecklistAssignmentDialog} from '../../../redux/actionCreators/masterChecklistActionCreators'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../redux/actionCreators/deleteDialogActionCreators'
import {useDialog} from '../../../contexts/DialogContext'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import NewChecklistAddDialog from './NewMasterChecklistAddDialog'
import NewQuestionAddDialog from './NewMasterQuestionAddDialog'
import NewSectionAddDialog from './NewMasterSectionAddDialog'
import NewChecklistSections from './NewMasterChecklistSections'
import ChecklistAssignmentDialog from '../MasterChecklists/MasterChecklistAssignmentDialog'
import NewChecklistAssignmentDialog from './NewMasterChecklistAssignmentDialog'
import EmptyMasterChecklist from './EmptyMasterChecklist'
import MasterChecklistHelpDialog from './MasterChecklistHelpDialog'
import SkeletonCircleLoading from '../../../umut-components/Loading/SkeletonCircleLoading'
import NewChecklistEditDialog from './NewMasterChecklistEditDialog'

const MasterChecklists: React.FC = () => {
  const dispatch = useDispatch()
  const deleteDispatch = useDispatch()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [renderKey, setRenderKey] = useState(1)
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [checklistSections, setChecklistSections] = useState<IChecklistItem>()
  const [serviceGroupList, setServiceGroupList] = useState<any[]>([])
  const [selectedServiceGroupId, setSelectedServiceGroupId] = useState(1)
  const [selectedServiceGroupInfo, setSelectedServiceGroupInfo] = useState<IServiceGroup>()
  const [selectedServiceId, setSelectedServiceId] = useState(1)
  const [selectedServiceInfo, setSelectedServiceInfo] = useState<IServiceTypes>()
  const [selectedChecklistId, setSelectedChecklistId] = useState(0)
  const [selectedChecklistInfo, setSelectedChecklistInfo] = useState<any>()
  const [selectedChecklistSectionId, setSelectedChecklistSectionId] = useState(0)
  const [checklistAddModalOpen, setChecklistAddModalOpen] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const [serviceTypes, setServiceTypes] = useState<any[]>([])
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const {
    data: serviceGroups,
    isLoading: serviceGroupLoading,
    error: serviceGroupError,
    refetch: refetchServiceGroup,
  } = useQuery('Get Service Groups with Services', () => ApiCalls.getServiceGroupsWithServices(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const {data: serviceTypeData} = useQuery('Get ServiceTypes', () => ApiCalls.getServiceTypes(), {
    cacheTime: 50000,
    refetchOnWindowFocus: false,
  })

  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery(
    'Get Host Master Checklists By Service',
    () => ApiCalls.getHostMasterChecklistsByService(user.userSection.hostId, selectedServiceId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: selectedServiceId > 0 ? true : false}
  )

  useEffect(() => {
    const list: any[] = []
    if (serviceTypeData) {
      for (const service of serviceTypeData?.data?.data) {
        if (service.isActive) {
          var find = list.find((l) => l.id == service.id)
          if (!find) {
            list.push({id: service.id, name: service.name})
          }
        }
      }
      setServiceTypes(list)
    }
  }, [serviceTypeData])

  const handleCloseChecklistAddModal = () => {
    setChecklistAddModalOpen(false)
  }

  const handleCloseHelpModal = () => {
    setHelpModalOpen(false)
  }

  const handleAddPropertySuccess = () => {
    setChecklistAddModalOpen(false)
    refetchChecklists()
  }

  const openAssignment = (checklist: any) => {
    setSelectedChecklistInfo(checklist)
    setSelectedChecklistId(checklist.id)
    dispatch(openChecklistAssignmentDialog(checklist))
  }

  const deleteAction = async () => {
    try {
      await ApiCalls.deleteChecklist(selectedChecklistId)
      refetchChecklists()
      deleteDispatch(closeDeleteDialog())
      showSuccessDialog('Checklist removed!')
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }

  const deleteButtonClick = async (checklist: any) => {
    deleteDispatch(
      openDeleteDialog(
        deleteAction,
        `Do you want to remove checklist ${checklist.name.en}?`,
        'Remove Checklist'
      )
    )
  }

  useEffect(() => {
    if (checklistData) {
      setChecklists(checklistData?.data)
    }
  }, [checklistData])

  useEffect(() => {
    const sglist: any[] = []
    if (!serviceGroupLoading && serviceGroups?.data?.data) {
      for (const sg of serviceGroups?.data?.data) {
        if (sg.isActive) {
          const findSG = sglist.find((item) => item.id == sg.id)
          if (findSG == undefined) {
            const sgservices: any[] = []
            for (const service of sg.serviceTypes) {
              if (service.isActive) {
                sgservices.push({
                  id: service.id,
                  serviceGroupId: sg.id,
                  name: service.name,
                  description: service.description,
                })
              }
            }

            sglist.push({
              id: sg.id,
              name: sg.name,
              description: sg.description,
              services: sgservices,
            })
          }
        }
      }

      setServiceGroupList(sglist)
    }
  }, [serviceGroupLoading, serviceGroups])

  useEffect(() => {
    var newkey = renderKey + 1
    if (serviceGroupList.length > 0) {
      const findGroup = serviceGroupList.find((sg) => sg.id == selectedServiceGroupId)
      if (findGroup != undefined) {
        setRenderKey(newkey)
        setSelectedServiceGroupInfo(findGroup)
        const findService = findGroup.services.find(
          (service: any) => service.id == selectedServiceId
        )
        if (findService != undefined) {
          setRenderKey(newkey)
          setSelectedServiceInfo(findService)
        }
      }
    }
    refetchChecklists()
  }, [serviceGroupList, selectedServiceGroupId, selectedServiceId])

  const refreshPage = () => {
    window.location.reload()
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  return (
    <>
      <PageTitle>Master Checklist</PageTitle>
      <div className='row'>
        <div className='col-xl-3 col-12 mb-3'>
          <div className='card mb-3'>
            <div className='card-body p-lg-15'>
              <div className='mb-5'>
                <h4 className='text-dark mb-7'>Services</h4>
                <div className='menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold'>
                  {serviceGroupList.map((sg, index) => {
                    return (
                      <div className='menu-item menu-accordion mb-1' key={'service-group-' + index}>
                        <div
                          className='menu-link py-3'
                          onClick={() => {
                            setSelectedServiceGroupId(sg.id)
                          }}
                        >
                          <span className='fw-boldest'>{sg.name}</span>
                        </div>
                        <div
                          className={
                            selectedServiceGroupId == sg.id
                              ? 'menu-sub menu-sub-accordion menu-active-bg show'
                              : 'menu-sub menu-sub-accordion menu-active-bg'
                          }
                        >
                          {sg.services.map((service: any, ind: number) => {
                            return (
                              <div
                                className='menu-item'
                                key={'service-group-' + index + '-service-' + ind}
                                onClick={() => {
                                  setSelectedServiceId(service.id)
                                  setSelectedServiceGroupId(sg.id)
                                }}
                              >
                                <div
                                  className={
                                    selectedServiceId == service.id
                                      ? 'menu-link active'
                                      : 'menu-link'
                                  }
                                >
                                  <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                  </span>
                                  <span className='menu-title'>{service.name}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className='m-0'>
                <h4 className='text-dark mb-7'>Help</h4>

                <div className='d-flex flex-stack mb-7'>
                  <div className='symbol symbol-60px symbol-2by3 me-4'>
                    <div
                      className='symbol-label'
                      style={{
                        backgroundImage: `url('/media/icons/check-list.jpeg')`,
                        backgroundSize: 'contain',
                      }}
                    ></div>
                  </div>
                  <div className='m-0'>
                    <button
                      className='btn btn-link text-start text-dark fw-bold text-hover-primary fs-6'
                      onClick={() => {
                        setHelpModalOpen(true)
                      }}
                    >
                      Understanding Checklists in Turnify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-9 col-12 mb-3'>
          <div className='card mb-3'>
            <div className='card-body'>
              <div
                className='d-flex justify-content-between align-items-center'
                key={'service-group-info-' + renderKey}
              >
                <div className='mb-2'>
                  <h4 className='fs-2x text-gray-800 w-bolder mb-6'>{selectedServiceInfo?.name}</h4>

                  <p className='fw-semibold fs-4 text-gray-600 mb-2'>
                    {selectedServiceInfo?.description}
                  </p>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-success'
                    onClick={() => {
                      setChecklistAddModalOpen(true)
                    }}
                  >
                    +Add New Checklist
                  </button>
                </div>
              </div>
            </div>
          </div>
          {checklistLoading && (
            <div className='card'>
              <div className='card-body'>
                <SkeletonCircleLoading />
              </div>
            </div>
          )}
          {!checklistLoading && (
            <>
              {checklists.length > 0 && (
                <>
                  {checklists.map((checklist: any, index: number) => {
                    return (
                      <div className='card mb-3'>
                        <div className='card-body'>
                          <div className='mb-15' key={'master-checklist-' + index}>
                            <div
                              className={
                                selectedChecklistId == checklist.id
                                  ? 'checklist-title active d-flex justify-content-between align-items-center'
                                  : 'checklist-title d-flex justify-content-between align-items-center'
                              }
                            >
                              <div className='d-flex align-items-center'>
                                <h3
                                  className='text-gray-800 w-bolder mb-0'
                                  onClick={() => {
                                    if (selectedChecklistId !== checklist.id) {
                                      setSelectedChecklistId(checklist.id)
                                      setSelectedChecklistInfo(checklist)
                                    } else {
                                      setSelectedChecklistId(0)
                                      setSelectedChecklistInfo(undefined)
                                    }
                                  }}
                                >
                                  {checklist.name.en}
                                </h3>
                              </div>
                              <div className='d-flex position-relative'>
                                <button
                                  className='btn btn-sm btn-icon btn-bg-light btn-color-primary dropmenu'
                                  onClick={() => {
                                    if (
                                      selectedDropIndx ==
                                      'drop-checklist-' + checklist.id + '-' + index
                                    ) {
                                      setSelectedDropIndx(undefined)
                                    } else {
                                      setSelectedDropIndx(
                                        'drop-checklist-' + checklist.id + '-' + index
                                      )
                                    }
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotone/General/Other2.svg'
                                    className='svg-icon-3 svg-icon-secondary dropmenu'
                                    svgClassName='dropmenu mh-50px'
                                  />
                                </button>
                                <div
                                  className={
                                    selectedDropIndx ==
                                    'drop-checklist-' + checklist.id + '-' + index
                                      ? 'menu menu-sub menu-sub-dropdown w-150px w-md-150px align-items-end dropcl show'
                                      : 'menu menu-sub menu-sub-dropdown w-150px w-md-150px align-items-end dropcl'
                                  }
                                >
                                  <div className='px-7 py-5 d-flex flex-column align-items-end'>
                                    <button
                                      className='btn btn-sm btn-link text-primary text-end'
                                      onClick={() => {
                                        setSelectedChecklistId(checklist.id)
                                        setSelectedChecklistInfo(checklist)
                                        openAssignment(checklist)
                                      }}
                                    >
                                      Assign to Property
                                    </button>
                                    <button
                                      className='btn btn-sm btn-link text-info text-end'
                                      onClick={() => {
                                        setSelectedChecklistId(checklist.id)
                                        setSelectedChecklistInfo(checklist)
                                        setShowEditModal(true)
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className='btn btn-sm btn-link text-danger text-end'
                                      onClick={() => {
                                        setSelectedChecklistId(checklist.id)
                                        setSelectedChecklistInfo(checklist)
                                        deleteButtonClick(checklist)
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={'checklist-sections active'}>
                              {checklist.sections != undefined && (
                                <>
                                  <NewChecklistSections
                                    checklistId={checklist.id}
                                    checklistSections={checklist}
                                    refetch={refetchChecklists}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
              {checklists.length == 0 && (
                <EmptyMasterChecklist serviceType={selectedServiceInfo?.name} />
              )}
            </>
          )}
        </div>
      </div>
      <NewChecklistAddDialog
        modalOpen={checklistAddModalOpen}
        handleClose={handleCloseChecklistAddModal}
        handleAddPropertySuccess={handleAddPropertySuccess}
        serviceTypeId={selectedServiceId}
      />
      <SectionEditDialog />
      <NewQuestionAddDialog checklistId={selectedChecklistId.toString()} />
      <QuestionEditDialog />
      <QuestionPhotoDialog />
      <DeleteDialog />
      <MasterChecklistHelpDialog modalOpen={helpModalOpen} handleClose={handleCloseHelpModal} />
      <NewChecklistAssignmentDialog checklist={selectedChecklistInfo} />
      {showEditModal && (
        <NewChecklistEditDialog
          checklist={selectedChecklistInfo}
          checklists={checklists}
          serviceTypes={serviceTypes}
          visible={showEditModal}
          handleClose={handleCloseEditModal}
          refetchChecklists={refetchChecklists}
          refreshPage={refreshPage}
        />
      )}
    </>
  )
}

export default MasterChecklists
