import React, {useEffect, useState} from 'react'
import {IHostPropertyBedroom, IHostPropertyInput} from '../../../../../interfaces/Property'
import SelectInput from '../../../../../umut-components/Inputs/SelectInput'
import {usePropertyForm} from '../../../../../contexts/PropertyFormContext'
import ApiCalls from '../../../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {TextInput} from '../../../../../umut-components/Inputs/TextInput'
import {Spinner} from 'react-bootstrap-v5'
import {useDialog} from '../../../../../contexts/DialogContext'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
  refetch: () => void
}

const booleanOptions = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
]

const Information: React.FC<Props> = ({property, propertyId, refetch}) => {
  if (property?.isMarketplace) {
    property.cleaningProviderId = 4
  }

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [cleanerProviders, setCleanerProviders] = useState<any[]>([])
  const [accessTypes, setAccessTypes] = useState<any[]>([])
  const [otherQuestions, setOtherQuestions] = useState<any[]>([])
  const [propertyInfo, setPropertyInfo] = useState<IHostPropertyInput | undefined>(property)
  const [loading, setLoading] = useState(false)
  const {showSuccessDialog, showSuccessPropertyDialog, showFailureDialog} = useDialog()
  const [bedDisabled, setBedDisabled] = useState(property?.cleaningProviderId === 2 ? true : false)
  const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(
    property?.numberOfBathrooms ? property?.numberOfBathrooms : 1
  )
  const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(
    property?.numberOfBedrooms ? property?.numberOfBedrooms : 0
  )
  const [selectedNumberOfBeds, setSelectedNumberOfBeds] = useState<number>(
    property?.numberOfBeds ? property?.numberOfBeds : 0
  )
  const [selectedHostPropertyBedrooms, setSelectedHostPropertyBedrooms] = useState<
    IHostPropertyBedroom[]
  >(
    property?.hostPropertyBedrooms && property.hostPropertyBedrooms.length > 0
      ? property?.hostPropertyBedrooms
      : []
  )

  const {data: cleanerProvidersData, error: cleanerProviderError} = useQuery(
    'Cleaner Providers',
    () => ApiCalls.getCleaningProviders(),
    {cacheTime: 500000, retry: true, refetchOnWindowFocus: false}
  )
  const {data: accessTypesData, error: accessTypesError} = useQuery(
    'Unit Access Types',
    () => ApiCalls.getAccessTypes(),
    {cacheTime: 500000, retry: true, refetchOnWindowFocus: false}
  )
  const {data: otherQuestionData, error: otherQuestionError} = useQuery(
    'Other Questions',
    () => ApiCalls.getHostPropertyOtherQuestion(),
    {cacheTime: 500000, retry: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (cleanerProvidersData) {
      let list: any[] = []
      for (const type of cleanerProvidersData?.data?.data) {
        if (propertyInfo?.cleaningProviderId != 2) {
          if (type.id != 2) {
            list.push({label: type.name, value: type.id})
          }
        }
      }
      setCleanerProviders(list)
    }
  }, [cleanerProvidersData])

  useEffect(() => {
    if (accessTypesData) {
      setAccessTypes(
        accessTypesData?.data?.data?.map((type: any) => ({label: type.name, value: type.id}))
      )
    }
  }, [accessTypesData])

  useEffect(() => {
    if (otherQuestionData) {
      setOtherQuestions(otherQuestionData?.data?.data)
    }
  }, [otherQuestionData])

  const onBedroomChange = (index: number, hostPropertyBedroom: IHostPropertyBedroom) => {
    setSelectedHostPropertyBedrooms(
      selectedHostPropertyBedrooms.map((bedroom, currentIndex) =>
        currentIndex === index ? hostPropertyBedroom : bedroom
      )
    )
  }

  useEffect(() => {
    if (
      selectedNumberOfBedRooms > 0 &&
      (!property?.hostPropertyBedrooms || property?.hostPropertyBedrooms?.length === 0)
    ) {
      let arr: IHostPropertyBedroom[] = []
      let obj: IHostPropertyBedroom = {numberOfBeds: 1}
      for (let i = 0; i < selectedNumberOfBedRooms; i++) {
        arr.push(obj)
      }
      setSelectedHostPropertyBedrooms(arr)
    }
  }, [selectedNumberOfBedRooms])

  useEffect(() => {
    let count = 0
    selectedHostPropertyBedrooms.forEach((room) => {
      count += room?.numberOfBeds ? room?.numberOfBeds : 0
    })
    setPropertyInfo({
      ...propertyInfo,
      numberOfBedrooms: selectedNumberOfBedRooms,
      numberOfBathrooms: selectedNumberOfBathRooms,
      numberOfBeds: selectedNumberOfBedRooms ? count : selectedNumberOfBeds,
      hostPropertyBedrooms: selectedHostPropertyBedrooms,
    })
  }, [
    selectedNumberOfBathRooms,
    selectedNumberOfBedRooms,
    selectedNumberOfBeds,
    selectedHostPropertyBedrooms,
  ])

  const onSuiteChange = (e: any) => {
    setPropertyInfo({...propertyInfo, suite: e.target.value})
  }

  const onProviderChange = (value: number) => {
    setPropertyInfo({...propertyInfo, cleaningProviderId: value})
  }

  const unitAccessIdChange = (value: number) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAccess: {...propertyInfo?.hostPropertyAccess, unitAccessTypeId: value},
    })
  }

  const buildingAccessIdChange = (value: number) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAccess: {...propertyInfo?.hostPropertyAccess, buildingAccessTypeId: value},
    })
  }

  const unitAccessTextChange = (value: string) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAccess: {...propertyInfo?.hostPropertyAccess, unitAccessValue: value},
    })
  }

  const buildingAccessTextChange = (value: string) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAccess: {...propertyInfo?.hostPropertyAccess, buildingAccessValue: value},
    })
  }

  const storageTextChange = (value: any) => {
    setPropertyInfo({...propertyInfo, hostPropertyStorages: [{storageDescription: value}]})
  }

  const laundryMachineCycleChange = (value: string) => {
    setPropertyInfo({...propertyInfo, laundryMachineCycle: +value})
  }

  const laundryDryerCycleChange = (value: string) => {
    setPropertyInfo({...propertyInfo, laundryDryerCycle: +value})
  }

  const laundryLinenNumberChange = (value: string) => {
    setPropertyInfo({...propertyInfo, laundryLinenNumber: value})
  }

  const trashDaysChange = (value: string) => {
    setPropertyInfo({...propertyInfo, trashDays: value})
  }

  const isPropertyValid = () => {
    if (
      !propertyInfo?.address ||
      !propertyInfo?.zipCode ||
      !propertyInfo?.latitude ||
      !propertyInfo?.longitude ||
      !propertyInfo?.country ||
      !propertyInfo?.state ||
      !propertyInfo?.city
    ) {
      showFailureDialog('Please fill Location information')
      return false
    }

    if (propertyInfo.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          propertyInfo?.hostPropertyAutomation?.checkInTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          propertyInfo?.hostPropertyAutomation?.checkInTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          propertyInfo?.hostPropertyAutomation?.checkOutTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          propertyInfo?.hostPropertyAutomation?.checkOutTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (propertyInfo?.cleaningProviderId == 2 || propertyInfo?.cleaningProviderId == 3) {
      if (
        !propertyInfo?.hostPropertyAccess?.unitAccessTypeId ||
        !propertyInfo?.hostPropertyAccess?.unitAccessValue ||
        !propertyInfo?.hostPropertyAccess?.buildingAccessTypeId ||
        !propertyInfo?.hostPropertyAccess?.buildingAccessValue
      ) {
        showFailureDialog('Please fill access informations')
        return false
      }
    }

    if (
      propertyInfo.hasStorage &&
      (propertyInfo.hostPropertyStorages?.length === 0 ||
        !propertyInfo.hostPropertyStorages?.[0].storageDescription ||
        propertyInfo.hostPropertyStorages?.[0].storageDescription === '')
    ) {
      showFailureDialog('Please provide at least one storage description')
      return false
    }

    if (
      propertyInfo.hasParking &&
      (!propertyInfo.parkingDescription || propertyInfo.parkingDescription === '')
    ) {
      showFailureDialog('Please enter parking description.')
      return false
    }

    return true
  }

  const handleUpdateProperty = async () => {
    if (isPropertyValid() && propertyId) {
      setLoading(true)
      let name =
        (propertyInfo?.address ? propertyInfo?.address : '') +
        (propertyInfo?.suite ? '#' + propertyInfo?.suite : '')
      const requestData: IHostPropertyInput = {
        ...propertyInfo,
        name,
        hostId: user.userSection.hostId,
        cleaningProviderId:
          propertyInfo?.cleaningProviderId == 4 ? 2 : propertyInfo?.cleaningProviderId,
        isMarketplace: propertyInfo?.cleaningProviderId == 4 ? true : false,
        numberOfCleaner: 0,
        propertySize: propertyInfo?.propertySize != undefined ? +propertyInfo.propertySize : 0,
        parkingDescription: propertyInfo?.hasParking ? propertyInfo.parkingDescription : undefined,
        hasParking: propertyInfo?.hasParking !== undefined ? propertyInfo?.hasParking : false,
        trashDays: propertyInfo?.trashDays ? propertyInfo.trashDays : undefined,
        hasTrashDisposal:
          propertyInfo?.hasTrashDisposal !== undefined ? propertyInfo?.hasTrashDisposal : false,
        hasDryer: propertyInfo?.hasLaundry
          ? propertyInfo?.hasDryer !== undefined
            ? propertyInfo?.hasDryer
            : false
          : false,
        hasLaundry: propertyInfo?.hasLaundry !== undefined ? propertyInfo?.hasLaundry : false,
        hasStorage: propertyInfo?.hasStorage !== undefined ? propertyInfo?.hasStorage : false,
        sameDayBooking:
          propertyInfo?.sameDayBooking !== undefined ? propertyInfo?.sameDayBooking : false,
        numberOfBedrooms: propertyInfo?.numberOfBedrooms ? propertyInfo?.numberOfBedrooms : 0,
        numberOfBathrooms: propertyInfo?.numberOfBathrooms ? propertyInfo?.numberOfBathrooms : 0,
        numberOfBeds: propertyInfo?.numberOfBeds ? propertyInfo?.numberOfBeds : 0,
        hostPropertyStorages: propertyInfo?.hasStorage
          ? propertyInfo?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          propertyInfo?.hostPropertyAccess &&
          Object.keys(propertyInfo?.hostPropertyAccess).length > 0
            ? propertyInfo.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...propertyInfo?.hostPropertyAutomation,
          isAutomaticSchedule:
            propertyInfo?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? propertyInfo?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //propertyInfo?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? propertyInfo?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS:
            propertyInfo?.hostPropertyAutomation?.usePMS !== undefined
              ? propertyInfo?.hostPropertyAutomation?.usePMS
              : false,
          checkInTime: propertyInfo?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: propertyInfo?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !propertyInfo?.hostPropertyAutomation?.usePMS
          ? propertyInfo?.hostPropertyICals
          : [],
      }
      ApiCalls.updateHostProperty(propertyId, requestData)
        .then((res) => {
          setLoading(false)
          refetch()
          showSuccessDialog('Property informations successfully updated.')
        })
        .catch((err) => {
          setLoading(false)
          if (
            err?.response?.data?.errors?.[0]?.message == 'icalUrl must be an URL address' ||
            err?.response?.data?.message == 'icalUrl must be an URL address'
          ) {
            showFailureDialog(
              'Error: Invalid iCal URL.',
              'Please enter a valid URL for the iCal feed. This should be a direct link to your calendar, ending with the file extension ".ics". Make sure there are no typos or extra characters in the URL.'
            )
          } else {
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          }
        })
    } else {
      showFailureDialog('Please fill all necessary fields')
    }
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title fs-3 fw-bold'>Property Informations</div>
      </div>
      <div className='card-body p-9'>
        <form className='form'>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Address</div>
            </div>
            <div className='col-xl-9 fv-row'>
              <input
                value={propertyInfo?.address}
                onChange={() => {}}
                disabled={true}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Suite/Apt #</div>
            </div>
            <div className='col-xl-9 fv-row'>
              <input
                value={propertyInfo?.suite}
                onChange={onSuiteChange}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Country / State / City</div>
            </div>
            <div className='col-xl-3 fv-row'>
              <input
                value={propertyInfo?.country}
                onChange={() => {}}
                disabled={true}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-3 fv-row'>
              <input
                value={propertyInfo?.state}
                onChange={() => {}}
                disabled={true}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-3 fv-row'>
              <input
                value={propertyInfo?.city}
                onChange={() => {}}
                disabled={true}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Zip Code</div>
            </div>
            <div className='col-xl-9 fv-row'>
              <input
                value={propertyInfo?.zipCode}
                onChange={() => {}}
                disabled={true}
                className='form-control'
                type='text'
                autoComplete='off'
              />
            </div>
          </div>
          {/* <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Service Provider</div>
            </div>
            <div className='col-xl-9 fv-row'>
              <SelectInput
                disabled={propertyInfo?.cleaningProviderId == 2}
                options={cleanerProviders}
                classNames='mt-3'
                onValueChanged={onProviderChange}
                defaultValue={propertyInfo?.cleaningProviderId}
              />
            </div>
          </div> */}
          <div className='row border-bottom mb-5 pb-5'></div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Access Unit</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your unit access process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                How do your cleaners access the unit? <span className='text-danger'>*</span>
              </label>
              <small className='text-muted col-lg-12 mb-3'>
                This information will be kept strictly confidential and will not be shared with
                anyone except the assigned cleaner(s). The access information will only be made
                available to the cleaner on the day of cleaning to ensure the highest level of
                security.
              </small>
              <div className='row mt-3'>
                <div className='col-xl-3'>
                  <SelectInput
                    options={accessTypes}
                    onValueChanged={unitAccessIdChange}
                    defaultValue={propertyInfo?.hostPropertyAccess?.unitAccessTypeId}
                  />
                  {!propertyInfo?.hostPropertyAccess?.unitAccessTypeId && (
                    <strong className='text-danger'>Missing Field</strong>
                  )}
                </div>
                <div className='col-xl-9'>
                  <TextInput
                    onValueChange={unitAccessTextChange}
                    defaultValue={propertyInfo?.hostPropertyAccess?.unitAccessValue}
                  />
                  {!propertyInfo?.hostPropertyAccess?.unitAccessValue && (
                    <strong className='text-danger'>Missing Field</strong>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Access Building</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your building access process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                How do your cleaners access the building? <span className='text-danger'>*</span>
              </label>
              <small className='text-muted col-lg-12 mb-3'>
                This information will be kept strictly confidential and will not be shared with
                anyone except the assigned cleaner(s). The access information will only be made
                available to the cleaner on the day of cleaning to ensure the highest level of
                security.
              </small>
              <div className='row mt-3'>
                <div className='col-xl-3'>
                  <SelectInput
                    options={accessTypes}
                    onValueChanged={buildingAccessIdChange}
                    defaultValue={propertyInfo?.hostPropertyAccess?.buildingAccessTypeId}
                  />
                  {!propertyInfo?.hostPropertyAccess?.buildingAccessTypeId && (
                    <strong className='text-danger'>Missing Field</strong>
                  )}
                </div>
                <div className='col-xl-9'>
                  <TextInput
                    onValueChange={buildingAccessTextChange}
                    defaultValue={propertyInfo?.hostPropertyAccess?.buildingAccessValue}
                  />
                  {!propertyInfo?.hostPropertyAccess?.buildingAccessValue && (
                    <strong className='text-danger'>Missing Field</strong>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Laundry</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your laundry process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Do you have loundry? <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-12'>
                <SelectInput
                  options={booleanOptions}
                  onValueChanged={(value) =>
                    setPropertyInfo({...propertyInfo, hasLaundry: value === 1 ? true : false})
                  }
                  defaultValue={propertyInfo?.hasLaundry ? 1 : 2}
                />
              </div>
              {propertyInfo?.hasLaundry && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    Do you have a dryer?
                  </label>
                  <div className='col-lg-12'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setPropertyInfo({...propertyInfo, hasDryer: value === 1 ? true : false})
                      }
                      defaultValue={propertyInfo?.hasDryer ? 1 : 2}
                    />
                  </div>
                </>
              )}
              <>
                <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                  Do you need laundry service to be included your quote?{' '}
                  <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-12'>
                  <SelectInput
                    options={booleanOptions}
                    onValueChanged={(value) =>
                      setPropertyInfo({
                        ...propertyInfo,
                        laundryInQuote: value === 1 ? true : false,
                      })
                    }
                    defaultValue={propertyInfo?.laundryInQuote ? 1 : 2}
                  />
                </div>
              </>
              {propertyInfo?.laundryInQuote && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    Do you want the laundry to be done in the promises?{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <div className='col-lg-12'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setPropertyInfo({
                          ...propertyInfo,
                          laundryToBeDone: value === 1 ? true : false,
                        })
                      }
                      defaultValue={propertyInfo?.laundryToBeDone ? 1 : 2}
                    />
                  </div>
                </>
              )}
              {propertyInfo?.laundryToBeDone && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    How many cycles does it take for your washing machine and dryer to wash your
                    linens in a regular turnover? <span className='text-danger'>*</span>
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 mb-3'>
                      <TextInput
                        onValueChange={laundryMachineCycleChange}
                        defaultValue={propertyInfo?.laundryMachineCycle}
                        type='number'
                        placeholder='Laundry Machine (Counter)'
                      />
                      {propertyInfo.laundryToBeDone && propertyInfo?.laundryMachineCycle == 0 && (
                        <strong className='text-danger'>Missing field</strong>
                      )}
                    </div>
                    <div className='col-lg-12 mb-3'>
                      <TextInput
                        onValueChange={laundryDryerCycleChange}
                        defaultValue={propertyInfo?.laundryDryerCycle}
                        type='number'
                        placeholder='Dryer (Counter)'
                      />
                      {propertyInfo.laundryToBeDone && propertyInfo?.laundryDryerCycle == 0 && (
                        <strong className='text-danger'>Missing field</strong>
                      )}
                    </div>
                  </div>
                </>
              )}
              {propertyInfo?.laundryInQuote && !propertyInfo?.laundryToBeDone && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    Please provide the number of your linens for each turnover{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <div className='col-lg-12'>
                    <TextInput
                      onValueChange={laundryLinenNumberChange}
                      defaultValue={propertyInfo?.laundryLinenNumber}
                      type='text'
                      placeholder='King Sheet / Queen Sheet / Twin Sheet etc.'
                    />
                    {!propertyInfo.laundryToBeDone && propertyInfo?.laundryLinenNumber == '' && (
                      <strong className='text-danger'>Missing field</strong>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Storage</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your storage process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Do you have a storage? <span className='text-danger'>*</span>
              </label>
              {propertyInfo?.hasStorage && (
                <small className='text-muted mb-3'>
                  Please give more information about the location and access details of your storage
                  for the staff.
                </small>
              )}
              <div className='row'>
                <div className='col-lg-3'>
                  <SelectInput
                    options={booleanOptions}
                    onValueChanged={(value) =>
                      setPropertyInfo({...propertyInfo, hasStorage: value === 1 ? true : false})
                    }
                    defaultValue={propertyInfo?.hasStorage ? 1 : 2}
                  />
                </div>
                <div className='col-lg-9'>
                  {propertyInfo?.hasStorage && (
                    <TextInput
                      onValueChange={storageTextChange}
                      defaultValue={
                        propertyInfo?.hostPropertyStorages &&
                        propertyInfo?.hostPropertyStorages.length > 0
                          ? propertyInfo?.hostPropertyStorages[0].storageDescription
                          : ''
                      }
                    />
                  )}
                  {propertyInfo?.hasStorage &&
                    ((propertyInfo?.hostPropertyStorages &&
                      propertyInfo?.hostPropertyStorages?.length > 0 &&
                      propertyInfo?.hostPropertyStorages[0].storageDescription === '') ||
                      (propertyInfo?.hostPropertyStorages &&
                        propertyInfo?.hostPropertyStorages?.length == 0)) && (
                      <strong className='text-danger'>Missing field</strong>
                    )}
                </div>
              </div>
              {propertyInfo?.hasStorage && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    Do you need the storage service to be included in your quote?{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <div className='col-lg-12'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setPropertyInfo({
                          ...propertyInfo,
                          storageInQuote: value === 1 ? true : false,
                        })
                      }
                      defaultValue={propertyInfo.storageInQuote ? 1 : 2}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Trash Disposal</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your trash disposal process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Do you need us to take the trash out to the curb in specific days of the week?{' '}
                <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-12'>
                <SelectInput
                  options={booleanOptions}
                  onValueChanged={(value) =>
                    setPropertyInfo({
                      ...propertyInfo,
                      hasTrashDisposal: value === 1 ? true : false,
                    })
                  }
                  defaultValue={propertyInfo?.hasTrashDisposal ? 1 : 2}
                />
              </div>
              {propertyInfo?.hasTrashDisposal && (
                <>
                  <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12 mt-5'>
                    Which days? <span className='text-danger'>*</span>
                  </label>
                  <div className='col-lg-12'>
                    <TextInput
                      onValueChange={trashDaysChange}
                      defaultValue={propertyInfo?.trashDays}
                      placeholder='Day List / Hours (Before)'
                    />
                    {propertyInfo?.hasTrashDisposal && propertyInfo?.trashDays === '' && (
                      <strong className='text-danger'>Missing field</strong>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Parking</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your parking process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Do you have parking?
              </label>
              {propertyInfo?.hasParking && (
                <small className='text-muted mb-3'>
                  Please give more information about the parking
                </small>
              )}
              <div className='row'>
                <div className='col-lg-3'>
                  <SelectInput
                    options={booleanOptions}
                    onValueChanged={(value) =>
                      setPropertyInfo({...propertyInfo, hasParking: value === 1 ? true : false})
                    }
                    defaultValue={propertyInfo?.hasParking ? 1 : 2}
                  />
                </div>
                <div className='col-lg-9'>
                  {propertyInfo?.hasParking && (
                    <>
                      <TextInput
                        onValueChange={(text) =>
                          setPropertyInfo({...propertyInfo, parkingDescription: text})
                        }
                        defaultValue={propertyInfo.parkingDescription}
                        placeholder='Please provide information on how to use the parking service (Paid or free etc.)'
                      />
                      {propertyInfo.hasParking &&
                        (!propertyInfo.parkingDescription ||
                          propertyInfo.parkingDescription === '') && (
                          <strong className='text-danger'>Missing Field</strong>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Property Size</div>
              <div>
                <small className='text-muted'>
                  Provide additional information about your property size process
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Please enter the size of your property (sqft.)
              </label>
              <input
                value={propertyInfo?.propertySize}
                onChange={(e) => {
                  setPropertyInfo({...propertyInfo, propertySize: +e.target.value})
                }}
                className='form-control'
                min={0}
                max={10000}
                step={0.1}
                type='number'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Bedrooms / Bathrooms</div>
              <div>
                <small className='text-muted'>
                  Provide the number of bedrooms / bathrooms in your property
                </small>
              </div>
            </div>
            <div className='col-xl-9 fv-row'>
              <div className='row mt-3'>
                <div className='col-xl-4 mb-3'>
                  <div className='fv-row mb-8 fv-plugins-icon-container'>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Number of Bedrooms</span>
                    </label>
                    <div className='position-relative w-lg-250px'>
                      {!bedDisabled && (
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                          onClick={() => {
                            setSelectedNumberOfBedRooms(
                              selectedNumberOfBedRooms > 0 ? selectedNumberOfBedRooms - 1 : 0
                            )
                            if (selectedHostPropertyBedrooms.length > 1) {
                              setSelectedHostPropertyBedrooms(
                                selectedHostPropertyBedrooms.filter(
                                  (bedroom, index) =>
                                    index !== selectedHostPropertyBedrooms.length - 1
                                )
                              )
                            } else {
                              setSelectedHostPropertyBedrooms([])
                            }
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Code/Minus.svg'
                            className='svg-icon-2x mt-1'
                          />
                        </button>
                      )}
                      <input
                        type='text'
                        className='form-control form-control-solid border-0 text-center'
                        placeholder='Number of Bedrooms'
                        readOnly={true}
                        value={selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}
                      />
                      {!bedDisabled && (
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                          onClick={() => {
                            let hostPropertyBedroom: IHostPropertyBedroom = {numberOfBeds: 1}
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                            setSelectedHostPropertyBedrooms([
                              ...selectedHostPropertyBedrooms,
                              hostPropertyBedroom,
                            ])
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Code/Plus.svg'
                            className='svg-icon-2x mt-1'
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 mb-3'>
                  <div className='fv-row mb-8 fv-plugins-icon-container'>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span className='required'>Number of Bathrooms</span>
                    </label>
                    <div className='position-relative w-lg-250px'>
                      {!bedDisabled && (
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                          onClick={() => {
                            if (selectedNumberOfBathRooms > 1) {
                              setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 0.5)
                            } else if (selectedNumberOfBathRooms === 1) {
                              setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 1)
                            }
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Code/Minus.svg'
                            className='svg-icon-2x mt-1'
                          />
                        </button>
                      )}
                      <input
                        type='text'
                        className='form-control form-control-solid border-0 text-center'
                        placeholder='Number of Bathrooms'
                        readOnly={true}
                        value={selectedNumberOfBathRooms}
                      />
                      {!bedDisabled && (
                        <button
                          type='button'
                          className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                          onClick={() => {
                            if (selectedNumberOfBathRooms === 0) {
                              setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 1)
                            } else {
                              setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 0.5)
                            }
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Code/Plus.svg'
                            className='svg-icon-2x mt-1'
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {!selectedNumberOfBedRooms ? null : (
                  <div className='col-xl-12 mb-3'>
                    <div className='fv-row mb-8 fv-plugins-icon-container'>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span className='required'>Number of Beds</span>
                      </label>
                      <div className='position-relative w-lg-250px'>
                        {!bedDisabled && (
                          <button
                            type='button'
                            className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
                            onClick={() =>
                              setSelectedNumberOfBeds(
                                selectedNumberOfBeds > 0 ? selectedNumberOfBeds - 1 : 0
                              )
                            }
                          >
                            <KTSVG
                              path='/media/icons/duotone/Code/Minus.svg'
                              className='svg-icon-2x mt-1'
                            />
                          </button>
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid border-0 text-center'
                          placeholder='Number of Beds'
                          readOnly={true}
                          value={selectedNumberOfBeds}
                        />
                        {!bedDisabled && (
                          <button
                            type='button'
                            className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
                            onClick={() => setSelectedNumberOfBeds(selectedNumberOfBeds + 1)}
                          >
                            <KTSVG
                              path='/media/icons/duotone/Code/Plus.svg'
                              className='svg-icon-2x mt-1'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='card-footer d-flex justify-content-end'>
        <button className='btn btn-success' disabled={loading} onClick={handleUpdateProperty}>
          Save Changes {loading ? <Spinner size='sm' animation='border' /> : null}
        </button>
      </div>
    </div>
  )
}

export default Information
