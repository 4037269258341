import {IOnBoarding} from '../../interfaces/OnBoarding'

export const GET_ON_BOARDING_INFO = 'GET_ON_BOARDING_INFO'
export interface GetOnBoardingInfo {
  type: typeof GET_ON_BOARDING_INFO
}

export const GET_ON_BOARDING_INFO_SUCCESS = 'GET_ON_BOARDING_INFO_SUCCESS'
export interface GetOnBoardingInfoSuccess {
  type: typeof GET_ON_BOARDING_INFO_SUCCESS
  onBoardingInfo: IOnBoarding
  propertyComplete: boolean
  checklistComplete: boolean
  staffComplete: boolean
  staffServiceComplete: boolean
  staffVisible: boolean
  hasPaymentCard: boolean
}

export const GET_ON_BOARDING_INFO_FAILURE = 'GET_ON_BOARDING_INFO_FAILURE'
export interface GetOnBoardingInfoFailure {
  type: typeof GET_ON_BOARDING_INFO_FAILURE
}

export const SET_ON_BOARDING_COMPLETE = 'SET_ON_BOARDING_COMPLETE'
export interface SetOnBoardingComplete {
  type: typeof SET_ON_BOARDING_COMPLETE
  isCompleted: boolean
}

export type OnBoardingAction =
  | GetOnBoardingInfo
  | GetOnBoardingInfoSuccess
  | GetOnBoardingInfoFailure
  | SetOnBoardingComplete
