import { Dispatch, useEffect } from "react";
import { Card } from "react-bootstrap-v5"
import { useParams } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core"
import StaffTable from "../staff/StaffTable"
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators';
import { useDispatch, useSelector } from "react-redux";
import { StaffAction } from "../../redux/actionTypes/staffActionTypes";
import { decryptText } from "../../utils/util";
import { RedusxAppState } from "../../redux/reducers/rootReducer";
import ManagersDialog from "./ManagersDialog";
import ManagerTable from "./ManagerTable";

const Managers = () => {

      const { user } = useSelector((state: RedusxAppState) => state.user);
      const { staffList, listLoading } = useSelector((state: RedusxAppState) => state.staff);
      const dispatch = useDispatch<Dispatch<StaffAction>>();
  
      useEffect(() => {
          dispatch(staffActionCreators.getStaffList(user.userSection.hostId,2));
      }, [])

      
      return <div>
            <PageTitle>Managers</PageTitle>
            <div className='row g-5 g-xl-8'>
                  <Card className="shadow">
                        <Card.Body >
                              <ManagerTable />
                        </Card.Body>
                        {staffList.length > 0 && 
                        <Card.Footer className="d-flex align-items-center justify-content-between">
                              <div />
                              <button className="btn btn-success" style={{ height: '100%' }} onClick={() => dispatch(staffActionCreators.openStaffAddDialog(null))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Add Managers
                              </button>
                        </Card.Footer>
                        }
                  </Card>
            </div>
            <ManagersDialog />
      </div>
}

export default Managers