import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { IBillingDetail, IDetail } from '../../../interfaces/Billing';
import ApiCalls from '../../../network/ApiCalls';
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading';
import PdfPrinter from '../../../umut-components/PdfPrinter/PdfPrinter';
import { decryptText } from '../../../utils/util';

type BillingItemProps = {
    detail: IDetail,
    hostPropertyName: string,
    billingDate: string,
}

const BillingItem: React.FC<BillingItemProps> = ({ detail, hostPropertyName, billingDate }) => {
    return <div className={'row p-3 rounded'}>
        <div className='col-6 d-flex flex-column'>
            <small>{detail.serviceTypeName}</small>
            <small >{dayjs(billingDate).format("MM/DD/YYYY")}</small>

            <small >({hostPropertyName})</small>

        </div>
        <div className='col-2'>
            <small>${detail.unitPrice}</small>
        </div>
        <div className='col-2'>
            <small>{detail.quantity}</small>
        </div>
        <div className='col-2'>
            <small>${detail.unitPrice && detail.quantity ? detail.unitPrice * detail.quantity : null}</small>
        </div>
    </div>
}

const BillingDetail: React.FC = () => {
    const ref = useRef(null);
    const history = useHistory();

    let { billingId }: any = useParams();
    billingId = decryptText(billingId);

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { data: billingDetailData, isLoading: billingDetailLoading, error: billingDetailError } = useQuery<AxiosResponse<IBillingDetail>>(['Billing Detail ', billingId], () => ApiCalls.getBillingDetails(billingId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: billingId ? true : false });

    const closeModal = () => {
        history.push('/billings');
    }

    const chargeInvoice = async () => {
        try {
            setLoading(true)
            await ApiCalls.postInvoiceCharge(billingId)
            closeModal()
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setErrorMessage(error?.response?.data.message)
        }
    }
    useEffect(() => {
        setModalOpen(billingId ? true : false);
        setErrorMessage(null)
    }, [billingId])



    return (
        <Modal show={modalOpen} onHide={closeModal} size="lg" centered>
            <Modal.Body>
                {
                    billingDetailLoading ? <SkeletonTableLoading /> :
                        <div className='ps-6' ref={ref}>
                            <div className='row'>
                                <div className='col-6 d-flex flex-column'>
                                    <img alt='turnify' style={{ width: 200 }} src={toAbsoluteUrl('/media/logos/turnify-logo.png')} />
                                    <strong className='mt-3'>TidyHost</strong>
                                    <small className='mt-3'>150 Orchard Court, Blue Bell, PA 19422 <br />
                                        concierge@turnify.com <br />
                                        (833)TDY-HOST
                                    </small>
                                    <strong className='mt-3'>Invoice Number</strong>
                                    <small>{billingDetailData?.data.invoiceNumber}</small>
                                </div>
                                <div className='col-6 d-flex flex-column align-items-end'>
                                    <small className='badge badge-primary mt-3' style={{ width: 'fit-content' }}>{billingDetailData?.data.hostInvoiceStatusTypeName}</small>
                                </div>
                            </div>
                            <div className='row mt-6 p-3 bg-light rounded d-flex flex-column'>
                                <strong>TO: </strong>
                                <small>{billingDetailData?.data.nameSurname}</small>
                                <small >{billingDetailData?.data.billingEmail}</small>
                                <small >{billingDetailData?.data.billingAddress}</small>
                            </div>
                            <div className='row mt-6 p-3 rounded bg-light'>
                                <div className='col-6'>
                                    <strong>Description</strong>
                                </div>
                                <div className='col-2'>
                                    <strong>Unit Price</strong>
                                </div>
                                <div className='col-2'>
                                    <strong>QTY</strong>
                                </div>
                                <div className='col-2'>
                                    <strong>Total</strong>
                                </div>
                            </div>
                            {
                                billingDetailData?.data.details.map(detail => <BillingItem detail={detail} hostPropertyName={billingDetailData.data.hostPropertyName} billingDate={billingDetailData.data.billingDate} />)
                            }
                            <div className='row p-3 rounded bg-light'>
                                <div className='col-6' />
                                <div className='col-2' />
                                <div className='col-2'>
                                    <strong>Subtotal</strong>
                                </div>
                                <div className='col-2'>
                                    <small>${billingDetailData?.data.totalGrossAmount}</small>
                                </div>
                            </div>
                            <div className='row p-3 rounded'>
                                <div className='col-6' />
                                <div className='col-2' />
                                <div className='col-2'>
                                    <strong>Tax</strong>
                                </div>
                                <div className='col-2'>
                                    <small>${billingDetailData?.data.totalTaxAmount}</small>
                                </div>
                            </div>
                            <div className='row p-3 rounded bg-light'>
                                <div className='col-6' />
                                <div className='col-2' />
                                <div className='col-2'>
                                    <strong>Grand Total</strong>
                                </div>
                                <div className='col-2'>
                                    <small>${billingDetailData?.data.totalNetAmount}</small>
                                </div>
                            </div>
                        </div>
                }
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-end' style={{ backgroundColor: '#000033' }}>
                <div className="mx-auto">
                    {errorMessage && <p className="text-warning fs-5">{errorMessage}!</p>}
                </div>
                <div>
                    <button type='button' className='btn btn-link text-danger me-6' onClick={closeModal}>Close</button>
                    <PdfPrinter targetRef={ref} filename='billing.pdf'>
                        {({ toPdf }: any) => <button type='button' className='btn btn-warning' onClick={toPdf}>Download</button>}
                    </PdfPrinter>
                    {billingDetailData?.data.hostInvoiceStatusTypeId == 1 && <button type='button' disabled={loading} className='btn btn-success ms-3' onClick={chargeInvoice}>Pay {loading && <Spinner animation="border" size="sm" />}</button>}                </div>


            </Modal.Footer>
        </Modal>
    )
}

export default BillingDetail;