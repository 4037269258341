import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import StepWizard from 'react-step-wizard'
import StepNav from './AddWizard/StepNav'
import PropertySelect from './AddWizard/PropertySelect'
import PropertyDetail from './AddWizard/PropertyDetail'
import CleaningNeeds from './AddWizard/CleaningNeeds'
import {useDialog} from '../../contexts/DialogContext'
import {useHistory} from 'react-router-dom'

type TabNames = 'select-property' | 'confirm-details' | 'describe-needs'

interface MarketplaceRequest {
  hostId: number
  hostPropertyId: number
  address: string
  city: string
  state: string
  country: string
  latitude: number
  longitude: number
  suite: string
  zipCode: string
  numberOfBedrooms: number
  numberOfBathrooms: number
  propertySize: number
  cleaningDuration: number
  jobsPerMonth: number
  hostProvideCleaningSupplies: boolean
  cleanLinenAndTowel: boolean
  offSiteCleanLinenAndTowel: boolean
  requestedServices: []
  note: string
  createdBy: number
}

const NewMarketplaceRequest = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)

  const history = useHistory()
  const {showFailureDialog, showSuccessDialog, openLoadingModal} = useDialog()
  const [activeStep, setActiveStep] = useState(1)
  const [keyId, setKeyId] = useState(1)
  const [selectedTab, setSelectedTab] = useState<TabNames>('select-property')
  const [savedValues, setSavedValues] = useState<any>(null)
  const [propertyAddress, setPropertyAddress] = useState<string>('')
  const [missingField, setMissingField] = useState<boolean>(false)
  const [selectedPropertyId, setSelectedPropertyId] = useState(0)

  const setCurrentStep = (d: any) => {
    setActiveStep(d.activeStep)
    setKeyId((keyId + 1) * d.activeStep)
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  const handleChange = (changedValues: any) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  const handlePropertySelect = (address: string) => {
    setPropertyAddress(address)
  }

  useEffect(() => {
    if (activeStep == 3) {
      handleCreateRequest()
    }
    if (activeStep == 1 && savedValues != null) {
      setSelectedPropertyId(savedValues.hostPropertyId)
    }
  }, [savedValues])

  const isValid = () => {
    if (
      savedValues.propertySize <= 0 ||
      savedValues.propertySize == undefined ||
      savedValues.propertySize == ''
    ) {
      showFailureDialog('Please specify property size')
      return false
    } else {
      return true
    }
  }

  const handleCreateRequest = () => {
    if (isValid()) {
      openLoadingModal('Please wait...')
      var request: MarketplaceRequest = {
        ...savedValues,
        createdBy: user.user.id,
        hostId: user.userSection.hostId,
        isActive: true,
      }

      ApiCalls.createMarketplaceRequest(request)
        .then((res: any) => {
          if (res?.data?.result) {
            showSuccessDialog('Your request is successfully created.')
            history.push('/marketplace')
          } else {
            showFailureDialog('There is an error occur during creating Marketplace request')
          }
        })
        .catch((err) => {
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        })
    }
  }

  return (
    <>
      <PageTitle>
        {propertyAddress != '' ? 'Marketplace : ' + propertyAddress : 'Marketplace'}
      </PageTitle>
      <div className='pt-3'>
        <Card>
          <Card.Body>
            <StepWizard
              onStepChange={setCurrentStep}
              nav={
                <StepNav
                  stepNames={[
                    'Select Property',
                    'Verify Property Information',
                    'Provide Cleaning Details',
                  ]}
                />
              }
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
              }}
            >
              <PropertySelect
                missingField={missingField}
                handlePropertySelect={handlePropertySelect}
                handleChange={handleChange}
                goToStep={handleChangeTab}
                currentStep={selectedTab}
              />
              <PropertyDetail
                missingField={missingField}
                handleChange={handleChange}
                goToStep={handleChangeTab}
                currentStep={selectedTab}
                propertyId={selectedPropertyId}
              />
              <CleaningNeeds
                missingField={missingField}
                handleChange={handleChange}
                goToStep={handleChangeTab}
                currentStep={selectedTab}
                propertyId={selectedPropertyId}
              />
            </StepWizard>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default NewMarketplaceRequest
