import dayjs from 'dayjs'
import {useEffect, useState} from 'react'
import {encryptText, weekdays} from '../../../utils/util'
import ApiCalls from '../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import * as jobActionCreator from '../../../redux/actionCreators/jobActionCreators'
import {KTSVG} from '../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'

const dayRanges = [-2, -1, 0, 1, 2, 3, 4]

type Props = {
  isMobile: boolean
  visible: boolean
  handleClose: () => void
}

const DashCalendar: React.FC<Props> = ({isMobile, visible, handleClose}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [jobList, setJobList] = useState<any[]>([])
  const [filteredJobList, setFilteredJobList] = useState<any[]>([])
  const [scheduledJobCount, setScheduledJobCount] = useState(0)

  const {
    data: weeklyJobs,
    isLoading: weeklyJobsLoading,
    error: weeklyJobsError,
    refetch: weeklyJobsGroup,
  } = useQuery('Get Today Job Summary', () => ApiCalls.weeklyJobs(user.userSection.hostId), {
    cacheTime: 500000,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (!weeklyJobsLoading && weeklyJobs) {
      if (weeklyJobs?.data && weeklyJobs?.data.length > 0) {
        const list: any[] = []
        for (const wj of weeklyJobs?.data) {
          var jobItem = {
            id: wj.id,
            jobStatusTypeId: wj.jobStatusTypeId,
            isLate: wj.isLateStart || wj.isLateEnd ? true : false,
            propertyName: wj.hostProperty.name,
            scheduledDate: dayjs(wj.scheduledStart).utc().format('YYYY-MM-DD'),
            scheduledStartTime: dayjs(wj.scheduledStart).utc().format('H:mm A'),
            scheduledEndTime: dayjs(wj.scheduledFinish).utc().format('H:mm A'),
            staff:
              wj.hostProperty.cleaningProviderId == 1
                ? wj.jobStaffs && wj.jobStaffs.length > 0
                  ? wj.jobStaffs[0].userInfo
                    ? wj.jobStaffs[0].userInfo.firstName +
                      ' ' +
                      wj.jobStaffs[0].userInfo.lastName.substring(0, 1) +
                      '.'
                    : null
                  : null
                : wj.jobStaffs && wj.jobStaffs.length > 0
                ? wj.jobStaffs[0].userInfo
                  ? wj.jobStaffs[0].userInfo.firstName +
                    ' ' +
                    wj.jobStaffs[0].userInfo.lastName.substring(0, 1) +
                    '.'
                  : null
                : null,
            serviceType: wj.jobServices[0].serviceType ? wj.jobServices[0].serviceType.name : null,
          }
          list.push(jobItem)
        }

        setJobList(list)
      }
    }
  }, [weeklyJobsLoading, weeklyJobs])

  useEffect(() => {
    if (jobList.length > 0) {
      var cloned = jobList
      var filter = jobList.filter((jl) => jl.scheduledDate == selectedDate)
      var scheduledJobs = filter.filter((fl) => fl.jobStatusTypeId > 1)
      setFilteredJobList(filter)
      setScheduledJobCount(scheduledJobs.length)
    }
  }, [jobList, selectedDate])

  const goToJobDetail = (jobid: any) => {
    window.open(`/job-details/` + encryptText(jobid))
  }

  return (
    <div className='card h-md-100 pt-md-0 pt-30'>
      <div className='card-header border-0 pt-5'>
        {isMobile && visible && (
          <div style={{position: 'absolute', top: -20, right: 10}} onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-3x me-2' />
          </div>
        )}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Today's Planner</span>

          <span className='text-muted mt-1 fw-semibold fs-7'>
            {scheduledJobCount > 0 ? scheduledJobCount : 'No'} Services Scheduled for Today
          </span>
        </h3>

        <div className='card-toolbar'>
          <a
            href='javascript:;'
            className='btn btn-sm btn-light'
            onClick={() => dispatch(jobActionCreator.openJobDialog())}
          >
            Create Job
          </a>
        </div>
      </div>

      <div className='card-body pt-7 px-0'>
        <ul
          className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'
          role='tablist'
        >
          {dayRanges.map((dr, ind) => {
            let weekday = weekdays[dayjs().add(dr, 'days').day()]
            let currDay = dayjs().add(dr, 'days').format('DD')
            return (
              <li className='nav-item p-0 ms-0' role='presentation'>
                <a
                  href='javascript:;'
                  onClick={() => {
                    setSelectedDate(dayjs().add(dr, 'days').format('YYYY-MM-DD'))
                  }}
                  className={
                    selectedDate == dayjs().add(dr, 'days').format('YYYY-MM-DD')
                      ? 'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger active'
                      : 'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger '
                  }
                >
                  <span className='fs-7 fw-semibold'>{weekday}</span>
                  <span className='fs-6 fw-bold'>{currDay}</span>
                </a>
              </li>
            )
          })}
        </ul>
        <div className='tab-content mb-2 px-9'>
          <div className='tab-pane fade active show' role='tabpanel'>
            {filteredJobList.length > 0 && (
              <>
                {filteredJobList.map((fj, index) => {
                  let statusBg = 'bg-light'
                  if (fj.jobStatusTypeId == 2) {
                    statusBg = 'bg-warning'
                  }
                  if (fj.jobStatusTypeId == 3) {
                    statusBg = 'bg-success'
                  }
                  if (fj.jobStatusTypeId == 5) {
                    statusBg = 'bg-primary'
                  }
                  if (fj.jobStatusTypeId == 6) {
                    statusBg = 'bg-info'
                  }
                  if (fj.isLate) {
                    statusBg = 'bg-danger'
                  }
                  return (
                    <div
                      className='d-flex align-items-center mb-6'
                      key={'job-info-' + index + '-' + fj.id}
                    >
                      <span
                        className={
                          'bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 ' +
                          statusBg
                        }
                      ></span>

                      <div className='flex-grow-1 me-5'>
                        <div className='text-gray-800 fw-semibold fs-2'>
                          {fj.scheduledStartTime + ' - ' + fj.scheduledEndTime}
                        </div>

                        <div className='text-gray-700 fw-semibold fs-6'>{fj.propertyName}</div>

                        <div className='text-gray-400 fw-semibold fs-7'>
                          {fj.serviceType} by
                          <a
                            href='javascript:;'
                            className='text-danger opacity-75-hover fw-semibold ms-2'
                          >
                            {fj.staff}
                          </a>
                        </div>
                      </div>

                      <a
                        href='javascript:;'
                        className='btn btn-sm btn-light'
                        onClick={() => {
                          goToJobDetail(fj.id)
                        }}
                      >
                        View
                      </a>
                    </div>
                  )
                })}
              </>
            )}
            {filteredJobList.length == 0 && (
              <div className='d-flex align-items-center mb-6' key={'job-info-empty'}>
                <span className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-dark'></span>

                <div className='flex-grow-1 me-5'>
                  <div className='text-gray-800 fw-semibold fs-2'>Quiet Day Today</div>

                  <div className='text-gray-700 fw-semibold fs-6'>No scheduled jobs</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashCalendar
