import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Accordion, AccordionCollapse, AccordionToggle, Button, Card, Spinner, useAccordionToggle } from 'react-bootstrap-v5';
import { usePropertyForm } from '../../../../contexts/PropertyFormContext';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import {
    IHostPropertyBedroom,
    IHostPropertyManualInput
} from '../../../../interfaces/Property';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem, Editing, Lookup, RequiredRule, Popup, Form } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import './style.css';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';

const dayOptions = [
    {
        label: 'Monday',
        value: 'Monday'
    },
    {
        label: 'Tuesday',
        value: 'Tuesday'
    },
    {
        label: 'Wednesday',
        value: 'Wednesday'
    },
    {
        label: 'Thursday',
        value: 'Thursday'
    },
    {
        label: 'Friday',
        value: 'Friday'
    },
    {
        label: 'Saturday',
        value: 'Saturday'
    },
    {
        label: 'Sunday',
        value: 'Sunday'
    }
]

const booleanOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
]

type Props = {
    selectedProPropertyList: IHostPropertyManualInput[],
    changeProPropertyList: (data: IHostPropertyManualInput[]) => void
}


const TurnifyProFormTable: React.FC<Props> = ({ selectedProPropertyList, changeProPropertyList }) => {
    const tableRef = useRef<DataGrid>(null);
    const { accessTypes } = usePropertyForm();
    const [tableData, setTableData] = useState<any>([]);

    if (selectedProPropertyList?.length === 0) {
        return <EmptyTable type='property' />
    }

    useEffect(() => {
        var array: any = [];
        if(selectedProPropertyList.length > 0) {
            selectedProPropertyList.forEach((sp, i) => {
                var item = {
                    address: sp.address,
                    city: sp.city,
                    accessUnit: sp.hostPropertyAccess?.unitAccessTypeId == 0 ? null : sp.hostPropertyAccess?.unitAccessTypeId,
                    accessUnitDesc: sp.hostPropertyAccess?.unitAccessValue,
                    accessBuilding: sp.hostPropertyAccess?.buildingAccessTypeId == 0 ? null : sp.hostPropertyAccess?.unitAccessTypeId,
                    accessBuildingDesc: sp.hostPropertyAccess?.buildingAccessValue,
                    hasLaundry: sp.hostPropertyServiceProvider?.hasLaundry,
                    hasDryer: sp.hostPropertyServiceProvider?.hasDryer,
                    laundryInQuote: sp.hostPropertyServiceProvider?.laundryInQuote,
                    laundryToBeDone: sp.hostPropertyServiceProvider?.laundryToBeDone,
                    laundryMachineCycle: sp.hostPropertyServiceProvider?.laundryMachineCycle == 0 ? null : sp.hostPropertyServiceProvider?.laundryMachineCycle,
                    laundryDryerCycle: sp.hostPropertyServiceProvider?.laundryDryerCycle == 0 ? null : sp.hostPropertyServiceProvider?.laundryMachineCycle,
                    laundryLinenNumber: sp.hostPropertyServiceProvider?.laundryLinenNumber,
                    hasStorage: sp.hostPropertyServiceProvider?.hasStorage,
                    storageInQuote: sp.hostPropertyServiceProvider?.storageInQuote,
                    storageDescription: sp.hostPropertyServiceProvider?.storageDescription,
                    hasTrashDisposal: sp.hostPropertyServiceProvider?.hasTrashDisposal,
                    trashDays: sp.hostPropertyServiceProvider?.trashDays,
                    hasParking: sp.hostPropertyServiceProvider?.hasParking,
                    parkingDescription: sp.hostPropertyServiceProvider?.parkingDescription,
                    propertySize: sp.hostPropertyServiceProvider?.propertySize == 0 ? null : sp.hostPropertyServiceProvider?.propertySize,
                }

                array.push(item);
            });

            setTableData(array);
        }
    }, [selectedProPropertyList]);

    const updatePropertyInfo = (d: any) => {
        var res = d.data;
        var find = selectedProPropertyList.find((sp) => sp.address == res.address);
        if(find != undefined) {
            find.hostPropertyAccess!.unitAccessTypeId = res.accessUnit;
            find.hostPropertyAccess!.unitAccessValue = res.accessUnitDesc;
            find.hostPropertyAccess!.buildingAccessTypeId = res.accessBuilding;
            find.hostPropertyAccess!.buildingAccessValue = res.accessBuildingDesc;
            find.hasProQuoteRequest = true;
            find.hasPlusQuoteRequest = false;
            find.cleaningProviderId = 2;
            find.hostPropertyServiceProvider!.cleaningProviderId = 2;
            find.hostPropertyServiceProvider!.hasLaundry = res.hasLaundry == 1 ? true : false;
            find.hostPropertyServiceProvider!.hasDryer = res.hasDryer == 1 ? true : false;
            find.hostPropertyServiceProvider!.laundryInQuote = res.laundryInQuote == 1 ? true : false;
            find.hostPropertyServiceProvider!.laundryToBeDone = res.laundryToBeDone == 1 ? true : false;
            find.hostPropertyServiceProvider!.laundryMachineCycle = res.laundryMachineCycle;
            find.hostPropertyServiceProvider!.laundryDryerCycle = res.laundryDryerCycle;
            find.hostPropertyServiceProvider!.laundryLinenNumber = res.laundryLinenNumber;
            find.hostPropertyServiceProvider!.hasStorage = res.hasStorage == 1 ? true : false;
            find.hostPropertyServiceProvider!.storageInQuote = res.storageInQuote == 1 ? true : false;
            find.hostPropertyServiceProvider!.storageDescription = res.storageDescription;
            find.hostPropertyServiceProvider!.hasTrashDisposal = res.hasTrashDisposal == 1 ? true : false;
            find.hostPropertyServiceProvider!.trashDays = res.trashDays;
            find.hostPropertyServiceProvider!.hasParking = res.hasParking == 1 ? true : false;
            find.hostPropertyServiceProvider!.parkingDescription = res.parkingDescription;
            find.hostPropertyServiceProvider!.propertySize = res.propertySize;
        }
        
        changeProPropertyList(selectedProPropertyList);
    }

    return (
        <div className="w-100 position-relative mt-25">
            <div style={{ zIndex: 1 }} className="position-absolute head-top">
                <h4>Turnify Pro Quote Form</h4>
                <small className='text-danger'><b>Please fill the quote form of the properties you have selected by clicking the "edit" button.</b></small>
            </div>
            <div className='table-area'>
            <DataGrid
                id="propertyTable"
                keyExpr="address"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={tableData}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={200}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowUpdated={updatePropertyInfo}
           >
                <div></div>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false}>
                    <Popup title="Turnify Pro Quote Form" showTitle={true} width={'80vw'} height={'80vh'} />
                    <Form>
                        <Item dataField="address" disabled={true} />
                        <Item dataField="city" disabled={true} />
                        <Item itemType="group">
                            <Item dataField="accessUnit" />
                            <Item dataField="accessUnitDesc" />
                        </Item>
                        <Item itemType="group">
                            <Item dataField="accessBuilding" />
                            <Item dataField="accessBuildingDesc" />
                        </Item>
                        <Item itemType="group">
                            <Item dataField="hasLaundry" />
                            <Item dataField="hasDryer" />
                            <Item dataField="laundryInQuote" />
                            <Item dataField="laundryToBeDone" />
                            <Item dataField="laundryMachineCycle" />
                            <Item dataField="laundryDryerCycle" />
                            <Item dataField="laundryLinenNumber" />
                        </Item>
                        <Item itemType="group">
                            <Item dataField="hasStorage" />
                            <Item dataField="storageInQuote" />
                            <Item dataField="storageDescription" />
                            <Item dataField="hasTrashDisposal" />
                            <Item dataField="trashDays" />
                            <Item dataField="hasParking" />
                            <Item dataField="parkingDescription" />
                        </Item>
                        <Item dataField="propertySize" />
                    </Form>
                </Editing>
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Column type={"buttons"}>
                    <Button name="edit" />
                </Column>
                <Column dataField="address" minWidth={250} allowEditing={false} cssClass='cls' />
                <Column dataField="city" allowEditing={false} cssClass='cls' />
                <Column dataField="accessUnit" caption="Access Building" cssClass='cls' visible={false}>
                    <Lookup dataSource={accessTypes} valueExpr="value" displayExpr="label" />
                </Column>
                <Column dataField="accessUnitDesc" caption="How do your cleaners access the unit?" cssClass='cls' visible={false}>
                </Column>
                <Column dataField="accessBuilding" caption="Access Building" cssClass='cls' visible={false}>
                    <Lookup dataSource={accessTypes} valueExpr="value" displayExpr="label" />
                </Column>
                <Column dataField="accessBuildingDesc" caption="How do your cleaners access the building?" cssClass='cls' visible={false}>
                </Column>
                <Column dataField="hasLaundry" alignment='center' caption='Do you have laundry?' cssClass='cls' >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="hasDryer" alignment='center' caption='Do you have a dryer?' cssClass='cls' visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label" />
                </Column>
                <Column dataField="laundryInQuote" alignment='center' caption='Do you need laundry service to be included your quote?' cssClass='cls' minWidth={650} visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label" />
                </Column>
                <Column dataField="laundryToBeDone" alignment='center' caption='Do you want the laundry to be done in the promises?' cssClass='cls' visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label" />
                </Column>
                <Column dataField="laundryMachineCycle" alignment='center' caption='How many cycles does it take for your washing machine to wash your linens in a regular turnover?' cssClass='cls' visible={false} >
                </Column>
                <Column dataField="laundryDryerCycle" alignment='center' caption='How many cycles does it take for your dryer to wash your linens in a regular turnover?' cssClass='cls' visible={false} >
                </Column>
                <Column dataField="laundryLinenNumber" alignment='center' caption='Please provide the number of your linens for each turnover' cssClass='cls' visible={false} >
                </Column>
                <Column dataField="hasStorage" alignment='center' caption='Do you have a storage?' cssClass='cls' >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="storageInQuote" alignment='center' caption='Do you need the storage service to be included in your quote?' cssClass='cls' visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="storageDescription" caption="Storage Details" cssClass='cls' visible={false}>
                </Column>
                <Column dataField="hasTrashDisposal" alignment='center' caption='Do you need us to take the trash out to the curb in specific days of the week?' cssClass='cls' visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="trashDays" caption="Which days?" cssClass='cls' visible={false}>
                    <Lookup dataSource={dayOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="hasParking" alignment='center' caption='Do you have parking?' cssClass='cls' visible={false} >
                    <Lookup dataSource={booleanOptions} valueExpr="value" displayExpr="label"  />
                </Column>
                <Column dataField="parkingDescription" caption="Parking information (free/paid etc.)" cssClass='cls' visible={false}>
                </Column>
                <Column dataField="propertySize" caption="Property Size (sqft.)" dataType={"number"} cssClass='cls'>
                </Column>
            </DataGrid>
            </div>
        </div>
    )
}

export default React.memo(TurnifyProFormTable);