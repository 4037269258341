import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'
import TimePicker from '../../../../../umut-components/Inputs/TimePicker'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'

type Props = {staff: IStaff}

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const WorkingAvailability: React.FC<Props> = ({staff}) => {
  const [offDayList, setOffDayList] = useState<number[]>([])
  const [allOffDays, setAllOffDays] = useState([])
  const {showFailureDialog} = useDialog()
  const {data, isLoading, error, refetch} = useQuery(
    ['Get Staff Off Days', staff.userId],
    () => ApiCalls.getStaffOffDays(staff.userId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: staff.userId ? true : false}
  )

  useEffect(() => {
    if (!isLoading) {
      if (data?.data != undefined) {
        setAllOffDays(data?.data?.data)
        const list = []
        for (const day of data?.data?.data) {
          list.push(day.weekDay)
        }
        setOffDayList(list)
      }
    }
  }, [data, isLoading])

  const changeCheckoutTime = (val: any, dayIndex: number, type: string) => {
    console.log('gelen değer', val)
  }

  const changeOffDay = async (day: number, checked: boolean) => {
    if (checked) {
      try {
        await ApiCalls.postStaffOffDays({userId: Number(staff.userId), weekDay: day})
        refetch()
      } catch (error) {
        showFailureDialog('An error occur during adding off day')
      }
    } else {
      try {
        const dayInfo: any = allOffDays.find((item: any) => item.weekDay == day)
        if (dayInfo != undefined) {
          await ApiCalls.deleteStaffOffDays(dayInfo.id)
          refetch()
        }
      } catch (error) {
        showFailureDialog('An error occur during deleting off day')
      }
    }
  }

  return (
    <div className='card mt-5'>
      <div className='card-header border-0 cursor-pointer px-1'>
        <div className='card-title m-0 d-block'>
          <h3 className='fw-bold m-0'>Set Standart Hours</h3>
          <div className='fs-6 text-muted mt-3'>
            Configure the standart hours of operation for this contact
          </div>
        </div>
      </div>
      <div className='card-body border-top px-1 pt-3 pb-4'>
        <div className='table-responsive'>
          <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
            <tbody className='fs-6 fw-semibold'>
              {weekdays.map((wd, index) => {
                return (
                  <tr key={'weekday-' + index}>
                    <td className='min-w-100px fs-4 fw-bold'>{wd}</td>
                    <td className='w-100px'>
                      <div className='form-check form-check-solid form-check-custom form-switch'>
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          id={'slackswitch' + index}
                          checked={offDayList.includes(index) ? true : false}
                          value={index}
                          onChange={(v) => {
                            changeOffDay(+v.target.value, v.target.checked)
                          }}
                        />
                        <label className='form-check-label ps-2' htmlFor={'slackswitch' + index}>
                          {offDayList.includes(index) ? 'Off Day' : 'Working'}
                        </label>
                      </div>
                    </td>
                    {/* <td className='min-w-350px staff-available'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='row w-50'>
                          <div className='w-100 h-30px'>
                            <TimePicker
                              className='h-100'
                              onChange={(v) => {
                                changeCheckoutTime(v, index, 'start')
                              }}
                              defaultValue={'09:00'}
                            />
                          </div>
                        </div>
                        <div className='ms-3 me-3'>to</div>
                        <div className='row w-50'>
                          <div className='w-100 h-30px'>
                            <TimePicker
                              className='h-100'
                              onChange={(v) => {
                                changeCheckoutTime(v, index, 'end')
                              }}
                              defaultValue={'18:00'}
                            />
                          </div>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default WorkingAvailability
