import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../interfaces/Staff'
import Availability from '../HostStaffTabs/SettingsTab/Availability'
import Notifications from '../HostStaffTabs/SettingsTab/Notifications'
import Preferences from '../HostStaffTabs/SettingsTab/Preferences'
import EmployerSetting from '../HostStaffTabs/SettingsTab/EmployerSetting'

type Props = {
  staff: IStaff
}

type TabNames = 'availability' | 'notifications' | 'preferences' | 'employer-setting'

const SettingsTab: React.FC<Props> = ({staff}) => {
  const [activeTab, setActiveTab] = useState<TabNames>('availability')

  return (
    <>
      <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2'>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('availability')
          }}
        >
          <div
            className={
              activeTab == 'availability'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Availability
          </div>
        </li>
        {/* <li
          className='nav-item'
          onClick={() => {
            setActiveTab('notifications')
          }}
        >
          <div
            className={
              activeTab == 'notifications'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Notifications
          </div>
        </li>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('preferences')
          }}
        >
          <div
            className={
              activeTab == 'preferences'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Preferences
          </div>
        </li>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('employer-setting')
          }}
        >
          <div
            className={
              activeTab == 'employer-setting'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Employer Settings
          </div>
        </li> */}
      </ul>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          {activeTab == 'availability' && <Availability staff={staff} />}
          {activeTab == 'notifications' && <Notifications staff={staff} />}
          {activeTab == 'preferences' && <Preferences staff={staff} />}
          {activeTab == 'employer-setting' && <EmployerSetting staff={staff} />}
        </div>
      </div>
    </>
  )
}

export default SettingsTab
