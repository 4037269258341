import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap-v5';
import {
      Column,
      DataGrid,
      HeaderFilter, Scrolling,
      SearchPanel,
      Summary,
      TotalItem,
      Selection
} from 'devextreme-react/data-grid';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import { closeDeleteDialog, openDeleteDialog } from '../../../redux/actionCreators/deleteDialogActionCreators';
import ApiCalls from '../../../network/ApiCalls';

type Props = {
      data: any
      staffId: number
      refetch: () => void
}
interface IPostManagerRequest {
      userId: number;
      hostPropertyId: number;
      roleId: number;
}
const ManagerProperties: FC<Props> = ({ data, staffId, refetch }) => {
      const [selectedProperty, setSelectedProperty] = useState([]);
      const [loading, setLoading] = useState(false);
      const deletDialogDispatch = useDispatch<Dispatch<DeleteActions>>();

      const addPermission = async () => {
            let postManagerRequest: IPostManagerRequest[] = []
            selectedProperty.map((propertyId: any) => postManagerRequest.push({ userId: staffId, hostPropertyId: propertyId, roleId: 2 }))
            try {
                  setLoading(true)
                  await ApiCalls.postManagerProperties(postManagerRequest)
                  refetch()
                  deletDialogDispatch(closeDeleteDialog())
                  setLoading(false)
            } catch (error) {
                  setLoading(false)
            }
      }
      const onSelectionChanged = (cellValue: any) => {
            setSelectedProperty(cellValue.selectedRowsData.map((element: any) => element.id))
      }

      return <>
            <h3>Properties</h3>
            <DataGrid
                  id='ManagerPropertiesTable'
                  keyExpr='id'
                  onRowPrepared={(e) => {
                        e.rowElement.classList.add('data-grid-row')
                  }}
                  dataSource={data}
                  showBorders={false}
                  showColumnLines={false}
                  showRowLines={true}
                  hoverStateEnabled={true}
                  height={'calc(50vh - 60px)'}
                  allowColumnResizing={true}
                  onSelectionChanged={onSelectionChanged}
            >
                  <HeaderFilter visible={true} />
                  <Selection mode='multiple' showCheckBoxesMode='always' />
                  <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                  <SearchPanel visible={true} />
                  <Summary>
                        <TotalItem cssClass='absolute-right' displayFormat="Total Properties: {0}" column='cityName' summaryType='count' />
                  </Summary>
                  <Column dataField='name' minWidth={350} cssClass='cls' caption="Property Name" />
                  <Column
                        dataField='stateName'
                        alignment='center'
                        caption='STATE'
                        minWidth={30}
                        cssClass='cls'
                  />
                  <Column dataField='cityName' minWidth={100} cssClass='cls' caption="CITY" />


            </DataGrid>
            <div className="mt-5">
                  <Button onClick={addPermission} disabled={loading} className={`btn-sm btn-success w-100`}>+ Allow access for selected properties</Button>
            </div>

      </>;
};

export default ManagerProperties;
