import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../interfaces/Staff'
import Jobs from '../HostStaffTabs/JobsTab/Jobs'
import Services from '../HostStaffTabs/JobsTab/Services'
import Payment from '../HostStaffTabs/JobsTab/Payment'
import Reviews from '../HostStaffTabs/JobsTab/Reviews'
import Properties from '../HostStaffTabs/JobsTab/Properties'

type Props = {
  staff: IStaff
}

type TabNames = 'schedules' | 'payments' | 'services' | 'reviews' | 'properties'

const JobsTab: React.FC<Props> = ({staff}) => {
  const [activeTab, setActiveTab] = useState<TabNames>('schedules')

  return (
    <>
      <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2'>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('schedules')
          }}
        >
          <div
            className={
              activeTab == 'schedules'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Schedules
          </div>
        </li>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('properties')
          }}
        >
          <div
            className={
              activeTab == 'properties'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Properties
          </div>
        </li>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('services')
          }}
        >
          <div
            className={
              activeTab == 'services'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Services
          </div>
        </li>
        {/* <li
          className='nav-item'
          onClick={() => {
            setActiveTab('payments')
          }}
        >
          <div
            className={
              activeTab == 'payments'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Payments
          </div>
        </li> */}
        {/* <li
          className='nav-item'
          onClick={() => {
            setActiveTab('reviews')
          }}
        >
          <div
            className={
              activeTab == 'reviews'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Reviews & Ratings
          </div>
        </li> */}
      </ul>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          {activeTab == 'schedules' && <Jobs staff={staff} />}
          {activeTab == 'properties' && <Properties staff={staff} />}
          {activeTab == 'services' && <Services staff={staff} />}
          {activeTab == 'payments' && <Payment staff={staff} />}
          {activeTab == 'reviews' && <Reviews staff={staff} />}
        </div>
      </div>
    </>
  )
}

export default JobsTab
