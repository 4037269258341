import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../redux/actionTypes/masterChecklistTypes'
import * as checklistActionCreator from '../../../redux/actionCreators/masterChecklistActionCreators'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistForm'
import ChecklistEditForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistEditForm'
import {decryptText} from '../../../utils/util'
import ChecklistAssignmentWizard from '../../../umut-components/ChecklistAssignmentWizard/ChecklistAssignmentWizard'
import AssignmentWizard from './AssignmentWizard/AssignmentWizard'

type Props = {
  checklist: any
}

const NewChecklistAssignmentDialog: React.FC<Props> = ({checklist}) => {
  let {hostPropertyId}: any = useParams()
  hostPropertyId = decryptText(hostPropertyId)
  const {assignmentDialog} = useSelector((state: RedusxAppState) => state.masterChecklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()

  const handleClose = () => {
    dispatch(checklistActionCreator.closeChecklistAssignmentDialog())
  }

  return (
    <Modal show={assignmentDialog.modalOpen} onHide={handleClose} size='lg' centered>
      <ModalHeader closeButton>
        <Modal.Title>Assign Checklist</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {assignmentDialog.modalOpen && <AssignmentWizard checklist={checklist} />}
      </Modal.Body>
    </Modal>
  )
}

export default NewChecklistAssignmentDialog
