import React, {useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import Billings from './billings/Billings'
import MakePayment from './makePayment/MakePayment'
import Payment from './payment/Payment'

type TabNames = 'billing' | 'payments' | 'makePayment'

const PaymentCenter = () => {
  const {onBoardingInfo} = useSelector((state: RedusxAppState) => state.onBoarding)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const billing = onBoardingInfo.hasTurnifyProProperty
  const hasFailedPayment = onBoardingInfo.failedPayment
  const [selectedTab, setSelectedTab] = useState<TabNames>('payments')

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      {/* <Card className="min-h-500px">
            <Card.Body className="">
                <Tabs defaultActiveKey={hasFailedPayment ? "makePayment" : "payments"} id="uncontrolled-tab-example" className="mb-3" onSelect={handleChangeTab}>
                    <Tab eventKey="payments" title="Payments">
                        {selectedTab === "payments" && <Payment />}
                    </Tab>
                    {billing && <Tab eventKey="billing" title="Invoice">
                        {selectedTab === "billing" && <Billings />}
                    </Tab>}
                    {hasFailedPayment && <Tab eventKey="makePayment" title="Make Payment">
                        {selectedTab === "makePayment" && <MakePayment />}
                    </Tab>}
                </Tabs>
            </Card.Body>
        </Card> */}

      <div className='card card-body'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2'>
          <li
            className='nav-item'
            onClick={() => {
              setSelectedTab('payments')
            }}
          >
            <div
              className={
                selectedTab == 'payments'
                  ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                  : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
              }
            >
              Payments
            </div>
          </li>
          {billing && (
            <li
              className='nav-item'
              onClick={() => {
                setSelectedTab('billing')
              }}
            >
              <div
                className={
                  selectedTab == 'billing'
                    ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                    : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
                }
              >
                Invoices
              </div>
            </li>
          )}
          {hasFailedPayment && (
            <li
              className='nav-item'
              onClick={() => {
                setSelectedTab('makePayment')
              }}
            >
              <div
                className={
                  selectedTab == 'makePayment'
                    ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                    : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
                }
              >
                Make Payment
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className='tab-content mt-5'>
        <div className='tab-pane fade show active'>
          {selectedTab === 'payments' && <Payment />}
          {selectedTab === 'billing' && <Billings />}
          {selectedTab === 'makePayment' && <MakePayment />}
        </div>
      </div>
    </>
  )
}

export default PaymentCenter
