import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'
import WorkingAvailability from './WorkingAvailability'
import ContactAvailability from './ContactAvailability'

type Props = {staff: IStaff}

const Availability: React.FC<Props> = ({staff}) => {
  return (
    <div className='row'>
      <div className='col-xl-6 col-12 mb-3'>
        <h3 className='text-primary'>Working Availability</h3>
        <WorkingAvailability staff={staff} />
      </div>
      {/* <div className='col-xl-6 col-12 mb-3'>
        <h3 className='text-primary'>Contact Availability</h3>
        <ContactAvailability staff={staff} />
      </div> */}
    </div>
  )
}

export default Availability
