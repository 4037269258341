import { AxiosResponse } from 'axios';
import React, { FC } from 'react'
import { Card } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { PageTitle } from '../../../../_metronic/layout/core';
import ApiCalls from '../../../network/ApiCalls';
import { IBillingResponse } from '../../../network/NetworkResponses/NetworkResponses';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import EmptyData from '../../../umut-components/Icons/EmptyData';
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading';
import BillingDetail from './BillingDetail';
import BillingsTable from './BillingsTable';

const Billings: FC = () => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { data: billingData, isLoading: billingLoading, error: billingError } = useQuery<AxiosResponse<IBillingResponse>>(['Get Billings By Host Id', user.userSection.hostId], () => ApiCalls.getBillingsByHostId(user.userSection.hostId), { cacheTime: 500000, refetchOnWindowFocus: false });

    return (
        <>
            <PageTitle>Invoice</PageTitle>
            <Card>
                <Card.Body>
                    {billingLoading ? <SkeletonTableLoading /> : billingData ? <BillingsTable data={billingData.data.data} /> : <EmptyData emptyText='No Billing Found' />}
                </Card.Body>
            </Card>
            <BillingDetail />
        </>
    )
}

export default Billings;