import React, { Dispatch } from 'react';
import { useQuery } from 'react-query';
import ApiCalls from '../../../../network/ApiCalls';
import PropertyTable from './PropertyTable';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
}

const BannedProperties: React.FC<Props> = () => {
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const { data: propertyData, isLoading: propertyLoading, error: propertyError, refetch } = useQuery(['Banned Properties', staffDetail.staff?.userId], () => ApiCalls.getBannedProperties(staffDetail.staff?.userId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: staffDetail.staff?.userId ? true : false });

    const clickAdd = () => {
        dispatch(staffActionCreator.openStaffPropertyDialog(refetch, "banned"));
    }

    return (
        <div className='p-6'>
            <h4>Restricted Properties</h4>
            {propertyLoading ? <SkeletonTableLoading /> : <PropertyTable data={propertyData?.data?.data} refetch={refetch} propertyType="banned" />}
            <div className='w-100' style={{display:"flex",justifyContent:"end",alignItems:"baseline",bottom:"0px",right:"0px",position:"inherit"}}>
                <button className='btn btn-danger btn-sm mt-3' onClick={clickAdd}>Restrict Properties</button>
            </div>
        </div>
    )
}

export default React.memo(BannedProperties);