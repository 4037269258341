import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'
import JobsTable from './JobsTable'

type Props = {staff: IStaff}

const Jobs: React.FC<Props> = ({staff}) => {
  return (
    <div className='p-6 pt-0'>
      <JobsTable staff={staff} />
    </div>
  )
}

export default Jobs
