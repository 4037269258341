import { IChecklistItem, IHostPropertyCheckList, IPhoto, IQuestion, ISection } from "../../interfaces/Checklist";

export const GET_CHECKLIST_ITEMS = "GET_CHECKLIST_ITEMS";
export interface GetChecklistItems {
    type: typeof GET_CHECKLIST_ITEMS,
    checklistId: number
}

export const GET_CHECKLIST_ITEMS_SUCCESS = "GET_CHECKLIST_ITEMS_SUCCESS";
export interface GetChecklistItemsSuccess {
    type: typeof GET_CHECKLIST_ITEMS_SUCCESS,
    checklistItems: IChecklistItem
}

export const GET_CHECKLIST_ITEMS_FAILURE = "GET_CHECKLIST_ITEMS_FAILURE";
export interface GetChecklistItemsFailure {
    type: typeof GET_CHECKLIST_ITEMS_FAILURE,
    errorText: string
}

export const SET_CHECKLIST_ITEMS = "SET_CHECKLIST_ITEMS";
export interface SetChecklistItems {
    type: typeof SET_CHECKLIST_ITEMS,
    checklistItems: IChecklistItem
}

export const OPEN_CHECKLIST_EDIT_DIALOG = "OPEN_CHECKLIST_EDIT_DIALOG";
export interface OpenChecklistEditDialog {
    type: typeof OPEN_CHECKLIST_EDIT_DIALOG,
    checklist: IHostPropertyCheckList
}

export const CLOSE_CHECKLIST_EDIT_DIALOG = "CLOSE_CHECKLIST_EDIT_DIALOG";
export interface CloseChecklistEditDialog {
    type: typeof CLOSE_CHECKLIST_EDIT_DIALOG
}

export const OPEN_QUESTION_DIALOG = "OPEN_QUESTION_DIALOG";
export interface OpenQuestionDialog {
    type: typeof OPEN_QUESTION_DIALOG,
    section: ISection
}

export const CLOSE_QUESTION_DIALOG = "CLOSE_QUESTION_DIALOG";
export interface CloseQuestionDialog {
    type: typeof CLOSE_QUESTION_DIALOG
}

export const SET_QUESTION_ORDER = "SET_QUESTION_ORDER";
export interface SetQuestionOrder {
    type: typeof SET_QUESTION_ORDER,
    section: ISection
}

export const OPEN_SECTION_EDIT_DIALOG = "OPEN_SECTION_EDIT_DIALOG";
export interface OpenSectionEditDialog {
    type: typeof OPEN_SECTION_EDIT_DIALOG,
    section: ISection
}

export const CLOSE_SECTION_EDIT_DIALOG = "CLOSE_SECTION_EDIT_DIALOG";
export interface CloseSectionEditDialog {
    type: typeof CLOSE_SECTION_EDIT_DIALOG,
}

export const OPEN_QUESTION_EDIT_DIALOG = "OPEN_QUESTION_EDIT_DIALOG";
export interface OpenQuestionEditDialog {
    type: typeof OPEN_QUESTION_EDIT_DIALOG,
    question: IQuestion
}

export const CLOSE_QUESTION_EDIT_DIALOG = "CLOSE_QUESTION_EDIT_DIALOG";
export interface CloseQuestionEditDialog {
    type: typeof CLOSE_QUESTION_EDIT_DIALOG,
}

export const OPEN_QUESTION_ADD_DIALOG = "OPEN_QUESTION_ADD_DIALOG";
export interface OpenQuestionAddDialog {
    type: typeof OPEN_QUESTION_ADD_DIALOG,
    section: ISection | null
}

export const CLOSE_QUESTION_ADD_DIALOG = "CLOSE_QUESTION_ADD_DIALOG";
export interface CloseQuestionAddDialog {
    type: typeof CLOSE_QUESTION_ADD_DIALOG,
}

export const OPEN_QUESTION_PHOTO_DIALOG = "OPEN_QUESTION_PHOTO_DIALOG";
export interface OpenQuestionPhotoDialog {
    type: typeof OPEN_QUESTION_PHOTO_DIALOG,
    question: IQuestion | null
}

export const CLOSE_QUESTION_PHOTO_DIALOG = "CLOSE_QUESTION_PHOTO_DIALOG";
export interface CloseQuestionPhotoDialog {
    type: typeof CLOSE_QUESTION_PHOTO_DIALOG,
}

export type ChecklistAction =
    | GetChecklistItems
    | GetChecklistItemsSuccess
    | GetChecklistItemsFailure
    | SetChecklistItems
    | OpenChecklistEditDialog
    | CloseChecklistEditDialog
    | OpenQuestionDialog
    | CloseQuestionDialog
    | SetQuestionOrder
    | OpenSectionEditDialog
    | CloseSectionEditDialog
    | OpenQuestionEditDialog
    | CloseQuestionEditDialog
    | OpenQuestionAddDialog
    | CloseQuestionAddDialog
    | OpenQuestionPhotoDialog
    | CloseQuestionPhotoDialog