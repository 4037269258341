import React, {Dispatch} from 'react'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import PropertyTable from './PropertyTable'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import {useDispatch, useSelector} from 'react-redux'
import {ProTeamAction} from '../../../../redux/actionTypes/proTeamActionTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'

type Props = {}

const AllowedProperties: React.FC<Props> = () => {
  const dispatch = useDispatch<Dispatch<ProTeamAction>>()
  const {proTeamDetail} = useSelector((state: RedusxAppState) => state.proTeam)

  const {
    data: propertyData,
    isLoading: propertyLoading,
    error: propertyError,
    refetch,
  } = useQuery(
    ['Pro Team User Allowed Properties', proTeamDetail.proTeam?.userId],
    () => ApiCalls.getProTeamUserAllowedProperties(proTeamDetail.proTeam?.userId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: proTeamDetail.proTeam?.userId ? true : false,
    }
  )

  return (
    <div className='p-6'>
      <h4>Assigned Properties</h4>
      {propertyLoading ? (
        <SkeletonTableLoading />
      ) : (
        <PropertyTable data={propertyData?.data?.data} refetch={refetch} propertyType='allowed' />
      )}
    </div>
  )
}

export default React.memo(AllowedProperties)
