import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap-v5'

type Props = {
    classNames?: string,
    approveFn: () => void,
    type?: 'submit' | 'button',
    text?: string,
    disabled?: boolean
    iconWidth?: string,
    iconHeight?: string,
    spinner?: boolean
}
const ApproveButton: FC<Props> = ({ classNames, type, iconWidth, iconHeight, approveFn, disabled, spinner, text }) => {
    return (


        <button type={type ? type : "button"} disabled={disabled ? disabled : false} className={classNames ? classNames : "btn btn-sm btn-success"} onClick={() => { approveFn() }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth ? iconWidth : "16"} height={iconHeight ? iconHeight : "16"} fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
            </svg> {text}
            {spinner && <Spinner className="ms-2" animation='border' size="sm" />}
        </button>


    )
}

export default ApproveButton
