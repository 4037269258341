import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {
  LiveJobResponse,
  MarketplaceListResponse,
} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import MarketplaceCard from './MarketplaceCard'

const Marketplace = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)

  const localTime = dayjs().utc().format()
  const {
    data: marketplaceData,
    isFetching: marketplaceLoading,
    error: marketplaceError,
    refetch: refetchMarketplaceData,
  } = useQuery<AxiosResponse<MarketplaceListResponse>>(
    'Get Marketplace Requests',
    () => ApiCalls.getMarketplaceRequest(user.userSection.hostId),
    {cacheTime: 500000, enabled: user ? true : false}
  )

  if (marketplaceError) {
    return <div>An error occur during getting marketplace requests!</div>
  }

  return (
    <>
      <PageTitle>Marketplace</PageTitle>
      <div className='pt-3'>
        {marketplaceLoading ? (
          <SkeletonTableLoading />
        ) : (
          <MarketplaceCard
            data={marketplaceData?.data}
            recordCount={marketplaceData?.data.count || 0}
            refetchData={refetchMarketplaceData}
          />
        )}
      </div>
    </>
  )
}

export default Marketplace
