import dayjs from 'dayjs'
import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import Select, {components} from 'react-select'
import {selectStyles} from '../../../config/selectStyle'
import {HostPropertyStatus} from '../../../enums/PropertyEnum'
import ApiCalls from '../../../network/ApiCalls'
import * as jobActionCreator from '../../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {toConvertedTimeString, convertUTCDateToLocalDate} from '../../../utils/util'
import ArrowButton from '../../Buttons/ArrowButton'
import DateTimePicker from '../../Inputs/DateTimePicker'

type Props = {
  nextStep?: () => void
  previousStep?: () => void
}

type DateTime = {
  date?: string | null
  time?: string | null
}

const PropertyStep: React.FC<Props> = ({nextStep, previousStep}) => {
  const roleRef = useRef<any>(null)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {jobDialog} = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [properties, setProperties] = useState([])
  const [property, setProperty] = useState({})
  const [roles, setRoles] = useState([])
  const [startDate, setStartDate] = useState<string | null>(null)
  const {
    data: propertyData,
    isLoading: propertyLoading,
    error: propertyError,
  } = useQuery(
    'Get Properties for Job Add',
    () => ApiCalls.getHostPropertiesByStatus(user.userSection.hostId, HostPropertyStatus['Active']),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const {
    data: roleData,
    isLoading: roleLoading,
    error: roleError,
  } = useQuery('Get Roles for Job Add', () => ApiCalls.getStaffRoles(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const handleChangeProperty = (value: any) => {
    setRoles(
      roleData?.data
        ?.filter((role: any) => {
          if (value?.value?.cleaningProviderName === 'Turnify PRO') {
            return (
              role.lobRoleName === 'Vendor' &&
              role.roleName !== 'Manager' &&
              role.roleName !== 'Admin'
            )
          }
          return (
            role.lobRoleName === 'Host' && role.roleName !== 'Manager' && role.roleName !== 'Admin'
          )
        })
        .map((role: any) => {
          return {
            value: role.roleId,
            label: role.roleName,
          }
        })
    )
    roleRef.current?.select?.clearValue()
    dispatch(
      jobActionCreator.setJobForm({
        ...jobDialog.jobForm,
        jobServices: undefined,
        hostPropertyId: parseInt(value?.value?.id),
        roleId: value?.value?.cleaningProviderName === 'Turnify PRO' ? 11 : undefined,
        staffSectionActive:
          value?.value?.cleaningProviderName === 'My Team' ||
          value?.value?.cleaningProviderName === 'Turnify+',
        jobTimezone: value?.value?.timeZone,
        isMarketplace: value?.value?.isMarketplace,
        cleaningPeriodStart: dayjs().tz(value?.value?.timeZone).format('YYYY-MM-DDTHH:mm:ss.sss'),
      })
    )
  }

  useEffect(() => {
    if (propertyData && !jobDialog?.propertyId) {
      setProperties(
        propertyData?.data?.data?.map((property: any) => {
          var providerName = property.cleaningProviderName
          if (property.isMarketplace) {
            providerName = 'Marketplace'
          }
          return {
            value: property,
            label: property.name + ` (${providerName})`,
          }
        })
      )
    }
  }, [propertyData])

  // if (jobDialog?.propertyId) {
  //     let property = propertyData?.data?.data?.find((property: any) => { return jobDialog?.propertyId == property.id });
  //     setProperty({
  //         value:property.id,
  //         label: property.name + ` (${property.cleaningProviderName})`
  //     })
  // }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No property was found. Please add property</span>
      </components.NoOptionsMessage>
    )
  }
  const NoOptionsMessageDo = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No role was found.Please select property first.</span>
      </components.NoOptionsMessage>
    )
  }

  useEffect(() => {
    if (startDate) {
      dispatch(jobActionCreator.setJobForm({...jobDialog.jobForm, cleaningPeriodStart: startDate}))
    }
  }, [startDate])

  return (
    <div className='row'>
      {/* begin::Form group */}
      <div className='fv-row col-lg-12'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          Property {<span style={{color: 'red'}}>*</span>}
        </label>
        <Select
          components={{NoOptionsMessage}}
          isDisabled={!!jobDialog?.propertyId}
          styles={selectStyles}
          onChange={handleChangeProperty}
          options={properties}
        />
      </div>
      {jobDialog.jobForm?.staffSectionActive && (
        <div className='fv-row col-lg-12 mt-6'>
          <label className='form-label fs-6 fw-bolder text-dark'>Who will do this?</label>
          <Select
            defaultValue={roleData?.data?.roleName}
            components={{NoOptionsMessage: NoOptionsMessageDo}}
            styles={selectStyles}
            ref={roleRef}
            onChange={(value: any) => {
              dispatch(
                jobActionCreator.setJobForm({
                  ...jobDialog.jobForm,
                  jobServices: undefined,
                  roleId: value?.value,
                })
              )
            }}
            options={jobDialog?.jobForm?.hostPropertyId ? roles : []}
          />
        </div>
      )}
      {jobDialog.jobForm?.jobTimezone && (
        <div className='fv-row col-lg-12 mt-6'>
          <label className='form-label fs-6 fw-bolder text-dark'>Cleaning Date</label>
          <DateTimePicker
            min={toConvertedTimeString(
              dayjs().add(-30, 'minutes').toString(),
              jobDialog.jobForm?.jobTimezone
            )}
            defaultValue={toConvertedTimeString(dayjs().toString(), jobDialog.jobForm?.jobTimezone)}
            onChange={setStartDate}
            dateOutOfRangeMessage={'Jobs cannot be created in past!'}
          />
          <small className='text-muted'>
            Property time:{' '}
            {convertUTCDateToLocalDate(dayjs().toString(), jobDialog.jobForm?.jobTimezone)}
          </small>
        </div>
      )}
      <div className='d-flex justify-content-end'>
        <ArrowButton
          type='forward'
          func={nextStep}
          disabled={
            !jobDialog.jobForm?.hostPropertyId ||
            !jobDialog.jobForm?.roleId ||
            !jobDialog.jobForm.cleaningPeriodStart
          }
          classNames='mt-6'
        />
      </div>
    </div>
  )
}

export default PropertyStep
