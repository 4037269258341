import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useHistory, useParams} from 'react-router-dom'
import ChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistForm'
import {decryptText} from '../../../utils/util'
import NewChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/NewChecklistForm'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  handleAddPropertySuccess: () => void
  serviceTypeId: number
}

const NewChecklistAddDialog: React.FC<Props> = ({
  modalOpen,
  handleClose,
  handleAddPropertySuccess,
  serviceTypeId,
}) => {
  return (
    <Modal show={modalOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Checklist</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <NewChecklistForm
          handleAddPropertySuccess={handleAddPropertySuccess}
          serviceTypeId={serviceTypeId}
        />
      </Modal.Body>
    </Modal>
  )
}

export default NewChecklistAddDialog
