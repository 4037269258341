import React, {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {CheckBox} from 'devextreme-react'
import {Card} from 'react-bootstrap-v5'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {LobRoleNameEnum} from '../../../enums/UserRoles'
import {ISelectedService} from '../../../interfaces/Marketplace'

type Props = {
  checklistData: Array<IHostPropertyCheckList>
  onSelectedListChanged: (data: any) => void
  selectedServiceId: number | undefined
  serviceList: ISelectedService[]
}

const ChecklistSelection: React.FC<Props> = ({
  checklistData,
  onSelectedListChanged,
  selectedServiceId,
  serviceList,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [selectedChecklist, setSelectedChecklist] = useState<number>(0)

  useEffect(() => {
    var item = serviceList.find((sl) => sl.id == selectedServiceId)
    if (item != undefined) {
      if (item.serviceChecklist != null) {
        setSelectedChecklist(item.serviceChecklist.id)
      }
    }
  }, [])

  useEffect(() => {
    onSelectedListChanged(selectedChecklist)
  }, [selectedChecklist])

  const onClickChecklistCard = (check: boolean, checklistId: number) => {
    if (check) {
      setSelectedChecklist(checklistId)
    }

    if (!check) {
      setSelectedChecklist(0)
    }
  }

  const controlSelectedChecklist = (id: number) => {
    if (id == selectedChecklist) {
      return true
    } else {
      return false
    }
  }

  type ChecklistItemBoxProps = {
    item: any
  }

  const ChecklistItemBox: FC<ChecklistItemBoxProps> = ({item}) => {
    let isSelected = controlSelectedChecklist(item?.id)
    return (
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-25px'>Select</th>
              <th className='min-w-150px'>Checklist</th>
              <th className='min-w-140px'>For Who</th>
              <th className='min-w-120px'>Service</th>
              <th className='min-w-120px'>Service Desc</th>
              <th className='min-w-120px'>Checklist Item</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <CheckBox
                    value={isSelected}
                    onValueChange={(check: boolean) => {
                      onClickChecklistCard(check, +item?.id)
                    }}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {item.name?.en ? item.name?.en : item.name[Object.keys(item.name)[0]]}
                    </a>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {item.lobRoleId === LobRoleNameEnum.HOST
                        ? 'My Team | Turnify+'
                        : 'Turnify PRO'}
                    </span>
                  </div>
                </div>
              </td>
              <td>For {item?.roleName}</td>
              <td>{item.serviceTypeName}</td>
              <td>{item.serviceTypeDescription}</td>
              <td>
                {item.questionCount} checklist item{parseInt(item.questionCount) > 1 ? 's' : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className='row gap-10 d-flex justify-content-center'>
      {checklistData?.map((item) => (
        <>{item.serviceTypeId == selectedServiceId && <ChecklistItemBox item={item} />}</>
      ))}
    </div>
  )
}

export default ChecklistSelection
