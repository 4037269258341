import { put, call, all, fork, takeLatest, delay, takeEvery, select } from "redux-saga/effects";
import { IChecklistItem } from "../../interfaces/Checklist";
import ApiCalls from "../../network/ApiCalls";
import { GetChecklistsResponse } from "../../network/NetworkResponses/NetworkResponses";

import * as checklistActionCreators from "../actionCreators/checklistActionCreators";
import * as checklistActionTypes from "../actionTypes/checklistTypes";

function* onGetChecklists({ checklistId }: checklistActionTypes.GetChecklistItems) {
    try {
        const result: GetChecklistsResponse = yield call(ApiCalls.getChecklistItems, checklistId);
        yield put(checklistActionCreators.getChecklistItemsSuccess(result.data));
    }
    catch (error: any) {
        yield put(checklistActionCreators.getChecklistItemsFailure(error?.response?.data?.message));
    }
}

function* watchOnGetChecklists() {
    yield takeLatest(checklistActionTypes.GET_CHECKLIST_ITEMS, onGetChecklists);
}

export default function* checklistSaga() {
    yield all([fork(watchOnGetChecklists)]);
}