import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import ApiCalls from '../../../../../../network/ApiCalls'
import {IHostPropertyInput} from '../../../../../../interfaces/Property'
import Payment from './Payment'
import Agreement from './Agreement'

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
  refetch: () => void
}

const MarketplacePrompt: React.FC<Props> = ({propertyId, property, refetch}) => {
  const [activeStep, setActiveStep] = useState<number>(1)
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false)
  const [selectedCard, setSelectedCard] = useState<any>(
    property?.stripeAccountCardId ? property?.stripeAccountCardId : ''
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState('')

  const handleChangeAgreement = (value: boolean) => {
    setAgreementAccepted(value)
  }

  const handleChangeCard = (value: boolean) => {
    setSelectedCard(value)
  }

  useEffect(
    () => () => {
      setLoading(false)
      setAgreementAccepted(false)
      setSelectedCard('')
    },
    []
  )

  const submitManage = async () => {
    setErrMsg('')
    if (!agreementAccepted) {
      setErrMsg('Please agree to user agreement')
    } else if (selectedCard == '') {
      setErrMsg('Please select a card')
    } else {
      setLoading(true)
      try {
        const data = {
          hostPropertyId: propertyId,
          stripeAccountCardId: selectedCard,
        }
        await ApiCalls.updatePropertyToMarketplace(data)
        setLoading(false)
        refetch()
      } catch (error) {
        setLoading(false)
        setErrMsg('An error occured during the activating personal property manager')
      }
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            <div className='stepper-nav ps-lg-10'>
              <div
                className={activeStep == 1 ? 'stepper-item current' : 'stepper-item'}
                data-kt-stepper-element='nav'
              >
                <div className='stepper-wrapper d-flex'>
                  <div className='stepper-icon w-40px h-40px'>
                    {activeStep == 1 ? (
                      <div className='stepper-number'>1</div>
                    ) : (
                      <KTSVG path='/media/icons/duotone/Navigation/Check.svg' />
                    )}
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>User Agreement</h3>
                    <div className='stepper-desc'>Read and agree to agreement</div>
                  </div>
                </div>
                <div className='stepper-line h-40px'></div>
              </div>
              <div
                className={activeStep == 2 ? 'stepper-item current' : 'stepper-item'}
                data-kt-stepper-element='nav'
              >
                <div className='stepper-wrapper d-flex'>
                  <div className='stepper-icon w-40px h-40px'>
                    <div className='stepper-number'>2</div>
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Billing</h3>
                    <div className='stepper-desc'>Provide payment details</div>
                  </div>
                </div>
              </div>
              <div className='stepper-item'></div>
            </div>
          </div>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='form'>
              <div
                className={activeStep == 1 ? 'current' : 'completed'}
                data-kt-stepper-element='content'
              >
                <Agreement accepted={agreementAccepted} handleChange={handleChangeAgreement} />
              </div>
              <div
                className={activeStep == 1 ? 'pending' : 'current'}
                data-kt-stepper-element='content'
              >
                <Payment selectedCard={selectedCard} handleChange={handleChangeCard} />
              </div>
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  {activeStep == 2 && (
                    <button
                      className='btn btn-sm btn-light-primary me-3'
                      onClick={() => {
                        setActiveStep(1)
                      }}
                    >
                      Back{' '}
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Angle-Left.svg'
                        className='ms-2'
                      />
                    </button>
                  )}
                </div>
                <div>
                  {activeStep == 1 && (
                    <button
                      className='btn btn-sm btn-primary'
                      disabled={!agreementAccepted}
                      onClick={() => {
                        setActiveStep(2)
                      }}
                    >
                      Continue{' '}
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Angle-Right.svg'
                        className='ms-2'
                      />
                    </button>
                  )}
                  {activeStep == 2 && (
                    <button
                      className='btn btn-sm btn-primary'
                      disabled={selectedCard == '' ? true : false}
                      onClick={submitManage}
                    >
                      {!loading ? 'Submit' : 'Please wait...'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplacePrompt
