import React, {useEffect, useState} from 'react'
import {IHostPropertyInput} from '../../../../../interfaces/Property'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import StaffList from './StaffList'
import MarketplaceOffers from './MarketplaceOffers'
import {StaffAction} from '../../../../../redux/actionTypes/staffActionTypes'
import * as staffActionCreators from '../../../../../redux/actionCreators/staffActionCreators'
import HostStaffAddDialog from '../../../../staff/HostStaffAddDialog'
import MarketplacePrompt from './MarketplacePrompt/MarketplacePrompt'

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
  refetch: () => void
}

type TabNames = 'Staff Authorization & Prioritization' | 'TurnifyMarketplace'
const tabList: TabNames[] = ['Staff Authorization & Prioritization', 'TurnifyMarketplace']

const Staff: React.FC<Props> = ({property, propertyId, refetch}) => {
  const dispatch = useDispatch()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [activeTab, setActiveTab] = useState<TabNames>('Staff Authorization & Prioritization')

  const handleAddStaff = () => {
    dispatch(staffActionCreators.openStaffAddDialog(null))
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            {tabList.map((tabItem, index) => {
              return (
                <li
                  key={'tabitem-' + index}
                  className='nav-item mt-2'
                  onClick={() => {
                    setActiveTab(tabItem)
                  }}
                >
                  <div
                    className={
                      tabItem == activeTab
                        ? 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer active'
                        : 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer'
                    }
                  >
                    {tabItem}
                  </div>
                </li>
              )
            })}
          </ul>
          <div className='card-toolbar'>
            <button className='btn btn-sm btn-primary' onClick={handleAddStaff}>
              + Add New Staff
            </button>
          </div>
        </div>
      </div>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          {activeTab == 'Staff Authorization & Prioritization' && (
            <StaffList property={property} propertyId={propertyId} refetch={refetch} />
          )}
          {activeTab == 'TurnifyMarketplace' && property?.isMarketplace && (
            <MarketplaceOffers propertyId={propertyId} property={property} />
          )}
          {activeTab == 'TurnifyMarketplace' && !property?.isMarketplace && (
            <MarketplacePrompt propertyId={propertyId} property={property} refetch={refetch} />
          )}
        </div>
      </div>
      <HostStaffAddDialog />
    </>
  )
}

export default Staff
