import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ApiCalls from '../../../network/ApiCalls'
import {IHostPropertyManualInput} from '../../../interfaces/Property'
import dayjs from 'dayjs'
import {Dispatch, useEffect, useState} from 'react'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useDialog} from '../../../contexts/DialogContext'
import {useDispatch, useSelector} from 'react-redux'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import PropertyInfo from './SignUpComponents/PropertyInfo'
import AutomationSetting from './SignUpComponents/AutomationSetting'
import AboutYourself from './SignUpComponents/AboutYourself'
import {RegisterRequest} from '../../../network/PostRequestModels'
import ChoosePlan from './SignUpComponents/ChoosePlan'

type TabNames = 'property-info' | 'automation-setting' | 'about-yourself' | 'choose-plan'

const SignUp = () => {
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<TabNames>('property-info')
  const {showSuccessDialog, showFailureDialog, openLoadingModal} = useDialog()
  const [savedValues, setSavedValues] = useState<IHostPropertyManualInput | null>(null)
  const [savedHost, setSavedHost] = useState<RegisterRequest | null>(null)
  const [savedPlan, setSavedPlan] = useState<any>(null)
  const [missingField, setMissingField] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<boolean>(false)
  const [key, setKey] = useState(1)

  const handleChangeHostProperty = (changedValues: IHostPropertyManualInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  const handleChangeHostInfo = (changedValues: RegisterRequest) => {
    setSavedHost({...savedHost, ...changedValues})
  }

  const handleChangePlan = (changedValues: any) => {
    setIsFailed(false)
    setSavedPlan({...savedPlan, ...changedValues})
  }

  const handleNext = async () => {
    if (currentTab === 'property-info') {
      setCurrentTab('automation-setting')
    }

    if (currentTab === 'automation-setting') {
      setCurrentTab('about-yourself')
    }

    if (currentTab === 'about-yourself') {
      setCurrentTab('choose-plan')
    }

    if (currentTab == 'choose-plan') {
      var hasError = false
      setKey(key + 1)
      if (savedPlan !== null) {
        if (savedPlan.plan !== 'myteam') {
          if (savedPlan.cardInfo.source == '') {
            hasError = true
            showFailureDialog('Please enter your card information')
          }
        }
      } else {
        hasError = true
      }

      if (!hasError) {
        try {
          setIsLoading(true)
          const data = {hostData: savedHost, hostPropertyData: savedValues, planData: savedPlan}
          await ApiCalls.newRegister(data)
          showSuccessDialog('Register confirmation is sent to ' + savedHost?.hostUserInfo.email)
          window.location.href = '/auth/login'
        } catch (error: any) {
          setIsLoading(false)
          setIsFailed(true)
          setKey(1)
          if (error?.response?.data?.message) {
            if (error?.response?.data?.message.includes('Invalid source object')) {
              showFailureDialog(
                'Card information is missing or incorrect. Please make sure that you have entered your card details correctly!'
              )
            } else {
              showFailureDialog(error?.response?.data?.message)
            }
          } else {
            showFailureDialog('An error occur during registration. Please check all informations!')
          }
        }
      }
    }
  }

  const handleBack = () => {
    if (currentTab === 'automation-setting') {
      setCurrentTab('property-info')
    }

    if (currentTab === 'about-yourself') {
      setCurrentTab('automation-setting')
    }

    if (currentTab === 'choose-plan') {
      setKey(1)
      setCurrentTab('about-yourself')
    }
  }

  return (
    <>
      <div
        className='app-main flex-column flex-row-fluid'
        style={{background: '#f9f9f9', height: '100vh'}}
      >
        <div className='auth-sticky-headbar'>
          <div className='head-content'>
            <div className='head-content-items'>
              <div className='logo'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/turnify-logo.png')}
                  className='h-45px'
                />
              </div>
              <div className='divider'></div>
              <div className='link'>
                Already have an account?{' '}
                <Link to='/auth/login' className='link-primary fw-bolder'>
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-column-fluid'>
          <div className='app-toolbar py-3 py-lg-6'></div>
          <div className='app-content flex-column-fluid'>
            <div className='app-container container-xxl'>
              <div className='card'>
                <div className='card-body'>
                  <div
                    className={
                      currentTab === 'property-info'
                        ? 'stepper stepper-links d-flex flex-column pt-15'
                        : 'stepper stepper-links d-flex flex-column pt-15 between'
                    }
                  >
                    <div className='stepper-nav mb-5'>
                      <div
                        className={
                          currentTab == 'property-info' ? 'stepper-item current' : 'stepper-item'
                        }
                      >
                        <h3 className='stepper-title'>Property Info</h3>
                      </div>
                      <div
                        className={
                          currentTab == 'automation-setting'
                            ? 'stepper-item current'
                            : 'stepper-item'
                        }
                      >
                        <h3 className='stepper-title'>Automation Setting</h3>
                      </div>
                      <div
                        className={
                          currentTab == 'about-yourself' ? 'stepper-item current' : 'stepper-item'
                        }
                      >
                        <h3 className='stepper-title'>About Yourself</h3>
                      </div>
                      <div
                        className={
                          currentTab == 'choose-plan' ? 'stepper-item current' : 'stepper-item'
                        }
                      >
                        <h3 className='stepper-title'>Choose Plan</h3>
                      </div>
                    </div>
                    <div className='mx-auto w-100 pt-5 fv-plugins-bootstrap5 fv-plugins-framework'>
                      <div
                        className={currentTab == 'property-info' ? 'current' : ''}
                        data-kt-stepper-element='content'
                      >
                        <PropertyInfo
                          missingField={missingField}
                          handleChangeHostProperty={handleChangeHostProperty}
                          onComplete={handleNext}
                        />
                      </div>
                      <div
                        className={currentTab == 'automation-setting' ? 'current' : ''}
                        data-kt-stepper-element='content'
                      >
                        <AutomationSetting
                          missingField={missingField}
                          handleChangeHostProperty={handleChangeHostProperty}
                          onComplete={handleNext}
                          onBack={handleBack}
                        />
                      </div>
                      <div
                        className={currentTab == 'about-yourself' ? 'current' : ''}
                        data-kt-stepper-element='content'
                      >
                        <AboutYourself
                          missingField={missingField}
                          handleChangeHostInfo={handleChangeHostInfo}
                          onComplete={handleNext}
                          onBack={handleBack}
                        />
                      </div>
                      <div
                        className={currentTab == 'choose-plan' ? 'current' : ''}
                        data-kt-stepper-element='content'
                      >
                        <ChoosePlan
                          missingField={missingField}
                          handleChangePlan={handleChangePlan}
                          onComplete={handleNext}
                          onBack={handleBack}
                          isLoading={isLoading}
                          isFailed={isFailed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
