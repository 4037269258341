import React, {Dispatch} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {TextInput} from '../../Inputs/TextInput'
import {ChecklistSectionEditRequest} from '../../../network/PostRequestModels/ChecklistRequests'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import {ISection} from '../../../interfaces/Checklist'
import {ChecklistAction} from '../../../redux/actionTypes/masterChecklistTypes'
import * as checklistActionCreator from '../../../redux/actionCreators/masterChecklistActionCreators'

type Props = {
  section: ISection | undefined
  checklistId: string
}

const NewChecklistSectionForm: React.FC<Props> = ({section, checklistId}) => {
  const {showSuccessDialog} = useDialog()
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const [sectionRequest, setSectionRequest] = useState<ChecklistSectionEditRequest>({
    roomIndex: section?.roomIndex ? section?.roomIndex : '',
  })
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [sectionTypes, setSectionTypes] = useState({})
  const {
    data: sectionTypesData,
    isLoading: sectionTypesLoading,
    error: sectionTypesError,
  } = useQuery('Get Section Types', () => ApiCalls.getChecklistSectionTypes(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const handleChecklistSectionEdit = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const {roomIndex} = sectionRequest
      if (roomIndex) {
        await ApiCalls.editSection(sectionRequest, section?.id)
        setLoading(false)
        dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
        showSuccessDialog('Checklist Section Updated.')
      } else {
        setLoading(false)
        setErrorMessage('All fields are required.')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  useEffect(() => {
    if (sectionTypesData) {
      let selectObj: any = {}
      sectionTypesData?.data?.data?.map((type: any) => {
        selectObj[type.name] = type.id
      })
      setSectionTypes(selectObj)
    }
  }, [sectionTypesData])

  if (sectionTypesLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>Section Name</label>
          <TextInput
            onValueChange={(text: string) =>
              setSectionRequest({...sectionRequest, roomIndex: text})
            }
            defaultValue={sectionRequest.roomIndex}
          />
          <small className='text-muted'>(e.g. Bathroom #1, Large Bedroom, etc.)</small>

          <div className='row mt-6'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleChecklistSectionEdit}
              >
                Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(NewChecklistSectionForm)
