import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IJob } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import JobServiceAddForm from '../../../../umut-components/Forms/JobServiceAddForm';
import JobTaskForm from '../../../../umut-components/Forms/JobTaskForm';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

type Props = {
}

const TaskDialog: React.FC<Props> = () => {
    const { jobTaskDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();

    const handleClose = () => {
        dispatch(jobActionCreator.closeTaskDialog());
    }

    return (
        <Dialog open={jobTaskDialog.modalOpen} onClose={handleClose} maxWidth='lg' fullWidth={true} style={{ overflowY: 'unset' }}>
            <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
                {jobTaskDialog.taskData ? 'Edit' : 'Add'} Task
                <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'unset' }}>
                <JobTaskForm />
            </DialogContent>
        </Dialog>
    )
}

export default TaskDialog;