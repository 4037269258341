import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {encryptText, getFeedFilter, humanDate, toAmazonUrl} from '../../utils/util'
import {KTSVG} from '../../../_metronic/helpers'
import DashCalendar from './calendar/DashCalendar'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import JobDialog from '../jobs/JobDialog'
import {FeedCategories} from '../../enums/FeedEnum'
import dayjs from 'dayjs'
import Select, {components} from 'react-select'
import SelectInput from '../../umut-components/Inputs/SelectInput'

const HostDashboard = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [totalTodayJob, setTotalTodayJob] = useState(0)
  const [totalTodayPendingJob, setTotalTodayPendingJob] = useState(0)
  const [totalTodayScheduledJob, setTotalTodayScheduledJob] = useState(0)
  const [properties, setProperties] = useState<any[]>([])
  const [propertyOptions, setPropertyOptions] = useState<any[]>([])
  const [feedSkip, setFeedSkip] = useState(0)
  const [feeds, setFeeds] = useState<any[]>([])
  const [feedsBackup, setFeedsBackup] = useState<any[]>([])
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720 ? true : false)
  const [showProfile, setShowProfile] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showFeedFilter, setShowFeedFilter] = useState(false)
  const [selectedFilteredProperties, setSelectedFilteredProperties] = useState<any[]>([])
  const [feedType, setFeedType] = useState('all')
  const [feedTime, setFeedTime] = useState('today')

  const {
    data: hostProperties,
    isLoading: hostPropertiesLoading,
    error: hostPropertiesError,
    refetch: refetchHostProperties,
  } = useQuery('Get Host Properties', () => ApiCalls.getHostProperties(user.userSection.hostId), {
    cacheTime: 10000,
    retry: false,
  })

  const {
    data: todayJobSummary,
    isLoading: todayJobSummaryLoading,
    error: todayJobSummaryError,
    refetch: todayJobSummaryGroup,
  } = useQuery(
    ['Get Today Job Summary', dayjs().format()],
    () => ApiCalls.todayJobSummary(user.userSection.hostId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: true,
    }
  )

  const {
    data: hostFeeds,
    isLoading: hostFeedsLoading,
    error: hostFeedsError,
    refetch: hostFeedsRefetch,
  } = useQuery(
    'Get Host Feeds',
    () => ApiCalls.getHostFeeds(user.userSection.hostId, feedSkip, 50),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window])

  useEffect(() => {
    if (!hostPropertiesLoading && hostProperties?.data) {
      const list: any[] = []
      const items: any[] = []
      if (hostProperties?.data?.count > 0) {
        for (const prop of hostProperties?.data?.data) {
          list.push({id: prop.id, name: prop.name, address: prop.address, suite: prop.suite})
          items.push({label: prop.name, value: prop.id})
        }
      }
      setProperties(list)
      setPropertyOptions(items)
    }
  }, [hostProperties, hostPropertiesLoading])

  useEffect(() => {
    if (!todayJobSummaryLoading && todayJobSummary) {
      let totalJob = 0
      let pendingJob = 0
      let scheduledJob = 0
      if (todayJobSummary?.data?.count > 0) {
        for (const tj of todayJobSummary?.data?.data) {
          totalJob += 1
          if (tj.jobStatusTypeId == 1) {
            pendingJob += 1
          }
          if (tj.jobStatusTypeId == 2) {
            scheduledJob += 1
          }
        }
      }
      setTotalTodayJob(totalJob)
      setTotalTodayPendingJob(pendingJob)
      setTotalTodayScheduledJob(scheduledJob)
    }
  }, [todayJobSummaryLoading, todayJobSummary])

  useEffect(() => {
    const list: any[] = [...feeds]
    if (!hostFeedsLoading && hostFeeds) {
      if (
        hostFeeds?.data?.data &&
        hostFeeds?.data?.data?.feeds &&
        hostFeeds?.data?.data?.feeds.length > 0
      ) {
        for (const feed of hostFeeds.data.data.feeds) {
          var find = list.find((l) => l.id == feed.id)
          if (!find) {
            list.push(feed)
          }
        }
      }
      setFeedsBackup(list)

      let feedData = list
      let filtered: any[] = []
      if (selectedFilteredProperties.length > 0) {
        filtered = feedData.filter((fd) => selectedFilteredProperties.includes(fd.hostPropertyId))
      } else {
        filtered = feedData
      }
      if (feedTime == 'today') {
        filtered = filtered.filter((ft) => dayjs().isSame(dayjs(ft.createdOn)))
      }
      if (feedTime == 'week') {
        filtered = filtered.filter(
          (ft) =>
            dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(7, 'days') <= dayjs(ft.createdOn)
        )
      }
      if (feedTime == 'month') {
        filtered = filtered.filter(
          (ft) =>
            dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(30, 'days') <= dayjs(ft.createdOn)
        )
      }
      if (feedType != 'all') {
        let filt = getFeedFilter(feedType)
        filtered = filtered.filter((ft) => filt?.includes(ft.category))
      }
      setFeeds(filtered)
    }
  }, [hostFeeds, hostFeedsLoading])

  useEffect(() => {
    let feedData = feedsBackup
    let filtered: any[] = []
    if (selectedFilteredProperties.length > 0) {
      filtered = feedData.filter((fd) => selectedFilteredProperties.includes(fd.hostPropertyId))
    } else {
      filtered = feedData
    }
    if (feedTime == 'today') {
      filtered = filtered.filter((ft) => dayjs().isSame(dayjs(ft.createdOn)))
    }
    if (feedTime == 'week') {
      filtered = filtered.filter(
        (ft) => dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(7, 'days') <= dayjs(ft.createdOn)
      )
    }
    if (feedTime == 'month') {
      filtered = filtered.filter(
        (ft) =>
          dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(30, 'days') <= dayjs(ft.createdOn)
      )
    }
    if (feedType != 'all') {
      let filt = getFeedFilter(feedType)
      filtered = filtered.filter((ft) => filt?.includes(ft.category))
    }
    setFeeds(filtered)
  }, [feedType, feedTime, hostFeedsLoading])

  const filterProperties = () => {
    let feedData = feedsBackup
    let filtered: any[] = []
    if (selectedFilteredProperties.length > 0) {
      filtered = feedData.filter((fd) => selectedFilteredProperties.includes(fd.hostPropertyId))
    } else {
      filtered = feedData
    }
    if (feedTime == 'today') {
      filtered = filtered.filter((ft) => dayjs().isSame(dayjs(ft.createdOn)))
    }
    if (feedTime == 'week') {
      filtered = filtered.filter(
        (ft) => dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(7, 'days') <= dayjs(ft.createdOn)
      )
    }
    if (feedTime == 'month') {
      filtered = filtered.filter(
        (ft) =>
          dayjs() >= dayjs(ft.createdOn) && dayjs().subtract(30, 'days') <= dayjs(ft.createdOn)
      )
    }
    if (feedType != 'all') {
      let filt = getFeedFilter(feedType)
      filtered = filtered.filter((ft) => filt?.includes(ft.category))
    }
    setFeeds(filtered)
  }

  const loadMoreFeed = (skip: number) => {
    if (!hostFeedsLoading) {
      setFeedSkip(skip)
      setTimeout(() => {
        hostFeedsRefetch()
      }, 300)
    }
  }

  const getFeedTitle = (category: any) => {
    return FeedCategories[category as keyof typeof FeedCategories]
  }

  const filterFeedProperty = (options: any) => {
    var list = []
    for (var i = 0; i < options.length; i++) {
      var opt = options[i]

      if (opt.selected) {
        list.push(+opt.value)
      }
    }
    setSelectedFilteredProperties(list)
  }

  const goToJobDetail = (jobid: any) => {
    window.open(`/job-details/` + encryptText(jobid))
  }

  const propertyFilterSelect = (e: any) => {
    console.log('select', e)
  }

  return (
    <div className='app-container'>
      <div className='d-flex flex-row'>
        <div
          className={
            isMobile
              ? showProfile
                ? 'd-lg-flex flex-column flex-lg-row-auto w-lg-300px drawer drawer-start drawer-on'
                : 'd-lg-flex flex-column flex-lg-row-auto w-lg-300px drawer drawer-start'
              : 'd-lg-flex flex-column flex-lg-row-auto w-lg-300px'
          }
        >
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body pt-15 px-0'>
              <div className='d-flex flex-column text-center mb-9 px-9'>
                <div className='symbol symbol-80px symbol-lg-150px mb-4 d-flex justify-content-center'>
                  {user.user.profilePhotoPath != null ? (
                    <img src={toAmazonUrl(user.user.profilePhotoPath)} className='' alt='' />
                  ) : (
                    <div className='symbol-label'>
                      <img
                        alt='Logo'
                        className='h-30px logo object-contain'
                        src='/media/logos/turnify-logo.png'
                      />
                    </div>
                  )}
                </div>

                <div className='text-center'>
                  <div className='text-gray-800 fw-bold text-hover-primary fs-4'>
                    {user.user.firstName + ' ' + user.user.lastName}
                  </div>

                  <span className='text-muted d-block fw-semibold'>{user.user.email}</span>
                </div>
              </div>

              <div className='row px-9 mb-4'>
                <div className='col-md-4 text-center'>
                  <div className='text-gray-800 fw-bold fs-3'>
                    <span className='m-0 counted'>{totalTodayJob}</span>
                  </div>

                  <span className='text-gray-500 fs-8 d-block fw-bold'>TODAY'S JOBS</span>
                </div>

                <div className='col-md-4 text-center'>
                  <div className='text-gray-800 fw-bold fs-3'>
                    <span className='m-0 counted'>{totalTodayScheduledJob}</span>
                  </div>

                  <span className='text-gray-500 fs-8 d-block fw-bold'>SCHEDULED JOBS</span>
                </div>

                <div className='col-md-4 text-center'>
                  <div className='text-gray-800 fw-bold fs-3'>
                    <span className='m-0 counted'>{totalTodayPendingJob}</span>
                  </div>

                  <span className='text-gray-500 fs-8 d-block fw-bold'>PENDING JOBS</span>
                </div>
              </div>

              <div className='m-0'>
                <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'all'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('all')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/RSS.svg'
                        className='svg-icon-2 me-2'
                      />
                      All Feeds
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'job'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('job')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Home/Door-open.svg'
                        className='svg-icon-2 me-2'
                      />
                      Jobs
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'schedule'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('schedule')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Home/Clock.svg'
                        className='svg-icon-2 me-2'
                      />
                      Schedules
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'virtual'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('virtual')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Devices/Camera.svg'
                        className='svg-icon-2 me-2'
                      />
                      Virtual Inspections
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'problem'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('problem')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Chat-error.svg'
                        className='svg-icon-2 me-2'
                      />
                      Reported Problems
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div
                      className={
                        feedType == 'action'
                          ? 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer active'
                          : 'nav-link text-muted text-active-primary ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'
                      }
                      onClick={() => {
                        setFeedType('action')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Code/Warning-2.svg'
                        className='svg-icon-2 me-2'
                      />
                      Action Required
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div className='nav-link text-muted text-active-light ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'>
                      <KTSVG
                        path='/media/icons/duotone/Shopping/Price1.svg'
                        className='svg-icon-2 me-2'
                      />
                      Marketplace Quotes
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div className='nav-link text-muted text-active-light ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'>
                      <KTSVG
                        path='/media/icons/duotone/Communication/Group.svg'
                        className='svg-icon-2 me-2'
                      />
                      Staff
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>

                  <li className='nav-item mt-5'>
                    <div className='nav-link text-muted text-active-light ms-0 py-0 me-10 ps-9 border-0 cursor-pointer'>
                      <KTSVG
                        path='/media/icons/duotone/Shopping/Credit-card.svg'
                        className='svg-icon-2 me-2'
                      />
                      Payment
                      <span className='bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end'></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='w-100 flex-lg-row-fluid mx-lg-13'>
          <div className='d-flex d-lg-none align-items-center justify-content-end mb-10'>
            <div className='d-flex align-items-center gap-2'>
              <div
                className='btn btn-icon btn-active-color-primary w-30px h-30px'
                onClick={() => {
                  setShowProfile(!showProfile)
                }}
              >
                <KTSVG path='/media/icons/duotone/General/User.svg' className='svg-icon-2 me-2' />
              </div>

              <div
                className='btn btn-icon btn-active-color-primary w-30px h-30px'
                onClick={() => {
                  setShowCalendar(!showCalendar)
                }}
              >
                <KTSVG path='/media/icons/duotone/Home/Clock.svg' className='svg-icon-2 me-2' />
              </div>
            </div>
          </div>

          <div className='card mb-3'>
            <div className='card-header card-header-stretch'>
              <div className='card-title d-flex align-items-center'>
                <h3 className='fw-bold m-0 text-gray-800'>{dayjs().format('MMM DD, YYYY')}</h3>
              </div>
              <div className='card-toolbar m-0'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <a
                      href='javascript:;'
                      className={
                        feedTime == 'today'
                          ? 'nav-link justify-content-center text-active-gray-800 active'
                          : 'nav-link justify-content-center text-active-gray-800'
                      }
                      onClick={() => {
                        setFeedTime('today')
                      }}
                    >
                      Today
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      href='javascript:;'
                      className={
                        feedTime == 'week'
                          ? 'nav-link justify-content-center text-active-gray-800 active'
                          : 'nav-link justify-content-center text-active-gray-800'
                      }
                      onClick={() => {
                        setFeedTime('week')
                      }}
                    >
                      Week
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      href='javascript:;'
                      className={
                        feedTime == 'month'
                          ? 'nav-link justify-content-center text-active-gray-800 active'
                          : 'nav-link justify-content-center text-active-gray-800'
                      }
                      onClick={() => {
                        setFeedTime('month')
                      }}
                    >
                      Month
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link justify-content-center text-active-gray-800'
                      href='javascript:;'
                      onClick={() => {
                        setShowFeedFilter(!showFeedFilter)
                      }}
                    >
                      <div className='btn btn-sm btn-flex btn-secondary fw-bold dropmenu'>
                        Filter
                      </div>
                    </a>
                  </li>
                </ul>
                <div
                  className={
                    showFeedFilter
                      ? 'menu menu-sub menu-sub-dropdown w-350px w-md-500px dropfilter show'
                      : 'menu menu-sub menu-sub-dropdown w-350px w-md-500px dropfilter'
                  }
                >
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Select properties your feed:</div>
                  </div>
                  <div className='separator border-gray-200'></div>
                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-label fw-semibold'>Selected Properties:</label>
                      <div>
                        <select
                          className='form-select form-select-solid select2'
                          multiple
                          onChange={(v) => {
                            filterFeedProperty(v.target.options)
                          }}
                        >
                          {properties.map((p) => {
                            return (
                              <option
                                value={p.id}
                                selected={selectedFilteredProperties.includes(p.id)}
                              >
                                {p.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        onClick={() => {
                          setSelectedFilteredProperties([])
                          setFeeds(feedsBackup)
                        }}
                      >
                        Reset
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        onClick={() => {
                          filterProperties()
                          setShowFeedFilter(false)
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {feeds.length > 0 && (
            <div className='mb-10'>
              {feeds.map((feed, index) => {
                return (
                  <div className='card card-flush mb-10'>
                    <div className='card-header pt-9'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            alt='Logo'
                            className='h-30px logo object-contain'
                            src='/media/logos/turnify-logo.png'
                          />
                        </div>

                        <div className='flex-grow-1'>
                          <div className='text-gray-800 text-hover-primary fs-4 fw-bold'>
                            {getFeedTitle(feed.category)}
                          </div>

                          <span className='text-gray-400 fw-semibold d-block'>
                            {humanDate(feed.createdOn) +
                              ' at ' +
                              dayjs(feed.createdOn).format('HH:mm A')}
                          </span>
                        </div>
                      </div>

                      <div className='card-toolbar'>
                        <div className='m-0'>
                          <button
                            className='btn btn-icon btn-color-gray-400 btn-active-color-primary me-n4'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-overflow='true'
                          >
                            <i className='ki-duotone ki-dots-square fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                              <span className='path4'></span>
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='card-body'>
                      <div className='fs-6 fw-normal text-gray-700'>{feed.feedMessage}</div>
                    </div>

                    <div className='card-footer pt-0'>
                      <div className='mb-6'>
                        <div className='separator separator-solid'></div>

                        <ul className='nav py-3'>
                          <li className='nav-item'>
                            <a
                              href='javascript:;'
                              className='nav-link btn btn-sm btn-color-gray-600 btn-color-primary btn-light-primary fw-bold px-4 me-1 collapsible '
                              onClick={() => {
                                goToJobDetail(feed.jobId)
                              }}
                            >
                              Job Detail
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {feeds.length == 0 && (
            <div className='card card-flush mb-10'>
              <div className='card-header pt-9'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-50px me-5'>
                    <img
                      alt='Logo'
                      className='h-30px logo object-contain'
                      src='/media/logos/turnify-logo.png'
                    />
                  </div>

                  <div className='flex-grow-1'>
                    <div className='text-gray-800 text-hover-primary fs-4 fw-bold'>
                      Quiet Day on Turnify
                    </div>

                    <span className='text-gray-400 fw-semibold d-block'>
                      {humanDate(dayjs()) + ' at ' + dayjs(dayjs()).format('HH:mm A')}
                    </span>
                  </div>
                </div>

                <div className='card-toolbar'>
                  <div className='m-0'>
                    <button
                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary me-n4'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-overflow='true'
                    >
                      <i className='ki-duotone ki-dots-square fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                        <span className='path4'></span>
                      </i>
                    </button>
                  </div>
                </div>
              </div>

              <div className='card-body'>
                <div className='fs-6 fw-normal text-gray-700'>
                  No new updates or activities to report.
                </div>
              </div>
            </div>
          )}

          {feeds.length > 0 && feeds.length % 50 == 0 && (
            <div className='d-flex flex-center'>
              <a
                href='javascript:;'
                className='btn btn-primary fw-bold px-6'
                onClick={() => {
                  loadMoreFeed(feedSkip + 50)
                }}
              >
                {!hostFeedsLoading && <span className='indicator-label'>Show more</span>}

                {hostFeedsLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </a>
            </div>
          )}
        </div>

        <div
          className={
            isMobile
              ? showCalendar
                ? 'd-lg-flex flex-column flex-lg-row-auto w-lg-300px drawer drawer-start drawer-on'
                : 'd-lg-flex flex-column flex-lg-row-auto w-lg-300px drawer drawer-start'
              : 'd-lg-flex flex-column flex-lg-row-auto w-lg-450px'
          }
        >
          <DashCalendar
            isMobile={isMobile}
            visible={showCalendar}
            handleClose={() => {
              setShowCalendar(false)
            }}
          />
        </div>
      </div>
      <JobDialog />
    </div>
  )
}

export default HostDashboard
