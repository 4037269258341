import {put, call, all, fork, takeLatest, delay, takeEvery, select} from 'redux-saga/effects'
import ApiCalls from '../../network/ApiCalls'
import {
  GetProTeamListsResponse,
  GetProTeamDetailResponse,
} from '../../network/NetworkResponses/NetworkResponses'

import * as proTeamActionCreators from '../actionCreators/proTeamActionCreators'
import * as proTeamActionTypes from '../actionTypes/proTeamActionTypes'

function* onGetProTeamList({hostId, roleId}: proTeamActionTypes.GetProTeamList) {
  try {
    const result: GetProTeamListsResponse = yield call(ApiCalls.getProTeam, hostId, roleId)
    yield put(proTeamActionCreators.getProTeamListSuccess(result.data.data))
  } catch (error: any) {
    yield put(proTeamActionCreators.getProTeamListFailure(error?.response?.data?.message))
  }
}

function* watchOnGetProTeamList() {
  yield takeLatest(proTeamActionTypes.GET_PROTEAM_LIST, onGetProTeamList)
}

function* onGetProTeamDetail({userId}: proTeamActionTypes.GetProTeamDetail) {
  try {
    const result: GetProTeamDetailResponse = yield call(ApiCalls.getProTeamDetails, userId)
    yield put(proTeamActionCreators.getProTeamDetailSuccess(result.data))
  } catch (error: any) {
    yield put(proTeamActionCreators.getProTeamDetailFailure(error?.response?.data?.message))
  }
}

function* watchOnGetProTeamDetail() {
  yield takeLatest(proTeamActionTypes.GET_PROTEAM_DETAIL, onGetProTeamDetail)
}

export default function* staffSaga() {
  yield all([fork(watchOnGetProTeamList), fork(watchOnGetProTeamDetail)])
}
