import React, {Dispatch, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'
import {useDialog} from '../../../contexts/DialogContext'
import {TextAreaInput} from '../../../umut-components/Inputs/TextAreaInput'
import SwitchBoxMinimal from '../../../umut-components/Inputs/SwitchBoxMinimal'
import {ICleaningNeeds, ISelectedService, IService} from '../../../interfaces/Marketplace'
import {Modal} from 'react-bootstrap-v5'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ChecklistSelection from './ChecklistSelection'

type Props = {
  handleChange: (changedValues: any) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
  propertyId: number
}

const CleaningNeeds: React.FC<Props> = ({
  missingField,
  handleChange,
  goToStep,
  currentStep,
  propertyId,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showFailureDialog} = useDialog()
  const [hostProvideCleaningSupplies, setHostProvideCleaningSupplies] = useState<boolean>(false)
  const [showChecklistSelectionModal, setShowChecklistSelectionModal] = useState<boolean>(false)
  const [offSiteHostProvideCleaningSupplies, setOffSiteHostProvideCleaningSupplies] =
    useState<boolean>(false)
  const [cleanLinenAndTowel, setCleanLinenAndTowel] = useState<boolean>(true)
  const [offSiteLinenAndTowel, setOffSiteLinenAndTowel] = useState<boolean>(false)
  const [selectedJobsPerMonth, setSelectedJobsPerMonth] = useState<number>(1)
  const [selectedCleaningDuration, setSelectedCleaningDuration] = useState<number>(1)
  const [currentNote, setCurrentNote] = useState<string>('')
  const [serviceList, setServiceList] = useState<IService[]>([])
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [selectedValues, setSelectedValues] = useState<ICleaningNeeds>({
    cleaningDuration: 1,
    jobsPerMonth: 1,
    hostProvideCleaningSupplies: false,
    cleanLinenAndTowel: true,
    offSiteLinenAndTowel: false,
    note: '',
    requestedServices: [],
  })
  const [selectedServiceForChecklist, setSelectedServiceForChecklist] = useState<ISelectedService>()

  const {
    data: serviceTypesList,
    isFetching: serviceTypesLoading,
    error: serviceTypesError,
  } = useQuery('Get Service Types', () => ApiCalls.getServiceTypes(), {
    cacheTime: 10000,
    retry: false,
  })

  const {data: masterChecklistData} = useQuery(
    ['Get Master Checklist', propertyId],
    () => ApiCalls.getMasterChecklists(user.userSection.hostId),
    {refetchOnWindowFocus: false, enabled: true, cacheTime: 100000}
  )

  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery(
    ['Get Checklist By Property Id', propertyId],
    () => ApiCalls.getHostPropertyChecklists(propertyId),
    {refetchOnWindowFocus: false, cacheTime: 50000}
  )

  useEffect(() => {
    var clist: IHostPropertyCheckList[] = []
    checklistData?.data?.map((cd: any) => {
      clist.push(cd)
    })
    masterChecklistData?.data?.map((mcd: any) => {
      clist.push(mcd)
    })

    setChecklists(clist)
  }, [checklistData, masterChecklistData])

  useEffect(() => {
    setServiceList(serviceTypesList?.data.data.filter((stl: any) => stl.id < 21 && stl.isActive))
  }, [serviceTypesList])

  const onBackStepClick = () => {
    goToStep(2)
  }

  const submitRequest = () => {
    if (selectedValues.requestedServices.length == 0) {
      showFailureDialog('Please select a service for which you would like a quote!')
    } else {
      var hasError = false
      for (const rs of selectedValues.requestedServices) {
        if (rs.serviceChecklist == null) {
          hasError = true
        }
      }

      if (hasError) {
        showFailureDialog('Please select a checklist for your requested services')
      } else {
        if (selectedValues.note != '' && selectedValues.note != null) {
          const mailReg =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
          const phoneReg =
            /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gim
          if (mailReg.test(selectedValues.note) || phoneReg.test(selectedValues.note)) {
            showFailureDialog(
              'Please do not enter e-mail, telephone numbers or special characters in the offer note!'
            )
          } else {
            handleChange({...selectedValues})
          }
        } else {
          handleChange({...selectedValues})
        }
      }
    }
  }

  const noteChange = (txt: string) => {
    if (txt.length <= 600) setCurrentNote(txt)
    setSelectedValues({...selectedValues, note: txt})
  }

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      cleanLinenAndTowel: cleanLinenAndTowel,
      offSiteLinenAndTowel: offSiteLinenAndTowel,
      hostProvideCleaningSupplies: hostProvideCleaningSupplies,
    })
  }, [cleanLinenAndTowel, hostProvideCleaningSupplies])

  const getSelectedServiceClass = (id: number) => {
    var list = selectedValues.requestedServices
    var find = list.find((l) => l.id == id)
    if (find != undefined) {
      return 'selected'
    } else {
      return ''
    }
  }

  const toggleService = (id: number) => {
    var list = selectedValues.requestedServices
    var find = list.find((l) => l.id == id)
    if (find != undefined) {
      var findIndex = list.findIndex((l) => l.id == id)
      list.splice(findIndex, 1)
    } else {
      var item = serviceList.find((sl) => sl.id == id)
      if (item != undefined) {
        list.push({id: id, name: item?.name, serviceChecklist: null})
      }
    }

    setSelectedValues({...selectedValues, requestedServices: list})
  }

  const checkSelectedServiceCheklist = (serviceId: number) => {
    var list = selectedValues.requestedServices
    var find = list.find((l) => l.id == serviceId)
    if (find != undefined) {
      if (find.serviceChecklist != null) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const onChangeChecklist = (checklistId: number) => {
    var list = selectedValues.requestedServices
    var find = list.find((l) => l.id == selectedServiceForChecklist?.id)
    if (find != undefined) {
      if (find.serviceChecklist?.id != checklistId) {
        var findChecklist = checklists.find((cl) => cl.id == checklistId)
        if (findChecklist != undefined) {
          find.serviceChecklist = {id: findChecklist.id, name: findChecklist.name}
        }

        if (checklistId == 0) {
          find.serviceChecklist = null
        }
      }
    }

    setSelectedValues({...selectedValues, requestedServices: list})
  }

  const getServiceChecklistInfo = (id: number) => {
    var list = selectedValues.requestedServices
    var find = list.find((l) => l.id == id)
    if (find != undefined) {
      return find.serviceChecklist?.name.en
    } else {
      return 'Unknown list name'
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='form w-100'>
            <div className='row mb-5'>
              <div className='fv-row col-lg-12'>
                <div className='row mb-5'>
                  <div className='col-lg-5 col-12 mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                      How long does it take to clean your unit (estimated)
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm me-2'
                        onClick={() => {
                          if (selectedCleaningDuration > 1) {
                            if (selectedCleaningDuration - 1 < 1) {
                              setSelectedCleaningDuration(1)
                              setSelectedValues({
                                ...selectedValues,
                                cleaningDuration: 1,
                              })
                            } else {
                              setSelectedCleaningDuration(selectedCleaningDuration - 0.5)
                              setSelectedValues({
                                ...selectedValues,
                                cleaningDuration: selectedCleaningDuration - 0.5,
                              })
                            }
                          }
                        }}
                      >
                        -
                      </button>
                      <div style={{width: 100, textAlign: 'center'}}>
                        <strong>{selectedCleaningDuration ? selectedCleaningDuration : '1'}</strong>
                      </div>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm ms-2'
                        onClick={() => {
                          if (selectedCleaningDuration === 0) {
                            setSelectedCleaningDuration(selectedCleaningDuration + 1)
                            setSelectedValues({
                              ...selectedValues,
                              cleaningDuration: selectedCleaningDuration + 1,
                            })
                          } else {
                            setSelectedCleaningDuration(selectedCleaningDuration + 0.5)
                            setSelectedValues({
                              ...selectedValues,
                              cleaningDuration: selectedCleaningDuration + 0.5,
                            })
                          }
                        }}
                      >
                        +
                      </button>
                      <span className='fw-bold ms-4'>hour(s)</span>
                    </div>
                  </div>
                  <div className='col-lg-5 col-12 mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                      Number of projects per month (estimated)
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm me-2'
                        onClick={() => {
                          if (selectedJobsPerMonth > 1) {
                            setSelectedJobsPerMonth(selectedJobsPerMonth - 1)
                            setSelectedValues({
                              ...selectedValues,
                              jobsPerMonth: selectedJobsPerMonth - 1,
                            })
                          }
                        }}
                      >
                        -
                      </button>
                      <div style={{width: 100, textAlign: 'center'}}>
                        <strong>{selectedJobsPerMonth}</strong>
                      </div>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm ms-2'
                        onClick={() => {
                          setSelectedJobsPerMonth(selectedJobsPerMonth + 1)
                          setSelectedValues({
                            ...selectedValues,
                            jobsPerMonth: selectedJobsPerMonth + 1,
                          })
                        }}
                      >
                        +
                      </button>
                      <span className='fw-bold ms-4'>job(s)</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-12 mb-5'>
                    <SwitchBoxMinimal
                      defaultChecked={hostProvideCleaningSupplies}
                      text='I provide Cleaning Supplies'
                      onCheckboxChange={setHostProvideCleaningSupplies}
                    />
                    <small className='w-75 text-muted text-start text-break '>
                      If you choose this option, pricing is excluding cleaning materials at the time
                      of bidding.
                    </small>
                  </div>
                  <div className='col-lg-6 col-12 mb-5'>
                    <SwitchBoxMinimal
                      defaultChecked={cleanLinenAndTowel}
                      text='On-site Linen and Towel Laundry Services'
                      onCheckboxChange={setCleanLinenAndTowel}
                    />
                    <small className='w-75 text-muted text-start text-break '>
                      If you choose this option, washing and drying of linen and towels will be
                      taken into account when bidding.
                    </small>
                  </div>
                  <div className='col-lg-6 col-12 mb-5'>
                    <SwitchBoxMinimal
                      defaultChecked={offSiteLinenAndTowel}
                      text='Off-site Linen and Towel Laundry Services'
                      onCheckboxChange={setOffSiteLinenAndTowel}
                    />
                    <small className='w-75 text-muted text-start text-break '>
                      If you choose this option, washing and drying of linen and towels will be
                      taken from off-site to account when bidding.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 mb-1'>
                <label className='form-label fs-6 fw-bolder text-dark'>Requested services</label>
              </div>
              <div className='col-12 mb-5'>
                <small className='text-danger fw-bold'>
                  NOTE: Make sure you have a checklist for the services you are offering. Job
                  assignments cannot be made for your services that do not have a checklist.
                </small>
              </div>
              {!serviceTypesLoading && serviceList != undefined && (
                <div className='col-lg-12 mb-5'>
                  <div className='row'>
                    {serviceList.map((sl, index) => {
                      return (
                        <div className='col-lg-3 col-12 mb-3' key={'service-card-' + index}>
                          <div className={'card service-card ' + getSelectedServiceClass(sl.id)}>
                            <div
                              className='card-body'
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              onClick={(e) => {
                                toggleService(sl.id)
                              }}
                            >
                              <p className='fw-bold'>{sl.name}</p>
                              <span>{sl.description}</span>
                            </div>
                            <div className='card-footer border-0 py-1 px-0'>
                              {getSelectedServiceClass(sl.id) &&
                                !checkSelectedServiceCheklist(sl.id) && (
                                  <div className=''>
                                    <button
                                      className='btn btn-sm btn-success'
                                      onClick={(e) => {
                                        setSelectedServiceForChecklist(sl)
                                        setShowChecklistSelectionModal(true)
                                      }}
                                    >
                                      Select Checklist
                                    </button>
                                  </div>
                                )}
                              {getSelectedServiceClass(sl.id) &&
                                checkSelectedServiceCheklist(sl.id) && (
                                  <div className=''>
                                    <button
                                      className='btn btn-sm btn-info'
                                      onClick={(e) => {
                                        setSelectedServiceForChecklist(sl)
                                        setShowChecklistSelectionModal(true)
                                      }}
                                    >
                                      {getServiceChecklistInfo(sl.id)}
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  If you have any special requirements, please add them in the notes section below
                  (optional)
                </label>
                <div className='text-danger mb-3'>
                  For your safety, please do not include sensitive information such as phone
                  numbers, e-mails, or property addresses in the field below.
                </div>
                <TextAreaInput
                  defaultValue={currentNote}
                  onValueChange={noteChange}
                  max={600}
                  row={8}
                />
                <span className='d-block w-100 text-end mt-2 mb-5'>
                  {currentNote.length} / 1000
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer p-0 pt-5'>
          <div className='d-flex justify-content-between flex-row w-100'>
            <ArrowButton type='back' func={onBackStepClick} classNames='align-self-end' />
            <button
              className='btn btn-success btn-sm'
              onClick={() => {
                submitRequest()
              }}
            >
              Find Nearby Cleaners
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showChecklistSelectionModal}
        onHide={() => {
          setShowChecklistSelectionModal(false)
          setSelectedServiceForChecklist(undefined)
        }}
        centered
        size={'lg'}
      >
        <Modal.Header>
          <h6>
            Select Cleaning Checklists For <u>"{selectedServiceForChecklist?.name}"</u>
          </h6>
        </Modal.Header>
        <Modal.Body>
          <ChecklistSelection
            checklistData={checklists}
            serviceList={selectedValues.requestedServices}
            selectedServiceId={selectedServiceForChecklist?.id}
            onSelectedListChanged={(data) => {
              onChangeChecklist(data)
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CleaningNeeds
