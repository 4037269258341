import React, { Dispatch, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import JobWizard from '../../umut-components/JobWizard/JobWizard';
import { useQuery } from 'react-query';
import ApiCalls from '../../network/ApiCalls';
import Select from 'react-select';
import { AxiosResponse } from 'axios';
import { IJobCancelTypeResponse } from '../../network/NetworkResponses/NetworkResponses';
import { useDialog } from '../../contexts/DialogContext';

type Props = {
}

const JobCancelDialog: React.FC<Props> = ({ }) => {
    const { showFailureDialog, showSuccessDialog } = useDialog();
    const { cancelJobDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const { data, isLoading, error } = useQuery<AxiosResponse<IJobCancelTypeResponse>>(['Get Job Cancel Types'], () => ApiCalls.getJobCancelTypes(), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: cancelJobDialog.modalOpen });
    const [selectedCancelType, setSelectedCancelType] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        dispatch(jobActionCreator.closeCancelJobDialog());
    }

    const cancelJob = async () => {
        try {
            if (selectedCancelType && cancelJobDialog.job) {
                setLoading(true);
                await ApiCalls.cancelJob({ jobId: cancelJobDialog.job?.id, jobCancelTypeId: selectedCancelType });
                setLoading(false);
                cancelJobDialog.refetch();
                dispatch(jobActionCreator.closeCancelJobDialog());
                showSuccessDialog('Job cancelled');
            }
        }
        catch (err: any) {
            setLoading(false);
            dispatch(jobActionCreator.closeCancelJobDialog());
            showFailureDialog(err?.response?.data?.message);
        }
    }

    useEffect(() => {
        setSelectedCancelType(null);
    }, [cancelJobDialog.modalOpen])

    return (
        <Modal show={cancelJobDialog.modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Cancel Job</Modal.Title>
            </ModalHeader>
            <Modal.Body className='d-flex flex-column'>
                <strong>Do you want to cancel job at {cancelJobDialog.job?.hostPropertyAddress}?</strong>
                <label className='mt-3'>Why do you want to cancel this job?</label>
                <Select className='mt-1' options={data?.data.data.map(type => ({ label: type.name, value: type.id }))} onChange={value => value && setSelectedCancelType(value?.value)} />
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-end'>
                <div className='d-flex flex-row'>
                    <button type='button' className='btn btn-link me-3' onClick={handleClose}>Close</button>
                    <button type='button' className='btn btn-danger' disabled={(selectedCancelType ? false : true) || loading} onClick={cancelJob}>Cancel Job {loading && <Spinner animation="border" size="sm" />}</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default JobCancelDialog;