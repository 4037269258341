import React, {Dispatch, useEffect, useState} from 'react'
import StepWizard from 'react-step-wizard'
import {Card} from 'react-bootstrap-v5'
import PropertyStep from './PropertyStep'
import SummaryStep from './SummaryStep'

type Props = {checklist: any}

const AssignmentWizard: React.FC<Props> = ({checklist}) => {
  const [selectedProperties, setSelectedProperties] = useState<any[]>([])
  return (
    <>
      <StepWizard transitions={{enterRight: '', enterLeft: '', exitRight: '', exitLeft: ''}}>
        <PropertyStep checklist={checklist} handleChange={setSelectedProperties} />
        <SummaryStep selectedProperties={selectedProperties} checklist={checklist} />
      </StepWizard>
    </>
  )
}

export default AssignmentWizard
