import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import StripeContainer from './Stripe/StripeContainer'

type Props = {
  handleChangePlan: (data: any) => void
  onComplete: () => void
  onBack: () => void
  missingField?: boolean
  isLoading: boolean
  isFailed: boolean
}

type PlanNames = 'myteam' | 'marketplace' | 'turnifyplus'

const ChoosePlan: React.FC<Props> = ({
  handleChangePlan,
  onComplete,
  onBack,
  missingField,
  isLoading,
  isFailed,
}) => {
  const [plan, setPlan] = useState<PlanNames>('myteam')
  const [cardInfo, setCardInfo] = useState<any>({source: ''})

  useEffect(() => {
    handleChangePlan({plan, cardInfo})
  }, [plan, cardInfo])

  const handleChangeSource = (value: string) => {
    setCardInfo({...cardInfo, source: value})
    handleChangePlan({plan, cardInfo})
  }

  const handleChangeType = (value: PlanNames) => {
    setPlan(value)
    handleChangePlan({plan, cardInfo})
  }

  const onSubmit = () => {
    handleChangePlan({plan, cardInfo})
    setTimeout(() => {
      onComplete()
    }, 200)
  }

  const onBackStepClick = () => {
    onBack()
  }

  return (
    <div className='form w-100'>
      <div className='row mt-10'>
        <div className='col-lg-4 col-12 mb-3'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <input
                type='radio'
                className='btn-check'
                name='account_type'
                value='myteam'
                checked={plan === 'myteam'}
                onChange={(e) => {
                  handleChangeType(e.target.value as PlanNames)
                }}
                id='pack1'
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
                htmlFor='pack1'
              >
                <KTSVG
                  path='/media/icons/duotone/General/Half-star.svg'
                  className='svg-icon-2x mt-1 me-5'
                />
                <span className='d-block fw-semibold text-start'>
                  <span className='text-dark fw-bold d-block fs-4 mb-2'>My Team</span>
                  <span className='text-muted fw-semibold fs-6'>
                    I’ll manage my own team without automations - $0 per month
                  </span>
                </span>
              </label>
            </div>
            <div className='col-12 mb-3'>
              <input
                type='radio'
                className='btn-check'
                name='account_type'
                value='marketplace'
                checked={plan === 'marketplace'}
                onChange={(e) => {
                  handleChangeType(e.target.value as PlanNames)
                }}
                id='pack2'
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
                htmlFor='pack2'
              >
                <KTSVG
                  path='/media/icons/duotone/General/Star.svg'
                  className='svg-icon-2x mt-1 me-5'
                />
                <span className='d-block fw-semibold text-start'>
                  <span className='text-dark fw-bold d-block fs-4 mb-2'>Marketplace</span>
                  <span className='text-muted fw-semibold fs-6'>
                    Give me full Turnify access - $6/month
                  </span>
                </span>
              </label>
            </div>
            <div className='col-12 mb-3'>
              <input
                type='radio'
                className='btn-check'
                name='account_type'
                value='turnifyplus'
                checked={plan === 'turnifyplus'}
                onChange={(e) => {
                  handleChangeType(e.target.value as PlanNames)
                }}
                id='pack3'
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
                htmlFor='pack3'
              >
                <KTSVG
                  path='/media/icons/duotone/General/Fire.svg'
                  className='svg-icon-2x mt-1 me-5'
                />
                <span className='d-block fw-semibold text-start'>
                  <span className='text-dark fw-bold d-block fs-4 mb-2'>Turnify+</span>
                  <span className='text-muted fw-semibold fs-6'>
                    Your very own virtual assistant to manage your team - just $6 per clean
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='col-lg-6 offset-lg-1 col-12 mb-3'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                <KTSVG
                  path='/media/icons/duotone/Code/Warning-1-circle.svg'
                  className='svg-icon-2x mt-1 me-5'
                />

                <div className='d-flex flex-stack flex-grow-1 '>
                  <div className=' fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Attention!</h4>

                    <div className='fs-6 text-gray-700 '>
                      If you are selecting the My Team plan you will not be charged, even if you
                      enter card information below. However, if you want to upgrade in the future or
                      use the marketplace, adding your card now will save you time in the future.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <StripeContainer
                handleSuccess={(v) => {
                  handleChangeSource(v)
                }}
                isFailed={isFailed}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between flex-row w-100 mt-20'>
        <div className='mr-2'>
          <button
            type='button'
            className='btn btn-lg btn-light-primary me-3'
            data-kt-stepper-action='previous'
            disabled={isLoading}
            onClick={() => {
              onBackStepClick()
            }}
          >
            {'< Back'}
          </button>
        </div>
        <div>
          <button
            type='button'
            onClick={() => {
              onSubmit()
            }}
            className='btn btn-lg btn-primary'
            data-kt-stepper-action='next'
          >
            {isLoading ? 'Please wait..' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChoosePlan
