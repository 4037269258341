import { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { PageTitle } from "../../../../_metronic/layout/core";
import { StaffAction } from "../../../redux/actionTypes/staffActionTypes";
import DeleteDialog from "../../../umut-components/Modals/DeleteDialog";
import { decryptText } from "../../../utils/util";
import ProfileTab from "../../staff/staff-detail/Tabs/ProfileTab";
import StaffEditDialog from "../../staff/StaffEditDialog";
import StaffPasswordEdit from "../../staff/StaffPasswordEdit";
import * as staffActionCreators from '../../../redux/actionCreators/staffActionCreators';
import CitiesPermission from "./CitiesPermission";
import ManagerProperties from "./ManagerProperties";
import ManagerPropertiesAssigned from "./ManagerPropertiesAssigned";
import ApiCalls from "../../../network/ApiCalls";
import { useQuery } from "react-query";
import { RedusxAppState } from "../../../redux/reducers/rootReducer";


type TabNames =
      | 'property-list'
      | 'cities-permission'


const ManagerDetail = () => {

      let { staffId }: any = useParams();
      staffId = decryptText(staffId);

      const { user } = useSelector((state: RedusxAppState) => state.user);

      const [selectedTab, setSelectedTab] = useState<TabNames>('property-list');
      const dispatch = useDispatch<Dispatch<StaffAction>>();
      const [editDialogOpen, setEditDialogOpen] = useState(false);

      const [permittedProperties, setPermittedProperties] = useState([]);

      const handleChangeTab = (key: any) => {
            setSelectedTab(key);
      }

      const { data: AllProperties, isLoading: isLoadingAP, error, isFetching, refetch } = useQuery('Get  Manager Properties', () => ApiCalls.getManagerProperties(staffId), { cacheTime: 10000, retry: false });

      const { data: managerProperties, isLoading: isLoadingMP, refetch: refetchMP } = useQuery(['Get Accepted Manager Properties', staffId], () => ApiCalls.getAcceptedManagerProperties(staffId), { cacheTime: 10000, retry: false })

      // useEffect(() => {
      //       if (managerProperties && AllProperties) {
      //             setPermittedProperties(AllProperties?.data.data.filter((properties: any) => !managerProperties?.data?.data.some((i: any) => i.id == properties.id)))
      //       }
      // }, [managerProperties, , AllProperties])

      const isFetch = () => {
            refetch()
            refetchMP()
      }
      useEffect(() => {
            dispatch(staffActionCreators.getStaffDetail(parseInt(staffId)));
      }, [])

      return (
            <>
                  <PageTitle>Manager Detail</PageTitle>
                  <div className='row g-5 g-xl-8'>
                        <Card className="shadow">
                              <Card.Body className='p-0'>
                                    <ProfileTab modalOpen={setEditDialogOpen} />
                                    <Tabs defaultActiveKey="property-list" id="uncontrolled-tab-example" className="mb-3 mt-3" onSelect={handleChangeTab}>
                                          <Tab eventKey="property-list" title="Properties">
                                                {selectedTab === 'property-list' &&
                                                      <div className="row my-10 justify-content-center w-full">
                                                            <div style={{ borderRadius: 7 }} className="col-6 p-5 border">
                                                                  {<ManagerProperties refetch={isFetch} staffId={Number(staffId)} data={AllProperties?.data?.data} />}
                                                            </div>
                                                            <div style={{ borderRadius: 7 }} className="col-6 p-5 border">
                                                                  {<ManagerPropertiesAssigned refetch={isFetch} staffId={Number(staffId)} data={managerProperties?.data?.data} />}
                                                            </div>
                                                      </div>
                                                }
                                          </Tab>
                                          <Tab eventKey="cities-permission" title="Cities">
                                                {selectedTab === 'cities-permission' && <CitiesPermission />}
                                          </Tab>
                                    </Tabs>
                              </Card.Body>
                        </Card>
                  </div>
                  <StaffEditDialog modalClose={setEditDialogOpen} modalOpen={editDialogOpen} />
                  <StaffPasswordEdit />
                  <DeleteDialog />
            </>
      )

}
export default ManagerDetail