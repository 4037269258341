import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressAction } from '../../../redux/actionTypes/addressTypes';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import * as addressActionCreators from '../../../redux/actionCreators/addressActionCreators';
import AddressTable from './AddressTable';

const AddressTab: React.FC = () => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { addressList, loading } = useSelector((state: RedusxAppState) => state.address);
    const dispatch = useDispatch<Dispatch<AddressAction>>();

    useEffect(() => {
        dispatch(addressActionCreators.getUserAddresses(user.user.id));
    }, [])

    return (
        <div>
            <AddressTable data={addressList} />
            <div className='d-flex justify-content-end mt-6'>
                <button type='button' className='btn btn-success' onClick={() => { dispatch(addressActionCreators.openAddressDialog(null)) }}>Add New Address</button>
            </div>
        </div>
    )
}

export default AddressTab;