import React, { useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import './style.css';
import { getDateFilter, onExporting } from '../../../utils/util';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';

type Props = {
    data: any,
    selectType: 'single' | 'multiple'
    onSelectionChanged: (data: any) => void
}

const PropertyListTable: React.FC<Props> = ({ data, onSelectionChanged, selectType }) => {
    const tableRef = useRef<DataGrid>(null)

    if (data?.length === 0) {
        return <EmptyTable type='property' />
    }

    return (
        <div className="w-100 position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="propertyTable"
                keyExpr="pmsReferenceId"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(48vh)'}
                allowColumnResizing={true}
                onSelectionChanged={onSelectionChanged}
                onExporting={(e) => onExporting(e, "Properties")}
            >
                <Export enabled />
                <div></div>
                <Selection
                    mode={selectType}
                    showCheckBoxesMode='always'
                />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Properties: {0}" column='address' summaryType='count' />
                </Summary>

                <Column dataField="address" minWidth={450} cssClass='cls' />
                <Column dataField="state" cssClass='cls' minWidth={150} />
                <Column dataField="city" cssClass='cls' minWidth={150} />
                <Column dataField="description" cssClass='cls' minWidth={200} />

                <Column dataField="defaultCheckInTime" alignment='center' caption='D. Check-In' dataType='time' minWidth={150} cssClass='cls'>
                    <HeaderFilter dataSource={getDateFilter('defaultCheckInTime')} />
                </Column>
                <Column dataField="defaultCheckOutTime" alignment='center' caption='D. Check-Out' dataType='time' minWidth={150} cssClass='cls'>
                    <HeaderFilter dataSource={getDateFilter('defaultCheckOutTime')} />
                </Column>
                <Column dataField="numberOfBedrooms" alignment='center' caption='Bedrooms' dataType='number' cssClass='cls' minWidth={150} />
                <Column dataField="numberOfBathrooms" alignment='center' caption='Bathrooms' dataType='number' cssClass='cls' minWidth={150} />
                <Column dataField="numberOfBeds" alignment='center' caption='Beds' dataType='number' cssClass='cls' minWidth={150} />
                <Column dataField="zipCode" alignment='center' cssClass='cls' minWidth={150} />
            </DataGrid>

        </div>
    )
}

export default React.memo(PropertyListTable);