import {Switch} from '@material-ui/core'
import {AxiosResponse} from 'axios'
import React, {Dispatch, ReactElement, useEffect, useRef, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import Select, {components} from 'react-select'
import {selectStyles} from '../../../../../../../config/selectStyle'
import {useDialog} from '../../../../../../../contexts/DialogContext'
import {AccountStatusObj} from '../../../../../../../enums/AccountEnum'
import {JobStatusObj} from '../../../../../../../enums/JobEnum'
import {IChecklistItem, IHostPropertyCheckList} from '../../../../../../../interfaces/Checklist'
import {IHostPropertyOffer} from '../../../../../../../interfaces/Property'
import {IService} from '../../../../../../../interfaces/ServiceType'
import {IJobService, IStaff} from '../../../../../../../interfaces/Staff'
import ApiCalls from '../../../../../../../network/ApiCalls'
import {
  JobService,
  ManualJobRequest,
} from '../../../../../../../network/PostRequestModels/JobRequests'
import * as jobActionCreator from '../../../../../../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../../../../../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../../../../../../redux/reducers/rootReducer'
import {encryptText, convertUTCDateToLocalDate} from '../../../../../../../utils/util'

import ServiceItem from './ServiceItem'
import JobDialog from '../../../../../../../pages/jobs/JobDialog'
import {TextInput} from '../../../../../../../umut-components/Inputs/TextInput'
import ArrowButton from '../../../../../../../umut-components/Buttons/ArrowButton'
import EmptyData from '../../../../../../../umut-components/Icons/EmptyData'

type Props = {
  nextStep?: () => void
  previousStep?: () => void
}

type DateTime = {
  date?: string | null
  time?: string | null
}

type ErrorTextProps = {
  hostPropertyId: any
}

type QuestionErrorTextProps = {
  checklistId: any
}
const StaffErrorText = () => {
  return (
    <strong className='text-danger w-100 text-break'>
      There is no staff assigned for this property who can provide this service.{' '}
      <a target='_blank' href={`/staff`}>
        Please add a staff or add this service to one of your existing staff.
      </a>
    </strong>
  )
}

const ChecklistErrorText: React.FC<ErrorTextProps> = ({hostPropertyId}) => {
  return (
    <strong className='text-danger w-100 text-break'>
      Cannot found checklist for this service type and role.{' '}
      <a href={`/checklists/${encryptText(hostPropertyId)}`} target='_blank'>
        Please add checklist first.
      </a>
    </strong>
  )
}

const ChecklistQuestionErrorText: React.FC<QuestionErrorTextProps> = ({checklistId}) => {
  return (
    <strong className='text-danger w-100 text-break'>
      Cannot found checklist item for this checklist.{' '}
      <a target='_blank' href={`/checklist-sections/${encryptText(checklistId)}`}>
        Please add checklist item first.
      </a>
    </strong>
  )
}
const ServiceStep: React.FC<Props> = ({nextStep, previousStep}) => {
  const {showSuccessDialog} = useDialog()
  const serviceRef = useRef<any>(null)
  const staffRef = useRef<any>(null)
  const checklistRef = useRef<any>(null)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    jobDialog: {jobForm},
    selectedJobTab,
  } = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [serviceErrorText, setServiceErrorText] = useState<string | null>(null)
  const [staffErrorText, setStaffErrorText] = useState<any>(null)
  const [staffErrorCompoment, setStaffErrorCompoment] = useState<ReactElement | null>(null)
  const [timeErrorText, setTimeErrorText] = useState<string | null>(null)
  const [checklistErrorText, setChecklistErrorText] = useState<any>(null)
  const [services, setServices] = useState([])
  const [staffs, setStaffs] = useState<any>([])
  const [checklists, setChecklists] = useState([])
  const [selectedService, setSelectedService] = useState<any>([])
  const [selectedServiceType, setSelectedServiceType] = useState<any>(null)
  const [selectedChecklist, setSelectedChecklist] = useState<IHostPropertyCheckList | null>(null)
  const [selectedNote, setSelectedNote] = useState<string | null>(null)
  //const [startDate, setStartDate] = useState<string | null>(null);
  // const {
  //   data: serviceData,
  //   isLoading: serviceLoading,
  //   error: serviceError,
  // } = useQuery(
  //   ['Get Services for Job', jobForm?.staffSectionActive, jobForm?.hostPropertyId],
  //   () =>
  //     jobForm?.staffSectionActive
  //       ? ApiCalls.getOfferedServicesByHostId(user.userSection.hostId)
  //       : jobForm?.isMarketplace
  //       ? ApiCalls.getHostPropertyMarketplaceOffers({hostPropertyId: jobForm?.hostPropertyId})
  //       : ApiCalls.getHostPropertyServiceOffers(jobForm?.hostPropertyId),
  //   {
  //     cacheTime: 500000,
  //     refetchOnWindowFocus: true,
  //     enabled: jobForm?.hostPropertyId ? true : false,
  //   }
  // )

  const {
    data: serviceData,
    isLoading: serviceLoading,
    error: serviceError,
  } = useQuery(
    ['Get Services for Job', jobForm?.staffSectionActive, jobForm?.hostPropertyId],
    () =>
      jobForm?.staffSectionActive
        ? ApiCalls.getOfferedServicesByHostId(user.userSection.hostId)
        : jobForm?.isMarketplace
        ? ApiCalls.getHostPropertyMarketplaceOffers({hostPropertyId: jobForm?.hostPropertyId})
        : ApiCalls.getHostPropertyServiceRates(jobForm?.hostPropertyId),
    {cacheTime: 500000, refetchOnWindowFocus: true, enabled: jobForm?.hostPropertyId ? true : false}
  )

  const {
    data: staffData,
    isLoading: staffLoading,
    error: staffError,
    isFetching: staffFetching,
    refetch: refetchStaffs,
  } = useQuery(
    [
      'Get Staffs for Job',
      user.userSection.hostId,
      jobForm?.hostPropertyId,
      selectedServiceType?.serviceTypeId,
      jobForm?.roleId,
      jobForm?.cleaningPeriodStart,
    ],
    () =>
      ApiCalls.getOfferedStaffs(
        user.userSection.hostId,
        jobForm?.hostPropertyId,
        selectedServiceType?.serviceTypeId,
        jobForm?.roleId,
        jobForm?.cleaningPeriodStart
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled:
        jobForm?.hostPropertyId &&
        selectedServiceType?.serviceTypeId &&
        jobForm?.roleId &&
        jobForm?.cleaningPeriodStart
          ? true
          : false,
    }
  )
  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    isFetching: checklistFetching,
    refetch: refetchChecklists,
  } = useQuery(
    'Get Checklist for Job',
    () =>
      ApiCalls.getHostPropertyChecklists(
        jobForm?.hostPropertyId,
        jobForm?.roleId,
        selectedServiceType?.serviceTypeId
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled:
        jobForm?.hostPropertyId && jobForm.roleId && selectedServiceType?.serviceTypeId
          ? true
          : false,
    }
  )
  const {data: notVerifiedStaff} = useQuery(
    ['Get Staff Email Not Verified', user.userSection.hostId],
    () =>
      ApiCalls.getStaffEmailNotVerified(
        user.userSection.hostId,
        AccountStatusObj['EmailNotVerified']
      ),
    {enabled: user.userSection.hostId ? true : false}
  )
  const {data: allStaffByVendorId} = useQuery(['Get All Staff'], () =>
    ApiCalls.getStaff(user.userSection.hostId)
  )

  const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null)
  const queryClient = useQueryClient()
  const {mutateAsync: addJobMutate} = useMutation(ApiCalls.addJob, {
    onSuccess: () =>
      queryClient.invalidateQueries(['Get Jobs By Id', JobStatusObj[selectedJobTab]]),
  })

  const handleAddJob = async () => {
    setLoading(true)
    setErrorText(null)

    if (jobForm?.isMarketplace) {
      try {
        let requestServices: JobService[] = jobForm?.jobServices
          ? jobForm?.jobServices?.map((service) => {
              return {
                staffOfferedServiceId: service.staffOfferedServiceId
                  ? service.staffOfferedServiceId
                  : undefined,
                serviceNote: service.serviceNote ? service.serviceNote : undefined,
                serviceTypeId: service.serviceTypeId,
                hostPropertyChecklistId: service.hostPropertyChecklistId
                  ? service.hostPropertyChecklistId
                  : undefined,
                vendorId: service.vendorId ? service.vendorId : undefined,
              }
            })
          : []
        let requestData: ManualJobRequest = {
          hostPropertyId: jobForm?.hostPropertyId,
          cleaningPeriodStart: jobForm?.cleaningPeriodStart,
          isManuallyCreated: true,
          vendorId: jobForm?.jobServices ? jobForm?.jobServices[0]?.vendorId : undefined,
          roleId: jobForm?.staffSectionActive ? jobForm?.roleId : 11, //roleId of 3 refers to Cleaner/Host
          jobServices: [...requestServices],
        }

        await ApiCalls.createMarketplaceJob(requestData)
        setLoading(false)
        dispatch(jobActionCreator.closeJobDialog())
        showSuccessDialog('Job added successfully.')
      } catch (err: any) {
        setLoading(false)
        if (
          err?.response?.data?.message === 'Host PropertyChecklist was not found in the database!'
        ) {
          setErrorText('Cannot found checklist for this service type. Please add checklist.')
        } else {
          setErrorText(err?.response?.data?.message)
        }
      }
    } else {
      try {
        let requestServices: JobService[] = jobForm?.jobServices
          ? jobForm?.jobServices?.map((service) => {
              return {
                staffOfferedServiceId: service.staffOfferedServiceId
                  ? service.staffOfferedServiceId
                  : undefined,
                serviceNote: service.serviceNote ? service.serviceNote : undefined,
                serviceTypeId: service.serviceTypeId,
                hostPropertyChecklistId: service.hostPropertyChecklistId
                  ? service.hostPropertyChecklistId
                  : undefined,
              }
            })
          : []
        let requestData: ManualJobRequest = {
          hostPropertyId: jobForm?.hostPropertyId,
          cleaningPeriodStart: jobForm?.cleaningPeriodStart,
          isManuallyCreated: true,
          roleId: jobForm?.staffSectionActive ? jobForm?.roleId : 11, //roleId of 3 refers to Cleaner/Host
          jobServices: [...requestServices],
        }
        await addJobMutate(requestData)
        setLoading(false)
        dispatch(jobActionCreator.closeJobDialog())
        showSuccessDialog('Job added successfully.')
      } catch (err: any) {
        setLoading(false)
        if (
          err?.response?.data?.message === 'Host PropertyChecklist was not found in the database!'
        ) {
          setErrorText('Cannot found checklist for this service type. Please add checklist.')
        } else {
          setErrorText(err?.response?.data?.message)
        }
      }
    }
  }

  /*const timeCompare = () => {
        if (startDate) {

            if (dayjs(jobForm?.cleaningPeriodStart).set('hours', parseInt(startDate.split(':')[0])).set('minutes', parseInt(startDate.split(':')[1])) > dayjs(jobForm?.cleaningPeriodStart)) {
                setTimeErrorText(null);
            }
            else {
                setTimeErrorText('Service time cannot be earlier than job start time!');
            }
        }
    }
    useEffect(timeCompare, [startDate]);*/

  const handleAddService = () => {
    let prevServices = jobForm?.jobServices ? [...jobForm?.jobServices] : []

    if (!jobForm?.staffSectionActive) {
      let newServices = [...prevServices]
      dispatch(
        jobActionCreator.setJobForm({
          ...jobForm,
          jobServices: [
            ...prevServices,
            {
              serviceTypeId: selectedServiceType.serviceTypeId,
              serviceTypeName: selectedServiceType.serviceTypeName,
              vendorId: selectedServiceType.offeredVendorId
                ? selectedServiceType.offeredVendorId
                : undefined,
              serviceNote: selectedNote ? selectedNote : undefined,
              servicePrice: selectedServiceType?.servicePrice,
              hostPropertyChecklistId: selectedChecklist?.id ?? undefined,
              hostPropertyChecklistName: selectedChecklist
                ? selectedChecklist?.name?.en
                  ? selectedChecklist?.name?.en
                  : selectedChecklist?.name[Object.keys(selectedChecklist?.name)[0]]
                : 'Default Checklist',
            },
          ],
        })
      )
    } else {
      let jobServices = selectedService.map((service: any) => {
        return {
          ...service,
          hostPropertyChecklistId: selectedChecklist?.id ?? undefined,
          hostPropertyChecklistName: selectedChecklist
            ? selectedChecklist?.name?.en
              ? selectedChecklist?.name?.en
              : selectedChecklist?.name[Object.keys(selectedChecklist?.name)[0]]
            : 'Default Checklist',
          serviceNote: selectedNote,
          servicePrice: service?.servicePrice,
        }
      })
      dispatch(
        jobActionCreator.setJobForm({...jobForm, jobServices: [...prevServices, ...jobServices]})
      )
    }
    staffRef?.current?.select?.clearValue()
    serviceRef?.current?.select?.clearValue()
    checklistRef.current?.select?.clearValue()
    setSelectedService([])
    setSelectedServiceType(null)
    setChecklistErrorText(null)
  }

  const handleSelectStaff = (values: any) => {
    setSelectedService(
      values.length > 0
        ? values.map((value: any) => {
            return {
              staffOfferedServiceId: value.value.id,
              serviceTypeId: value.value.serviceTypeId,
              serviceTypeName: value.value.serviceTypeName,
              firstName: value.value.firstName,
              lastName: value.value.lastName,
              profilePhotoPath: value.value.profilePhotoPath,
              duration: value.value.duration,
              servicePrice: value.value.servicePrice,
            }
          })
        : []
    )
  }

  const handleNoteChange = (text: any) => {
    setSelectedNote(text)
  }

  const handleChangeService = (value: any) => {
    if (value?.value) {
      var selectedData: any = {
        serviceTypeId: value.value.serviceTypeId,
        serviceTypeName: value.value.serviceTypeName,
        servicePrice: value.value.offeredServicePrice,
      }
      if (value?.value?.offeredVendorId) {
        selectedData.offeredVendorId = value?.value?.offeredVendorId
      }
    }

    setSelectedServiceType(selectedData)
  }

  const handleChangeChecklist = (value: any) => {
    value?.value && setSelectedChecklist(value.value)
  }

  useEffect(() => {
    if (serviceData) {
      setServices(
        serviceData?.data?.data?.map((type: any) => {
          return {
            value: type,
            label:
              type.serviceTypeName +
              (type?.offeredServicePrice ? ` - $${type?.offeredServicePrice}` : '') +
              (type?.vendorName ? ` (${type?.vendorName})` : ''),
          }
        })
      )
      setSelectedService([])
      setSelectedServiceType(null)
      setSelectedChecklist(null)
      setSelectedNote(null)
      setStaffs([])
      setChecklists([])
      setErrorText(null)
      setChecklistErrorText(null)
      setStaffErrorText(null)
      staffRef?.current?.select?.clearValue()
      serviceRef?.current?.select?.clearValue()
      checklistRef.current?.select?.clearValue()
    }
  }, [serviceData])

  useEffect(() => {
    const notAvailableStaffs = allStaffByVendorId?.data?.data
      .filter((item: any) => !staffData?.data?.data.includes(item.userId))
      .map((hostStaff: any) => ({
        label: hostStaff.firstName + ' ' + hostStaff.lastName + ' (Not Available)',
        disabled: true,
        value: hostStaff,
      }))
    const availableStaffs = staffData?.data?.data.map((staffOfferedService: any) => {
      return {
        value: staffOfferedService,
        label:
          staffOfferedService.firstName +
          ' ' +
          staffOfferedService.lastName +
          ' - $' +
          staffOfferedService.servicePrice,
      }
    })
    const notVerifiedStaffs = notVerifiedStaff?.data.data.map((hostStaff: any) => ({
      label: hostStaff.firstName + ' ' + hostStaff.lastName + ' (Email Not Verified)',
      disabled: true,
      value: hostStaff,
    }))

    if (notAvailableStaffs && availableStaffs && notVerifiedStaffs) {
      setStaffs([...availableStaffs, ...notVerifiedStaffs, ...notAvailableStaffs])
      staffRef?.current?.select?.clearValue()
    }
  }, [staffData, notVerifiedStaff, allStaffByVendorId])

  useEffect(() => {
    if (checklistData) {
      setChecklists(
        checklistData?.data.map((checklist: IChecklistItem) => {
          return {
            value: checklist,
            label: checklist?.name?.en
              ? checklist?.name?.en
              : checklist?.name[Object.keys(checklist?.name)[0]],
          }
        })
      )
      setChecklistErrorText(
        checklistData?.data?.length > 0 ? null : (
          <ChecklistErrorText hostPropertyId={jobForm?.hostPropertyId} />
        )
      )
      checklistRef.current?.select?.clearValue()
    }
  }, [checklistData])

  useEffect(() => {
    if (selectedChecklist) {
      setChecklistErrorText(
        parseInt(selectedChecklist.questionCount) === 0 ? (
          <ChecklistQuestionErrorText checklistId={selectedChecklist.id} />
        ) : null
      )
    }
  }, [selectedChecklist])

  useEffect(() => {
    if (staffData) {
      setStaffErrorCompoment(parseInt(staffData?.data?.count) === 0 ? <StaffErrorText /> : null)
    }
  }, [staffData])

  useEffect(() => {
    if (selectedServiceType?.serviceTypeId && jobForm?.roleId && jobForm?.hostPropertyId) {
      refetchStaffs()
    }
  }, [selectedServiceType, jobForm?.roleId, jobForm?.hostPropertyId])

  useEffect(() => {
    if (jobForm?.roleId && jobForm?.hostPropertyId) {
      setErrorText(null)
      setServiceErrorText(null)
      setChecklistErrorText(null)
      setSelectedServiceType(null)
    }
  }, [jobForm?.roleId, jobForm?.hostPropertyId])

  useEffect(() => {
    if (jobForm?.roleId && jobForm?.hostPropertyId && selectedServiceType?.serviceTypeId) {
      refetchChecklists()
      setSelectedChecklist(null)
      setChecklistErrorText(null)
    }
  }, [jobForm?.roleId, jobForm?.hostPropertyId, selectedServiceType])

  useEffect(() => {
    if (selectedServiceType?.serviceTypeId && !jobForm?.staffSectionActive) {
      setServiceErrorText(
        jobForm?.jobServices?.some(
          (service: any) => service?.serviceTypeId == selectedServiceType?.serviceTypeId
        )
          ? 'Service already exists.'
          : null
      )
    }
  }, [selectedServiceType])

  useEffect(() => {
    setStaffErrorText(
      jobForm?.staffSectionActive &&
        jobForm?.jobServices?.some((service) =>
          selectedService?.some(
            (selected: any) => selected?.staffOfferedServiceId === service.staffOfferedServiceId
          )
        )
        ? 'Staff already selected'
        : null
    )
  }, [selectedService])

  useEffect(() => {
    return () => {
      setErrorText(null)
      setServiceErrorText(null)
      setChecklistErrorText(null)
    }
  }, [])
  useEffect(() => {
    if (selectedServiceType && serviceData?.data?.data) {
      const selectedValue: IHostPropertyOffer = serviceData?.data?.data?.find(
        (service: IHostPropertyOffer) => service.serviceTypeId === selectedServiceType.serviceTypeId
      )
      setSelectedServiceDescription(selectedValue?.serviceTypeDescription)
    } else {
      setSelectedServiceDescription(null)
    }
  }, [selectedService, selectedServiceType])

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No services found.</span>
      </components.NoOptionsMessage>
    )
  }
  const NoOptionsMessageStaff = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No suitable staff.</span>
      </components.NoOptionsMessage>
    )
  }
  const NoOptionsMessageChecklist = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No checklist found. Please add checklist </span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div className='row d-flex flex-wrap'>
      <div className='col-6' style={{position: 'relative'}}>
        <div className='fv-row col-lg-12' style={{maxHeight: 500, overflowY: 'auto'}}>
          {jobForm?.jobServices && jobForm?.jobServices?.length > 0 ? null : (
            <EmptyData emptyText='No Services Selected' />
          )}
          {jobForm?.jobServices?.map((jobService: IJobService, index: number) => (
            <ServiceItem jobService={jobService} index={index} />
          ))}
          <strong style={{position: 'absolute', bottom: 0, right: 20}}>
            Total: $
            {jobForm?.jobServices?.reduce((sum, item) => {
              return sum + item.servicePrice
            }, 0)}
          </strong>
        </div>
      </div>
      <div className='col-6 border-start'>
        <div className='row'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Services {<span style={{color: 'red'}}>*</span>}
          </label>
          <div className='col-12'>
            <Select
              components={{NoOptionsMessage}}
              styles={selectStyles}
              ref={serviceRef}
              onChange={handleChangeService}
              options={services}
            />
            {selectedServiceDescription && (
              <span className='text-muted m-1'>{selectedServiceDescription}</span>
            )}
          </div>
          {serviceErrorText ? (
            <strong className='text-danger w-100 text-break'>{serviceErrorText}</strong>
          ) : null}
          {jobForm?.staffSectionActive ? (
            <>
              <label className='form-label fs-6 fw-bolder text-dark mt-6'>
                Staff {<span style={{color: 'red'}}>*</span>}
              </label>
              <div className='col-12'>
                {staffFetching ? (
                  <div>Please Wait...</div>
                ) : (
                  <Select
                    components={{NoOptionsMessage: NoOptionsMessageStaff}}
                    styles={selectStyles}
                    isMulti
                    ref={staffRef}
                    onChange={handleSelectStaff}
                    options={staffs}
                    isOptionDisabled={(option: any) => option.disabled}
                  />
                )}
              </div>
              {staffErrorCompoment ? staffErrorCompoment : null}
            </>
          ) : null}
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Checklist</label>
          <div className='col-12'>
            {checklistFetching ? (
              <div>Please Wait...</div>
            ) : (
              <Select
                components={{NoOptionsMessage: NoOptionsMessageChecklist}}
                styles={selectStyles}
                ref={checklistRef}
                onChange={handleChangeChecklist}
                options={checklists}
              />
            )}
          </div>
          {checklistErrorText ? checklistErrorText : null}
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Note</label>
          <div className='col-12'>
            <TextInput onValueChange={handleNoteChange} />
          </div>
          <div className='d-flex justify-content-start mt-6'>
            <button
              className='btn btn-info btn-sm'
              disabled={
                (jobForm?.staffSectionActive && selectedService.length === 0) ||
                !selectedServiceType ||
                serviceErrorText !== null ||
                checklistFetching ||
                staffFetching ||
                staffErrorText !== null ||
                !!timeErrorText ||
                (checklistData?.data.length > 0 && !selectedChecklist)
              }
              onClick={handleAddService}
            >
              Add Service to List
            </button>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-6'>
        <ArrowButton type='back' func={previousStep} />
        <button
          type='button'
          style={{height: 'fit-content'}}
          className='btn btn-success'
          disabled={!jobForm?.jobServices || jobForm?.jobServices?.length === 0 || loading}
          onClick={handleAddJob}
        >
          Save Job {loading ? <Spinner animation='border' size='sm' /> : null}
        </button>
      </div>
      <div className='d-flex justify-content-end mt-3'>
        <strong className='text-danger'>{errorText}</strong>
      </div>
    </div>
  )
}

export default ServiceStep
