import { Dispatch, FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators';
import { Modal } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { useDispatch, useSelector } from 'react-redux';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import StaffOfferedForm from '../../../../umut-components/Forms/StaffOfferedForm';

type Props = {
}
const OfferedDialog: FC<Props> = () => {
    const { staffOfferedDialog, staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const closeModal = () => {
        dispatch(staffActionCreator.closeStaffOfferedAddDialog());
    }

    return (
        <div>
            <Modal show={staffOfferedDialog.modalOpen} onHide={closeModal} size='lg' centered>
                <ModalHeader closeButton>
                    <Modal.Title>Select the areas that {staffDetail.staff?.firstName + ' ' + staffDetail.staff?.lastName} specializes in.</Modal.Title>
                </ModalHeader>
                <Modal.Body className='pt-0'>
                    {staffOfferedDialog.modalOpen ? <StaffOfferedForm /> : null}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OfferedDialog
