import React, {Dispatch, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {
  IHostPropertyInput,
  IHostPropertyManualInput,
  IHostPropertyAutomation,
} from '../../../interfaces/Property'
import {useState} from 'react'
import {Card, Spinner, Tab, Tabs} from 'react-bootstrap-v5'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useDialog} from '../../../contexts/DialogContext'
import {useHistory} from 'react-router-dom'
import StepNav from './Steps/StepNav/StepNav'
import dayjs from 'dayjs'
import PropertyInfoStep from './Steps/PropertyInfoStep'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import AutomationStep from './Steps/AutomationStep'
import PaymentStep from './Steps/PaymentStep'

type Props = {
  propertyId?: number
  type: 'pms' | 'manual'
  editModalClose?: () => void
}

type TabNames = 'property-detail' | 'service-provider' | 'staffs-and-roles' | 'checklists'

const navTabs = ['Property Info', 'Automation Settings', 'Payment Settings']

const AddNewPropertyManuallyWizard = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessPropertyDialog, showFailureDialog, openLoadingModal} = useDialog()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState<TabNames>('property-detail')
  const [savedValues, setSavedValues] = useState<IHostPropertyManualInput | null>(null)
  const [missingField, setMissingField] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(1)
  const [keyId, setKeyId] = useState(1)
  const [navTabNames, setNavTabNames] = useState(navTabs)

  const handleChangeHostProperty = (changedValues: IHostPropertyManualInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  useEffect(() => {
    if (activeStep == 3) {
      handleCreateProperty()
    }
  }, [savedValues])

  function isPropertyValid() {
    if (
      !savedValues?.address ||
      !savedValues?.zipCode ||
      !savedValues?.latitude ||
      !savedValues?.longitude ||
      !savedValues?.country ||
      !savedValues?.state ||
      !savedValues?.city
    ) {
      showFailureDialog('Please fill location informations!')
      return false
    }

    if (savedValues.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (!savedValues.hostPropertyAutomation?.isAutomaticSchedule) {
      delete savedValues.hostPropertyICals
    }

    if (savedValues.hostPropertyICals && savedValues.hostPropertyICals!.length > 0) {
      var icalList = savedValues.hostPropertyICals
      var emptyList = []
      icalList?.forEach((il, k) => {
        if (Object.keys(il).length === 0) {
          emptyList.push(k)
        }
      })

      if (emptyList.length == icalList?.length) {
        delete savedValues.hostPropertyICals
      }
    }

    return true
  }

  const handleCreateProperty = async () => {
    openLoadingModal('Please wait..')
    setTimeout(() => {
      setLoading(true)
      if (isPropertyValid()) {
        setMissingField(false)
        var name =
          savedValues?.address +
          ' ' +
          (savedValues?.suite
            ? '#' + savedValues?.suite
            : '#' + (Math.floor(Math.random() * (999999 - 100000)) + 100000))
        var requestData: IHostPropertyManualInput = {
          ...savedValues,
          name: name,
          cleaningProviderId:
            savedValues?.cleaningProviderId == 4 ? 2 : savedValues?.cleaningProviderId,
          isMarketplace: savedValues?.cleaningProviderId == 4 ? true : false,
        }

        ApiCalls.createNewHostProperty(requestData)
          .then((res) => {
            setLoading(false)
            showSuccessPropertyDialog(
              "Your property has been added successfully. You can now view and manage your property by accessing the dashboard. If you need further assistance, please don't hesitate to contact our support team."
            )
            history.push('/properties')
          })
          .catch((err) => {
            setLoading(false)
            if (
              err?.response?.data?.errors?.[0]?.message == 'icalUrl must be an URL address' ||
              err?.response?.data?.message == 'icalUrl must be an URL address'
            ) {
              showFailureDialog(
                'Error: Invalid iCal URL.',
                'Please enter a valid URL for the iCal feed. This should be a direct link to your calendar, ending with the file extension ".ics". Make sure there are no typos or extra characters in the URL.'
              )
            } else {
              showFailureDialog(
                err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
              )
            }
          })
      } else {
        setMissingField(true)
      }
    }, 1200)
  }

  const setCurrentStep = (d: any) => {
    setActiveStep(d.activeStep)
    setKeyId((keyId + 1) * d.activeStep)
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <div className='row mb-10'>
        <div className='col-10'></div>
        <div className='col-2'>
          <button
            className='btn btn-danger w-100'
            onClick={() => history.push('/add-property-from-pms')}
          >
            Add Property from PMS
          </button>
        </div>
      </div>
      <Card id={'cardbox-' + keyId}>
        <Card.Body>
          <StepWizard
            onStepChange={setCurrentStep}
            nav={<StepNav stepNames={navTabNames} />}
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            <PropertyInfoStep
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
            />
            <AutomationStep
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
              totalStep={navTabNames.length}
            />
            <PaymentStep
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
            />
          </StepWizard>
        </Card.Body>
      </Card>
    </>
  )
}

export default AddNewPropertyManuallyWizard
