import React, {Dispatch} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import ApiCalls from '../../../network/ApiCalls'
import {getStaffDetail} from '../../../redux/actionCreators/staffActionCreators'
import {StaffAction} from '../../../redux/actionTypes/staffActionTypes'
import StaffEditForm from '../../../umut-components/Forms/StaffEditForm'
import {IStaff} from '../../../interfaces/Staff'

type Props = {
  staff: IStaff
  modalOpen: boolean
  modalClose: (arg: boolean) => void
}

const HostStaffEditDialog: React.FC<Props> = ({modalOpen, modalClose, staff}) => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const {
    data: userDetailData,
    isLoading: userDetailLoading,
    error: isUserDetailError,
  } = useQuery(['Get Staff Detail', staff?.id], () => ApiCalls.getStaffDetails(staff?.id), {
    refetchOnWindowFocus: false,
  })

  const closeModal = () => {
    modalClose(false)
  }

  const successFunc = () => {
    staff && dispatch(getStaffDetail(staff?.id))
    modalClose(false)
  }

  return (
    <Modal show={modalOpen} onHide={closeModal} size='xl' centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Staff</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {userDetailLoading ? (
          'Loading...'
        ) : (
          <StaffEditForm
            selectStatusActive={true}
            user={userDetailData?.data}
            userId={staff?.id}
            successFunc={successFunc}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default HostStaffEditDialog
