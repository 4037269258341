import React, {Dispatch, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {IHostPropertyManualInput} from '../../interfaces/Property'
import {useState} from 'react'
import {Card, Spinner, Tab, Tabs} from 'react-bootstrap-v5'
import ApiCalls from '../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useDialog} from '../../contexts/DialogContext'
import {useHistory} from 'react-router-dom'
import StepNav from './StepNav/StepNav'
import dayjs from 'dayjs'
import {HostPropertyAction} from '../../redux/actionTypes/hostPropertyTypes'
import SelectPropertyStep from './StepTabs/SelectPropertyStep'
import ServiceProviderStep from './StepTabs/ServiceProviderStep'

type Props = {
  propertyId?: number
  type: 'pms' | 'manual'
  editModalClose?: () => void
}

type TabNames = 'property-detail' | 'service-provider'

const AddNewPropertyPms = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessPropertyDialog, showFailureDialog} = useDialog()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState<TabNames>('property-detail')
  const [savedValues, setSavedValues] = useState<IHostPropertyManualInput[]>([])
  const [missingField, setMissingField] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(1)

  const handleChangeHostProperty = (changedValues: IHostPropertyManualInput[]) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  useEffect(() => {
    if (activeStep == 2) {
      handleCreateProperty()
    }
  }, [savedValues])

  const isPropertiesValid = () => {
    var list = Object.keys(savedValues).map((k, i) => savedValues[i])

    list.forEach((sv) => {
      if (
        !sv?.address ||
        !sv?.zipCode ||
        !sv?.latitude ||
        !sv?.longitude ||
        !sv?.country ||
        !sv?.state ||
        !sv?.city
      ) {
        showFailureDialog('Please fill location informations!')
        return false
      }

      if (sv.sameDayBooking) {
        const checkinDate = dayjs()
          .set(
            'hours',
            sv?.hostPropertyAutomation?.checkInTime
              ? parseInt(sv.hostPropertyAutomation.checkInTime.split(':')[0])
              : 15
          )
          .set(
            'minutes',
            sv?.hostPropertyAutomation?.checkInTime
              ? parseInt(sv.hostPropertyAutomation.checkInTime.split(':')[1])
              : 0
          )
        const checkoutDate = dayjs()
          .set(
            'hours',
            sv?.hostPropertyAutomation?.checkOutTime
              ? parseInt(sv.hostPropertyAutomation.checkOutTime.split(':')[0])
              : 11
          )
          .set(
            'minutes',
            sv?.hostPropertyAutomation?.checkOutTime
              ? parseInt(sv.hostPropertyAutomation.checkOutTime.split(':')[1])
              : 0
          )
        if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
          showFailureDialog(
            'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
          )
          return false
        }
      }

      /*if (sv?.cleaningProviderId === 2 && !sv?.hostPropertyPaymentInfo) {
        showFailureDialog('Please select a payment card!')
        return false
      }

      if (sv?.cleaningProviderId === 1 && sv?.hostPropertyStaffs?.length == 0) {
        showFailureDialog('Please select a staff!')
        return false
      }

      if (sv?.cleaningProviderId === 1 && !sv?.hostPropertyChecklist) {
        showFailureDialog('Please select a checklist!')
        return false
      }*/
    })

    return true
  }

  const handleCreateProperty = async () => {
    if (isPropertiesValid()) {
      setLoading(true)
      setMissingField(false)
      try {
        var list = Object.keys(savedValues).map((k, i) => savedValues[i])
        list.forEach((p) => {
          let hasProQuoteRequest = p?.hasProQuoteRequest
          let hasPlusQuoteRequest = p?.hasPlusQuoteRequest
          let name = (p?.address ? p?.address : '') + (p?.suite ? '#' + p?.suite : '')
          const requestData: IHostPropertyManualInput = {
            ...p,
            name,
            hasProQuoteRequest: hasProQuoteRequest,
            hasPlusQuoteRequest: hasPlusQuoteRequest,
            cleaningProviderId:
              hasProQuoteRequest || hasPlusQuoteRequest ? 1 : p?.cleaningProviderId,
          }

          ApiCalls.createHostProperty(requestData)
            .then((res) => {
              setLoading(false)
              showSuccessPropertyDialog(
                "Your property has been added successfully. You can now view and manage your property by accessing the dashboard. If you need further assistance, please don't hesitate to contact our support team."
              )
              history.push('/properties')
            })
            .catch((err) => {
              setLoading(false)
              showFailureDialog(
                err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
              )
            })
        })
      } catch (error) {
        setLoading(false)
        showFailureDialog(error)
      }
    } else {
      setMissingField(true)
    }
  }

  const setCurrentStep = (d: any) => {
    setActiveStep(d.activeStep)
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <div className='row mb-10'>
        <div className='col-10'></div>
        <div className='col-2'>
          <button
            className='btn btn-danger w-100'
            onClick={() => history.push('/add-property-manually')}
          >
            Add Property Manually
          </button>
        </div>
      </div>
      <Card>
        <Card.Body>
          <StepWizard
            onStepChange={setCurrentStep}
            nav={<StepNav stepNames={['Select Property from PMS', 'Service Providers']} />}
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            <SelectPropertyStep
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              savedValues={savedValues}
            />
            <ServiceProviderStep
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
              savedValues={savedValues}
            />
          </StepWizard>
        </Card.Body>
      </Card>
    </>
  )
}

export default AddNewPropertyPms
