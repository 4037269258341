import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import {
  encryptText,
  toConvertedDate,
  onExporting,
  toAmazonUrl,
  getDateFilter,
  setDxFilterPlaceholder,
} from '../../utils/util'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {JobStatusObj, JobStatusType} from '../../enums/JobEnum'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import {useHistory} from 'react-router'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {
  closeCancelJobDialog,
  openCancelJobDialog,
} from '../../redux/actionCreators/jobActionCreators'
import config from '../../config/config.json'
type Props = {
  jobStatusId: number
}

const JobTable: React.FC<Props> = ({jobStatusId}) => {
  const history = useHistory()
  const [startColumn, setStartColumn] = useState<string>('')
  const [endColumn, setEndColumn] = useState<string>('')
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const tableRef = useRef<DataGrid>(null)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {data, isLoading, error, refetch} = useQuery(
    ['Get Jobs By Id', jobStatusId],
    () => ApiCalls.getJobsByStatusId(user.userSection.hostId, jobStatusId, null, null),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: user.userSection.hostId ? true : false,
    }
  )

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const displayDeleteButton = (cellData: any) => {
    return (
      <DeleteButton
        iconWidth='20'
        iconHeight='20'
        deleteFn={() => {
          dispatch(openCancelJobDialog(refetch, cellData?.data))
        }}
      />
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue[startColumn], cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue[endColumn], cellValue.hostPropertyCityTimeZone)
  }
  const calculateFullName = (cellValue: any) => {
    if (!cellValue?.cancelUser?.firstName) return 'System'
    return cellValue?.cancelUser?.firstName + ' ' + cellValue?.cancelUser?.lastName
  }
  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }

  const toJobCancelDetail = (cellData: any) => {
    if (cellData?.data?.id) {
      history.push({
        pathname: `/job-details/` + encryptText(cellData?.data?.id),
      })
    }
  }
  useEffect(() => {
    switch (jobStatusId) {
      case JobStatusObj.Pending:
        setStartColumn('cleaningPeriodStart')
        setEndColumn('cleaningPeriodEnd')
        break
      case JobStatusObj.Completed:
        setStartColumn('startedOn')
        setEndColumn('finishedOn')
        break
      default:
        setStartColumn('scheduledStart')
        setEndColumn('scheduledFinish')
        break
    }
  }, [jobStatusId])

  if (isLoading || startColumn === '' || endColumn === '') {
    return (
      <div className='mt-6'>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (data?.data?.data?.length === 0) {
    return <EmptyTable title='No Jobs' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id={'jobTable' + jobStatusId}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        className='mt-6'
        onCellClick={toJobCancelDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Cancelled Jobs')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='hostPropertyAddress'
          cellRender={isManuallyCreatedJob}
          minWidth={450}
          caption='Property'
          cssClass='cls'
        />
        <Column
          dataField='jobStatusTypeName'
          alignment='center'
          caption='Status'
          cellRender={displayStatusType}
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField={startColumn}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter(startColumn)} />
        </Column>
        <Column
          dataField={endColumn}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter(endColumn)} />
        </Column>
        <Column
          dataField='cancelUser.firstName'
          calculateCellValue={calculateFullName}
          caption='Cancelled By'
          minWidth={200}
          cssClass='cls'
        />

        <Column
          visible={
            jobStatusId === JobStatusObj['Pending'] || jobStatusId === JobStatusObj['Scheduled']
          }
          width={110}
          alignment='center'
          cellRender={displayDeleteButton}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default JobTable
