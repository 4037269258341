import React, { Dispatch, } from 'react'
import { useDispatch } from 'react-redux';
import { IJobDetail, IJobIssue } from '../../../../interfaces/Job';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { getTimeFromNow } from '../../../../utils/util';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';

type Props = {
    issue: IJobIssue,
    jobDetail: IJobDetail
    refetch?: () => void,
    jobEventsActive: boolean;
}

const IssueItem: React.FC<Props> = ({ issue, jobDetail }) => {
    const dispatch = useDispatch<Dispatch<JobAction>>();

    return (
        <div className='row p-3 border-bottom pb-6'>
            <div className='col-12 d-flex flex-row justify-content-between'>
                <div className='d-flex align-items-center'>
                    {jobDetail?.cleaningProviderId == "My Team" || jobDetail?.cleaningProviderId == "Turnify+" ? <ProfilePhotoTemp classNames='me-2' width={35} height={35} borderRadius='50%' profilePhotoPath={issue.reporter.profilePhotoPath} firstName={issue.reporter.firstName} lastName={issue.reporter.lastName} /> : <img style={{ width: 25, height: 25 }} alt="Turnify PRO" src="/media/logos/turnify-logo-title.png"></img>}
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                        {jobDetail?.cleaningProviderId == "My Team" || jobDetail?.cleaningProviderId == "Turnify+" ? <strong>{issue.reporter.firstName + ' ' + issue.reporter.lastName}</strong> : <strong style={{color:"#ff5d5d"}} className="ms-2 fs-5">Turnify Pro</strong>}
                        <small className='text-muted' style={{marginLeft:"9px"}}>{getTimeFromNow(issue.reporter.reportOn)}</small>
                    </div>
                </div>
            </div>
            <div className='col-12 mt-6 d-flex justify-content-between flex-wrap'>
                <strong className='col-9 text-muted text-break d-flex align-items-center'>{issue.note} {issue.jobIssueTypeName ? '(' + issue.jobIssueTypeName + ')' : null}</strong>
                <button type='button' className='col-3 btn btn-link btn-sm text-end' onClick={() => { dispatch(jobActionCreator.openIssueDialog(issue)) }}>Show Details</button>
            </div>
        </div>
    )
}

export default IssueItem;