import {Dispatch, FC, useEffect, useState} from 'react'
import {map} from 'rxjs/operators'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {StaffAction} from '../../../../../redux/actionTypes/staffActionTypes'
import * as staffActionCreator from '../../../../../redux/actionCreators/staffActionCreators'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import StaffOfferedForm from '../../../../../umut-components/Forms/StaffOfferedForm'
import {IOfferedService, IStaff} from '../../../../../interfaces/Staff'
import HostStaffOfferedServiceForm from '../../../../../umut-components/Forms/HostStaffOfferedServiceForm'
import {StaffOfferedRequest} from '../../../../../network/PostRequestModels'
import {NumberInput} from '../../../../../umut-components/Inputs/NumberInput'
import ApiCalls from '../../../../../network/ApiCalls'
import SelectInput from '../../../../../umut-components/Inputs/SelectInput'
import {useQuery} from 'react-query'

type Props = {staff: IStaff; service: any; handleClose: () => void; refetch: () => void}
const EditServiceRateDialog: FC<Props> = ({staff, service, handleClose, refetch}) => {
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [types, setTypes] = useState([])
  const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null)
  const [offeredRequest, setOfferedRequest] = useState<StaffOfferedRequest>({
    unitSize: 0,
    servicePrice: service.servicePrice,
    duration: service.duration,
    // serviceTypeId: service.serviceTypeId
    serviceTypeId: service.serviceTypeId ? service.serviceTypeId : 1,
  } as StaffOfferedRequest)

  const {
    data: serviceTypes,
    isLoading,
    error,
  } = useQuery('Offered Services Types', () => ApiCalls.getServiceTypes(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const closeModal = () => {
    handleClose()
  }

  useEffect(() => {
    if (serviceTypes) {
      setTypes(serviceTypes?.data?.data.map((type: any) => ({label: type.name, value: type.id})))
    }
  }, [serviceTypes])

  const handleSubmit = async () => {
    if (offeredRequest.duration && offeredRequest.serviceTypeId) {
      try {
        setLoading(true)
        const data = {
          ...offeredRequest,
          staffType: service.staffType,
          userId: staff.userId,
          offerId: service.offerId,
        }
        await ApiCalls.updateStaffServiceRate(data)
        setLoading(false)
        refetch()
        dispatch(staffActionCreator.closeStaffOfferedAddDialog())
      } catch (error: any) {
        setLoading(false)
        setErrorMessage('An error occur during updating service rate')
      }
    } else {
      setErrorMessage('All fields required.')
    }
  }

  return (
    <div>
      <Modal show={true} onHide={closeModal} size='lg' centered>
        <ModalHeader closeButton>
          <Modal.Title>
            Edit the areas that {staff?.firstName + ' ' + staff?.lastName.substring(0, 1) + '.'}{' '}
            specializes in.
          </Modal.Title>
        </ModalHeader>
        <Modal.Body className='pt-0'>
          <div className='row'>
            <div className='col-12'>
              <label className='form-label fs-6 fw-bolder text-dark mt-6 '>
                Service Type {<span style={{color: 'red'}}>*</span>}
              </label>
              <SelectInput
                disabled={true}
                defaultValue={offeredRequest.serviceTypeId}
                options={types}
                onValueChanged={(value) => {
                  setOfferedRequest({...offeredRequest, serviceTypeId: value})
                }}
              />
              {selectedServiceDescription && (
                <span className='text-muted m-1 '>{selectedServiceDescription}</span>
              )}
              <div></div>
              <label className='form-label fs-6 fw-bolder text-dark mt-6'>
                Service Price (Optional){' '}
              </label>
              <NumberInput
                disabled={service.staffType != 'myteam'}
                defaultValue={offeredRequest.servicePrice}
                isErrorMessageActive={false}
                min={1}
                max={1000}
                placeholder='Service Price'
                onValueChange={(text: number) =>
                  setOfferedRequest({...offeredRequest, servicePrice: text ? text : undefined})
                }
              />

              <label className='form-label fs-6 fw-bolder text-dark mt-6 w-100'>
                Duration (mins) {<span style={{color: 'red'}}>*</span>}
              </label>
              <span className='text-muted me-3'>
                Approximate duration for completing this service
              </span>
              <NumberInput
                disabled={service.staffType != 'myteam'}
                defaultValue={offeredRequest.duration}
                min={1}
                placeholder='Duration'
                onValueChange={(text: number) =>
                  setOfferedRequest({...offeredRequest, duration: text})
                }
              />
            </div>
          </div>
        </Modal.Body>
        {service.staffType == 'myteam' && (
          <Modal.Footer className='d-flex justify-content-end'>
            <button className='btn btn-link me-2' onClick={closeModal}>
              Cancel
            </button>
            <button className='btn btn-sm btn-primary' onClick={handleSubmit}>
              Apply
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  )
}

export default EditServiceRateDialog
