import React, {Dispatch, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AddressAction} from '../../../redux/actionTypes/addressTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import * as addressActionCreators from '../../../redux/actionCreators/addressActionCreators'
import SwitchBox from '../../../components/Inputs/SwitchBox'
import {IHostFeedSetting} from '../../../interfaces/Feed'
import ApiCalls from '../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {useDialog} from '../../../contexts/DialogContext'

const FeedSettings: React.FC = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [keyid, setKeyid] = useState(0)
  const [feedSetting, setFeedSetting] = useState<IHostFeedSetting>({
    id: 0,
    hostId: 0,
    jobSchedulingIssue: true,
    delayedJobFinish: true,
    jobCancelled: true,
    jobScheduled: true,
    delayedJobStart: true,
    jobFinished: true,
    jobStarted: true,
    jobTimeChanged: true,
    reportProblem: true,
  })
  const {showSuccessDialog, showFailureDialog} = useDialog()

  const {
    data: hostFeedSettings,
    isFetching: hostFeedSettingsLoading,
    error: hostFeedSettingsError,
    refetch: refetchHostFeedSettings,
  } = useQuery<any>(
    'Get Host Feed Settings',
    () => ApiCalls.getHostFeedSettings(user.userSection.hostId),
    {cacheTime: 500000, enabled: user ? true : false}
  )

  useEffect(() => {
    if (!hostFeedSettingsLoading && hostFeedSettings) {
      setFeedSetting({...hostFeedSettings.data})
      setKeyid(keyid + 1)
    }
  }, [hostFeedSettingsLoading, hostFeedSettings])

  useEffect(() => {
    setKeyid(keyid + 1)
  }, [feedSetting])

  const onJobScheduledChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobScheduled = checked
    setFeedSetting(rawData)
  }

  const onJobSchedulingIssueChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobSchedulingIssue = checked
    setFeedSetting(rawData)
  }

  const onJobStartChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobStarted = checked
    setFeedSetting(rawData)
  }

  const onDelayedJobStartChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.delayedJobStart = checked
    setFeedSetting(rawData)
  }

  const onJobFinishChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobFinished = checked
    setFeedSetting(rawData)
  }

  const onDelayedJobFinishChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.delayedJobFinish = checked
    setFeedSetting(rawData)
  }

  const onJobCancelledChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobCancelled = checked
    setFeedSetting(rawData)
  }

  const onJobTimeChangedChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.jobTimeChanged = checked
    setFeedSetting(rawData)
  }

  const onReportProblemChange = (checked: boolean) => {
    const rawData = Object.assign({}, feedSetting)
    rawData.reportProblem = checked
    setFeedSetting(rawData)
  }

  const updateSetting = async () => {
    try {
      await ApiCalls.updateHostFeedSettings(feedSetting)
      refetchHostFeedSettings()
      showSuccessDialog('Your notification settings have been successfully updated.')
    } catch (error) {
      showFailureDialog('An error occur during updating your notification settings!')
    }
  }

  return (
    <>
      <div className='row mt-6 p-4' key={'setting-list-' + keyid}>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Schedule</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobScheduled}
            text='When enabled, you will be notified of job assignments for your properties.'
            onCheckboxChange={onJobScheduledChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Schedule Issue</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobSchedulingIssue}
            text='When enabled, you will be notified of work assignment issues for your properties.'
            onCheckboxChange={onJobSchedulingIssueChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Start</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobStarted}
            text='When enabled, you will be notified when jobs for your properties are started.'
            onCheckboxChange={onJobStartChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Delayed Job Start</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.delayedJobStart}
            text='When enabled, you will be notified when jobs for your properties start late.'
            onCheckboxChange={onDelayedJobStartChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Finish</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobFinished}
            text='When enabled, you will be notified when work on your properties is finished.'
            onCheckboxChange={onJobFinishChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Delayed Job Finish</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.delayedJobFinish}
            text='When enabled, you will be notified when work on your properties is finished late.'
            onCheckboxChange={onDelayedJobFinishChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Cancel</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobCancelled}
            text='When enabled, you will be notified when jobs for your properties are canceled.'
            onCheckboxChange={onJobCancelledChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Job Time Change</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.jobTimeChanged}
            text='When enabled, you will be notified when the date or time of jobs for your properties changes.'
            onCheckboxChange={onJobTimeChangedChange}
          />
        </div>
        <div className='col-lg-3 col-md-4 col-12 mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Report Problem</label>
          <SwitchBox
            mutedText
            defaultChecked={feedSetting.reportProblem}
            text='When enabled, you will be notified with the problem reported in the jobs belonging to your properties.'
            onCheckboxChange={onReportProblemChange}
          />
        </div>
      </div>
      <div className='row d-flex justify-content-end p-4'>
        <div className='col-12 col-md-3 d-flex justify-content-end'>
          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              updateSetting()
            }}
          >
            Update
          </button>
        </div>
      </div>
    </>
  )
}

export default FeedSettings
