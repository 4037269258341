import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {decryptText} from '../../../utils/util'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {useDialog} from '../../../contexts/DialogContext'
import {IHostPropertyInput} from '../../../interfaces/Property'
import {KTSVG} from '../../../../_metronic/helpers'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import * as jobActionCreator from '../../../redux/actionCreators/jobActionCreators'
import Activities from './Tabs/Activities/Activities'
import Information from './Tabs/Information/Information'
import Staff from './Tabs/Staff/Staff'
import Checklists from './Tabs/Checklists/Checklists'
import Jobs from './Tabs/Jobs/Jobs'
import dayjs from 'dayjs'
import Settings from './Tabs/Settings/Settings'
import JobAddDialog from './Tabs/Jobs/JobAddDialog'
import PropertyNameEditDialog from './Tabs/PropertyNameEditDialog'
import {PageTitle} from '../../../../_metronic/layout/core'

type TabNames = 'Activities' | 'Jobs' | 'Checklists' | 'Staff' | 'Information' | 'Settings'
const tabList: TabNames[] = ['Activities', 'Jobs', 'Checklists', 'Staff', 'Information', 'Settings']

const PropertyDetail = () => {
  let {propertyId}: any = useParams()
  propertyId = decryptText(propertyId)
  const dispatch = useDispatch()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [activeTab, setActiveTab] = useState<TabNames>('Activities')
  const [showPropertyNameEditDialog, setShowPropertyNameEditDialog] = useState<boolean>(false)
  const {
    showSuccessDialog,
    showSuccessPropertyDialog,
    showFailureDialog,
    openLoadingModal,
    closeModal,
  } = useDialog()
  const [savedValues, setSavedValues] = useState<IHostPropertyInput | undefined>(undefined)
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const {
    data: hostPropertyData,
    error: hostPropertyError,
    isLoading: hostPropertyLoading,
    isFetching: hostPropertyFetching,
    refetch: refetchProperty,
  } = useQuery(
    ['Get Host Property By Id', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId ? propertyId : 0),
    {refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )

  const {
    data: propertyJobSummaryData,
    isLoading: propertyJobSummaryLoading,
    refetch: refetchpropertyJobSummary,
  } = useQuery(
    ['Get Host Property Future Job Summary', propertyId],
    () => ApiCalls.getHostPropertyFutureJobSummary(propertyId),
    {refetchOnWindowFocus: false, enabled: propertyId > 0 ? true : false}
  )

  useEffect(() => {
    if (hostPropertyData) {
      setSavedValues({
        ...hostPropertyData?.data?.hostProperty,
        ...hostPropertyData?.data,
        hostProperty: hostPropertyData?.data?.hostProperty,
        numberOfBathrooms: parseFloat(
          hostPropertyData?.data?.numberOfBathrooms ? hostPropertyData?.data?.numberOfBathrooms : 0
        ),
      })
    }
  }, [hostPropertyData])

  const getCalendarSyncTime = () => {
    if (savedValues?.hostPropertyICals?.length == 0 && savedValues?.hostPropertyPMS?.length == 0) {
      return '-'
    } else {
      if (savedValues?.hostPropertyAutomation?.usePMS) {
      } else {
        if (savedValues != undefined) {
          const icalList = savedValues.hostPropertyICals
          if (icalList != undefined && icalList.length > 0) {
            const lastControl = icalList[0].lastControlOn ?? null

            if (lastControl != null) {
              return dayjs(lastControl).tz(savedValues?.timeZone).format('MMM DD, YYYY HH:mm A')
            } else {
              return '-'
            }
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      }
    }
  }

  const getProviderName = () => {
    if (savedValues?.cleaningProviderId == 1) {
      return 'MyTeam'
    }
    if (savedValues?.cleaningProviderId == 3) {
      return 'Turnify+'
    }
    if (savedValues?.cleaningProviderId == 2) {
      if (savedValues?.isMarketplace) {
        return 'Marketplace'
      } else {
        return 'TurnifyPRO'
      }
    }
  }

  const syncPropertyCalendar = async () => {
    try {
      openLoadingModal('Please wait..')
      await ApiCalls.syncPropertyCalendar(propertyId)
      refetchProperty()
      setTimeout(() => {
        closeModal()
        showSuccessDialog(
          'Synchronization process started. The cleaning schedule for the property will be synchronized shortly.'
        )
      }, 1000)
    } catch (error) {
      setTimeout(() => {
        closeModal()
        showFailureDialog('An error occured during the synchronization process')
      }, 1000)
    }
  }

  const getScheduledJobs = () => {
    if (propertyJobSummaryData?.data) {
      return propertyJobSummaryData?.data?.scheduledJobs
    } else {
      return 0
    }
  }

  const getPendingJobs = () => {
    if (propertyJobSummaryData?.data) {
      return propertyJobSummaryData?.data?.pendingJobs
    } else {
      return 0
    }
  }

  const reloadPage = () => {
    window.location.reload()
  }

  if (hostPropertyLoading) {
    return <SkeletonTableLoading />
  }

  if (!hostPropertyLoading && savedValues == undefined) {
    return (
      <div className='card'>
        <div className='card-body p-0'>
          <div className='card-px text-center py-20 my-10'>
            <h2 className='fs-2x fw-bold mb-10'>Oops!</h2>
            <p className='text-gray-400 fs-4 fw-semibold mb-10'>
              There was a problem loading the property page. Try again by reloading the page.
            </p>
            <button className='btn btn-primary' onClick={reloadPage}>
              Reload Page
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='app-content  flex-column-fluid'>
      <div className='app-container  container-xxl'>
        <PageTitle>{savedValues?.name ?? 'Property Detail'}</PageTitle>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-200px symbol-fixed position-relative'>
                  {savedValues?.staticMapUrl && (
                    <img src={savedValues?.staticMapUrl} className='w-100' />
                  )}
                  {!savedValues?.staticMapUrl && <div className='symbol-label'></div>}
                  {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle bg-white border border-primary border-2 border-body h-30px w-30px d-flex justify-content-center align-items-center'>
                    <div className='cursor-pointer btn btn-sm btn-icon btn-active-color-primary'>
                      <KTSVG path='/media/icons/duotone/Design/Edit.svg' className='svg-icon' />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                        {savedValues?.name}
                      </div>
                      <div
                        className='cursor-pointer btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => {
                          setShowPropertyNameEditDialog(true)
                        }}
                      >
                        <KTSVG path='/media/icons/duotone/Design/Edit.svg' className='svg-icon-2' />
                      </div>
                    </div>
                    <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                      <KTSVG
                        path='/media/icons/duotone/Map/Marker1.svg'
                        className='svg-icon-2 me-2'
                      />
                      <span className='text-gray-900'>
                        {savedValues?.address} {savedValues?.suite ? savedValues?.suite : ''}
                      </span>
                    </div>
                  </div>
                  <div className='d-flex my-4'>
                    {savedValues != undefined &&
                      (savedValues!.hostPropertyICals!.length > 0 ||
                        savedValues!.hostPropertyPMS!.length > 0) && (
                        <button
                          className='btn btn-sm btn-light me-2'
                          onClick={syncPropertyCalendar}
                        >
                          Sync Calendar
                        </button>
                      )}
                    <button
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => {
                        dispatch(jobActionCreator.openJobDialog())
                      }}
                    >
                      Create Job
                    </button>
                    <div className='me-0 position-relative'>
                      <div
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        onClick={() => {
                          setSelectedDropIndx('property-actions-' + propertyId)
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotone/General/Other2.svg'
                          className='svg-icon-3 svg-icon-secondary dropmenu'
                          svgClassName='dropmenu mh-50px'
                        />
                      </div>
                      <div
                        className={
                          selectedDropIndx == 'property-actions-' + propertyId
                            ? 'menu menu-sub menu-sub-dropdown w-150px w-md-150px align-items-end dropcl show'
                            : 'menu menu-sub menu-sub-dropdown w-150px w-md-150px align-items-end dropcl'
                        }
                      >
                        <div className='menu-item px-3'>
                          <div className='menu-link px-3 text-danger'>Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-wrap flex-stack mb-4'>
                  <div className='badge badge-light-success'>{getProviderName()}</div>
                </div>
                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold counted'>{getCalendarSyncTime()}</div>
                        </div>
                        <div className='fw-semibold fs-7 text-gray-400'>Last Calendar Sync</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold counted'>{getScheduledJobs()}</div>
                        </div>
                        <div className='fw-semibold fs-7 text-gray-400'>Scheduled Jobs</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold counted'>{getPendingJobs()}</div>
                        </div>
                        <div className='fw-semibold fs-7 text-gray-400'>Pending Jobs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
              {tabList.map((tabItem, index) => {
                return (
                  <li
                    key={'tabitem-' + index}
                    className='nav-item mt-2'
                    onClick={() => {
                      setActiveTab(tabItem)
                    }}
                  >
                    <div
                      className={
                        tabItem == activeTab
                          ? 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer active'
                          : 'nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer'
                      }
                    >
                      {tabItem}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            {activeTab == 'Activities' && (
              <Activities property={savedValues} propertyId={propertyId} />
            )}
            {activeTab == 'Jobs' && <Jobs property={savedValues} propertyId={propertyId} />}
            {activeTab == 'Checklists' && (
              <Checklists property={savedValues} propertyId={propertyId} />
            )}
            {activeTab == 'Staff' && (
              <Staff property={savedValues} propertyId={propertyId} refetch={refetchProperty} />
            )}
            {activeTab == 'Information' && (
              <Information
                property={savedValues}
                propertyId={propertyId}
                refetch={refetchProperty}
              />
            )}
            {activeTab == 'Settings' && (
              <Settings property={savedValues} propertyId={propertyId} refetch={refetchProperty} />
            )}
          </div>
        </div>
        <JobAddDialog propertyId={propertyId} />
        {showPropertyNameEditDialog && (
          <PropertyNameEditDialog
            propertyId={propertyId}
            propertyName={savedValues?.name}
            modalOpen={showPropertyNameEditDialog}
            handleClose={() => {
              setShowPropertyNameEditDialog(false)
            }}
            refetch={refetchProperty}
          />
        )}
      </div>
    </div>
  )
}

export default PropertyDetail
