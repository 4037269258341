import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC, useState} from 'react'
import {Accordion, Button, Card, ListGroup, Modal, Tab, Tabs} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {useDialog} from '../../contexts/DialogContext'
import {IMarketplace, IMarketplaceOffer} from '../../interfaces/Marketplace'
import ApiCalls from '../../network/ApiCalls'
import {MarketplaceListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp'
import ReviewListModal from '../../umut-components/Modals/ReviewListModal'
import StarRatingView from '../../umut-components/Rating/StarRatingView'
import {encryptText} from '../../utils/util'

type Props = {
  offers: IMarketplaceOffer[] | []
  isManagementService: boolean
  fetchData: () => void
}

const MarketplaceOffers: React.FC<Props> = ({offers, isManagementService, fetchData}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const {showSuccessDialog, showFailureDialog, openLoadingModal, closeModal} = useDialog()
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false)
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false)
  const [showReviews, setShowReviews] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<IMarketplaceOffer | undefined>()

  const acceptOffer = (offerId: any) => {
    openLoadingModal('Please do not turn off the screen, this may take some time.', 50000)
    var request = {id: offerId, marketplaceOfferStatusTypeId: 3}
    ApiCalls.acceptOrDeclineMarketplaceRequest(request)
      .then((res: any) => {
        if (res?.data?.success) {
          closeModal()
          setTimeout(() => {
            showSuccessDialog('You accepted the offer successfully.')
          }, 200)
          fetchData()
        } else {
          closeModal()
          setTimeout(() => {
            showFailureDialog('An error occurred while accepting the offer')
          }, 200)
        }
      })
      .catch((err) => {
        closeModal()
        setTimeout(() => {
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        }, 200)
      })
  }

  const declineOffer = (offerId: any) => {
    var request = {id: offerId, marketplaceOfferStatusTypeId: 4}
    ApiCalls.acceptOrDeclineMarketplaceRequest(request)
      .then((res: any) => {
        if (res?.data?.success) {
          showSuccessDialog('You declined the offer successfully.')
          fetchData()
        } else {
          showFailureDialog('An error occurred while declining the offer')
        }
      })
      .catch((err) => {
        showFailureDialog(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message)
      })
  }

  const openReviewModal = (offer: IMarketplaceOffer) => {
    if (offer.vendor.vendorScoreReviews && offer.vendor.vendorScoreReviews.length > 0) {
      setSelectedOffer(offer)
      setShowReviews(true)
    }
  }

  return (
    <>
      <div className='row'>
        {offers.length > 0 && (
          <>
            {offers.map((offer, index) => {
              if (
                offer.vendor &&
                offer.serviceTypeInfo &&
                offer.isManagementService == isManagementService
              ) {
                var vendorNameRaw = offer.vendor.name.split(' ')
                var vendorShort = ''
                vendorNameRaw.map((vnr) => {
                  vendorShort += vnr[0]
                })
                return (
                  <div className='col-lg-4 col-12 mb-4' key={'offer-line-' + index}>
                    <div className='card card-custom card-stretch gutter-b h-100 border border-1 border-gray-300 ribbon ribbon-top'>
                      {+offer.vendor.vendorScore >= 4 && (
                        <div className='ribbon-label bg-danger'>
                          <span className='svg-icon svg-icon-2 trustbadge'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                              version='1.1'
                            >
                              <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                <rect x='0' y='0' width='24' height='24' />
                                <path
                                  d='M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z'
                                  fill='#290'
                                  opacity='0.3'
                                />
                                <path
                                  d='M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z'
                                  fill='#290'
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      )}
                      <div className='card-body' style={{paddingBottom: 60}}>
                        <div className='row mb-3'>
                          <div className='col-2 mb-3'>
                            <div className='symbol symbol-120 symbol-circle symbol-success overflow-hidden'>
                              <span className='symbol-label'>
                                <ProfilePhotoTemp
                                  fontSize={14}
                                  width={160}
                                  height={160}
                                  borderRadius={'24'}
                                  firstName={''}
                                  lastName={''}
                                  vendorName={vendorShort}
                                />
                              </span>
                            </div>
                          </div>
                          <div className='col-6 ps-5 align-items-start justify-content-center d-flex flex-column mb-3 '>
                            <div className='fw-bold text-dark-75 text-danger'>
                              {offer.vendor.name}
                            </div>
                            <div className='fw-bold' style={{color: '#666', fontSize: 10}}>
                              {offer.vendor.cityInfo.name} / {offer.vendor.stateInfo.name}
                            </div>
                            <div className='text-muted' style={{fontSize: 10, marginTop: 4}}>
                              Member Since{' '}
                              <strong>{dayjs(offer.vendor.createdOn).format('YYYY')}</strong>
                            </div>
                          </div>
                          <div className='col-4 align-items-end justify-content-center d-flex flex-column  mb-3'>
                            <StarRatingView rate={+offer.vendor.vendorScore} />
                            <div
                              className='review-line'
                              onClick={() => {
                                openReviewModal(offer)
                              }}
                            >
                              {offer.vendor.vendorScoreReviews
                                ? offer.vendor.vendorScoreReviews.length + ' Reviews'
                                : 'No Review'}
                            </div>
                          </div>
                        </div>
                        <div className='row mb-3'>
                          {offer.vendor.description && (
                            <div className='col-12'>
                              <span className='fw-bold'>About : </span>
                              {offer.vendor.description}
                            </div>
                          )}
                        </div>
                        <div className='row mb-3'>
                          <div className='col-lg-12 col-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-6 col-12 fw-bold mb-3'>
                                Requested Service :
                              </div>
                              <div className='col-lg-6 col-12 mb-3'>
                                {offer.serviceTypeInfo.name}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-6 col-12 fw-bold mb-3'>
                                Sample Checklist :{' '}
                              </div>
                              <div className='col-lg-6 col-12 mb-3'>
                                {offer.checklistInfo != undefined && (
                                  <div>{offer.checklistInfo.name.en}</div>
                                )}
                                {offer.checklistInfo == undefined && (
                                  <div className='text-danger'>No Related Checklist</div>
                                )}
                              </div>
                            </div>
                            {offer.marketplaceOfferStatusTypeId > 1 && (
                              <div className='row'>
                                <div className='col-lg-6 col-12 fw-bold mb-3'>
                                  <div className='text-danger' style={{fontSize: 14}}>
                                    Quoted Price :
                                  </div>
                                </div>
                                <div className='col-lg-6 col-12 mb-3 d-flex align-items-center'>
                                  <div style={{fontSize: 22, marginRight: 4}}>
                                    ${offer.offerPrice}
                                  </div>
                                  <div>{offer.offerType == 'hourly' ? ' / hour(s)' : ' / job'}</div>
                                </div>
                              </div>
                            )}
                            {offer.vendorNote && (
                              <div className='row'>
                                <div className='col-lg-6 col-12 fw-bold mb-3'>
                                  <div className='text-dark'>Brief :</div>
                                </div>
                                <div className='col-lg-6 col-12 mb-3 d-flex align-items-center'>
                                  <div>{offer.vendorNote}</div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='offer-action-buttons'>
                          <div className='row'>
                            {offer.marketplaceOfferStatusTypeId == 2 && (
                              <>
                                <div className='col-lg-6 col-12 mb-3'>
                                  <button
                                    className='btn btn-success font-weight-bolder w-100'
                                    onClick={() => {
                                      setSelectedOffer(offer)
                                      setShowAcceptModal(true)
                                    }}
                                  >
                                    Accept Offer
                                  </button>
                                </div>
                                <div className='col-lg-6 col-12 mb-3'>
                                  <button
                                    className='btn btn-outline btn-outline-danger font-weight-bolder w-100'
                                    onClick={() => {
                                      setSelectedOffer(offer)
                                      setShowDeclineModal(true)
                                    }}
                                  >
                                    Decline Offer
                                  </button>
                                </div>
                              </>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 1 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-dark font-weight-bolder w-100'>
                                  Quote requested. Offer Pending.
                                </button>
                              </div>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 3 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-success font-weight-bolder w-100'>
                                  Offer Accepted
                                </button>
                              </div>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 4 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-danger font-weight-bolder w-100'>
                                  Offer Declined
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
            <Modal
              show={showAcceptModal}
              onHide={() => {
                setSelectedOffer(undefined)
                setShowAcceptModal(false)
              }}
              centered
            >
              <ModalHeader closeButton>
                <Modal.Title>Warning!</Modal.Title>
              </ModalHeader>
              <Modal.Body>
                <p style={{fontSize: 15}}>
                  You are about to accept the offer from{' '}
                  <strong>{selectedOffer?.vendor?.name}</strong>. Accept offer?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className='btn btn-link text-muted me-5'
                  onClick={() => {
                    setSelectedOffer(undefined)
                    setShowAcceptModal(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    acceptOffer(selectedOffer!.id || undefined)
                  }}
                >
                  Yes, accept
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showDeclineModal}
              onHide={() => {
                setSelectedOffer(undefined)
                setShowDeclineModal(false)
              }}
              centered
            >
              <ModalHeader closeButton>
                <Modal.Title>Warning!</Modal.Title>
              </ModalHeader>
              <Modal.Body>
                <p style={{fontSize: 15}}>
                  You are about to decline the offer from{' '}
                  <strong>{selectedOffer?.vendor?.name}</strong>. Decline offer?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className='btn btn-link text-muted me-5'
                  onClick={() => {
                    setSelectedOffer(undefined)
                    setShowDeclineModal(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    declineOffer(selectedOffer!.id || undefined)
                  }}
                >
                  Yes, decline
                </button>
              </Modal.Footer>
            </Modal>
            <ReviewListModal
              title={selectedOffer?.vendor.name}
              data={selectedOffer?.vendor.vendorScoreReviews}
              modalOpen={showReviews}
              handleClose={() => {
                setSelectedOffer(undefined)
                setShowReviews(false)
              }}
            />
          </>
        )}
      </div>
    </>
  )
}

export default MarketplaceOffers
