import React, {useState} from 'react'

interface ITextAreaInput {
  onValueChange: (text: string) => void
  placeholder?: string
  defaultValue?: any
  classNames?: string
  row?: number
  min?: number
  max?: number
  disabled?: boolean
  errorText?: string
  characterLimit?: number
}
export const TextAreaInput: React.FC<ITextAreaInput> = ({
  onValueChange,
  placeholder,
  defaultValue,
  classNames,
  row,
  min,
  max,
  disabled,
  errorText,
}) => {
  const [textValue, setTextValue] = useState(defaultValue ? defaultValue : '')

  const textValueChange = (event: any) => {
    setTextValue(event.target.value)
    onValueChange(event.target.value)
  }

  return (
    <div>
      <textarea
        value={textValue}
        onChange={textValueChange}
        placeholder={placeholder}
        className={'form-control ' + classNames}
        disabled={disabled}
        minLength={min}
        maxLength={max}
        rows={row}
      />
      {errorText && <small className='text-danger'>{errorText}</small>}
    </div>
  )
}
