import React, { useEffect } from 'react';
import { useState } from 'react';
import { Card } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import ApiCalls from '../../network/ApiCalls';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import AddPropertyTypeSelection from '../../umut-components/Modals/AddPropertyTypeSelection';
import DeleteDialog from '../../umut-components/Modals/DeleteDialog';
import { decryptText } from '../../utils/util';
import PropertyDeleteDialog from './PropertyDeleteDialog';
import PropertyEditDialog from './PropertyEditDialog';
import PropertyTable from './PropertyTable';

const Properties: React.FC = () => {
    let { propertyId }: any = useParams();
    propertyId = decryptText(propertyId);
    const { propertyDelete }: any = useSelector((state: RedusxAppState) => state.hostProperty)
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const [propertyTypeModalOpen, setPropertyTypeModalOpen] = useState(false);
    const [editPropertyModalOpen, setEditPropertyModalOpen] = useState(false);
    const { data, isLoading, error, isFetching, refetch } = useQuery('Get Host Properties', () => ApiCalls.getHostProperties(user.userSection.hostId), { cacheTime: 10000, retry: false });

    const closeTypeModal = () => {
        setPropertyTypeModalOpen(false);
    }

    useEffect(() => {
        if (!isLoading && !isFetching && !propertyId) {
            refetch();
        }
        setEditPropertyModalOpen(propertyId ? true : false);
    }, [propertyId])

    return (
        <>
            <PageTitle>Properties</PageTitle>

            <div className='row g-5 g-xl-8'>
                <Card className="shadow">
                    <Card.Body >
                        {isLoading ? <SkeletonTableLoading /> : <PropertyTable data={data?.data?.data} refetch={refetch} />}
                    </Card.Body>
                    {data?.data?.data.length > 0 &&
                    <Card.Footer className="d-flex align-items-center justify-content-between">
                        <div />
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="btn btn-success" style={{ height: '100%' }} onClick={() => setPropertyTypeModalOpen(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                                Add New Property
                            </button></div>
                    </Card.Footer>
                    }
                </Card>
            </div>
            <AddPropertyTypeSelection modalOpen={propertyTypeModalOpen} handleClose={closeTypeModal} />
            <PropertyDeleteDialog />
            <PropertyEditDialog modalOpen={editPropertyModalOpen} propertyId={propertyId} />
        </>
    )
}

export default Properties;