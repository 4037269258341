import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ApiCalls from '../../../network/ApiCalls';

const PaymentSuccess: FC = () => {
    const history = useHistory();
    const { successToken }: any = useParams();
    const [success, setSuccess] = useState<boolean | undefined>(undefined);

    const clickBackDashboard = () => {
        history.replace('/dashboard');
    }

    if (successToken) {
        ApiCalls.verifyBilling()
            .then(res => res.status === 200 && setSuccess(true))
            .catch(err => setSuccess(false));
    }

    if (success === undefined) {
        return null;
    }

    if (!success) {
        return <div className='w-100 d-flex flex-column justify-content-center align-items-center' style={{ height: '60vh' }}>
            <img className='w-75 w-sm-250px mb-10' alt="modal" src={toAbsoluteUrl(`/media/icons/danger.png`)} />
            <h1 className='mb-10 text-success'>Error</h1>
            <button type='button' className='w-100 w-sm-400px btn btn-info' onClick={clickBackDashboard}>Back to Dashboard</button>
        </div>
    }

    return (
        <div className='w-100 d-flex flex-column justify-content-center align-items-center' style={{ height: '60vh' }}>
            <img className='w-75 w-sm-250px mb-10' alt="modal" src={toAbsoluteUrl(`/media/icons/success.png`)} />
            <h1 className='mb-10 text-success'>Stripe Successfully Added</h1>
            <button type='button' className='w-100 w-sm-400px btn btn-info' onClick={clickBackDashboard}>Back to Dashboard</button>
        </div>
    )
}

export default PaymentSuccess;