import React from 'react'
import {Tab} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import ApiCalls from '../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import JobTable from './JobTable'

const Jobs: React.FC = () => {
  const {proTeamDetail} = useSelector((state: RedusxAppState) => state.proTeam)

  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
  } = useQuery(
    ['Job History', proTeamDetail.proTeam?.hostPropertyTeam?.vendorId],
    () => ApiCalls.getJobsByVendorId(proTeamDetail.proTeam?.hostPropertyTeam?.vendorId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: proTeamDetail.proTeam?.userId ? true : false,
    }
  )

  if (jobLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }

  return (
    <div className='p-6 pt-0'>
      <JobTable data={jobData?.data?.data} />
    </div>
  )
}

export default Jobs
