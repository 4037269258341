import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { IJobDetail, IJobIssue } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { decryptText } from '../../../../utils/util';
import IssueItem from './IssueItem';
import IssueMediaDialog from './IssueMediaDialog';

type Props = {
    jobEventsActive: boolean;
    jobDetail: IJobDetail;
}

const IssuesTab: React.FC<Props> = ({ jobEventsActive, jobDetail }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Issues By Id', jobId], () => ApiCalls.getJobIssues(jobId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });
    const [reportedIssues, setReportedIssues] = useState<IJobIssue[]>([]);
    const [resolvedIssues, setResolvedIssues] = useState<IJobIssue[]>([]);

    useEffect(() => {
        if (data) {
            setReportedIssues(data.data?.data?.filter((issue: IJobIssue) => issue.jobIssueStatusTypeName === "Reported"));
            setResolvedIssues(data.data?.data?.filter((issue: IJobIssue) => issue.jobIssueStatusTypeName === "Resolved"));
        }
    }, [data])

    if (isLoading) {
        return <div className='d-flex justify-content-center p-6'>Loading...</div>
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-6 d-flex flex-column align-items-center mt-6'>
                    <div className='w-100 d-flex justify-content-between border-bottom p-3 ps-0 pe-0 mb-6'>
                        <h6 className='h-100 d-flex align-items-center'>Reported Issues</h6>
                        <button type='button' className='btn btn-outline-success btn-sm invisible' onClick={() => { }}>Add</button>
                    </div>
                    <div className='w-100' style={{ maxHeight: 'calc(50vh - 60px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        {reportedIssues.length === 0 && <div className='d-flex text-center align-items-center text-muted'>No Reported Issue</div>}
                        {reportedIssues.map((issue: IJobIssue) => <IssueItem jobDetail={jobDetail} issue={issue} refetch={refetch} jobEventsActive={jobEventsActive} />)}
                    </div>
                </div>
                <div className='col-sm-6 d-flex flex-column align-items-center mt-6'>
                    <div className='w-100 d-flex justify-content-between border-bottom p-3 ps-0 pe-0 mb-6'>
                        <h6 className='h-100 d-flex align-items-center'>Resolved Issues</h6>
                        <button className='btn btn-outline-success btn-sm invisible'>Add</button>
                    </div>
                    <div className='w-100' style={{ maxHeight: 'calc(50vh - 60px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        {resolvedIssues.length === 0 && <div className='d-flex text-center align-items-center text-muted'>No Resolved Issue</div>}
                        {resolvedIssues.map((issue: IJobIssue) => <IssueItem jobDetail={jobDetail} issue={issue} jobEventsActive={jobEventsActive} />)}
                    </div>
                </div>
            </div>
            <IssueMediaDialog jobEventsActive={jobEventsActive} refetch={refetch} />
        </>
    )
}

export default IssuesTab;