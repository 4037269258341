import React, { Dispatch, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useDialog } from '../../contexts/DialogContext';
import { ILocation } from '../../interfaces/Property';
import ApiCalls from '../../network/ApiCalls';
import { AddressRequest } from '../../network/PostRequestModels/AddressRequests';
import * as addressActionCreators from '../../redux/actionCreators/addressActionCreators';
import { AddressAction } from '../../redux/actionTypes/addressTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { setErrorObject } from '../../utils/util';
import AutocompleteMap from '../Inputs/AutocompleteMap';
import SelectInput from '../Inputs/SelectInput';
import Map from '../Map/GoogleMap';
type Props = {
}

const AddressEditForm: React.FC<Props> = () => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { addressEdit } = useSelector((state: RedusxAppState) => state.address);
    const dispatch = useDispatch<Dispatch<AddressAction>>();
    const { address: selectedAddress } = addressEdit;
    const { showSuccessDialog, showFailureDialog } = useDialog();
    const [addressRequest, setAddressRequest] = useState<AddressRequest>({
        address: selectedAddress?.address,
        cityName: selectedAddress?.cityName,
        countryName: selectedAddress?.countryName,
        stateName: selectedAddress?.stateName,
        userAddressTypeId: selectedAddress?.userAddressTypeId,
        zipCode: selectedAddress?.zipCode
    } as AddressRequest);
    const [errorText, setErrorText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [addressTypes, setAddressTypes] = useState([]);
    const [searchText, setSearchText] = useState<string>(selectedAddress?.address ? selectedAddress?.address : '');
    const { data: addressTypesData, isLoading: addressTypesLoading, error: addressTypesError } = useQuery('Get Address Types', () => ApiCalls.getAddressTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const [validationErrors, setValidationErrors] = useState<any>({});

    const onMarkerChange = (changedLocation: ILocation) => {
        try {
            const { address, city, country, state, zipCode } = changedLocation;
            setSearchText(address ?? '');
            setAddressRequest({
                address: address ? address : '',
                cityName: city ? city : '',
                countryName: country ? country : '',
                stateName: state ? state : '',
                zipCode: zipCode ? zipCode : '',
                userAddressTypeId: addressRequest.userAddressTypeId
            })
        }
        catch (err: any) {
            setAddressRequest({ ...addressRequest })
            setErrorObject(err, setValidationErrors);
        }
    }

    const onAddressChange = (e: string) => {
        setAddressRequest({ ...addressRequest, address: e });
    }

    const handleSendAddress = async () => {
        try {
            setLoading(true);
            setErrorText('');
            const { address, cityName, countryName, stateName, userAddressTypeId, zipCode } = addressRequest;
            if (address !== undefined && cityName !== undefined && countryName !== undefined && stateName !== undefined && userAddressTypeId !== undefined && zipCode !== undefined &&
                address !== '' && cityName !== '' && countryName !== '' && stateName !== '' && zipCode !== '') {
                if (selectedAddress) {
                    await ApiCalls.updateAddress(user.user.id, selectedAddress.userAddressId, addressRequest);
                }
                else {
                    await ApiCalls.createAddress(user.user.id, addressRequest);
                }
                setLoading(false);
                dispatch(addressActionCreators.getUserAddresses(user.user.id));
                dispatch(addressActionCreators.closeAddressDialog());
                showSuccessDialog('Success');
            }
            else {
                setLoading(false);
                setErrorText('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorText(err?.response?.data?.message);
            setErrorObject(err, setValidationErrors);
        }
    }

    const handleAutocompleteError = () => {
        setSearchText(addressRequest.address ?? '');
    }

    useEffect(() => {
        if (addressTypesData) {
            setAddressTypes(addressTypesData?.data?.data?.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [addressTypesData])

    if (addressTypesLoading) {
        <div className='d-flex justify-content-center'>Loading...</div>
    }

    return (
        <div className='row'>
            <div className='fv-row col-lg-4 mb-6'>
                <Map placeId={undefined} selectedAddress={addressRequest.address} initialLocation={null} initialAddress={addressRequest.address} onLatLngChanged={onMarkerChange} draggable={true} />
            </div>
            <div className='fv-row col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Address Type</label>
                <SelectInput options={addressTypes} defaultValue={addressRequest.userAddressTypeId} onValueChanged={(value: number) => { setAddressRequest({ ...addressRequest, userAddressTypeId: value }) }} />

                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Address</label>
                <AutocompleteMap text={searchText} setText={setSearchText} handleSelectAddress={onAddressChange} handleOnError={handleAutocompleteError} />

                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Country</label>
                <input
                    value={addressRequest.countryName}
                    className='form-control'
                    type='text'
                    autoComplete='off'
                    required
                    disabled
                />

                <label className='form-label fs-6 fw-bolder text-dark mt-6'>State</label>
                <input
                    value={addressRequest.stateName}
                    className='form-control'
                    type='text'
                    autoComplete='off'
                    required
                    disabled
                />

                <label className='form-label fs-6 fw-bolder text-dark mt-6'>City</label>
                <input
                    value={addressRequest.cityName}
                    className='form-control'
                    type='text'
                    autoComplete='off'
                    required
                    disabled
                />

                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Zip Code</label>
                <input
                    value={addressRequest.zipCode}
                    className='form-control'
                    type='text'
                    autoComplete='off'
                    required
                    disabled
                />

                <div className='d-flex justify-content-end mt-6'>
                    <button type='button' className='btn btn-success' onClick={handleSendAddress} disabled={loading}>{addressEdit.address ? 'Save Changes' : 'Save Address'}{loading ? <Spinner animation='border' size='sm' /> : null}</button>
                </div>
                <div className='d-flex justify-content-end mt-6'>
                    <strong className='text-danger'>{errorText}</strong>
                </div>
            </div>
        </div>
    )
}

export default AddressEditForm;