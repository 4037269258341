import { Switch } from '@material-ui/core';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Select from 'react-select';
import { useDialog } from '../../contexts/DialogContext';
import { IChecklistItem } from '../../interfaces/Checklist';
import ApiCalls from '../../network/ApiCalls';
import { JobServiceAddRequest } from '../../network/PostRequestModels/JobRequests';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { TextInput } from '../Inputs/TextInput';
import { selectStyles } from '../../config/selectStyle';
import { decryptText, encryptText, convertUTCDateToLocalDate } from '../../utils/util';
import { IService } from '../../interfaces/ServiceType';
import { IHostPropertyOffer } from '../../interfaces/Property';

type Props = {
    hostPropertyId: number,
    roleId: number,
}

type ErrorTextProps = {
    hostPropertyId: any
}

type QuestionErrorTextProps = {
    checklistId: any
}

const ChecklistErrorText: React.FC<ErrorTextProps> = ({ hostPropertyId }) => {
    return <strong className='text-danger w-100 text-break'>Cannot found checklist for this service type and role. <a href={`/checklists/${encryptText(hostPropertyId)}`} target='_blank'>Please add checklist first.</a></strong>
}

const ChecklistQuestionErrorText: React.FC<QuestionErrorTextProps> = ({ checklistId }) => {
    return <strong className='text-danger w-100 text-break'>Cannot found checklist item for this checklist. <a target='_blank' href={`/checklist-sections/${encryptText(checklistId)}`}>Please add checklist item first.</a></strong>
}

const JobServiceAddForm: React.FC<Props> = ({ hostPropertyId, roleId }) => {
    const { serviceAddDialog, jobDetail } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { showSuccessDialog } = useDialog();
    const serviceRef = useRef<any>(null);
    const staffRef = useRef<any>(null);
    const checklistRef = useRef<any>(null);
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [staffSectionActive, setStaffSectionActive] = useState(false);
    const [checklistErrorComp, setChecklistErrorComp] = useState<any>(null);
    const [checklistQuestionErrorComp, setChecklistQuestionErrorComp] = useState<any>(null);
    const [services, setServices] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [selectedService, setSelectedService] = useState<any>({});
    const [selectedServiceType, setSelectedServiceType] = useState<any>(null);
    const [selectedChecklistId, setSelectedChecklistId] = useState<any>(null);
    const [selectedNote, setSelectedNote] = useState<string | null>(null);
    const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null);

    //const [startDate, setStartDate] = useState<string | null>(null);
    const { data: serviceData, isLoading: serviceLoading, error: serviceError } = useQuery('Get Services for Job', () => (jobDetail?.cleaningProviderId === "My Team" || jobDetail?.cleaningProviderId === "Turnify+") ? ApiCalls.getOfferedServicesByHostId(user.userSection.hostId) : ApiCalls.getHostPropertyServiceOffers(jobDetail?.hostPropertyId), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: checklistData, isLoading: checklistLoading, isFetching: checklistFetching, error: checklistError, refetch: refetchChecklists } = useQuery('Get Checklist for Job', () => ApiCalls.getHostPropertyChecklists(hostPropertyId, roleId, selectedServiceType?.serviceTypeId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostPropertyId && roleId && selectedServiceType?.serviceTypeId ? true : false });
    const { data: hostPropertyData, isLoading: hostPropertyLoading, error: hostPropertyError } = useQuery(['Get Host Property By Id', hostPropertyId], () => ApiCalls.getHostPropertyById(hostPropertyId), { refetchOnWindowFocus: false });
    const { data: staffData, isLoading: staffLoading, error: staffError, refetch: refetchStaffs } = useQuery(['Get Staffs for Job', hostPropertyData?.data.hostProperty?.hostId, hostPropertyId, roleId, staffSectionActive, jobDetail?.cleaningPeriodStart], () => ApiCalls.getOfferedStaffs(hostPropertyData?.data.hostProperty?.hostId, hostPropertyId, selectedServiceType?.serviceTypeId, roleId, jobDetail?.cleaningPeriodStart), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostPropertyId && selectedServiceType?.serviceTypeId && roleId && staffSectionActive ? true : false });
    const handleAddService = async () => {
        setLoading(true);
        setErrorText(null);
        try {
            const serviceRequest: JobServiceAddRequest = {
                serviceTypeId: selectedServiceType?.serviceTypeId,
                staffOfferedServiceId: selectedService?.id ? selectedService?.id : undefined,
                hostPropertyChecklistId: selectedChecklistId ? selectedChecklistId : undefined,
                serviceNote: selectedNote ? selectedNote : undefined,
                jobId: parseInt(jobId),
                roleId
            }
            await ApiCalls.addJobService(serviceRequest);
            showSuccessDialog('Service added.');
            setLoading(false);
            serviceAddDialog.refetch();
            dispatch(jobActionCreator.closeServiceAddDialog());
        }
        catch (err: any) {
            setLoading(false);
            if (err?.response?.data?.message === "Host PropertyChecklist was not found in the database!") {
                setErrorText('Cannot found checklist for this service type. Please add checklist.');
            }
            else {
                setErrorText(err?.response?.data?.message);
            }
        }
    }
    const handleSelectStaff = (value: any) => {
        setSelectedService(value?.value);
    }

    const handleNoteChange = (text: any) => {
        setSelectedNote(text);
    }

    const handleChangeService = (value: any) => {
        value?.value && setSelectedServiceType({ serviceTypeId: value.value.serviceTypeId, serviceTypeName: value.value.serviceTypeName });
    }

    const handleChangeChecklist = (value: any) => {
        setChecklistQuestionErrorComp(parseInt(value?.value?.questionCount) === 0 ? <ChecklistQuestionErrorText checklistId={value?.value?.id} /> : null);
        value?.value && setSelectedChecklistId(parseInt(value.value.id));
    }

    useEffect(() => {
        if (serviceData) {
            const existingServices = serviceAddDialog?.services?.map((item: any) => item.serviceTypeName)
            const filterServices = serviceData.data.data.filter((service: any) => !existingServices?.includes(service.serviceTypeName))
            setServices(filterServices.map((type: any) => { return { value: type, label: type.serviceTypeName + (type.offeredServicePrice ? (' - $' + type.offeredServicePrice) : '') } }));
            setSelectedService({});
        }
    }, [serviceData])

    useEffect(() => {
        if (staffData) {
            setStaffs(staffData?.data?.data.map((staffOfferedService: any) => {
                return {
                    value: staffOfferedService,
                    label: staffOfferedService.firstName + " " + staffOfferedService.lastName
                }
            }))
            staffRef?.current?.select?.clearValue();
        }
    }, [staffData])

    useEffect(() => {
        if (checklistData) {
            setChecklists(checklistData?.data.map((checklist: IChecklistItem) => {
                return {
                    value: checklist,
                    label: checklist.name?.en ? checklist.name?.en : checklist.name[Object.keys(checklist.name)[0]]
                }
            }))
            setSelectedChecklistId(null);
            setChecklistErrorComp(checklistData?.data?.length === 0 ? <ChecklistErrorText hostPropertyId={jobDetail?.hostPropertyId} /> : null);
            checklistRef.current?.select?.clearValue();
        }
    }, [checklistData])

    useEffect(() => {
        if (selectedServiceType?.serviceTypeId && roleId && hostPropertyId) {
            refetchStaffs();
            setSelectedService(null);
        }
    }, [selectedServiceType, roleId, hostPropertyId])

    useEffect(() => {
        if (roleId && hostPropertyId && selectedServiceType?.serviceTypeId) {
            refetchChecklists();
            setSelectedChecklistId(null);
        }
    }, [roleId, hostPropertyId, selectedServiceType])


    useEffect(() => {
        if (hostPropertyData) {
            setStaffSectionActive(hostPropertyData?.data?.hostProperty?.cleaningProviderId === 1);
        }
    }, [hostPropertyData])

    useEffect(() => {

        if (selectedServiceType) {
            const selectedValue: IHostPropertyOffer = serviceData?.data?.data?.find((service: IHostPropertyOffer) => service?.serviceTypeId == selectedServiceType?.serviceTypeId)
            setSelectedServiceDescription(selectedValue.serviceTypeDescription)
        }
        else {
            setSelectedServiceDescription(null);
        }
    }, [selectedServiceType])

    if (serviceLoading || hostPropertyLoading) {
        return <div className='d-flex justify-content-between'>Loading...</div>
    }

    return (
        <div className='row d-flex flex-wrap'>
            <div className='row'>
                <label className='form-label fs-6 fw-bolder text-dark'>Services {<span style={{ color: "red" }}>*</span>}</label>
                <div className='col-12'>
                    <Select styles={selectStyles} ref={serviceRef} onChange={handleChangeService} options={services} />
                    {selectedServiceDescription && <span className='text-muted m-1'>{selectedServiceDescription}</span>}

                </div>
                {staffSectionActive ?
                    <>
                        <label className='form-label fs-6 fw-bolder text-dark mt-6'>Staff</label>
                        <div className='col-12'>
                            <Select styles={selectStyles} ref={staffRef} onChange={handleSelectStaff} options={staffs} />
                        </div>
                    </> : null}
                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Checklist</label>
                <div className='col-12'>
                    {checklistFetching ? 'Loading...' : <Select styles={selectStyles} ref={checklistRef} onChange={handleChangeChecklist} options={checklists} />}
                    {checklistErrorComp}
                    {checklistQuestionErrorComp}
                </div>
                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Note</label>
                <div className='col-12'>
                    <TextInput onValueChange={handleNoteChange} />
                </div>
                {/*staffSectionActive ?
                    <>
                        <div className='fv-row col-12 mt-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Start Date</label>
                            <DateTimePicker onChange={setStartDate} min={jobDetail?.scheduledStart} max={jobDetail?.scheduledFinish} />
                            <small className='text-muted'>Job Time: {convertUTCDateToLocalDate(jobDetail?.scheduledStart)} - {convertUTCDateToLocalDate(jobDetail?.scheduledFinish)}</small>
                        </div>
                </> : null*/}
            </div>
            <div className='d-flex justify-content-end mt-6'>
                <button type='button' className='btn btn-success' disabled={loading || !selectedServiceType?.serviceTypeId || (staffSectionActive && !selectedService?.id) || checklists?.length === 0 || checklistFetching} onClick={handleAddService}>Save Service {loading ? <Spinner animation='border' size='sm' /> : null}</button>
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <strong className='text-danger'>{errorText}</strong>
            </div>
        </div>
    )
}

export default JobServiceAddForm;