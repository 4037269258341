import { FC } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap-v5'
import { IJobDetail } from '../../../../interfaces/Job'
import OffDayEffectedJobTable from './OffDayEffectedJobTable'

type Props = {
    modalOpen: boolean
    modalClose: () => void
    checkedSave?: () => void
    data: IJobDetail[]
}
const OffDayEffectedJob: FC<Props> = ({ modalOpen, modalClose, checkedSave, data }) => {
    return (
        <Modal show={modalOpen} onHide={modalClose} size="lg">
            <Modal.Header>
                <Modal.Title>Effected Jobs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-warning">Are you sure you want to Off Day? Please keep in mind that if this day has any future jobs, they will be deleted as well.</p>
                <OffDayEffectedJobTable data={data} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={modalClose} className="btn btn-sm bg-transparent text-danger">cancel</button>
                <Button onClick={checkedSave} className="btn btn-sm">Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OffDayEffectedJob
