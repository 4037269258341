import React, {useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  toConvertedDate,
  setDxFilterPlaceholder,
} from '../../utils/util'
import {JobStatusType} from '../../enums/JobEnum'
import {IJob} from '../../interfaces/Job'
import ViewButton from '../../umut-components/Buttons/ViewButton'
import {useHistory} from 'react-router'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {Link} from 'react-router-dom'
import config from '../../config/config.json'
import dayjs from 'dayjs'
import {CleaningProviders} from '../../enums/PropertyEnum'

type Props = {
  data: IJob[]
}

const LiveTable: React.FC<Props> = ({data}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateNextCheckIn = (cellValue: any) => {
    return toConvertedDate(cellValue.nextCheckIn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStaff = (cellValue: any) => {
    if (
      cellValue?.data?.cleaningProviderName === 'My Team' ||
      cellValue?.data?.cleaningProviderName === 'Turnify+'
    )
      return <span>{cellValue?.data?.jobStaff}</span>

    return <span className='text-danger'>Turnify PRO</span>
  }
  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }

  const renderCheckIn = (cellValue: any) => {
    const checkInDate = dayjs(
      toConvertedDate(cellValue?.data?.nextCheckIn, cellValue?.data?.hostPropertyCityTimeZone)
    )
    const isToday = dayjs().get('date') === checkInDate.get('date')

    if (isToday) return <strong className='text-danger'>Today</strong>

    return (
      <span>
        {convertUTCDateToLocalDate(
          cellValue?.data?.nextCheckIn,
          cellValue?.data?.hostPropertyCityTimeZone
        )}
      </span>
    )
  }

  const toPropertyDetail = (cellData: any) => {
    if (cellData?.column?.name !== 'delete-button' && cellData?.data?.id)
      // history.push({
      //     //pathname: `/job-details/` + encryptText(cellData?.data?.id)
      // })
      window.open(`/job-details/` + encryptText(cellData?.data?.id))
  }

  if (data?.length === 0) {
    return <EmptyTable title='There are no scheduled jobs for today' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>

      <DataGrid
        id='jobTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(75vh)'}
        allowColumnResizing={true}
        onCellClick={toPropertyDetail}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Live')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='hostPropertyAddress'
          cellRender={isManuallyCreatedJob}
          minWidth={450}
          caption='Property'
          cssClass='cls'
        />
        <Column dataField='services' minWidth={350} caption='Service' cssClass='cls' />

        <Column
          dataField='scheduledStart'
          dataType='time'
          caption='Sch.Start'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          cellRender={displayStatusType}
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='jobStaff'
          caption='Staff'
          minWidth={250}
          cssClass='cls'
          alignment='center'
          cellRender={calculateStaff}
        />
        {/* <Column dataField="startOn" caption="Started at" dataType='time' selectedFilterOperation='between' format={config.time_format_dev_extreme} calculateCellValue={calculateStartedOn} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('startOn')} />
                </Column> */}
        <Column
          dataField='scheduledFinish'
          dataType='time'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>
        <Column
          dataField='finishedOn'
          caption='Finished at'
          dataType='time'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
        <Column dataField='hostPropertyCityName' caption='City' minWidth={200} cssClass='cls' />
        <Column dataField='hostPropertyStateName' caption='State' minWidth={150} cssClass='cls' />
        <Column
          dataField='hostPropertyCountryName'
          caption='Country'
          minWidth={200}
          cssClass='cls'
        />
        <Column
          dataField='nextCheckIn'
          caption='Next Check-In'
          dataType='datetime'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateNextCheckIn}
          cellRender={renderCheckIn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('nextCheckIn')} />
        </Column>
      </DataGrid>
    </div>
  )
}

export default LiveTable
