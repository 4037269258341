import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react"
import { Modal } from "react-bootstrap-v5"
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { selectStyles } from "../../../config/selectStyle";
import ApiCalls from "../../../network/ApiCalls";
import { IListResponse } from "../../../network/NetworkResponses/NetworkResponses";
import { ManagerCityPermission } from "../../../network/PostRequestModels/StaffRequest";
import { RedusxAppState } from "../../../redux/reducers/rootReducer";
import { decryptText } from "../../../utils/util";
import notify from 'devextreme/ui/notify'


type Props = {
      modalOpen: boolean
      closeModal: () => void
}
const ManagerCitiesDialog: FC<Props> = ({ modalOpen, closeModal }) => {
      let { staffId }: any = useParams();
      staffId = decryptText(staffId);

      const { user } = useSelector((state: RedusxAppState) => state.user);
      const [loading, setLoading] = useState(false)

      const [selectedCity, setSelectedCity] = useState<any>({ label: null, value: null })
      const [selectedState, setSelectedState] = useState<any>({ label: null, value: null })

      const [states, setStates] = useState()
      const [cities, setCities] = useState()

      const { data: citiess, isLoading: citiesLoading, error: citiesError } = useQuery(['Get Cities', selectedState.value], () => ApiCalls.getCities(selectedState.value), { cacheTime: 50000, enabled: selectedState.value ? true : false })
      const { data: statess, isLoading: statesLoading, error: statesError } = useQuery(['Get states'], () => ApiCalls.getStates(), { cacheTime: 50000 })

      const query = useQueryClient()
      const { mutateAsync } = useMutation(ApiCalls.postManagerCities, { onSuccess: () => query.invalidateQueries(['Manager cities permission', Number(staffId)]) })


      const handleAddCityPermission = async () => {
            try {
                  if (selectedCity && selectedState) {
                        const permissionRequest: ManagerCityPermission = {
                              hostId: user?.userSection?.hostId,
                              userId: Number(staffId),
                              city: selectedCity.label,
                              state: selectedState.label
                        }
                        setLoading(true)
                        await mutateAsync(permissionRequest)
                        closeModal()
                        setLoading(false)
                  }

            } catch (error: any) {
                  notify({
                        message: error?.response?.data?.message,
                        position: {
                              my: 'center bottom',
                              at: 'center bottom',
                        },
                  }, 'warning', 1000);
                  setLoading(false)
            }
      }


      useEffect(() => {
            if (statess) {
                  setStates(statess?.data?.data?.map((state: any) => ({ label: state.name, value: state.id })));
            }
      }, [statess])


      useEffect(() => {
            if (citiess) {
                  setCities(citiess?.data?.data?.map((state: any) => ({ label: state.name, value: state.id })));
            }
      }, [citiess])

      return <div>
            <Modal show={modalOpen} onHide={closeModal} centered>
                  <ModalHeader closeButton>
                        <Modal.Title>Add City Permission</Modal.Title>
                  </ModalHeader>
                  <Modal.Body>
                        <label className='form-label fs-6 fw-bolder text-dark mt-6'>Select State{<span style={{ color: "red" }}>*</span>}</label>
                        <Select styles={selectStyles} options={states} onChange={(value) => setSelectedState({ label: value?.label, value: value?.value })} />

                        <label className='form-label fs-6 fw-bolder text-dark mt-6'>Select Cities{<span style={{ color: "red" }}>*</span>}</label>
                        <Select styles={selectStyles} options={cities} onChange={(value) => setSelectedCity({ label: value?.label, value: value?.value })} isLoading={citiesLoading} />

                        <div className="d-flex justify-content-end mt-7">
                              <button disabled={loading} onClick={handleAddCityPermission} className='btn btn-sm btn-success '>Add</button>
                        </div>
                  </Modal.Body>
            </Modal>
      </div>
}

export default ManagerCitiesDialog