import React, {Dispatch, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import {IStaff, IUserGroup} from '../../interfaces/Staff'
import HostStaffDetail from './staff-detail/HostStaffDetail'
import {StaffAction} from '../../redux/actionTypes/staffActionTypes'
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators'
import StaffDialog from './StaffDialog'
import HostAddGroupDialog from './HostAddGroupDialog'
import HostEditGroupDialog from './HostEditGroupDialog'
import {useDialog} from '../../contexts/DialogContext'
import HostStaffAddDialog from './HostStaffAddDialog'

const HostStaffs: React.FC = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [searchKey, setSearchKey] = useState<string>('')
  const [selectedStaff, setSelectedStaff] = useState<IStaff>()
  const [selectedStaffType, setSelectedStaffType] = useState('All')
  const [allStaffs, setAllStaffs] = useState<IStaff[]>([])
  const [blockedStaffs, setBlockedStaffs] = useState<IStaff[]>([])
  const [staffsList, setStaffList] = useState<IStaff[]>([])
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([])
  const [showDropBx, setShowDropBx] = useState<boolean>(false)
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [showAddGroupDialog, setShowAddGroupDialog] = useState<boolean>(false)
  const [showEditGroupDialog, setShowEditGroupDialog] = useState<boolean>(false)
  const [selectedGroup, setSelectedGroup] = useState<IUserGroup>()
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const {data, isLoading, error, isFetching, refetch} = useQuery(
    'Get Host Staffs',
    () => ApiCalls.getHostStaffs(user.userSection.hostId),
    {cacheTime: 10000, retry: false}
  )

  const {
    data: hostUserGroupsData,
    isLoading: hostUserGroupsLoading,
    error: hostUserGroupsError,
    refetch: hostUserGroupsRefetch,
  } = useQuery('Get Host User Groups', () => ApiCalls.getHostUserGroups(), {
    cacheTime: 10000,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    setAllStaffs([])
    setStaffList([])
    if (!isLoading) {
      if (data?.data?.data) {
        const blocked = []
        const allList = []
        for (const staff of data?.data?.data) {
          allList.push(staff)
          if (staff.accountStatusId == 2 || staff.accountStatusId == 5) {
            blocked.push(staff)
          }
        }
        setBlockedStaffs(blocked)
        setAllStaffs(allList)
        setStaffList(allList)
      }
    }
  }, [data, isLoading])

  useEffect(() => {
    if (!hostUserGroupsLoading) {
      if (hostUserGroupsData?.data != undefined) {
        const list = []
        for (const group of hostUserGroupsData?.data?.data) {
          var staffs = allStaffs.filter((staff) => staff.hostUserGroupIds?.includes(group.id))
          var data = {
            id: group.id,
            hostId: group.hostId,
            name: group.name,
            isActive: group.isActive,
            staffCount: staffs.length,
          }
          list.push(data)
        }
        setUserGroups(list)
      }
    }
  }, [hostUserGroupsData, hostUserGroupsLoading, allStaffs])

  useEffect(() => {
    if (selectedStaffType == 'All') {
      setStaffList(allStaffs)
    } else if (selectedStaffType == 'Blocked') {
      setStaffList(blockedStaffs)
    } else {
      const group = userGroups.find((ug) => ug.name == selectedStaffType)
      if (group != undefined) {
        var list = allStaffs.filter((staff) => staff.hostUserGroupIds?.includes(group.id))
        setStaffList(list)
      }
    }
  }, [selectedStaffType, data, isLoading, allStaffs])

  const searchStaff = (key: any) => {
    setSearchKey(key)
    if (key != '' && key != null && key != undefined) {
      if (selectedStaffType == 'All') {
        var list = allStaffs
        var result = list.filter(
          (ls) =>
            ls.email.toLowerCase().includes(key.toLowerCase()) ||
            ls.firstName.toLowerCase().includes(key.toLowerCase()) ||
            ls.lastName.toLowerCase().includes(key.toLowerCase())
        )
        setStaffList(result)
      }
      if (selectedStaffType == 'Blocked') {
        var list = blockedStaffs
        var result = list.filter(
          (ls) =>
            ls.email.toLowerCase().includes(key.toLowerCase()) ||
            ls.firstName.toLowerCase().includes(key.toLowerCase()) ||
            ls.lastName.toLowerCase().includes(key.toLowerCase())
        )
        setStaffList(result)
      }
      if (selectedStaffType != 'All' && selectedStaffType != 'Blocked') {
        const group = userGroups.find((ug) => ug.name == selectedStaffType)
        if (group != undefined) {
          var list = allStaffs.filter((staff) => staff.hostUserGroupIds?.includes(group.id))
          var result = list.filter(
            (ls) =>
              ls.email.toLowerCase().includes(key.toLowerCase()) ||
              ls.firstName.toLowerCase().includes(key.toLowerCase()) ||
              ls.lastName.toLowerCase().includes(key.toLowerCase())
          )
          setStaffList(result)
        }
      }
    } else {
      if (selectedStaffType == 'All') {
        setStaffList(allStaffs)
      }
      if (selectedStaffType == 'Blocked') {
        setStaffList(blockedStaffs)
      }
    }
  }

  const handleAddStaff = () => {
    dispatch(staffActionCreators.openStaffAddDialog(null))
  }

  const handleAddGroup = () => {
    setShowAddGroupDialog(true)
  }

  const handleEditGroup = (groupName: string) => {
    setShowDropBx(false)
    const group = userGroups.find((ug) => ug.name == groupName)
    setSelectedGroup(group)
    setShowEditGroupDialog(true)
  }

  const handleRefetch = () => {
    setSelectedGroup(undefined)
    refetch()
    hostUserGroupsRefetch()
  }

  const handleDeleteGroup = async (groupName: string) => {
    const group = userGroups.find((ug) => ug.name == groupName)
    try {
      if (group != undefined) {
        const data = {
          id: group.id,
          hostId: group.hostId,
        }
        await ApiCalls.deleteHostUserGroup(data)
        setShowDropBx(false)
        refetch()
        hostUserGroupsRefetch()
        showSuccessDialog(groupName + ' group deleted successfully')
        setSelectedStaffType('All')
      }
    } catch (error) {
      showFailureDialog('An error occur during deleting group')
    }
  }

  const getContactName = (name: string, surname: string) => {
    return name + ' ' + surname.substring(0, 1) + '.'
  }

  return (
    <div className='app-container'>
      <div className='row g-7'>
        <div className='col-xl-12 mb-3'>
          <div className='card card-body'>
            <div className='container-fluid d-flex align-items-center justify-content-between bg-white py-3'>
              <div className='d-flex align-items-center w-100'>
                <div className='page-title d-md-flex d-none align-items-center flex-wrap me-3  mb-5 mb-lg-0 '>
                  <h1 className='d-flex align-items-center text-dark fw-bold my-1 fs-3'>Groups</h1>
                </div>
                <div className='h-20px d-md-block d-none border-gray-200 border-start ms-3 mx-2'></div>
                <div className='d-flex align-items-center flex-wrap'>
                  <ul className='nav staff-group-scroll flex-nowrap' role='tablist'>
                    <li
                      className='nav-item staff-group-item'
                      role='presentation'
                      onClick={() => {
                        setSelectedStaffType('All')
                        setSearchKey('')
                      }}
                    >
                      <div
                        className={
                          selectedStaffType == 'All'
                            ? 'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 active'
                            : 'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1'
                        }
                      >
                        All
                        <div className='badge badge-light-dark ms-2'>{allStaffs.length}</div>
                      </div>
                    </li>
                    {userGroups.map((group, index) => {
                      return (
                        <li
                          className='nav-item  staff-group-item'
                          role='presentation'
                          onClick={() => {
                            setSelectedStaffType(group.name)
                            setSearchKey('')
                          }}
                          key={'user-group-' + index}
                        >
                          <div
                            className={
                              selectedStaffType == group.name
                                ? 'nav-link btn btn-sm btn-color-muted btn-active-color-warning btn-active-light fw-semibold fs-7 px-4 me-1 active'
                                : 'nav-link btn btn-sm btn-color-muted btn-active-color-warning btn-active-light fw-semibold fs-7 px-4 me-1'
                            }
                          >
                            {group.name}
                            <div className='badge badge-light-warning ms-2'>{group.staffCount}</div>
                          </div>
                        </li>
                      )
                    })}

                    <li
                      className='nav-item staff-group-item'
                      role='presentation'
                      onClick={() => {
                        setSelectedStaffType('Blocked')
                        setSearchKey('')
                      }}
                    >
                      <div
                        className={
                          selectedStaffType == 'Blocked'
                            ? 'nav-link btn btn-sm btn-color-muted btn-active-color-danger btn-active-light fw-semibold fs-7 px-4 me-1 active'
                            : 'nav-link btn btn-sm btn-color-muted btn-active-color-danger btn-active-light fw-semibold fs-7 px-4 me-1'
                        }
                      >
                        Blocked
                        <div className='badge badge-light-danger ms-2'>{blockedStaffs.length}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='d-md-flex d-none justify-content-end min-w-300px'>
                <button
                  className='btn btn-sm btn-success me-2'
                  onClick={() => {
                    handleAddGroup()
                  }}
                >
                  + Add New Group
                </button>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    handleAddStaff()
                  }}
                >
                  + Add New Staff
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 d-md-none d-block'>
          <div className='card card-body'>
            <div className='d-flex justify-content-between min-w-300px'>
              <button
                className='btn btn-sm btn-success me-2'
                onClick={() => {
                  handleAddGroup()
                }}
              >
                + Add New Group
              </button>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  handleAddStaff()
                }}
              >
                + Add New Staff
              </button>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-xl-3 col-12 mb-3'>
          <div className='card card-flush'>
            <div className='card-header pt-7'>
              <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
                <h2>{selectedStaffType}</h2>
                {selectedStaffType != 'All' && selectedStaffType != 'Blocked' && (
                  <div className='position-relative'>
                    <button
                      className='btn btn-sm btn-icon btn-light btn-active-light-primary dropmenu'
                      onClick={() => {
                        setShowDropBx(!showDropBx)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/General/Other2.svg'
                        className='svg-icon-3 svg-icon-secondary dropmenu'
                        svgClassName='dropmenu mh-50px'
                      />
                    </button>
                    <div
                      className={
                        showDropBx
                          ? 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 dropcl show'
                          : 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 dropcl'
                      }
                    >
                      <div
                        className='menu-item px-3'
                        onClick={() => {
                          handleEditGroup(selectedStaffType)
                        }}
                      >
                        <div className='menu-link px-3'>Edit</div>
                      </div>
                      <div
                        className='menu-item px-3'
                        onClick={() => {
                          handleDeleteGroup(selectedStaffType)
                        }}
                      >
                        <div className='menu-link text-danger px-3'>Delete</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex align-items-center position-relative w-100 m-0'>
                <KTSVG
                  path={toAbsoluteUrl('/media/icons/duotone/General/Search.svg')}
                  className='fs-3 position-absolute top-50 ms-5 translate-middle-y svg-icon-2x'
                />
                <input
                  type='text'
                  className='form-control form-control-solid ps-13'
                  value={searchKey}
                  onChange={(v) => {
                    searchStaff(v.target.value)
                  }}
                />
              </div>
            </div>
            <div className='card-body pt-5'>
              {staffsList.length > 0 && (
                <div className='scroll-y me-n5 pe-5 h-300px h-xl-500px'>
                  {staffsList.map((staff, index) => {
                    if (staff.userId != user.userSection.userId)
                      return (
                        <div
                          className='d-flex flex-stack py-4 cursor-pointer'
                          key={'staff-' + index}
                          onClick={() => {
                            setSelectedStaff(staff)
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-40px symbol-circle '>
                              {staff.profilePhotoPath && <img src={staff.profilePhotoPath} />}
                              {!staff.profilePhotoPath && (
                                <span className='symbol-label'>
                                  {staff.firstName.substring(0, 1).toUpperCase() +
                                    '' +
                                    staff.lastName.substring(0, 1).toUpperCase()}
                                </span>
                              )}
                            </div>
                            <div className='ms-4'>
                              <div className='fs-6 fw-bold text-gray-900 text-hover-primary'>
                                {getContactName(staff.firstName, staff.lastName)}
                              </div>
                              {/* <div className='fw-semibold fs-7 text-muted'>{staff.email}</div> */}
                            </div>
                          </div>
                        </div>
                      )
                  })}
                </div>
              )}
              {searchKey != '' && staffsList.length == 0 && (
                <div className='scroll-y me-n5 pe-5 h-300px h-xl-500px'>
                  <div className='d-flex flex-stack py-4 cursor-pointer'>
                    <div className='ms-4'>
                      <div className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                        No matching results found.
                      </div>
                      <div className='fw-semibold fs-7 text-muted'>
                        Please check the name and try again.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {searchKey == '' && staffsList.length == 0 && (
                <div className='scroll-y me-n5 pe-5 h-300px h-xl-500px'>
                  <div className='d-flex flex-stack py-4 cursor-pointer'>
                    <div className='ms-4'>
                      <div className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                        No contact found.
                      </div>
                      <div className='fw-semibold fs-7 text-muted'>
                        You can add new contact by clicking on the button below.
                      </div>
                    </div>
                  </div>
                  <button
                    className='btn btn-primary w-100'
                    onClick={() => {
                      handleAddStaff()
                    }}
                  >
                    + Add New Staff
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-8 col-xl-9 col-12 mb-3'>
          {selectedStaff && <HostStaffDetail staff={selectedStaff} refetch={refetch} />}
          {!selectedStaff && (
            <div className='card card-flush h-lg-100'>
              <div className='card-body'>
                <div className='card-px text-start py-20 my-10'>
                  <h2 className='fs-3x fw-bold mb-10'>Welcome To StaffConnect Hub!</h2>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    At the StaffConnect Hub, you're at the heart of everything. This is where you
                    can effortlessly manage your team, communicate with staff, and streamline your
                    operations. Here's what you can do:{' '}
                  </p>
                  <p className='text-black fs-4 fw-semibold mb-10'>
                    <strong>👥 Connect with Your Team:</strong>
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    Keep in touch with your staff members, managers, and fellow service providers
                    seamlessly.
                  </p>
                  <p className='text-black fs-4 fw-semibold mb-10'>
                    <strong>💬 Chat on the Go:</strong>
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    Our integrated chat feature allows you to communicate instantly, so you can stay
                    in the loop at all times.
                  </p>
                  <p className='text-black fs-4 fw-semibold mb-10'>
                    <strong>📊 Review Marketplace Offers:</strong>
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    View and manage marketplace offers and decide which ones are right for your
                    property.
                  </p>
                  <p className='text-black fs-4 fw-semibold mb-10'>
                    <strong>🤝 Authorize and Manage:</strong>
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    Add managers, staff members, and other service providers to properties, so
                    everyone is on the same page.
                  </p>
                  <p className='text-black fs-4 fw-semibold mb-10'>
                    <strong>✅ Approve or Decline Offers:</strong>
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    Respond to offers from the marketplace directly from here, saving you time and
                    effort.{' '}
                  </p>
                  <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                    Welcome to your command center for all things staff and service management.
                    Let's get started! 🚀
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <HostStaffAddDialog />
      {showAddGroupDialog && (
        <HostAddGroupDialog
          staffs={allStaffs}
          refetch={hostUserGroupsRefetch}
          handleClose={() => {
            setShowAddGroupDialog(false)
          }}
        />
      )}
      {showEditGroupDialog && (
        <HostEditGroupDialog
          staffs={allStaffs}
          group={selectedGroup}
          refetch={handleRefetch}
          handleClose={() => {
            setSelectedGroup(undefined)
            setShowEditGroupDialog(false)
          }}
        />
      )}
    </div>
  )
}

export default HostStaffs
