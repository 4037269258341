import React, { Dispatch, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { Card } from 'react-bootstrap-v5';
import PropertyStep from './Steps/PropertyStep';
import ServiceStep from './Steps/ServiceStep';
import StepNav from '../StepNav/StepNav';
import { useDispatch } from 'react-redux';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { ManualJobRequest } from '../../network/PostRequestModels/JobRequests';

type Props = {
}

const JobWizard: React.FC<Props> = () => {
    const dispatch = useDispatch<Dispatch<JobAction>>();

    useEffect(() => {
        return () => {
            dispatch(jobActionCreator.setJobForm(null));
        }
    }, [])

    return (
        <>
            <Card>
                <Card.Body className='p-3'>
                    <StepWizard transitions={{ enterRight: '', enterLeft: '', exitRight: '', exitLeft: '' }}>
                        <PropertyStep />
                        <ServiceStep />
                    </StepWizard>
                </Card.Body>
            </Card>
        </>
    )
}

export default JobWizard;