import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {useSelector} from 'react-redux'
import ApiCalls from '../../../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {IQuestion, ISection} from '../../../../../interfaces/Checklist'
import SelectInput from '../../../../../umut-components/Inputs/SelectInput'

type Props = {
  sectionName: string | undefined
  question: IQuestion | undefined
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
}

const CloneChecklistSectionQuestionDialog: React.FC<Props> = ({
  sectionName,
  question,
  modalOpen,
  handleClose,
  refetch,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [checklists, setChecklists] = useState<any[]>([])
  const [selectedChecklist, setSelectedChecklist] = useState<any>(0)
  const [sections, setSections] = useState<any[]>([])
  const [selectedSections, setSelectedSections] = useState<number[]>([])
  const [errMsg, setErrMsg] = useState('')
  const {
    data: checklistData,
    isLoading,
    error,
    isFetching,
    refetch: refetchChecklists,
  } = useQuery('Get Host Checklists', () => ApiCalls.getHostChecklists(user.userSection.hostId), {
    cacheTime: 10000,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: modalOpen,
  })

  const {
    data: sectionData,
    isLoading: sectionLoading,
    error: sectionError,
  } = useQuery('Get Host Checklist Sections', () => ApiCalls.getChecklistItems(selectedChecklist), {
    cacheTime: 10000,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: modalOpen && selectedChecklist > 0 ? true : false,
  })

  useEffect(() => {
    if (!isLoading && checklistData) {
      const lists: any[] = []
      if (checklistData?.data && checklistData?.data?.length > 0) {
        for (const item of checklistData?.data) {
          if (item.serviceTypeName) {
            lists.push({
              label:
                item.name.en + ' (' + item.serviceTypeName + ') (' + item.hostPropertyName + ')',
              value: item.id,
            })
          }
        }
      }
      setChecklists(lists)
    }
  }, [checklistData, isLoading])

  useEffect(() => {
    if (!sectionLoading && sectionData) {
      const lists: any[] = []
      if (sectionData?.data && sectionData?.data?.sections?.length > 0) {
        for (const item of sectionData?.data.sections) {
          lists.push({
            label: item.roomIndex,
            value: item.id,
          })
        }
      }
      setSections(lists)
    }
  }, [sectionData, sectionLoading])

  const selectChecklistSection = (options: any) => {
    var list = []
    for (var i = 0; i < options.length; i++) {
      var opt = options[i]

      if (opt.selected) {
        list.push(+opt.value)
      }
    }
    setSelectedSections(list)
  }

  const cloneSectionQuestion = async () => {
    setErrMsg('')
    if (selectedChecklist) {
      try {
        await ApiCalls.cloneSectionQuestion({
          questionId: question?.id,
          hostPropertyChecklistSectionIds: selectedSections,
        })
        refetch()
        handleClose()
      } catch (error) {
        setErrMsg('An error occur during clone question')
      }
    } else {
      setErrMsg('You have to select at least 1 section')
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>
          Clone {question?.title?.en} of {sectionName} to:
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row'>
          <div className='col-12 mb-3'>
            <label className='fs-6 fw-bold'>Checklist</label>
            <select
              className='form-control'
              onChange={(v) => {
                setSelectedChecklist(v.target.value)
              }}
            >
              <option value=''>Please select</option>
              {checklists.map((cl, i) => {
                return <option value={cl.value}>{cl.label}</option>
              })}
            </select>
          </div>
          {selectedChecklist > 0 && (
            <div className='col-12 mb-3'>
              <label className='fs-6 fw-bold'>Sections</label>
              <small className='d-block text-danger'>
                You can select multiple sections to clone to this question
              </small>
              <select
                multiple
                className='form-control'
                onChange={(v) => {
                  selectChecklistSection(v.target.options)
                }}
              >
                {sections.map((s, i) => {
                  return <option value={s.value}>{s.label}</option>
                })}
              </select>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-link text-warning me-3' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={cloneSectionQuestion}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CloneChecklistSectionQuestionDialog
