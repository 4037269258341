/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect} from 'react'
import {Redirect, Switch, Route, useHistory} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'

import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {AuthPage} from '../pages/auth/AuthPage'
import {RedusxAppState} from '../redux/reducers/rootReducer'
import {Logout} from '../pages/auth/components/Logout'
import AccountConfirm from '../pages/accountConfirm/AccountConfirm'
import StaffConfirm from '../pages/staffConfirm/StaffConfirm'
import ForgorPasswordConfirm from '../pages/auth/components/ForgotPasswordConfirm'
import {CometChat} from '@cometchat-pro/chat'
import chatInit from '../services/cometchat/chat'

const Routes: FC = () => {
  const {isAuthenticated, user, loginError} = useSelector((state: RedusxAppState) => state.user)
  const {propertyComplete} = useSelector((state: RedusxAppState) => state.onBoarding)
  const history = useHistory()

  const region = 'eu'
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build()
  if (isAuthenticated) {
    if (user != null && user != undefined) chatInit(user)
  }

  useEffect(() => {
    if (propertyComplete) {
      history.push('add-property-manually')
    }
  }, [])

  return (
    <Switch>
      <Route path='/verify-account' component={AccountConfirm} />
      <Route path='/accept-invite' component={StaffConfirm} />
      <Route path='/forgot-password-host-user' component={ForgorPasswordConfirm} />
      {!isAuthenticated ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthenticated ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
