import {AxiosResponse} from 'axios'
import {put, call, all, fork, takeLatest, delay, takeEvery, select} from 'redux-saga/effects'
import {IOnBoarding} from '../../interfaces/OnBoarding'
import {IUser} from '../../interfaces/User'
import ApiCalls from '../../network/ApiCalls'

import * as onBoardingActionCreators from '../actionCreators/onBoardingActionCreators'
import * as onBoardingActionTypes from '../actionTypes/onBoardingTypes'
import {onBoardingSelector, userSelector} from '../selector/selectors'

function* onGetOnBoarding() {
  try {
    const {user} = yield select(userSelector)
    const {onBoardingComplete: isBoardingComplete} = yield select(onBoardingSelector)
    const result: AxiosResponse<IOnBoarding> = yield call(
      ApiCalls.getOnBoarding,
      user.userSection.hostId
    )

    const hasPaymentCard = result.data.hasPaymentCard
    const propertyComplete = result.data.propertyCount > 0
    const checklistComplete =
      result.data.hostPropertyInfo.length > 0 &&
      result.data.hostPropertyInfo.some((property) => parseInt(property.checklistCount) > 0)
    const staffComplete = result.data.staff ? result.data.staff.length > 0 : false
    const staffServiceComplete = result.data.staff
      ? result.data.staff.some((staff) => staff.staffOfferedServiceId)
      : false
    const staffVisible = result.data.staff !== undefined
    const onBoardingComplete =
      propertyComplete &&
      checklistComplete &&
      (result.data.hasMyTeamProperty
        ? staffVisible === undefined || (staffComplete && staffServiceComplete)
        : result.data.isStripeValid === undefined || result.data.isStripeValid)
    yield put(onBoardingActionCreators.setOnBoardingComplete(onBoardingComplete))
    yield put(
      onBoardingActionCreators.getOnBoardingInfoSuccess(
        result.data,
        propertyComplete,
        checklistComplete,
        staffComplete,
        staffServiceComplete,
        staffVisible,
        hasPaymentCard
      )
    )
  } catch (error: any) {
    yield put(onBoardingActionCreators.getOnBoardingInfoFailure())
  }
}

function* watchOnGetOnBoarding() {
  yield takeLatest(onBoardingActionTypes.GET_ON_BOARDING_INFO, onGetOnBoarding)
}

export default function* onBoardingSaga() {
  yield all([fork(watchOnGetOnBoarding)])
}
