import React, {useEffect} from 'react'
import {useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import * as checklistActionCreator from '../../../../../redux/actionCreators/masterChecklistActionCreators'
import {IChecklistItem, ISection} from '../../../../../interfaces/Checklist'
import {useDispatch} from 'react-redux'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../../../redux/actionCreators/deleteDialogActionCreators'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'
import UpdateButton from '../../../../../umut-components/Buttons/UpdateButton'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd'
import {ChecklistSetSectionOrderRequest} from '../../../../../network/PostRequestModels/ChecklistRequests'
import {Spinner} from 'react-bootstrap-v5'
import SectionBoard from '../../../../master-checklist/MasterChecklistSections/MasterSectionBoard'
import ChecklistSectionQuestion from './ChecklistSectionQuestions'
import ChecklistSectionAddDialog from './ChecklistSectionAddDialog'
import CloneChecklistSectionDialog from './CloneChecklistSectionDialog'
import ChecklistSectionEditDialog from './ChecklistSectionEditDialog'

const grid = 2

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  // styles we need to apply on draggables
  ...draggableStyle,
})

type Props = {
  checklistId: any
  checklistSections: IChecklistItem
  refetch: () => void
}

const ChecklistSections: React.FC<Props> = ({checklistId, checklistSections, refetch}) => {
  const dispatch = useDispatch()
  const deleteDispatch = useDispatch()
  const [selectedChecklistSectionId, setSelectedChecklistSectionId] = useState(0)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [checklistSectionList, setChecklistSectionList] = useState(checklistSections)
  const [saveChangesActive, setSaveChangeActive] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [addSectionModalOpen, setAddSectionModalOpen] = useState<boolean>(false)
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const [showCloneSectionDialog, setShowCloneSectionDialog] = useState(false)
  const [showEditSectionDialog, setShowEditSectionDialog] = useState(false)
  const [cloneSection, setCloneSection] = useState<ISection>()
  const [cloneSectionChecklistName, setCloneSectionChecklistName] = useState()

  const showDeleteDialog = async (section: any) => {
    deleteDispatch(
      openDeleteDialog(
        deleteSection,
        `Do you want to remove section ${section.roomIndex}?`,
        'Delete Section'
      )
    )
  }

  const deleteSection = async () => {
    try {
      await ApiCalls.deleteChecklistSection(selectedChecklistSectionId)
      dispatch(checklistActionCreator.getChecklistItems(checklistId))
      deleteDispatch(closeDeleteDialog())
      showSuccessDialog('Section deleted')
      refetch()
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }

  const handleCloseModal = () => {
    setAddSectionModalOpen(false)
  }

  const handleCloseCloneSectionModal = () => {
    setShowCloneSectionDialog(false)
  }

  const handleAddSectionSuccess = () => {
    setAddSectionModalOpen(false)
    dispatch(checklistActionCreator.getChecklistItems(checklistId))
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index
    if (
      sourceIndex !== undefined &&
      destinationIndex !== undefined &&
      sourceIndex !== destinationIndex
    ) {
      setSaveChangeActive(true)
      let newSections: ISection[] = checklistSections?.sections
        ? [...checklistSections?.sections]
        : []

      let [removedSection] = newSections.splice(sourceIndex, 1)
      newSections.splice(destinationIndex, 0, removedSection)
      setChecklistSectionList({...checklistSections, sections: newSections})
    }
  }

  const handleChangeSectionList = async () => {
    setSendLoading(true)
    try {
      const sectionOrderRequest: ChecklistSetSectionOrderRequest[] = checklistSectionList?.sections
        ? checklistSectionList?.sections.map((section, index): ChecklistSetSectionOrderRequest => {
            return {
              id: section.id,
              orderIndex: index + 1,
            }
          })
        : []
      await ApiCalls.setSectionOrder(sectionOrderRequest, checklistSectionList?.id)
      dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
      setSaveChangeActive(false)
      setSendLoading(false)
      showSuccessDialog('Orders are changed successfully.')
    } catch (err) {
      setSaveChangeActive(false)
      setSendLoading(false)
    }
  }

  const handleCloseEditSectionDialog = () => {
    setShowEditSectionDialog(false)
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <SectionBoard droppableId='section-container'>
          {checklistSectionList?.sections.map((section: any, i: number) => {
            return (
              <Draggable key={section.id} draggableId={section.id.toString()} index={i}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...(selectedChecklistSectionId !== section.id
                        ? provided.draggableProps
                        : null)}
                      {...(selectedChecklistSectionId !== section.id
                        ? provided.dragHandleProps
                        : null)}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      className={snapshot.isDragging ? 'bg-warning' : ''}
                    >
                      <div className='m-0'>
                        <div
                          className='d-flex align-items-center collapsible py-3 toggle mb-0'
                          data-bs-toggle='collapse'
                          data-bs-target={'#property-cl-section-collapse-' + section.id}
                        >
                          <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                            {selectedChecklistSectionId == section.id ? (
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Minus.svg'
                                className='svg-icon-3 svg-icon-secondary'
                              />
                            ) : (
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Plus.svg'
                                className='svg-icon-3 svg-icon-primary'
                              />
                            )}
                          </div>
                          <div className='d-flex justify-content-between align-items-center w-100'>
                            <h4
                              className='text-gray-700 fw-bold cursor-pointer mb-0'
                              onClick={() => {
                                if (selectedChecklistSectionId !== section.id) {
                                  setSelectedChecklistSectionId(section.id)
                                } else {
                                  setSelectedChecklistSectionId(0)
                                }
                              }}
                            >
                              {section.roomIndex}
                            </h4>
                            <div className='text-end position-relative'>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-color-primary dropmenu'
                                onClick={() => {
                                  if (
                                    selectedDropIndx ==
                                    'drop-checklist-section-' + section.id + '-' + i
                                  ) {
                                    setSelectedDropIndx(undefined)
                                  } else {
                                    setSelectedDropIndx(
                                      'drop-checklist-section-' + section.id + '-' + i
                                    )
                                  }
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotone/General/Other2.svg'
                                  className='svg-icon-3 svg-icon-secondary dropmenu'
                                  svgClassName='dropmenu mh-50px'
                                />
                              </button>
                              <div
                                className={
                                  selectedDropIndx ==
                                  'drop-checklist-section-' + section.id + '-' + i
                                    ? 'menu menu-sub menu-sub-dropdown w-100px w-md-100px dropcl align-items-end show'
                                    : 'menu menu-sub menu-sub-dropdown w-100px w-md-100px dropcl align-items-end'
                                }
                              >
                                <div className='px-7 py-5 d-flex flex-column'>
                                  <div
                                    className='btn btn-link btn-sm text-primary text-end'
                                    onClick={() => {
                                      setCloneSection(section)
                                      setCloneSectionChecklistName(checklistSections.name.en)
                                      setShowEditSectionDialog(true)
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className='btn btn-link btn-sm text-info text-end'
                                    onClick={() => {
                                      setCloneSection(section)
                                      setCloneSectionChecklistName(checklistSections.name.en)
                                      setShowCloneSectionDialog(true)
                                    }}
                                  >
                                    Clone
                                  </div>
                                  <div
                                    className='btn btn-link btn-sm text-danger text-end'
                                    onClick={() => {
                                      showDeleteDialog(section)
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={'property-cl-section-collapse-' + section.id}
                          className={
                            selectedChecklistSectionId == section.id
                              ? 'collapse show fs-6 ms-1'
                              : 'collapse fs-6 ms-1'
                          }
                        >
                          <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                            {section.questions.length > 0 && (
                              <ChecklistSectionQuestion section={section} refetch={refetch} />
                            )}
                            {section.questions.length == 0 && (
                              <button
                                className='btn btn-sm btn-link text-primary'
                                onClick={() => {
                                  dispatch(checklistActionCreator.openQuestionAddDialog(section))
                                }}
                              >
                                + Add New Item
                              </button>
                            )}
                          </div>
                        </div>
                        <div className='separator separator-dashed'></div>
                      </div>
                    </div>
                  </>
                )}
              </Draggable>
            )
          })}
          <div className='m-0' style={{padding: 4}}>
            <div
              className='d-flex align-items-center collapsible py-3 toggle mb-0'
              onClick={() => {
                setAddSectionModalOpen(true)
              }}
            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <KTSVG
                  path='/media/icons/duotone/Navigation/Plus.svg'
                  className='svg-icon-3 svg-icon-primary'
                />
              </div>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h4 className='text-primary fw-bold cursor-pointer mb-0'>Add New Room</h4>
              </div>
            </div>
          </div>
        </SectionBoard>
      </DragDropContext>
      {saveChangesActive && (
        <div className='d-flex justify-content-end'>
          <button
            className='btn btn-success'
            disabled={sendLoading}
            style={{height: '100%'}}
            onClick={handleChangeSectionList}
          >
            Save Changes {sendLoading ? <Spinner animation='border' size='sm' /> : null}
          </button>
        </div>
      )}
      <ChecklistSectionAddDialog
        checklistName={checklistSections?.name?.en}
        checklistId={checklistSections.id.toString()}
        orderIndex={+checklistSections?.sections.length + 1}
        modalOpen={addSectionModalOpen}
        handleClose={handleCloseModal}
        handleAddSectionSuccess={handleAddSectionSuccess}
      />
      <CloneChecklistSectionDialog
        modalOpen={showCloneSectionDialog}
        handleClose={handleCloseCloneSectionModal}
        checklistName={cloneSectionChecklistName}
        section={cloneSection}
        refetch={refetch}
      />
      <ChecklistSectionEditDialog
        visible={showEditSectionDialog}
        handleClose={handleCloseEditSectionDialog}
        section={cloneSection}
        checklistId={checklistSections.id}
      />
    </>
  )
}

export default ChecklistSections
