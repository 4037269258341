import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import {AxiosResponse} from 'axios'
import {IBillingInformation} from '../../../../interfaces/Payment'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useDialog} from '../../../../contexts/DialogContext'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import StripeContainer from '../../../../umut-components/PropertyAddWizards/StepTabs/Stripe/StripeContainer'
import {IHostPropertyManualInput, IHostPropertyPaymentInfo} from '../../../../interfaces/Property'

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyManualInput) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: string
}

const PaymentStep: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  currentStep,
}) => {
  const [addCardSection, setAddCardSection] = useState(false)
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const [selectedPropertyPaymentInfo, setSelectedPropertyAutomation] =
    useState<IHostPropertyPaymentInfo>({
      stripeAccountCardId: '',
    })

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(
    ['Get Customer Sources', user.userSection.hostId],
    () => ApiCalls.getCustomerSources(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {
    data: billingInformation,
    isLoading,
    error,
    refetch,
  } = useQuery<AxiosResponse<IBillingInformation>>(
    ['Get Billing Information', user.userSection.hostId],
    () => ApiCalls.getBillingInformation(),
    {cacheTime: 100000, retry: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!billingInformation?.data.stripeAccountId || error) {
      try {
        ApiCalls.postBillingInformation()
          .then((response) => {
            if (!response.status) {
              throw response
            }
          })
          .catch((error) => console.log(error))
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  const toggleAddCard = () => {
    setAddCardSection(!addCardSection)
  }

  const selectCard = async (cardInfo: any) => {
    setSelectedPropertyAutomation({stripeAccountCardId: cardInfo.id})
  }

  const handleComplete = () => {
    var request = {hostPropertyPaymentInfo: selectedPropertyPaymentInfo}
    handleChangeHostProperty(request)
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }

  return (
    <>
      <div className='form w-100 mt-5'>
        <div className='row mt-5'>
          <div className='col-md-3 col-12 mb-3 text-center'>
            <div className='d-flex justify-content-center mb-3'>
              <div className='success-check'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14px'
                  height='14px'
                  viewBox='0 0 24 24'
                  version='1.1'
                >
                  <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                    <polygon points='0 0 24 0 24 24 0 24' />
                    <path
                      d='M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z'
                      fill='#fff'
                      fill-rule='nonzero'
                      transform='translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) '
                    />
                  </g>
                </svg>
              </div>
            </div>
            <h5>Add Your Card</h5>
            <p>Save time and track your expenses with adding your payment card.</p>
          </div>
          <div className='col-md-3 col-12 mb-3 text-center'>
            <div className='d-flex justify-content-center mb-3'>
              <div className='success-check'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14px'
                  height='14px'
                  viewBox='0 0 24 24'
                  version='1.1'
                >
                  <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                    <polygon points='0 0 24 0 24 24 0 24' />
                    <path
                      d='M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z'
                      fill='#fff'
                      fill-rule='nonzero'
                      transform='translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) '
                    />
                  </g>
                </svg>
              </div>
            </div>
            <h5>Auto Payment</h5>
            <p>
              With automatic payment, we pay the cleaner's account after the cleaning job is done.
            </p>
          </div>
          <div className='col-md-6 col-12 mb-3'>
            <div className='d-flex justify-content-end align-items-center'>
              <img
                src={toAbsoluteUrl('/media/svg/powered-by-stripe.png')}
                alt='stripe'
                style={{maxWidth: 240}}
              />
            </div>
          </div>
        </div>
        <div className='row mt-5' style={{minHeight: '30vh'}}>
          <div className='col-md-9 col-12 mb-4'>
            <h5>Your Payment Cards:</h5>
            <div className='row mt-5'>
              {cardList?.data && cardList?.data.length > 0 && (
                <>
                  {cardList?.data.map((cl: any, i: number) => {
                    return (
                      <div className='col-md-4 col-12 mb-4'>
                        <div
                          style={{
                            borderWidth: 1,
                            borderColor: '#ddd',
                            borderRadius: 8,
                            borderStyle: 'solid',
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 24,
                            paddingRight: 24,
                            height: 130,
                            position: 'relative',
                          }}
                        >
                          <div className='d-flex justify-content-between'>
                            <CreditLogos brand={cl?.brand} />
                            <div>
                              {cl?.default && (
                                <span className='badge badge-sm badge-success'>Default</span>
                              )}
                              {!cl?.default && (
                                <span className='badge badge-sm badge-danger'>Not Default</span>
                              )}
                            </div>
                          </div>
                          <div style={{position: 'absolute', left: 20, bottom: 30}}>
                            **** **** **** {cl?.last4}
                          </div>
                          <div style={{position: 'absolute', right: 20, bottom: 10}}>
                            {((selectedPropertyPaymentInfo.stripeAccountCardId &&
                              selectedPropertyPaymentInfo.stripeAccountCardId != cl.id) ||
                              !selectedPropertyPaymentInfo.stripeAccountCardId) && (
                              <button
                                className='btn btn-sm btn-danger'
                                onClick={() => {
                                  selectCard(cl)
                                }}
                              >
                                Use This
                              </button>
                            )}
                            {selectedPropertyPaymentInfo.stripeAccountCardId &&
                              selectedPropertyPaymentInfo.stripeAccountCardId == cl.id && (
                                <button className='btn btn-sm btn-success'>Selected</button>
                              )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
              <div className='col-md-4 col-12 mb-4'>
                <button
                  onClick={() => {
                    toggleAddCard()
                  }}
                  style={{
                    backgroundColor: '#fff',
                    borderWidth: 1,
                    borderColor: '#ddd',
                    borderRadius: 8,
                    borderStyle: 'dashed',
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingLeft: 24,
                    paddingRight: 24,
                    height: 130,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <h3 className='text-success'>+ Add New Card</h3>
                </button>
              </div>
            </div>
          </div>
          {addCardSection && (
            <div className='col-md-5 col-12 ms-auto me-auto mb-4 mt-8'>
              <StripeContainer />
            </div>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-end flex-row w-100 mt-6'>
        <button className='btn btn-success' onClick={handleComplete}>
          Confirm
        </button>
      </div>
    </>
  )
}

export default PaymentStep

type CreditProps = {
  brand: string | undefined
}
const CreditLogos: React.FC<CreditProps> = ({brand}) => {
  switch (brand) {
    case 'MasterCard':
      return <img src={toAbsoluteUrl('/media/logos/creditcard-logos/mc.png')} alt='mc' />
    case 'Visa':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/visa_electron.png')} alt='visa' />
      )
    case 'AmericanExpress':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express.png')} alt='ae' />
      )
    default:
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express_green.png')}
          alt='defaultcard'
        />
      )
  }
}
