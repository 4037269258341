import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ChecklistSectionForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistSectionForm'
import {decryptText} from '../../../utils/util'

type Props = {
  modalOpen: boolean
  handleClose?: () => void
  handleAddSectionSuccess: () => void
}

const SectionAddDialog: React.FC<Props> = ({modalOpen, handleClose, handleAddSectionSuccess}) => {
  let {checklistId}: any = useParams()
  checklistId = decryptText(checklistId)
  const {checklistItems} = useSelector((state: RedusxAppState) => state.masterChecklist)

  return (
    <Modal show={modalOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>
          Add a room to{' '}
          {checklistItems?.name?.en
            ? checklistItems?.name?.en
            : checklistItems?.name[Object.keys(checklistItems.name)[0]]}
        </Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <ChecklistSectionForm
          hostPropertyChecklistId={checklistId}
          handleAddSectionSuccess={handleAddSectionSuccess}
        />
      </Modal.Body>
    </Modal>
  )
}

export default SectionAddDialog
