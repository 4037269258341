import ContentLoader from "react-content-loader"

const SkeletonCircleLoading = () => {
    return (<>

        <div style={{ overflowX: 'hidden' }}>
            <ContentLoader
                speed={2}
                width={1200}
                height={150}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"

            >
                <circle cx="79" cy="66" r="64" />
                <rect x="170" y="20" rx="8" ry="8" width="200" height="10" />
                <rect x="170" y="60" rx="8" ry="8" width="200" height="10" />
                <rect x="170" y="95" rx="8" ry="8" width="200" height="10" />
            </ContentLoader>
        </div>

    </>
    )
}

export default SkeletonCircleLoading
