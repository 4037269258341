import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Accordion, Button, Card, ListGroup, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {MarketplaceDetailResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {decryptText} from '../../utils/util'
import MarketplaceOffers from './MarketplaceOffers'

const MarketplaceDetail = () => {
  let {marketplaceId}: any = useParams()
  marketplaceId = decryptText(marketplaceId)
  const {user} = useSelector((state: RedusxAppState) => state.user)

  const {
    data: marketplaceData,
    isFetching: marketplaceLoading,
    error: marketplaceError,
    refetch: refetchMarketplaceData,
  } = useQuery<AxiosResponse<MarketplaceDetailResponse>>(
    'Get Marketplace Detail',
    () => ApiCalls.getMarketplaceDetail(marketplaceId),
    {cacheTime: 500000, enabled: marketplaceId ? true : false}
  )

  if (marketplaceError) {
    return <div>An error occur during getting marketplace detail request!</div>
  }

  return (
    <>
      <PageTitle>{'Marketplace - ' + marketplaceData?.data?.data.address}</PageTitle>
      <div className='pt-3'>
        {marketplaceLoading ? (
          <SkeletonTableLoading />
        ) : (
          <>
            <Accordion defaultActiveKey='-1'>
              <Card>
                <Accordion.Toggle as={Card.Header} variant='link' eventKey='0'>
                  <Card.Title>Request Parameters</Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body>
                    <ListGroup>
                      <ListGroup.Item className='border-0'>
                        <strong>Address</strong> : {marketplaceData?.data?.data?.address}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Estimated Project Time</strong> :{' '}
                        {marketplaceData?.data?.data?.cleaningDuration} hours
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Jobs Per Month</strong> :{' '}
                        {marketplaceData?.data?.data?.jobsPerMonth} jobs
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Bedrooms</strong> : {marketplaceData?.data?.data?.numberOfBedrooms}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Bathrooms</strong> :{' '}
                        {marketplaceData?.data?.data?.numberOfBathrooms}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Unit Size</strong> : {marketplaceData?.data?.data?.propertySize}{' '}
                        sqft.
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>On-Site Laundry</strong> :{' '}
                        {marketplaceData?.data?.data?.cleanLinenAndTowel
                          ? 'Required'
                          : 'Not Required'}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Off-Site Laundry</strong> :{' '}
                        {marketplaceData?.data?.data?.offSiteLinenAndTowel
                          ? 'Required'
                          : 'Not Required'}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Supplies</strong> :{' '}
                        {marketplaceData?.data?.data?.hostProvideCleaningSupplies
                          ? 'Provided'
                          : 'Not Provided'}
                      </ListGroup.Item>
                      <ListGroup.Item className='border-0'>
                        <strong>Note</strong> :{' '}
                        {marketplaceData?.data?.data?.note == ''
                          ? '-'
                          : marketplaceData?.data?.data?.note}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Card className='mt-5'>
              <Card.Header>
                <Card.Title>Management Offers</Card.Title>
              </Card.Header>
              <Card.Body>
                <MarketplaceOffers
                  offers={
                    marketplaceData?.data?.data?.offers != undefined
                      ? marketplaceData?.data?.data?.offers
                      : []
                  }
                  isManagementService={true}
                  fetchData={refetchMarketplaceData}
                />
              </Card.Body>
            </Card>
            <Card className='mt-5'>
              <Card.Header>
                <Card.Title>Cleaning Offers</Card.Title>
              </Card.Header>
              <Card.Body>
                <MarketplaceOffers
                  offers={
                    marketplaceData?.data?.data?.offers != undefined
                      ? marketplaceData?.data?.data?.offers
                      : []
                  }
                  isManagementService={false}
                  fetchData={refetchMarketplaceData}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  )
}

export default MarketplaceDetail
