import {DateBox} from 'devextreme-react'
import config from '../../config/config.json'
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'

type Props = {
  onChange: (data: any) => void
  defaultValue?: string
  disabled?: boolean
  min?: string
  max?: string
  className?: string
}

const TimePicker: React.FC<Props> = ({min, max, onChange, defaultValue, disabled, className}) => {
  const [initialValue, setInitialValue] = useState<string | undefined | Date | null>(undefined)

  const handleChangeTime = (value: any) => {
    onChange(dayjs(value).format('HH:mm'))
  }

  useEffect(() => {
    if (defaultValue) {
      try {
        let time = dayjs()
          .set('hours', parseInt(defaultValue.split(':')[0]))
          .set('minutes', parseInt(defaultValue.split(':')[1]))
        if (time.toString() !== 'Invalid Date') {
          setInitialValue(time.toString())
        } else {
          time = dayjs()
            .set('hours', parseInt(defaultValue.split('.')[0]))
            .set('minutes', parseInt(defaultValue.split('.')[1]))
          if (time.toString() !== 'Invalid Date') {
            setInitialValue(time.toString())
          } else {
            setInitialValue(null)
          }
        }
      } catch (err: any) {
        setInitialValue(null)
      }
    } else {
      setInitialValue(null)
    }
  }, [defaultValue])

  return (
    <>
      {initialValue !== undefined ? (
        <DateBox
          className={className}
          type='time'
          min={min}
          max={max}
          openOnFieldClick={true}
          defaultValue={initialValue}
          onValueChange={handleChangeTime}
          disabled={disabled}
        />
      ) : null}
    </>
  )
}

export default TimePicker
