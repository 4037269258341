import React, { Dispatch } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import UserEditForm from '../../umut-components/Forms/UserEditForm';
import JobWizard from '../../umut-components/JobWizard/JobWizard';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';



const JobDialog = () => {
    const { jobDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();

    const handleClose = () => {
        dispatch(jobActionCreator.closeJobDialog());
    }

    return (
        <Dialog open={jobDialog.modalOpen} onClose={handleClose} maxWidth='lg' fullWidth={true} style={{ overflowY: 'unset' }}>
            <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
                Add Job
                <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'unset' }}>
                {jobDialog.modalOpen && <JobWizard />}
            </DialogContent>
        </Dialog>
    )
}

export default JobDialog;