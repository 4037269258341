import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IJob, IJobDetail } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import JobServiceAddForm from '../../../../umut-components/Forms/JobServiceAddForm';

type Props = {
    jobDetail: IJobDetail,
}

const ServiceDialog: React.FC<Props> = ({ jobDetail }) => {
    const { serviceAddDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();

    const handleClose = () => {
        dispatch(jobActionCreator.closeServiceAddDialog());
    }

    return (
        <Modal show={serviceAddDialog.modalOpen} onHide={handleClose} size="lg" centered>
            <ModalHeader closeButton>
                <Modal.Title>Add Service</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <JobServiceAddForm roleId={jobDetail.roleId} hostPropertyId={jobDetail.hostPropertyId} />
            </Modal.Body>
        </Modal>
    )
}

export default ServiceDialog;