import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {toAmazonUrl} from '../../../../utils/util'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'

type Props = {}

const StaffTable: React.FC<Props> = () => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const {proTeamDetail, listLoading} = useSelector((state: RedusxAppState) => state.proTeam)

  const {
    data: staffData,
    isLoading: staffLoading,
    error: staffError,
  } = useQuery(
    ['Vendor Staffs', proTeamDetail.proTeam?.hostPropertyTeam?.vendorId],
    () => ApiCalls.getStaffByVendorId(proTeamDetail.proTeam?.hostPropertyTeam?.vendorId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: proTeamDetail.proTeam?.userId ? true : false,
    }
  )

  const renderProfilePhoto = (cellValue: any) => {
    return cellValue?.data?.profilePhotoPath ? (
      <img
        alt='pp'
        style={{width: 45, height: 45, borderRadius: '50%'}}
        src={
          cellValue?.data?.profilePhotoPath
            ? toAmazonUrl(cellValue?.data?.profilePhotoPath)
            : toAbsoluteUrl('/media/icons/noImage.png')
        }
      />
    ) : (
      <ProfilePhotoTemp
        firstName={cellValue?.data?.firstName}
        lastName={cellValue?.data?.lastName}
        width={45}
        height={45}
        borderRadius='50%'
      />
    )
  }

  const calculateFullName = (cellValue: any) => {
    return cellValue?.firstName + ' ' + cellValue?.lastName
  }

  if (staffLoading) {
    return <SkeletonTableLoading />
  }

  if (proTeamDetail.proTeam?.staffs?.length === 0 || staffError) {
    return <EmptyTable type='staff' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='vendorStaffTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={staffData?.data?.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(54vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'none'}}
      >
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Staff: {0}'
            column='firstName'
            summaryType='count'
          />
        </Summary>

        <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
        <Column
          dataField='firstName'
          calculateCellValue={calculateFullName}
          caption='Name'
          minWidth={180}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='lastName'
          calculateCellValue={calculateFullName}
          caption='Surname'
          minWidth={180}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='roleName'
          caption='Role'
          minWidth={180}
          dataType='string'
          cssClass='cls'
        />
        <Column dataField='email' dataType='string' width={200} cssClass='cls' />
        <Column dataField='phoneNumber' dataType='string' width={200} cssClass='cls' />
        <Column
          dataField='accountStatusName'
          caption='Status'
          dataType='string'
          width={180}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default React.memo(StaffTable)
