import React, {Dispatch, useState} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {DeleteActions} from '../../redux/actionTypes/deleteDialogTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import * as deleteDialogActionCreators from '../../redux/actionCreators/deleteDialogActionCreators'

const DeleteDialog: React.FC = () => {
  const {title, bodyText, btnText, modalOpen, deleteFn} = useSelector(
    (state: RedusxAppState) => state.deleteDialog
  )
  const dispatch = useDispatch<Dispatch<DeleteActions>>()
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | null>(null)

  const handleClose = () => {
    dispatch(deleteDialogActionCreators.closeDeleteDialog())
  }

  const handleDelete = async () => {
    try {
      setLoading(true)
      setErrorText(null)
      deleteFn && (await deleteFn())
      setLoading(false)
    } catch (err: any) {
      setErrorText(err?.response?.data?.message)
      setLoading(false)
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>{title}</Modal.Title>
      </ModalHeader>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant='link' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDelete} disabled={loading}>
          {btnText} {loading ? <Spinner animation='border' size='sm' /> : null}
        </Button>
        <strong className='text-danger'>{errorText}</strong>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteDialog
