import {IOnBoarding} from '../../interfaces/OnBoarding'
import * as actions from '../actionTypes/onBoardingTypes'

export function getOnBoardingInfo(): actions.GetOnBoardingInfo {
  return {
    type: actions.GET_ON_BOARDING_INFO,
  }
}

export function getOnBoardingInfoSuccess(
  onBoardingInfo: IOnBoarding,
  propertyComplete: boolean,
  checklistComplete: boolean,
  staffComplete: boolean,
  staffServiceComplete: boolean,
  staffVisible: boolean,
  hasPaymentCard: boolean
): actions.GetOnBoardingInfoSuccess {
  return {
    type: actions.GET_ON_BOARDING_INFO_SUCCESS,
    onBoardingInfo,
    propertyComplete,
    checklistComplete,
    staffComplete,
    staffServiceComplete,
    staffVisible,
    hasPaymentCard,
  }
}

export function getOnBoardingInfoFailure(): actions.GetOnBoardingInfoFailure {
  return {
    type: actions.GET_ON_BOARDING_INFO_FAILURE,
  }
}

export function setOnBoardingComplete(isCompleted: boolean): actions.SetOnBoardingComplete {
  return {
    type: actions.SET_ON_BOARDING_COMPLETE,
    isCompleted,
  }
}
