import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap-v5";
import { useMutation, useQueryClient } from "react-query";
import ApiCalls from "../../../../network/ApiCalls";


export default function PaymentForm() {
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();

    const queryClient = useQueryClient();
    const { mutateAsync } = useMutation(ApiCalls.postCustomerSource, { onSuccess: () => queryClient.invalidateQueries('Get Customer Sources') })


    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };


    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setProcessing(true);

        const card = elements?.getElement(CardElement)

        if (stripe && elements && card) {
            const payload = await stripe.createToken(card);
            let source = payload.token?.id
            if (source) {
                try {
                    setError(null);
                    await mutateAsync({ source }).then((response: any) => {
                        setSuccess(response?.data?.message);
                        setProcessing(false);
                    }
                    )
                } catch (error: any) {
                    console.log('error', error);
                    setError(`Registration failed.`);
                    setProcessing(false);
                }
            }
            if (payload?.error) {
                setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            }
        }
    };


    return (
        <>

            <div className="row w-100">
                <div className="col-lg-12 mx-auto">
                    <div className="p-5 ms-2">

                        <div className="tab-content">
                            <span id="card-header " className="fs-5 fw-bold">New card:</span>
                            <div id="nav-tab-card" className="tab-pane fade show active mt-5">
                                {success && <p className="alert alert-success">{success}</p>}
                                {error && <p className="alert alert-danger">{error}</p>}

                                <form onSubmit={handleSubmit} role="form" id="payment-form">
                                    <div className="form-group">
                                        <label htmlFor="username" >Full name (on the card)</label>
                                        <input type="text" name="username" placeholder="Jassa" required className="form-control" />
                                    </div>
                                    <div className="form-group mt-5">
                                        <label htmlFor="cardNumber">Card number</label>
                                        <fieldset className="FormGroup">
                                            <div className="FormRow form-control rounded h-40px">
                                                <div className="my-auto">
                                                    <CardElement options={cardStyle} id="card-element" />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <button type="submit" disabled={processing} className="subscribe btn btn-success btn-block w-100 rounded-sm shadow-sm mt-15"> Add Card {processing && <Spinner animation="border" size="sm" />} </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
              
            </div>


        </>
    )
}
