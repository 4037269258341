import {
      Column,
      DataGrid,
      Export,
      HeaderFilter,
      Paging,
      Scrolling,
      SearchPanel,
} from 'devextreme-react/data-grid'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ApiCalls from '../../../network/ApiCalls'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import { decryptText, onExporting } from '../../../utils/util'
import ManagerCitiesDialog from './ManagerCitiesDialog'

const CitiesPermission = () => {
      let { staffId }: any = useParams();
      staffId = decryptText(staffId);

      const { user } = useSelector((state: RedusxAppState) => state.user);
      const [request, setRequest] = useState(false);
      const [modalOpen, setModalOpen] = useState<boolean>(false)
      const { data, isLoading, error, refetch } = useQuery(['Manager cities permission', Number(staffId)], () => ApiCalls.getManagerCities(staffId, user?.userSection?.hostId), { cacheTime: 500000, enabled: staffId && user?.userSection?.hostId ? true : false })


      const handleDeleteCity = async (cellValue: any) => {
            try {
                  setRequest(true)     
                  await ApiCalls.deleteManagerCities(cellValue?.data?.id)
                  refetch()
                  setRequest(false)
            } catch (error: any) {
                  setRequest(false)
                  console.log(error?.response?.data.message)
            }
      }
      
      const displayDeleteButton = (cellValue: any) => {
            return <DeleteButton disabled={request} deleteFn={() => handleDeleteCity(cellValue)} />
      }
      const modalClose = () => setModalOpen(false)
      if (isLoading) {
            return <SkeletonTableLoading />
      }

      return <div>
            <DataGrid
                  id='ManagerCities'
                  keyExpr='id'
                  onRowPrepared={(e) => {
                        e.rowElement.classList.add('data-grid-row')
                  }}
                  dataSource={data?.data?.data}
                  showBorders={false}
                  showColumnLines={false}
                  showRowLines={true}
                  hoverStateEnabled={true}
                  height={'calc(50vh - 60px)'}
                  allowColumnResizing={true}
                  onExporting={(e) => onExporting(e, "Permission Cities")}
                  selection={{ mode: 'single' }}
            >
                  <Export enabled />
                  <HeaderFilter visible={true} />
                  <Scrolling showScrollbar='always' />
                  <Paging enabled={false} />
                  <SearchPanel visible={true} />
                  <Column dataField='cityName' minWidth={150} cssClass='cls' />
                  <Column
                        dataField='stateName'
                        alignment='center'
                        caption='STATE'
                        minWidth={150}
                        cssClass='cls'
                  />

                  <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
            </DataGrid>
            <div className="d-flex justify-content-end p-5">
                  <button onClick={() => setModalOpen(true)} className='btn btn-sm btn-success '>Add City</button>
            </div>
            <ManagerCitiesDialog modalOpen={modalOpen} closeModal={modalClose} />
      </div>
}

export default CitiesPermission