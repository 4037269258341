/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserAction } from '../../../redux/actionTypes/userActionTypes'
import * as actions from '../../../redux/actionCreators/userActionCreators';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { Redirect, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap-v5';

export function Logout() {
    const history = useHistory();
    const { isAuthenticated } = useSelector((state: RedusxAppState) => state.user);
    const dispatch = useDispatch<Dispatch<UserAction>>();

    const handleLogout = () => {
        dispatch(actions.logout());
    }

    const handleCanceLogout = () => {
        history.goBack();
    }

    if (isAuthenticated) {
        return (
            <Modal show={true} onHide={() => { }} centered>
                <Modal.Body>
                    Are you sure you want to exit?
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-end'>
                    <button className='btn btn-link text-warning me-3' onClick={handleCanceLogout}>Cancel</button>
                    <button className='btn btn-danger' onClick={handleLogout}>Exit</button>
                </Modal.Footer>
            </Modal>
        )
    }

    return <Redirect to="/auth/login" />;
}
