import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {onExporting} from '../../../../utils/util'
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton'
import {useDispatch, useSelector} from 'react-redux'
import {DeleteActions} from '../../../../redux/actionTypes/deleteDialogTypes'
import * as deleteActionCreator from '../../../../redux/actionCreators/deleteDialogActionCreators'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ApiCalls from '../../../../network/ApiCalls'
import {useDialog} from '../../../../contexts/DialogContext'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'

type Props = {
  data: any
  refetch: () => void
  propertyType: 'allowed' | 'banned'
}

const renderTooltip = (props: any, description: string) => (
  <Tooltip id='button-tooltip' style={{zIndex: 10}} {...props}>
    {description}
  </Tooltip>
)

const PropertyTable: React.FC<Props> = ({data, refetch, propertyType}) => {
  const {staffDetail} = useSelector((state: RedusxAppState) => state.staff)
  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
  } = useQuery(
    ['Job History', staffDetail.staff?.userId],
    () => ApiCalls.getJobsByUserId(staffDetail.staff?.userId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: staffDetail.staff?.userId ? true : false,
    }
  )

  const dispatch = useDispatch<Dispatch<DeleteActions>>()
  const {showFailureDialog} = useDialog()
  const tableRef = useRef<DataGrid>(null)

  const renderDeleteButton = (cellValue: any) => {
    const deleteFn = () => {
      dispatch(
        deleteActionCreator.openDeleteDialog(
          async () => {
            try {
              propertyType === 'allowed' &&
                (await ApiCalls.deletePropertyPermission(cellValue?.data?.id))
              propertyType === 'banned' &&
                (await ApiCalls.deleteBanUserFromProperty(cellValue?.data?.id))
              refetch()
              dispatch(deleteActionCreator.closeDeleteDialog())
            } catch (err: any) {
              showFailureDialog(err?.response?.data?.message)
            }
          },
          `Do you want to remove ${propertyType} property ${cellValue?.data?.name} from staff ${
            staffDetail.staff?.firstName + ' ' + staffDetail.staff?.lastName
          } ?`,
          'Do you want to remove property?'
        )
      )
    }
    return <DeleteButton iconWidth='16' iconHeight='16' deleteFn={deleteFn} />
  }

  const renderProperty = (cellValue: any) => {
    return propertyType === 'allowed' ? (
      cellValue?.data?.name
    ) : (
      <OverlayTrigger
        placement='top'
        overlay={(props) => renderTooltip(props, cellValue?.data?.description)}
      >
        <span>{cellValue?.data?.name}</span>
      </OverlayTrigger>
    )
  }

  if (data?.length === 0) {
    return (
      <div className='h-300px'>
        <EmptyTable title='No properties' />
      </div>
    )
  }

  return (
    <div>
      <DataGrid
        id={'propertyTable ' + propertyType}
        keyExpr='hostPropertyId'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(45vh - 60px)'}
        width={'100%'}
        style={{maxWidth: '100%'}}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Properties: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='name'
          caption='Property'
          cellRender={renderProperty}
          width={350}
          cssClass='cls'
        />
        <Column dataField='cityName' caption='city' width={200} cssClass='cls' />
        <Column dataField='stateName' caption='state' width={150} cssClass='cls' />
        <Column dataField='countryName' caption='country' width={150} cssClass='cls' />
        <Column cellRender={renderDeleteButton} width={75} />
      </DataGrid>
    </div>
  )
}

export default PropertyTable
