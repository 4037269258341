import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import {IStaff, IUserGroup} from '../../interfaces/Staff'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import ApiCalls from '../../network/ApiCalls'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  handleClose: () => void
  staffs: IStaff[]
  group: IUserGroup | undefined
  refetch: () => void
}
const HostEditGroupDialog: React.FC<Props> = ({handleClose, staffs, group, refetch}) => {
  const [groupName, setGroupName] = useState<string>(group?.name ?? '')
  const [selectedStaffs, setSelectedStaffs] = useState<number[]>([])
  const [staffList, setStaffList] = useState<any[]>([])
  const [errMsg, setErrMsg] = useState<string>('')
  const {showSuccessDialog} = useDialog()

  useEffect(() => {
    if (staffs.length > 0) {
      if (group != undefined) {
        const filteredStaffs = staffs.filter((s) => s.hostUserGroupIds?.includes(group?.id))
        const staffIds: any[] = []
        for (const fs of filteredStaffs) {
          if (!staffIds.includes(fs.userId)) {
            staffIds.push(fs.userId)
          }
        }
        setSelectedStaffs(staffIds)
      }
      const list: any[] = []
      for (const staff of staffs) {
        var find = list.find((l) => l.value == staff.userId)
        if (find == undefined) {
          list.push({
            label: staff.firstName + ' ' + staff.lastName.substring(0, 1) + '.',
            value: staff.userId,
          })
        }
      }

      setStaffList(list)
    }
  }, [staffs])

  const selectStaff = (options: any) => {
    var list = []
    for (var i = 0; i < options.length; i++) {
      var opt = options[i]

      if (opt.selected) {
        list.push(+opt.value)
      }
    }
    setSelectedStaffs(list)
  }

  const handleApply = async () => {
    setErrMsg('')
    if (groupName == '') {
      setErrMsg('Group name is required')
    } else if (groupName.length < 3) {
      setErrMsg('Group name must be at least 3 characters')
    } else if (groupName == 'All' || groupName == 'Blocked') {
      setErrMsg(
        'Group name is not allowedThe group name must be different from "All" or "Blocked".'
      )
    } else if (selectedStaffs.length == 0) {
      setErrMsg('Select at least one contact')
    } else {
      const data = {
        id: group?.id,
        name: groupName,
        staffs: selectedStaffs,
      }

      try {
        await ApiCalls.updateHostUserGroups(data)
        showSuccessDialog('Group created successfully')
        refetch()
        handleClose()
      } catch (error) {
        setErrMsg('An error occur during creating group')
      }
    }
  }

  return (
    <div>
      <Modal show={true} onHide={handleClose} size='lg' centered>
        <ModalHeader closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </ModalHeader>
        <Modal.Body className='pt-0'>
          <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
              <div className='col-lg-12 w-100 mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark mt-6 '>
                  Group Name {<span style={{color: 'red'}}>*</span>}
                </label>
                <TextInput
                  defaultValue={groupName}
                  onValueChange={(value) => {
                    setGroupName(value)
                  }}
                />
              </div>
              <div className='col-lg-12 w-100'>
                <label className='form-label fs-6 fw-bolder text-dark mt-6 '>
                  Select Contact {<span style={{color: 'red'}}>*</span>}
                </label>
                <select
                  className='form-control'
                  multiple
                  onChange={(v) => {
                    selectStaff(v.target.options)
                  }}
                >
                  {staffList.map((staff, index) => {
                    return (
                      <option
                        value={staff.value}
                        key={'opt-' + index}
                        selected={selectedStaffs.includes(staff.value)}
                      >
                        {staff.label}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          {errMsg != '' && <span className='text-danger fs-6 me-3'>{errMsg}</span>}
          <button className='btn btn-link text-dark me-3' onClick={handleClose}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={handleApply}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default HostEditGroupDialog
