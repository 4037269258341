import React, { Dispatch, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap-v5';
import { PageTitle } from '../../../../_metronic/layout/core';
import { StaffAction } from '../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreators from '../../../redux/actionCreators/staffActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProfileTab from './Tabs/ProfileTab';
import OfferedServices from './Tabs/OfferedServices';
import Jobs from './Tabs/Jobs';
import OffDayTab from './Tabs/OffDayTab';
import PropertyTab from './Tabs/PropertyTab';
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog';
import StaffPasswordEdit from '../StaffPasswordEdit';
import StaffEditDialog from '../StaffEditDialog';
import { decryptText } from '../../../utils/util';

type TabNames =
    | 'property-list'
    | 'staff-offered-service'
    | 'jobs'
    | 'off-days'

const StaffDetail: React.FC = () => {
    let { staffId }: any = useParams();
    staffId = decryptText(staffId);
    const [selectedTab, setSelectedTab] = useState<TabNames>('property-list');
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const handleChangeTab = (key: any) => {
        setSelectedTab(key);
    }

    useEffect(() => {
        dispatch(staffActionCreators.getStaffDetail(parseInt(staffId)));
    }, [])

    return (
        <>
            <PageTitle>Staff Detail</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className="shadow">
                    <Card.Body className='p-0'>
                        <ProfileTab modalOpen={setEditDialogOpen} />
                        <Tabs defaultActiveKey="property-list" id="uncontrolled-tab-example" className="mb-3 mt-3" onSelect={handleChangeTab}>
                            <Tab eventKey="property-list" title="Properties">
                                {selectedTab === 'property-list' && <PropertyTab />}
                            </Tab>
                            <Tab eventKey="staff-offered-service" title="Offered Services">
                                {selectedTab === 'staff-offered-service' && <OfferedServices />}
                            </Tab>
                            <Tab eventKey="jobs" title="Jobs">
                                {selectedTab === 'jobs' && <Jobs />}
                            </Tab>
                            <Tab eventKey="off-days" title="Off Days">
                                {selectedTab === 'off-days' && <OffDayTab />}
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
            <StaffEditDialog modalClose={setEditDialogOpen} modalOpen={editDialogOpen} />
            <StaffPasswordEdit />
            <DeleteDialog />
        </>
    )
}

export default StaffDetail;