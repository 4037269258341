import React from 'react'
import AllowedProperties from './AllowedProperties'

const PropertyTab: React.FC = () => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <AllowedProperties />
      </div>
    </div>
  )
}

export default PropertyTab
