import axios, {AxiosRequestConfig} from 'axios'

const appID = '227187fc335d9a40'
const authKey = 'dc8f171410d6cb35f3df34a08902ad8316eba2d0'
const apiKey = 'f2933a6200901babaaa9fe75768b89abb051a892'
const region = 'eu'
const baseUrl = 'https://227187fc335d9a40.api-eu.cometchat.io/v3/'

export const createChatUser = async (uid: string, name: string, email: string, phone: string) => {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      apikey: apiKey,
    },
    body: JSON.stringify({
      metadata: {'@private': {email: email, contactNumber: phone}},
      uid: uid,
      name: name,
    }),
  }

  return fetch(baseUrl + 'users', options)
    .then((response) => response.json())
    .then((response) => {
      return {result: 'success', data: response.data}
    })
    .catch((err) => {
      return {result: 'error', data: err.response}
    })
}

export const getChatUser = async (uid: string) => {
  const options = {
    method: 'GET',
    headers: {accept: 'application/json', apikey: 'f2933a6200901babaaa9fe75768b89abb051a892'},
  }

  return fetch(baseUrl + 'users/' + uid, options)
    .then((response) => response.json())
    .then((response) => {
      if (response.data != undefined) {
        return {result: 'success', data: response.data}
      } else {
        return {result: 'error', data: 'Not found'}
      }
    })
    .catch((err) => {
      return {result: 'error', data: err.response}
    })
}

export const getConversationWithUser = async (sender: string, receiver: string) => {
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      onBehalfOf: sender,
      apikey: apiKey,
    },
  }

  return fetch(baseUrl + 'users/' + receiver + '/conversation', options)
    .then((response) => response.json())
    .then((response) => {
      if (response.data != undefined) {
        return {result: 'success', data: response.data}
      } else {
        return {result: 'error', data: 'Not found'}
      }
    })
    .catch((err) => {
      return {result: 'error', data: err.response}
    })
}

export const sendMessageToUser = async (sender: string, receiver: string, message: string) => {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      onBehalfOf: sender,
      'content-type': 'application/json',
      apikey: apiKey,
    },
    body: JSON.stringify({
      category: 'message',
      type: 'text',
      data: {text: message},
      receiver: receiver,
      receiverType: 'user',
    }),
  }

  return fetch(baseUrl + 'messages', options)
    .then((response) => response.json())
    .then((response) => {
      if (response.data != undefined) {
        return {result: 'success', data: response.data}
      } else {
        return {result: 'error', data: 'Not found'}
      }
    })
    .catch((err) => {
      return {result: 'error', data: err.response}
    })
}

export const getConversationMessages = async (conversationId: string, sender: string) => {
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      onBehalfOf: sender,
      apikey: apiKey,
    },
  }

  return fetch(baseUrl + 'messages?limit=100&conversationId=' + conversationId, options)
    .then((response) => response.json())
    .then((response) => {
      if (response.data != undefined) {
        return {result: 'success', data: response.data}
      } else {
        return {result: 'error', data: 'Not found'}
      }
    })
    .catch((err) => {
      return {result: 'error', data: err.response}
    })
}
