import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import AddChecklist from '../../../components/PropertyAddManuallyWizard/StepTabs/HostChecklists/AddChecklist'
import {encryptText} from '../../../utils/util'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {DataGrid} from 'devextreme-react'
import {Export} from 'devextreme-react/bar-gauge'
import {
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Paging,
  Summary,
  TotalItem,
  Column,
  Selection,
} from 'devextreme-react/data-grid'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import {useDialog} from '../../../contexts/DialogContext'

type Props = {
  isVisible: boolean
  onClose: () => void
  propertyId: number
}

const NoChecklistWarnDialog: React.FC<Props> = ({isVisible, onClose, propertyId}) => {
  const history = useHistory()
  const {showFailureDialog} = useDialog()
  const {data: masterChecklistData} = useQuery(
    ['Get Admin Master Checklist', propertyId],
    () => ApiCalls.getAdminMasterChecklists(),
    {refetchOnWindowFocus: false, enabled: true, cacheTime: 100000}
  )

  const [loading, setloading] = useState(false)
  const [showWarn, setShowWarn] = useState(false)
  const [selectChecklist, setselectChecklist] = useState<IHostPropertyCheckList[]>([])
  const calculateChecklistName = (cellValue: any) => {
    return cellValue?.name?.en
      ? cellValue?.name?.en
      : cellValue?.name[Object.keys(cellValue?.name)[0]]
  }
  const onSelectionChanged = (cellValue: any) => {
    setselectChecklist(cellValue.selectedRowsData.map((element: any) => element))
  }

  const goToChecklistPage = () => {
    const enc = encryptText(propertyId)
    history.push('/checklists/' + enc)
  }

  const injectClone = async () => {
    if (selectChecklist.length > 0) {
      setShowWarn(false)
      setloading(true)
      for (let index = 0; index < selectChecklist.length; index++) {
        let selectChecklistId = selectChecklist?.[index]
        try {
          await ApiCalls.postCloneCheckLists({
            hostPropertyIds: [Number(propertyId)],
            hostPropertyChecklistId: selectChecklistId.id,
          })
        } catch (error: any) {
          setloading(false)
          showFailureDialog(error?.response.data.message)
        }
      }
      setloading(false)
      onClose()
    } else {
      setShowWarn(true)
    }
  }

  return (
    <>
      <Modal show={isVisible} onHide={onClose} size='xl' centered>
        <ModalHeader closeButton>
          <Modal.Title>Sorry!</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <p className='mb-4'>
            <strong>
              There is no checklist for your property. In order to receive a quote, you must create
              a checklist.
            </strong>
          </p>
          <p className='text-danger'>
            <strong>
              Note: You can quickly add one of the ready-made checklists that Turnify has prepared
              for you from the list below to your property.
            </strong>
          </p>
          <DataGrid
            id='checklistTable'
            keyExpr='id'
            onRowPrepared={(e) => {
              e.rowElement.classList.add('data-grid-row')
            }}
            dataSource={masterChecklistData?.data}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            hoverStateEnabled={true}
            height={'calc(55vh - 60px)'}
            allowColumnResizing={true}
            onSelectionChanged={onSelectionChanged}
          >
            <Export enabled />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Selection mode='multiple' showCheckBoxesMode='always' />
            <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
            <Paging defaultPageSize={50} />
            <Summary>
              <TotalItem
                cssClass='absolute-right'
                displayFormat='Total Checklists: {0}'
                column='name'
                summaryType='count'
              />
            </Summary>

            <Column
              dataField='name'
              calculateCellValue={calculateChecklistName}
              caption='Checklist Name'
              minWidth={150}
              cssClass='cls'
            />
            <Column dataField='roleName' caption='Role' minWidth={150} cssClass='cls' />
            <Column dataField='serviceTypeName' caption='Service' minWidth={150} cssClass='cls' />
          </DataGrid>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <button className='btn btn-sm btn-info me-3' onClick={goToChecklistPage}>
            + Add New Checklist
          </button>
          <div>
            {showWarn && <span className='text-danger me-4'>You have to select a checklist!</span>}
            <button
              disabled={loading}
              className='btn btn-sm btn-success me-3'
              onClick={injectClone}
            >
              Save Checklist
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NoChecklistWarnDialog
