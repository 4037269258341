import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {
  IHostPropertyAutomation,
  IHostPropertyICal,
  IHostPropertyInput,
  IHostPropertyManualInput,
  ILocation,
} from '../../../../interfaces/Property'
import ApiCalls from '../../../../network/ApiCalls'
import {Button, Spinner} from 'react-bootstrap-v5'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {decryptText, getLocationByAddress, getStaticMap} from '../../../../utils/util'
import ArrowButton from '../../../../components/Buttons/ArrowButton'
import DeleteButton from '../../../../components/Buttons/DeleteButton'
import AutocompleteMap from '../../../../components/Inputs/AutocompleteMap'
import SwitchBox from '../../../../components/Inputs/SwitchBox'
import {TextInput} from '../../../../components/Inputs/TextInput'
import TimePicker from '../../../../components/Inputs/TimePicker'
import Map from '../../../../components/Map/GoogleMap'
import './custom.css'
import {IStaffAddress} from '../../../../interfaces/Address'
import SelectInput from '../../../../umut-components/Inputs/SelectInput'
const debounce = require('debounce')

interface IDetail {
  numberOfBedrooms: number
  numberOfBathrooms: number
  cleaningProvider: number
  propertySize: number
}

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyManualInput) => void
  goToStep: (key: any) => void
  missingField?: boolean
}

const providers = [{value: 1, label: 'My Team'}]

const PropertyInfoStep: React.FC<Props> = ({missingField, handleChangeHostProperty, goToStep}) => {
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({})
  const [placeId, setPlaceId] = useState<string | undefined>(undefined)
  const [text, setText] = useState<string>('')
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)
  const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
  const [hasSizeError, setHasSizeError] = useState<boolean>(false)
  const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(1)
  const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(1)
  const [selectedValues, setSelectedValues] = useState<IDetail>({
    numberOfBedrooms: 1,
    numberOfBathrooms: 1,
    cleaningProvider: 1,
    propertySize: 0,
  })

  const onMarkerChange = (changedLocation: ILocation) => {
    var city = changedLocation.city
    if (changedLocation.country == 'Turkey') {
      if (city == '') {
        city = changedLocation.state
      }
    }

    setText(changedLocation.address ? changedLocation.address : '')
    setSelectedLocation({...changedLocation, suite: selectedLocation.suite, city: city})
  }

  const onSuiteChange = (e: any) => {
    setSelectedLocation({...selectedLocation, suite: e.target.value})
  }

  const handleSelectPlaceId = (e: string) => {
    // setSelectedLocation({ ...selectedLocation, address: e });
    setPlaceId(e)
  }

  useEffect(() => {
    getLocationByAddress(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  const onNextStepClick = () => {
    if (
      !selectedLocation.address ||
      !selectedLocation.zipCode ||
      !selectedLocation.latitude ||
      !selectedLocation.longitude ||
      !selectedLocation.country ||
      !selectedLocation.state ||
      !selectedLocation.city ||
      selectedValues.cleaningProvider == 0 ||
      selectedValues.propertySize <= 0
    ) {
      setHasMissingField(true)
    } else {
      setHasMissingField(false)
      handleChangeHostProperty({
        address: selectedLocation.address,
        city: selectedLocation.city,
        state: selectedLocation.state,
        suite: selectedLocation.suite,
        country: selectedLocation.country,
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude,
        zipCode: selectedLocation.zipCode,
        numberOfBathrooms: selectedValues.numberOfBathrooms,
        numberOfBedrooms: selectedValues.numberOfBedrooms,
        cleaningProviderId: selectedValues.cleaningProvider,
        propertySize: selectedValues.propertySize,
      })
      goToStep(2)
    }
  }

  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }

  const providerChange = (d: any) => {
    setSelectedValues({...selectedValues, cleaningProvider: d})
  }

  useEffect(() => {
    var selected = selectedLocation
    selected.city = fullLocation?.cityName
    setSelectedLocation(selected)
    handleChangeHostProperty({
      address: fullLocation?.address,
      city: fullLocation?.cityName,
      state: fullLocation?.stateName,
      country: fullLocation?.countryName,
      latitude: fullLocation?.latitude,
      longitude: fullLocation?.longitude,
      zipCode: fullLocation?.zipCode,
    })
  }, [fullLocation])

  return (
    <>
      <div className='form w-100 mt-5'>
        <div className='row'>
          {/* begin::Form group */}
          <div className='fv-row col-lg-4'>
            <Map
              selectedAddress={selectedLocation.address}
              placeId={placeId}
              initialLocation={location}
              onLatLngChanged={onMarkerChange}
              initialAddress={null}
              draggable={true}
            />
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row col-lg-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Address {<span style={{color: 'red'}}>*</span>}
            </label>
            <AutocompleteMap
              disabled={false}
              text={text}
              setText={setText}
              handleSelectPlaceId={handleSelectPlaceId}
              handleOnError={handleAutocompleteError}
            />
            {(missingField || hasMissingField) && !selectedLocation.address && (
              <>
                <strong className='text-danger'>Missing Field</strong>
                <br />
              </>
            )}

            <label className='form-label fs-6 fw-bolder text-dark mt-3'>Suite/Apt # </label>
            <input
              value={selectedLocation.suite}
              onChange={onSuiteChange}
              className='form-control'
              type='text'
              autoComplete='off'
              required
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>
              Country {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              value={selectedLocation.country}
              className='form-control'
              type='text'
              autoComplete='off'
              disabled
              required
            />

            {(missingField || hasMissingField) && !selectedLocation.country && (
              <strong className='text-danger'>Missing Field</strong>
            )}
            <div className='row mt-5'>
              <div className='fv-row col-lg-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  State {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={fullLocation?.stateName}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />
                {(missingField || hasMissingField) && !selectedLocation.state && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
              </div>
              <div className='fv-row col-lg-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  City {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={selectedLocation.city}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />
                {(missingField || hasMissingField) && !selectedLocation.city && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
              </div>
            </div>
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>
              Zip Code {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              value={selectedLocation.zipCode}
              className='form-control'
              type='text'
              autoComplete='off'
              disabled
              required
            />
            {(missingField || hasMissingField) && !selectedLocation.zipCode && (
              <strong className='text-danger'>Missing Field</strong>
            )}
          </div>
        </div>
        <div className='row mt-10'>
          <div className='col-lg-12 mb-5'>
            <label className='fw-bolder text-dark fs-6 mb-3'>
              Cleaning Provider {<span style={{color: 'red'}}>*</span>}
            </label>
            <SelectInput
              options={providers}
              onValueChanged={providerChange}
              defaultValue={selectedValues?.cleaningProvider}
            />
            {(missingField || hasMissingField) && selectedValues.cleaningProvider == 0 && (
              <>
                <strong className='text-danger'>Please select</strong>
                <br />
              </>
            )}
          </div>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{width: 200}}>
                  Number of Bedrooms {<span style={{color: 'red'}}>*</span>}
                </label>
                <div className='d-flex align-items-center'>
                  <button
                    type='button'
                    className='btn btn-dark btn-sm me-2'
                    onClick={() => {
                      if (selectedNumberOfBedRooms > 1) {
                        setSelectedNumberOfBedRooms(selectedNumberOfBedRooms - 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBedrooms: selectedNumberOfBedRooms - 1,
                        })
                      } else if (selectedNumberOfBedRooms === 1) {
                        setSelectedNumberOfBedRooms(selectedNumberOfBedRooms - 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBedrooms: selectedNumberOfBedRooms - 1,
                        })
                      }
                    }}
                  >
                    -
                  </button>
                  <div style={{width: 50, textAlign: 'center'}}>
                    <strong>
                      {selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}
                    </strong>
                  </div>
                  <button
                    type='button'
                    className='btn btn-dark btn-sm ms-2'
                    onClick={() => {
                      if (selectedNumberOfBedRooms === 0) {
                        setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBedrooms: selectedNumberOfBedRooms + 1,
                        })
                      } else {
                        setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBedrooms: selectedNumberOfBedRooms + 1,
                        })
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className='col-lg-6 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{width: 200}}>
                  Number of Bathrooms {<span style={{color: 'red'}}>*</span>}
                </label>
                <div className='d-flex align-items-center'>
                  <button
                    type='button'
                    className='btn btn-dark btn-sm me-2'
                    onClick={() => {
                      if (selectedNumberOfBathRooms > 1) {
                        setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 0.5)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBathrooms: selectedNumberOfBathRooms - 0.5,
                        })
                      } else if (selectedNumberOfBathRooms === 1) {
                        setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBathrooms: selectedNumberOfBathRooms - 1,
                        })
                      }
                    }}
                  >
                    -
                  </button>
                  <div style={{width: 50, textAlign: 'center'}}>
                    <strong>{selectedNumberOfBathRooms}</strong>
                  </div>
                  <button
                    type='button'
                    className='btn btn-dark btn-sm ms-2'
                    onClick={() => {
                      if (selectedNumberOfBathRooms === 0) {
                        setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 1)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBathrooms: selectedNumberOfBathRooms + 1,
                        })
                      } else {
                        setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 0.5)
                        setSelectedValues({
                          ...selectedValues,
                          numberOfBathrooms: selectedNumberOfBathRooms + 0.5,
                        })
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <label className='fw-bolder text-dark fs-6 mb-3'>
              Property Size (sqft.) {<span style={{color: 'red'}}>*</span>}
            </label>
            <TextInput
              onValueChange={(text) => {
                setHasSizeError(false)
                setSelectedValues({...selectedValues, propertySize: +text})
              }}
              type='number'
              defaultValue={selectedValues.propertySize || 0}
              placeholder='Type here'
              classNames={hasSizeError ? 'error-select' : ''}
            />
            {(missingField || hasMissingField) && selectedValues.propertySize < 150 && (
              <>
                <strong className='text-danger'>Size below the minimum limit</strong>
                <br />
              </>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-end flex-row w-100 mt-6'>
          <ArrowButton type='forward' func={onNextStepClick} classNames='align-self-end' />
        </div>
      </div>
    </>
  )
}

export default PropertyInfoStep
