import {IProTeam, IProTeamDetail} from '../../interfaces/ProTeam'

export const GET_PROTEAM_LIST = 'GET_PROTEAM_LIST'
export interface GetProTeamList {
  type: typeof GET_PROTEAM_LIST
  hostId: number
  roleId: number | undefined
}

export const GET_PROTEAM_LIST_SUCCESS = 'GET_PROTEAM_LIST_SUCCESS'
export interface GetProTeamListSuccess {
  type: typeof GET_PROTEAM_LIST_SUCCESS
  proTeamList: IProTeam[]
}

export const GET_PROTEAM_LIST_FAILURE = 'GET_PROTEAM_LIST_FAILURE'
export interface GetProTeamListFailure {
  type: typeof GET_PROTEAM_LIST_FAILURE
  errorText: string
}

export const GET_PROTEAM_DETAIL = 'GET_PROTEAM_DETAIL'
export interface GetProTeamDetail {
  type: typeof GET_PROTEAM_DETAIL
  userId: number
}

export const GET_PROTEAM_DETAIL_SUCCESS = 'GET_PROTEAM_DETAIL_SUCCESS'
export interface GetProTeamDetailSuccess {
  type: typeof GET_PROTEAM_DETAIL_SUCCESS
  proTeam: IProTeamDetail
}

export const GET_PROTEAM_DETAIL_FAILURE = 'GET_PROTEAM_DETAIL_FAILURE'
export interface GetProTeamDetailFailure {
  type: typeof GET_PROTEAM_DETAIL_FAILURE
  errorText: string
}

export type ProTeamAction =
  | GetProTeamList
  | GetProTeamListSuccess
  | GetProTeamListFailure
  | GetProTeamDetail
  | GetProTeamDetailSuccess
  | GetProTeamDetailFailure
