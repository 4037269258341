import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';

type Props = {
    emptyText?: string;
}

const EmptyData: React.FC<Props> = ({ emptyText }) => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <img style={{ height: 250, objectFit: 'contain' }} alt='empty-data' src={toAbsoluteUrl('/media/icons/emptyData.png')} />
            <strong className='text-muted'>{emptyText ? emptyText : 'No Items Selected'}</strong>
        </div>
    )
}

export default EmptyData;