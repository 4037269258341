import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {IStaff, IStaffAssignedProperty} from '../../../../../interfaces/Staff'
import ApiCalls from '../../../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {useDialog} from '../../../../../contexts/DialogContext'
import {DeleteActions} from '../../../../../redux/actionTypes/deleteDialogTypes'
import * as deleteActionCreator from '../../../../../redux/actionCreators/deleteDialogActionCreators'
import * as staffActionCreator from '../../../../../redux/actionCreators/staffActionCreators'
import {useDispatch} from 'react-redux'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {StaffAction} from '../../../../../redux/actionTypes/staffActionTypes'

type Props = {
  staff: IStaff
}

const ExcludedProperties: React.FC<Props> = ({staff}) => {
  const dispatch = useDispatch<Dispatch<DeleteActions>>()
  const addDispatch = useDispatch<Dispatch<StaffAction>>()
  const {showFailureDialog} = useDialog()
  const [properties, setProperties] = useState<IStaffAssignedProperty[]>([])
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const {
    data: propertyData,
    isLoading: propertyLoading,
    error: propertyError,
    refetch,
  } = useQuery(
    ['Excluded Properties', staff?.userId],
    () => ApiCalls.getBannedProperties(staff?.userId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: true,
      enabled: staff?.userId ? true : false,
    }
  )

  useEffect(() => {
    if (!propertyLoading) {
      if (propertyData?.data != undefined) {
        const props = []
        for (const pd of propertyData?.data?.data) {
          props.push(pd)
        }
        setProperties(props)
      }
    }
  }, [propertyLoading, propertyData])

  const clickAdd = () => {
    addDispatch(staffActionCreator.openStaffPropertyDialog(refetch, 'banned'))
  }

  const deleteExcludedProperty = (propertyId: number, propertyName: string) => {
    dispatch(
      deleteActionCreator.openDeleteDialog(
        async () => {
          try {
            await ApiCalls.deletePropertyPermission(propertyId)
            refetch()
            dispatch(deleteActionCreator.closeDeleteDialog())
          } catch (err: any) {
            showFailureDialog(err?.response?.data?.message)
          }
        },
        `Do you want to remove excluded property ${propertyName} from staff ${
          staff?.firstName + ' ' + staff?.lastName
        } ?`,
        'Do you want to remove property?'
      )
    )
  }

  return (
    <div className='card pt-4 mb-6 mb-xl-9'>
      <div className='card-header border-0 px-1 py-1'>
        <div className='card-title'>
          <h2 className='fw-bold mb-0'>Unauthorized Properties</h2>
        </div>
        <div className='card-toolbar'>
          <button className='btn btn-sm btn-flex btn-light-danger' onClick={clickAdd}>
            Unauthorize Staff
          </button>
        </div>
      </div>
      <div className='card-body px-1 py-1'>
        {!propertyLoading && properties.length > 0 && (
          <div className='table-responsive pb-10 px-1'>
            <table className='table align-middle table-row-dashed fs-6 fw-bold gy-5 dataTable no-footer'>
              <thead className='border-bottom border-gray-200 fs-7 text-uppercase fw-bold'>
                <tr className='text-start text-muted gs-0'>
                  <th className='min-w-250px sorting'>Property</th>
                  <th className='min-w-50px sorting'>Suite</th>
                  <th className='min-w-150px sorting'>City</th>
                  <th className='min-w-50px sorting'></th>
                </tr>
              </thead>
              <tbody className='fs-6 fw-semibold text-gray-600'>
                {properties.map((property, index) => {
                  return (
                    <tr
                      className={index % 2 == 0 ? 'odd' : 'even'}
                      key={'assigned-prop-row-' + index}
                    >
                      <td className='sorting_1'>
                        <div className='text-gray-600 text-hover-primary'>{property.name}</div>
                      </td>
                      <td>{property.suite ? property.suite : '-'}</td>
                      <td>{property.cityName}</td>
                      <td className='text-end position-relative'>
                        <button
                          className='btn btn-sm btn-icon btn-bg-light btn-color-primary dropmenu'
                          onClick={() => {
                            if (selectedDropIndx == 'assigned-prop-action-' + property.id) {
                              setSelectedDropIndx(undefined)
                            } else {
                              setSelectedDropIndx('assigned-prop-action-' + property.id)
                            }
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/General/Other2.svg'
                            className='svg-icon-3 svg-icon-secondary dropmenu'
                            svgClassName='dropmenu mh-50px'
                          />
                        </button>
                        <div
                          className={
                            selectedDropIndx == 'assigned-prop-action-' + property.id
                              ? 'menu menu-sub menu-sub-dropdown align-items-end w-100px py-1 show'
                              : 'menu menu-sub menu-sub-dropdown align-items-end w-100px py-1'
                          }
                          style={{top: 60, position: 'absolute', right: 0}}
                        >
                          <div className='menu-item px-3'>
                            <div
                              className='menu-link px-3 text-danger'
                              onClick={() => {
                                deleteExcludedProperty(property.id, property.name)
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {!propertyLoading && properties.length == 0 && (
          <>
            <div className='ms-4 text-center'>
              <div className='fs-2 fw-bold text-gray-900 text-hover-primary mb-2'>Not Found</div>
              <div className='fw-semibold fs-4 text-muted'>
                Property where the staff was unauthorized was not found.
              </div>
            </div>
          </>
        )}
        {propertyLoading && <SkeletonTableLoading />}
      </div>
    </div>
  )
}

export default ExcludedProperties
