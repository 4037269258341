import { IJob, IJobChecklistPhoto, IJobDetail, IJobIssue, IJobTask, JobTabNames } from "../../interfaces/Job";
import { IStaffOfferedService } from "../../interfaces/Staff";
import { ManualJobRequest } from "../../network/PostRequestModels/JobRequests";

export const OPEN_JOB_DIALOG = "OPEN_JOB_DIALOG";
export interface OpenJobDialog {
    type: typeof OPEN_JOB_DIALOG,
    propertyId?: number
}

export const CLOSE_JOB_DIALOG = "CLOSE_JOB_DIALOG";
export interface CloseJobDialog {
    type: typeof CLOSE_JOB_DIALOG,
}

export const SET_JOB_FORM = "SET_JOB_FORM";
export interface SetJobForm {
    type: typeof SET_JOB_FORM,
    jobForm: IStaffOfferedService | null
}

export const OPEN_SERVICE_ADD_DIALOG = "OPEN_SERVICE_ADD_DIALOG";
export interface OpenServiceAddDialog {
    type: typeof OPEN_SERVICE_ADD_DIALOG,
    services: [],
    refetch: () => void
}

export const CLOSE_SERVICE_ADD_DIALOG = "CLOSE_SERVICE_ADD_DIALOG";
export interface CloseServiceAddDialog {
    type: typeof CLOSE_SERVICE_ADD_DIALOG,
}

export const OPEN_TASK_DIALOG = "OPEN_TASK_DIALOG";
export interface OpenTaskDialog {
    type: typeof OPEN_TASK_DIALOG,
    refetch: () => void,
    taskData?: IJobTask
}

export const CLOSE_TASK_DIALOG = "CLOSE_TASK_DIALOG";
export interface CloseTaskDialog {
    type: typeof CLOSE_TASK_DIALOG,
}

export const OPEN_ISSUE_MEDIA_DIALOG = "OPEN_ISSUE_MEDIA_DIALOG";
export interface OpenIssueMediaDialog {
    type: typeof OPEN_ISSUE_MEDIA_DIALOG,
    issue: IJobIssue
}

export const CLOSE_ISSUE_MEDIA_DIALOG = "CLOSE_ISSUE_MEDIA_DIALOG";
export interface CloseIssueMediaDialog {
    type: typeof CLOSE_ISSUE_MEDIA_DIALOG,
}

export const OPEN_CHECKLIST_PHOTO_DIALOG = "OPEN_CHECKLIST_PHOTO_DIALOG";
export interface OpenChecklistPhotoDialog {
    type: typeof OPEN_CHECKLIST_PHOTO_DIALOG,
    checklistPhoto: IJobChecklistPhoto
}

export const CLOSE_CHECKLIST_PHOTO_DIALOG = "CLOSE_CHECKLIST_PHOTO_DIALOG";
export interface CloseChecklistPhotoDialog {
    type: typeof CLOSE_CHECKLIST_PHOTO_DIALOG,
}

export const OPEN_REVIEW_DIALOG = "OPEN_REVIEW_DIALOG";
export interface OpenReviewDialog {
    type: typeof OPEN_REVIEW_DIALOG,
    refetch: () => void,
    serviceTypeId: number
}

export const CLOSE_REVIEW_DIALOG = "CLOSE_REVIEW_DIALOG";
export interface CloseReviewDialog {
    type: typeof CLOSE_REVIEW_DIALOG,
}

export const OPEN_CANCEL_JOB_DIALOG = "OPEN_CANCEL_JOB_DIALOG";
export interface OpenCancelJobDialog {
    type: typeof OPEN_CANCEL_JOB_DIALOG,
    refetch: () => void,
    job: IJob
}

export const CLOSE_CANCEL_JOB_DIALOG = "CLOSE_CANCEL_JOB_DIALOG";
export interface CloseCancelJobDialog {
    type: typeof CLOSE_CANCEL_JOB_DIALOG,
}

export const SET_JOB_DETAIL = "SET_JOB_DETAIL";
export interface SetJobDetail {
    type: typeof SET_JOB_DETAIL,
    jobDetail: IJobDetail | null
}

export const SET_SELECTED_JOB_TAB = "SET_SELECTED_JOB_TAB";
export interface SetSelectedJobTab {
    type: typeof SET_SELECTED_JOB_TAB,
    selectedJobTab: JobTabNames
}


export type JobAction =
    | OpenJobDialog
    | CloseJobDialog
    | SetJobForm
    | OpenServiceAddDialog
    | CloseServiceAddDialog
    | OpenTaskDialog
    | CloseTaskDialog
    | OpenIssueMediaDialog
    | CloseIssueMediaDialog
    | OpenChecklistPhotoDialog
    | CloseChecklistPhotoDialog
    | OpenReviewDialog
    | CloseReviewDialog
    | OpenCancelJobDialog
    | CloseCancelJobDialog
    | SetJobDetail
    | SetSelectedJobTab