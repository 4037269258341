import React, {Dispatch, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ProfileEditDialog from './ProfileEditDialog'
import AddressTab from './Address/AddressTab'
import AddressEditDialog from './Address/AddressEditDialog'
import DeleteDialog from '../../umut-components/Modals/DeleteDialog'
import * as actions from '../../redux/actionCreators/userActionCreators'
import {AccountStatusIcon} from '../../umut-components/Icons/AccountStatus'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import SkeletonCircleLoading from '../../umut-components/Loading/SkeletonCircleLoading'
import ProfileChangePassword from './ProfileChangePassword'
import ChangePasswordButton from '../../umut-components/Buttons/ChangePasswordButton'
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp'
import ProfileDeleteDialog from './ProfileDeleteDialog'
import {UserAction} from '../../redux/actionTypes/userActionTypes'
import {useDialog} from '../../contexts/DialogContext'
import FeedSettings from './FeedSettings/FeedSettings'

const Profile: React.FC = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [status, setStatus] = useState('Pending')
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const {showFailureDialog} = useDialog()
  const dispatch = useDispatch<Dispatch<UserAction>>()

  const {data, isLoading, error} = useQuery(
    ['Get Status Type'],
    () => ApiCalls.getAccountStatusTypes(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
  }
  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const deleteAccount = async () => {
    try {
      await ApiCalls.deleteUserAccount(user.user.id)
      dispatch(actions.logout())
    } catch (error: any) {
      showFailureDialog('An error occur during deleting your account!')
    }
  }

  useEffect(() => {
    setStatus(data?.data?.data[user?.userSection?.accountStatusTypeId - 1].name)
  }, [data])

  return (
    <>
      <PageTitle>My Profile</PageTitle>
      <div className='card mb-5 mb-xl-12 p-3'>
        <Tabs defaultActiveKey='profile'>
          <Tab eventKey='profile' title='Profile'>
            {!isLoading ? (
              <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                  <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-200px symbol-fixed position-relative'>
                      <ProfilePhotoTemp
                        fontSize={48}
                        width={200}
                        height={200}
                        borderRadius={'24'}
                        firstName={user?.user?.firstName}
                        lastName={user?.user?.lastName}
                        profilePhotoPath={user?.user?.profilePhotoPath}
                      />
                      <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                    </div>
                  </div>

                  <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex flex-column col-6'>
                        <div className='d-flex align-items-center mb-2'>
                          <a
                            href='#'
                            className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                          >
                            {user?.user?.firstName}&nbsp;{user?.user?.lastName}
                          </a>
                          <a href='#'>
                            <span className='svg-icon svg-icon-1 svg-icon-primary'>
                              <AccountStatusIcon status={status} />
                            </span>
                          </a>
                        </div>
                        <div className='d-flex flex-wrap gap-3 fw-bold fs-6 mb-4 pe-2'>
                          <a
                            href='#'
                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                          >
                            <span className='svg-icon svg-icon-4 me-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mh-50px'
                              >
                                <path
                                  opacity='0.3'
                                  d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z'
                                  fill='black'
                                ></path>
                                <path
                                  d='M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z'
                                  fill='black'
                                ></path>
                              </svg>
                            </span>
                            {user?.role}
                          </a>
                          <a
                            href={`tel:${user?.user?.phoneNumber}`}
                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                          >
                            <span className='svg-icon svg-icon-4 me-1'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                className='bi bi-telephone-fill'
                                viewBox='0 0 16 16'
                              >
                                <path
                                  fill-rule='evenodd'
                                  d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                                />
                              </svg>
                            </span>
                            {user?.user?.phoneNumber}
                          </a>
                          <a
                            href={`mailto:${user?.user?.email}`}
                            className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                          >
                            <span className='svg-icon svg-icon-4 me-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mh-50px'
                              >
                                <path
                                  opacity='0.3'
                                  d='M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z'
                                  fill='black'
                                ></path>
                                <path
                                  d='M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z'
                                  fill='black'
                                ></path>
                              </svg>
                            </span>
                            {user?.user?.email}
                          </a>
                        </div>
                      </div>
                      <div className='d-flex my-4 gap-2'>
                        <ChangePasswordButton
                          classNames='me-3'
                          changePassFn={() => {
                            setPasswordDialogOpen(true)
                          }}
                        />
                        <button
                          className='btn btn-sm btn-primary me-3'
                          onClick={() => setEditDialogOpen(true)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className='d-flex '>
                      <button
                        className='btn btn-sm btn-danger'
                        onClick={() => {
                          setDeleteDialogOpen(true)
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <span className='svg-icon svg-icon-2x svg-icon-danger me-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                              version='1.1'
                            >
                              <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                <rect x='0' y='0' width='24' height='24' />
                                <path
                                  d='M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z'
                                  fill='#fff'
                                  opacity='0.3'
                                />
                                <rect fill='#fff' x='11' y='9' width='2' height='7' rx='1' />
                                <rect fill='#fff' x='11' y='17' width='2' height='2' rx='1' />
                              </g>
                            </svg>
                          </span>
                          <div className='fs-5 fw-bolder'>Delete Account</div>
                        </div>
                        <div className='fw-bold fs-7 text-gray-400 text-center'></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='mt-5'>
                <SkeletonCircleLoading />
              </div>
            )}
          </Tab>
          <Tab eventKey='addresses' title='Addresses'>
            <AddressTab />
          </Tab>
          <Tab eventKey='feedSettings' title='Notification Settings'>
            <FeedSettings />
          </Tab>
        </Tabs>
      </div>
      <ProfileEditDialog modalOpen={editDialogOpen} handleClose={handleCloseEditDialog} />
      <ProfileChangePassword
        modalOpen={passwordDialogOpen}
        close={handleClosePasswordDialog}
        userId={user?.user?.id}
      />
      <AddressEditDialog />
      <DeleteDialog />
      <ProfileDeleteDialog
        modalOpen={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleDelete={deleteAccount}
      />
    </>
  )
}

export default Profile
