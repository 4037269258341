/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Register} from './components/Register'
import ForgotPassword from '../auth/components/ForgotPassword'
import Policy from './components/Policy'
import {Carousel} from 'bootstrap'
import SignUp from './components/SignUp'

export function AuthPage() {
  const history = useHistory()
  const [pathName, setPathName] = useState(history.location.pathname)
  const [activeSlide, setActiveSlide] = useState<number>(1)
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    setPathName(history.location.pathname)
  }, [history.location])

  const carouselRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const element = carouselRef.current
    if (!element) {
      return
    }

    const carousel = new Carousel(element)
    return function cleanUp() {
      carousel.dispose()
    }
  }, [])

  useEffect(() => {
    setInterval(() => {
      if (activeSlide + 1 < 6) {
        setActiveSlide(activeSlide + 1)
      } else {
        setActiveSlide(1)
      }
    }, 5000)
  }, [])

  return (
    <>
      {/* <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/progress-hd.png')})`,
        }}
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <a href='#' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/turnify-logo.png')}
              className='h-45px'
            />
          </a>
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Switch>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/register' component={Register} />
              <Route path='/policy' component={Policy} />
              <Route exact path='/auth/forgot-password' component={ForgotPassword} />
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
        </div>
      </div> */}
      {pathName != '/auth/signup' && (
        <div
          className='d-flex flex-column flex-lg-row flex-column-fluid'
          style={{backgroundImage: `url('/media/auth/bg10.jpeg')`}}
        >
          <div className='d-flex flex-lg-row-fluid'>
            <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
              <div
                className='carousel carousel-custom slide'
                data-bs-ride='carousel'
                data-bs-interval='8000'
                ref={carouselRef}
              >
                <div className='carousel-inner pt-9'>
                  <div className={activeSlide == 1 ? 'carousel-item active' : 'carousel-item'}>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
                      <img
                        src='/media/auth/authbg-1.jpeg'
                        className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                      />
                      <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7 mw-100 w-300px w-lg-400px'>
                        Simplify Property Management
                      </h1>
                      <div className='text-gray-600 fs-base text-center fw-semibold mw-100 w-300px w-lg-400px'>
                        Turnify streamlines property management tasks, making it easier for property
                        owners, service providers, and staff to collaborate and deliver exceptional
                        service.
                      </div>
                    </div>
                  </div>
                  <div className={activeSlide == 2 ? 'carousel-item active' : 'carousel-item'}>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
                      <img
                        src='/media/auth/authbg-2.jpeg'
                        className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                      />
                      <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7 mw-100 w-300px w-lg-400px'>
                        Trusted and Secure
                      </h1>
                      <div className='text-gray-600 fs-base text-center fw-semibold mw-100 w-300px w-lg-400px'>
                        Your safety and security are our top priorities. Turnify provides a trusted
                        and secure environment for property-related services, with verified users
                        and secure payment processing.
                      </div>
                    </div>
                  </div>
                  <div className={activeSlide == 3 ? 'carousel-item active' : 'carousel-item'}>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
                      <img
                        src='/media/auth/authbg-3.jpeg'
                        className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                      />
                      <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7 mw-100 w-300px w-lg-400px'>
                        Efficient Communication
                      </h1>
                      <div className='text-gray-600 fs-base text-center fw-semibold mw-100 w-300px w-lg-400px'>
                        Effective communication is key. Turnify enhances communication among
                        property owners, service providers, and staff, ensuring smooth and
                        hassle-free service delivery.
                      </div>
                    </div>
                  </div>
                  <div className={activeSlide == 4 ? 'carousel-item active' : 'carousel-item'}>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
                      <img
                        src='/media/auth/authbg-4.jpeg'
                        className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                      />
                      <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7 mw-100 w-300px w-lg-400px'>
                        Streamlined Bookings and Payments
                      </h1>
                      <div className='text-gray-600 fs-base text-center fw-semibold mw-100 w-300px w-lg-400px'>
                        Manage your bookings and payments in one place. Turnify simplifies the
                        process, helping you save time and effort.
                      </div>
                    </div>
                  </div>
                  <div className={activeSlide == 5 ? 'carousel-item active' : 'carousel-item'}>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-3 w-100'>
                      <img
                        src='/media/auth/authbg-5.jpeg'
                        className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                      />
                      <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7 mw-100 w-300px w-lg-400px'>
                        Tailored Solutions
                      </h1>
                      <div className='text-gray-600 fs-base text-center fw-semibold mw-100 w-300px w-lg-400px'>
                        Turnify offers customized solutions for property management, allowing you to
                        select the services that meet your specific needs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12'>
            <div className='bg-body d-flex flex-column flex-center rounded-3 w-md-600px p-10'>
              <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
                <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20'>
                  <Switch>
                    <Route path='/auth/login' component={Login} />
                    {/* <Route path='/auth/register' component={Register} /> */}
                    <Route path='/auth/signup' component={SignUp} />
                    <Route path='/policy' component={Policy} />
                    <Route exact path='/auth/forgot-password' component={ForgotPassword} />
                    <Redirect from='/auth' exact={true} to='/auth/login' />
                    <Redirect to='/auth/login' />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pathName == '/auth/signup' && (
        <div>
          <Switch>
            <Route path='/auth/login' component={Login} />
            {/* <Route path='/auth/register' component={Register} /> */}
            <Route path='/auth/signup' component={SignUp} />
            <Route path='/policy' component={Policy} />
            <Route exact path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
      )}
    </>
  )
}
