import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { decryptText, convertUTCDateToLocalDate, onExporting } from '../../../utils/util';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useQuery } from 'react-query';
import ApiCalls from '../../../network/ApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { CheckBox } from 'devextreme-react';
import { useHistory, useParams } from 'react-router';
import { PermissionRequest } from '../../../network/PostRequestModels/Permission';
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading';
import { AxiosResponse } from 'axios';
import { IJob } from '../../../interfaces/Job';
import { IHostPropertyOfferResponse } from '../../../network/NetworkResponses/NetworkResponses';
import DeleteButton from '../../Buttons/DeleteButton';
import { IHostPropertyOffer } from '../../../interfaces/Property';
import EmptyTable from '../../Icons/EmptyTable';


type ItemProps = {
    propertyService: IHostPropertyOffer
}

const PropertyServiceItem: React.FC<ItemProps> = ({ propertyService }) => {
    return (
        <div className='w-100 w-sm-225px border rounded p-6 d-flex flex-column justify-content-between bg-white mt-3 me-3 align-items-center position-relative' style={{ height: 130 }}>
            <div className='d-flex flex-column align-items-center'>
                <h2 style={{ fontWeight: 400 }}>{propertyService.serviceTypeName}</h2>
                <small className='text-muted text-center' style={{ fontWeight: 400 }}>{propertyService.serviceTypeDescription}</small>
            </div>
            <span className='text-success text-center fs-3' style={{ fontWeight: 'bold' }}>${propertyService.offeredServicePrice}</span>
        </div>
    )
}

const PropertyOfferedStep: React.FC = () => {
    let { propertyId }: any = useParams()
    propertyId = propertyId ? decryptText(propertyId) : undefined;
    const { data: propertyServiceData, isFetching: propertyServicesLoading, error: propertyServiceError, refetch: propertyServiceRefetch } = useQuery<AxiosResponse<IHostPropertyOfferResponse>>(['Get Property Services', propertyId], () => ApiCalls.getHostPropertyServiceOffers(propertyId), { cacheTime: 500000, refetchOnWindowFocus: false });

    if (propertyServicesLoading) {
        return <div><SkeletonTableLoading /></div>
    }

    if (propertyServiceError) {
        return <div>Error</div>
    }

    return (
        <div className='row'>
            {propertyServiceData?.data.data.length === 0 ? (
                <EmptyTable title='No offered services' />
            )
                :
                <div className='d-flex flex-row flex-wrap'>
                    {
                        propertyServiceData?.data.data.map(service => <PropertyServiceItem propertyService={service} />)
                    }
                </div>
            }
        </div>
    )
}

export default PropertyOfferedStep;