import {IOnBoarding} from '../../interfaces/OnBoarding'
import * as actions from '../actionTypes/onBoardingTypes'

export interface OnBoardingState {
  loading: boolean
  onBoardingComplete: boolean
  onBoardingInfo: IOnBoarding
  propertyComplete: boolean
  checklistComplete: boolean
  staffComplete: boolean
  staffServiceComplete: boolean
  staffVisible: boolean
  error: boolean
  failedPayment: boolean
  hasPaymentCard: boolean
  hasMarketplaceRequest: boolean
}

const emptyBoardingInfo: IOnBoarding = {
  isStripeValid: false,
  propertyCount: 0,
  hostPropertyInfo: [],
  staff: [],
  hasTurnifyProProperty: false,
  hasMyTeamProperty: false,
  hasTurnifyPlusProperty: false,
  failedPayment: false,
  hasPaymentCard: true,
  hasMarketplaceRequest: false,
}

const initialState: OnBoardingState = {
  loading: false,
  onBoardingComplete: false,
  onBoardingInfo: {...emptyBoardingInfo},
  propertyComplete: false,
  checklistComplete: false,
  staffComplete: false,
  staffServiceComplete: false,
  staffVisible: false,
  error: false,
  failedPayment: false,
  hasPaymentCard: true,
  hasMarketplaceRequest: false,
}

export default function onBoardingReducer(
  state: OnBoardingState = initialState,
  action: actions.OnBoardingAction
): OnBoardingState {
  switch (action.type) {
    case actions.GET_ON_BOARDING_INFO:
      return {...state, loading: true, error: false}
    case actions.GET_ON_BOARDING_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        onBoardingInfo: action.onBoardingInfo,
        propertyComplete: action.propertyComplete,
        checklistComplete: action.checklistComplete,
        staffComplete: action.staffComplete,
        staffServiceComplete: action.staffServiceComplete,
        staffVisible: action.staffVisible,
        error: false,
        hasPaymentCard: action.hasPaymentCard,
      }
    case actions.GET_ON_BOARDING_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        onBoardingInfo: {...emptyBoardingInfo},
        propertyComplete: false,
        checklistComplete: false,
        staffComplete: false,
        staffServiceComplete: false,
        error: true,
        hasPaymentCard: true,
      }
    case actions.SET_ON_BOARDING_COMPLETE:
      return {...state, loading: false, onBoardingComplete: action.isCompleted, error: false}
    default:
      return state
  }
}
