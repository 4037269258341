/*İmport Edilecek Yer */

import { IForceFinish } from "../../interfaces/ForceFinishRequest"

export const FORCEFINISH_OPEN_VIEW = 'FORCEFINISH_OPEN_VIEW'

export interface OpenForceFinishAction {
    type: typeof  FORCEFINISH_OPEN_VIEW,
    forceFinish: any
}

export const FORCEFINISH_CLOSE_VIEW = 'FORCEFINIS_CLOSE_VIEW'

 export interface CloseForceFinishAction {
     type:typeof FORCEFINISH_CLOSE_VIEW
 }

 export type ForceFinishActions = 
 | OpenForceFinishAction
 | CloseForceFinishAction
