import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import ChangePasswordButton from '../../../../umut-components/Buttons/ChangePasswordButton';
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import SkeletonCircleLoading from '../../../../umut-components/Loading/SkeletonCircleLoading';

type Props = {
    modalOpen: (arg0: boolean) => void
}
const ProfileTab: React.FC<Props> = ({ modalOpen }) => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();


    if (staffDetail.staff == null) {
        return <div className="mt-5"><SkeletonCircleLoading /></div>
    }
    return (

        <div className='d-flex p-6 ps-0 flex-wrap justify-content-between'>
            <div className="d-flex ">
                <div className='d-flex me-6'>
                    <ProfilePhotoTemp width={75} height={75} fontSize={25} borderRadius='50%' profilePhotoPath={staffDetail?.staff?.profilePhotoPath} firstName={staffDetail?.staff?.firstName ?? ''} lastName={staffDetail?.staff?.lastName ?? ''} />
                </div>
                <div className='row'>
                    <div className='col-lg-12 h-auto'>
                        <span className={`badge badge-${staffDetail?.staff?.accountStatusName ? "success" : "danger"} mb-1`}>{staffDetail?.staff?.accountStatusName}</span>
                    </div>
                    <div className='col-lg-12 h-auto'>
                        <strong>{staffDetail?.staff?.firstName + ' ' + staffDetail?.staff?.lastName}</strong>
                    </div>
                    <div className='col-lg-12 h-auto'>
                        <strong className='text-muted'>{staffDetail?.staff?.email}</strong>
                    </div>
                    <div className='col-lg-12 h-auto'>
                        <strong className='text-muted'>{staffDetail?.staff?.phoneNumber}</strong>
                    </div>
                </div>
            </div>
            <div className="d-flex gap-3 h-75">
                <UpdateButton classNames='btn-sm' iconWidth="15" iconHeight="15" updateFn={() => { modalOpen(true) }} />
                <ChangePasswordButton
                    classNames='btn-sm'
                    iconWidth='15'
                    iconHeight='15' changePassFn={() => {
                        dispatch(staffActionCreator.openStaffPasswordDialog(staffDetail?.staff?.userId))
                    }} />
            </div>
        </div>

    )
}

export default ProfileTab;