import React, {useEffect} from 'react'
import {useState} from 'react'
import {IQuestion, ISection} from '../../../interfaces/Checklist'
import * as checklistActionCreator from '../../../redux/actionCreators/masterChecklistActionCreators'
import {useDispatch} from 'react-redux'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import ApiCalls from '../../../network/ApiCalls'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../redux/actionCreators/deleteDialogActionCreators'
import {useDialog} from '../../../contexts/DialogContext'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import {ChecklistSetQuestionOrderRequest} from '../../../network/PostRequestModels/ChecklistRequests'
import {Spinner} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'
import NewCloneSectionQuestionDialog from './NewMasterChecklistSectionCloneQuestion'

const grid = 2

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  // styles we need to apply on draggables
  ...draggableStyle,
})

type Props = {
  section: ISection
  refetch: () => void
}

const NewSectionQuestion: React.FC<Props> = ({section, refetch}) => {
  const dispatch = useDispatch()
  const deleteDispatch = useDispatch()
  const [sectionData, setSectionData] = useState(section)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [saveChangesActive, setSaveChangesActive] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const [showCloneSectionQuestionDialog, setShowCloneSectionQuestionDialog] = useState(false)
  const [cloneSectionQuestion, setCloneSectionQuestion] = useState<IQuestion>()
  const [cloneSectionName, setCloneSectionName] = useState<any>()

  const deleteSectionQuestion = async (questionId: any) => {
    try {
      await ApiCalls.deleteSectionQuestion(questionId)
      dispatch(checklistActionCreator.getChecklistItems(questionId))
      deleteDispatch(closeDeleteDialog())
      showSuccessDialog('Checklist item deleted')
      refetch()
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index
    if (
      sourceIndex !== undefined &&
      destinationIndex !== undefined &&
      sourceIndex !== destinationIndex
    ) {
      setSaveChangesActive(true)
      let newQuestions = section?.questions ? [...section?.questions] : []
      const [removedQuestion] = newQuestions.splice(sourceIndex, 1)
      newQuestions.splice(destinationIndex, 0, removedQuestion)
      setSectionData({...section, questions: newQuestions})
    }
  }

  const handleChangeQuestionList = async () => {
    setSendLoading(true)
    try {
      const questionOrderRequest: ChecklistSetQuestionOrderRequest[] = sectionData?.questions
        ? sectionData?.questions.map((question, index): ChecklistSetQuestionOrderRequest => {
            return {
              id: question.id,
              orderIndex: index + 1,
            }
          })
        : []
      await ApiCalls.setQuestionOrder(questionOrderRequest, sectionData?.id)
      setSaveChangesActive(false)
      setSendLoading(false)
      showSuccessDialog('Orders are changed successfully.')
    } catch (err) {
      setSaveChangesActive(false)
      setSendLoading(false)
    }
  }

  const handleCloseCloneSectionQuestionModal = () => {
    setShowCloneSectionQuestionDialog(false)
  }

  return (
    <div className='table-responsive'>
      <table
        className='table align-middle table-row-dashed fs-6 gy-5'
        id='kt_ecommerce_products_table'
      >
        <thead>
          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
            <th className='w-10px pe-2'></th>
            <th className='min-w-100px'>Image</th>
            <th className='text-start min-w-100px'>Question Title</th>
            <th className='text-start min-w-300px'>Question Description</th>
            <th className='text-center min-w-70px'>Photo Required</th>
            <th className='text-center min-w-70px'>Virtual Inspection</th>
            <th className='text-end min-w-70px'>Actions</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='table-body'>
            {(provided, snapshot) => (
              <tbody
                className='fw-semibold text-gray-600'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sectionData.questions.map((question: any, indx: number) => {
                  return (
                    <Draggable key={question.id} draggableId={question.id.toString()} index={indx}>
                      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <>
                          <tr
                            key={'section-' + section.id + '-question-' + indx}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div
                                  className='symbol symbol-50px'
                                  onClick={() => {
                                    dispatch(
                                      checklistActionCreator.openQuestionPhotoDialog(question)
                                    )
                                  }}
                                >
                                  {question.photos.length > 0 ? (
                                    <img
                                      src={question.photos[0].samplePhotoURL}
                                      className='w-100'
                                    />
                                  ) : (
                                    <span className='symbol-label'></span>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className='text-start pe-0'>
                              <span className='fw-bold'>{question.title.en}</span>
                            </td>
                            <td className='text-start pe-0'>
                              <span className='fw-bold'>{question.description.en}</span>
                            </td>
                            <td className='text-center pe-0'>
                              {question.isPhotoMandatory ? 'YES' : 'NO'}
                            </td>
                            <td className='text-center pe-0'>
                              {question.hasVirtualInspection ? 'YES' : 'NO'}
                            </td>
                            <td className='text-end position-relative'>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-color-primary dropmenu'
                                onClick={() => {
                                  if (
                                    selectedDropIndx ==
                                    'drop-section-' + section.id + '-' + indx
                                  ) {
                                    setSelectedDropIndx(undefined)
                                  } else {
                                    setSelectedDropIndx('drop-section-' + section.id + '-' + indx)
                                  }
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotone/General/Other2.svg'
                                  className='svg-icon-3 svg-icon-secondary dropmenu'
                                  svgClassName='dropmenu mh-50px'
                                />
                              </button>
                              <div
                                className={
                                  selectedDropIndx == 'drop-section-' + section.id + '-' + indx
                                    ? 'menu menu-sub menu-sub-dropdown w-100px w-md-100px align-items-end dropcl2 show'
                                    : 'menu menu-sub menu-sub-dropdown w-100px w-md-100px align-items-end dropcl2'
                                }
                                data-kt-menu='true'
                              >
                                <div className='px-7 py-5 d-flex flex-column'>
                                  <div
                                    className='btn btn-link btn-sm text-primary text-end'
                                    onClick={() => {
                                      dispatch(
                                        checklistActionCreator.openQuestionEditDialog(question)
                                      )
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className='btn btn-link btn-sm text-info text-end'
                                    onClick={() => {
                                      setCloneSectionQuestion(question)
                                      setCloneSectionName(section.roomIndex)
                                      setShowCloneSectionQuestionDialog(true)
                                    }}
                                  >
                                    Clone
                                  </div>
                                  <div
                                    className='btn btn-link btn-sm text-danger text-end'
                                    onClick={() => {
                                      deleteSectionQuestion(question.id)
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </Draggable>
                  )
                })}
                <tr>
                  <td colSpan={7}>
                    <button
                      className='btn btn-sm btn-link text-primary'
                      onClick={() => {
                        dispatch(checklistActionCreator.openQuestionAddDialog(section))
                      }}
                    >
                      + Add New Item
                    </button>
                  </td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      {saveChangesActive && (
        <div className='d-flex justify-content-end'>
          <button
            className='btn btn-success'
            disabled={sendLoading}
            style={{height: '100%'}}
            onClick={handleChangeQuestionList}
          >
            Save Changes {sendLoading ? <Spinner animation='border' size='sm' /> : null}
          </button>
        </div>
      )}
      <NewCloneSectionQuestionDialog
        modalOpen={showCloneSectionQuestionDialog}
        handleClose={handleCloseCloneSectionQuestionModal}
        sectionName={cloneSectionName}
        question={cloneSectionQuestion}
        refetch={refetch}
      />
    </div>
  )
}

export default NewSectionQuestion
