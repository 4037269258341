import React, {useEffect, useRef, useState} from 'react'
import {IHostPropertyInput} from '../../../../../interfaces/Property'
import {useDialog} from '../../../../../contexts/DialogContext'
import dayjs from 'dayjs'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'
import {IJobResponse} from '../../../../../network/NetworkResponses/NetworkResponses'
import {AxiosResponse} from 'axios'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import DatePicker from '../../../../../umut-components/Inputs/DatePicker'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'
import {DataGrid} from 'devextreme-react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import JobSectionTab from './JobSectionTab'

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
}

type TabNames = 'upcoming' | 'past'

const Jobs: React.FC<Props> = ({property, propertyId}) => {
  const tableRef = useRef<DataGrid>(null)
  const [selectedTab, setSelectedTab] = useState<TabNames | null>('upcoming')
  const {showFailureDialog} = useDialog()
  const [cleaningPeriodStart, setCleaningPeriodStart] = useState(
    dayjs().add(-3, 'day').format('YYYY-MM-DD')
  )
  const [cleaningPeriodEnd, setCleaningPeriodEnd] = useState(
    dayjs().add(30, 'day').format('YYYY-MM-DD')
  )

  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
    refetch: refetchJobs,
  } = useQuery<AxiosResponse<IJobResponse>>(
    ['Get Jobs by Property Id', propertyId],
    () => ApiCalls.getJobsByPropertyId(propertyId, cleaningPeriodStart, cleaningPeriodEnd),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  const changeStartDate = (date: any) => {
    setCleaningPeriodStart(dayjs(date).format('YYYY-MM-DD'))
  }

  const changeEndDate = (date: any) => {
    setCleaningPeriodEnd(dayjs(date).format('YYYY-MM-DD'))
  }

  const searchFilteredJobs = () => {
    if (dayjs(cleaningPeriodStart).isAfter(cleaningPeriodEnd)) {
      showFailureDialog('Start date should lower than end date!')
    } else {
      refetchJobs()
    }
  }

  if (jobLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (jobData?.data?.data?.length === 0) {
    return (
      <div className='card card-body'>
        <div className='mb-5 mt-4'>
          <span className='text-danger'>
            This tab displays a list of all jobs created for the selected property. You can view the
            status of each job, as well as the scheduled and completed time for each job.
          </span>
        </div>
        <div className='mt-4'>
          <div className='row'>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  searchFilteredJobs()
                }}
                disabled={jobLoading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <EmptyTable title='No jobs found for this property. You can try again by changing the date range.' />
      </div>
    )
  }

  return (
    <div className='card card-body'>
      <div className='row position-relative'>
        <div className='mb-5 mt-4'>
          <span className='text-danger'>
            This tab displays a list of all jobs created for the selected property. You can view the
            status of each job, as well as the scheduled and completed time for each job.
          </span>
        </div>
        <div className='mt-4'>
          <div className='row'>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  searchFilteredJobs()
                }}
                disabled={jobLoading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div style={{zIndex: 1}} className='position-absolute top-15'>
          <span
            className='fs-8 text-primary cursor-pointer'
            onClick={() => tableRef.current?.instance.clearFilter()}
          >
            Clear Filter
          </span>
        </div>
        <Tabs
          defaultActiveKey='upcoming'
          id='uncontrolled-tab-example'
          className='mb-3 p-3'
          onSelect={handleChangeTab}
        >
          <Tab eventKey='upcoming' title='Upcoming Jobs'>
            <JobSectionTab dataType='upcoming' data={jobData?.data?.data} propertyId={propertyId} />
          </Tab>

          <Tab eventKey='past' title='Past Jobs'>
            <JobSectionTab dataType='past' data={jobData?.data?.data} propertyId={propertyId} />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default Jobs
