import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { onExporting } from '../../../../utils/util';
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton';
import { useDispatch, useSelector } from 'react-redux';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators';
import * as deleteActionCreator from '../../../../redux/actionCreators/deleteDialogActionCreators';
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton';
import { DeleteActions } from '../../../../redux/actionTypes/deleteDialogTypes';
import ApiCalls from '../../../../network/ApiCalls';
import { useDialog } from '../../../../contexts/DialogContext';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';

type Props = {
    data: any,
    refetch: () => void
}

const ServiceTable: React.FC<Props> = ({ data, refetch }) => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const deleteDispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showFailureDialog } = useDialog();
    const tableRef = useRef<DataGrid>(null)

    const calculateDuration = (cellData: any) => {
        return (cellData?.duration + " minutes");
    }

    const calculatePrice = (cellData: any) => {
        return "$" + cellData?.servicePrice;
    }

    const displayEditButton = (cellData: any) => {
        return (
            <UpdateButton iconWidth="20" iconHeight="20" updateFn={() => { dispatch(staffActionCreator.openStaffOfferedAddDialog(refetch, cellData?.data)) }} />
        )
    }

    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                deleteDispatch(deleteActionCreator.openDeleteDialog(async () => {
                    try {
                        await ApiCalls.deleteStaffOfferedService(cellData?.data?.id);
                        deleteDispatch(deleteActionCreator.closeDeleteDialog());
                        refetch();
                    }
                    catch (err: any) {
                        showFailureDialog(err?.response?.data?.message);
                    }
                },
                    `Do you want to remove ${cellData.data.serviceTypeName} service from ${staffDetail?.staff?.firstName +" "+ staffDetail?.staff?.lastName} ?`,
                    "Remove Service"));
            }} />
        )
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Services" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="offeredTable"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(45vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Job Services")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Services: {0}" column='serviceTypeName' summaryType='count' />
                </Summary>

                <Column dataField="serviceTypeName" caption="Service Type" minWidth={150} cssClass='cls' />
                <Column dataField="duration" calculateCellValue={calculateDuration} minWidth={150} cssClass='cls' />
                <Column dataField="servicePrice" caption="Price" calculateCellValue={calculatePrice} minWidth={150} cssClass='cls' />
                <Column width={110} alignment='center' cellRender={displayEditButton} cssClass='cls' />
                <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
            </DataGrid>

        </div>




    )
}

export default React.memo(ServiceTable);
