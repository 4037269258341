import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import React, {Dispatch, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import {
  encryptText,
  toConvertedDate,
  onExporting,
  getDateFilter,
  setDxFilterPlaceholder,
} from '../../utils/util'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {JobStatusObj, JobStatusType} from '../../enums/JobEnum'
import {openCancelJobDialog} from '../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import config from '../../config/config.json'
import dayjs from 'dayjs'
import DatePicker from '../../umut-components/Inputs/DatePicker'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  jobStatusId: number
}

const JobTable: React.FC<Props> = ({jobStatusId}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const {showFailureDialog} = useDialog()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [cleaningPeriodStart, setCleaningPeriodStart] = useState(
    dayjs().add(-30, 'day').format('YYYY-MM-DD')
  )
  const [cleaningPeriodEnd, setCleaningPeriodEnd] = useState(
    dayjs().add(90, 'day').format('YYYY-MM-DD')
  )
  const {data, isLoading, error, refetch} = useQuery(
    ['Get Jobs By Id', jobStatusId],
    () =>
      ApiCalls.getJobsByStatusId(
        user.userSection.hostId,
        jobStatusId,
        cleaningPeriodStart,
        cleaningPeriodEnd
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: user.userSection.hostId ? true : false,
    }
  )

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.id && cellData?.column?.name !== 'delete-button') {
      // history.push({
      //     pathname: `/job-details/` + encryptText(cellData?.data?.id)
      // })
      window.open(`/job-details/` + encryptText(cellData?.data?.id))
    }
  }

  const displayDeleteButton = (cellData: any) => {
    return (
      <div style={{position: 'relative', zIndex: 3000}}>
        <DeleteButton
          classNames='invisible btn'
          iconWidth='20'
          iconHeight='20'
          deleteFn={() => {}}
        />
      </div>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodStart = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodEnd = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodEnd, cellValue.hostPropertyCityTimeZone)
  }

  const changeStartDate = (date: any) => {
    setCleaningPeriodStart(dayjs(date).format('YYYY-MM-DD'))
  }

  const changeEndDate = (date: any) => {
    setCleaningPeriodEnd(dayjs(date).format('YYYY-MM-DD'))
  }

  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }

  const renderStatusReason = (cellValue: any) => {
    if (cellValue?.data?.pendingReason) {
      return <span>{cellValue?.data?.pendingReason}</span>
    }
    return <span className='text-muted'>-</span>
  }

  const searchFilteredJobs = () => {
    if (dayjs(cleaningPeriodStart).isAfter(cleaningPeriodEnd)) {
      showFailureDialog('Start date should lower than end date!')
    } else {
      refetch()
    }
  }

  if (isLoading) {
    return (
      <div className='mt-6'>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (data?.data?.data?.length === 0) {
    return (
      <>
        <div className='mt-4'>
          <div className='row'>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  searchFilteredJobs()
                }}
                disabled={isLoading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <EmptyTable title='No Jobs have been found' />
      </>
    )
  }

  return (
    <div className='position-relative'>
      <div className='mt-4'>
        <div className='row'>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <button
              className='btn btn-sm btn-primary'
              onClick={() => {
                searchFilteredJobs()
              }}
              disabled={isLoading}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id={'jobTable' + jobStatusId}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        className='mt-6'
        ref={tableRef}
        onCellClick={toJobDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Jobs')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>
        <Column
          dataField='hostPropertyAddress'
          cellRender={isManuallyCreatedJob}
          minWidth={450}
          caption='Property'
          cssClass='cls'
        />
        <Column
          visible={jobStatusId === JobStatusObj['Pending']}
          dataField='cleaningPeriodStart'
          caption='Expected Start'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateCleaningPeriodStart}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('cleaningPeriodStart')} />
        </Column>
        <Column
          visible={
            jobStatusId === JobStatusObj['Scheduled'] ||
            jobStatusId === JobStatusObj['Started'] ||
            jobStatusId === JobStatusObj['Completed']
          }
          dataField='scheduledStart'
          caption='Scheduled Start'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          alignment='center'
          allowFiltering={false}
          caption='Status'
          cellRender={displayStatusType}
          minWidth={150}
          cssClass='cls'
        />
        <Column
          visible={
            jobStatusId === JobStatusObj['Started'] || jobStatusId === JobStatusObj['Completed']
          }
          dataField='startedOn'
          caption='Started at'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateStartedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('startedOn')} />
        </Column>
        <Column
          visible={jobStatusId === JobStatusObj['Pending']}
          dataField='cleaningPeriodEnd'
          caption='Expected Finish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateCleaningPeriodEnd}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('cleaningPeriodEnd')} />
        </Column>

        <Column
          visible={
            jobStatusId === JobStatusObj['Scheduled'] ||
            jobStatusId === JobStatusObj['Started'] ||
            jobStatusId === JobStatusObj['Completed']
          }
          dataField='scheduledFinish'
          caption='Scheduled Finish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={190}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>

        <Column
          visible={jobStatusId === JobStatusObj['Completed']}
          dataField='finishedOn'
          caption='Finished at'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>

        <Column dataField='hostPropertyCityName' caption='City' minWidth={200} cssClass='cls' />
        <Column dataField='hostPropertyStateName' caption='State' minWidth={150} cssClass='cls' />
        <Column
          dataField='hostPropertyCountryName'
          caption='Country'
          minWidth={200}
          cssClass='cls'
        />

        <Column
          name='delete-button'
          visible={
            jobStatusId === JobStatusObj['Pending'] || jobStatusId === JobStatusObj['Scheduled']
          }
          width={110}
          alignment='center'
          cellRender={displayDeleteButton}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default JobTable
