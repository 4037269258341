import {IProTeam, IProTeamDetail} from '../../interfaces/ProTeam'
import * as actions from '../actionTypes/proTeamActionTypes'

export function getProTeamList(hostId: number, roleId?: number): actions.GetProTeamList {
  return {
    type: actions.GET_PROTEAM_LIST,
    hostId,
    roleId,
  }
}

export function getProTeamListSuccess(proTeamList: IProTeam[]): actions.GetProTeamListSuccess {
  return {
    type: actions.GET_PROTEAM_LIST_SUCCESS,
    proTeamList,
  }
}

export function getProTeamListFailure(errorText: string): actions.GetProTeamListFailure {
  return {
    type: actions.GET_PROTEAM_LIST_FAILURE,
    errorText,
  }
}

export function getProTeamDetail(userId: number): actions.GetProTeamDetail {
  return {
    type: actions.GET_PROTEAM_DETAIL,
    userId,
  }
}

export function getProTeamDetailSuccess(proTeam: IProTeamDetail): actions.GetProTeamDetailSuccess {
  return {
    type: actions.GET_PROTEAM_DETAIL_SUCCESS,
    proTeam,
  }
}

export function getProTeamDetailFailure(errorText: string): actions.GetProTeamDetailFailure {
  return {
    type: actions.GET_PROTEAM_DETAIL_FAILURE,
    errorText,
  }
}
