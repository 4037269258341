import * as actions from '../actionTypes/deleteDialogTypes'

export function openDeleteDialog(
  deleteFn: () => Promise<void>,
  bodyText: string,
  title: string,
  btnText?: string
): actions.OpenDeleteDialog {
  return {
    type: actions.OPEN_DELETE_DIALOG,
    deleteFn,
    bodyText,
    title,
    btnText: btnText ? btnText : 'Delete',
  }
}

export function closeDeleteDialog(): actions.CloseDeleteDialog {
  return {
    type: actions.CLOSE_DELETE_DIALOG,
  }
}
