import SelectInput from '../../../umut-components/Inputs/SelectInput'
import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'
import {useDialog} from '../../../contexts/DialogContext'
import {encryptText, getDateFilter} from '../../../utils/util'
import {useHistory} from 'react-router-dom'
import NoChecklistWarnDialog from './NoChecklistWarnDialog'

type Props = {
  handleChange: (changedValues: any) => void
  handlePropertySelect: (data: string) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
}

const PropertySelect: React.FC<Props> = ({
  missingField,
  handleChange,
  handlePropertySelect,
  goToStep,
  currentStep,
}) => {
  const history = useHistory()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showFailureDialog} = useDialog()
  const [propertyList, setPropertyList] = useState([])
  const [hasError, setHasError] = useState(false)
  const [hasAlreadyRequest, setHasAlreadyRequest] = useState(false)
  const [selectedValues, setSelectedValues] = useState({
    hostPropertyId: 0,
  })
  const [showChecklistWarnDialog, setShowChecklistWarnDialog] = useState(false)

  const tableRef = useRef<DataGrid>(null)

  const onPropertyChange = (value: number) => {
    setHasError(false)
    setSelectedValues({...selectedValues, hostPropertyId: value})
  }

  const {
    data: properties,
    isFetching: propertiesLoading,
    error: propertyError,
  } = useQuery(
    'Get Host Properties',
    () => ApiCalls.getHostPropertiesByProvider(user.userSection.hostId, 2, true),
    {
      cacheTime: 10000,
      retry: false,
    }
  )

  const onPropertySelect = async (data: any) => {
    var propertyId = data.selectedRowsData[0].id
    var address = data.selectedRowsData[0].name
    handlePropertySelect(address)
    setHasError(false)
    setSelectedValues({...selectedValues, hostPropertyId: propertyId})
  }

  useEffect(() => {
    if (properties) {
      setPropertyList(properties?.data?.data)
    }
  }, [properties])

  const onNextStepClick = () => {
    if (selectedValues.hostPropertyId == 0) {
      setHasError(true)
      showFailureDialog('Please select a property')
    } else {
      if (!hasAlreadyRequest) {
        ApiCalls.checkPropertyMarketplace(selectedValues.hostPropertyId)
          .then((d) => {
            if (d.data.count > 0) {
              setHasAlreadyRequest(true)
              history.push('/marketplace/detail/' + encryptText(d.data.marketplaceId))
            } else {
              ApiCalls.getHostPropertyChecklists(selectedValues.hostPropertyId)
                .then((r) => {
                  if (r.data.length > 0) {
                    setHasAlreadyRequest(true)
                    handleChange({
                      hostPropertyId: selectedValues.hostPropertyId,
                    })
                    goToStep(2)
                  } else {
                    setShowChecklistWarnDialog(true)
                  }
                })
                .catch((e) => {})
            }
          })
          .catch((e) => {
            handleChange({
              hostPropertyId: selectedValues.hostPropertyId,
            })
            goToStep(2)
          })
      } else {
        handleChange({
          hostPropertyId: selectedValues.hostPropertyId,
        })
        goToStep(2)
      }
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='form w-100'>
            <div className='row mb-6 d-flex justify-content-center'>
              <div className='fv-row col-lg-12 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark w-100'>
                  Ready to find the perfect airbnb cleaner for your property? Select your property
                  to get started.
                </label>
                <small className='text-danger'>Click on the property you want to select</small>
              </div>
              <div className='fv-row col-lg-12 mb-5'>
                <DataGrid
                  id='propertyTable'
                  keyExpr='id'
                  onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                  }}
                  dataSource={propertyList}
                  showBorders={false}
                  ref={tableRef}
                  showColumnLines={false}
                  showRowLines={true}
                  hoverStateEnabled={true}
                  height={'calc(32vh)'}
                  allowColumnResizing={true}
                  onSelectionChanged={onPropertySelect}
                >
                  <Selection mode={'single'} showCheckBoxesMode='always' />
                  <FilterRow showOperationChooser={false} visible={false} />
                  <HeaderFilter visible={false} />
                  <SearchPanel visible={false} />
                  <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                  <Paging defaultPageSize={50} />
                  <Summary>
                    <TotalItem
                      cssClass='absolute-right'
                      displayFormat='Total Properties: {0}'
                      column='name'
                      summaryType='count'
                    />
                  </Summary>

                  <Column dataField='name' caption={'Address'} minWidth={550} cssClass='cls' />
                  <Column dataField='cityName' caption={'City'} cssClass='cls' minWidth={150} />
                  <Column dataField='stateName' caption={'State'} cssClass='cls' minWidth={150} />
                  <Column
                    dataField='countryName'
                    caption={'Country'}
                    cssClass='cls'
                    minWidth={150}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer p-0 pt-5'>
          <div className='d-flex justify-content-end flex-row w-100'>
            <button
              className='btn btn-sm btn-success'
              onClick={() => {
                onNextStepClick()
              }}
            >
              Verify Property Information
            </button>
          </div>
        </div>
      </div>
      <NoChecklistWarnDialog
        propertyId={selectedValues.hostPropertyId}
        isVisible={showChecklistWarnDialog}
        onClose={() => {
          setShowChecklistWarnDialog(false)
        }}
      />
    </>
  )
}

export default PropertySelect
