import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {PropertyFormProvider} from '../../contexts/PropertyFormContext'
import AddNewPropertyManuallyWizard from './AddWizard/AddNewPropertyManuallyWizard'

export function AddNewPropertyManuallyPage() {
  return (
    <>
      <PropertyFormProvider>
        <PageTitle>Add Property Manually</PageTitle>
        <AddNewPropertyManuallyWizard />
      </PropertyFormProvider>
    </>
  )
}
