import {AxiosResponse} from 'axios'
import React, {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {IBillingInformation} from '../../../interfaces/Payment'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import CardList from './CardList'
import StripeContainer from './Stripe/StripeContainer'

const Payment = () => {
  const [addCardSection, setAddCardSection] = useState(false)

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(
    ['Get Customer Sources', user.userSection.hostId],
    () => ApiCalls.getCustomerSources(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {
    data: billingInformation,
    isLoading,
    error,
    refetch,
  } = useQuery<AxiosResponse<IBillingInformation>>(
    ['Get Billing Information', user.userSection.hostId],
    () => ApiCalls.getBillingInformation(),
    {cacheTime: 100000, retry: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!billingInformation?.data.stripeAccountId || error) {
      try {
        ApiCalls.postBillingInformation()
          .then((response) => {
            if (!response.status) {
              throw response
            }
          })
          .catch((error) => console.log(error))
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  const toggleAddCard = () => {
    setAddCardSection(!addCardSection)
  }

  const deleteSource = async (sourceId: string) => {
    try {
      await ApiCalls.deleteCustomerSource(sourceId)
      cardListFetching()
    } catch (error: any) {
      console.log(error?.data?.response?.message)
    }
  }

  const setDefaultSource = async (sourceId: string) => {
    try {
      await ApiCalls.putDefaultCustomerSource({source: sourceId})
      cardListFetching()
    } catch (error: any) {
      console.log(error?.data?.response?.message)
    }
  }

  if (error && !billingInformation?.data.stripeAccountId) {
    return (
      <div>
        <Card className='pb-15'>
          <EmptyTable title="Something went wrong on Stripe's end." />
        </Card>
      </div>
    )
  }

  return (
    <div>
      <PageTitle>Payment</PageTitle>
      <div className='card'>
        <div className='card-header card-header-stretch pb-0'>
          <div className='card-title'>
            <h3 className='m-0'>Payment Methods</h3>
          </div>
          <div className='card-toolbar' style={{maxWidth: '10%', minWidth: 140}}>
            <img
              src={toAbsoluteUrl('/media/svg/powered-by-stripe.png')}
              alt='stripe'
              className='w-100 object-contain'
            />
          </div>
        </div>
        <div className='card-body'>
          <div className='row gx-9 gy-6'>
            {cardList?.data && cardList?.data.length > 0 && (
              <>
                {cardList?.data.map((cl: any, i: number) => {
                  return (
                    <div className='col-xl-6 col-lg-6' key={'card-' + i}>
                      <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                        <div className='d-flex flex-column py-2'>
                          <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                            Card #{i + 1}
                            {cl?.default && (
                              <span className='badge badge-light-success fs-7 ms-2'>Default</span>
                            )}
                          </div>
                          <div className='d-flex align-items-center'>
                            <CreditLogos brand={cl?.brand} />
                            <div>
                              <div className='fs-4 fw-bold'>
                                {cl?.brand} **** {cl?.last4}
                              </div>
                              <div className='fs-7 fw-semibold text-gray-500'>
                                Card expires at {cl?.exp_month + '/' + cl?.exp_year}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          {!cl?.default && (
                            <button
                              className='btn btn-sm btn-light btn-active-light-success me-3'
                              onClick={() => {
                                setDefaultSource(cl.id)
                              }}
                            >
                              Set as Default
                            </button>
                          )}
                          <button
                            className='btn btn-sm btn-light-danger me-3'
                            onClick={() => {
                              deleteSource(cl.id)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            )}
            <div className='col-xl-6 col-lg-6'>
              <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Add a Card</h4>
                    <div className='fs-6 text-gray-700 pe-7'>
                      Save time and track your expenses with adding your payment card.
                    </div>
                  </div>
                  <button
                    className='btn btn-primary px-6 align-self-center text-nowrap'
                    onClick={() => {
                      toggleAddCard()
                    }}
                  >
                    + Add New Card
                  </button>
                </div>
              </div>
            </div>
          </div>
          {addCardSection && (
            <div className='row gx-9 gy-6 mt-5'>
              <div className='col-md-5 col-12 ms-auto me-auto mb-4 mt-8'>
                <StripeContainer
                  handleSuccess={() => {
                    setAddCardSection(false)
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Payment

type CreditProps = {
  brand: string | undefined
}
const CreditLogos: React.FC<CreditProps> = ({brand}) => {
  switch (brand) {
    case 'MasterCard':
      return <img src={toAbsoluteUrl('/media/logos/creditcard-logos/mc.png')} alt='mc' />
    case 'Visa':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/visa_electron.png')} alt='visa' />
      )
    case 'AmericanExpress':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express.png')} alt='ae' />
      )
    default:
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express_green.png')}
          alt='defaultcard'
        />
      )
  }
}
