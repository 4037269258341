import React, {Dispatch, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../../../contexts/DialogContext'
import {LobRoleNameEnum} from '../../../../enums/UserRoles'
import ApiCalls from '../../../../network/ApiCalls'
import {closeChecklistAssignmentDialog} from '../../../../redux/actionCreators/masterChecklistActionCreators'
import {ChecklistAction} from '../../../../redux/actionTypes/masterChecklistTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'

type Props = {
  checklist: any
  selectedProperties: any[]
  nextStep?: () => void
  previousStep?: () => void
}

const SummaryStep: React.FC<Props> = ({checklist, selectedProperties, nextStep, previousStep}) => {
  const {assignmentDialog} = useSelector((state: RedusxAppState) => state.masterChecklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string | null>(null)
  const {showSuccessDialog, showFailureDialog} = useDialog()

  const handleAssignChecklist = async () => {
    setLoading(true)
    setErrorText(null)
    showSuccessDialog('Checklist assignment request sent. You will be notified.')
    ApiCalls.assignChecklists({
      hostPropertyChecklistId: checklist?.id,
      hostPropertyIds: selectedProperties.map((property: any) => property.id),
    })
      .then((res) => {
        setLoading(false)
        showSuccessDialog('Checklist successfully assigned to properties.')
        dispatch(closeChecklistAssignmentDialog())
      })
      .catch((err: any) => {
        setLoading(false)
        showSuccessDialog(err?.response?.data?.message)
        setErrorText(err?.response?.data?.message)
      })
    dispatch(closeChecklistAssignmentDialog())
  }

  return (
    <div>
      <div className='row'>
        <div className='col-6 d-flex flex-column'>
          <h2 style={{fontWeight: 400}}>Assigned Checklist</h2>
          <div className='d-flex flex-column w-100 border rounded me-6 p-6 position-relative'>
            <div className='d-flex flex-column mt-3'>
              <h2 style={{fontWeight: 400}}>
                {checklist?.name?.en
                  ? checklist?.name?.en
                  : checklist?.name[Object.keys(checklist?.name)[0]]}
              </h2>
              <span className='text-muted' style={{fontWeight: 400}}>
                {checklist?.serviceTypeName}
              </span>
              <small className='text-muted' style={{fontWeight: 400}}>
                {checklist?.serviceTypeDescription}
              </small>
              <span className='text-muted mt-3' style={{fontWeight: 400}}>
                {checklist?.questionCount} checklist items
              </span>
            </div>
            <div className='position-absolute d-flex flex-row' style={{top: 0, right: 20}}>
              <span
                className='badge badge-primary badge-sm me-3 rounded-top-0'
                style={{fontWeight: 400}}
              >
                {checklist?.roleName}
              </span>
              <span
                className='badge badge-success badge-sm rounded-top-0'
                style={{fontWeight: 400}}
              >
                {checklist?.lobRoleId === LobRoleNameEnum.HOST
                  ? 'My Team | Turnify+'
                  : 'Turnify PRO'}
              </span>
            </div>
          </div>
        </div>
        <div className='col-6 d-flex flex-column' style={{maxHeight: 400, overflowY: 'scroll'}}>
          <h2 className='sticky-top bg-white' style={{fontWeight: 400}}>
            Assignee Properties
          </h2>
          {selectedProperties?.length === 0 ? (
            <EmptyTable title='No Properties Selected' />
          ) : (
            selectedProperties.map((property: any) => (
              <div key={property.id} className='d-flex align-items-center p-6 border border-full'>
                {property.name}
              </div>
            ))
          )}
        </div>
      </div>
      {errorText ? (
        <div className='d-flex justify-content-end'>
          <strong className='text-danger'>{errorText}</strong>
        </div>
      ) : null}
      <div className='col-12 d-flex justify-content-between mt-6'>
        <ArrowButton type='back' func={previousStep} />
        <button
          className='btn btn-success'
          disabled={selectedProperties.length === 0 || loading}
          onClick={handleAssignChecklist}
        >
          Assign Checklist {loading && <Spinner animation='border' size='sm' />}
        </button>
      </div>
    </div>
  )
}

export default SummaryStep
