import * as actions from '../actionTypes/finishRequestTypes'
import { IForceFinish } from '../../interfaces/ForceFinishRequest'


export interface forceFinishState {
    modalOpen:boolean,
    forceFinish: IForceFinish | null
}
const initialState:forceFinishState = {
    modalOpen:false,
    forceFinish: null
}

export default function forceFinishReducer(
    state:forceFinishState = initialState,
    action:actions.ForceFinishActions
){
    switch(action.type){
        case actions.FORCEFINISH_OPEN_VIEW:
            return {...state,modalOpen:true,forceFinish:action.forceFinish}
        case actions.FORCEFINISH_CLOSE_VIEW:
            return {...state,modalOpen:false}
        default:
            return state
    }
}