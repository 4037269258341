import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC, useState} from 'react'
import {Accordion, Button, Card, ListGroup, Modal, Tab, Tabs} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {useDialog} from '../../contexts/DialogContext'
import {IMarketplace} from '../../interfaces/Marketplace'
import ApiCalls from '../../network/ApiCalls'
import {MarketplaceListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {encryptText} from '../../utils/util'

type Props = {
  recordCount: number
  data: MarketplaceListResponse | undefined
  refetchData: () => void
}

const MarketplaceCard: React.FC<Props> = ({recordCount, data, refetchData}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const {showSuccessDialog, showFailureDialog, openLoadingModal} = useDialog()
  const [selectedMP, setSelectedMP] = useState<IMarketplace>()
  const [showMPInfoModal, setShowMPInfoModal] = useState<boolean>(false)
  const [showRequestClosingModal, setShowRequestClosingModal] = useState<boolean>(false)
  const [showReopenModal, setShowReopenModal] = useState<boolean>(false)

  const showSelectedMarketplaceInfo = (mp: IMarketplace) => {
    setSelectedMP(mp)
    setShowMPInfoModal(true)
  }

  const openMarketplaceOffers = (index: number) => {
    var marketplaces = data?.data
    if (marketplaces != undefined) {
      var findMP = marketplaces.find((mp, i) => i == index)
      if (findMP != undefined) {
        history.push('marketplace/detail/' + encryptText(findMP.id))
      }
    }
  }

  const closeMarketplaceRequest = (mp: IMarketplace) => {
    setSelectedMP(mp)
    setShowRequestClosingModal(true)
  }

  const closeSelectedMP = () => {
    if (selectedMP == undefined) {
      showFailureDialog('Please select a request for close!')
    } else {
      var marketplaceId = selectedMP.id
      var request = {id: marketplaceId, isActive: false}
      ApiCalls.updateMarketplaceRequestStatus(request)
        .then((res: any) => {
          if (res?.data?.success) {
            showSuccessDialog('Your cleaner search request is successfully closed.')
            history.push('/marketplace')
            setSelectedMP(undefined)
            setShowRequestClosingModal(false)
            refetchData()
          } else {
            showFailureDialog('There is an error occur during closing cleaner search request')
          }
        })
        .catch((err: any) => {
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        })
    }
  }

  const reopenMarketplaceRequest = (mp: IMarketplace) => {
    setSelectedMP(mp)
    setShowReopenModal(true)
  }

  const reopenSelectedMP = () => {
    if (selectedMP == undefined) {
      showFailureDialog('Please select a request for reopen!')
    } else {
      var marketplaceId = selectedMP.id
      var request = {id: marketplaceId, isActive: true}
      ApiCalls.updateMarketplaceRequestStatus(request)
        .then((res: any) => {
          if (res?.data?.success) {
            showSuccessDialog('Your cleaner search request is successfully opened.')
            history.push('/marketplace')
            setSelectedMP(undefined)
            setShowReopenModal(false)
            refetchData()
          } else {
            showFailureDialog('There is an error occur during opening cleaner search request')
          }
        })
        .catch((err: any) => {
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        })
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Your Requests ({recordCount})</Card.Title>
          <div className='card-toolbar'>
            <button
              className='btn btn-danger w-100'
              onClick={() => history.push('/marketplace/new')}
            >
              Create Request
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          {recordCount > 0 && (
            <>
              {data?.data.map((mp, i) => {
                return (
                  <div
                    className='d-flex align-items-center mb-10 p-3'
                    style={{backgroundColor: '#f0f0f0', borderRadius: 6}}
                  >
                    <div className='symbol symbol-40 symbol-light-primary me-5'>
                      <span className='symbol-label'>
                        <span className='svg-icon svg-icon-2x svg-icon-danger'>
                          <svg
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            version='1.1'
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-75 align-self-end'
                          >
                            <defs></defs>
                            <g
                              id='Stockholm-icons-/-Home-/-Library'
                              stroke='none'
                              stroke-width='1'
                              fill='none'
                              fill-rule='evenodd'
                            >
                              <rect id='bound' x='0' y='0' width='24' height='24'></rect>
                              <path
                                d='M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z'
                                id='Combined-Shape'
                                fill='currentColor'
                              ></path>
                              <rect
                                id='Rectangle-Copy-2'
                                fill='currentColor'
                                opacity='0.3'
                                transform='translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) '
                                x='16.3255682'
                                y='2.94551858'
                                width='3'
                                height='18'
                                rx='1'
                              ></rect>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 font-weight-bold'>
                      <div className='text-dark text-hover-danger mb-1 font-size-lg'>
                        {mp.address + ' ' + (mp.suite ? mp.suite : '')}
                      </div>
                      <button
                        onClick={() => {
                          showSelectedMarketplaceInfo(mp)
                        }}
                        className='btn btn-sm btn-link p-0 text-start text-muted'
                      >
                        Searching Details
                      </button>
                    </div>
                    {mp.isActive && (
                      <>
                        {mp?.offers && mp?.offers.length > 0 && (
                          <>
                            <button
                              className='btn btn-sm btn-link me-3 text-danger'
                              onClick={() => {
                                openMarketplaceOffers(i)
                              }}
                            >
                              View Nearby Cleaners
                            </button>
                            {/* <button
                              className='btn btn-sm'
                              onClick={() => {
                                closeMarketplaceRequest(mp)
                              }}
                            >
                              <span className='svg-icon svg-icon-2x svg-icon-danger'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24px'
                                  height='24px'
                                  viewBox='0 0 24 24'
                                  version='1.1'
                                >
                                  <g
                                    stroke='#FE5D5D'
                                    stroke-width='1'
                                    fill='#FE5D5D'
                                    fill-rule='evenodd'
                                  >
                                    <g
                                      transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)'
                                      fill='#FE5D5D'
                                    >
                                      <rect x='0' y='7' width='16' height='2' rx='1' />
                                      <rect
                                        opacity='0.3'
                                        transform='translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) '
                                        x='0'
                                        y='7'
                                        width='16'
                                        height='2'
                                        rx='1'
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            </button> */}
                          </>
                        )}
                        {(!mp?.offers || (mp.offers && mp.offers.length == 0)) && (
                          <span className='text-danger'>Waiting for nearby cleaners..</span>
                        )}
                      </>
                    )}
                    {!mp.isActive && (
                      <>
                        <button
                          className='btn btn-sm btn-outline btn-outline-dark'
                          onClick={() => {
                            reopenMarketplaceRequest(mp)
                          }}
                        >
                          Reopen Search
                        </button>
                      </>
                    )}
                  </div>
                )
              })}
            </>
          )}
          {recordCount == 0 && (
            <div className='alert alert-secondary'>
              You do not have a current request. You can create a new request by clicking the
              "Create Request" button.
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal
        show={showMPInfoModal}
        onHide={() => {
          setSelectedMP(undefined)
          setShowMPInfoModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>{selectedMP?.address}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item className='border-0'>
              <strong>Address</strong> : {selectedMP?.address}
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Estimated Project Time</strong> : {selectedMP?.cleaningDuration} hours
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Jobs Per Month</strong> : {selectedMP?.jobsPerMonth} jobs
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Bedrooms</strong> : {selectedMP?.numberOfBedrooms}
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Bathrooms</strong> : {selectedMP?.numberOfBathrooms}
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Unit Size</strong> : {selectedMP?.propertySize} sqft.
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Laundry</strong> :{' '}
              {selectedMP?.cleanLinenAndTowel ? 'Required' : 'Not Required'}
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Supplies</strong> :{' '}
              {selectedMP?.hostProvideCleaningSupplies ? 'Provided' : 'Not Provided'}
            </ListGroup.Item>
            <ListGroup.Item className='border-0'>
              <strong>Note</strong> : {selectedMP?.note == '' ? '-' : selectedMP?.note}
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRequestClosingModal}
        onHide={() => {
          setSelectedMP(undefined)
          setShowRequestClosingModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <p style={{fontSize: 15}}>
            You are about to close a quote request for the property at{' '}
            <strong>{selectedMP?.address}</strong>. Is your request closed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-link text-muted me-5'
            onClick={() => {
              setSelectedMP(undefined)
              setShowRequestClosingModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-sm btn-danger'
            onClick={() => {
              closeSelectedMP()
            }}
          >
            Yes, close it
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showReopenModal}
        onHide={() => {
          setSelectedMP(undefined)
          setShowReopenModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <p style={{fontSize: 15}}>
            You are about to reopen the quote request for the property at{' '}
            <strong>{selectedMP?.address}</strong>. Open your request?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-link text-muted me-5'
            onClick={() => {
              setSelectedMP(undefined)
              setShowReopenModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-sm btn-danger'
            onClick={() => {
              reopenSelectedMP()
            }}
          >
            Yes, open it
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MarketplaceCard
