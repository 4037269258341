import { IPMSProperty } from "../../interfaces/Property";
import * as actions from "../actionTypes/hostPropertyTypes";

export interface HostPropertyState {
    hostPropertyList: IPMSProperty[],
    selectedCleaningProviderId?: number | null,
    propertySubmission?: {
        submissionError?: string | null,
        submitting: boolean
    },
    propertyDelete: {
        modalOpen: boolean
        propertyId: number | null
    }
}

const initialState: HostPropertyState = {
    hostPropertyList: [],
    selectedCleaningProviderId: null,
    propertySubmission: {
        submissionError: null,
        submitting: false
    },
    propertyDelete: {
        modalOpen: false,
        propertyId: null
    }
}

export default function hostPropertyReducer(
    state: HostPropertyState = initialState,
    action: actions.HostPropertyAction
): HostPropertyState {
    switch (action.type) {
        case actions.GET_HOST_PROPERTY_LIST:
            return state;
        case actions.SET_HOST_PROPERTY_LIST:
            return { ...state, hostPropertyList: action.hostPropertyList }
        case actions.CLEAR_HOST_PROPERTY_LIST:
            return { ...state, hostPropertyList: [] }
        case actions.SET_HOST_PROPERTY_SUCCESS:
            return { ...state, hostPropertyList: action.hostPropertyList }
        case actions.SET_HOST_PROPERTY_FAILURE:
            return { ...state, hostPropertyList: [] }
        case actions.POST_HOST_PROPERTY_FORM:
            return { ...state, propertySubmission: { submissionError: null, submitting: true } }
        case actions.POST_HOST_PROPERTY_FORM_SUCCESS:
            return { ...state, hostPropertyList: [], propertySubmission: { submissionError: null, submitting: false } }
        case actions.POST_HOST_PROPERTY_FORM_FAILURE:
            return { ...state, propertySubmission: { submissionError: action.error, submitting: true } }
        case actions.GET_SELECTED_CLEANING_PROVIDER:
            return state;
        case actions.SET_SELECTED_CLEANING_PROVIDER:
            return { ...state, selectedCleaningProviderId: action.cleaningProviderId }
        case actions.CLEAR_SELECTED_CLEANING_PROVIDER:
            return { ...state, selectedCleaningProviderId: null }
        case actions.OPEN_DELETE_PROPERTY_MODAL:
            return { ...state, propertyDelete: { modalOpen: true, propertyId: action.propertyId } }
        case actions.CLOSE_DELETE_PROPERTY_MODAL:
            return { ...state, propertyDelete: { modalOpen: false, propertyId: null } }
        default:
            return state;
    }
}