import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { JobStatusObj, JobStatusType } from '../../../../enums/JobEnum';
import { IJobChecklistPhoto, IJobDetail, IJobIssue } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { decryptText } from '../../../../utils/util';
import PhotoItem from './PhotoItem';
import PhotosDialog from './PhotosDialog';

type Props = {
    jobEventsActive: boolean;
}

const PhotosTab: React.FC<Props> = ({ jobEventsActive }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { data, isLoading, error } = useQuery(['Get Job Checklist Photos', jobId], () => ApiCalls.getChecklistPhotos(jobId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job);

    return (
        <>
            <div className='d-flex justify-content-start flex-wrap flex-row'>
                {data?.data?.data.filter((virtual: IJobChecklistPhoto) => !virtual.hostPropertyChecklistSectionQuestionHasVirtualInspection || virtual?.isVirtualInspectionComplete).map((item: IJobChecklistPhoto) => <PhotoItem item={item} />)}
                {data?.data?.data.filter((virtual: IJobChecklistPhoto) => !virtual.hostPropertyChecklistSectionQuestionHasVirtualInspection || virtual?.isVirtualInspectionComplete).length === 0 ? <div className='p-3 mt-6'>No Checklist Found</div> : null}
            </div>
            <PhotosDialog />
        </>
    )
}

export default PhotosTab;