import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PMSTypeName} from '../../../enums/PropertyEnum'
import {
  IHostPropertyAutomation,
  IHostPropertyICal,
  IHostPropertyInput,
  IPMSProperty,
} from '../../../interfaces/Property'
import ApiCalls from '../../../network/ApiCalls'
import PropertyTable from '../../../pages/add-property-pms/Table/PropertyTable'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {decryptText} from '../../../utils/util'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import ArrowButton from '../../Buttons/ArrowButton'
import DeleteButton from '../../Buttons/DeleteButton'
import SwitchBox from '../../Inputs/SwitchBox'
import TimePicker from '../../Inputs/TimePicker'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import SelectBox from '../../Inputs/SelectBox'
import {HostawayForm} from '../../../pages/add-property-pms/Forms/HostawayForm'
import {
  HostawayRequest,
  StayntouchRequest,
  GuestyRequestV2,
  MyVrRequest,
  MewsRequest,
} from '../../../network/PostRequestModels/PropertyFromPMS'
import {GuestyForm} from '../../../pages/add-property-pms/Forms/GuestyForm'
import {Mews} from '../../../pages/add-property-pms/Forms/Mews'
import {MyVrForm} from '../../../pages/add-property-pms/Forms/MyVrForm'
import {StayntouchForm} from '../../../pages/add-property-pms/Forms/StayntouchForm'
import {useDialog} from '../../../contexts/DialogContext'
const debounce = require('debounce')

type Props = {
  hostPropertyAutomation: IHostPropertyAutomation
  hostPropertyICals?: IHostPropertyICal[]
  pmsReferenceId?: string
  handleChangeHostProperty: (changedValues: IHostPropertyInput) => void
  sameDayBooking?: boolean
  nextStep?: () => void
  previousStep?: () => void
  goToStep?: (step: number) => void
  currentStep?: number
  type: 'pms' | 'manual'
  editMode?: boolean
  missingField?: boolean
}

const AutomationStep: React.FC<Props> = ({
  type,
  missingField,
  goToStep,
  currentStep,
  hostPropertyAutomation,
  handleChangeHostProperty,
  nextStep,
  previousStep,
  hostPropertyICals,
  editMode,
  sameDayBooking,
}) => {
  let {propertyId}: any = useParams()
  propertyId = Number(decryptText(propertyId))

  const {user} = useSelector((state: RedusxAppState) => state.user)

  const [selectedPropertyAutomation, setSelectedPropertyAutomation] =
    useState<IHostPropertyAutomation>(
      hostPropertyAutomation
        ? hostPropertyAutomation
        : {
            isAutomaticCalendarSync: true,
            isAutomaticPayment: false,
            isAutomaticSchedule: true,
            usePMS: false,
            checkInTime: '15:00',
            checkOutTime: '11:00',
          }
    )

  const [selectedICalUrls, setSelectedICalUrls] = useState<IHostPropertyICal[]>(
    hostPropertyICals ? (hostPropertyICals.length > 0 ? hostPropertyICals : [{}]) : [{}]
  )
  const [hasSameDayBooking, setHasSameDayBooking] = useState<boolean>(
    sameDayBooking ? sameDayBooking : false
  )
  const [validTime, setValidTime] = useState<string | null>(null)
  const [icalErrorIndexes, setIcalErrorIndexes] = useState<number[]>([])
  const [deletedICals, setDeletedIcals] = useState<IHostPropertyICal[]>([])
  const [selectedICalUrlForDelete, setSelectedICalUrlForDelete] = useState<IHostPropertyICal>()
  const [showIcalDeleteModal, setShowIcalDeleteModal] = useState(false)
  const [pmsTypeSelectObj, setPmsTypeSelectObj] = useState<any>({})
  const [selectedPMSType, setSelectedPMSType] = useState(null)
  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyList, setPropertyList] = useState<any[]>([])
  const {showFailureDialog} = useDialog()

  // PMS Maping process states
  const [pmsMapping, setPmsMapping] = useState<boolean>(false)
  const [changeRefLoading, setChangeRefLoading] = useState<boolean>(false)
  const [selectedPMS, setSelectedPMS] = useState<IPMSProperty | null>(null)

  const {data, isLoading, error} = useQuery(
    ['Get Property Detail', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId),
    {cacheTime: 400000, enabled: propertyId ? true : false}
  )

  const {
    data: pmsTypesData,
    isLoading: pmsTypeLoading,
    error: pmsTypeError,
  } = useQuery('Get PMS Types', () => ApiCalls.getPMSTypes(), {
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    data: pmsKeyData,
    isLoading: pmsKeyLoading,
    isFetching: pmsKeyFetching,
    error: pmsKeyError,
  } = useQuery(
    ['Get PMS Keys', user.userSection.hostId, selectedPMSType],
    () =>
      ApiCalls.getPMSKeysByHostId(user.userSection.hostId, selectedPMSType ? selectedPMSType : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: selectedPMSType ? true : false}
  )

  useEffect(() => {
    if (pmsTypesData) {
      let selectObj: any = {}
      pmsTypesData?.data?.data
        ?.filter((type: any) => type.isActive)
        .forEach((type: any) => {
          let value = type.id
          let key = type.name
          selectObj[key] = value
        })
      setPmsTypeSelectObj(selectObj)
    }
  }, [pmsTypesData])

  const {
    data: pmsData,
    isLoading: pmsLoading,
    error: pmsError,
    refetch: pmsDataFetch,
  } = useQuery(
    ['Get Pms information of the Property', propertyId],
    () => {
      switch (data?.data?.pmsTypeId) {
        case PMSTypeName.Guesty:
          //return ApiCalls.getPropertiesFromGuesty({ token: data?.data?.configJSON.token, hostId: user?.userSection?.hostId, pmsTypeId: data?.data?.pmsTypeId })
          return ApiCalls.getPropertiesFromGuesty({
            client_id: data?.data?.guestyConfig.client_id,
            client_secret: data?.data?.guestyConfig.client_secret,
            hostId: user?.userSection?.hostId,
            pmsTypeId: data?.data?.pmsTypeId,
          })
        case PMSTypeName.Hostaway:
          return ApiCalls.getPropertiesFromHostify({
            client_id: data?.data?.configJSON.client_id,
            client_secret: data?.data?.configJSON.client_secret,
            hostId: user?.userSection?.hostId,
            pmsTypeId: data?.data?.pmsTypeId,
          })
        case PMSTypeName.MyVr:
          return ApiCalls.getPropertiesFromMyVr({
            token: data?.data?.configJSON.token,
            hostId: user?.userSection?.hostId,
            pmsTypeId: data?.data?.pmsTypeId,
          })
        case PMSTypeName.Stayntouch:
        //
        default:
          break
      }
    },
    {
      cacheTime: 500000,
      enabled: (data?.data?.configJSON?.token && pmsMapping) || selectedPMSType ? true : false,
    }
  )

  const fetchPropertyList = async (data: any) => {
    setPropertyLoading(true)
    setPropertyList([])
    try {
      let totalProperty = 0
      let requestData: any = {}
      //  !!! this logic does not belong here move it  !!!
      if (selectedPMSType === pmsTypeSelectObj['Hostaway']) {
        const hostawayRequest: HostawayRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromHostify(hostawayRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = hostawayRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Stayntouch']) {
        const stayntouchRequest: StayntouchRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromStayntouch(stayntouchRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = stayntouchRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Guesty']) {
        const guestyRequest: GuestyRequestV2 = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromGuesty(guestyRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = guestyRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['MyVR']) {
        const myVrRequest: MyVrRequest = {
          token: data.token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMyVr(myVrRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = myVrRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Mews']) {
        const mewsRequest: MewsRequest = {
          client_token: data.client_token,
          access_token: data.access_token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMews(mewsRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = mewsRequest
      }
      setPropertyLoading(false)
    } catch (err: any) {
      setPropertyLoading(false)
      setPropertyList([])
      showFailureDialog('Please check the required fields again.')
      return err?.response?.data?.message
    }
  }

  useEffect(() => {
    if (data?.data?.pmsTypeId) {
      pmsDataFetch()
    }
  }, [data])

  const changeSelectedPms = (data: IPMSProperty) => {
    setSelectedPMS(data)
  }

  const changeScheduling = (check: boolean) => {
    setSelectedPropertyAutomation({
      ...selectedPropertyAutomation,
      isAutomaticSchedule: check,
      isAutomaticCalendarSync: check,
    })
  }

  const changePayments = (check: boolean) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, isAutomaticPayment: check})
  }

  const changeCalendarSync = (check: boolean) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, isAutomaticCalendarSync: check})
  }

  const changeCheckoutTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkOutTime: event})
  }

  const changeCheckinTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkInTime: event})
  }

  const checkICalValid = (value: string, changeIndex: number) => {
    ApiCalls.validateICal(value)
      .then((res) => {
        if (res.data) {
          setIcalErrorIndexes(icalErrorIndexes.filter((index) => index !== changeIndex))
        } else {
          setIcalErrorIndexes([...icalErrorIndexes, changeIndex])
        }
      })
      .catch((err) => setIcalErrorIndexes([...icalErrorIndexes, changeIndex]))
  }
  const checkICalValidDebounce = debounce(checkICalValid, 600)

  const changeICalUrl = (value: string, changeIndex: number) => {
    setSelectedICalUrls(
      selectedICalUrls.map((iCal, index) =>
        index === changeIndex ? {...iCal, icalUrl: value} : iCal
      )
    )
    checkICalValidDebounce(value, changeIndex)
  }

  const addNewICalUrl = () => {
    setSelectedICalUrls([...selectedICalUrls, {icalUrl: ''}])
  }

  const editPmsReference = async () => {
    if (selectedPMS) {
      try {
        let reference = selectedPMS?.pmsReferenceId.toString()
        setChangeRefLoading(true)
        await ApiCalls.putPropertyPmsReference({pmsReferenceId: reference}, propertyId)
        setChangeRefLoading(false)
        setPmsMapping(false)
      } catch (error: any) {
        setChangeRefLoading(false)
      }
    }
  }

  const deleteICalUrl = () => {
    var iCalx = selectedICalUrlForDelete
    const deletedList = deletedICals

    selectedICalUrls.map((iCal, index) => {
      if (iCal.id === iCalx?.id) {
        deletedList.push({...iCal, isDeleted: true})
      }
    })
    setDeletedIcals(deletedList)
    let newArray: any = selectedICalUrls.filter((iCal, index) => iCal.id !== iCalx?.id)
    setSelectedICalUrls(newArray.length > 0 ? newArray : [{icalUrl: ''}])
  }

  useEffect(() => {
    handleChangeHostProperty({
      sameDayBooking: hasSameDayBooking,
      hostPropertyAutomation: selectedPropertyAutomation,
      hostPropertyICals: selectedPropertyAutomation.isAutomaticCalendarSync
        ? [...selectedICalUrls, ...deletedICals]
        : [],
    })
  }, [selectedPropertyAutomation, selectedICalUrls, hasSameDayBooking])

  useEffect(() => {
    if (hasSameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          selectedPropertyAutomation?.checkInTime
            ? parseInt(selectedPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          selectedPropertyAutomation?.checkInTime
            ? parseInt(selectedPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          selectedPropertyAutomation?.checkOutTime
            ? parseInt(selectedPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          selectedPropertyAutomation?.checkOutTime
            ? parseInt(selectedPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        setValidTime(
          `Check-in Time cannot be earlier then Check-out Time. (${
            selectedPropertyAutomation.checkOutTime ?? '11:00 AM'
          })`
        )
      } else {
        setValidTime(null)
      }
    } else {
      setValidTime(null)
    }
  }, [selectedPropertyAutomation, hasSameDayBooking])

  return (
    <>
      <form className='form w-100 mt-5'>
        {/* begin::Form group */}
        <div className='row'>
          <div className='col-lg-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Same Day Booking</label>
            <SwitchBox
              mutedText
              defaultChecked={hasSameDayBooking}
              text='When enabled, Check-in Time cannot be earlier then Check-out Time.'
              onCheckboxChange={setHasSameDayBooking}
            />

            <label className='form-label fs-6 fw-bolder text-dark mt-5'>Automatic Scheduling</label>
            <SwitchBox
              mutedText
              defaultChecked={
                selectedPropertyAutomation.isAutomaticSchedule !== undefined
                  ? selectedPropertyAutomation.isAutomaticSchedule
                  : true
              }
              text='When enabled, cleaners will be automatically sent cleaning requests for all of your checkouts.'
              onCheckboxChange={(e) => {
                changeScheduling(e)
              }}
            />
            {/*<label className='form-label fs-6 fw-bolder text-dark mt-5'>Automatic Payments</label>
                    <SwitchBox mutedText text="When enabled, payment will be taken automatically from your credit card." defaultChecked={selectedPropertyAutomation.isAutomaticPayment || false} onCheckboxChange={changePayments} />*/}

            {/* <label className='form-label fs-6 fw-bolder text-dark mt-5'>Automatic Calendar Sync</label>
                    <SwitchBox mutedText defaultChecked={selectedPropertyAutomation.isAutomaticCalendarSync !== undefined ? selectedPropertyAutomation.isAutomaticCalendarSync : true} text="When enabled, all of your checkouts will be pulled from your ical link and shown in your app. If disabled, all of the cleanings must be added manually." onCheckboxChange={changeCalendarSync} /> */}

            {!selectedPropertyAutomation?.usePMS &&
            selectedPropertyAutomation.isAutomaticCalendarSync
              ? selectedICalUrls.map((iCal, index, arr) => {
                  return iCal.isDeleted ? null : (
                    <>
                      <div className='row'>
                        <strong className='col-12 mb-3 mt-3'>
                          ICal URL #{index + 1} {<span style={{color: 'red'}}>*</span>}
                        </strong>
                        <div className='col-11'>
                          <input
                            value={iCal.icalUrl}
                            defaultValue={iCal.icalUrl}
                            onChange={(e: any) => {
                              changeICalUrl(e.target.value, index)
                            }}
                            className={'form-control '}
                            type={'text'}
                            autoComplete='off'
                            required
                          />
                          {missingField && (iCal.icalUrl === '' || !iCal.icalUrl) && (
                            <small className='text-danger'>
                              Missing Field
                              <br />
                            </small>
                          )}
                          {icalErrorIndexes.includes(index) && (
                            <small className='text-danger'>ICal is not valid!</small>
                          )}
                        </div>
                        {
                          <DeleteButton
                            deleteFn={() => {
                              setSelectedICalUrlForDelete(iCal)
                              setShowIcalDeleteModal(true)
                            }}
                            classNames='col-1 btn btn-link text-danger'
                          />
                        }
                        {index === arr.length - 1 ? (
                          <Button
                            style={{maxWidth: 1030}}
                            disabled={iCal.icalUrl === '' || !iCal.icalUrl}
                            type='button'
                            className='btn-success col-12 btn-sm m-2'
                            onClick={() => addNewICalUrl()}
                          >
                            Add new ICal
                          </Button>
                        ) : null}
                      </div>
                    </>
                  )
                })
              : null}
            <div>
              <Button
                onClick={() => {
                  setPmsMapping((prevState) => !prevState)
                }}
                className='btn-sm col-12 my-5'
              >
                PMS Mapping
              </Button>
              <small className='text-muted p-0'>
                These operations will take some time to complete, please wait.
              </small>
            </div>
          </div>

          <div className='d-flex flex-column col-lg-6'>
            <strong>Please select the check-in / check-out times of your property.</strong>
            <small className='text-muted'>
              Assignments will be made based on the times you have chosen while creating the
              cleaning schedule for your property.
            </small>
            <div className='row'>
              <div className='w-50'>
                {' '}
                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  Check-out Time {<span style={{color: 'red'}}>*</span>}
                </label>
                <TimePicker
                  onChange={changeCheckoutTime}
                  defaultValue={selectedPropertyAutomation.checkOutTime ?? '11:00'}
                />
              </div>
              <div className='w-50'>
                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  Check-in Time {<span style={{color: 'red'}}>*</span>}
                </label>
                <TimePicker
                  onChange={changeCheckinTime}
                  defaultValue={selectedPropertyAutomation.checkInTime ?? '15:00'}
                />
                {validTime && <strong className='text-danger'>{validTime}</strong>}
              </div>
            </div>
          </div>
          <div className='col-lg-12 p-5'>
            {pmsMapping && (
              <>
                {data?.data?.pmsReferenceId && (
                  <>
                    <div className='col-6 row mt-5'>
                      <div className='col-6'>
                        <input
                          disabled
                          className='form-control'
                          value={data?.data?.pmsReferenceId}
                        />
                      </div>
                      {/* <div className='col-6'>
                        <input
                          className='form-control'
                          disabled
                          value={selectedPMS?.pmsReferenceId ?? ''}
                        />
                      </div> */}
                    </div>
                    {pmsLoading ? (
                      <SkeletonTableLoading />
                    ) : (
                      <>
                        <PropertyTable
                          selectType='single'
                          data={pmsData?.data?.data}
                          onSelectionChanged={(arg) =>
                            changeSelectedPms(arg?.selectedRowsData?.[0])
                          }
                        />
                        <div className='d-flex w-100 justify-content-end'>
                          <Button
                            disabled={changeRefLoading}
                            onClick={editPmsReference}
                            className='btn-sm'
                          >
                            Reference Transfer{' '}
                            {changeRefLoading && <Spinner animation='border' size='sm' />}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!data?.data?.pmsReferenceId && (
                  <>
                    <div className='col-6 row'>
                      <div className='col-6'>
                        <SelectBox
                          searchBoxObj={pmsTypeSelectObj}
                          onValueChanged={setSelectedPMSType}
                          disabledText='Select a PMS Type'
                        />
                        {selectedPMSType === pmsTypeSelectObj['Hostaway'] ? (
                          pmsKeyFetching ? (
                            <div>Loading...</div>
                          ) : (
                            <HostawayForm
                              keys={pmsKeyData?.data?.data[0]}
                              fetchPropertyList={fetchPropertyList}
                              propertyLoading={propertyLoading}
                            />
                          )
                        ) : null}
                        {selectedPMSType === pmsTypeSelectObj['Guesty'] ? (
                          pmsKeyFetching ? (
                            <div>Loading...</div>
                          ) : (
                            <GuestyForm
                              keys={pmsKeyData?.data?.data[0]}
                              fetchPropertyList={fetchPropertyList}
                              propertyLoading={propertyLoading}
                            />
                          )
                        ) : null}
                        {selectedPMSType === pmsTypeSelectObj['MyVR'] ? (
                          pmsKeyFetching ? (
                            <div>Loading...</div>
                          ) : (
                            <MyVrForm
                              keys={pmsKeyData?.data?.data[0]}
                              fetchPropertyList={fetchPropertyList}
                              propertyLoading={propertyLoading}
                            />
                          )
                        ) : null}
                        {selectedPMSType === pmsTypeSelectObj['Stayntouch'] ? (
                          pmsKeyFetching ? (
                            <div>Loading...</div>
                          ) : (
                            <StayntouchForm
                              keys={pmsKeyData?.data?.data[0]}
                              fetchPropertyList={fetchPropertyList}
                              propertyLoading={propertyLoading}
                            />
                          )
                        ) : null}
                        {selectedPMSType === pmsTypeSelectObj['Mews'] ? (
                          pmsKeyFetching ? (
                            <div>Loading...</div>
                          ) : (
                            <Mews
                              keys={pmsKeyData?.data?.data[0]}
                              fetchPropertyList={fetchPropertyList}
                              propertyLoading={propertyLoading}
                            />
                          )
                        ) : null}
                      </div>
                    </div>
                    {propertyLoading ? (
                      <SkeletonTableLoading />
                    ) : (
                      <>
                        <PropertyTable
                          selectType='single'
                          data={propertyList}
                          onSelectionChanged={(arg) =>
                            changeSelectedPms(arg?.selectedRowsData?.[0])
                          }
                          onlyList
                        />
                        {propertyList.length > 0 && (
                          <div className='d-flex w-100 justify-content-end'>
                            <Button
                              disabled={changeRefLoading}
                              onClick={editPmsReference}
                              className='btn-sm'
                            >
                              Reference Transfer{' '}
                              {changeRefLoading && <Spinner animation='border' size='sm' />}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {editMode ? null : (
          <div className='d-flex justify-content-between flex-row w-100 mt-6'>
            <ArrowButton type='back' func={previousStep} />
            <ArrowButton type='forward' func={nextStep} />
          </div>
        )}
      </form>
      <Modal
        show={showIcalDeleteModal}
        onHide={() => {
          setShowIcalDeleteModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <b>If you delete this iCal URL, the jobs created for this calendar will be cancelled.</b>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-link text-dark me-3'
            onClick={() => {
              setShowIcalDeleteModal(false)
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-danger me-3'
            onClick={() => {
              deleteICalUrl()
              setShowIcalDeleteModal(false)
            }}
          >
            Yes, delete iCal
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AutomationStep
