import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {decryptText, onExporting} from '../../../utils/util'
import {useParams} from 'react-router-dom'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import EmptyTable from '../../Icons/EmptyTable'

const ReportedIssuesStep = () => {
  let {propertyId}: any = useParams()
  propertyId = propertyId ? Number(decryptText(propertyId)) : undefined

  const {data, isLoading, error} = useQuery(
    ["Get Property's Issue", propertyId],
    () => ApiCalls.getAllJobIssueByProperty(propertyId),
    {cacheTime: 50000}
  )

  const renderFullName = (cellValue: any) => {
    return <span>{cellValue?.data?.reporter?.firstName + cellValue?.data?.reporter?.lastName}</span>
  }
  const renderStatus = (cellValue: any) => {
    switch (cellValue?.data.jobIssueStatusTypeId) {
      case 1:
        return <span className='text-warning'>{cellValue?.data?.jobIssueStatusTypeName}</span>
      case 2:
        return <span className='text-success'>{cellValue?.data?.jobIssueStatusTypeName}</span>
      case 3:
        return <span className='text-danger'>{cellValue?.data?.jobIssueStatusTypeName}</span>
      default:
        return <span>Unknown</span>
    }
  }
  if (isLoading) {
    return <SkeletonTableLoading />
  }
  if (data?.data?.count == 0) {
    return (
      <>
        <div className='mt-5 mb-5'>
          <span className='text-danger'>
            This tab displays all the issues reported by the cleaners during their cleaning visits
            to your property. This feature allows you to stay on top of any recurring or new issues
            that may require your attention. You can view the date the issue was reported, the
            location of the issue, and a brief description provided by the cleaner. By addressing
            these issues promptly, you can ensure that your property stays in excellent condition
            and your guests have a comfortable stay.
          </span>
        </div>
        <EmptyTable title='No issues reported at this time.' />
      </>
    )
  }
  return (
    <>
      <div className='mt-5 mb-5'>
        <span className='text-danger'>
          This tab displays all the issues reported by the cleaners during their cleaning visits to
          your property. This feature allows you to stay on top of any recurring or new issues that
          may require your attention. You can view the date the issue was reported, the location of
          the issue, and a brief description provided by the cleaner. By addressing these issues
          promptly, you can ensure that your property stays in excellent condition and your guests
          have a comfortable stay.
        </span>
      </div>
      <DataGrid
        id='hosts'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(71vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onExporting={(e) => onExporting(e, 'Hosts')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Hosts: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column dataField='note' caption='Note' minWidth={300} dataType='string' cssClass='cls' />
        <Column
          dataField='jobIssueStatusTypeName'
          caption='Status'
          cellRender={renderStatus}
          minWidth={200}
          dataType='string'
          cssClass='cls'
        />
        <Column dataField='hostName' minWidth={250} dataType='string' cssClass='cls' />
        <Column
          dataField='jobIssueTypeName'
          caption='Issue Name'
          width={180}
          dataType='string'
          cssClass='cls'
        />

        <Column
          dataField='reporter.reportOn'
          caption='Reported On'
          width={180}
          dataType='datetime'
          cssClass='cls'
        />
        <Column
          dataType='string'
          caption='reporter name'
          cellRender={renderFullName}
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
      </DataGrid>
    </>
  )
}

export default ReportedIssuesStep
