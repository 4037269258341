import React, { Dispatch } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ChecklistAction } from '../../../../redux/actionTypes/checklistTypes';
import * as checklistActionCreators from '../../../../redux/actionCreators/checklistActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import QuestionEditForm from '../../../../umut-components/Forms/QuestionEditForm';
import { decryptText } from '../../../../utils/util';

type Props = {
    propertyDetailData: any
}

const QuestionEditDialog: React.FC<Props> = ({ propertyDetailData }) => {
    let { checklistId }: any = useParams();
    checklistId = decryptText(checklistId);
    const { questionEditDialog } = useSelector((state: RedusxAppState) => state.checklist);
    const cleaningProviderName = propertyDetailData?.data?.hostProperty?.cleaningProviderName
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();

    const closeModal = () => { dispatch(checklistActionCreators.closeQuestionEditDialog()) }

    return (
        <Modal show={questionEditDialog.dialogOpen} onHide={closeModal} size='lg' centered>
            <ModalHeader closeButton>
                <Modal.Title>Update Checklist Item</Modal.Title>
            </ModalHeader>
            <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
                <QuestionEditForm cleaningProviderName={cleaningProviderName} checklistId={checklistId} question={questionEditDialog.selectedQuestion} />
            </Modal.Body>
        </Modal>
    )
}

export default QuestionEditDialog;