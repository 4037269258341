import { IUser } from "../../interfaces/User";
import * as actions from "../actionTypes/userActionTypes";

export interface UserState {
    token: string;
    user?: IUser;
    loginError: boolean;
    isAuthenticated: boolean;
    errorText: "Email or Password Wrong" | "Login Expired" | "";
    loading: boolean;
}

const initialState: UserState = {
    token: "",
    loginError: false,
    isAuthenticated: false,
    errorText: "",
    loading: false
}

export default function userReducer(
    state: UserState = initialState,
    action: actions.UserAction
) {
    switch (action.type) {
        case actions.LOGIN:
            return { ...state, loading: true, loginError: false, errorText: "" }
        case actions.LOGIN_SUCCESS:
            return { ...state, loginError: false, isAuthenticated: true, errorText: "", token: action.token }
        case actions.LOGIN_FAILURE:
            return { ...state, loginError: true, isAuthenticated: false, errorText: action.error ? action.error : 'Email or Password Wrong', token: "", loading: false }
        case actions.GET_USER_DETAILS:
            return { ...state, loginError: false, errorText: "", loading: true }
        case actions.GET_USER_INFO_SUCCESS:
            return { ...state, loginError: false, isAuthenticated: true, user: action.user, errorText: "", loading: false }
        case actions.GET_USER_INFO_FAILURE:
            return { ...state, loginError: true, isAuthenticated: false, user: null, errorText: "Login Expired", loading: false, token: "" }
        case actions.CLEAR_LOADING:
            return { ...state, loading: false }
        case actions.LOGOUT:
            return { ...state, isAuthenticated: false, user: null, loading: false, token: "" }
        default:
            return state;
    }
}