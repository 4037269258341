import React, { Dispatch } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ApiCalls from '../../network/ApiCalls';
import { getStaffDetail } from '../../redux/actionCreators/staffActionCreators';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import StaffEditForm from '../../umut-components/Forms/StaffEditForm';
import UserEditForm from '../../umut-components/Forms/UserEditForm';
import { decryptText } from '../../utils/util';

type Props = {
    modalOpen: boolean
    modalClose: (arg: boolean) => void
}

const StaffEditDialog: React.FC<Props> = ({ modalOpen, modalClose }) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const { data: userDetailData, isLoading: userDetailLoading, error: isUserDetailError } = useQuery(['Get Staff Detail', staffDetail?.staff?.id], () => ApiCalls.getStaffDetails(staffDetail?.staff?.id), { refetchOnWindowFocus: false })

    const closeModal = () => {
        modalClose(false)
    }

    const successFunc = () => {
        staffDetail.staff && dispatch(getStaffDetail(staffDetail.staff?.id));
        modalClose(false);
    }

    return (
        <Modal show={modalOpen} onHide={closeModal} size='xl' centered>
            <ModalHeader closeButton>
                <Modal.Title>Edit Staff</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {userDetailLoading ? 'Loading...' : <StaffEditForm selectStatusActive={true} user={userDetailData?.data} userId={staffDetail?.staff?.id} successFunc={successFunc} />}
            </Modal.Body>
        </Modal>
    )
}

export default StaffEditDialog;