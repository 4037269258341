import React, { useEffect, useState } from 'react'

interface INumberInput {
    onValueChange: (number: number) => void,
    placeholder?: string,
    defaultValue?: string | number,
    classNames?: string,
    min?: number,
    max?: number,
    disabled?: boolean,
    isErrorMessageActive?: boolean
}
export const NumberInput: React.FC<INumberInput> = ({ onValueChange, placeholder, defaultValue, classNames, min, max, disabled, isErrorMessageActive }) => {
    const [textValue, setTextValue] = useState<string | number | undefined>(defaultValue)

    const textValueChange = (event: any) => {
        let newValue = parseInt(event.target.value);
        setTextValue(newValue);
        onValueChange(newValue);
    }

    useEffect(() => {
        if (defaultValue) {
            setTextValue(defaultValue);
        }
    }, [defaultValue])

    return (
        <div>
            <input
                value={textValue}
                onChange={textValueChange}
                min={min}
                max={max}
                placeholder={placeholder}
                className={'form-control ' + classNames}
                type='number'
                autoComplete='off'
                disabled={disabled}
                required
            />
            {isErrorMessageActive && min !== undefined && textValue !== undefined && min > textValue && <small className='text-danger d-flex'>Must greater than {min}</small>}
            {isErrorMessageActive && max !== undefined && textValue !== undefined && max < textValue && <small className='text-danger d-flex'>Must smaller than {max}</small>}
        </div>
    )
};
