import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'
import AssignedProperties from './AssignedProperties'
import AddPropertyDialog from '../AddPropertyDialog'
import ExcludedProperties from './ExcludedProperties'

type Props = {
  staff: IStaff
}

const Properties: React.FC<Props> = ({staff}) => {
  return (
    <div className='row'>
      <div className='col-xl-12 col-12 mb-3'>
        <AssignedProperties staff={staff} />
      </div>
      <div className='w-100 border-bottom border-gray-300'></div>
      <div className='col-xl-12 col-12 mb-3'>
        <ExcludedProperties staff={staff} />
      </div>
      <AddPropertyDialog staff={staff} />
    </div>
  )
}

export default Properties
