import React, {Dispatch, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {selectStyles} from '../../config/selectStyle'
import {useDialog} from '../../contexts/DialogContext'
import {ILocation, IStaffAddress} from '../../interfaces/Address'
import {IStaff} from '../../interfaces/Staff'
import ApiCalls from '../../network/ApiCalls'
import {StaffAddRequest} from '../../network/PostRequestModels'
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators'
import {StaffAction} from '../../redux/actionTypes/staffActionTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {getLocationByAddress, getLocationByAddressForStaff} from '../../utils/util'
import AutocompleteMap from '../Inputs/AutocompleteMap'
import PhoneTextInput from '../Inputs/PhoneInput'
import {TextInput} from '../Inputs/TextInput'

type Props = {
  staff: IStaff | null
}

const StaffForm: React.FC<Props> = ({staff}) => {
  const {showSuccessDialog} = useDialog()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [staffRequest, setStaffRequest] = useState<StaffAddRequest>({} as StaffAddRequest)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [roles, setRoles] = useState([])
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({address: ' '})
  const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
  const [text, setText] = useState<string>('')

  const {
    data: roleTypesData,
    isLoading: roleTypesLoading,
    error: roleTypesError,
  } = useQuery('Get Roles', () => ApiCalls.getStaffRoles(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const handleSelectAddress = (e: string) => {
    setSelectedLocation({...selectedLocation, address: e})
    setText(e)
  }
  const handleSendStaff = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      if (staffRequest.phoneNumber && fullLocation) {
        const finalRequest: StaffAddRequest = {
          ...staffRequest,
          userAddressCreateDto: {...fullLocation, userAddressTypeId: 2},
          phoneNumber: staffRequest.phoneNumber,
          hostId: user.userSection.hostId,
        }
        const {email, firstName, lastName, hostId, phoneNumber, roleId} = finalRequest
        if (
          email &&
          firstName &&
          lastName &&
          hostId &&
          phoneNumber &&
          roleId &&
          !emailErrorMessage &&
          fullLocation
        ) {
          await ApiCalls.createStaff(finalRequest)
          dispatch(staffActionCreator.closeStaffAddDialog())
          dispatch(staffActionCreator.getStaffList(user.userSection.hostId))
          setLoading(false)
          showSuccessDialog('Staff ' + (staff ? 'Updated.' : ' Invited.'))
        } else {
          setLoading(false)
          setErrorMessage('All fields are required.')
        }
      } else {
        setLoading(false)
        setErrorMessage('Phone number is not valid!')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  const onEmailChange = (email: string) => {
    let pattern = /^\S+@\S+\.\S+$/
    if (pattern.test(email)) {
      setEmailErrorMessage(null)
    } else {
      setEmailErrorMessage('Please enter valid email.')
    }
    setStaffRequest({...staffRequest, email})
  }

  const onPhoneChange = (value: string) => {
    setStaffRequest({...staffRequest, phoneNumber: value})
  }

  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }

  useEffect(() => {
    getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  useEffect(() => {
    if (roleTypesData) {
      setRoles(
        roleTypesData?.data
          ?.filter(
            (role: any) =>
              role.lobRoleName === 'Host' &&
              role.roleName !== 'Admin' &&
              role.roleName !== 'Manager'
          )
          .map((role: any) => ({label: role.roleName, value: role.roleId}))
      )
    }
  }, [roleTypesData])

  if (roleTypesLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Email {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput onValueChange={onEmailChange} defaultValue={staffRequest.email} />
          <strong className='d-flex fw-bold break-word text-danger'>{emailErrorMessage}</strong>

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            First Name {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            onValueChange={(text: string) => setStaffRequest({...staffRequest, firstName: text})}
            defaultValue={staffRequest.firstName}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Last Name {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            onValueChange={(text: string) => setStaffRequest({...staffRequest, lastName: text})}
            defaultValue={staffRequest.lastName}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            City {<span style={{color: 'red'}}>*</span>}
          </label>
          <AutocompleteMap
            text={text}
            setText={setText}
            handleSelectAddress={handleSelectAddress}
            handleOnError={handleAutocompleteError}
          />
          {!selectedLocation.address ||
            (fullLocation?.zipCode == '' && (
              <>
                <strong className='text-danger fw-6'>Please enter a valid address</strong>
                <br />
              </>
            ))}

          <div className='row mt-5'>
            <div className='fv-row col-12'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Phone Number {<span style={{color: 'red'}}>*</span>}
              </label>
              <PhoneTextInput onChange={onPhoneChange} />
            </div>
          </div>
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Select Role {<span style={{color: 'red'}}>*</span>}
          </label>
          <Select
            styles={selectStyles}
            defaultValue={{value: staffRequest.roleId}}
            options={roles}
            onChange={(value) =>
              setStaffRequest({...staffRequest, roleId: value ? value.value : null})
            }
          />

          <div className='row mt-6'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleSendStaff}
              >
                {staff ? 'Edit' : 'Add'} Staff{' '}
                {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(StaffForm)
