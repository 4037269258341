import { AxiosResponse } from "axios";
import { Modal } from "react-bootstrap-v5"
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader"
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import ApiCalls from "../../network/ApiCalls";
import { IForceFinishResponse } from "../../network/NetworkResponses/NetworkResponses";
import * as finishRequestActionCreators from '../../redux/actionCreators/finishRequestCreator';
import { ForceFinishActions } from "../../redux/actionTypes/finishRequestTypes"
import { RedusxAppState } from "../../redux/reducers/rootReducer";

const ForceFinishModal = () => {
    const {modalOpen, forceFinish} = useSelector((state: RedusxAppState) => state.forceFinish);

    const dispatch = useDispatch<Dispatch<ForceFinishActions>>()
  
    const handleClose = () => {
        dispatch(finishRequestActionCreators.closeForceFinishView())
    }

    return (
        <Modal show={modalOpen}  onHide={handleClose} size='lg' centered closeButton>
            <ModalHeader closeButton>
                <Modal.Title>Request Detail</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <div className='row gap-5 justify-content-center'>
                    <div className='col-lg-5 col-12 d-flex flex-column '>
                        <strong className='text-primary'>Sample Photo</strong>
                        <div className=' shadow-sm'>
                            <img
                                alt='question-sample'
                                className='w-100'
                                src={forceFinish?.medias?.[0].mediaPath ? forceFinish?.medias[0].mediaPath : "/media/illustrations/free-trial.png"}
                                style={{ height: 400, objectFit: 'contain' }}
                            />
                        </div>
                    </div>
                    <div className="d-flex bg-light-info rounded min-h-50px border-top justify-content-center">
                        <strong className="fw-bolder mt-5 ml-5">{forceFinish?.forceReason}</strong>
                    </div>
                </div>
                <div className='row'></div>
            </Modal.Body>

        </Modal>
    )
}

export default ForceFinishModal
