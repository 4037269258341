import {useEffect, useState} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import {Button, Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useParams} from 'react-router'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ApiCalls from '../../../network/ApiCalls'
import {BanRequest} from '../../../network/PostRequestModels/Permission'
import {decryptText, toAmazonUrl} from '../../../utils/util'
import EmptyTable from '../../Icons/EmptyTable'
import ProfilePhotoTemp from '../../Icons/ProfilePhotoTemp'

const grid = 5

const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: '100%',
})

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 10px 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
})

type Props = {
  providerType: number | undefined
}

const StaffPriorityStep: React.FC<Props> = ({providerType}) => {
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined

  const [loading, setLoading] = useState({idx: 0, boolean: false})
  const [saveLoading, setsaveLoading] = useState(false)
  const [orderStaffList, setOrderStaffList] = useState<any>([])
  const [requestList, setRequestList] = useState<any>([])
  const {data, isLoading, error, refetch} = useQuery(
    ['Get Accepted Staffs', propertyId],
    () =>
      providerType === 2
        ? ApiCalls.getAcceptedProperties(propertyId)
        : ApiCalls.getAcceptedMyProperties(propertyId),
    {cacheTime: 50000, enabled: propertyId ? true : false}
  )

  const handleExclude = async (userId: number, roleId: any, vendorId: number, idx: number) => {
    if (userId && roleId && propertyId)
      try {
        let excludesReuqest: BanRequest = {
          hostId: 1,
          hostPropertyId: Number(propertyId),
          description: '',
          userId: userId,
          roleId: roleId,
        }
        setLoading({idx: idx, boolean: true})
        await ApiCalls.banUserFromProperty(excludesReuqest)
        refetch()
        setLoading({idx: idx, boolean: false})
      } catch (error) {
        setLoading({idx: idx, boolean: false})
      }
  }
  const handleOrderChange = async () => {
    if (requestList) {
      try {
        setsaveLoading(true)
        await ApiCalls.putPrirorityProperty(requestList)
        setsaveLoading(false)
      } catch (error: any) {
        console.log(error?.response?.message?.data)
      }
    }
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index
    if (
      sourceIndex !== undefined &&
      destinationIndex !== undefined &&
      sourceIndex !== destinationIndex
    ) {
      const result = [...orderStaffList]
      const [removed] = result.splice(sourceIndex, 1)
      result.splice(destinationIndex, 0, removed)
      setOrderStaffList(result)
      setRequestList(
        result.map((item, index) => {
          return {id: item?.id, staffQueueIndex: index}
        })
      )
    }
  }

  useEffect(() => {
    if (data) {
      setOrderStaffList(data?.data?.data)
    }
  }, [data])
  return (
    <>
      <div className='mt-5 mb-5'>
        <span className='text-danger'>
          You can view a list of all cleaners who have permission to clean your property. You can
          easily prioritize the cleaners by dragging and dropping their names into the desired
          order. This will determine the sequence in which the cleaners are assigned to clean your
          property, based on their availability. The cleaner at the top of the list will be assigned
          to clean your property first, and if they are not available, the next cleaner on the list
          will be assigned. This feature allows you to optimize your cleaning schedule and ensure
          that your property is cleaned efficiently.
        </span>
      </div>
      <Card className='min-h-500px'>
        <Card.Body>
          {orderStaffList.length == 0 ? (
            <EmptyTable title='There are no staff assigned to this property yet. Please go to the Staff Access tab and add staff to the property before prioritizing their order.' />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {orderStaffList?.map((staff: any, index: number) => (
                      <Draggable
                        key={staff?.userId}
                        draggableId={staff?.userId.toString()}
                        index={index}
                      >
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            className={`bg-${
                              snapshot.isDragging ? 'info' : 'light'
                            } shadow-sm border rounded d-flex flex-row justify-content-between align-items-center`}
                          >
                            <div className='d-flex justify-content-between w-100'>
                              <div className='d-flex justify-content-start align-items-center gap-5'>
                                <span className='fs-7 text-info'>{index + 1}.</span>
                                {staff?.profilePhotoPath ? (
                                  <img
                                    alt='pp'
                                    style={{width: 55, height: 55, borderRadius: '50%'}}
                                    src={
                                      staff?.profilePhotoPath
                                        ? toAmazonUrl(staff?.profilePhotoPath)
                                        : toAbsoluteUrl('/media/icons/noImage.png')
                                    }
                                  />
                                ) : (
                                  <ProfilePhotoTemp
                                    firstName={staff?.firstName}
                                    lastName={staff?.lastName}
                                    width={45}
                                    height={45}
                                    borderRadius='50%'
                                  />
                                )}
                                <div className='w-175px'>
                                  <div className='text-dark'>
                                    <strong>{staff?.firstName}</strong>&nbsp;
                                    <strong>{staff?.lastName}</strong>
                                  </div>
                                  <span className='text-muted'>{staff?.roleName}</span>
                                </div>

                                <div className='d-flex align-items-center w-250px d-none d-xl-block'>
                                  <span className='svg-icon svg-icon-primary svg-icon-2x'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      xmlnsXlink='http://www.w3.org/1999/xlink'
                                      width='24px'
                                      height='24px'
                                      viewBox='0 0 24 24'
                                      version='1.1'
                                    >
                                      <g
                                        stroke='none'
                                        stroke-width='1'
                                        fill='none'
                                        fill-rule='evenodd'
                                      >
                                        <rect x='0' y='0' width='24' height='24' />
                                        <path
                                          d='M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z'
                                          fill='#000000'
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <a
                                    href={`mailto:${staff?.email}`}
                                    className='fs-6 text-muted ms-2'
                                  >
                                    {staff?.email}
                                  </a>
                                </div>
                                <div className='d-flex w-175px align-items-center d-none d-lg-block'>
                                  <span className='svg-icon svg-icon-4 me-2 text-primary'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='16'
                                      height='16'
                                      fill='currentColor'
                                      className='bi bi-telephone-fill'
                                      viewBox='0 0 16 16'
                                    >
                                      <path
                                        fill-rule='evenodd'
                                        d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                                      />
                                    </svg>
                                  </span>
                                  <a href={`tel:${staff?.phoneNumber}`} className='fs-6 text-muted'>
                                    {staff?.phoneNumber}
                                  </a>
                                </div>
                                <p className='fs-5 fw-bold text-success d-none d-lg-block'>
                                  {staff?.vendorName}
                                </p>
                              </div>
                              <div className='d-flex align-items-center'>
                                <Button
                                  disabled={loading.boolean}
                                  onClick={() =>
                                    handleExclude(
                                      staff?.userId,
                                      staff?.roleId,
                                      staff?.vendorId,
                                      index
                                    )
                                  }
                                  variant={'danger'}
                                  className='btn-sm h-30px'
                                >
                                  Exclude{' '}
                                  {loading.idx == index && loading.boolean && (
                                    <Spinner size='sm' animation='border' />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <button disabled={saveLoading} className='btn btn-success' onClick={handleOrderChange}>
            Save Priorities {saveLoading ? <Spinner size='sm' animation='border' /> : null}
          </button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default StaffPriorityStep
