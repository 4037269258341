import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import ChecklistButton from '../../umut-components/Buttons/ChecklistButton'
import {
  encryptText,
  onExporting,
  toConvertedDate,
  getDateFilter,
  setDxFilterPlaceholder,
  toConvertedOnlyDate,
} from '../../utils/util'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import {useDispatch} from 'react-redux'
import {useDialog} from '../../contexts/DialogContext'
import {HostPropertyAction} from '../../redux/actionTypes/hostPropertyTypes'
import {OpenDeletePropertyModal} from '../../redux/actionCreators/hostPropertyActionCreators'
import config from '../../config/config.json'
import dayjs from 'dayjs'

type Props = {
  data: any
  refetch: () => void
}

const PropertyTable: React.FC<Props> = ({data, refetch}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const deleteDispatch = useDispatch<Dispatch<HostPropertyAction>>()

  const calculateNextCheckIn = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? toConvertedOnlyDate(cellValue?.nextCheckIn, cellValue?.timeZone)
      : '--'
  }

  const calculateNextCheckOut = (cellValue: any) => {
    return cellValue?.nextCheckOut
      ? toConvertedOnlyDate(cellValue?.nextCheckOut, cellValue?.timeZone)
      : '--'
  }

  const calculateLastCleanedOn = (cellValue: any) => {
    return cellValue?.lastCleanDate
      ? toConvertedOnlyDate(cellValue?.lastCleanDate, cellValue?.timeZone)
      : '--'
  }

  const toPropertyDetail = (cellValue: any) => {
    if (
      cellValue?.data?.id &&
      cellValue?.column?.name !== 'checklist-button' &&
      cellValue?.column?.name !== 'delete-button'
    ) {
      // history.push({
      //   pathname: `/properties/detail/` + encryptText(cellValue?.data?.id),
      // })
      window.open('/properties/detail/' + encryptText(cellValue?.data?.id), '_blank')
    }
  }

  const renderChecklistButton = (cellValue: any) => {
    return cellValue?.data?.hostPropertyStatusTypeName !== 'Property Fields Not Set' ? (
      <ChecklistButton
        iconWidth='20'
        iconHeight='20'
        viewFn={() => {
          history.push({pathname: '/checklists/' + encryptText(cellValue?.data?.id)})
        }}
      />
    ) : null
  }

  const calculatePropertyStatus = (cellValue: any) => {
    return (
      <strong
        className={`text-${
          cellValue?.data?.hostPropertyStatusTypeName === 'Property Fields Not Set'
            ? 'danger'
            : cellValue?.data?.hostPropertyStatusTypeName === 'Active'
            ? 'success'
            : cellValue?.data?.hostPropertyStatusTypeName === 'Declined'
            ? 'danger'
            : cellValue?.data?.isMarketplace
            ? 'success'
            : 'warning'
        }`}
      >
        {cellValue?.data?.hostPropertyStatusTypeName}
      </strong>
    )
  }

  const getCleaningProvider = (cellValue: any) => {
    return (
      <span>
        {cellValue.data.isMarketplace ? 'Marketplace' : cellValue.data.cleaningProviderName}
      </span>
    )
  }

  if (data?.length === 0) {
    return (
      <EmptyTable
        type='property'
        title="You don't have any property yet. Click on the bellow button to add your first property on Turnify."
      />
    )
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='propertyTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('properties-data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        onCellClick={toPropertyDetail}
        height={'calc(68vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Properties')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Properties: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column dataField='name' caption='Property' minWidth={400} cssClass='cls' />
        <Column dataField='cityName' caption='City' minWidth={150} cssClass='cls' />
        <Column dataField='stateName' caption='State' minWidth={150} cssClass='cls' />
        <Column dataField='countryName' caption='Country' minWidth={150} cssClass='cls' />

        <Column
          dataField='cleaningProviderName'
          caption='Provider'
          minWidth={150}
          cellRender={getCleaningProvider}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hostPropertyStatusTypeName'
          cellRender={calculatePropertyStatus}
          caption='Status'
          minWidth={300}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='defaultCheckInTime'
          caption='Next Check-In'
          width={180}
          calculateCellValue={calculateNextCheckIn}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('defaultCheckInTime')} />
        </Column>
        <Column
          dataField='defaultCheckOutTime'
          caption='Next Check-Out'
          width={180}
          calculateCellValue={calculateNextCheckOut}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('defaultCheckOutTime')} />
        </Column>

        <Column
          dataField='lastCleanDate'
          caption='Last Cleaned On'
          calculateCellValue={calculateLastCleanedOn}
          selectedFilterOperation='between'
          dataType='date'
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('lastCleanDate')} />
        </Column>
        {/* <Column width={100} cellRender={renderEditButton} cssClass='cls' />
        <Column
          name='checklist-button'
          width={100}
          cellRender={renderChecklistButton}
          cssClass='cls'
        />
        <Column name='delete-button' width={100} cellRender={renderDeleteButton} cssClass='cls' />*/}
      </DataGrid>
    </div>
  )
}

export default React.memo(PropertyTable)
