import { CountryCode } from 'libphonenumber-js/min';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import config from '../../config/config.json';

const countries: CountryCode[] = [...config.countries] as CountryCode[];

type Props = {
    defaultValue?: string,
    onChange: (number: string) => void,
}

const PhoneTextInput: React.FC<Props> = ({ defaultValue, onChange }) => {
    const [phoneNumber, setPhoneNumber] = useState<any>(defaultValue);


    const onPhoneChange = (a: any, b: any, c: any, d: any) => {
        setPhoneNumber(d);
        onChange(d);
    }

    return (
        <PhoneInput
            country="us"
            inputStyle={{ width: "100%", backgroundColor: "transparent" }}
            value={phoneNumber}
            onChange={onPhoneChange}
        />
    )
}

export default PhoneTextInput;
