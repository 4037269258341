import { IOfferedService, IStaff, IStaffDetail, IStaffOfferedService } from "../../interfaces/Staff";
import { StaffOfferedRequest } from "../../network/PostRequestModels";

export const GET_STAFF_LIST = "GET_STAFF_LIST";
export interface GetStaffList {
    type: typeof GET_STAFF_LIST,
    hostId: number
    roleId: number | undefined
}

export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export interface GetStaffListSuccess {
    type: typeof GET_STAFF_LIST_SUCCESS,
    staffList: IStaff[]
}

export const GET_STAFF_LIST_FAILURE = "GET_STAFF_LIST_FAILURE";
export interface GetStaffListFailure {
    type: typeof GET_STAFF_LIST_FAILURE,
    errorText: string
}

export const GET_STAFF_DETAIL = "GET_STAFF_DETAIL";
export interface GetStaffDetail {
    type: typeof GET_STAFF_DETAIL,
    userId: number
}

export const GET_STAFF_DETAIL_SUCCESS = "GET_STAFF_DETAIL_SUCCESS";
export interface GetStaffDetailSuccess {
    type: typeof GET_STAFF_DETAIL_SUCCESS,
    staff: IStaffDetail
}

export const GET_STAFF_DETAIL_FAILURE = "GET_STAFF_DETAIL_FAILURE";
export interface GetStaffDetailFailure {
    type: typeof GET_STAFF_DETAIL_FAILURE,
    errorText: string
}

export const OPEN_STAFF_ADD_DIALOG = "OPEN_STAFF_ADD_DIALOG";
export interface OpenStaffAddDialog {
    type: typeof OPEN_STAFF_ADD_DIALOG,
    staff: IStaff | null
}

export const CLOSE_STAFF_ADD_DIALOG = "CLOSE_STAFF_ADD_DIALOG";
export interface CloseStaffAddDialog {
    type: typeof CLOSE_STAFF_ADD_DIALOG,
}

export const OPEN_STAFF_OFFERED_ADD_DIALOG = "OPEN_STAFF_OFFERED_ADD_DIALOG";
export interface OpenStaffOfferedAddDialog {
    type: typeof OPEN_STAFF_OFFERED_ADD_DIALOG,
    refetch: () => void,
    staffOfferedService?: IOfferedService
}

export const CLOSE_STAFF_OFFERED_ADD_DIALOG = "CLOSE_STAFF_OFFERED_ADD_DIALOG";
export interface CloseStaffOfferedAddDialog {
    type: typeof CLOSE_STAFF_OFFERED_ADD_DIALOG,
}

export const OPEN_STAFF_PROPERTY_DIALOG = "OPEN_STAFF_PROPERTY_DIALOG";
export interface OpenStaffPropertyDialog {
    type: typeof OPEN_STAFF_PROPERTY_DIALOG,
    refetch: () => void,
    formType: "allowed" | "banned"
}

export const CLOSE_STAFF_PROPERTY_DIALOG = "CLOSE_STAFF_PROPERTY_DIALOG";
export interface CloseStaffPropertyDialog {
    type: typeof CLOSE_STAFF_PROPERTY_DIALOG,
}

export const OPEN_STAFF_PASSWORD_DIALOG = "OPEN_STAFF_PASSWORD_DIALOG";
export interface OpenStaffPasswordDialog {
    type: typeof OPEN_STAFF_PASSWORD_DIALOG,
    staffId: number | undefined
}

export const CLOSE_STAFF_PASSWORD_DIALOG = "CLOSE_STAFF_PASSWORD_DIALOG";
export interface CloseStaffPasswordDialog {
    type: typeof CLOSE_STAFF_PASSWORD_DIALOG,
}



export type StaffAction =
    | OpenStaffAddDialog
    | CloseStaffAddDialog
    | GetStaffList
    | GetStaffListSuccess
    | GetStaffListFailure
    | GetStaffDetail
    | GetStaffDetailSuccess
    | GetStaffDetailFailure
    | OpenStaffOfferedAddDialog
    | CloseStaffOfferedAddDialog
    | OpenStaffPropertyDialog
    | CloseStaffPropertyDialog
    | OpenStaffPasswordDialog
    | CloseStaffPasswordDialog