import React, {useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import HeaderWrapper from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import ApiCalls from '../../app/network/ApiCalls'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../app/redux/reducers/rootReducer'

const MasterLayout: React.FC = ({children}) => {
  const {hasPaymentCard} = useSelector((state: RedusxAppState) => state.onBoarding)

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          {!hasPaymentCard && (
            <div className='card-warning-bar'>
              <strong>
                Attention: Your jobs will not get auto-scheduled and the calendars will not get
                auto-synced until you add a valid payment method.
              </strong>
            </div>
          )}
          <AsideDefault hasCard={hasPaymentCard} />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper hasCard={hasPaymentCard} />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
