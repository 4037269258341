import React, {useEffect, useState, useRef, useMemo} from 'react'
import {useDialog} from '../../../contexts/DialogContext'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {IHostPropertyManualInput} from '../../../interfaces/Property'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {getDateFilter} from '../../../utils/util'
import TurnifyProFormTable from './ProQuestions/TurnifyProFormTable'
import './custom.css'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyManualInput[]) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
  savedValues: IHostPropertyManualInput[]
}

const booleanOptions = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
]

const bedroomNumOptions = [
  {
    label: 'Studio',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
]

const bathroomNumOptions = [
  {
    label: '0.5',
    value: 0.5,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '1.5',
    value: 1.5,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '2.5',
    value: 2.5,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '3.5',
    value: 3.5,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '4.5',
    value: 4.5,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '5.5',
    value: 5.5,
  },
  {
    label: '6',
    value: 6,
  },
]

const ServiceProviderStep: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  currentStep,
  savedValues,
}) => {
  const {showFailureDialog} = useDialog()
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)
  const [propertyList, setPropertyList] = useState<IHostPropertyManualInput[]>([])
  const [selectedProPropertyList, setSelectedProPropertyList] = useState<
    IHostPropertyManualInput[]
  >([])
  const tableRef = useRef<DataGrid>(null)

  useEffect(() => {
    var list = Object.keys(savedValues).map((k, i) => savedValues[i])
    setPropertyList(list)
  }, [savedValues])

  useEffect(() => {
    if (+currentStep == 2) {
      window.scrollTo(0, 0)
    }
  }, [currentStep])

  const onChangeProPropertyList = (data: IHostPropertyManualInput[]) => {
    setSelectedProPropertyList(data)
  }

  const onPropertySelect = async (data: any) => {
    var selectedlist = data?.selectedRowsData
    selectedlist.forEach((f: IHostPropertyManualInput) => {
      ;(f.hasProQuoteRequest = true), (f.hasPlusQuoteRequest = false)
    })

    setSelectedProPropertyList(selectedlist)
  }

  const controlPropertyProviderDetails = () => {
    var hasError = false
    propertyList.forEach((pl) => {
      if (pl.hasProQuoteRequest) {
        /*if(pl.hostPropertyAccess != undefined) {
                    if(pl.hostPropertyAccess!.unitAccessTypeId == 0 || pl.hostPropertyAccess?.unitAccessValue == "") {
                        hasError = true;
                    }
            
                    if(pl.hostPropertyAccess!.buildingAccessTypeId == 0 || pl.hostPropertyAccess?.buildingAccessValue == "") {
                        hasError = true;
                    }
                } else {
                    hasError = true;
                }
        
                if(pl.hostPropertyServiceProvider != undefined) {
                    if(pl.hostPropertyServiceProvider?.hasStorage && pl.hostPropertyServiceProvider?.storageDescription == '') {
                        hasError = true;
                    }
            
                    if(pl.hostPropertyServiceProvider?.hasParking && pl.hostPropertyServiceProvider?.parkingDescription == '') {
                        hasError = true;
                    }
            
                    if(pl.hostPropertyServiceProvider?.hasLaundry && pl.hostPropertyServiceProvider?.laundryInQuote && !pl.hostPropertyServiceProvider?.laundryToBeDone && pl.hostPropertyServiceProvider?.laundryLinenNumber == '') {
                        hasError = true;
                    }
            
                    if(pl.hostPropertyServiceProvider?.hasLaundry && pl.hostPropertyServiceProvider?.laundryInQuote && pl.hostPropertyServiceProvider?.laundryToBeDone && (pl.hostPropertyServiceProvider?.laundryMachineCycle == 0 && pl.hostPropertyServiceProvider?.laundryDryerCycle == 0)) {
                        hasError = true;
                    }
            
                    if(pl.hostPropertyServiceProvider?.hasTrashDisposal && pl.hostPropertyServiceProvider?.trashDays == '') {
                        hasError = true;
                    }
        
                    if(pl.hostPropertyServiceProvider?.propertySize == 0) {
                        hasError = true;
                    }
                } else {
                    hasError = true;
                }*/
        hasError = false
      } else {
        hasError = false
      }
    })

    return hasError
  }

  const onCompleteClick = () => {
    var list = propertyList
    selectedProPropertyList.forEach((spp) => {
      var find = list.find((l) => l.address == spp.address)
      if (find != undefined) {
        find = spp
      }
    })

    if (!controlPropertyProviderDetails()) {
      handleChangeHostProperty(list)
    } else {
      showFailureDialog('Please complete all fields!')
    }
  }

  const onBackStepClick = () => {
    goToStep(1)
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div style={{width: '50%'}} className='m-auto'>
            <img src={toAbsoluteUrl('/media/turnify-pro-teaser.png')} className='w-100' />
            <div className='pro-teaser-bottom-bar2'>
              <div className='pro-learn-more'>
                <a href='https://www.turnify.com/turnify-pro/' target='_blank'>
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div style={{width: '70%'}} className='m-auto mt-5 text-center'>
            <h2 className='text-dark'>
              Get a free quote by selecting the properties you want Turnify to provide cleaning
              service
            </h2>
          </div>
        </div>
      </div>
      <div className='card'>
        <DataGrid
          id='propertyTable'
          keyExpr='pmsReferenceId'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={propertyList}
          showBorders={false}
          ref={tableRef}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          height={'calc(32vh)'}
          allowColumnResizing={true}
          onSelectionChanged={onPropertySelect}
        >
          <Selection mode={'multiple'} showCheckBoxesMode='always' />
          <FilterRow showOperationChooser={false} visible={false} />
          <HeaderFilter visible={false} />
          <SearchPanel visible={false} />
          <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={50} />
          <Summary>
            <TotalItem
              cssClass='absolute-right'
              displayFormat='Total Properties: {0}'
              column='address'
              summaryType='count'
            />
          </Summary>

          <Column dataField='address' minWidth={550} cssClass='cls' />
          <Column dataField='state' cssClass='cls' minWidth={150} />
          <Column dataField='city' cssClass='cls' minWidth={150} />

          <Column
            dataField='defaultCheckInTime'
            alignment='center'
            caption='D. Check-In'
            dataType='time'
            minWidth={150}
            cssClass='cls'
          >
            <HeaderFilter dataSource={getDateFilter('defaultCheckInTime')} />
          </Column>
          <Column
            dataField='defaultCheckOutTime'
            alignment='center'
            caption='D. Check-Out'
            dataType='time'
            minWidth={150}
            cssClass='cls'
          >
            <HeaderFilter dataSource={getDateFilter('defaultCheckOutTime')} />
          </Column>
          <Column
            dataField='numberOfBedrooms'
            alignment='center'
            caption='Bedrooms'
            dataType='number'
            cssClass='cls'
            minWidth={150}
          />
          <Column
            dataField='numberOfBathrooms'
            alignment='center'
            caption='Bathrooms'
            dataType='number'
            cssClass='cls'
            minWidth={150}
          />
          <Column
            dataField='numberOfBeds'
            alignment='center'
            caption='Beds'
            dataType='number'
            cssClass='cls'
            minWidth={150}
          />
          <Column dataField='zipCode' alignment='center' cssClass='cls' minWidth={150} />
        </DataGrid>
      </div>
      {selectedProPropertyList.length > 0 && (
        <>
          <TurnifyProFormTable
            selectedProPropertyList={selectedProPropertyList}
            changeProPropertyList={onChangeProPropertyList}
          />
        </>
      )}
      <div className='row'>
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={onBackStepClick} classNames='align-self-end' />
          <button type='button' className='btn btn-success' onClick={onCompleteClick}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default ServiceProviderStep
