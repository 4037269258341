import React, {Dispatch, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {IUserDetail} from '../../interfaces/User'
import {UserAction} from '../../redux/actionTypes/userActionTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import UserEditForm from '../../umut-components/Forms/UserEditForm'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  handleDelete: () => void
}

const ProfileDeleteDialog: React.FC<Props> = ({modalOpen, handleClose, handleDelete}) => {
  const [text, setText] = useState('')
  const [hasError, setHasError] = useState(false)

  const checkDelete = () => {
    setHasError(false)
    if (text == 'DELETE') {
      setHasError(false)
      handleDelete()
    } else {
      setHasError(true)
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} size='lg' centered>
      <ModalHeader closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <p className='text-dark' style={{fontWeight: 'bold'}}>
          Warning! Please only delete your account if you no longer want to use Turnify. If you
          delete your account now, it may not be possible sign up again with same credentials.
        </p>
        <p className='text-danger' style={{fontWeight: 'bold'}}>
          Type DELETE below to delete your account.
        </p>
        <div>
          <input
            type='text'
            onChange={(e) => {
              setText(e.target.value)
            }}
            placeholder='Type DELETE here'
            className='form-control'
          />
        </div>
        {hasError && (
          <span className='text-danger' style={{fontSize: 12, fontWeight: 'bold'}}>
            * Please write as stated above
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-link text-muted me-5'
          onClick={() => {
            handleClose()
          }}
        >
          Cancel
        </button>
        <button
          className='btn btn-sm btn-danger'
          onClick={() => {
            checkDelete()
          }}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfileDeleteDialog
