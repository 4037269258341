import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'

type Props = {staff: IStaff}

const General: React.FC<Props> = ({staff}) => {
  const getStatusName = (staff: IStaff) => {
    if (staff.accountStatusId == 1) {
      return '<span class="text-success">Active</span>'
    }
    if (staff.accountStatusId == 2) {
      return '<span class="text-danger">Inactive</span>'
    }
    if (staff.accountStatusId == 3) {
      return '<span class="text-warning">Email Not Verified</span>'
    }

    return '<span class="text-info">' + staff.accountStatusName + '</span>'
  }

  const getVendorName = (text: any) => {
    var nameList = text.split(' ')
    var newName = ''
    for (const item of nameList) {
      newName += item.charAt(0).toUpperCase() + '**** '
    }
    return newName
  }

  return (
    <div className='d-flex flex-column gap-5 mt-7'>
      <div className='d-flex flex-column gap-1'>
        <div className='fw-bold text-muted'>Contact Name</div>
        <div className='fw-bold fs-5'>
          {staff.firstName + ' ' + staff.lastName.substring(0, 1) + '.'}
        </div>
      </div>
      {/* <div className='d-flex flex-column gap-1'>
        <div className='fw-bold text-muted'>Email</div>
        <div className='fw-bold fs-5'>{staff.email}</div>
      </div>
      <div className='d-flex flex-column gap-1'>
        <div className='fw-bold text-muted'>Phone</div>
        <div className='fw-bold fs-5'>{staff.phoneNumber}</div>
      </div> */}
      {staff.vendorId != null && (
        <div className='d-flex flex-column gap-1'>
          <div className='fw-bold text-muted'>Service Provider</div>
          <div className='fw-bold fs-5'>{getVendorName(staff.vendorName)}</div>
        </div>
      )}
      <div className='d-flex flex-column gap-1'>
        <div className='fw-bold text-muted'>Role</div>
        <div className='fw-bold fs-5'>{staff.roleName}</div>
      </div>
      <div className='d-flex flex-column gap-1'>
        <div className='fw-bold text-muted'>Status</div>
        <div
          className='fw-bold fs-5'
          dangerouslySetInnerHTML={{__html: getStatusName(staff)}}
        ></div>
      </div>
    </div>
  )
}

export default General
