import React, {Dispatch, useEffect, useState} from 'react'
import {IStaff} from '../../../interfaces/Staff'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import StaffEditDialog from '../StaffEditDialog'
import StaffPasswordEdit from '../StaffPasswordEdit'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import * as staffActionCreator from '../../../redux/actionCreators/staffActionCreators'
import {useDispatch} from 'react-redux'
import {StaffAction} from '../../../redux/actionTypes/staffActionTypes'
import General from './HostStaffTabs/ProfileTab/General'
import Jobs from './HostStaffTabs/JobsTab/Jobs'
import Properties from './HostStaffTabs/JobsTab/Properties'
import StaffPropertyDialog from './Tabs/StaffPropertyDialog'
import OfferedService from './HostStaffTabs/OfferedService'
import OffDays from './HostStaffTabs/OffDays'
import OffTimes from './HostStaffTabs/OffTimes'
import HostStaffEditDialog from './HostStaffEditDialog'
import HostStaffBlockDialog from './HostStaffBlockDialog'
import ProfileTab from './HostStaffMainTabs/ProfileTab'
import JobsTab from './HostStaffMainTabs/JobsTab'
import SettingsTab from './HostStaffMainTabs/SettingsTab'
import HostStaffChat from './HostStaffChat'

type Props = {
  staff: IStaff
  refetch: () => void
}

type TabNames = 'profile' | 'jobs' | 'settings'

const HostStaffDetail: React.FC<Props> = ({staff, refetch}) => {
  const [showDropBx, setShowDropBx] = useState(false)
  const [activeTab, setActiveTab] = useState<TabNames>('profile')
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [blockDialogOpen, setBlockDialogOpen] = useState(false)
  const [chatStaff, setChatStaff] = useState<IStaff>()
  const [showChat, setShowChat] = useState(false)

  const getContactName = (name: string, surname: string) => {
    return name + ' ' + surname.substring(0, 1) + '.'
  }

  useEffect(() => {
    handleCloseChat()
  }, [staff])

  const openChat = (staff: IStaff) => {
    setChatStaff(staff)
    setShowChat(true)
  }

  const handleCloseChat = () => {
    setShowChat(false)
    setTimeout(() => {
      setChatStaff(undefined)
    }, 300)
  }

  return (
    <>
      <div className='card card-flush h-lg-100'>
        <div className='card-header pt-7'>
          <div className='card-title'>
            <h2>Contact Details</h2>
          </div>

          <div className='card-toolbar gap-3 position-relative'>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary dropmenu'
              onClick={() => {
                setShowDropBx(!showDropBx)
              }}
            >
              <KTSVG
                path='/media/icons/duotone/General/Other2.svg'
                className='svg-icon-3 svg-icon-secondary dropmenu'
                svgClassName='dropmenu mh-50px'
              />
            </button>
            <div
              data-kt-menu-trigger='click'
              className={
                showDropBx
                  ? 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 dropcl show'
                  : 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 dropcl'
              }
            >
              {!staff.isMarketplace && (
                <>
                  <div className='menu-item px-3'>
                    <div
                      className='menu-link px-3'
                      onClick={() => {
                        setEditDialogOpen(true)
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  {/* <div className='menu-item px-3'>
                    <div
                      className='menu-link px-3'
                      onClick={() => {
                        dispatch(staffActionCreator.openStaffPasswordDialog(staff?.userId))
                      }}
                    >
                      Change Password
                    </div>
                  </div> */}
                </>
              )}
              <div className='menu-item px-3'>
                <div
                  className='menu-link px-3'
                  onClick={() => {
                    setEditDialogOpen(true)
                  }}
                >
                  Add To Group
                </div>
              </div>
              <div className='menu-item px-3'>
                <div
                  className='menu-link text-danger px-3'
                  onClick={() => {
                    setBlockDialogOpen(true)
                  }}
                >
                  Block
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body pt-5'>
          <div className='d-flex gap-7 align-items-center'>
            <div className='symbol symbol-circle symbol-100px'>
              {staff.profilePhotoPath && (
                <img src={staff.profilePhotoPath} style={{objectFit: 'cover'}} />
              )}
              {!staff.profilePhotoPath && (
                <span className='symbol-label fs-1'>
                  {staff.firstName.substring(0, 1).toUpperCase() +
                    '' +
                    staff.lastName.substring(0, 1).toUpperCase()}
                </span>
              )}
            </div>
            <div className='d-flex flex-column gap-2'>
              <h3 className='mb-0'>
                {getContactName(staff.firstName, staff.lastName)}{' '}
                {staff.isMarketplace ? (
                  <span className='badge badge-light-success ms-2'>TurnifyMarketplace</span>
                ) : (
                  <span className='badge badge-light-danger ms-2'>MyTeam</span>
                )}
              </h3>
              <div className='d-flex align-items-center gap-2'>
                <button
                  className='btn btn-sm btn-light btn-active-light-primary d-flex align-items-center'
                  onClick={() => {
                    openChat(staff)
                  }}
                >
                  <KTSVG
                    path={toAbsoluteUrl('/media/icons/duotone/Communication/Chat6.svg')}
                    className='svg-icon-2'
                  />
                  <span className='ms-2'>Chat</span>
                </button>
              </div>
              {/* <div className='d-flex align-items-center gap-2'>
                <KTSVG path={toAbsoluteUrl('/media/icons/duotone/Communication/Mail.svg')} />
                <span className='text-muted text-hover-primary'>{staff.email}</span>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <KTSVG path={toAbsoluteUrl('/media/icons/duotone/Communication/Call-1.svg')} />
                <span className='text-muted text-hover-primary'>{staff.phoneNumber}</span>
              </div> */}
            </div>
          </div>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2'>
            <li
              className='nav-item'
              onClick={() => {
                setActiveTab('profile')
              }}
            >
              <div
                className={
                  activeTab == 'profile'
                    ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                    : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
                }
              >
                Profile
              </div>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                setActiveTab('jobs')
              }}
            >
              <div
                className={
                  activeTab == 'jobs'
                    ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                    : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
                }
              >
                Jobs
              </div>
            </li>
            {!staff.isMarketplace && (
              <li
                className='nav-item'
                onClick={() => {
                  setActiveTab('settings')
                }}
              >
                <div
                  className={
                    activeTab == 'settings'
                      ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                      : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
                  }
                >
                  Settings
                </div>
              </li>
            )}
          </ul>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              {activeTab == 'profile' && <ProfileTab staff={staff} />}
              {activeTab == 'jobs' && <JobsTab staff={staff} />}
              {activeTab == 'settings' && <SettingsTab staff={staff} />}
            </div>
          </div>
        </div>
      </div>
      <HostStaffEditDialog
        staff={staff}
        modalClose={setEditDialogOpen}
        modalOpen={editDialogOpen}
      />
      <HostStaffBlockDialog
        staff={staff}
        refetch={refetch}
        modalClose={setBlockDialogOpen}
        modalOpen={blockDialogOpen}
      />
      <StaffPasswordEdit />
      <HostStaffChat handleShow={showChat} handleClose={handleCloseChat} staff={chatStaff} />
    </>
  )
}

export default HostStaffDetail
