import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useHistory} from 'react-router-dom'
import ApiCalls from '../../../../network/ApiCalls'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  propertyId: number
  propertyName: string | undefined
  refetch: () => void
}

const PropertyNameEditDialog: React.FC<Props> = ({
  propertyId,
  propertyName,
  modalOpen,
  handleClose,
  refetch,
}) => {
  const [name, setName] = useState<string | undefined>(propertyName)
  const [errMsg, setErrMsg] = useState<string>('')

  const updateName = async () => {
    setErrMsg('')
    if (name == null || name == undefined || name == '') {
      setErrMsg('Property name is required')
    } else {
      try {
        const data = {hostPropertyId: +propertyId, name: name}
        await ApiCalls.updatePropertyName(data)
        refetch()
        handleClose()
      } catch (error) {
        setErrMsg('An error occured during the updating property name')
      }
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} size='lg' centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Property Name</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <label className='form-label'>Property Name</label>
            <input
              type='text'
              className='form-control'
              defaultValue={propertyName}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        {errMsg != '' && <span className='text-danger me-3'>{errMsg}</span>}
        <button className='btn btn-link text-warning me-3' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={updateName}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PropertyNameEditDialog
