import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {IMarketplaceOfferedRate} from '../../../../interfaces/Marketplace'
import {useDialog} from '../../../../contexts/DialogContext'
import ApiCalls from '../../../../network/ApiCalls'
import {TextInput} from '../../../Inputs/TextInput'

type Props = {
  offer: IMarketplaceOfferedRate | null | undefined
  handleClose: () => void
  refetch: () => void
}

const OfferDetailDialog: React.FC<Props> = ({offer, handleClose, refetch}) => {
  const {openLoadingModal, showSuccessDialog, showFailureDialog} = useDialog()

  const acceptOffer = (offerId: any) => {
    openLoadingModal('Please do not turn off the screen, this may take some time.', 50000)
    var request = {id: offer?.id, marketplaceOfferStatusTypeId: 3}
    ApiCalls.acceptOrDeclineMarketplaceRequest(request)
      .then((res: any) => {
        if (res?.data?.success) {
          handleClose()
          setTimeout(() => {
            showSuccessDialog('You accepted the offer successfully.')
          }, 200)
          refetch()
        } else {
          handleClose()
          setTimeout(() => {
            showFailureDialog('An error occurred while accepting the offer')
          }, 200)
        }
      })
      .catch((err) => {
        handleClose()
        setTimeout(() => {
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        }, 200)
      })
  }

  const declineOffer = (offerId: any) => {
    var request = {id: offerId, marketplaceOfferStatusTypeId: 4}
    ApiCalls.acceptOrDeclineMarketplaceRequest(request)
      .then((res: any) => {
        if (res?.data?.success) {
          handleClose()
          setTimeout(() => {
            showSuccessDialog('You declined the offer successfully.')
          }, 200)
          refetch()
        } else {
          showFailureDialog('An error occurred while declining the offer')
        }
      })
      .catch((err) => {
        showFailureDialog(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message)
      })
  }

  const getVendorName = (text: any) => {
    if (text != undefined) {
      var nameList = text.split(' ')
      var newName = ''
      for (const item of nameList) {
        newName += item.charAt(0).toUpperCase() + '**** '
      }
      return newName
    } else {
      return ''
    }
  }

  return (
    <Modal show={true} onHide={handleClose} centered size={'lg'}>
      <ModalHeader>
        <Modal.Title>Offer Detail</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row'>
          <div className='col-12 mb-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-50px'>
                  <span className='symbol-label'></span>
                </div>
                <div className='ms-5'>
                  <div
                    className='text-gray-800 text-hover-primary fs-5 fw-bold'
                    data-kt-ecommerce-product-filter='product_name'
                  >
                    {getVendorName(offer?.vendorName)}
                  </div>
                  <span className='text-gray-600 fs-8'>
                    {offer && offer?.checklistId > 0 ? offer?.checklistName : 'Any Checklist'}
                  </span>
                </div>
              </div>
              <div className='ms-5'>
                <div className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                  Offered Rate ($)
                </div>
                <span className='text-danger'>${offer?.offerPrice}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {offer?.offerStatusId == 2 && (
        <Modal.Footer className='d-flex justify-content-end'>
          <button className='btn btn-success font-weight-bolder me-2' onClick={acceptOffer}>
            Accept
          </button>
          <button className='btn btn-danger font-weight-bolder' onClick={declineOffer}>
            Decline
          </button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default OfferDetailDialog
