import React, {useEffect, useState} from 'react'
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd'

type Props = {key: string; draggableId: string}

const ManageCard: React.FC<Props> = ({key, draggableId}) => {
  return (
    <Draggable key={key} draggableId={draggableId} index={10000000}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`bg-${
              snapshot.isDragging
                ? 'light-warning'
                : 'white border border-dashed border-2 border-danger'
            } d-flex align-items-center rounded p-5 mb-7`}
          >
            <div className='symbol symbol-70px me-5'>
              <img src='/media/logos/turnify-logo-title.png' />
            </div>
            <div className='flex-grow-1'>
              <div className='text-dark fw-bolder text-hover-primary fs-1'>Turnify+</div>
              <div className='text-muted d-block fw-bolder fs-4'>
                Your Personal Property Manager
              </div>
              <div className={`text-muted d-block fs-6`}>Rate: $6/job</div>
            </div>
          </div>
        </>
      )}
    </Draggable>
  )
}

export default ManageCard
