import React from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap-v5';
import Select, { components } from 'react-select';
import { selectStyles } from '../../config/selectStyle';

interface ISelectBox {
    options: any,
    onValueChanged: (text: any) => void,
    disabledText?: string,
    classNames?: string,
    defaultValue?: any,
    ref?: any,
    disabled?: boolean
    noOptionMessage?:string
    multiple?: boolean
}

const SelectInput: React.FC<ISelectBox> = ({ options, onValueChanged, disabledText, multiple, classNames, defaultValue, ref, disabled,noOptionMessage }) => {
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue ? defaultValue : null);

    const valueChanged = (value: any) => {
        if (value) {
            if (!isNaN(parseInt(value.value))) {
                setSelectedValue(parseInt(value.value));
                onValueChanged(parseInt(value.value));
            }
            else {
                setSelectedValue(value.value);
                onValueChanged(value.value);
            }
        }
    }
    
const NoOptionsMessage = (props:any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noOptionMessage}</span>
      </components.NoOptionsMessage>
    );
  };

    return (
        <Select isDisabled={disabled} isMulti={multiple} components={{ NoOptionsMessage }} value={options?.filter((option: any) => option.value === selectedValue)} options={options} onChange={valueChanged} styles={selectStyles} ref={ref} className={classNames} ></Select>
    )
}

export default SelectInput;