import React, {Dispatch, useEffect, useState} from 'react'
import {IHostPropertyInput} from '../../../../../interfaces/Property'
import {useDialog} from '../../../../../contexts/DialogContext'
import {IStaff} from '../../../../../interfaces/Staff'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'
import {PermissionRequest} from '../../../../../network/PostRequestModels/Permission'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import StaffDroppableBoard from './StaffDroppableBoard'
import AuthorizedStaff from './AuthorizedStaff'
import UnauthorizedStaff from './UnauthorizedStaff'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import ManageCard from './ManageCards/ManageCard'
import ManagePromptWizard from './ManagePrompt/ManagePromptWizard'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {DeleteActions} from '../../../../../redux/actionTypes/deleteDialogTypes'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../../../redux/actionCreators/deleteDialogActionCreators'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
  refetch: () => void
}

type ManageCards = 'MP' | 'MP+' | 'MyTeam' | 'Turnify+'
const manageCardList = ['turnifyplus', 'myteam', 'marketplace', 'marketplaceplus']
type ManagePromptTypes = 'turnifyplus' | 'marketplaceplus' | 'marketplace' | 'myteam'

const StaffList: React.FC<Props> = ({property, propertyId, refetch}) => {
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const {hasPaymentCard} = useSelector((state: RedusxAppState) => state.onBoarding)
  const {showFailureDialog, openLoadingModal, closeModal} = useDialog()
  const [allStaffList, setAllStaffList] = useState<IStaff[]>([])
  const [authorizedStaffList, setAuthorizedStaffList] = useState<IStaff[]>([])
  const [permissionList, setPermissionList] = useState<any[]>([])
  const [unauthorizedStaffList, setUnauthorizedStaffList] = useState<IStaff[]>([])
  const [orderRequestList, setOrderRequestList] = useState<any>([])
  const [manageCardType, setManageCardType] = useState<ManageCards>('MyTeam')
  const [showManagePrompt, setShowManagePrompt] = useState<boolean>(false)
  const [managePromptType, setManagePromptType] = useState<ManagePromptTypes>('turnifyplus')
  const {
    data: propertyStaff,
    isLoading: propertyStaffLoading,
    error: propertyStaffError,
    refetch: propertyStaffRefetch,
  } = useQuery(
    ['Get Host Property Staffs', propertyId],
    () => ApiCalls.getHostStaffByProperty(propertyId),
    {cacheTime: 10000, retry: false, enabled: propertyId ? true : false}
  )

  const {
    data: permissionData,
    isLoading: permissionDataLoading,
    error: permissionDataError,
    refetch: permissionRefetch,
  } = useQuery(
    ['Get Permissions Staff', propertyId],
    () =>
      property?.cleaningProviderId === 2 || property?.isMarketplace
        ? ApiCalls.getAcceptedProperties(propertyId)
        : ApiCalls.getAcceptedMyProperties(propertyId),
    {cacheTime: 10000, refetchOnWindowFocus: false, enabled: property ? true : false}
  )

  useEffect(() => {
    if (property) {
      if (property.isMarketplace) {
        if (property.hasMpManagement) {
          setManageCardType('MP+')
          setManagePromptType('marketplace')
        } else {
          setManageCardType('MP')
          setManagePromptType('marketplaceplus')
        }
      } else {
        if (property.cleaningProviderId == 3) {
          setManageCardType('Turnify+')
          setManagePromptType('myteam')
        } else {
          setManageCardType('MyTeam')
          setManagePromptType('turnifyplus')
        }
      }
    }
  }, [property])

  useEffect(() => {
    if (!propertyStaffLoading && propertyStaff?.data != undefined) {
      var list: IStaff[] = []
      for (const staff of propertyStaff?.data?.data) {
        if (!['Admin', 'Manager'].includes(staff.roleName) && staff.accountStatusId != 2) {
          if (property?.isMarketplace) {
            if (staff.isMarketplace) {
              list.push(staff)
            }
          } else {
            if (!staff.isMarketplace) {
              list.push(staff)
            }
          }
        }
      }

      setAllStaffList(list)
    }
  }, [propertyStaff, propertyStaffLoading])

  useEffect(() => {
    if (allStaffList.length > 0) {
      var authIds: number[] = []
      if (permissionData?.data?.data != undefined) {
        var list: IStaff[] = []
        for (const pd of permissionData?.data?.data) {
          var findStaff = allStaffList.find((staff) => staff.userId === pd.userId)
          if (findStaff != undefined) {
            setPermissionList(pd)
            findStaff.staffQueueIndex = pd.staffQueueIndex
            list.push(findStaff)
            authIds.push(findStaff.userId)
          }
        }
        list.sort((a, b) => a.staffQueueIndex! - b.staffQueueIndex!)
        setAuthorizedStaffList(list)
      }

      var unAuthList: IStaff[] = []
      for (const staff of allStaffList) {
        if (!authIds.includes(staff.userId)) {
          unAuthList.push(staff)
        }
      }
      unAuthList.sort((a, b) => a.accountStatusId - b.accountStatusId)
      setUnauthorizedStaffList(unAuthList)
    }
  }, [allStaffList, permissionData, permissionDataLoading, orderRequestList])

  useEffect(() => {
    if (orderRequestList.length > 0) {
      handleOrderStaff()
    }
  }, [orderRequestList])

  const handleOrderStaff = async () => {
    openLoadingModal('Please wait...', 30000)
    try {
      await ApiCalls.updatePropertyUserPriorityByUser(orderRequestList)
      setOrderRequestList([])
      permissionRefetch()
      setTimeout(() => {
        closeModal()
      }, 2000)
    } catch (error: any) {
      setTimeout(() => {
        closeModal()
        showFailureDialog(error?.response?.message?.data)
      }, 2000)
    }
  }

  const authorizeStaff = async (userId: any, roleId: any, reqList: any) => {
    try {
      let permissionRequest: PermissionRequest[] = [
        {
          hostPropertyId: propertyId,
          userId,
          roleId,
        },
      ]
      await ApiCalls.addPropertyPermission(permissionRequest)
      await permissionRefetch()
      closeModal()
      setOrderRequestList(reqList)
    } catch (err: any) {
      setTimeout(() => {
        closeModal()
        showFailureDialog(err?.response?.message?.data)
      }, 1000)
    }
  }

  const unauthorizeStaff = async (userId: any, roleId: any) => {
    try {
      let permissionRequest: PermissionRequest = {
        hostPropertyId: +propertyId,
        userId,
        roleId,
      }
      await ApiCalls.deletePropertyPermissionByUser(permissionRequest)
      await permissionRefetch()
      setTimeout(() => {
        closeModal()
      }, 1000)
    } catch (err: any) {
      setTimeout(() => {
        closeModal()
        showFailureDialog(err?.response?.message?.data)
      }, 1000)
    }
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination, draggableId} = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index

    if (manageCardList.includes(draggableId)) {
      if (source.droppableId !== destination?.droppableId) {
        if (draggableId == 'turnifyplus' || draggableId == 'marketplaceplus') {
          setManagePromptType(draggableId)
          setShowManagePrompt(true)
        }

        if (draggableId == 'myteam' || draggableId == 'marketplace') {
          setManagePromptType(draggableId)
          deleteDispatch(
            openDeleteDialog(
              downgradePropertyManage,
              `Do you want to remove your personal property manager?`,
              'Remove'
            )
          )
        }
      }
    } else {
      if (source.droppableId == destination?.droppableId) {
        if (source.droppableId == 'authorized-staff') {
          openLoadingModal('Please wait...', 30000)
          if (sourceIndex !== undefined && destinationIndex !== undefined) {
            const result = [...authorizedStaffList]
            const [removed] = result.splice(sourceIndex, 1)
            result.splice(destinationIndex, 0, removed)
            setOrderRequestList(
              result.map((item, index) => {
                return {userId: item?.userId, hostPropertyId: +propertyId, staffQueueIndex: index}
              })
            )
          }
        }
      } else {
        if (source.droppableId == 'unauthorized-staff') {
          const findUser = allStaffList.find((asl) => asl.userId == +result.draggableId)
          if (findUser) {
            if (
              sourceIndex !== undefined &&
              destinationIndex !== undefined &&
              sourceIndex !== destinationIndex
            ) {
              openLoadingModal('Please wait...', 30000)
              const result = [...authorizedStaffList]
              const [removed] = result.splice(sourceIndex, 1)
              result.splice(destinationIndex, 0, removed)
              const newReqList = result.map((item, index) => {
                return {userId: item?.userId, hostPropertyId: +propertyId, staffQueueIndex: index}
              })
              authorizeStaff(findUser.userId, findUser.roleId, newReqList)
            }
          }
        }
        if (source.droppableId == 'authorized-staff') {
          const findUser = allStaffList.find((asl) => asl.userId == +result.draggableId)
          if (findUser) {
            openLoadingModal('Please wait...', 30000)
            unauthorizeStaff(findUser.userId, findUser.roleId)
          }
        }
      }
    }
  }

  const downgradePropertyManage = async () => {
    try {
      const data = {
        hostPropertyId: propertyId,
        type: managePromptType,
      }
      await ApiCalls.removePersonalPropertyManager(data)
      refetch()
      deleteDispatch(closeDeleteDialog())
    } catch (error) {
      showFailureDialog('An error occured during the removing personal property manager')
    }
  }

  const handleCloseManagePrompt = () => {
    setShowManagePrompt(false)
  }

  if (propertyStaffLoading) {
    return <SkeletonTableLoading />
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='row'>
          <div className='col-xl-6'>
            <div className='card mb-5'>
              <div className='card-header'>
                <div className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold text-dark'>Pending Authorization</span>
                  <span className='text-muted mt-1 fw-semibold fs-7'>
                    Drag and drop profiles to the "Authorized Staff" to enable access.
                  </span>
                </div>
              </div>
              <div className='card-body'>
                <StaffDroppableBoard droppableId='unauthorized-staff'>
                  {manageCardType == 'MyTeam' && (
                    <ManageCard key='upgrade-to-turnifyplus' draggableId='turnifyplus' />
                  )}
                  {manageCardType == 'MP' && (
                    <ManageCard key='upgrade-to-marketplace-plus' draggableId='marketplaceplus' />
                  )}
                  {unauthorizedStaffList.map((staff, index) => {
                    return (
                      <UnauthorizedStaff
                        staff={staff}
                        index={index}
                        propertyId={propertyId}
                        refetch={permissionRefetch}
                      />
                    )
                  })}
                </StaffDroppableBoard>
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='card mb-5'>
              <div className='card-header'>
                <div className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold text-dark'>Authorized Staff</span>
                  <span className='text-muted mt-1 fw-semibold fs-7'>
                    Drag & drop the profiles here to set their priority from high to low.
                  </span>
                </div>
              </div>
              <div className='card-body'>
                <StaffDroppableBoard droppableId='authorized-staff'>
                  {manageCardType == 'Turnify+' && (
                    <ManageCard key='downgrade-to-myteam' draggableId='myteam' />
                  )}
                  {manageCardType == 'MP+' && (
                    <ManageCard key='downgrade-to-marketplace' draggableId='marketplace' />
                  )}
                  {authorizedStaffList.map((staff, index) => {
                    return (
                      <AuthorizedStaff
                        staff={staff}
                        index={index}
                        propertyId={propertyId}
                        refetch={permissionRefetch}
                      />
                    )
                  })}
                </StaffDroppableBoard>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
      {showManagePrompt && (
        <ManagePromptWizard
          propertyId={propertyId}
          promptType={managePromptType}
          show={showManagePrompt}
          handleClose={handleCloseManagePrompt}
          hasPayment={hasPaymentCard}
          refetch={refetch}
        />
      )}
      <DeleteDialog />
    </>
  )
}

export default StaffList
