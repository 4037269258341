import React, { FC, useState } from 'react';
import {
  Column,
  DataGrid,
  HeaderFilter, Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  Selection
} from 'devextreme-react/data-grid';
import { Button } from 'react-bootstrap-v5';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators';
import ApiCalls from '../../../network/ApiCalls';



type Props = {
  data: any
  staffId: number
  refetch: () => void
}

const ManagerPropertiesAssigned: FC<Props> = ({ data, refetch }) => {
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [loading, setLoading] = useState(false);
  const deletDialogDispatch = useDispatch<Dispatch<DeleteActions>>();

  const removeManagerPermission = async () => {
    deletDialogDispatch(deleteDialogActionCreators.openDeleteDialog(
      async () => {
        try {
          setLoading(true)
          let requestString = { permissionIds: selectedProperty }
          await ApiCalls.deleteManagerProperties(requestString)
          refetch()
          deletDialogDispatch(deleteDialogActionCreators.closeDeleteDialog())
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
      `Are you sure you want to delete manager permission?`,
      'Delete Permission?'
    ))
  }
  const onSelectionChanged = (cellValue: any) => {
    setSelectedProperty(cellValue.selectedRowsData.map((element: any) => element.id))
  }


  return <>
    <h3>Manager Properties</h3>
    <DataGrid
      id='ManagerPropertiesTable'
      keyExpr='id'
      onRowPrepared={(e) => {
        e.rowElement.classList.add('data-grid-row')
      }}
      dataSource={data}
      showBorders={false}
      showColumnLines={false}
      showRowLines={true}
      hoverStateEnabled={true}
      height={'calc(50vh - 60px)'}
      allowColumnResizing={true}
      onSelectionChanged={onSelectionChanged}
    >
      <HeaderFilter visible={true} />
      <Selection mode='multiple' showCheckBoxesMode='always' />
      <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
      <SearchPanel visible={true} />
      <Summary>
        <TotalItem cssClass='absolute-right' displayFormat="Total Properties: {0}" column='cityName' summaryType='count' />
      </Summary>
      <Column dataField='name' minWidth={350} cssClass='cls' caption="Property Name" />
      <Column
        dataField='stateName'
        alignment='center'
        caption='STATE'
        minWidth={30}
        cssClass='cls'
      />
      <Column dataField='cityName' minWidth={100} cssClass='cls' caption="CITY" />

    </DataGrid>
    <div className="mt-5">
      <Button onClick={removeManagerPermission} disabled={loading} className={`btn-sm btn-danger w-100`}>Remove Permission</Button>
    </div>

  </>;
};

export default ManagerPropertiesAssigned;
