import React, {Dispatch, useState} from 'react'
import {CometChatUI, CometChatUserListWithMessages} from './cometchat/CometChatWorkspace/src'

const Chat = () => {
  return (
    <div style={{width: '100%', height: '80vh'}}>
      <CometChatUserListWithMessages />
    </div>
  )
}

export default Chat
