/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { GuestyRequest, HostawayRequest, MyVrRequest, StayntouchRequest } from '../../../network/PostRequestModels/PropertyFromPMS';

type Props = {
    fetchPropertyList: (data: HostawayRequest | GuestyRequest | MyVrRequest | StayntouchRequest) => Promise<string | void>,
    propertyLoading: boolean,
    keys?: any
}

export const MyVrForm: React.FC<Props> = ({ fetchPropertyList, propertyLoading, keys }) => {
    const [token, setToken] = useState<string | undefined>(keys?.configJSON?.token ?? undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const getProperties = async () => {
        if (token) {
            setErrorMessage(null);
            const myVrRequest: MyVrRequest = {
                token
            }
            fetchPropertyList(myVrRequest);
        }
        else {
            setErrorMessage('All fields are required');
        }
    }

    return (
        <form
            className='form w-100 mt-5'
            noValidate
            id='kt_myvr_form'
        >
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>MyVr Private Key (API)</label>
                <input
                    value={token}
                    onChange={(e: any) => setToken(e.target.value)}
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    autoComplete='off'
                    required
                />
            </div>
            {/* end::Form group */}

            {/* begin:: ErrorText */}
            <strong style={{ color: 'red' }}>{errorMessage}</strong>
            {/* end:: ErrorText */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='button'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    onClick={getProperties}
                    disabled={propertyLoading}
                >
                    {!propertyLoading && <span className='indicator-label'>Get MyVr Properties</span>}
                    {propertyLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
