import React from 'react';

type Props = {
    classNames?: string,
    viewFn: (param?: any) => void,
    type?: 'submit' | 'button',
    param?: any,
    iconWidth?: string,
    iconHeight?: string
}

const ChecklistButton: React.FC<Props> = ({ classNames, type, param, viewFn, iconWidth, iconHeight }) => {
    return (
        <button type={type ? type : "button"} className={'btn btn-light-info ' + classNames} onClick={() => { viewFn(param) }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth ? iconWidth : "24"} height={iconHeight ? iconHeight : "24"} fill="currentColor" className="bi bi-list-check" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
            </svg>
        </button>
    )
}

export default ChecklistButton;