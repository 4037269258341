import { IUser } from "../../interfaces/User";
import { LoginRequest } from "../../network/PostRequestModels";

export const LOGIN = "LOGIN";
export interface LoginAction {
    type: typeof LOGIN,
    loginRequest: LoginRequest,
    logoutFunc: () => void
}

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export interface GetUserDetailAction {
    type: typeof GET_USER_DETAILS,
    token?: string,
    logoutFunc: () => void
}

export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export interface GetUserInfoSuccess {
    type: typeof GET_USER_INFO_SUCCESS,
    user: IUser
}

export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";
export interface GetUserInfoFailure {
    type: typeof GET_USER_INFO_FAILURE;
    error: Error | string;
}

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS,
    token: string
}

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export interface LoginFailureAction {
    type: typeof LOGIN_FAILURE,
    error: Error | string
}

export const CLEAR_LOADING = "CLEAR_LOADING";
export interface ClearLoadingAction {
    type: typeof CLEAR_LOADING
}

export const LOGOUT = "LOGOUT";
export interface LogoutAction {
    type: typeof LOGOUT
}

export type UserAction =
    | LoginAction
    | LoginSuccessAction
    | LoginFailureAction
    | LogoutAction
    | GetUserInfoSuccess
    | GetUserInfoFailure
    | GetUserDetailAction
    | ClearLoadingAction