import * as actions from '../actionTypes/deleteDialogTypes'

export interface DeleteState {
  deleteFn?: () => Promise<void>
  modalOpen: boolean
  bodyText: string | null
  btnText: string | undefined
  title: string | null
}

const initialState: DeleteState = {
  deleteFn: undefined,
  modalOpen: false,
  bodyText: null,
  btnText: undefined,
  title: null,
}

export default function deleteDialogReducer(
  state: DeleteState = initialState,
  action: actions.DeleteActions
): DeleteState {
  switch (action.type) {
    case actions.OPEN_DELETE_DIALOG:
      return {
        ...state,
        deleteFn: action.deleteFn,
        bodyText: action.bodyText,
        btnText: action.btnText,
        title: action.title,
        modalOpen: true,
      }
    case actions.CLOSE_DELETE_DIALOG:
      return {
        ...state,
        modalOpen: false,
        deleteFn: undefined,
        bodyText: null,
        title: null,
        btnText: undefined,
      }
    default:
      return state
  }
}
