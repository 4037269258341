import {all, fork} from 'redux-saga/effects'
import addressSaga from './addressSaga'
import checklistSaga from './checklistSaga'
import hostPropertySaga from './hostPropertySaga'
import masterChecklistSaga from './masterChecklistSaga'
import onBoardingSaga from './onBoardingSaga'
import staffSaga from './staffSaga'
import proTeamSaga from './proTeamSaga'
import userSaga from './userSaga'
export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(hostPropertySaga),
    fork(checklistSaga),
    fork(masterChecklistSaga),
    fork(staffSaga),
    fork(proTeamSaga),
    fork(addressSaga),
    fork(onBoardingSaga),
  ])
}
