import React, {useEffect, useState} from 'react'
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd'
import {IStaff} from '../../../../../interfaces/Staff'
import {PermissionRequest} from '../../../../../network/PostRequestModels/Permission'
import ApiCalls from '../../../../../network/ApiCalls'

type Props = {
  staff: IStaff
  index: number
  propertyId: number
  refetch: () => void
}

const AuthorizedStaff: React.FC<Props> = ({staff, index, propertyId, refetch}) => {
  return (
    <Draggable key={staff.id} draggableId={staff.userId.toString()} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`bg-${
              snapshot.isDragging ? 'light-warning' : 'white border border-1'
            } d-flex align-items-center rounded p-5 mb-7`}
          >
            <div className='symbol symbol-70px me-5'>
              <span className='symbol-label bg-light'>
                {staff.firstName.substring(0, 1).toUpperCase() +
                  '' +
                  staff.lastName.substring(0, 1).toUpperCase()}
              </span>
            </div>
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary fs-6'>
                {staff.firstName + ' ' + staff.lastName.substring(0, 1) + '.'}
              </div>
              <div className='text-muted d-block fw-bold'>{staff.roleName}</div>
              <div
                className={`badge badge-light-${
                  staff.accountStatusId == 1 ? 'success' : 'warning'
                } fs-8 fw-bold`}
              >
                {staff.accountStatusName}
              </div>
            </div>
          </div>
        </>
      )}
    </Draggable>
  )
}

export default AuthorizedStaff
