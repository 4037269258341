import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import ApiCalls from '../../network/ApiCalls';
import { closeStaffPasswordDialog } from '../../redux/actionCreators/staffActionCreators';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { TextInput } from '../../umut-components/Inputs/TextInput';

const StaffPasswordEdit = () => {
    const { staffPasswordDialog } = useSelector((state: RedusxAppState) => state.staff);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState({ newPassword: "", passwordConfirm: "" })
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [matchError, setMatchError] = useState<string | null>(null)
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const handleClose = () => {
        dispatch(closeStaffPasswordDialog())
    }


    const handleChangePass = async () => {
        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
        if (password.newPassword !== password.passwordConfirm) {
            setMatchError("* passwords do not match")
        } else
            try {
                setMatchError("")
                setLoading(true);
                await ApiCalls.putStaffPassword({ newPassword: password?.newPassword }, staffPasswordDialog?.staffId)
                setErrorMessage(null);
                setLoading(false);
                handleClose()

            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.message);
            }
    }
    return (
        <Modal show={staffPasswordDialog?.modalOpen} onHide={handleClose} centered>         
            <Modal.Body>
            <Modal.Title  text-center>Change Password</Modal.Title>
                <form className='form w-100 my-10 mt-5'>

                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-12'>
                            <p className="fs-6 mb-10">You can change staff password below</p>

                            <label className='form-label fs-6 fw-bolder text-dark mt-3'>New Password {<span style={{color:"red"}}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, newPassword: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                            <label className='form-label fs-6 fw-bolder text-dark mt-6'>New Password (confirm) {<span style={{color:"red"}}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, passwordConfirm: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="d-flex mx-5" variant="link" onClick={() => { handleClose(); setMatchError("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleChangePass} disabled={loading}>
                    Change {loading ? <Spinner animation='border' size='sm' /> : null}
                </Button>
                <strong className='text-danger'>{errorMessage}</strong>
            </Modal.Footer>
        </Modal>
    )
}

export default StaffPasswordEdit
