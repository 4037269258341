import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {
  IHostPropertyAutomation,
  IHostPropertyICal,
  IHostPropertyInput,
  IHostPropertyManualInput,
  ILocation,
} from '../../../../interfaces/Property'
import ApiCalls from '../../../../network/ApiCalls'
import {Button, Spinner} from 'react-bootstrap-v5'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {decryptText, getLocationByAddress, getStaticMap} from '../../../../utils/util'
import ArrowButton from '../../../../components/Buttons/ArrowButton'
import DeleteButton from '../../../../components/Buttons/DeleteButton'
import AutocompleteMap from '../../../../components/Inputs/AutocompleteMap'
import SwitchBox from '../../../../components/Inputs/SwitchBox'
import {TextInput} from '../../../../components/Inputs/TextInput'
import TimePicker from '../../../../components/Inputs/TimePicker'
import Map from '../../../../components/Map/GoogleMap'
import './custom.css'
import {IStaffAddress} from '../../../../interfaces/Address'
import {useDialog} from '../../../../contexts/DialogContext'
const debounce = require('debounce')

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyManualInput) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: string
  totalStep: number
}

const icalProviders = [
  {
    name: 'Airbnb',
    image: 'media/logos/ical-providers/airbnb.svg',
  },
  {
    name: 'Booking',
    image: 'media/logos/ical-providers/booking.svg',
  },
  {
    name: 'Guesty',
    image: 'media/logos/ical-providers/guesty.svg',
  },
  {
    name: 'Vrbo',
    image: 'media/logos/ical-providers/vrbo.svg',
  },
  {
    name: 'HomeAway',
    image: 'media/logos/ical-providers/homeaway.svg',
  },
  {
    name: 'Hostaway',
    image: 'media/logos/ical-providers/hostaway.svg',
  },
  {
    name: 'Hospitable',
    image: 'media/logos/ical-providers/hospitable.svg',
  },
  {
    name: 'Hostfully',
    image: 'media/logos/ical-providers/hostfully.svg',
  },
  {
    name: 'Lodgix',
    image: 'media/logos/ical-providers/lodgix.svg',
  },
  {
    name: 'Smoobu',
    image: 'media/logos/ical-providers/smoobu.svg',
  },
  {
    name: 'Streamline VRS',
    image: 'media/logos/ical-providers/streamlinevrs.svg',
  },
  {
    name: 'Other',
    image: 'media/logos/ical-providers/ical.svg',
  },
]

const AutomationStep: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  currentStep,
  totalStep,
}) => {
  const {showFailureDialog} = useDialog()
  const [selectedPropertyAutomation, setSelectedPropertyAutomation] =
    useState<IHostPropertyAutomation>({
      isAutomaticCalendarSync: true,
      isAutomaticPayment: true,
      isAutomaticSchedule: true,
      usePMS: false,
      checkInTime: '15:00',
      checkOutTime: '11:00',
    })
  const [hasSameDayBooking, setHasSameDayBooking] = useState<boolean>(false)
  const [validTime, setValidTime] = useState<string | null>(null)
  const [selectedICalUrls, setSelectedICalUrls] = useState<IHostPropertyICal[]>([{}])
  const [icalErrorIndexes, setIcalErrorIndexes] = useState<number[]>([])
  const [text, setText] = useState<string>('')
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)

  const changeScheduling = (check: boolean) => {
    setSelectedPropertyAutomation({
      ...selectedPropertyAutomation,
      isAutomaticSchedule: check,
      isAutomaticCalendarSync: check,
    })
  }

  const changeCheckoutTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkOutTime: event})
  }

  const changeCheckinTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkInTime: event})
  }

  const checkICalValid = (value: string, changeIndex: number) => {
    ApiCalls.validateICal(value)
      .then((res) => {
        if (res.data) {
          setIcalErrorIndexes(icalErrorIndexes.filter((index) => index !== changeIndex))
        } else {
          setIcalErrorIndexes([...icalErrorIndexes, changeIndex])
        }
      })
      .catch((err) => setIcalErrorIndexes([...icalErrorIndexes, changeIndex]))
  }
  const checkICalValidDebounce = debounce(checkICalValid, 600)

  const changeICalUrl = (value: string, changeIndex: number) => {
    setSelectedICalUrls(
      selectedICalUrls.map((iCal, index) =>
        index === changeIndex ? {...iCal, icalUrl: value} : iCal
      )
    )
    checkICalValidDebounce(value, changeIndex)
  }

  const addNewICalUrl = () => {
    setSelectedICalUrls([...selectedICalUrls, {icalUrl: ''}])
  }

  const deleteIcalUrl = (index: number) => {
    var icals = selectedICalUrls
    if (icals.length == 1) {
      var emptyIcal = [
        {
          id: 1,
          icalUrl: '',
          isDeleted: false,
        },
      ]
      setSelectedICalUrls([...emptyIcal])
    } else {
      icals.splice(index, 1)
      setSelectedICalUrls([...icals])
    }
  }

  const areIcalUrlsValid = () => {
    var isValid = true
    selectedICalUrls.map((ical) => {
      if (Object.keys(ical).length === 0) {
        isValid = false
      }
    })

    return isValid
  }

  const onNextStepClick = () => {
    if (
      selectedPropertyAutomation.isAutomaticSchedule &&
      selectedICalUrls.length > 1 &&
      !areIcalUrlsValid()
    ) {
      showFailureDialog('Some of the iCal addresses entered are empty.')
      setHasMissingField(true)
    } else if (
      selectedPropertyAutomation.isAutomaticSchedule &&
      selectedICalUrls.length > 1 &&
      icalErrorIndexes.length > 1
    ) {
      showFailureDialog('Some of the iCal addresses entered are incorrect.')
      setHasMissingField(true)
    } else {
      setHasMissingField(false)
      handleChangeHostProperty({
        hostPropertyAutomation: selectedPropertyAutomation,
        hostPropertyICals: selectedICalUrls,
      })
      goToStep(3)
    }
  }

  const onBackStepClick = () => {
    goToStep(1)
  }

  return (
    <>
      <div className='form w-100 mt-5'>
        <div className='row mt-10'>
          <div className='col-lg-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Same Day Booking</label>
            <SwitchBox
              mutedText
              defaultChecked={hasSameDayBooking}
              text='When enabled, Check-in Time cannot be earlier then Check-out Time.'
              onCheckboxChange={setHasSameDayBooking}
            />

            <label className='form-label fs-6 fw-bolder text-dark mt-5'>Automatic Scheduling</label>
            <SwitchBox
              mutedText
              defaultChecked={
                selectedPropertyAutomation.isAutomaticSchedule !== undefined
                  ? selectedPropertyAutomation.isAutomaticSchedule
                  : true
              }
              text='When enabled, cleaners will be automatically sent cleaning requests for all of your checkouts.'
              onCheckboxChange={(e) => {
                changeScheduling(e)
              }}
            />
          </div>
          <div className='d-flex flex-column col-lg-6'>
            <strong>Please select the check-in / check-out times of your property.</strong>
            <small className='text-muted'>
              Assignments will be made based on the times you have chosen while creating the
              cleaning schedule for your property.
            </small>
            <div className='row'>
              <div className='w-50'>
                {' '}
                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  Check-out Time {<span style={{color: 'red'}}>*</span>}
                </label>
                <TimePicker
                  onChange={changeCheckoutTime}
                  defaultValue={selectedPropertyAutomation.checkOutTime ?? '11:00'}
                />
              </div>
              <div className='w-50'>
                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  Check-in Time {<span style={{color: 'red'}}>*</span>}
                </label>
                <TimePicker
                  onChange={changeCheckinTime}
                  defaultValue={selectedPropertyAutomation.checkInTime ?? '15:00'}
                />
                {validTime && <strong className='text-danger'>{validTime}</strong>}
              </div>
            </div>
          </div>
        </div>
        {selectedPropertyAutomation.isAutomaticCalendarSync && (
          <div className='row mt-10'>
            <div className='form-label fs-6 fw-bolder text-dark'>
              Reservation calendar synchronization
            </div>
            <div className='form-label text-muted' style={{fontSize: 12}}>
              Turnify syncs with the above and many other booking calendars to automatically
              generate cleaning jobs.
            </div>
            <div className='row'>
              {icalProviders.map((ip, i) => {
                return (
                  <div className='col-lg-3 col-12 mb-3 mt-3'>
                    <div className='ical-providers'>
                      <img src={ip.image} alt={ip.name} />
                    </div>
                  </div>
                )
              })}
            </div>
            {selectedICalUrls.map((iCal, index, arr) => {
              return (
                <div className='row'>
                  <strong className='col-12 d-flex mb-3 mt-3'>
                    ICal URL #{index + 1}
                    {!hasMissingField &&
                      !icalErrorIndexes.includes(index) &&
                      iCal.icalUrl != '' &&
                      iCal.icalUrl != undefined && (
                        <div className='success-check'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14px'
                            height='14px'
                            viewBox='0 0 24 24'
                            version='1.1'
                          >
                            <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                              <polygon points='0 0 24 0 24 24 0 24' />
                              <path
                                d='M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z'
                                fill='#fff'
                                fill-rule='nonzero'
                                transform='translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) '
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                  </strong>
                  <div className='col-11'>
                    <input
                      value={iCal.icalUrl}
                      defaultValue={iCal.icalUrl}
                      onChange={(e: any) => {
                        changeICalUrl(e.target.value, index)
                      }}
                      className={'form-control '}
                      type={'text'}
                      autoComplete='off'
                      required
                    />
                    {(missingField || hasMissingField) && (iCal.icalUrl === '' || !iCal.icalUrl) && (
                      <strong className='text-danger'>
                        Missing Field
                        <br />
                      </strong>
                    )}
                    {icalErrorIndexes.includes(index) && (
                      <strong className='text-danger'>ICal is not valid!</strong>
                    )}
                    {!icalErrorIndexes.includes(index) && (
                      <strong className='text-success'></strong>
                    )}
                  </div>
                  {
                    <DeleteButton
                      deleteFn={() => {
                        deleteIcalUrl(index)
                      }}
                      classNames='col-1 btn btn-link text-danger'
                    />
                  }
                  {index === arr.length - 1 ? (
                    <Button
                      style={{maxWidth: 1030}}
                      disabled={iCal.icalUrl === '' || !iCal.icalUrl}
                      type='button'
                      className='btn-success col-12 btn-sm m-2'
                      onClick={() => addNewICalUrl()}
                    >
                      Add new ICal
                    </Button>
                  ) : null}
                </div>
              )
            })}
          </div>
        )}
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={onBackStepClick} classNames='align-self-end' />
          {totalStep == 2 ? (
            <button className='btn btn-success' onClick={onNextStepClick}>
              Confirm
            </button>
          ) : (
            <ArrowButton type='forward' func={onNextStepClick} classNames='align-self-end' />
          )}
        </div>
      </div>
    </>
  )
}

export default AutomationStep
