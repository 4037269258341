import {AxiosResponse} from 'axios'
import {CheckBox, DataGrid} from 'devextreme-react'
import {
  Column,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  Selection,
} from 'devextreme-react/data-grid'
import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import ApiCalls from '../../network/ApiCalls'
import {IPropertyResponse} from '../../network/NetworkResponses/NetworkResponses'
import {BanRequest, PermissionRequest} from '../../network/PostRequestModels/Permission'
import {StaffAction} from '../../redux/actionTypes/staffActionTypes'
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {onExporting} from '../../utils/util'
import ProfilePhotoTemp from '../Icons/ProfilePhotoTemp'
import SkeletonTableLoading from '../Loading/SkeletonTableLoading'
import {Spinner} from 'react-bootstrap-v5'
import {TextInput} from '../Inputs/TextInput'
import {useDialog} from '../../contexts/DialogContext'
import EmptyTable from '../Icons/EmptyTable'
import {CleaningProviders} from '../../enums/PropertyEnum'

export default function StaffPropertyForm() {
  const {showFailureDialog} = useDialog()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {staffDetail, staffPropertyDialog} = useSelector((state: RedusxAppState) => state.staff)
  const {
    data: permissionProperties,
    isLoading: permissionPropertiesLoading,
    error: permissionPropertiesError,
  } = useQuery<AxiosResponse<IPropertyResponse>>(
    ['Get allowed/banned properties', staffDetail.staff?.userId, staffPropertyDialog.formType],
    () =>
      staffPropertyDialog.formType === 'allowed'
        ? ApiCalls.getAllowedProperties(staffDetail.staff?.userId)
        : ApiCalls.getBannedProperties(staffDetail.staff?.userId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: staffPropertyDialog.modalOpen}
  )
  const {
    data: properties,
    isLoading: propertiesLoading,
    error: propertiesError,
  } = useQuery<AxiosResponse<IPropertyResponse>>(
    ['Get Host Properties', user.userSection.hostId],
    () =>
      ApiCalls.getHostPropertiesByProvider(
        user.userSection.hostId,
        CleaningProviders.MY_TEAM + '&cleaningProviderId=' + CleaningProviders.TURNIFY_PLUS,
        false
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: staffPropertyDialog.modalOpen,
    }
  )
  const [errorMessage, setErrorMessage] = useState<string | null>('')
  const [availableProperties, setAvailableProperties] = useState<any[]>([])
  const [selectedProperty, setSelectedProperty] = useState<any>(null)
  const [banDescription, setBanDescription] = useState<string | null>(null)
  const tableRef = useRef<DataGrid>(null)
  const queryClient = useQueryClient()
  const {mutateAsync} = useMutation(ApiCalls.addPropertyPermission, {
    onSuccess: () =>
      queryClient.invalidateQueries(['Allowed Properties', staffDetail.staff?.userId]),
  })
  const {mutateAsync: Banned} = useMutation(ApiCalls.banUserFromProperty, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Allowed Properties', staffDetail.staff?.userId])
      queryClient.invalidateQueries(['Banned Properties', staffDetail.staff?.userId])
    },
  })

  const savePermissions = async () => {
    try {
      if (staffDetail.staff && selectedProperty) {
        setLoading(true)
        setErrorMessage(null)
        let permissionRequest: PermissionRequest[] = selectedProperty.map((property: any) => ({
          hostPropertyId: property.id,
          userId: staffDetail.staff?.userId,
          roleId: staffDetail.staff?.roleId,
        }))
        if (staffPropertyDialog.formType === 'allowed') {
          if (permissionRequest) {
            mutateAsync(permissionRequest)
              .then((res) => {
                staffPropertyDialog.refetch()
                setLoading(false)
                dispatch(staffActionCreator.closeStaffPropertyDialog())
              })
              .catch((err) => {
                setErrorMessage(err?.response?.data?.message)
              })
          }
          // await ApiCalls.addPropertyPermission(permissionRequest);
        } else if (staffPropertyDialog.formType === 'banned' && staffDetail.staff?.hostId) {
          let banRequest: BanRequest = {
            hostPropertyId: selectedProperty?.[0].id,
            userId: staffDetail.staff?.userId,
            roleId: staffDetail.staff?.roleId,
            hostId: staffDetail.staff?.hostId,
            description: banDescription ? banDescription : undefined,
          }
          if (banRequest) {
            Banned(banRequest)
              .then((res) => {
                staffPropertyDialog.refetch()
                setLoading(false)
                dispatch(staffActionCreator.closeStaffPropertyDialog())
              })
              .catch((err) => {
                setErrorMessage(err?.response?.data?.message)
              })
          }
          // await ApiCalls.banUserFromProperty(banRequest);
        }
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  const selectedPropertyChanged = (data: any) => {
    setSelectedProperty(data.selectedRowsData)
  }

  useEffect(() => {
    if (permissionProperties && properties) {
      staffPropertyDialog.formType === 'allowed' &&
        setAvailableProperties(
          properties.data.data.filter(
            (property) =>
              !permissionProperties.data.data.some(
                (permissionProperty) => permissionProperty.hostPropertyId === property.id
              )
          )
        )
      staffPropertyDialog.formType === 'banned' &&
        setAvailableProperties(
          properties.data.data.filter(
            (property) =>
              !permissionProperties.data.data.some(
                (permissionProperty) => permissionProperty.hostPropertyId === property.id
              )
          )
        )
    }
  }, [permissionProperties, properties])

  if (permissionPropertiesLoading || propertiesLoading) {
    return <SkeletonTableLoading />
  }

  if (permissionPropertiesError || propertiesError) {
    return <div>Error</div>
  }

  return (
    <div className='row position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <div className='col-lg-8'>
        {availableProperties.length === 0 ? (
          <EmptyTable classNames='mt-6' title='No Properties' />
        ) : (
          <DataGrid
            id='propertyTable'
            keyExpr='id'
            onRowPrepared={(e) => {
              e.rowElement.classList.add('data-grid-row')
            }}
            dataSource={availableProperties}
            showBorders={false}
            showColumnLines={false}
            ref={tableRef}
            showRowLines={true}
            hoverStateEnabled={true}
            height={'calc(40vh - 60px)'}
            allowColumnResizing={true}
            onExporting={(e) => onExporting(e, 'Permissions')}
            onSelectionChanged={selectedPropertyChanged}
          >
            <Export enabled={false} />
            <Selection
              mode={staffPropertyDialog.formType === 'banned' ? 'single' : 'multiple'}
              showCheckBoxesMode='always'
            />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
            <Paging defaultPageSize={50} />
            <Summary>
              <TotalItem
                cssClass='absolute-right'
                displayFormat='Total Properties: {0}'
                column='name'
                summaryType='count'
              />
            </Summary>

            <Column
              dataField='name'
              caption='Property'
              minWidth={250}
              dataType='string'
              cssClass='cls'
            />
            <Column dataField='cityName' caption='city' minWidth={150} cssClass='cls' />
            <Column dataField='stateName' caption='state' minWidth={150} cssClass='cls' />
            <Column dataField='countryName' caption='country' minWidth={150} cssClass='cls' />
          </DataGrid>
        )}
      </div>
      <div className='col-lg-4 d-flex flex-column bg-light-primary p-6'>
        <strong className='w-100'>Properties</strong>
        <div style={{maxHeight: 200, overflowY: 'auto'}}>
          {selectedProperty?.map((property: any) => (
            <p className='text-muted'>-{property.name}</p>
          ))}
        </div>
        <strong className='w-100 mt-6'>Staff</strong>
        <div className='d-flex flex-wrap mt-2'>
          <div className='d-flex me-6'>
            <ProfilePhotoTemp
              width={35}
              height={35}
              fontSize={12}
              borderRadius='50%'
              profilePhotoPath={staffDetail?.staff?.profilePhotoPath}
              firstName={staffDetail?.staff?.firstName ?? ''}
              lastName={staffDetail?.staff?.lastName ?? ''}
            />
          </div>
          <div className='row'>
            <div className='col-lg-12 h-auto d-flex align-items-center'>
              <strong className='text-muted'>
                {staffDetail?.staff?.firstName + ' ' + staffDetail?.staff?.lastName}
              </strong>
            </div>
          </div>
        </div>
        {staffPropertyDialog.formType === 'banned' && (
          <div className='row mt-6'>
            <div className='d-flex flex-column'>
              <label className='form-label'>Restriction Description</label>
              <TextInput onValueChange={setBanDescription} />
            </div>
          </div>
        )}
        <div className='d-flex flex-column w-100 align-items-end mt-6'>
          <button
            className={`btn btn-${
              staffPropertyDialog.formType === 'allowed' ? 'success' : 'danger'
            } btn-sm`}
            onClick={savePermissions}
            disabled={loading}
          >
            {staffPropertyDialog.formType === 'allowed' ? 'Assign' : 'Restrict'} Staff{' '}
            {loading && <Spinner animation='border' size='sm' />}
          </button>
          <strong className='w-100 d-flex justify-content-end align-items-end text-danger'>
            {errorMessage}
          </strong>
        </div>
      </div>
    </div>
  )
}
