import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ChecklistSectionForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistSectionForm'
import {decryptText} from '../../../utils/util'
import NewChecklistSectionForm from '../../../umut-components/Forms/MasterChecklistForms/NewChecklistSectionForm'

type Props = {
  checklistName: string
  checklistId: string
  orderIndex: number
  modalOpen: boolean
  handleClose?: () => void
  handleAddSectionSuccess: () => void
}

const NewSectionAddDialog: React.FC<Props> = ({
  checklistName,
  checklistId,
  orderIndex,
  modalOpen,
  handleClose,
  handleAddSectionSuccess,
}) => {
  const {checklistItems} = useSelector((state: RedusxAppState) => state.masterChecklist)

  return (
    <Modal show={modalOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add a room to {checklistName}</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <NewChecklistSectionForm
          orderIndex={orderIndex}
          hostPropertyChecklistId={checklistId}
          handleAddSectionSuccess={handleAddSectionSuccess}
        />
      </Modal.Body>
    </Modal>
  )
}

export default NewSectionAddDialog
