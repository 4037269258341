import { IPMSProperty } from "../../interfaces/Property";
import * as actions from '../actionTypes/hostPropertyTypes';

export function getPropertyList(): actions.GetHostPropertyList {
    return {
        type: actions.GET_HOST_PROPERTY_LIST
    }
}

export function setPropertyList(hostPropertyList: IPMSProperty[]): actions.SetHostPropertyList {
    return {
        type: actions.SET_HOST_PROPERTY_LIST,
        hostPropertyList: hostPropertyList
    }
}

export function clearPropertyList(): actions.ClearHostPropertyList {
    return {
        type: actions.CLEAR_HOST_PROPERTY_LIST
    }
}

export function setProperty(hostPropertyInput: IPMSProperty): actions.SetHostProperty {
    return {
        type: actions.SET_HOST_PROPERTY,
        hostPropertyInput
    }
}

export function setPropertySuccess(hostPropertyList: IPMSProperty[]): actions.SetHostPropertySuccess {
    return {
        type: actions.SET_HOST_PROPERTY_SUCCESS,
        hostPropertyList
    }
}

export function setPropertyFailure(): actions.SetHostPropertyFailure {
    return {
        type: actions.SET_HOST_PROPERTY_FAILURE
    }
}

export function postHostPropertyForm(): actions.PostHostPropertyForm {
    return {
        type: actions.POST_HOST_PROPERTY_FORM
    }
}

export function postHostPropertyFormSuccess(): actions.PostHostPropertyFormSuccess {
    return {
        type: actions.POST_HOST_PROPERTY_FORM_SUCCESS
    }
}

export function PostHostPropertyFormFailure(error: string): actions.PostHostPropertyFormFailure {
    return {
        type: actions.POST_HOST_PROPERTY_FORM_FAILURE,
        error
    }
}

export function getSelectedProviderId(): actions.GetSelectedCleaningProvider {
    return {
        type: actions.GET_SELECTED_CLEANING_PROVIDER
    }
}

export function SetSelectedCleaningProvider(cleaningProviderId: number): actions.SetSelectedCleaningProvider {
    return {
        type: actions.SET_SELECTED_CLEANING_PROVIDER,
        cleaningProviderId
    }
}

export function ClearSelectedCleaningProvider(): actions.ClearSelectedCleaningProvider {
    return {
        type: actions.CLEAR_SELECTED_CLEANING_PROVIDER
    }
}
export function OpenDeletePropertyModal(propertyId: number): actions.OpenDeletePropertyModal {
    return {
        type: actions.OPEN_DELETE_PROPERTY_MODAL,
        propertyId
    }
}
export function CloseDeletePropertyModal(): actions.CloseDeletePropertModal {
    return {
        type: actions.CLOSE_DELETE_PROPERTY_MODAL
    }
}