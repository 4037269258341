import React, {Dispatch, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import ApiCalls from '../../../network/ApiCalls'
import {IStaff} from '../../../interfaces/Staff'
import {useDialog} from '../../../contexts/DialogContext'

type Props = {
  staff: IStaff
  modalOpen: boolean
  modalClose: (arg: boolean) => void
  refetch: () => void
}

const HostStaffBlockDialog: React.FC<Props> = ({modalOpen, modalClose, staff, refetch}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState('')
  const {showSuccessDialog} = useDialog()

  const closeModal = () => {
    modalClose(false)
  }

  const blockContact = async () => {
    setLoading(true)
    setErrMsg('')
    try {
      const data = {userId: staff.userId}
      await ApiCalls.blockStaff(data)
      setLoading(false)
      showSuccessDialog(
        staff.firstName + ' ' + staff.lastName.substring(0, 1) + ' blocked successfully'
      )
      refetch()
      modalClose(false)
    } catch (error) {
      setLoading(false)
      setErrMsg('An error occur during blocking contact')
    }
  }

  return (
    <Modal show={modalOpen} onHide={closeModal} size='lg' centered>
      <ModalHeader closeButton>
        <Modal.Title>Block Contact</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <p>
          Please note that blocking{' '}
          <b>{staff.firstName + ' ' + staff.lastName.substring(0, 1) + '.'}</b> will result in the
          removal of all future jobs and authorized properties associated with this contact from
          your account.
        </p>
        <p>
          Should you decide to unblock this contact in the future, it will be treated as a new
          connection. This means you'll need to set up authorizations and other settings once more.
        </p>
        <p>
          <b className='text-danger'>
            Are you sure you want to proceed with blocking{' '}
            {staff.firstName + ' ' + staff.lastName.substring(0, 1) + '.'} ?
          </b>
        </p>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <button type='button' className='btn btn-link me-3' onClick={closeModal}>
          Close
        </button>
        <button type='button' className='btn btn-danger' disabled={loading} onClick={blockContact}>
          Block {loading && <Spinner animation='border' size='sm' />}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default HostStaffBlockDialog
