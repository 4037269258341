import React from 'react';

type Props = {
    text: string
}

const Seperator: React.FC<Props> = ({ text }) => {
    return (
        <div className='w-100 d-flex align-items-center mt-10 mb-10'>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            <span className='fw-bold text-gray-400 fs-7 mx-2'>{text}</span>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>
    )
}

export default Seperator;