import React, {useEffect, useState} from 'react'

type Props = {
  handleChange: (val: boolean) => void
  accepted: boolean
}

const Agreement: React.FC<Props> = ({accepted, handleChange}) => {
  return (
    <div>
      <div
        style={{
          boxShadow: '0 0 7px 3px rgba(0,0,0,0.1) inset',
          padding: '20px 10px',
          maxHeight: 400,
          overflowY: 'scroll',
        }}
      >
        <div>
          <p>
            <strong>*Turnify Marketplace User Agreement*</strong>
            <br />
            <br />
            This User Agreement (&quot;Agreement&quot;) is entered into by and between Turnify Inc.
            (&quot;Turnify&quot;) and you, the user, as an individual or entity (&quot;User&quot;).
            By accessing and using Turnify&apos;s marketplace platform (&quot;Platform&quot;), you
            agree to be bound by the terms and conditions of this Agreement. Please read this
            Agreement carefully before using the Platform. If you do not agree with any part of this
            Agreement, please do not use the Platform.
            <br />
            <br />
            <strong>*1. Purpose of Turnify Marketplace*</strong>
            <br />
            <br />
            Turnify provides a digital platform for property owners, service providers, and staff
            members to connect and facilitate property-related services, including but not limited
            to cleaning, maintenance, and guest support. The Platform enables users to post and
            request services, communicate, schedule, and process payments in a secure and efficient
            manner.
            <br />
            <br />
            <strong>*2. User Responsibilities*</strong>
            <br />
            <br />
            As a User of Turnify Marketplace, you agree to the following responsibilities:
            <br />
            <br />- Provide accurate and up-to-date information in your user profile.
            <br />- Act in good faith, communicate respectfully, and maintain professionalism when
            interacting with other Users.
            <br />- Comply with all local, state, and federal laws and regulations applicable to
            your use of the Platform.
            <br />- Ensure the safety and security of your account and login credentials.
            <br />- Use the Platform only for lawful purposes, and refrain from engaging in any
            fraudulent or harmful activities.
            <br />
            <br />
            <strong>*3. Limitations of Turnify&apos;s Responsibilities*</strong>
            <br />
            <br />
            Turnify serves solely as a platform for connecting Users and facilitating communication
            and payment processing. We are not involved in property management, nor do we assume any
            responsibility for the quality or outcome of services provided by Users. Turnify does
            not verify the accuracy or completeness of User-provided information and disclaims any
            liability for any interactions, disputes, or damages between Users.
            <br />
            <br />
            <strong>*4. Dispute Resolution*</strong>
            <br />
            <br />
            In the event of a dispute between Users, Turnify encourages parties to engage in open
            communication and amicable resolution. If a dispute cannot be resolved independently,
            Turnify is not liable for any claims, damages, or issues arising from such disputes.
            Users may, at their own discretion, utilize the Platform&apos;s dispute resolution
            system.
            <br />
            <br />
            <strong>*5. Privacy and Data Protection*</strong>
            <br />
            <br />
            Protecting your privacy and data security is important to us. Please review our Privacy
            Policy to understand how we collect, use, and protect your personal information.
            <br />
            <br />
            <strong>*6. Termination*</strong>
            <br />
            <br />
            Turnify reserves the right to suspend, terminate, or restrict your access to the
            Platform at any time, with or without cause, without notice. Users may terminate their
            accounts by notifying Turnify, subject to any outstanding obligations.
            <br />
            <br />
            <strong>*7. Changes to the Agreement*</strong>
            <br />
            <br />
            Turnify reserves the right to amend or update this Agreement at any time. We will notify
            Users of any changes, and continued use of the Platform after such notifications
            constitutes your acceptance of the revised Agreement.
            <br />
            <br />
            <strong>*8. Contact Information*</strong>
            <br />
            <br />
            If you have any questions, concerns, or inquiries related to this Agreement or the
            Turnify Marketplace, please contact us at{' '}
            <a href='mailto:contact@turnify.com'>contact@turnify.com</a>
            <br />
            <br />
            This Agreement represents the entire understanding between Turnify and Users. By using
            the Platform, you acknowledge that you have read, understood, and agreed to be bound by
            the terms of this Agreement.
          </p>
        </div>
      </div>
      <div className='mt-5'>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            value='1'
            id='agreementBox'
            onChange={(e) => {
              handleChange(e.target.checked)
            }}
          />
          <label className='form-check-label fw-bold fs-5' htmlFor='agreementBox'>
            By checking this box, I agree to pay $6/month to facilitate Turnify Marketplace.
          </label>
        </div>
      </div>
    </div>
  )
}

export default Agreement
