import React, {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProTeamAction} from '../../redux/actionTypes/proTeamActionTypes'
import * as proTeamActionCreators from '../../redux/actionCreators/proTeamActionCreators'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useEffect} from 'react'
import ProTeamTable from './ProTeamTable'
import {useParams} from 'react-router'
import {decryptText} from '../../utils/util'

const ProTeam: React.FC = () => {
  let {userId}: any = useParams()
  userId = decryptText(userId)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const dispatch = useDispatch<Dispatch<ProTeamAction>>()

  useEffect(() => {
    dispatch(proTeamActionCreators.getProTeamList(user.userSection.hostId))
  }, [])

  return (
    <>
      <PageTitle>Pro Team</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            <ProTeamTable />
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default ProTeam
