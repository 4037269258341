import React from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

type Props = {}
const EmptyOfferListCard: React.FC<Props> = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='card-px text-center py-20 my-10'>
          <div className='fs-2x fw-bold mb-10'>No Quotes Available</div>
          <div className='text-gray-400 fs-4 fw-semibold mb-10'>
            Currently, there are no quotes available for the selected service. You can create a
            checklist to start receiving tailored quotes from service providers around you.
          </div>
        </div>
        <div className='text-center px-4'>
          <img
            className='mw-100 mh-300px'
            alt=''
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
          ></img>
        </div>
      </div>
    </div>
  )
}

export default EmptyOfferListCard
