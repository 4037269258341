import React from 'react';
import { Spinner } from 'react-bootstrap-v5';

type Props = {
    classNames?: string,
    deleteFn: () => void,
    type?: 'submit' | 'button',
    iconWidth?: string,
    iconHeight?: string,
    spinner?: boolean,
    disabled?: boolean
}
const DeleteButton: React.FC<Props> = ({ classNames, deleteFn, type, iconWidth, iconHeight, spinner, disabled }) => {
    return (
        <button disabled={disabled} type={type ? type : "button"} className={classNames ? classNames : 'btn btn-outline-danger me-5'} onClick={() => { deleteFn() }}>
            {spinner ? <Spinner animation="border" size="sm" /> : <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth ? iconWidth : "24"} height={iconHeight ? iconHeight : "24"} fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
            </svg>}
        </button>
    )
}

export default DeleteButton;