import { Dispatch } from "react";
import { Modal } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { StaffAction } from "../../redux/actionTypes/staffActionTypes";
import { RedusxAppState } from "../../redux/reducers/rootReducer";
import ManagerForm from "../../umut-components/Forms/ManagerForm";
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators';

const StaffDialog = () => {
      const { staffAddDialog } = useSelector((state: RedusxAppState) => state.staff);
      const dispatch = useDispatch<Dispatch<StaffAction>>();
  
      const closeModal = () => {
          dispatch(staffActionCreators.closeStaffAddDialog());
      }
  
      return (
          <Modal show={staffAddDialog.dialogOpen} onHide={closeModal} centered>
              <ModalHeader closeButton>
                  <Modal.Title> Manager </Modal.Title>
              </ModalHeader>
              <Modal.Body>
                  <ManagerForm staff={staffAddDialog.staff} />
              </Modal.Body>
          </Modal>
      )
  }
  
  export default StaffDialog;