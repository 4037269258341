import React, {Dispatch, useEffect, useState} from 'react'
import {AxiosResponse} from 'axios'
import {Button, Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import {JobStatusType} from '../../enums/JobEnum'
import {IJobDetail} from '../../interfaces/Job'
import ApiCalls from '../../network/ApiCalls'
import {openCancelJobDialog, setJobDetail} from '../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import IssuesTab from './Tabs/IssuesTab/IssuesTab'
import NotesTab from './Tabs/NotestTab/NotesTab'
import PhotosTab from './Tabs/PhotosTab/PhotosTab'
import ServiceTab from './Tabs/ServiceTab/ServiceTab'
import StaffTab from './Tabs/StaffTab/StaffTab'
import TasksTab from './Tabs/TasksTab/TasksTab'
import {
  convertUTCDateToLocalDate,
  decryptText,
  encryptText,
  toConvertedDate,
} from '../../utils/util'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ForceFinishDialog from './forceFinishDialog'
import JobCancelDialog from '../jobs/JobCancelDialog'
import dayjs from 'dayjs'
import JobReviewModal from './JobReviewModal'

type TabNames =
  | 'services'
  | 'photos'
  | 'notes'
  | 'tasks'
  | 'reported-issues'
  | 'property'
  | 'staff'
  | 'vendor'
  | 'client'

const JobDetails = () => {
  let {jobId}: any = useParams()
  jobId = decryptText(jobId)

  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [selectedTab, setSelectedTab] = useState<TabNames>('services')
  const [jobEventsActive, setJobEventsActive] = useState<boolean>(false)
  const [forceFinishModalOpen, setForceFinishModalOpen] = useState(false)
  const [showJobReviewModal, setShowJobReviewModal] = useState(false)

  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)

  const jobScheduleDay = dayjs(
    convertUTCDateToLocalDate(jobDetail?.scheduledStart, jobDetail?.hostPropertyCityTimeZone)
  )
  const jobExpectedDay = dayjs(
    convertUTCDateToLocalDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostPropertyCityTimeZone)
  )
  const tomorrow = dayjs().add(1, 'day')
  const isPast =
    tomorrow.diff(jobScheduleDay, 'hours') >= 0 || tomorrow.diff(jobExpectedDay, 'hours') >= 0

  const {data, isLoading, error, refetch} = useQuery<AxiosResponse<IJobDetail>>(
    ['Get Job Detail'],
    () => ApiCalls.getJobDetails(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )
  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }
  const closeTypeModal = () => {
    setForceFinishModalOpen(false)
  }

  useEffect(() => {
    if (data) {
      setJobEventsActive(
        !(
          data?.data?.jobStatusTypeName === JobStatusType.COMPLETED ||
          data?.data?.jobStatusTypeName === JobStatusType.CANCELLED
        )
      )
      dispatch(setJobDetail(data.data))
    }
    return () => {
      dispatch(setJobDetail(null))
    }
  }, [data])

  if (isLoading) {
    return <div className='d-flex justify-content-center p-6'>Loading...</div>
  }

  if (error) {
    return <div className='d-flex justify-content-center p-6'>Error</div>
  }

  return (
    <>
      <PageTitle>Job Detail</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Header className='w-100 d-flex justify-content-between flex-row mt-7'>
            <div>
              <strong className='fs-5'>
                {' '}
                <a
                  href={'/properties/detail/' + encryptText(data?.data?.hostPropertyId)}
                  target='_blank'
                >
                  {data?.data.hostProperty.address}
                </a>
                {data?.data.isManuallyCreated && (
                  <span style={{color: '#ff5d5d', fontSize: 14, marginLeft: 3}}>ⓜ</span>
                )}{' '}
              </strong>
              <div className='mt-3'>
                {/* <span className='badge badge-primary badge me-3'>{jobId}</span> */}
                <span className='badge badge-info me-3'>
                  {data?.data?.jobStatusTypeName === 'Started'
                    ? 'In Progress'
                    : data?.data?.jobStatusTypeName}
                </span>
                <span className='badge badge-warning me-3'>
                  {data?.data?.hostProperty.isMarketplace
                    ? 'Marketplace'
                    : data?.data?.hostProperty.cleaningProvider.name}
                </span>
                {data?.data?.scheduledStart ? (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledStart,
                      data?.data?.hostPropertyCityTimeZone
                    )}{' '}
                  </span>
                ) : (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodStart,
                      data?.data?.hostPropertyCityTimeZone
                    )}{' '}
                  </span>
                )}

                {data?.data?.scheduledFinish ? (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledFinish,
                      data?.data?.hostPropertyCityTimeZone
                    )}
                  </span>
                ) : (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodEnd,
                      data?.data?.hostPropertyCityTimeZone
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className='d-flex mt-5'>
              {jobDetail?.jobStatusTypeName !==
                (JobStatusType.CANCELLED && JobStatusType.COMPLETED) && (
                <div>
                  <Button
                    className='btn btn-sm btn-danger'
                    children='Cancel Job'
                    onClick={() => {
                      dispatch(openCancelJobDialog(refetch, data?.data))
                    }}
                  />
                </div>
              )}
              {jobDetail?.cleaningProviderId == 'My Team' ||
                (jobDetail?.cleaningProviderId == 'Turnify+' &&
                  (jobDetail?.jobStatusTypeName === JobStatusType.SCHEDULED ||
                    jobDetail?.jobStatusTypeName === JobStatusType.STARTED) && (
                    <div>
                      <button
                        onClick={() => setForceFinishModalOpen(true)}
                        type='button'
                        className='btn btn-sm btn-success'
                        style={{float: 'right', marginLeft: '15px'}}
                      >
                        Force Finish
                      </button>
                    </div>
                  ))}
              {data?.data?.cleaningProviderId == 'Turnify PRO' &&
                [4, 5, 6].includes(data?.data?.jobStatusTypeId) &&
                !data?.data?.hasVendorScore && (
                  <div>
                    <button
                      onClick={() => setShowJobReviewModal(true)}
                      type='button'
                      className='btn btn-sm btn-info'
                      style={{float: 'right', marginLeft: '15px'}}
                    >
                      Review Job
                    </button>
                  </div>
                )}
            </div>
          </Card.Header>
          <Card.Body>
            <Tabs defaultActiveKey='services' onSelect={handleChangeTab}>
              <Tab eventKey='services' title='Services'>
                {selectedTab === 'services' && data?.data ? (
                  <ServiceTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              <Tab eventKey='photos' title='Checklist'>
                {selectedTab === 'photos' ? <PhotosTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>

              <Tab eventKey='notes' title='Notes'>
                {selectedTab === 'notes' ? <NotesTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='tasks' title='Tasks'>
                {selectedTab === 'tasks' && data?.data ? (
                  <TasksTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              <Tab eventKey='reported-issues' title='Reported Issues'>
                {selectedTab === 'reported-issues' && data?.data ? (
                  <IssuesTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              {/* <Tab eventKey='property' title='Property Details'>
                                {selectedTab === "property" && data?.data ? <PropertyTab jobEventsActive={jobEventsActive} hostPropertyId={data?.data?.hostPropertyId} /> : null}
                            </Tab> */}
              {data?.data?.cleaningProviderId === 'My Team' ||
                (jobDetail?.cleaningProviderId == 'Turnify+' && (
                  <Tab eventKey='staff' title='Staff Detail'>
                    {selectedTab === 'staff' ? (
                      <StaffTab jobEventsActive={jobEventsActive} />
                    ) : null}
                  </Tab>
                ))}
            </Tabs>
          </Card.Body>
          {forceFinishModalOpen && (
            <ForceFinishDialog modalOpen={forceFinishModalOpen} handleClose={closeTypeModal} />
          )}
        </Card>
        <JobCancelDialog />
        <JobReviewModal
          modalOpen={showJobReviewModal}
          handleClose={() => {
            setShowJobReviewModal(false)
          }}
          vendorId={data?.data?.vendorId}
          jobId={data?.data?.id}
          refetch={refetch}
        />
      </div>
    </>
  )
}

export default JobDetails
