import React, {Dispatch, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {IHostPropertyInput, IHostPropertyAutomation} from '../../interfaces/Property'
import {useState} from 'react'
import LocationStep from './StepTabs/LocationStep'
import AutomationStep from './StepTabs/AutomationStep'
import PropertyDetailStep from './StepTabs/PropertyDetailStep'
import RoomBedStep from './StepTabs/RoomBedStep'
import {Card, Spinner, Tab, Tabs} from 'react-bootstrap-v5'
import ApiCalls from '../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useDialog} from '../../contexts/DialogContext'
import {useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import StepNav from '../StepNav/StepNav'
import JobHistoryStep from './StepTabs/JobHistoryStep'
import {HostPropertyStatus} from '../../enums/PropertyEnum'
import dayjs from 'dayjs'
import PropertyOfferedStep from './StepTabs/PropertyOfferedStep'
import StaffPriorityStep from './StepTabs/StaffPriorityStep'
import PermissionStep from './StepTabs/PermissionStep'
import {encryptText} from '../../utils/util'
import {OpenDeletePropertyModal} from '../../redux/actionCreators/hostPropertyActionCreators'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import {HostPropertyAction} from '../../redux/actionTypes/hostPropertyTypes'
import ReportedIssuesStep from './StepTabs/ReportedIssuesStep'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import JobDialog from '../../pages/jobs/JobDialog'
import PaymentStep from './StepTabs/PaymentStep'
import ChecklistStep from './StepTabs/ChecklistStep'
import MarketplaceOfferedRatesStep from './StepTabs/MarketplaceOfferedRatesStep'

type Props = {
  propertyId?: number
  type: 'pms' | 'manual'
  editModalClose?: () => void
}

type TabNames =
  | 'property-detail'
  | 'location'
  | 'automation'
  | 'rooms'
  | 'permission'
  | 'job-history'
  | 'services'
  | 'staff-priority'
  | 'reported-issues'
  | 'payments'
  | 'checklists'
  | 'mp-offers'

const PropertyAddWizard: React.FC<Props> = ({propertyId, type, editModalClose}) => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const jobDispatch = useDispatch<Dispatch<JobAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showSuccessPropertyDialog, showFailureDialog} = useDialog()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState<TabNames>('permission')
  const [savedValues, setSavedValues] = useState<IHostPropertyInput | null>(null)
  const [missingField, setMissingField] = useState<boolean>(false)
  const {
    data: hostPropertyData,
    error: hostPropertyError,
    isLoading: hostPropertyLoading,
    isFetching: hostPropertyFetching,
    refetch: refetchProperty,
  } = useQuery(
    ['Get Host Property By Id', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId ? propertyId : 0),
    {refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )

  const handleChangeHostProperty = (changedValues: IHostPropertyInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  const isPropertyValid = () => {
    if (
      !savedValues?.address ||
      !savedValues?.zipCode ||
      !savedValues?.latitude ||
      !savedValues?.longitude ||
      !savedValues?.country ||
      !savedValues?.state ||
      !savedValues?.city
    ) {
      showFailureDialog('Please fill Location information')
      return false
    }

    if (savedValues.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (savedValues?.cleaningProviderId == 2 || savedValues?.cleaningProviderId == 3) {
      if (
        !savedValues?.hostPropertyAccess?.unitAccessTypeId ||
        !savedValues?.hostPropertyAccess?.unitAccessValue ||
        !savedValues?.hostPropertyAccess?.buildingAccessTypeId ||
        !savedValues?.hostPropertyAccess?.buildingAccessValue
      ) {
        showFailureDialog('Please fill Access information')
        return false
      }
    }

    /*if (!savedValues?.hostPropertyAutomation?.usePMS) {
      if (
        savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync != undefined &&
        savedValues?.hostPropertyAutomation.isAutomaticCalendarSync
      ) {
        if (
          savedValues.hostPropertyICals?.length === 0 ||
          savedValues.hostPropertyICals?.some((ical) => ical.icalUrl === '' || !ical.icalUrl)
        ) {
          showFailureDialog('Please provide ICal url')
          return false
        }
      }
    }*/

    if (
      savedValues.hasStorage &&
      (savedValues.hostPropertyStorages?.length === 0 ||
        !savedValues.hostPropertyStorages?.[0].storageDescription ||
        savedValues.hostPropertyStorages?.[0].storageDescription === '')
    ) {
      showFailureDialog('Please provide at least one storage description')
      return false
    }

    if (
      savedValues.hasParking &&
      (!savedValues.parkingDescription || savedValues.parkingDescription === '')
    ) {
      showFailureDialog('Please enter parking description.')
      return false
    }

    return true
  }

  const handlePostProperty = async () => {
    if (isPropertyValid()) {
      setLoading(true)
      setMissingField(false)
      let name =
        (savedValues?.address ? savedValues?.address : '') +
        (savedValues?.suite ? '#' + savedValues?.suite : '')
      const requestData: IHostPropertyInput = {
        ...savedValues,
        name,
        hostId: user.userSection.hostId,
        numberOfCleaner: 0,
        parkingDescription: savedValues?.hasParking ? savedValues.parkingDescription : undefined,
        hasParking: savedValues?.hasParking !== undefined ? savedValues?.hasParking : false,
        hasDryer: savedValues?.hasLaundry
          ? savedValues?.hasDryer !== undefined
            ? savedValues?.hasDryer
            : false
          : false,
        hasLaundry: savedValues?.hasLaundry !== undefined ? savedValues?.hasLaundry : false,
        hasStorage: savedValues?.hasStorage !== undefined ? savedValues?.hasStorage : false,
        sameDayBooking:
          savedValues?.sameDayBooking !== undefined ? savedValues?.sameDayBooking : false,
        numberOfBedrooms: savedValues?.numberOfBedrooms ? savedValues?.numberOfBedrooms : 0,
        numberOfBathrooms: savedValues?.numberOfBathrooms ? savedValues?.numberOfBathrooms : 0,
        numberOfBeds: savedValues?.numberOfBeds ? savedValues?.numberOfBeds : 0,
        hostPropertyStorages: savedValues?.hasStorage
          ? savedValues?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          savedValues?.hostPropertyAccess && Object.keys(savedValues?.hostPropertyAccess).length > 0
            ? savedValues.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...savedValues?.hostPropertyAutomation,
          isAutomaticSchedule:
            savedValues?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //savedValues?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? savedValues?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS: false,
          checkInTime: savedValues?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: savedValues?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !savedValues?.hostPropertyAutomation?.usePMS
          ? savedValues?.hostPropertyICals
          : [],
        cleaningProviderId: 1, //Refers to My Team
      }
      ApiCalls.postHostProperty(requestData)
        .then((res) => {
          setLoading(false)
          showSuccessPropertyDialog(
            "Your property has been added successfully. You can now view and manage your property by accessing the dashboard. If you need further assistance, please don't hesitate to contact our support team."
          )
          history.push('/properties')
        })
        .catch((err) => {
          setLoading(false)
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        })
    } else {
      setMissingField(true)
    }
  }

  const handleUpdateProperty = async () => {
    if (isPropertyValid() && propertyId) {
      setLoading(true)
      setMissingField(false)
      let name =
        (savedValues?.address ? savedValues?.address : '') +
        (savedValues?.suite ? '#' + savedValues?.suite : '')
      const requestData: IHostPropertyInput = {
        ...savedValues,
        name,
        hostId: user.userSection.hostId,
        cleaningProviderId:
          savedValues?.cleaningProviderId == 4 ? 2 : savedValues?.cleaningProviderId,
        isMarketplace: savedValues?.cleaningProviderId == 4 ? true : false,
        numberOfCleaner: 0,
        propertySize: savedValues?.propertySize != undefined ? +savedValues.propertySize : 0,
        parkingDescription: savedValues?.hasParking ? savedValues.parkingDescription : undefined,
        hasParking: savedValues?.hasParking !== undefined ? savedValues?.hasParking : false,
        hasDryer: savedValues?.hasLaundry
          ? savedValues?.hasDryer !== undefined
            ? savedValues?.hasDryer
            : false
          : false,
        hasLaundry: savedValues?.hasLaundry !== undefined ? savedValues?.hasLaundry : false,
        hasStorage: savedValues?.hasStorage !== undefined ? savedValues?.hasStorage : false,
        sameDayBooking:
          savedValues?.sameDayBooking !== undefined ? savedValues?.sameDayBooking : false,
        numberOfBedrooms: savedValues?.numberOfBedrooms ? savedValues?.numberOfBedrooms : 0,
        numberOfBathrooms: savedValues?.numberOfBathrooms ? savedValues?.numberOfBathrooms : 0,
        numberOfBeds: savedValues?.numberOfBeds ? savedValues?.numberOfBeds : 0,
        hostPropertyStorages: savedValues?.hasStorage
          ? savedValues?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          savedValues?.hostPropertyAccess && Object.keys(savedValues?.hostPropertyAccess).length > 0
            ? savedValues.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...savedValues?.hostPropertyAutomation,
          isAutomaticSchedule:
            savedValues?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //savedValues?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? savedValues?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS:
            savedValues?.hostPropertyAutomation?.usePMS !== undefined
              ? savedValues?.hostPropertyAutomation?.usePMS
              : false,
          checkInTime: savedValues?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: savedValues?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !savedValues?.hostPropertyAutomation?.usePMS
          ? savedValues?.hostPropertyICals
          : [],
      }
      ApiCalls.updateHostProperty(propertyId, requestData)
        .then((res) => {
          setLoading(false)
          showSuccessDialog('Property is successfully updated.')
          history.push('/properties')
        })
        .catch((err) => {
          setLoading(false)
          if (
            err?.response?.data?.errors?.[0]?.message == 'icalUrl must be an URL address' ||
            err?.response?.data?.message == 'icalUrl must be an URL address'
          ) {
            showFailureDialog(
              'Error: Invalid iCal URL.',
              'Please enter a valid URL for the iCal feed. This should be a direct link to your calendar, ending with the file extension ".ics". Make sure there are no typos or extra characters in the URL.'
            )
          } else {
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          }
        })
    } else {
      setMissingField(true)
      //showFailureDialog('Please fill all necessary fields')
    }
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  useEffect(() => {
    if (hostPropertyData) {
      setSavedValues({
        ...hostPropertyData?.data?.hostProperty,
        ...hostPropertyData?.data,
        hostProperty: undefined,
        numberOfBathrooms: parseFloat(
          hostPropertyData?.data?.numberOfBathrooms ? hostPropertyData?.data?.numberOfBathrooms : 0
        ),
      })
    }
  }, [hostPropertyData])

  useEffect(() => {
    setMissingField(
      hostPropertyData?.data?.hostProperty?.hostPropertyStatusTypeId ===
        HostPropertyStatus['Property Fields Not Set']
    )
  }, [hostPropertyData])

  if (hostPropertyLoading || hostPropertyFetching) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <Spinner className='align-self-center' animation='border' />
      </div>
    )
  }

  if (propertyId && !hostPropertyData?.data?.hostProperty) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <strong>Property cannot found.</strong>
        <button className='btn btn-danger mt-6' onClick={editModalClose}>
          Back to Property List
        </button>
      </div>
    )
  }

  return (
    <>
      <Card>
        <Card.Body>
          {propertyId && (
            <>
              <div className='d-flex justify-content-between'>
                <div className='mb-5 flex'>
                  <span className='badge badge-info py-2'>
                    {hostPropertyData?.data?.hostProperty?.name}
                  </span>
                </div>
                <div>
                  {/* <button type="button" className="btn btn-sm me-6 btn-success" onClick={() => jobDispatch(jobActionCreator.openJobDialog(hostPropertyData?.data?.hostProperty?.id))}>
                                    Add Job
                                </button> */}
                  {/* <button
                    className='btn btn-light-warning btn-sm me-6'
                    onClick={() => history.push('/checklists/' + encryptText(propertyId))}
                  >
                    Checklists
                  </button> */}
                  <button
                    className='btn btn-danger btn-sm'
                    onClick={() => dispatch(OpenDeletePropertyModal(propertyId))}
                  >
                    Delete Property
                  </button>
                </div>
              </div>
              {propertyId &&
                hostPropertyData?.data?.hostProperty.cleaningProviderId == 1 &&
                !hostPropertyData?.data?.hostProperty.stripeAccountCardId && (
                  <div className='mt-5'>
                    <div className='alert alert-warning'>
                      Please provide a payment card so your jobs can be scheduled
                    </div>
                  </div>
                )}
            </>
          )}
          {!propertyId ? (
            <StepWizard
              nav={<StepNav stepNames={['Location', 'Property Detail', 'Automation', 'Rooms']} />}
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
              }}
            >
              <LocationStep
                missingField={missingField}
                location={{
                  address: hostPropertyData?.data?.hostProperty?.address,
                  city: hostPropertyData?.data?.hostProperty?.city,
                  state: hostPropertyData?.data?.hostProperty?.state,
                  country: hostPropertyData?.data?.hostProperty?.country,
                  latitude: hostPropertyData?.data?.hostProperty?.latitude,
                  longitude: hostPropertyData?.data?.hostProperty?.longitude,
                  suite: hostPropertyData?.data?.hostProperty?.suite,
                  zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
                }}
                pmsReferenceId={hostPropertyData?.data?.pmsReferenceId}
                handleChangeHostProperty={handleChangeHostProperty}
              />
              <PropertyDetailStep
                missingField={missingField}
                values={{
                  parkingDescription: hostPropertyData?.data?.hostProperty?.parkingDescription,
                  cleaningProviderId: hostPropertyData?.data?.hostProperty?.cleaningProviderId,
                  hasDryer: hostPropertyData?.data?.hostProperty?.hasDryer,
                  hasLaundry: hostPropertyData?.data?.hostProperty?.hasLaundry,
                  hasParking: hostPropertyData?.data?.hostProperty?.hasParking,
                  hasStorage: hostPropertyData?.data?.hostProperty?.hasStorage,
                  storageDescription:
                    hostPropertyData?.data?.hostPropertyStorages.lenght > 0
                      ? hostPropertyData?.data?.hostPropertyStorages[0].storageDescription
                      : '',
                  sameDayBooking: hostPropertyData?.data?.hostProperty?.sameDayBooking,
                  numberOfCleaner: hostPropertyData?.data?.hostProperty?.numberOfCleaner,
                  propertySize: hostPropertyData?.data?.hostProperty?.propertySize || 0,
                  laundryDryerCycle: hostPropertyData?.data?.hostProperty?.laundryDryerCycle,
                  laundryInQuote: hostPropertyData?.data?.hostProperty?.laundryInQuote,
                  laundryLinenNumber: hostPropertyData?.data?.hostProperty?.laundryLinenNumber,
                  laundryMachineCycle: hostPropertyData?.data?.hostProperty?.laundryMachineCycle,
                  laundryToBeDone: hostPropertyData?.data?.hostProperty?.laundryToBeDone,
                }}
                hostPropertyAccess={hostPropertyData?.data?.hostPropertyAccess}
                hostPropertyOthers={hostPropertyData?.data?.hostPropertyOthers}
                hostPropertyStorage={hostPropertyData?.data?.hostPropertyStorage}
                handleChangeHostProperty={handleChangeHostProperty}
              />
              <AutomationStep
                missingField={missingField}
                type={type}
                sameDayBooking={hostPropertyData?.data?.hostProperty?.sameDayBooking}
                hostPropertyAutomation={{
                  ...hostPropertyData?.data?.hostPropertyAutomation,
                  isAutomaticCalendarSync: true,
                  isAutomaticSchedule: true,
                }}
                handleChangeHostProperty={handleChangeHostProperty}
              />
              <RoomBedStep
                handleChangeHostProperty={handleChangeHostProperty}
                loading={loading}
                handlePostProperty={handlePostProperty}
                hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms}
                numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds}
                numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms}
                numberOfBathrooms={parseFloat(
                  hostPropertyData?.data?.hostProperty?.numberOfBathrooms
                )}
                pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
              />
            </StepWizard>
          ) : (
            <Tabs
              defaultActiveKey='property-detail'
              id='uncontrolled-tab-example'
              className='mb-3'
              onSelect={handleChangeTab}
            >
              <Tab eventKey='property-detail' title='Property Detail'>
                <LocationStep
                  missingField={true}
                  editMode={true}
                  location={{
                    address: hostPropertyData?.data?.hostProperty?.address,
                    city: hostPropertyData?.data?.hostProperty?.city,
                    state: hostPropertyData?.data?.hostProperty?.state,
                    country: hostPropertyData?.data?.hostProperty?.country,
                    latitude: hostPropertyData?.data?.hostProperty?.latitude,
                    longitude: hostPropertyData?.data?.hostProperty?.longitude,
                    suite: hostPropertyData?.data?.hostProperty?.suite,
                    zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
                  }}
                  staticMapUrl={hostPropertyData?.data?.hostProperty?.staticMapUrl}
                  pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  handleChangeHostProperty={handleChangeHostProperty}
                />
                <div className='d-flex  flex-row p-5'>
                  <RoomBedStep
                    disabled={hostPropertyData?.data?.hostProperty?.cleaningProviderId === 2}
                    editMode={true}
                    handleChangeHostProperty={handleChangeHostProperty}
                    hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms}
                    numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds}
                    numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms}
                    numberOfBathrooms={parseFloat(
                      hostPropertyData?.data?.hostProperty?.numberOfBathrooms
                    )}
                    pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  />
                </div>

                <PropertyDetailStep
                  missingField={true}
                  editMode={true}
                  values={{
                    parkingDescription: hostPropertyData?.data?.hostProperty?.parkingDescription,
                    cleaningProviderId: hostPropertyData?.data?.hostProperty?.cleaningProviderId,
                    hasDryer: hostPropertyData?.data?.hostProperty?.hasDryer,
                    hasLaundry: hostPropertyData?.data?.hostProperty?.hasLaundry,
                    hasParking: hostPropertyData?.data?.hostProperty?.hasParking,
                    hasStorage: hostPropertyData?.data?.hostProperty?.hasStorage,
                    storageDescription:
                      hostPropertyData?.data?.hostPropertyStorages.lenght > 0
                        ? hostPropertyData?.data?.hostPropertyStorages[0].storageDescription
                        : '',
                    sameDayBooking: hostPropertyData?.data?.hostProperty?.sameDayBooking,
                    numberOfCleaner: hostPropertyData?.data?.hostProperty?.numberOfCleaner,
                    propertySize: +hostPropertyData?.data?.hostProperty?.propertySize || 0,
                    laundryDryerCycle: hostPropertyData?.data?.hostProperty?.laundryDryerCycle,
                    laundryInQuote: hostPropertyData?.data?.hostProperty?.laundryInQuote,
                    laundryLinenNumber: hostPropertyData?.data?.hostProperty?.laundryLinenNumber,
                    laundryMachineCycle: hostPropertyData?.data?.hostProperty?.laundryMachineCycle,
                    laundryToBeDone: hostPropertyData?.data?.hostProperty?.laundryToBeDone,
                    isMarketplace: hostPropertyData?.data?.hostProperty?.isMarketplace,
                  }}
                  hostPropertyAccess={hostPropertyData?.data?.hostPropertyAccess}
                  hostPropertyOthers={hostPropertyData?.data?.hostPropertyOthers}
                  hostPropertyStorage={hostPropertyData?.data?.hostPropertyStorages}
                  handleChangeHostProperty={handleChangeHostProperty}
                />
              </Tab>

              <Tab eventKey='automation' title='Automation Settings'>
                <AutomationStep
                  missingField={missingField}
                  editMode={true}
                  type={type}
                  hostPropertyICals={hostPropertyData?.data?.hostPropertyICals}
                  sameDayBooking={hostPropertyData?.data?.hostProperty?.sameDayBooking}
                  hostPropertyAutomation={{
                    ...hostPropertyData?.data?.hostPropertyAutomation,
                    isAutomaticCalendarSync:
                      hostPropertyData?.data.hostPropertyAutomation.isAutomaticSchedule,
                  }}
                  pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  handleChangeHostProperty={handleChangeHostProperty}
                />
              </Tab>
              <Tab eventKey='checklists' title='Checklists'>
                {selectedTab === 'checklists' && <ChecklistStep propertyId={propertyId} />}
              </Tab>

              <Tab eventKey='job-history' title='Jobs'>
                {selectedTab === 'job-history' && <JobHistoryStep />}
              </Tab>
              {savedValues?.isMarketplace && (
                <Tab eventKey='mp-offers' title='Marketplace Offers'>
                  {selectedTab === 'mp-offers' && <MarketplaceOfferedRatesStep />}
                </Tab>
              )}
              <Tab eventKey='permission' title='Staff Access'>
                {selectedTab === 'permission' && (
                  <PermissionStep
                    providerType={savedValues?.cleaningProviderId}
                    isMarketplace={savedValues?.isMarketplace}
                  />
                )}
              </Tab>
              {(savedValues?.cleaningProviderId != 2 ||
                (savedValues?.cleaningProviderId === 2 && !savedValues.isMarketplace)) && (
                <Tab eventKey='staff-priority' title='Staff Prioritization'>
                  {selectedTab === 'staff-priority' && (
                    <StaffPriorityStep providerType={savedValues?.cleaningProviderId} />
                  )}
                </Tab>
              )}
              {(savedValues?.cleaningProviderId != 2 ||
                (savedValues?.cleaningProviderId === 2 && !savedValues.isMarketplace)) && (
                <Tab eventKey='reported-issues' title='Reported Issues'>
                  {selectedTab === 'reported-issues' && <ReportedIssuesStep />}
                </Tab>
              )}
              {savedValues?.cleaningProviderId === 2 && (
                <Tab eventKey='services' title='Services'>
                  {selectedTab === 'services' && <PropertyOfferedStep />}
                </Tab>
              )}
              <Tab eventKey='payments' title='Payments'>
                {selectedTab === 'payments' && (
                  <PaymentStep
                    property={hostPropertyData?.data?.hostProperty}
                    propertyId={hostPropertyData?.data?.id}
                    refetchProperty={refetchProperty}
                  />
                )}
              </Tab>
            </Tabs>
          )}
        </Card.Body>
        {selectedTab !== 'staff-priority' && selectedTab != 'payments' && propertyId ? (
          <Card.Footer className='d-flex align-items-center justify-content-between'>
            <button className='btn btn-danger' onClick={editModalClose}>
              Cancel
            </button>
            <button className='btn btn-success' disabled={loading} onClick={handleUpdateProperty}>
              Save Changes {loading ? <Spinner size='sm' animation='border' /> : null}
            </button>
          </Card.Footer>
        ) : null}
      </Card>
      <JobDialog />
    </>
  )
}

export default PropertyAddWizard
