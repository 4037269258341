/* eslint-disable jsx-a11y/anchor-is-valid */
import {yupResolver} from '@hookform/resolvers/yup'
import React, {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useForm} from 'react-hook-form'
import {useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {useDialog} from '../../../../contexts/DialogContext'
import ApiCalls from '../../../../network/ApiCalls'
import {RegisterRequest} from '../../../../network/PostRequestModels'
import PhoneTextInput from '../../../../umut-components/Inputs/PhoneInput'
import {setErrorObject} from '../../../../utils/util'
import Policy from '../Policy'
import TermsOfUse from '../TermsOfUse'
import ReactPixel from 'react-facebook-pixel'
import ReCAPTCHA from 'react-google-recaptcha'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IHostPropertyManualInput} from '../../../../interfaces/Property'

type PageProps = {
  handleChangeHostInfo: (changedValues: RegisterRequest) => void
  onComplete: () => void
  onBack: () => void
  missingField?: boolean
}

type InputType = {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordAgain: string
  companyName: string
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please Enter Valid Email'
    )
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 digits')
    .required('Password is required'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password'), null], 'Password must be matched'),
  companyName: Yup.string().nullable(),
})

const defaultValues: InputType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordAgain: '',
  companyName: '',
}

const AboutYourself: React.FC<PageProps> = ({handleChangeHostInfo, onComplete, onBack}) => {
  const history = useHistory()
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | undefined>('')
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const {data: countries} = useQuery('Get Countries', () => ApiCalls.getCountries(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('')
  const [validationError, setValidationError] = useState<any>({})
  const [policyModal, setPolicyModal] = useState(false)
  const [policyType, setPolicyType] = useState<string>('policy')

  const {control, formState, handleSubmit, reset, register} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const captchaKeyLive = '6LfEvBgmAAAAAIy74lLwSwBfj2o9AmGkWAoZ00wN'
  const captchaKeyDev = '6LfAhBgmAAAAACgOmw3dvLTZdzwCEM7ApPtxO9tB'

  const {isValid, dirtyFields, errors} = formState

  const onLogin = async (data: InputType) => {
    if (phoneNumber) {
      const host: RegisterRequest = {
        hostUserInfo: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          phoneNumber: phoneNumber,
        },
        hostInfo: {
          name: data.companyName,
        },
      }
      handleChangeHostInfo(host)
      onComplete()
    } else {
      showFailureDialog('Phone is not valid!')
      setLoading(false)
      setErrorText('Phone is not valid!')
    }
  }

  const recaptchaChange = (d: any) => {
    setCaptchaVerified(true)
  }

  const modalClose = () => {
    setPolicyModal(false)
  }

  useEffect(() => {
    if (countries) {
      setSelectedCountryCode('(+1)')
    }
  }, [countries])

  const onBackStepClick = () => {
    onBack()
  }

  return (
    <>
      <form
        className='form w-100'
        onSubmit={handleSubmit(onLogin)}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='d-flex justify-content-center'>
          <div className=' mw-600px'>
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Company Name {<span style={{color: 'red'}}>*</span>}
              </label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                autoComplete='off'
                {...register('companyName')}
                required
              />
              <p style={{color: 'red', fontWeight: 'bold'}}>{errors.companyName?.message}</p>
            </div>
            {/* end::Form group */}

            <div className='row'>
              {/* begin::Form group */}
              <div className='fv-row col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  First Name {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  autoComplete='off'
                  {...register('firstName')}
                  required
                />
                <p style={{color: 'red', fontWeight: 'bold'}}>{errors.firstName?.message}</p>
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='fv-row col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  Last Name {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  autoComplete='off'
                  {...register('lastName')}
                  required
                />
                <p style={{color: 'red', fontWeight: 'bold'}}>{errors.lastName?.message}</p>
              </div>
              {/* end::Form group */}
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Email {<span style={{color: 'red'}}>*</span>}
              </label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='email'
                autoComplete='off'
                {...register('email')}
                required
              />
              <p style={{color: 'red', fontWeight: 'bold'}}>{errors.email?.message}</p>
              {/* { validationError["email"]&&<small>{validationError["email"]}</small>} */}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    Password {<span style={{color: 'red'}}>*</span>}
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                {...register('password')}
                type='password'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                required
              />
              <p style={{color: 'red', fontWeight: 'bold'}}>{errors.password?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    Confirm Password {<span style={{color: 'red'}}>*</span>}
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                {...register('passwordAgain')}
                type='password'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                required
              />
              <p style={{color: 'red', fontWeight: 'bold'}}>{errors.passwordAgain?.message}</p>
            </div>
            {/* end::Form group */}

            <div className='row mb-10'>
              {/* begin::Form group */}
              <div className='fv-row col-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  Phone Number {<span style={{color: 'red'}}>*</span>}
                </label>
                <PhoneTextInput onChange={setPhoneNumber} />
              </div>
              {/* end::Form group */}
            </div>

            {/* begin:: ErrorText */}
            <strong style={{color: 'red'}}>{errorText}</strong>
            {/* end:: ErrorText */}
          </div>
        </div>
        <div className='d-flex justify-content-between flex-row w-100 mt-20'>
          <div className='mr-2'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary me-3'
              data-kt-stepper-action='previous'
              onClick={() => {
                onBackStepClick()
              }}
            >
              {'< Back'}
            </button>
          </div>
          <div>
            <button type='submit' className='btn btn-lg btn-primary' data-kt-stepper-action='next'>
              {'Continue >'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default AboutYourself
