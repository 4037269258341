import { IJob, IJobChecklistPhoto, IJobDetail, IJobIssue, IJobTask, JobTabNames } from "../../interfaces/Job";
import { IStaffOfferedService } from "../../interfaces/Staff";
import * as actions from "../actionTypes/jobTypes";

export interface JobState {
    jobDialog: {
        modalOpen: boolean,
        propertyId: number | null
        jobForm?: IStaffOfferedService | null
    },
    serviceAddDialog: {
        modalOpen: boolean,
        refetch: () => void,
        services: [] | null
    },
    jobTaskDialog: {
        modalOpen: boolean,
        refetch: () => void,
        taskData?: IJobTask | null
    },
    jobIssueMediaDialog: {
        modalOpen: boolean,
        issue: IJobIssue | null
    },
    checklistPhotoDialog: {
        modalOpen: boolean,
        checklistPhoto: IJobChecklistPhoto | null
    },
    reviewDialog: {
        modalOpen: boolean,
        refetch: () => void,
        serviceTypeId: number | null
    },
    cancelJobDialog: {
        modalOpen: boolean,
        refetch: () => void,
        job: IJob | null
    },
    jobDetail: IJobDetail | null,
    selectedJobTab: JobTabNames
}

const initialState: JobState = {
    jobDialog: {
        modalOpen: false,
        propertyId: null,
        jobForm: null
    },
    serviceAddDialog: {
        modalOpen: false,
        services: null,
        refetch: () => { }
    },
    jobTaskDialog: {
        modalOpen: false,
        refetch: () => { },
        taskData: null
    },
    jobIssueMediaDialog: {
        modalOpen: false,
        issue: null
    },
    checklistPhotoDialog: {
        modalOpen: false,
        checklistPhoto: null
    },
    reviewDialog: {
        modalOpen: false,
        refetch: () => { },
        serviceTypeId: null
    },
    cancelJobDialog: {
        modalOpen: false,
        refetch: () => { },
        job: null
    },
    jobDetail: null,
    selectedJobTab: "Scheduled"
}

export default function jobReducer(
    state: JobState = initialState,
    action: actions.JobAction
): JobState {
    switch (action.type) {
        case actions.OPEN_JOB_DIALOG:
            return { ...state, jobDialog: { modalOpen: true, jobForm: null, propertyId: action.propertyId ? action.propertyId : null } }
        case actions.CLOSE_JOB_DIALOG:
            return { ...state, jobDialog: { modalOpen: false, jobForm: null, propertyId: null } }
        case actions.SET_JOB_FORM:
            return { ...state, jobDialog: { ...state.jobDialog, jobForm: action.jobForm } }
        case actions.OPEN_SERVICE_ADD_DIALOG:
            return { ...state, serviceAddDialog: { modalOpen: true, refetch: action.refetch, services: action.services } }
        case actions.CLOSE_SERVICE_ADD_DIALOG:
            return { ...state, serviceAddDialog: { modalOpen: false, refetch: () => { }, services: null } }
        case actions.OPEN_TASK_DIALOG:
            return { ...state, jobTaskDialog: { modalOpen: true, refetch: action.refetch, taskData: action.taskData } }
        case actions.CLOSE_TASK_DIALOG:
            return { ...state, jobTaskDialog: { modalOpen: false, refetch: () => { }, taskData: null } }
        case actions.OPEN_ISSUE_MEDIA_DIALOG:
            return { ...state, jobIssueMediaDialog: { modalOpen: true, issue: action.issue } }
        case actions.CLOSE_ISSUE_MEDIA_DIALOG:
            return { ...state, jobIssueMediaDialog: { modalOpen: false, issue: null } }
        case actions.OPEN_CHECKLIST_PHOTO_DIALOG:
            return { ...state, checklistPhotoDialog: { modalOpen: true, checklistPhoto: action.checklistPhoto } }
        case actions.CLOSE_CHECKLIST_PHOTO_DIALOG:
            return { ...state, checklistPhotoDialog: { modalOpen: false, checklistPhoto: null } }
        case actions.OPEN_REVIEW_DIALOG:
            return { ...state, reviewDialog: { modalOpen: true, refetch: action.refetch, serviceTypeId: action.serviceTypeId } }
        case actions.CLOSE_REVIEW_DIALOG:
            return { ...state, reviewDialog: { modalOpen: false, refetch: () => { }, serviceTypeId: null } }
        case actions.OPEN_CANCEL_JOB_DIALOG:
            return { ...state, cancelJobDialog: { modalOpen: true, refetch: action.refetch, job: action.job } }
        case actions.CLOSE_CANCEL_JOB_DIALOG:
            return { ...state, cancelJobDialog: { modalOpen: false, refetch: () => { }, job: null } }
        case actions.SET_JOB_DETAIL:
            return { ...state, jobDetail: action.jobDetail }
        case actions.SET_SELECTED_JOB_TAB:
            return { ...state, selectedJobTab: action.selectedJobTab }
        default:
            return state;
    }
}