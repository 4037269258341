import React from 'react'
import {useState} from 'react'

type Props = {
  defaultChecked?: boolean
  text: string
  onCheckboxChange: (check: boolean) => void
  classNames?: string
  mutedText?: boolean
  disabled?: boolean
}

const SwitchBoxMinimal: React.FC<Props> = ({
  defaultChecked,
  text,
  onCheckboxChange,
  classNames,
  mutedText,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)
  const valueChanged = (event: any) => {
    setIsChecked(event.target.checked)
    onCheckboxChange(event.target.checked)
  }

  return (
    <div className={`form-check form-switch ${classNames}`} style={{padding: 0}}>
      <div className='row'>
        <div className='col-8'>
          {mutedText ? (
            <small className={'w-75 text-muted text-start text-break ' + classNames}>{text}</small>
          ) : (
            <label
              className={'form-label fs-6 fw-bolder text-start text-break text-dark ' + classNames}
            >
              {text}
            </label>
          )}
        </div>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          <input
            className='form-check-input'
            type='checkbox'
            id={text}
            onChange={valueChanged}
            defaultChecked={defaultChecked}
            disabled={disabled}
          />
        </div>
        <div className='col-1' />
      </div>
    </div>
  )
}

export default SwitchBoxMinimal
