import {IProTeam, IProTeamDetail} from '../../interfaces/ProTeam'
import * as actions from '../actionTypes/proTeamActionTypes'

export interface ProTeamState {
  proTeamList: IProTeam[]
  listLoading: boolean
  listErrorText: string
  proTeamDetail: {
    proTeam: IProTeamDetail | null
    loading: boolean
    errorText: string
  }
}

const initialState: ProTeamState = {
  proTeamList: [],
  listLoading: false,
  listErrorText: '',
  proTeamDetail: {
    proTeam: null,
    loading: false,
    errorText: '',
  },
}

export default function proTeamReducer(
  state: ProTeamState = initialState,
  action: actions.ProTeamAction
): ProTeamState {
  switch (action.type) {
    case actions.GET_PROTEAM_LIST:
      return {...state, listErrorText: '', listLoading: true}
    case actions.GET_PROTEAM_LIST_SUCCESS:
      return {...state, listErrorText: '', listLoading: false, proTeamList: action.proTeamList}
    case actions.GET_PROTEAM_LIST_FAILURE:
      return {...state, listErrorText: action.errorText, listLoading: false, proTeamList: []}
    case actions.GET_PROTEAM_DETAIL:
      return {...state, proTeamDetail: {loading: true, errorText: '', proTeam: null}}
    case actions.GET_PROTEAM_DETAIL_SUCCESS:
      return {...state, proTeamDetail: {loading: false, errorText: '', proTeam: action.proTeam}}
    case actions.GET_PROTEAM_DETAIL_FAILURE:
      return {...state, proTeamDetail: {loading: false, errorText: action.errorText, proTeam: null}}
    default:
      return state
  }
}
