import React from 'react';
import './style.css';

type Props = {
    currentStep?: number,
    totalSteps?: number,
    nextStep?: () => void,
    goToStep?: () => void,
    stepNames?: string[]
}

const styles: any = {
    activeStep: {
        color: 'red',
        fontWeight: 'bold'
    },
    step: {
        color: 'black'
    }
}

const StepNav: React.FC<Props> = ({ currentStep = 0, totalSteps = 0, nextStep, goToStep = (i: number) => { }, stepNames }) => {
    const dots = [];
    for (let i = 1; i <= totalSteps; i += 1) {
        const isActive = currentStep === i;
        dots.push((
            <span
                onClick={() => {}}>
                <strong className={`${isActive ? 'active-step' : 'step'} me-3  ${i === 1 ? '' : 'ms-3'}`}>{stepNames ? stepNames[i - 1] : null}</strong>
                {i !== totalSteps ? '>' : null}
            </span >
        ));
    }

    return (
        <div className='w-100 mb-6 border-bottom pb-6'>{dots}</div>
    );
};

export default StepNav;