import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { encryptText, toAmazonUrl } from '../../../../utils/util';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useHistory } from 'react-router';
import NoPhoto from '../../../../umut-components/Icons/NoPhoto';

type Props = {
}

const PhotosDialog: React.FC<Props> = () => {
    const { checklistPhotoDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const history = useHistory()

    const handleClose = () => {
        dispatch(jobActionCreator.closeChecklistPhotoDialog());
    }

    return (
        <Modal show={checklistPhotoDialog.modalOpen} onHide={handleClose} size="lg" centered>
            <ModalHeader closeButton>
                <Modal.Title>Checklist Photo</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {checklistPhotoDialog.checklistPhoto ?
                    <div className='row'>
                        <div className='col-sm-6 d-flex flex-column p-0 pe-sm-3'>
                            <strong>Sample Photo</strong>
                            <div className='h-100 d-flex align-items-center border'>
                                {
                                    checklistPhotoDialog.checklistPhoto.samplePhotoUrl ?
                                        <img alt='sample' src={toAmazonUrl(checklistPhotoDialog.checklistPhoto.samplePhotoUrl)} className='w-100' />
                                        :
                                        <NoPhoto title='No Sample Photo' classNames='m-6' />
                                }
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex flex-column p-0 ps-sm-3'>
                            <strong>Staff Taken Photo</strong>
                            <div className='h-100 d-flex align-items-center border'>
                                {
                                    checklistPhotoDialog.checklistPhoto.jobUserPhotoUrl ?
                                        <img alt='answer' className='w-100' src={toAmazonUrl(checklistPhotoDialog.checklistPhoto.jobUserPhotoUrl)} />
                                        :
                                        <NoPhoto title='No Answer Photo' classNames='m-6' />
                                }
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-column p-6 mt-6 bg-light-primary'>
                            <div className='d-flex justify-content-start'>
                                <span className='badge badge-info me-3'>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionRoomIndex}</span>
                                <span className='badge badge-primary me-3'>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle?.en ?? checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle[Object.keys(checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle)[0]]}</span>
                                <span className={`badge badge-${checklistPhotoDialog.checklistPhoto.JobChecklistDetailQuestionStatusTypeName === "Completed" ? "success" : "danger"}`}>{checklistPhotoDialog.checklistPhoto.JobChecklistDetailQuestionStatusTypeName}</span>
                            </div>
                            <span className='mt-6' style={{ fontWeight: 400 }}>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionDescription?.en}</span>
                            <span className='text-muted'>{checklistPhotoDialog.checklistPhoto.questionAnswer ? checklistPhotoDialog.checklistPhoto.questionAnswer === "undefined" ? "" : checklistPhotoDialog.checklistPhoto.questionAnswer : 'Not answered yet'}</span>
                            {
                                checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionHasVirtualInspection &&
                                <>
                                    <span className='mt-6' style={{ fontWeight: 400 }}>Is Virtual Inspection Complete</span>
                                    {
                                        checklistPhotoDialog.checklistPhoto.isVirtualInspectionComplete
                                            ?
                                            <img alt='completed' style={{ width: 18, height: 18 }} src={toAbsoluteUrl('/media/icons/success.png')} />
                                            :
                                            <img alt='not-completed' style={{ width: 18, height: 18 }} src={toAbsoluteUrl('/media/icons/danger.png')} />
                                    }
                                </>
                            }
                        </div>
                        {/* <div className="d-flex justify-content-end">
                            <button onClick={handleHistoryPush} className="btn btn-info btn-sm">
                                <span className="svg-icon svg-icon-primary svg-icon-x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                                    </g>
                                </svg></span>
                                Edit Checklist</button>
                        </div> */}

                    </div>
                    :
                    null}
            </Modal.Body>
        </Modal>
    )
}

export default PhotosDialog;