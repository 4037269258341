import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import React from 'react'
import PaymentForm from './PaymentForm'

const PUBLIC_KEY_DEV =
  'pk_live_51HcuWEFdTV6mupoltscS6CT6w2zU4pgx8EF8KgHAvIJQTrcei0foUygvcZuORP2rzOife9s0DuqxKnhG3yVUuAIZ006kIchKT7'
//const PUBLIC_KEY_DEV = 'pk_test_51NQRuKCcKelwKj3PKYb8dTgcqPCwP9hSk5XEaIu0Sv1IlJ3EH30sLnxO3NrN0pUX7i8ntEnWlWZuxNm9O6BKr6cv00yLf3RHDf'

const stripeTestPromise = loadStripe(PUBLIC_KEY_DEV)

type Props = {
  handleSuccess: () => void
}

const StripeContainer: React.FC<Props> = ({handleSuccess}) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm handleSuccess={handleSuccess} />
    </Elements>
  )
}

export default StripeContainer
