import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import * as Yup from 'yup'
import notify from 'devextreme/ui/notify'
import {useFormik} from 'formik'
import {useDialog} from '../../../../../contexts/DialogContext'
import ApiCalls from '../../../../../network/ApiCalls'
import {IHostPropertyCheckList} from '../../../../../interfaces/Checklist'

type Props = {
  checklist: IHostPropertyCheckList
  checklists: IHostPropertyCheckList[]
  serviceTypes: any[]
  visible: boolean
  handleClose: () => void
  refetchChecklists: () => void
  refreshPage: () => void
}

const checklistSchema = Yup.object().shape({
  name: Yup.string().required('Checklist name is required'),
  roleId: Yup.string().required('Checklist role is required'),
  isDefault: Yup.string().required('Checklist default option is required'),
  serviceTypeId: Yup.string().required('Checklist service type is required'),
})

var defaultChecklistValues = {
  id: 0,
  name: '',
  roleId: '',
  isDefault: 'false',
  serviceTypeId: 0,
}

const ChecklistEditDialog: React.FC<Props> = ({
  checklist,
  serviceTypes,
  checklists,
  visible,
  handleClose,
  refetchChecklists,
  refreshPage,
}) => {
  const {openLoadingModal} = useDialog()
  const [isUpdating, setIsUpdating] = useState(false)
  const [renderKey, setRenderKey] = useState(1)

  useEffect(() => {
    defaultChecklistValues.id = checklist?.id
    defaultChecklistValues.name = checklist?.name?.en
    defaultChecklistValues.roleId = checklist?.lobRoleId + '-' + checklist?.roleId
    defaultChecklistValues.isDefault = checklist?.isDefault ? 'true' : 'false'
    defaultChecklistValues.serviceTypeId = checklist?.serviceTypeId
    setRenderKey(renderKey + 1)
  }, [visible, checklist])

  const formik = useFormik({
    initialValues: defaultChecklistValues,
    validationSchema: checklistSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        updateChecklist(values)
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const updateChecklist = async (values: any) => {
    openLoadingModal('Please wait..')
    setIsUpdating(true)
    var checklistId = values.id
    var serviceTypeId = values.serviceTypeId
    var roleIdRaw = values.roleId.split('-')

    var data = {
      name: {
        en: values.name,
      },
      roleId: +roleIdRaw[1],
      isDefault: values.isDefault == 'true' ? true : false,
      serviceTypeId: +serviceTypeId,
    }

    await ApiCalls.updateChecklistMainInfo(data, checklistId)
      .then((d) => {
        setIsUpdating(false)
        if (d?.data?.success) {
          handleClose()
          refetchChecklists()
          refreshPage()
        }
        notify(
          {
            message: d?.data?.success
              ? 'Checklist info have been successfully updated.'
              : 'An error occurred while updating checklist',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          d?.data?.success ? 'success' : 'error',
          5000
        )
      })
      .catch(() => {
        setIsUpdating(false)
        notify(
          {
            message: 'An error occurred while updating checklist',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          5000
        )
      })
  }

  const hasChecklistAlternative = (serviceTypeId: number, id: number) => {
    var find = checklists.find((d) => d.serviceTypeId == serviceTypeId && d.id != id)
    if (find != undefined) {
      return true
    } else {
      return false
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <ModalHeader closeButton>
          <Modal.Title>Edit Checklist</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Checklist Name</label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Checklist name'
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Checklist Role</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('roleId')}
              >
                <option value=''>Select a Role...</option>
                <option value='1-1'>Host Admin</option>
                <option value='1-2'>Host Manager</option>
                <option value='1-3'>Host Cleaner</option>
                <option value='1-4'>Host Inspector</option>
                <option value='1-5'>Host Logistics</option>
                <option value='1-6'>Host Maintenance</option>
                <option value='3-9'>Vendor Admin</option>
                <option value='3-10'>Vendor Manager</option>
                <option value='3-11'>Vendor Cleaner</option>
                <option value='3-12'>Vendor Inspector</option>
                <option value='3-13'>Vendor Logistics</option>
                <option value='3-14'>Vendor Maintenance</option>
              </select>
              {formik.touched.roleId && formik.errors.roleId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.roleId}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Checklist Service</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('serviceTypeId')}
              >
                <option value=''>Select a Service...</option>
                {serviceTypes.map((st) => {
                  return <option value={st.id}>{st.name}</option>
                })}
              </select>
              {formik.touched.serviceTypeId && formik.errors.serviceTypeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.serviceTypeId}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Is Default?</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('isDefault')}
                disabled={
                  !hasChecklistAlternative(
                    formik.getFieldProps('serviceTypeId').value,
                    formik.getFieldProps('id').value
                  )
                }
              >
                <option value=''>Select a Role...</option>
                <option value='true'>True</option>
                <option value='false'>False</option>
              </select>
              {formik.touched.isDefault && formik.errors.isDefault && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.isDefault}</div>
                </div>
              )}
              {!hasChecklistAlternative(
                formik.getFieldProps('serviceTypeId').value,
                formik.getFieldProps('id').value
              ) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    There is no other checklist in the service type of this checklist.
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          <button type='button' className='btn btn-link text-danger me-3' onClick={handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || isUpdating}
          >
            <span className='indicator-label'>Update</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ChecklistEditDialog
