import {AxiosResponse} from 'axios'
import {DataGrid} from 'devextreme-react'
import {Export} from 'devextreme-react/bar-gauge'
import {
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Paging,
  Summary,
  TotalItem,
  Column,
  Selection,
} from 'devextreme-react/data-grid'
import {EventInfo} from 'devextreme/events'
import dxDataGrid, {SelectionChangedInfo} from 'devextreme/ui/data_grid'
import React, {Dispatch, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {IHostProperty} from '../../../../interfaces/Property'
import ApiCalls from '../../../../network/ApiCalls'
import {IListResponse} from '../../../../network/NetworkResponses/NetworkResponses'
import {setChecklistAssignmentProperties} from '../../../../redux/actionCreators/masterChecklistActionCreators'
import {ChecklistAction} from '../../../../redux/actionTypes/masterChecklistTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {onExporting} from '../../../../utils/util'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'

type Props = {
  checklist: any
  handleChange: (d: any) => void
  nextStep?: () => void
  previousStep?: () => void
}

const PropertyStep: React.FC<Props> = ({checklist, nextStep, previousStep, handleChange}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {assignmentDialog} = useSelector((state: RedusxAppState) => state.masterChecklist)
  const {data, isLoading, error, isFetching, refetch} = useQuery<
    AxiosResponse<IListResponse<IHostProperty>>
  >('Get Host Properties', () => ApiCalls.getHostProperties(user.userSection.hostId), {
    cacheTime: 10000,
    refetchOnWindowFocus: false,
    retry: false,
  })
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const [properties, setProperties] = useState<IHostProperty[]>([])

  const onSelectionChanged = (e: any) => {
    handleChange(e.selectedRowsData)
  }

  useEffect(() => {
    if (data) {
      // setProperties(
      //   data.data.data.filter((property) =>
      //     checklist?.lobRoleName === 'Vendor'
      //       ? property.cleaningProviderName === 'Turnify PRO'
      //       : property.cleaningProviderName === 'My Team' ||
      //         property.cleaningProviderName === 'Turnify+'
      //   )
      // )
      setProperties(data.data.data)
    }
  }, [data])

  return (
    <div className='d-flex flex-column w-100 align-items-start'>
      <h2 style={{fontWeight: 400}}>Select Properties</h2>
      {isFetching ? (
        <SkeletonTableLoading />
      ) : properties.length === 0 ? (
        <EmptyTable title='No Properties Found to be assigned' />
      ) : (
        <DataGrid
          id='propertyTableAssignment'
          keyExpr='id'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={properties}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          height={'calc(48vh)'}
          allowColumnResizing={true}
          onSelectionChanged={onSelectionChanged}
          onExporting={(e) => onExporting(e, 'Properties')}
        >
          <Export enabled />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={50} />
          <Summary>
            <TotalItem
              cssClass='absolute-right'
              displayFormat='Total Properties: {0}'
              column='address'
              summaryType='count'
            />
          </Summary>

          <Column dataField='name' cssClass='cls' minWidth={350} />
        </DataGrid>
      )}
      <div className='d-flex w-100 justify-content-end'>
        <ArrowButton type='forward' func={nextStep} />
      </div>
    </div>
  )
}

export default PropertyStep
