import SelectInput from '../../../umut-components/Inputs/SelectInput'
import React, {Dispatch, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'
import {useDialog} from '../../../contexts/DialogContext'
import {getStaticMap} from '../../../utils/util'
import Map from '../../../umut-components/Map/GoogleMap'
import {ILocation} from '../../../interfaces/Property'
import AutocompleteMap from '../../../umut-components/Inputs/AutocompleteMap'
import {TextInput} from '../../../umut-components/Inputs/TextInput'

interface IDetail {
  address: string
  city: string
  state: string
  country: string
  latitude: number
  longitude: number
  suite: string
  zipCode: string
  numberOfBedrooms: number
  numberOfBathrooms: number
  propertySize: number
}

type Props = {
  handleChange: (changedValues: any) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
  propertyId: number
}

const PropertyDetail: React.FC<Props> = ({
  missingField,
  handleChange,
  goToStep,
  currentStep,
  propertyId,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showFailureDialog} = useDialog()
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({
    address: '',
    city: '',
    state: '',
    country: '',
    latitude: 0,
    longitude: 0,
    suite: '',
    zipCode: '',
  })
  const [placeId, setPlaceId] = useState<string | undefined>(undefined)
  const [address, setAddress] = useState<string>('')
  const [hasSizeError, setHasSizeError] = useState<boolean>(false)
  const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(1)
  const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(1)
  const [selectedValues, setSelectedValues] = useState<IDetail>({
    address: '',
    city: '',
    state: '',
    country: '',
    latitude: 0,
    longitude: 0,
    suite: '',
    zipCode: '',
    numberOfBedrooms: 0,
    numberOfBathrooms: 0,
    propertySize: 0,
  })

  const {
    data: hostPropertyData,
    error: hostPropertyError,
    isLoading: hostPropertyLoading,
    isFetching: hostPropertyFetching,
  } = useQuery(
    ['Get Host Property By Id', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId),
    {refetchOnWindowFocus: false, enabled: propertyId != 0 ? true : false}
  )

  const onMarkerChange = (changedLocation: ILocation) => {
    setAddress(changedLocation.address ? changedLocation.address : '')
    setSelectedLocation({
      ...changedLocation,
      suite: selectedLocation != undefined ? selectedLocation.suite : '',
    })
  }

  const handleSelectPlaceId = (e: string) => {
    setPlaceId(e)
  }

  const handleAutocompleteError = () => {
    var addr = selectedLocation != undefined ? selectedLocation.address : ''
    setAddress(addr!)
  }

  useEffect(() => {
    var loc = {
      address: hostPropertyData?.data?.hostProperty?.address,
      city: hostPropertyData?.data?.hostProperty?.city,
      state: hostPropertyData?.data?.hostProperty?.state,
      country: hostPropertyData?.data?.hostProperty?.country,
      latitude: hostPropertyData?.data?.hostProperty?.latitude,
      longitude: hostPropertyData?.data?.hostProperty?.longitude,
      suite: hostPropertyData?.data?.hostProperty?.suite,
      zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
    }
    setSelectedLocation(loc)
    setSelectedValues({
      ...selectedValues,
      address: hostPropertyData?.data?.hostProperty?.address,
      city: hostPropertyData?.data?.hostProperty?.city,
      state: hostPropertyData?.data?.hostProperty?.state,
      country: hostPropertyData?.data?.hostProperty?.country,
      latitude: hostPropertyData?.data?.hostProperty?.latitude,
      longitude: hostPropertyData?.data?.hostProperty?.longitude,
      suite: hostPropertyData?.data?.hostProperty?.suite,
      zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
      propertySize: +hostPropertyData?.data?.hostProperty?.propertySize || 0,
      numberOfBedrooms: hostPropertyData?.data?.hostProperty.numberOfBedrooms,
      numberOfBathrooms: hostPropertyData?.data?.hostProperty.numberOfBathrooms,
    })
    setSelectedNumberOfBedRooms(hostPropertyData?.data?.hostProperty.numberOfBedrooms)
    setSelectedNumberOfBathRooms(hostPropertyData?.data?.hostProperty.numberOfBathrooms)
  }, [hostPropertyData])

  const onSuiteChange = (e: any) => {
    setSelectedValues({...selectedValues, suite: e.target.value})
    setSelectedLocation({...selectedLocation, suite: e.target.value})
  }

  const onBackStepClick = () => {
    goToStep(1)
  }

  const onNextStepClick = () => {
    if (selectedValues.propertySize == 0) {
      setHasSizeError(true)
      showFailureDialog('Please enter the property size')
    } else {
      handleChange({...selectedValues})
      goToStep(3)
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='form w-100'>
            <div className='row'>
              <div className='fv-row col-lg-4'>
                <Map
                  selectedAddress={hostPropertyData?.data?.hostProperty.address}
                  placeId={placeId}
                  initialLocation={selectedLocation}
                  onLatLngChanged={onMarkerChange}
                  initialAddress={null}
                  draggable={false}
                />
              </div>
              <div className='fv-row col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  Address {<span style={{color: 'red'}}>*</span>}
                </label>
                <AutocompleteMap
                  disabled={true}
                  text={hostPropertyData?.data?.hostProperty?.address}
                  setText={setAddress}
                  handleSelectPlaceId={handleSelectPlaceId}
                  handleOnError={handleAutocompleteError}
                />
                <label className='form-label fs-6 fw-bolder text-dark mt-3'>Suite/Apt # </label>
                <input
                  value={selectedLocation.suite}
                  onChange={onSuiteChange}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  required
                />
                <div className='row'>
                  <div className='col-lg-6 mb-5'>
                    <label
                      className='form-label fs-6 fw-bolder text-dark mt-5'
                      style={{width: 200}}
                    >
                      Number of Bedrooms
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm me-2'
                        onClick={() => {
                          if (selectedNumberOfBedRooms > 1) {
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms - 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBedrooms: selectedNumberOfBedRooms - 1,
                            })
                          } else if (selectedNumberOfBedRooms === 1) {
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms - 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBedrooms: selectedNumberOfBedRooms - 1,
                            })
                          }
                        }}
                      >
                        -
                      </button>
                      <div style={{width: 50, textAlign: 'center'}}>
                        <strong>
                          {selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}
                        </strong>
                      </div>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm ms-2'
                        onClick={() => {
                          if (selectedNumberOfBedRooms === 0) {
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBedrooms: selectedNumberOfBedRooms + 1,
                            })
                          } else {
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBedrooms: selectedNumberOfBedRooms + 1,
                            })
                          }
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <label
                      className='form-label fs-6 fw-bolder text-dark mt-5'
                      style={{width: 200}}
                    >
                      Number of Bathrooms
                    </label>
                    <div className='d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm me-2'
                        onClick={() => {
                          if (selectedNumberOfBathRooms > 1) {
                            setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 0.5)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBathrooms: selectedNumberOfBathRooms - 0.5,
                            })
                          } else if (selectedNumberOfBathRooms === 1) {
                            setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBathrooms: selectedNumberOfBathRooms - 1,
                            })
                          }
                        }}
                      >
                        -
                      </button>
                      <div style={{width: 50, textAlign: 'center'}}>
                        <strong>{selectedNumberOfBathRooms}</strong>
                      </div>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm ms-2'
                        onClick={() => {
                          if (selectedNumberOfBathRooms === 0) {
                            setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 1)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBathrooms: selectedNumberOfBathRooms + 1,
                            })
                          } else {
                            setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 0.5)
                            setSelectedValues({
                              ...selectedValues,
                              numberOfBathrooms: selectedNumberOfBathRooms + 0.5,
                            })
                          }
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <label className='fw-bolder text-dark fs-6 mb-3'>Property Size (sqft.)</label>
                <input
                  value={+selectedValues.propertySize}
                  onChange={(e) => {
                    setHasSizeError(false)
                    setSelectedValues({...selectedValues, propertySize: +e.target.value})
                  }}
                  placeholder='Type here'
                  className='form-control'
                  type='number'
                  autoComplete='off'
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer p-0 pt-5'>
          <div className='d-flex justify-content-between flex-row w-100'>
            <button
              className='btn btn-sm btn-danger'
              onClick={() => {
                onBackStepClick()
              }}
            >
              Previous
            </button>
            <button
              className='btn btn-sm btn-success'
              onClick={() => {
                onNextStepClick()
              }}
            >
              Provide Cleaning Details
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyDetail
