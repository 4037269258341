import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import * as openStaffPasswordDialog from '../../redux/actionCreators/staffActionCreators';
import ViewButton from '../../umut-components/Buttons/ViewButton';
import UpdateButton from '../../umut-components/Buttons/UpdateButton';
import { encryptText, onExporting, toAmazonUrl } from '../../utils/util';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp';
import EmptyTable from '../../umut-components/Icons/EmptyTable';
import ChangePasswordButton from '../../umut-components/Buttons/ChangePasswordButton';

type Props = {
}

const ManagerTable: React.FC<Props> = () => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)
    const { staffList, listLoading } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const toStaffDetail = (cellData: any) => {
        if (cellData?.data?.id) {
            history.push({
                pathname: `/manager-details/` + encryptText(cellData?.data?.id)
            })
        }
    }
    const renderProfilePhoto = (cellValue: any) => {
        return (
            cellValue?.data?.profilePhotoPath ?
                <img alt='pp' style={{ width: 45, height: 45, borderRadius: '50%' }} src={cellValue?.data?.profilePhotoPath ? toAmazonUrl(cellValue?.data?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} />
                : <ProfilePhotoTemp firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }


    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + " " + cellValue?.lastName;
    }

    if (listLoading) {
        return <SkeletonTableLoading />
    }

    if (staffList.length === 0) {
        return <EmptyTable type='manager' />
    }

    return (
        <div className="position-relative">
             <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>
            <DataGrid
                id="staffTable"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={staffList}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(54vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onCellClick={toStaffDetail}
                onExporting={(e) => onExporting(e, "Manager")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Manager: {0}" column='firstName' summaryType='count' />
                </Summary>

                <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
                <Column dataField="firstName" calculateCellValue={calculateFullName} caption='Fullname' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="roleName" caption='Role' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="email" dataType='string' minWidth={150} cssClass='cls' />
                <Column dataField="phoneNumber" dataType='string' width={200} cssClass='cls' />
                <Column dataField="accountStatusName" caption='Status' dataType='string' width={180} cssClass='cls' />
            </DataGrid>
           
        </div>
    )
}

export default React.memo(ManagerTable);