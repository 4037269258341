import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'

type Props = {staff: IStaff}

const Notifications: React.FC<Props> = ({staff}) => {
  return <div>Notifications coming soon..</div>
}

export default Notifications
