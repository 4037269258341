/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Dispatch, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import {
  GuestyRequest,
  GuestyRequestV2,
  HostawayRequest,
  MyVrRequest,
} from '../../../network/PostRequestModels/PropertyFromPMS'

type Props = {
  fetchPropertyList: (data: HostawayRequest | GuestyRequest | MyVrRequest) => Promise<string | void>
  propertyLoading: boolean
  keys?: any
}

export const GuestyForm: React.FC<Props> = ({fetchPropertyList, propertyLoading, keys}) => {
  //const [token, setToken] = useState<string | undefined>(keys?.configJSON?.token ?? undefined);
  const [clientId, setClientId] = useState<string | undefined>(
    keys?.configJSON?.client_id ?? undefined
  )
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    keys?.configJSON?.client_secret ?? undefined
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  /*const getProperties = async () => {
        if (token) {
            setErrorMessage(null);
            const guestyRequest: GuestyRequest = {
                token,
            }
            fetchPropertyList(guestyRequest);
        }
        else {
            setErrorMessage('All fields are required')
        }
    }*/

  const getProperties = async () => {
    if (clientId && clientSecret) {
      setErrorMessage(null)
      const guestyRequest: GuestyRequestV2 = {
        client_id: clientId,
        client_secret: clientSecret,
      }
      fetchPropertyList(guestyRequest)
    } else {
      setErrorMessage('All fields are required')
    }
  }

  return (
    <form className='form w-100 mt-5' noValidate id='kt_guesty_form'>
      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Guesty Token</label>
                <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    autoComplete='off'
                    value={token}
                    onChange={(e: any) => setToken(e.target.value)}
                    required
                />
            </div> */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Guesty Client ID</label>
        <input
          className='form-control form-control-lg form-control-solid'
          type='text'
          autoComplete='off'
          value={clientId}
          onChange={(e: any) => setClientId(e.target.value)}
          required
        />
        <p style={{color: 'red', fontWeight: 'bold'}}>{errorMessage}</p>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Guesty Client Secret</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          value={clientSecret}
          onChange={(e: any) => setClientSecret(e.target.value)}
          type='password'
          autoComplete='off'
          className='form-control form-control-lg form-control-solid'
          required
        />
        <p style={{color: 'red', fontWeight: 'bold'}}>{errorMessage}</p>
      </div>
      {/* end::Form group */}

      {/* begin:: ErrorText */}
      <strong style={{color: 'red'}}>{errorMessage}</strong>
      {/* end:: ErrorText */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-lg btn-primary w-100 mb-5'
          onClick={getProperties}
          disabled={propertyLoading}
        >
          {!propertyLoading && <span className='indicator-label'>Get Guesty Properties</span>}
          {propertyLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
