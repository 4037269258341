import {Dispatch, Suspense, useEffect} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {AddPropertyManualPage} from '../pages/add-property-manual/AddPropertyManualPage'
import {AddPropertyPmsPage} from '../pages/add-property-pms/AddPropertyPmsPage'
import ChecklistSections from '../pages/checklist/ChecklistSections/ChecklistSections'
import PropertyChecklists from '../pages/checklist/PropertyChecklists/PropertyChecklists'
import JobDetails from '../pages/job-details/JobDetails'
import Profile from '../pages/profile/Profile'
import Properties from '../pages/properties/Properties'
import StaffDetail from '../pages/staff/staff-detail/StaffDetail'
import Staffs from '../pages/staff/Staffs'
import Jobs from '../pages/jobs/Jobs'
import Dashboard from '../pages/dashboard/Dashboard'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {OnBoardingAction} from '../redux/actionTypes/onBoardingTypes'
import {getOnBoardingInfo} from '../redux/actionCreators/onBoardingActionCreators'
import {RedusxAppState} from '../redux/reducers/rootReducer'
import MasterChecklists from '../pages/master-checklist/NewMasterChecklist/NewMasterChecklists'
import MasterChecklistSections from '../pages/master-checklist/MasterChecklistSections/MasterChecklistSections'
import ForceFinishRequest from '../pages/ForceFinishRequest/forceFinish'
import PaymentCenter from '../pages/paymentCenter/PaymentCenter'
import Billings from '../pages/paymentCenter/billings/Billings'
import PaymentSuccess from '../pages/paymentCenter/payment/PaymentSuccess'
import Payment from '../pages/paymentCenter/payment/Payment'
import LiveTabs from '../pages/live/LiveTabs'
import Managers from '../pages/managers/Managers'
import ManagerDetail from '../pages/managers/manager-detail/ManagerDetail'
import {AddPropertyManuallyPage} from '../pages/add-property-manually/AddPropertyManually'
import {AddPropertyFromPmsPage} from '../pages/add-property-from-pms/AddPropertyFromPms'
import Chat from '../pages/chat/ChatPage'
import Marketplace from '../pages/marketplace/Marketplace'
import NewMarketplaceRequest from '../pages/marketplace/NewMarketplaceRequest'
import MarketplaceDetail from '../pages/marketplace/MarketplaceDetail'
import {AddNewPropertyManuallyPage} from '../pages/add-new-property-manually/AddNewPropertyManually'
import ProTeam from '../pages/pro-team/ProTeam'
import ProTeamDetail from '../pages/pro-team/proteam-detail/ProTeamDetail'
import AddNewPropertyPms from '../pages/add-new-property-pms/AddNewPropertyPms'
import HostDashboard from '../pages/dashboard/HostDashboard'
import HostStaffs from '../pages/staff/HostStaffs'
import PropertyDetail from '../pages/properties/property-detail/PropertyDetail'

export function PrivateRoutes() {
  const history = useHistory()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {onBoardingInfo, hasPaymentCard} = useSelector(
    (state: RedusxAppState) => state.onBoarding,
    shallowEqual
  )
  const dispatch = useDispatch<Dispatch<OnBoardingAction>>()

  useEffect(() => {
    history.listen(() => {
      dispatch(getOnBoardingInfo())
      if (onBoardingInfo.failedPayment) {
        history.push('/payment-center')
      }
    })
  }, [history])

  useEffect(() => {
    dispatch(getOnBoardingInfo())
  }, [user])

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={HostDashboard} />
        <Route path='/live' component={LiveTabs} />
        <Route path='/jobs' component={Jobs} />
        <Route path='/job-details/:jobId' component={JobDetails} />
        {/* <Route path='/turnovers' component={Turnovers} /> */}
        <Route exact path='/marketplace/detail/:marketplaceId' component={MarketplaceDetail} />
        <Route exact path='/marketplace/new' component={NewMarketplaceRequest} />
        <Route exact path='/marketplace' component={Marketplace} />
        <Route path='/properties/edit/:propertyId' component={Properties} />
        <Route path='/properties/detail/:propertyId' component={PropertyDetail} />
        <Route path='/properties' component={Properties} />
        <Route path='/checklists/:hostPropertyId' component={PropertyChecklists} />
        <Route path='/checklist-sections/:checklistId' component={ChecklistSections} />
        <Route path='/master-checklists' component={MasterChecklists} />
        <Route path='/master-checklist-sections/:checklistId' component={MasterChecklistSections} />
        {/* <Route path='/staff/:userId' component={Staffs} /> */}
        <Route path='/staff-connect-hub' component={HostStaffs} />
        {/* <Route path='/staffs' component={Staffs} /> */}
        <Route path='/staff-details/:staffId' component={StaffDetail} />

        <Route exact path='/pro-team' component={ProTeam} />
        <Route exact path='/pro-team-detail/:userId' component={ProTeamDetail} />

        <Route exact path='/billings/detail/:billingId' component={Billings} />
        <Route path='/billings' component={Billings} />

        {/*<Route path='/add-property-pms' component={AddPropertyPmsPage} />*/}
        {/*<Route path='/add-property-manual' component={AddPropertyManualPage} />*/}
        {/* New Add Manually Property (2022) */}
        {/* <Route path='/add-property-manually' component={AddPropertyManuallyPage} /> */}
        {/* New Add Property From PMS (2022) */}
        {/* <Route path='/add-property-from-pms' component={AddPropertyFromPmsPage} /> */}

        {/* New Add Manually Property (2023) */}
        <Route path='/add-property-manually' component={AddNewPropertyManuallyPage} />
        {/* New Add Property From PMS (2023) */}
        <Route path='/add-property-from-pms' component={AddNewPropertyPms} />

        <Route path='/profile' component={Profile} />
        <Route path='/payment-success/:successToken' component={PaymentSuccess} />
        <Route path='/payment' component={Payment} />
        <Route path='/payment-center' component={PaymentCenter} />

        <Route path='/turnify-messenger' component={Chat} />

        <Route path='/force-finish' component={ForceFinishRequest} />

        <Route path='/managers'>
          {user?.userSection?.roleId !== 1 ? <Redirect to='/live' /> : <Managers />}
        </Route>
        <Route path='/manager-details/:staffId'>
          {user?.userSection?.roleId !== 1 ? <Redirect to='/live' /> : <ManagerDetail />}
        </Route>

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />

        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
