import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {LiveJobResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import LiveTable from './LiveTable'

const Live = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)

  const localTime = dayjs().utc().format()
  const {
    data: liveData,
    isFetching: liveLoading,
    error: liveError,
  } = useQuery<AxiosResponse<LiveJobResponse>>(
    'Get Live Jobs',
    () => ApiCalls.getLiveJobs(user.userSection.hostId, localTime),
    {cacheTime: 500000, enabled: !!localTime}
  )

  if (liveError) {
    return <div>Error</div>
  }

  return (
    <div className='pt-3'>
      {liveLoading ? (
        <SkeletonTableLoading />
      ) : (
        liveData && <LiveTable data={liveData?.data.data} />
      )}
    </div>
  )
}

export default Live
