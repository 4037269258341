import React, {Dispatch, FC, useEffect, useState} from 'react'
import {decryptText, encryptText} from '../../../utils/util'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import {IHostPropertyOffer} from '../../../interfaces/Property'
import {IService, IServiceGroup} from '../../../interfaces/ServiceType'
import SkeletonCircleLoading from '../../Loading/SkeletonCircleLoading'
import EmptyOfferListCard from './MarketplaceOffers/EmptyOfferListCard'
import MarketplaceServiceOffers from './MarketplaceOffers/MarketplaceServiceOffers'

type ItemProps = {
  propertyService: IHostPropertyOffer
  propertyServiceRefetch: () => void
}

const MarketplaceOfferedRatesStep: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined
  const [serviceGroupList, setServiceGroupList] = useState<any[]>([])
  const [renderKey, setRenderKey] = useState(1)
  const [selectedServiceGroupId, setSelectedServiceGroupId] = useState(1)
  const [selectedServiceGroupInfo, setSelectedServiceGroupInfo] = useState<IServiceGroup>()
  const [selectedServiceId, setSelectedServiceId] = useState(1)
  const [selectedServiceInfo, setSelectedServiceInfo] = useState<IService>()

  const {
    data: propertyServiceData,
    isFetching: propertyServicesLoading,
    error: propertyServiceError,
    refetch: propertyServiceRefetch,
  } = useQuery(
    ['Get Property Marketplace Offers', propertyId],
    () =>
      ApiCalls.getPropertyOffersByService({
        hostPropertyId: +propertyId,
        serviceTypeId: selectedServiceId,
      }),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: selectedServiceId && propertyId ? true : false,
    }
  )

  const {
    data: serviceGroups,
    isLoading: serviceGroupLoading,
    error: serviceGroupError,
    refetch: refetchServiceGroup,
  } = useQuery('Get Service Groups with Services', () => ApiCalls.getServiceGroupsWithServices(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    const sglist: any[] = []
    if (!serviceGroupLoading && serviceGroups?.data?.data) {
      for (const sg of serviceGroups?.data?.data) {
        if (sg.isActive) {
          const findSG = sglist.find((item) => item.id == sg.id)
          if (findSG == undefined) {
            const sgservices: any[] = []
            for (const service of sg.serviceTypes) {
              if (service.isActive) {
                sgservices.push({
                  id: service.id,
                  serviceGroupId: sg.id,
                  name: service.name,
                  description: service.description,
                })
              }
            }

            sglist.push({
              id: sg.id,
              name: sg.name,
              description: sg.description,
              services: sgservices,
            })
          }
        }
      }

      setServiceGroupList(sglist)
    }
  }, [serviceGroupLoading, serviceGroups])

  useEffect(() => {
    var newkey = renderKey + 1
    if (serviceGroupList.length > 0) {
      const findGroup = serviceGroupList.find((sg) => sg.id == selectedServiceGroupId)
      if (findGroup != undefined) {
        setRenderKey(newkey)
        setSelectedServiceGroupInfo(findGroup)
        const findService = findGroup.services.find(
          (service: any) => service.id == selectedServiceId
        )
        if (findService != undefined) {
          setRenderKey(newkey)
          setSelectedServiceInfo(findService)
        }
      }
    }
    propertyServiceRefetch()
  }, [serviceGroupList, selectedServiceGroupId, selectedServiceId])

  if (propertyServicesLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (propertyServiceError) {
    return <div>Error</div>
  }

  return (
    <div className='app-content'>
      <div className='app-container'>
        <div className='row'>
          <div className='col-xl-3 col-12 mb-3'>
            <div className='card mb-3'>
              <div className='card-body p-lg-15'>
                <div className='mb-5'>
                  <h4 className='text-dark mb-7'>Services</h4>
                  <div className='menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold'>
                    {serviceGroupList.map((sg, index) => {
                      return (
                        <div
                          className='menu-item menu-accordion mb-1'
                          key={'service-group-' + index}
                        >
                          <div
                            className='menu-link py-3'
                            onClick={() => {
                              setSelectedServiceGroupId(sg.id)
                            }}
                          >
                            <span className='fw-boldest'>{sg.name}</span>
                          </div>
                          <div
                            className={
                              selectedServiceGroupId == sg.id
                                ? 'menu-sub menu-sub-accordion menu-active-bg show'
                                : 'menu-sub menu-sub-accordion menu-active-bg'
                            }
                          >
                            {sg.services.map((service: any, ind: number) => {
                              return (
                                <div
                                  className='menu-item'
                                  key={'service-group-' + index + '-service-' + ind}
                                  onClick={() => {
                                    setSelectedServiceId(service.id)
                                    setSelectedServiceGroupId(sg.id)
                                  }}
                                >
                                  <div
                                    className={
                                      selectedServiceId == service.id
                                        ? 'menu-link active'
                                        : 'menu-link'
                                    }
                                  >
                                    <span className='menu-bullet'>
                                      <span className='bullet bullet-dot'></span>
                                    </span>
                                    <span className='menu-title'>{service.name}</span>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-9 col-12 mb-3'>
            <div className='card mb-3'>
              <div className='card-body'>
                <div
                  className='d-flex justify-content-between align-items-center'
                  key={'service-group-info-' + renderKey}
                >
                  <div className='mb-2'>
                    <h4 className='fs-2x text-gray-800 w-bolder mb-6'>
                      {selectedServiceInfo?.name}
                    </h4>

                    <p className='fw-semibold fs-4 text-gray-600 mb-2'>
                      {selectedServiceInfo?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {propertyServicesLoading && (
              <div className='card'>
                <div className='card-body'>
                  <SkeletonCircleLoading />
                </div>
              </div>
            )}
            {!propertyServicesLoading && (
              <>
                {propertyServiceData?.data?.data.length == 0 ? (
                  <EmptyOfferListCard />
                ) : (
                  <>
                    <MarketplaceServiceOffers
                      offers={propertyServiceData?.data?.data}
                      refetch={propertyServiceRefetch}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceOfferedRatesStep
