import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useSelector} from 'react-redux'
import ApiCalls from '../../../network/ApiCalls'
import {useQuery} from 'react-query'
import {ISection} from '../../../interfaces/Checklist'
import SelectInput from '../../../umut-components/Inputs/SelectInput'

type Props = {
  checklistName: string | undefined
  section: ISection | undefined
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
}

const NewCloneSectionDialog: React.FC<Props> = ({
  checklistName,
  section,
  modalOpen,
  handleClose,
  refetch,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [checklists, setChecklists] = useState<any[]>([])
  const [selectedChecklists, setSelectedChecklists] = useState<number[]>([])
  const [errMsg, setErrMsg] = useState('')
  const {
    data,
    isLoading,
    error,
    isFetching,
    refetch: refetchChecklists,
  } = useQuery('Get Host Checklists', () => ApiCalls.getHostChecklists(user.userSection.hostId), {
    cacheTime: 10000,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: modalOpen,
  })

  useEffect(() => {
    if (!isLoading && data) {
      const lists: any[] = []
      if (data?.data && data?.data?.length > 0) {
        for (const item of data?.data) {
          if (item.serviceTypeName) {
            lists.push({
              label:
                item.name.en + ' (' + item.serviceTypeName + ') (' + item.hostPropertyName + ')',
              value: item.id,
            })
          }
        }
      }
      setChecklists(lists)
    }
  }, [data, isLoading])

  const selectChecklist = (options: any) => {
    var list = []
    for (var i = 0; i < options.length; i++) {
      var opt = options[i]

      if (opt.selected) {
        list.push(+opt.value)
      }
    }
    setSelectedChecklists(list)
  }

  const cloneSection = async () => {
    setErrMsg('')
    if (selectedChecklists.length > 0) {
      await ApiCalls.cloneSection({
        sectionId: section?.id,
        hostPropertyChecklistIds: selectedChecklists,
      })
      refetch()
      handleClose()
    } else {
      setErrMsg('You have to select at least 1 checklist')
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>
          Clone {section?.roomIndex} of {checklistName} to:
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row'>
          <div className='col-12 mb-3'>
            <label className='fs-6 fw-bold'>Checklists</label>
            <small className='d-block text-danger'>
              You can select multiple checklists to clone to this section
            </small>
            <select
              className='form-control'
              multiple
              onChange={(v) => {
                selectChecklist(v.target.options)
              }}
            >
              {checklists.map((cl, i) => {
                return <option value={cl.value}>{cl.label}</option>
              })}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-link text-warning me-3' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-danger' onClick={cloneSection}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewCloneSectionDialog
