import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useParams} from 'react-router'
import {decryptText} from '../../utils/util'
import ApiCalls from '../../network/ApiCalls'
import {useMutation, useQueryClient} from 'react-query'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  vendorId: any
  jobId: any
  refetch: () => void
}

const scoreOptions = [
  {label: 'Terrible', value: 1},
  {label: 'Poor', value: 2},
  {label: 'OK', value: 3},
  {label: 'Good', value: 4},
  {label: 'Excellent', value: 5},
]

const JobReviewModal: React.FC<Props> = ({modalOpen, handleClose, vendorId, jobId, refetch}) => {
  const [loading, setLoading] = useState(false)
  const [selectedScore, setSelectedScore] = useState(-1)
  const [scoreNote, setScoreNote] = useState('')
  const {showSuccessDialog, showFailureDialog} = useDialog()

  const handleChangeScore = (data: any) => {
    setSelectedScore(data)
  }

  const sendJobReview = async () => {
    if (selectedScore == -1) {
      showFailureDialog('Please select a score!')
    } else {
      try {
        var request = {
          vendorId: vendorId,
          score: selectedScore,
          note: scoreNote,
          jobId: jobId,
        }

        await ApiCalls.sendVendorScore(request)
        showSuccessDialog('Your score saved successfully!')
        refetch()
        setTimeout(() => {
          handleClose()
        }, 500)
      } catch (error) {
        showFailureDialog('An error occur during voting vendor!')
      }
    }
  }

  return (
    <>
      <Modal show={modalOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rate the Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Please rate your vendor over the service you received for this cleaning job.</h4>
          <div className='row mt-4'>
            <div className='col-12 mb-4'>
              <label>Select a score</label>
              <SelectInput
                options={scoreOptions}
                defaultValue={selectedScore}
                onValueChanged={handleChangeScore}
              />
            </div>
            <div className='col-12 mb-4'>
              <label>Type your note (optional)</label>
              <textarea
                className='form-control'
                value={scoreNote}
                onChange={(e) => {
                  setScoreNote(e.target.value)
                }}
                rows={3}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-danger' onClick={handleClose}>
            Close
          </button>
          <button disabled={loading} className='btn btn-success btn-sm' onClick={sendJobReview}>
            Send {loading && <Spinner animation='border' size='sm' />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default JobReviewModal
