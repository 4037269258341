import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Dispatch} from 'redux'
import {ProTeamAction} from '../../../../redux/actionTypes/proTeamActionTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import SkeletonCircleLoading from '../../../../umut-components/Loading/SkeletonCircleLoading'

type Props = {
  modalOpen: (arg0: boolean) => void
}
const ProfileTab: React.FC<Props> = ({modalOpen}) => {
  const {proTeamDetail} = useSelector((state: RedusxAppState) => state.proTeam)
  const dispatch = useDispatch<Dispatch<ProTeamAction>>()

  if (proTeamDetail.proTeam == null) {
    return (
      <div className='mt-5'>
        <SkeletonCircleLoading />
      </div>
    )
  }
  return (
    <div className='d-flex p-6 ps-0 flex-wrap justify-content-between'>
      <div className='d-flex '>
        <div className='d-flex me-6'>
          <ProfilePhotoTemp
            width={75}
            height={75}
            fontSize={25}
            borderRadius='50%'
            profilePhotoPath={proTeamDetail?.proTeam?.profilePhotoPath}
            firstName={proTeamDetail?.proTeam?.firstName ?? ''}
            lastName={proTeamDetail?.proTeam?.lastName ?? ''}
          />
        </div>
        <div className='row'>
          <div className='col-lg-12 h-auto'>
            <span
              className={`badge badge-${
                proTeamDetail?.proTeam?.accountStatusName ? 'success' : 'danger'
              } mb-1`}
            >
              {proTeamDetail?.proTeam?.accountStatusName}
            </span>
          </div>
          <div className='col-lg-12 h-auto'>
            <strong>
              {proTeamDetail?.proTeam?.firstName + ' ' + proTeamDetail?.proTeam?.lastName}{' '}
              {'(' + proTeamDetail?.proTeam?.vendorName + ')'}
            </strong>
          </div>
          <div className='col-lg-12 h-auto'>
            <strong className='text-muted'>{proTeamDetail?.proTeam?.email}</strong>
          </div>
          <div className='col-lg-12 h-auto'>
            <strong className='text-muted'>{proTeamDetail?.proTeam?.phoneNumber}</strong>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileTab
