import { ITurnover } from "../../interfaces/Turnover";

export const OPEN_VIEW = "OPEN_VIEW";
export interface OpenViewAction {
    type: typeof OPEN_VIEW,
    turnover: ITurnover
}

export const CLOSE_VIEW = "CLOSE_VIEW";
export interface CloseViewAction {
    type: typeof CLOSE_VIEW
}

export const CHANGE_PAGE = "CHANGE_PAGE";
export interface ChangePageAction {
    type: typeof CHANGE_PAGE,
    page: 'future' | 'past'
}

export type TurnoverAction =
    | OpenViewAction
    | CloseViewAction
    | ChangePageAction