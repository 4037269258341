import React, {Dispatch} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {StaffAction} from '../../redux/actionTypes/staffActionTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators'
import StaffForm from '../../umut-components/Forms/StaffForm'
import HostStaffForm from '../../umut-components/Forms/HostStaffForm'

const HostStaffAddDialog = () => {
  const {staffAddDialog} = useSelector((state: RedusxAppState) => state.staff)
  const dispatch = useDispatch<Dispatch<StaffAction>>()

  const closeModal = () => {
    dispatch(staffActionCreators.closeStaffAddDialog())
  }

  return (
    <Modal show={staffAddDialog.dialogOpen} onHide={closeModal} centered>
      <ModalHeader closeButton>
        <Modal.Title> Add Staff</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <HostStaffForm staff={staffAddDialog.staff} />
      </Modal.Body>
    </Modal>
  )
}

export default HostStaffAddDialog
