import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../interfaces/Staff'
import General from '../HostStaffTabs/ProfileTab/General'
import Verifications from '../HostStaffTabs/ProfileTab/Verifications'

type Props = {
  staff: IStaff
}

type TabNames = 'contact-detail' | 'verifications'

const ProfileTab: React.FC<Props> = ({staff}) => {
  const [activeTab, setActiveTab] = useState<TabNames>('contact-detail')

  return (
    <>
      <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2'>
        <li
          className='nav-item'
          onClick={() => {
            setActiveTab('contact-detail')
          }}
        >
          <div
            className={
              activeTab == 'contact-detail'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Contact Details
          </div>
        </li>
        {/* <li
          className='nav-item'
          onClick={() => {
            setActiveTab('verifications')
          }}
        >
          <div
            className={
              activeTab == 'verifications'
                ? 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4 active'
                : 'nav-link text-active-primary d-flex align-items-center cursor-pointer pb-4'
            }
          >
            Verifications
          </div>
        </li> */}
      </ul>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          {activeTab == 'contact-detail' && <General staff={staff} />}
          {activeTab == 'verifications' && <Verifications staff={staff} />}
        </div>
      </div>
    </>
  )
}

export default ProfileTab
