import React, { useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { encryptText, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../../utils/util';
import { IBilling } from '../../../interfaces/Billing';
import config from '../../../config/config.json';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';

type Props = {
    data: IBilling[]
}

const BillingTable: React.FC<Props> = ({ data }) => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)

    const toBillDetail = (cellData: any) => {
        if (cellData?.data?.id) {
            history.push({
                pathname: `/billings/detail/` + encryptText(cellData?.data?.id)
            })
        }
    }
    const calculateBillingDate = (cellValue: any) => {
        return toConvertedDate(cellValue?.billingDate)
    }

    const calculateNetAmount = (cellValue: any) => {
        return '$' + cellValue?.totalNetAmount;
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Billings" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="billingTable"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                hoverStateEnabled={true}
                
                height={'calc(60vh)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onCellClick={toBillDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Billings")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Invoices: {0}" column='invoiceNumber' summaryType='count' />
                </Summary>
                <Column dataField="invoiceNumber"  dataType='string' caption='Invoice ID' width={150} cssClass='cls' />
                <Column dataField="hostPropertyName" caption='Property' minWidth={200} dataType='string' cssClass='cls' alignment='center' />
                <Column dataField="billingDate" dataType='time' caption="JOB DATE" format={config.date_time_format_dev_extreme} calculateCellValue={calculateBillingDate} minWidth={200} alignment="center" cssClass='cls'  ></Column>
                <Column dataField="hostInvoiceStatusTypeName" dataType='string' caption='Status' width={280} cssClass='cls' alignment='center' />
                <Column dataField="totalNetAmount" caption='Amount(Net)' calculateCellValue={calculateNetAmount} dataType='string' width={180} cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default React.memo(BillingTable);