import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import React, {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useMutation, useQueryClient} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'

type Props = {
  handleSuccess: (val: any) => void
  isFailed: boolean
}

const PaymentForm: React.FC<Props> = ({handleSuccess, isFailed}) => {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  useEffect(() => {
    if (isFailed) {
      if (elements != undefined && elements != null) {
        const card = elements.getElement(CardElement)
        card?.clear()
      }
    }
  }, [isFailed])

  const handleSubmit = async () => {
    const card = elements?.getElement(CardElement)
    if (stripe && elements && card) {
      const payload = await stripe.createToken(card)
      let source = payload.token?.id
      if (source) {
        handleSuccess(source)
      }
      if (payload?.error) {
        setError(`Ops! ${payload.error.message}`)
        setProcessing(false)
      } else {
        setError(null)
      }
    }
  }

  return (
    <>
      <div className='row w-100'>
        <div className='col-lg-12 mx-auto'>
          <div className='p-5 ms-2'>
            <div className='tab-content'>
              <span id='card-header ' className='fs-5 fw-bold'>
                New card:
              </span>
              <div id='nav-tab-card' className='tab-pane fade show active mt-5'>
                {success && <p className='alert alert-success'>{success}</p>}
                {error && <p className='alert alert-danger'>{error}</p>}

                <div role='form' id='payment-form'>
                  <div className='form-group'>
                    <label htmlFor='username'>Full name (on the card)</label>
                    <input
                      type='text'
                      name='username'
                      placeholder='Jassa'
                      required
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mt-5'>
                    <label htmlFor='cardNumber'>Card number</label>
                    <fieldset className='FormGroup'>
                      <div className='FormRow form-control rounded h-40px'>
                        <div className='my-auto'>
                          <CardElement
                            options={cardStyle}
                            id='card-element'
                            onChange={() => {
                              handleSubmit()
                            }}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentForm
