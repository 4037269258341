import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap-v5';

interface ISelectBox {
    searchBoxObj: object,
    onValueChanged: (text: any) => void,
    disabledText?: string,
    classNames?: string,
    defaultValue?: any,
    ref?: any,
    disabled?: boolean,
}

const SelectBox: React.FC<ISelectBox> = ({ searchBoxObj, onValueChanged, disabledText, classNames, defaultValue, ref, disabled }) => {
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue ? defaultValue : null);

    const valueChanged = (event: any) => {
        setSelectedValue(parseInt(event.target.value));
        onValueChanged(parseInt(event.target.value));
    }
    useEffect(() => {
       setSelectedValue(defaultValue)
    }, [defaultValue])

    return (
        <Form.Select disabled={disabled} ref={ref} value={selectedValue} onChange={valueChanged} className={classNames}>
            {disabledText ? <option selected disabled>{disabledText}</option> : null}
            {Object.entries(searchBoxObj).map(([key, value]) => <option value={value}>{key}</option>)}
        </Form.Select>
    )
}

export default SelectBox;