import {AxiosResponse} from 'axios'
import React, {useState} from 'react'
import {Dropdown, Modal, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ApiCalls from '../../network/ApiCalls'
import {ISearchResponse} from '../../network/NetworkResponses/NetworkResponses'
import {encryptText} from '../../utils/util'
import {TextInput} from './TextInput'
var debounce = require('debounce')

const SearchModal: React.FC = () => {
  const [searchText, setSearchText] = useState<string | null>(null)
  const {data, isFetching, error} = useQuery<AxiosResponse<ISearchResponse>>(
    ['Search', searchText],
    () => ApiCalls.getSearch(searchText),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: searchText && searchText.length > 2 ? true : false,
    }
  )

  const debounceFunc = debounce(setSearchText, 400)

  return (
    <Dropdown.Menu align='right' className='searchbar shadow-sm border border-xl'>
      <TextInput
        onValueChange={debounceFunc}
        classNames='sticky sticky-top border border-light'
        placeholder='Search'
      />
      {searchText === null ||
        (searchText.length <= 2 && (
          <div className='d-flex justify-content-center my-3 text-muted'>
            Type at least 3 words to search
          </div>
        ))}
      {isFetching && (
        <div className='d-flex justify-content-center my-3'>
          <Spinner animation='border' size='sm' />
        </div>
      )}
      {!isFetching &&
        data?.data &&
        data?.data.hostProperties.length === 0 &&
        data?.data &&
        data?.data &&
        data?.data.hostUsers.length === 0 && (
          <div className='d-flex justify-content-center my-3'>No results found</div>
        )}
      {!isFetching && data?.data && data?.data.hostProperties.length > 0 && (
        <Dropdown.Header>Properties</Dropdown.Header>
      )}
      {!isFetching &&
        data?.data &&
        data?.data.hostProperties.length > 0 &&
        data.data.hostProperties.map((property) => (
          <Dropdown.Item
            key={property.hostPropertyId}
            href={'/properties/detail/' + encryptText(property.hostPropertyId)}
          >
            {property.hostPropertyName}
          </Dropdown.Item>
        ))}
      {!isFetching && data?.data && data?.data.hostUsers.length > 0 && (
        <Dropdown.Header>Host Staff</Dropdown.Header>
      )}
      {!isFetching &&
        data?.data &&
        data?.data.hostUsers.length > 0 &&
        data.data.hostUsers.map((hostStaff) => (
          <Dropdown.Item
            key={hostStaff.hostUserId}
            href={'/staff-details/' + encryptText(hostStaff.hostUserId)}
          >
            {hostStaff.fullName}
          </Dropdown.Item>
        ))}
    </Dropdown.Menu>
  )
}

type CustomToggleProps = {
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
}

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick && props.onClick(e)
      }}
    >
      {props.children}
      <KTSVG path='/media/icons/duotone/General/Search.svg' className='svg-icon-2x mt-1' />
    </a>
  )
)

const SearchInput: React.FC = () => {
  return (
    <Dropdown className='d-flex align-items-center'>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-search' />
      <SearchModal />
    </Dropdown>
  )
}

export default SearchInput
