import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Button, Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ApiCalls from '../../../network/ApiCalls'
import ChecklistAddDialog from '../../../pages/checklist/PropertyChecklists/ChecklistAddDialog'
import ChecklistCloneDialog from '../../../pages/checklist/PropertyChecklists/ChecklistCloneDialog'
import ChecklistEditDialog from '../../../pages/checklist/PropertyChecklists/ChecklistEditDialog'
import ChecklistTable from '../../../pages/checklist/PropertyChecklists/ChecklistTable'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {decryptText} from '../../../utils/util'
import DeleteDialog from '../../Modals/DeleteDialog'
const debounce = require('debounce')

type Props = {
  goToStep?: (step: number) => void
  currentStep?: number
  propertyId: number
}

const ChecklistStep: React.FC<Props> = ({goToStep, currentStep, propertyId}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [checklistAddModalOpen, setChecklistAddModalOpen] = useState(false)
  const [checklistCloneModalOpen, setChecklistCloneModalOpen] = useState(false)

  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery(
    ['Get Checklist By Property Id', propertyId],
    () => ApiCalls.getHostPropertyChecklists(propertyId),
    {refetchOnWindowFocus: false, cacheTime: 50000}
  )
  const {data: propertyDetailData} = useQuery(
    'Get Property by Id',
    () => ApiCalls.getHostPropertyById(propertyId),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {data: masterChecklistData} = useQuery(
    ['Get Admin Master Checklist', propertyId],
    () => ApiCalls.getAdminMasterChecklists(),
    {refetchOnWindowFocus: false, enabled: true, cacheTime: 100000}
  )

  const handleCloseChecklistAddModal = () => {
    setChecklistAddModalOpen(false)
  }
  const handleCloseChecklistCloneModal = () => {
    setChecklistCloneModalOpen(false)
    refetchChecklists()
  }

  const handleAddPropertySuccess = () => {
    setChecklistAddModalOpen(false)
    refetchChecklists()
  }

  useEffect(() => {
    if (checklistData) {
      setChecklists(checklistData?.data)
    }
  }, [checklistData])

  return (
    <>
      <PageTitle>Checklist</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Header>
            <Card.Title className='text-danger'>
              You can assign specific checklist items to different rooms and areas of the property.
              Once you've created your checklist, it will be available for your staff to access and
              complete during their jobs.
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {checklistLoading ? (
              <div className='d-flex justify-content-center'>
                <Spinner animation='border' />
              </div>
            ) : checklists.length > 0 ? (
              <ChecklistTable data={checklists} refetchChecklists={refetchChecklists} />
            ) : (
              <div className='d-flex h-100 justify-content-center align-items-center fw-bold'>
                No checklist found for this property
              </div>
            )}
          </Card.Body>
          <Card.Footer className='d-flex align-items-center justify-content-end'>
            <div />
            <button
              className='btn btn-info'
              style={{height: '100%'}}
              onClick={() => {
                setChecklistCloneModalOpen(true)
              }}
            >
              Clone From Turnify
            </button>
            <button
              className='btn btn-success ms-3'
              style={{height: '100%'}}
              onClick={() => {
                setChecklistAddModalOpen(true)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Checklist
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ChecklistCloneDialog
        modalOpen={checklistCloneModalOpen}
        modalClose={handleCloseChecklistCloneModal}
        masterCheckLists={masterChecklistData?.data}
        hostPropertyId={propertyId}
      />
      <ChecklistAddDialog
        modalOpen={checklistAddModalOpen}
        handleClose={handleCloseChecklistAddModal}
        handleAddPropertySuccess={handleAddPropertySuccess}
        propertyId={propertyId}
      />
      <ChecklistEditDialog refetchChecklists={refetchChecklists} />
      <DeleteDialog />
    </>
  )
}

export default ChecklistStep
