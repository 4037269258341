import { ITurnover } from "../../interfaces/Turnover";
import * as actions from "../actionTypes/turnoverActionTypes";

export interface TurnoverState {
    viewModalOpen: boolean,
    viewModalData: ITurnover | null,
    currentPage: 'future' | 'past'
}

const initialState: TurnoverState = {
    viewModalOpen: false,
    viewModalData: null,
    currentPage: 'future'
}

export default function turnoverReducer(
    state: TurnoverState = initialState,
    action: actions.TurnoverAction
) {
    switch (action.type) {
        case actions.OPEN_VIEW:
            return { ...state, viewModalOpen: true, viewModalData: action.turnover }
        case actions.CLOSE_VIEW:
            return { ...state, viewModalOpen: false, viewModalData: null }
        case actions.CHANGE_PAGE:
            return { ...state, currentPage: action.page }
        default:
            return state;
    }
}