import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useHistory, useParams} from 'react-router-dom'
import ChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistForm'
import {decryptText} from '../../../utils/util'
import NewChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/NewChecklistForm'

type Props = {
  modalOpen: boolean
  handleClose: () => void
}

const MasterChecklistHelpDialog: React.FC<Props> = ({modalOpen, handleClose}) => {
  return (
    <Modal show={modalOpen} onHide={handleClose} size='lg' className='zi-top'>
      <ModalHeader closeButton>
        <Modal.Title>Help</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='mb-5'>
          <div className='text-dark text-hover-primary fs-2 fw-bold'>
            Understanding Checklists in Turnify: A Comprehensive Guide
          </div>
        </div>
        <div className='fs-5 fw-semibold text-gray-600'>
          <p className='mb-2'>
            <strong>Introduction:</strong> Checklists are a powerful tool within Turnify, allowing
            you to streamline property management and ensure tasks are completed efficiently. In
            this guide, we'll delve into the various aspects of using checklists effectively on the
            platform.
          </p>
          <p className='mb-2'>
            <strong>Adding Checklists:</strong> You can add as many checklists as needed for each
            service on Turnify. These checklists can be customized to suit your specific
            requirements. A common approach is to organize checklists into sections, typically
            naming rooms within a property, and listing desired tasks as checklist items under the
            relevant section. However, you have the flexibility to structure checklists differently
            based on your service needs.
          </p>
          <p className='mb-2'>
            <strong>Checklist Items:</strong> Each checklist contains individual items that your
            staff will follow during a job. When adding a checklist item, consider the following key
            components.
          </p>
          <p className='mb-2'>
            <strong>Title for Your Checklist Item:</strong> This title is what your staff will see
            on their mobile apps, providing a quick overview of the task.
          </p>
          <p className='mb-2'>
            <strong>Description:</strong> Use this field to provide a more detailed explanation or a
            tutorial for the specific checklist item, ensuring clarity for your staff.
          </p>
          <p className='mb-2'>
            <strong>Answer Types:</strong> Depending on your requirements, you can choose from
            different answer types for checklist items.
          </p>
          <p className='mb-2'>
            <strong>Yes/No Answer Type:</strong> Staff can respond with a simple "Yes" or "No"
            answer, ideal for questions with binary responses.
          </p>
          <p className='mb-2'>
            <strong>Text/Number Answer Type:</strong> This option allows staff to provide text or
            numeric answers, suitable for more detailed feedback.
          </p>
          <p className='mb-2'>
            <strong>None Answer Type:</strong> Use this when you don't need feedback from staff,
            often used for reminders or minor tasks.
          </p>
          <p className='mb-2'>
            <strong>Photo Required?:</strong> You can specify whether you want photo feedback from
            staff for a checklist item. If enabled, staff must provide a photo confirming the
            completion of the checklist item. You can view these photos in the job detail page when
            the related checklist was used.
          </p>
          <p className='mb-2'>
            <strong>Virtual Inspection:</strong> Virtual Inspection is an optional Turnify service.
            When enabled, our managers virtually inspect checklist items each time they are
            completed. We analyze your requirements, review sample photos depicting your
            expectations, and compare them with staff feedback. If discrepancies arise, both you and
            your staff receive notifications, allowing you to address issues before the next guest
            arrives.
          </p>
          <p className='mb-2'>
            <strong>Upload Image:</strong> For added clarity, you can upload a photo for each
            checklist item to illustrate your expectations. Staff will refer to these images to
            ensure tasks are completed to your satisfaction.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MasterChecklistHelpDialog
