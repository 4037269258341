import { DataGrid } from 'devextreme-react';
import { Column, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Dispatch, FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDialog } from '../../contexts/DialogContext';
import ApiCalls from '../../network/ApiCalls';
import { ForceFinishActions } from '../../redux/actionTypes/finishRequestTypes';
import ApproveButton from '../../umut-components/Buttons/ApproveButton';
import DeclineButton from '../../umut-components/Buttons/DeclineButton';
import EmptyTable from '../../umut-components/Icons/EmptyTable';
import { onExporting } from '../../utils/util';
import * as finishRequestActionCreators from '../../redux/actionCreators/finishRequestCreator';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import ForceFinishModal from './forceFinishModal';


type Props = {
    data:any,
    refetch:()=>void
}
const ForceFinishRequestTable:FC<Props> = ({data,refetch}) =>{
    const { showSuccessDialog } = useDialog()
    const { user } = useSelector((state: RedusxAppState) => state.user);


    const dispatch = useDispatch<Dispatch<ForceFinishActions>>()
    const [loading, setloading] = useState({ columnId: null, boolean: false })
    const tableRef = useRef<DataGrid>(null)
    const history = useHistory();

    const openRequestDetail = (cellValue: any) => {
  
        if (cellValue?.column?.name !== "accept" && cellValue?.column?.name !== "reject") {
            history.push(cellValue && dispatch(finishRequestActionCreators.openForceFinishView(cellValue?.data)) 
            )
        }
    }

    const renderApproveButton = (cellValue: any) => {
        return (
            <ApproveButton disabled={loading.boolean || loading.columnId == cellValue?.data?.jobForceFinishRequestId || cellValue?.data?.jobForceFinishRequestStatusTypeName !== "Pending"} spinner={loading.boolean && loading.columnId == cellValue?.data?.id} iconWidth="15" iconHeight="15" approveFn={async () => {
                try {
                    setloading({ columnId: cellValue?.data?.id, boolean: true })
                    await ApiCalls.postApproveForce(cellValue?.data?.jobForceFinishRequestId)
                    refetch()
                    showSuccessDialog('Request successfully accepted.')
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                } catch (error: any) {
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                }
            }} />
        )
    }
    const renderDeclineButton = (cellValue: any) => {
        return (
            <DeclineButton disabled={loading.boolean || loading.columnId == cellValue?.data?.jobForceFinishRequestId  || cellValue?.data?.jobForceFinishRequestStatusTypeName  !== "Pending"} iconWidth="15" iconHeight="15" declineFn={async () => {
                try {
                    setloading({ columnId: cellValue?.data?.id, boolean: true })
                    await ApiCalls.postDeclineForce(cellValue?.data?.jobForceFinishRequestId)
                    showSuccessDialog('request denied')
                    refetch()
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                } catch (error: any) {
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                }
            }} />
        )
    }
    if (data?.length === 0) {
        return <EmptyTable title='No Job Finish Request' />
    }
    return(

        <div className="position-relative">
        <div style={{ zIndex: 1 }} className="position-absolute top-15">
            <span className="fs-8 text-primary cursor-pointer"
                onClick={() => tableRef.current?.instance.clearFilter()}
            >
                Clear Filter
            </span>
        </div>
        <DataGrid
            id="forceFinish"
            keyExpr="jobForceFinishRequestId"
            onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
            dataSource={data}
            showBorders={false}
            showColumnLines={false}
            ref={tableRef}
            showRowLines={true}
            hoverStateEnabled={true}
            
            height={'calc(45vh - 60px)'}
            className='mt-6'
            onCellClick={openRequestDetail}
            allowColumnResizing={true}
            selection={{ mode: 'single' }}
            onExporting={(e) => onExporting(e, "Force")}
        >
            <Export enabled />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
            <Paging defaultPageSize={50} />
            <Summary>
                <TotalItem cssClass='absolute-right' displayFormat="Total Requests: {0}" column='hostPropertyAddress' summaryType='count' />
            </Summary>

            <Column dataField="propertyName" caption='Property' minWidth={300} dataType='string' cssClass='cls' />
            <Column dataField="hasTechnicalDifficulties" caption='Technical Difficulties' dataType='boolean' alignment="center" width={250} cssClass='cls' />
            <Column dataField="jobForceFinishRequestStatusTypeName" caption='Status' dataType='string' minWidth={130} cssClass='cls' />
            <Column name="accept" cellRender={renderApproveButton} width={130} caption="accept" cssClass='cls' />
            <Column name="reject" cellRender={renderDeclineButton} width={130} caption="reject" cssClass='cls' />
        </DataGrid>
        <ForceFinishModal/>
    </div >
    
    )
    
}
export default ForceFinishRequestTable