import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { IHostPropertyOther } from '../interfaces/Property';
import ApiCalls from '../network/ApiCalls';

const PropertyFormContext = createContext({});

export const PropertyFormProvider = ({ children }: any) => {
    const [cleanerProviders, setCleanerProviders] = useState([]);
    const [accessTypes, setAccessTypes] = useState([]);
    const [otherQuestions, setOtherQuestions] = useState([]);
    const { data: cleanerProvidersData, error: cleanerProviderError } = useQuery('Cleaner Providers', () => ApiCalls.getCleaningProviders(), { cacheTime: 500000, retry: true, refetchOnWindowFocus: false });
    const { data: accessTypesData, error: accessTypesError } = useQuery('Unit Access Types', () => ApiCalls.getAccessTypes(), { cacheTime: 500000, retry: true, refetchOnWindowFocus: false });
    const { data: otherQuestionData, error: otherQuestionError } = useQuery('Other Questions', () => ApiCalls.getHostPropertyOtherQuestion(), { cacheTime: 500000, retry: true, refetchOnWindowFocus: false });

    useEffect(() => {
        if (cleanerProvidersData) {
            setCleanerProviders(cleanerProvidersData?.data?.data?.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [cleanerProvidersData])

    useEffect(() => {
        if (accessTypesData) {
            setAccessTypes(accessTypesData?.data?.data?.map((type: any) => ({ label: type.name, value: type.id })))
        }
    }, [accessTypesData])

    useEffect(() => {
        if (otherQuestionData) {
            setOtherQuestions(otherQuestionData?.data?.data);
        }
    }, [otherQuestionData])

    return (
        <PropertyFormContext.Provider value={{ cleanerProviders, accessTypes, otherQuestions }}>
            {children}
        </PropertyFormContext.Provider>
    );
}

export default PropertyFormContext;

export const usePropertyForm = (): any => useContext(PropertyFormContext);