import React from 'react';
import Pdf from 'react-to-pdf';

function PdfPrinter({ children, targetRef, filename }) {
    return (
        <Pdf targetRef={targetRef} filename={filename}>
            {children}
        </Pdf>
    )
}

export default PdfPrinter;