import React, { Dispatch, useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../Inputs/TextInput';
import { useDialog } from '../../contexts/DialogContext';
import { Button } from '@material-ui/core';
import { IUserDetail } from '../../interfaces/User';
import { UserEditRequest } from '../../network/PostRequestModels/UserRequest';
import { getLocationByAddress, getLocationByAddressForStaff, toAmazonUrl } from '../../utils/util';
import { useQuery } from 'react-query';
import SelectInput from '../Inputs/SelectInput';
import { IStaffDetail } from '../../interfaces/Staff';
import PhoneTextInput from '../Inputs/PhoneInput';
import AutocompleteMap from '../Inputs/AutocompleteMap';
import { ILocation, IStaffAddress } from '../../interfaces/Address';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

type Props = {
    user: IStaffDetail,
    userId: any,
    successFunc?: () => void,
    selectStatusActive?: boolean
}

const UserEditForm: React.FC<Props> = ({ user, userId, successFunc, selectStatusActive }) => {
    const { showSuccessDialog } = useDialog();
    const [editRequest, setEditRequest] = useState<UserEditRequest>({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
    } as UserEditRequest);

    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [statusTypes, setStatusTypes] = useState<any>([])
    const [imagePreview, setImagePreview] = useState<any>(user?.profilePhotoPath ? toAmazonUrl(user?.profilePhotoPath) : null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });
    const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
    const [text, setText] = useState<string>('');

    const { data: staffAddress, isLoading: addressLoading, error: addressError } = useQuery(['Get Staff Address', user.userId], () => ApiCalls.getAddressesByUserId(Number(user.userId)), { onSuccess: (data: any) => setText(data.data.data?.[0]?.address) })
    const homeAddress = staffAddress?.data.data.find((address: IStaffAddress) => address.userAddressTypeId == 2)

    const { data, isLoading, error } = useQuery(
        ['Get User Types'],
        () => ApiCalls.getStaffStatusTypes(),
        { cacheTime: 500000 }
    )



    const handleSelectAddress = (e: string) => {
        setSelectedLocation({ ...selectedLocation, address: e });
        setText(e)
    }
    const handleUserEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            var formData = new FormData();
            formData.append("firstName", editRequest.firstName);
            formData.append("lastName", editRequest.lastName);
            formData.append("phoneNumber", editRequest.phoneNumber);
            formData.append("profilePhoto", editRequest.profilePhoto);
            const { firstName, lastName, phoneNumber } = editRequest;

            if (fullLocation) {
                { homeAddress ? await ApiCalls.updateAddress(Number(userId), Number(homeAddress.userAddressId), fullLocation) : await ApiCalls.createAddress(Number(userId), { ...fullLocation, userAddressTypeId: 2 }) }
            }
            if (firstName && lastName && phoneNumber) {
                selectedStatus !== 0 && await ApiCalls.updateStaffStatus({ accountStatusId: selectedStatus, userId: Number(user.userId) }, user.userId);
                await ApiCalls.editUser(formData, user.userId.toString());
                setLoading(false);
                showSuccessDialog('User Updated.');
                successFunc && successFunc()
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : 'Unexpected error.');
        }
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setEditRequest({ ...editRequest, profilePhoto: file })
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }
    const handleAutocompleteError = () => {
        setText(selectedLocation.address ?? '');
    }

    useEffect(() => {
        getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
    }, [selectedLocation])

    useEffect(() => {
        if (data) {
            setStatusTypes(data?.data?.data.splice(0, 2).map((type: any) => ({ label: type.name, value: type.id })))
        }
    }, [data])

    useEffect(() => {
        if (user) {
            setSelectedStatus(user.accountStatusId);
        }
    }, [user])

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-6'>

                    {
                        selectStatusActive &&
                        <>
                            <label className='form-label fs-6 fw-bolder text-dark'>Select Status</label>
                            <SelectInput
                                options={statusTypes}
                                defaultValue={user?.accountStatusId}
                                onValueChanged={(value: number) => {
                                    setSelectedStatus(value)
                                }}
                            />
                        </>
                    }

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>First Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })} defaultValue={editRequest?.firstName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Last Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, lastName: text })} defaultValue={editRequest?.lastName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>City {<span style={{ color: "red" }}>*</span>}</label>
                    <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
                    {!selectedLocation.address || fullLocation?.zipCode == "" && <><strong className='text-danger fw-6'>Please enter a valid address</strong><br /></>}

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone</label>
                    <PhoneTextInput defaultValue={editRequest.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />


                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleUserEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5 d-flex align-items-center'>
                    <div className='row d-flex justify-content-center'>
                        {imagePreview ? <div className='d-flex justify-content-center'><img className='rounded' style={{ height: 200 }} alt='selected-sample' src={imagePreview} /></div> :
                            <div className="d-flex flex-column align-items-center max-w-250px">
                                <img style={{ width: 250, height: 250 }} src={toAbsoluteUrl('/media/icons/noPhoto.png')} alt="noPhoto" />
                                <strong className='text-muted text-center'>No Photo Available</strong>
                            </div>}   <Button
                                className='col-lg-9 mt-6'
                                variant="contained"
                                component="label"
                            >
                            Upload Photo
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={changeImage}
                                hidden
                            />
                        </Button>
                        (Max Photo Size 2MB)
                    </div>
                </div>


            </div>
        </form>
    )
}

export default React.memo(UserEditForm);