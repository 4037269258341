import React, { FC } from 'react'
import ContentLoader from "react-content-loader"

type Props = {
    custom?: number
}
const piece: Array<number> = [1,2,3];
const bordertop = {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #ddd",
    height: "10px"
}
const borderbottom = {
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #ddd",
    height: "35px"
}

const SkeletonTableLoading: FC<Props> = ({ custom }) => {
     
    return (<>
        <div className="d-flex justify-content-start" > <ContentLoader speed={1.5}
            width={"100%"}
            height={50}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="40" rx="3" ry="3" width="100" height="6" />
            <rect x="300" y="40" rx="3" ry="3" width="100" height="6" />
            <rect x="600" y="40" rx="3" ry="3" width="100" height="6" />
            <rect x="900" y="40" rx="3" ry="3" width="100" height="6" />
            <rect x="93%" y="0" rx="3" ry="6" width="150" height="35" />
        </ContentLoader>
        </div>
        <div style={bordertop}></div>
        <div style={borderbottom}></div>
        <div style={{ overflowX: 'hidden' }}>
            <ContentLoader
                speed={1.5}
                width={"100%"}
                height={300}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"

            >
                <rect x="0" y="25" rx="3" ry="3" width="100%" height="8" />
                {piece.slice(0,custom ? custom : 4).map((idx: number) => (
                    <rect x="0" y={`${(idx * 55) + 20}`} rx="3" ry="3" width="100%" height="8" />
                ))}

            </ContentLoader>
        </div>

    </>
    )
}

export default SkeletonTableLoading;
