import React, {Dispatch, useState} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'
import {IFailedPaymentInformation} from '../../../interfaces/Payment'
import ApiCalls from '../../../network/ApiCalls'
import dayjs from 'dayjs'
import notify from 'devextreme/ui/notify'

const MakePayment = () => {
  const {
    data: failedPaymentList,
    isLoading: failedPaymentListLoading,
    error: failedPaymentListError,
    refetch: refetchFailedPaymentList,
  } = useQuery<AxiosResponse<IFailedPaymentInformation[]>>(
    'Get Failed Payments',
    () => ApiCalls.getFailedPayments(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const makePaymentIntent = async (id: any) => {
    await ApiCalls.makePayment(id)
      .then(async (d) => {
        if (d?.data?.success) {
          refetchFailedPaymentList()
        }
        notify(
          {
            message: d?.data?.success
              ? 'The payment transaction has been completed successfully.'
              : 'An error occurred while payment',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          d?.data?.success ? 'success' : 'error',
          5000
        )
      })
      .catch(() => {
        notify(
          {
            message: 'An error occurred while payment',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          5000
        )
      })
  }

  return (
    <div className='card card-body'>
      <div className='table-responsive border-dashed border-1 rounded px-10'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-25px'>#</th>
              <th className='min-w-150px'>Payment Date</th>
              <th className='min-w-140px'>Amount</th>
              <th className='min-w-150px'>Status</th>
              <th className='min-w-120px'></th>
            </tr>
          </thead>
          <tbody>
            {failedPaymentList?.data.map((cl: any, i: number) => {
              return (
                <tr key={'failed-payment-' + (i + 1) * 10}>
                  <td>{'#' + (i + 1)}</td>
                  <td>{dayjs(new Date(cl.paymentDate)).format('MM/DD/YYYY')}</td>
                  <td>${cl.amountCaptured}</td>
                  <td>
                    <span className='badge badge-danger'>Failed</span>
                  </td>
                  <td>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        makePaymentIntent(cl.id)
                      }}
                    >
                      Make Payment
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MakePayment
