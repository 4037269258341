import React from 'react'
import {Droppable, DropResult, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd'

type Props = {
  droppableId: string
}

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: '100%',
})

const StaffDroppableBoard: React.FC<Props> = (props) => {
  const {droppableId, children} = props
  return (
    <Droppable droppableId={droppableId}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default StaffDroppableBoard
