import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  serviceType: string | undefined
}

const EmptyMasterChecklist: React.FC<Props> = ({serviceType}) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='card-px text-center pt-15 pb-15'>
          <h2 className='fs-2x fw-bold mb-0'>Ready to streamline your property management?</h2>
          <p className='text-gray fs-4 fw-semibold py-7'>
            You don't have any checklists for <span className='fw-bolder'>{serviceType}</span> yet.
            Create checklists for your services to get started! With checklists, your staff can
            efficiently handle tasks, and you can receive quotes from our marketplace service
            providers. Click <span className='fw-bold text-danger'>'Add New Checklist'</span> to
            begin optimizing your property operations.
          </p>
        </div>
        <div className='text-center pb-15 px-5'>
          <img
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')}
            alt=''
            className='mw-100 h-200px h-sm-325px'
          />
        </div>
      </div>
    </div>
  )
}

export default EmptyMasterChecklist
