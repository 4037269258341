import React, {Dispatch, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Editing,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {Modal, Button, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useHistory} from 'react-router-dom'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ViewButton from '../../../umut-components/Buttons/ViewButton'
import {ChecklistEditRequest} from '../../../network/PostRequestModels/ChecklistRequests'
import ApiCalls from '../../../network/ApiCalls'
import {useDialog} from '../../../contexts/DialogContext'
import {useDispatch} from 'react-redux'
import {ChecklistAction} from '../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreator from '../../../redux/actionCreators/checklistActionCreators'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {encryptText, onExporting, setDxFilterPlaceholder} from '../../../utils/util'
import ChecklistButton from '../../../umut-components/Buttons/ChecklistButton'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import {DeleteActions} from '../../../redux/actionTypes/deleteDialogTypes'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../redux/actionCreators/deleteDialogActionCreators'
import UpdateChecklistForJobsButton from '../../../umut-components/Buttons/UpdateChecklistForJobsButton'

type Props = {
  data: IHostPropertyCheckList[]
  refetchChecklists: () => void
}

const ChecklistTable: React.FC<Props> = ({data, refetchChecklists}) => {
  const history = useHistory()
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const tableRef = useRef<DataGrid>(null)
  const [showUpdateJobsModal, setShowUpdateJobsModal] = useState(false)
  const [selectedChecklist, setSelectedChecklist] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | null>(null)

  const renderSectionButton = (cellValue: any) => {
    return (
      <ChecklistButton
        iconWidth='20'
        iconHeight='20'
        viewFn={() => {
          //history.push({pathname: '/checklist-sections/' + encryptText(cellValue?.data?.id)})
          window.open('/checklist-sections/' + encryptText(cellValue?.data?.id))
        }}
      />
    )
  }

  const renderEditButton = (cellValue: any) => {
    return (
      <UpdateButton
        iconWidth='20'
        iconHeight='20'
        updateFn={() => {
          dispatch(checklistActionCreator.openChecklistEditDialog(cellValue?.data))
        }}
      />
    )
  }

  const renderDeleteButton = (cellValue: any) => {
    const deleteAction = async () => {
      try {
        await ApiCalls.deleteChecklist(cellValue?.data?.id)
        refetchChecklists && (await refetchChecklists())
        deleteDispatch(closeDeleteDialog())
        showSuccessDialog('Checklist removed!')
      } catch (err: any) {
        showFailureDialog(err?.response?.data?.message)
      }
    }
    const deleteButtonClick = async () => {
      deleteDispatch(
        openDeleteDialog(
          deleteAction,
          `Attention please! If you delete this checklist, problems may occur when reviewing historical records if the checklist you want to delete was used for previously completed cleaning jobs. Do you still want to remove checklist ${
            cellValue?.data?.name?.en
              ? cellValue?.data?.name?.en
              : cellValue?.data?.name[Object.keys(cellValue?.data?.name)[0]]
          }?`,
          'Remove Checklist'
        )
      )
    }

    return <DeleteButton iconWidth='20' iconHeight='20' deleteFn={deleteButtonClick} />
  }

  const renderTooltip = (props: any, role: any) => (
    <Tooltip id='button-tooltip' {...props}>
      This is the default checklist for {role}
    </Tooltip>
  )

  const renderDefaultButton = (cellValue: any) => {
    return (
      <div className='d-flex justify-content-center'>
        {cellValue?.data?.isDefault ? (
          <OverlayTrigger
            placement='top'
            overlay={(props) =>
              renderTooltip(
                props,
                cellValue?.data?.serviceTypeName +
                  ' ' +
                  cellValue?.data?.roleName +
                  '/' +
                  cellValue?.data?.lobRoleName
              )
            }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-bookmark-fill'
              viewBox='0 0 16 16'
            >
              <path d='M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z' />
            </svg>
          </OverlayTrigger>
        ) : null}
      </div>
    )
  }

  const calculateRoleValue = (cellValue: any) => {
    return cellValue.roleName + '/' + cellValue.lobRoleName
  }

  const calculateChecklistName = (cellValue: any) => {
    return cellValue?.name?.en
  }

  const renderUpdateJobsButton = (cellValue: any) => {
    const updateJobsButtonClick = async () => {
      setSelectedChecklist(cellValue?.data)
      setShowUpdateJobsModal(true)
    }

    return (
      <UpdateChecklistForJobsButton
        iconWidth='20'
        iconHeight='20'
        updateFn={updateJobsButtonClick}
      />
    )
  }

  const updateJobsAction = async () => {
    const data = {
      hostPropertyId: selectedChecklist.hostPropertyId,
      hostPropertyChecklistId: selectedChecklist.id,
    }

    try {
      setErrorText(null)
      setLoading(true)
      await ApiCalls.updateFutureJobsChecklistsByProperty(data)
      refetchChecklists()
      setLoading(false)
      setSelectedChecklist(null)
      setShowUpdateJobsModal(false)
      showSuccessDialog('Jobs Checklists Updated!')
    } catch (err: any) {
      setLoading(false)
      setErrorText(err?.response?.data?.message)
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Checklists' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='checklistTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Checklists')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Checklists: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column width={50} cellRender={renderDefaultButton} cssClass='cls' />

        <Column
          dataField='name'
          calculateCellValue={calculateChecklistName}
          caption='Checklist Name'
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='roleName' caption='Role' minWidth={150} cssClass='cls' />
        <Column dataField='serviceTypeName' caption='Service' minWidth={150} cssClass='cls' />
        <Column width={100} cellRender={renderEditButton} cssClass='cls' />
        <Column width={100} cellRender={renderSectionButton} cssClass='cls' />
        <Column width={100} cellRender={renderUpdateJobsButton} cssClass='cls' />
        <Column width={100} cellRender={renderDeleteButton} cssClass='cls' />
      </DataGrid>
      <Modal
        show={showUpdateJobsModal}
        onHide={() => {
          setShowUpdateJobsModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Update Property Future Jobs Checklists</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          {'Do you want to update property future jobs checklists with ' +
            selectedChecklist?.name?.en}
        </Modal.Body>
        <Modal.Footer className='d-flex flex-column'>
          <div className='d-flex justify-content-end w-100'>
            <Button
              className='d-flex mx-5'
              variant='link'
              onClick={() => {
                setShowUpdateJobsModal(false)
              }}
            >
              Cancel
            </Button>
            <Button variant='danger' onClick={updateJobsAction} disabled={loading}>
              Update {loading ? <Spinner animation='border' size='sm' /> : null}
            </Button>
          </div>
          <div className='w-100 text-end'>
            <strong className='text-danger'>{errorText}</strong>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default React.memo(ChecklistTable)

//                <Column width={100} cellRender={renderEditButton} cssClass='cls' />
