import React, {Dispatch, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {PageTitle} from '../../../_metronic/layout/core'
import {JobStatusObj} from '../../enums/JobEnum'
import JobTable from './JobTable'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {useDispatch, useSelector} from 'react-redux'
import JobDialog from './JobDialog'
import JobCancelDialog from './JobCancelDialog'
import JobCancelTable from './JobCancelTable'
import JobLateStart from './JobLateStart'
import JobLateFinish from './JobLateFinish'
import {JobTabNames} from '../../interfaces/Job'
import JobWarningDialog from './JobWarningDialog'

type TabNames =
  | 'Pending'
  | 'Scheduled'
  | 'Started'
  | 'Not Completed'
  | 'Completed'
  | 'Cancelled'
  | 'Late Start'
  | 'Late Finish'

const Jobs: React.FC = () => {
  const {selectedJobTab} = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()

  const handleChangeTab = (key: any) => {
    dispatch(jobActionCreator.setSelectedJobTab(key))
  }

  return (
    <>
      <PageTitle>Jobs</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body className='d-flex flex-column'>
            <Tabs defaultActiveKey={selectedJobTab} onSelect={handleChangeTab}>
              <Tab eventKey={'Scheduled'} title={'Scheduled'}>
                {selectedJobTab === 'Scheduled' ? (
                  <JobTable jobStatusId={JobStatusObj['Scheduled']} />
                ) : null}
              </Tab>
              <Tab eventKey={'Started'} title={'In Progress'}>
                {selectedJobTab === 'Started' ? (
                  <JobTable jobStatusId={JobStatusObj['Started']} />
                ) : null}
              </Tab>
              <Tab eventKey={'Completed'} title={'Completed'}>
                {selectedJobTab === 'Completed' ? (
                  <JobTable jobStatusId={JobStatusObj['Completed']} />
                ) : null}
              </Tab>
              <Tab eventKey={'Cancelled'} title={'Cancelled'}>
                {selectedJobTab === 'Cancelled' ? (
                  <JobCancelTable jobStatusId={JobStatusObj['Cancelled']} />
                ) : null}
              </Tab>
              {/* <Tab eventKey={"Late Start"} title={"Late Start"}>{selectedJobTab === "Late Start" ? <JobLateStart /> : null}</Tab> */}
              {/* <Tab eventKey={"Late Finish"} title={"Late Finish"}>{selectedJobTab === "Late Finish" ? <JobLateFinish /> : null}</Tab> */}
              <Tab eventKey={'Pending'} title={'Pending'}>
                {selectedJobTab === 'Pending' ? (
                  <JobTable jobStatusId={JobStatusObj['Pending']} />
                ) : null}
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-success'
              onClick={() => dispatch(jobActionCreator.openJobDialog())}
            >
              Add Job
            </button>
          </Card.Footer>
        </Card>
      </div>
      <JobDialog />
      {/*<JobWarningDialog />*/}
    </>
  )
}

export default Jobs
