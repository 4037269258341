import React, {useEffect, useState} from 'react'
import {IChatUser, IStaff} from '../../../interfaces/Staff'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  createChatUser,
  getChatUser,
  getConversationMessages,
  getConversationWithUser,
  sendMessageToUser,
} from '../../../utils/comet'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import axios from 'axios'
import {IChatMessage} from '../../../interfaces/Comet'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

type Props = {
  staff: IStaff | undefined
  handleShow: boolean
  handleClose: () => void
}

const HostStaffChat: React.FC<Props> = ({staff, handleShow, handleClose}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const sender = user.userSectionType + '-' + user.user.id
  const receiver = staff?.isMarketplace
    ? 'vendorusers-' + staff.userId
    : 'hostusers-' + staff?.userId
  const [message, setMessage] = useState('')
  const [chatUser, setChatUser] = useState<IChatUser>()
  const [messageList, setMessageList] = useState<IChatMessage[]>([])

  useEffect(() => {
    if (!handleShow) {
      setMessageList([])
      setChatUser(undefined)
      setMessage('')
    }
  }, [handleShow])

  const updateMessage = (text: string) => {
    setMessage(text)
  }

  useEffect(() => {
    if (staff != undefined) {
      getChatUserInfo()
    }
  }, [staff])

  useEffect(() => {
    if (chatUser != undefined) {
      getConversationInfo()
    }
  }, [chatUser])

  const closeChat = () => {
    handleClose()
    setTimeout(() => {
      setMessageList([])
      setChatUser(undefined)
      setMessage('')
    }, 300)
  }

  const getChatUserInfo = async () => {
    const user = await getChatUser(receiver)
    if (user.result == 'success') {
      setChatUser(user.data)
    } else {
      if (staff != undefined) {
        const createUser = await createChatUser(
          receiver,
          staff?.firstName + ' ' + staff?.lastName,
          staff?.email,
          staff?.phoneNumber
        )

        if (createUser.result == 'success') {
          const user = await getChatUser(receiver)
          if (user.result == 'success') {
            setChatUser(user.data)
          }
        }
      }
    }
  }

  const getConversationInfo = async () => {
    const conversation = await getConversationWithUser(sender, receiver)
    if (conversation.result == 'success') {
      getConversationMessageList(conversation.data.conversationId)
    }
  }

  const sendMessage = async () => {
    if (message == '' || message == null) {
    } else {
      const result = await sendMessageToUser(sender, receiver, message)
      if (result.result == 'success') {
        setMessage('')
        getConversationMessageList(result.data.conversationId)
      }
    }
  }

  const getConversationMessageList = async (conversationId: string) => {
    const allMessages = await getConversationMessages(conversationId, sender)
    if (allMessages.result == 'success') {
      setMessageList(allMessages.data)
    }
  }

  return (
    <div
      className={
        handleShow
          ? 'bg-body drawer drawer-end chat-drawer drawer-on'
          : 'bg-body drawer drawer-end chat-drawer'
      }
    >
      <div className='card w-100 border-0 rounded-0'>
        <div className='card-header pe-5'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <div className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                {staff?.firstName + ' ' + staff?.lastName.substring(0, 1) + '.'}{' '}
              </div>
              {staff?.isMarketplace && (
                <div className='fs-6 fw-bold text-gray-700'>{staff?.vendorName}</div>
              )}
              {chatUser?.status == 'online' ? (
                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-semibold text-muted'>Online</span>
                </div>
              ) : (
                <div className='mb-0 lh-1'>
                  <span className='badge badge-secondary badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-semibold text-muted'>Offline</span>
                </div>
              )}
            </div>
          </div>
          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary bg-light'
              onClick={closeChat}
            >
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-2' />
            </div>
          </div>
        </div>
        <div className='card-body'>
          {messageList.length > 0 && (
            <div className='scroll-y me-n5 pe-5'>
              {messageList.map((message, index) => {
                return (
                  <>
                    <ChatMessageItem
                      type={message.sender == sender ? 'sender' : 'receiver'}
                      message={message.data.text}
                      date={message.sentAt}
                      staff={staff}
                      user={user}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
        <div className='card-footer pt-4'>
          <textarea
            className='form-control form-control-flush mb-3'
            placeholder='Type a message'
            onChange={(v) => {
              updateMessage(v.target.value)
            }}
            value={message}
          ></textarea>
          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-2'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                type='button'
                data-bs-toggle='tooltip'
                aria-label='Coming soon'
                data-bs-original-title='Coming soon'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-paper-clip fs-3'></i>{' '}
              </button>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                type='button'
                data-bs-toggle='tooltip'
                aria-label='Coming soon'
                data-bs-original-title='Coming soon'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-cloud-add fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>{' '}
              </button>
            </div>
            <button className='btn btn-primary' type='button' onClick={sendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostStaffChat

type MsgCardProps = {
  type: 'sender' | 'receiver'
  message: string
  date: number
  staff: IStaff | undefined
  user: any
}

const ChatMessageItem: React.FC<MsgCardProps> = ({type, message, date, staff, user}) => {
  const [classLabel, setClassLabel] = useState(type == 'sender' ? 'start' : 'end')
  const [userName, setUserName] = useState(
    type == 'sender'
      ? user.user.firstName + ' ' + user.user.lastName
      : staff?.firstName + ' ' + staff?.lastName.substring(0, 1) + '.'
  )
  const [userShortName, setUserShortName] = useState(
    type == 'sender'
      ? user.user.firstName.substring(0, 1) + '' + user.user.lastName.substring(0, 1)
      : staff?.firstName.substring(0, 1) + '' + staff?.lastName.substring(0, 1)
  )

  const getMessageTime = (date: number) => {
    const realDate = dayjs(new Date(date * 1000))
    const diff = dayjs().diff(realDate, 'day')
    if (diff > 1) {
      return realDate.format('MM DD, YYYY HH:mm A')
    } else {
      return realDate.fromNow()
    }
  }

  return (
    <div className={'d-flex justify-content-' + classLabel + ' mb-10 '}>
      <div className={'d-flex flex-column align-items-' + classLabel}>
        <div className='d-flex align-items-center mb-2'>
          <div className='symbol symbol-35px symbol-circle'>
            <span className='symbol-label'>{userShortName}</span>
          </div>
          <div className='ms-3'>
            <div className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'>{userName}</div>
            <div className='text-muted fs-7 mb-1'>{getMessageTime(date)}</div>
          </div>
        </div>
        <div
          className={
            'p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-' + classLabel
          }
        >
          {message}
        </div>
      </div>
    </div>
  )
}
