import React, {Dispatch, useEffect, useState} from 'react'
import {IHostPropertyInput, IMarketplaceSubscription} from '../../../../../interfaces/Property'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import ApiCalls from '../../../../../network/ApiCalls'
import {AxiosResponse} from 'axios'
import {IBillingInformation} from '../../../../../interfaces/Payment'
import {useQuery} from 'react-query'
import {useDialog} from '../../../../../contexts/DialogContext'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import StripeContainer from './Stripe/StripeContainer'
import {DeleteActions} from '../../../../../redux/actionTypes/deleteDialogTypes'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../../../redux/actionCreators/deleteDialogActionCreators'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'

type Props = {
  propertyId: number
  property: IHostPropertyInput | undefined
  refetch: () => void
}

const Payment: React.FC<Props> = ({propertyId, property, refetch}) => {
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const [addCardSection, setAddCardSection] = useState(false)
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const [propertyInfo, setPropertyInfo] = useState<IHostPropertyInput | undefined>(property)
  const [subscription, setSubscription] = useState<IMarketplaceSubscription | undefined>()

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(
    ['Get Customer Sources', user.userSection.hostId],
    () => ApiCalls.getCustomerSources(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {
    data: subscriptionInfo,
    isLoading: subscriptionLoading,
    error: subscriptionError,
    refetch: subscriptionFetching,
  } = useQuery(
    [
      'Get Property subscription info',
      user.userSection.hostId,
      property?.cleaningProviderId,
      property?.isMarketplace,
      propertyId,
    ],
    () =>
      ApiCalls.getSubscriptionInfo({hostPropertyId: propertyId, hostId: user.userSection.hostId}),
    {cacheTime: 50000, refetchOnWindowFocus: false, enabled: property?.isMarketplace ? true : false}
  )
  const {
    data: billingInformation,
    isLoading,
    error,
    refetch: refetchBillingInformation,
  } = useQuery<AxiosResponse<IBillingInformation>>(
    ['Get Billing Information', user.userSection.hostId],
    () => ApiCalls.getBillingInformation(),
    {cacheTime: 100000, retry: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!property?.isMarketplace) {
      setSubscription(undefined)
    }
  }, [property])

  useEffect(() => {
    if (!subscriptionLoading && subscriptionInfo?.data != undefined) {
      setSubscription(subscriptionInfo?.data)
    }
  }, [subscriptionInfo, subscriptionLoading])

  useEffect(() => {
    if (!billingInformation?.data.stripeAccountId || error) {
      try {
        ApiCalls.postBillingInformation()
          .then((response) => {
            if (!response.status) {
              throw response
            }
          })
          .catch((error) => console.log(error))
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  const toggleAddCard = () => {
    setAddCardSection(!addCardSection)
  }

  const updateCardInfo = async (cardInfo: any) => {
    var request = {hostPropertyPaymentInfo: {stripeAccountCardId: cardInfo.id}}
    try {
      await ApiCalls.updateHostPropertyPayment(propertyId, request)
      showSuccessDialog('Your property payment info successfully updated')
      cardListFetching()
      refetch()
      if (propertyInfo != undefined) {
        var newInfo = propertyInfo
        newInfo.stripeAccountCardId = cardInfo.id
        setPropertyInfo(newInfo)
      }
    } catch (error: any) {
      showFailureDialog('An error occur during update property payment info')
    }
  }

  const cancelSubscriptionPrompt = () => {
    deleteDispatch(
      openDeleteDialog(
        cancelSubscription,
        `Are you sure you want to unsubscribe from Marketplace? Note: This action will cancel all future jobs associated with your property.`,
        'Cancel Subscription',
        'Apply'
      )
    )
  }

  const cancelSubscription = async () => {
    try {
      await ApiCalls.cancelSubscription({
        hostPropertyId: propertyId,
        hostId: user.userSection.hostId,
        subscriptionId: subscription?.id,
      })
      showSuccessDialog('Your subscription successfully canceled')
      closeDeleteDialog()
      refetch()
    } catch (error) {}
  }

  const openUrl = (url: string) => {
    window.open(url, '_blank')
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }

  return (
    <>
      {subscription && (
        <div className='card mb-5'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-7'>
                <h3>Active since {subscription.startDate}</h3>
                <p className='fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15'>
                  We will send you a notification upon Subscription expiration{' '}
                </p>
                <div className='fs-5 mb-2'>
                  <span className='text-gray-800 fw-bold me-1'>
                    {'$' + (subscription.price / 100).toFixed(2)}
                  </span>
                  <span className='text-gray-600 fw-semibold'>
                    Per {subscription.period.charAt(0).toUpperCase() + subscription.period.slice(1)}
                  </span>
                </div>
                <div className='fs-6 text-gray-600 fw-semibold'>Marketplace Subscription</div>
              </div>
              <div className='col-lg-5'>
                <div className='d-flex justify-content-end pb-0 px-0'>
                  <button
                    className='btn btn-light btn-active-light-danger me-2'
                    onClick={() => {
                      cancelSubscriptionPrompt()
                    }}
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='card'>
        <div className='card-header card-header-stretch pb-0'>
          <div className='card-title'>
            <h3 className='m-0'>Payment Methods</h3>
          </div>
          <div className='card-toolbar' style={{maxWidth: '10%', minWidth: 140}}>
            <img
              src={toAbsoluteUrl('/media/svg/powered-by-stripe.png')}
              alt='stripe'
              className='w-100 object-contain'
            />
          </div>
        </div>
        <div className='card-body'>
          <div className='row gx-9 gy-6'>
            {cardList?.data && cardList?.data.length > 0 && (
              <>
                {cardList?.data.map((cl: any, i: number) => {
                  return (
                    <div className='col-xl-6' key={'card-' + i}>
                      <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                        <div className='d-flex flex-column py-2'>
                          <div className='d-flex align-items-center'>
                            <CreditLogos brand={cl?.brand} />
                            <div>
                              <div className='fs-4 fw-bold'>
                                {cl?.brand} **** {cl?.last4}
                              </div>
                              <div className='fs-7 fw-semibold text-gray-500'>
                                Card expires at {cl?.exp_month + '/' + cl?.exp_year}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          {((propertyInfo?.stripeAccountCardId &&
                            propertyInfo.stripeAccountCardId != cl.id) ||
                            !propertyInfo?.stripeAccountCardId) && (
                            <button
                              className='btn btn-sm btn-light btn-active-light-primary me-3'
                              onClick={() => {
                                updateCardInfo(cl)
                              }}
                            >
                              Use This
                            </button>
                          )}
                          {propertyInfo?.stripeAccountCardId &&
                            propertyInfo?.stripeAccountCardId == cl.id && (
                              <button className='btn btn-sm btn-light-success'>Selected</button>
                            )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            )}
            <div className='col-xl-6'>
              <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Add a Card</h4>
                    <div className='fs-6 text-gray-700 pe-7'>
                      Save time and track your expenses with adding your payment card.
                    </div>
                  </div>
                  <button
                    className='btn btn-primary px-6 align-self-center text-nowrap'
                    onClick={() => {
                      toggleAddCard()
                    }}
                  >
                    + Add New Card
                  </button>
                </div>
              </div>
            </div>
          </div>
          {addCardSection && (
            <div className='row gx-9 gy-6 mt-5'>
              <div className='col-md-5 col-12 ms-auto me-auto mb-4 mt-8'>
                <StripeContainer />
              </div>
            </div>
          )}
        </div>
      </div>
      {subscription && (
        <>
          <div className='card mt-5 mb-5 mb-xl-10'>
            <div className='card-header'>
              <div className='card-title'>
                <h3>Billing Address</h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='row gx-9 gy-6'>
                <div className='col-xl-12'>
                  <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                    <div className='d-flex flex-column py-2'>
                      <div className='d-flex align-items-center fs-5 fw-bold mb-5'>
                        {subscription?.customer?.name}
                      </div>
                      <div className='fs-6 fw-semibold text-gray-600'>
                        {subscription?.customer?.email}
                        <br />
                        {subscription?.customer?.phone}
                        <br />
                        <br />
                        {subscription?.customer?.address && (
                          <>
                            {subscription?.customer?.address.city +
                              ' / ' +
                              subscription?.customer?.address.state +
                              ' / ' +
                              subscription?.customer?.address.country}
                            <br />
                            {subscription?.customer?.address.line1}
                            <br />
                            {subscription?.customer?.address.postal_code}
                            <br />
                          </>
                        )}
                      </div>
                    </div>
                    <div className='d-flex align-items-center py-2'>
                      <button className='btn btn-sm btn-light btn-active-light-primary'>
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {subscription.invoices.length > 0 && (
            <div className='card mt-5 mb-5 mb-xl-10'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Billing History</h3>
                </div>
              </div>
              <div className='card-body '>
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gy-4 gs-9'>
                    <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                      <tr>
                        <td className='min-w-150px'>Invoice No.</td>
                        <td className='min-w-150px'>Date</td>
                        <td className='min-w-250px'>Status</td>
                        <td className='min-w-150px'>Amount</td>
                        <td className='min-w-150px'>Invoice</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {subscription.invoices.map((invoice, index) => {
                        return (
                          <tr key={'invoice-' + index}>
                            <td>{invoice.number}</td>
                            <td>{invoice.dueDate}</td>
                            <td>
                              {invoice.paid ? (
                                <span className='badge badge-light-success'>Paid</span>
                              ) : (
                                <span className='badge badge-light-danger'>Unpaid</span>
                              )}
                            </td>
                            <td>
                              <button
                                className='btn btn-sm btn-light btn-active-light-primary'
                                onClick={() => {
                                  openUrl(invoice.invoicePdf)
                                }}
                              >
                                PDF
                              </button>
                            </td>
                            <td>
                              <button
                                className='btn btn-sm btn-light btn-active-light-primary'
                                onClick={() => {
                                  openUrl(invoice.hostedInvoiceUrl)
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <DeleteDialog />
        </>
      )}
    </>
  )
}

export default Payment

type CreditProps = {
  brand: string | undefined
}
const CreditLogos: React.FC<CreditProps> = ({brand}) => {
  switch (brand) {
    case 'MasterCard':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/mc.png')}
          alt='mc'
          className='me-4'
        />
      )
    case 'Visa':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/visa_electron.png')}
          alt='visa'
          className='me-4'
        />
      )
    case 'AmericanExpress':
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express.png')}
          alt='ae'
          className='me-4'
        />
      )
    default:
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express_green.png')}
          alt='defaultcard'
          className='me-4'
        />
      )
  }
}
