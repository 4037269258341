import { IChecklistItem, IHostPropertyCheckList, IPhoto, IQuestion, ISection } from "../../interfaces/Checklist";
import * as actions from "../actionTypes/checklistTypes";

export function getChecklistItems(checklistId: number): actions.GetChecklistItems {
    return {
        type: actions.GET_CHECKLIST_ITEMS,
        checklistId
    }
}

export function getChecklistItemsSuccess(checklistItems: IChecklistItem): actions.GetChecklistItemsSuccess {
    return {
        type: actions.GET_CHECKLIST_ITEMS_SUCCESS,
        checklistItems
    }
}

export function getChecklistItemsFailure(errorText: string): actions.GetChecklistItemsFailure {
    return {
        type: actions.GET_CHECKLIST_ITEMS_FAILURE,
        errorText
    }
}

export function setChecklistItems(checklistItems: IChecklistItem): actions.SetChecklistItems {
    return {
        type: actions.SET_CHECKLIST_ITEMS,
        checklistItems
    }
}

export function openChecklistEditDialog(checklist: IHostPropertyCheckList): actions.OpenChecklistEditDialog {
    return {
        type: actions.OPEN_CHECKLIST_EDIT_DIALOG,
        checklist
    }
}

export function closeChecklistEditDialog(): actions.CloseChecklistEditDialog {
    return {
        type: actions.CLOSE_CHECKLIST_EDIT_DIALOG,
    }
}

export function openQuestionDialog(section: ISection): actions.OpenQuestionDialog {
    return {
        type: actions.OPEN_QUESTION_DIALOG,
        section
    }
}

export function closeQuestionDialog(): actions.CloseQuestionDialog {
    return {
        type: actions.CLOSE_QUESTION_DIALOG,
    }
}

export function setQuestionOrder(section: ISection): actions.SetQuestionOrder {
    return {
        type: actions.SET_QUESTION_ORDER,
        section
    }
}

export function openSectionEditDialog(section: ISection): actions.OpenSectionEditDialog {
    return {
        type: actions.OPEN_SECTION_EDIT_DIALOG,
        section
    }
}

export function closeSectionEditDialog(): actions.CloseSectionEditDialog {
    return {
        type: actions.CLOSE_SECTION_EDIT_DIALOG,
    }
}

export function openQuestionEditDialog(question: IQuestion): actions.OpenQuestionEditDialog {
    return {
        type: actions.OPEN_QUESTION_EDIT_DIALOG,
        question
    }
}

export function closeQuestionEditDialog(): actions.CloseQuestionEditDialog {
    return {
        type: actions.CLOSE_QUESTION_EDIT_DIALOG,
    }
}

export function openQuestionAddDialog(section: ISection | null): actions.OpenQuestionAddDialog {
    return {
        type: actions.OPEN_QUESTION_ADD_DIALOG,
        section
    }
}

export function closeQuestionAddDialog(): actions.CloseQuestionAddDialog {
    return {
        type: actions.CLOSE_QUESTION_ADD_DIALOG,
    }
}

export function openQuestionPhotoDialog(question: IQuestion | null): actions.OpenQuestionPhotoDialog {
    return {
        type: actions.OPEN_QUESTION_PHOTO_DIALOG,
        question
    }
}

export function closeQuestionPhotoDialog(): actions.CloseQuestionPhotoDialog {
    return {
        type: actions.CLOSE_QUESTION_PHOTO_DIALOG,
    }
}