import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {
  IHostPropertyAutomation,
  IHostPropertyICal,
  IHostPropertyInput,
  IHostPropertyManualInput,
  ILocation,
} from '../../../interfaces/Property'
import ApiCalls from '../../../network/ApiCalls'
import {
  GuestyRequest,
  HostawayRequest,
  MyVrRequest,
  StayntouchRequest,
  MewsRequest,
  GuestyRequestV2,
} from '../../../network/PostRequestModels/PropertyFromPMS'
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Button,
  Spinner,
  useAccordionToggle,
} from 'react-bootstrap-v5'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {decryptText, getStaticMap} from '../../../utils/util'
import SelectBox from '../../../umut-components/Inputs/SelectBox'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import SwitchBox from '../../../umut-components/Inputs/SwitchBox'
import {TextInput} from '../../../umut-components/Inputs/TextInput'
import TimePicker from '../../../umut-components/Inputs/TimePicker'
import Map from '../../../umut-components/Map/GoogleMap'
import './custom.css'
import {useDialog} from '../../../contexts/DialogContext'
import {HostawayForm} from '../../../pages/add-property-pms/Forms/HostawayForm'
import {GuestyForm} from '../../../pages/add-property-pms/Forms/GuestyForm'
import {MyVrForm} from '../../../pages/add-property-pms/Forms/MyVrForm'
import {StayntouchForm} from '../../../pages/add-property-pms/Forms/StayntouchForm'
import PropertyListTable from '../../../pages/add-property-from-pms/Table/PropertyListTable'
import {Mews} from '../../../pages/add-property-pms/Forms/Mews'
const debounce = require('debounce')

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyManualInput[]) => void
  goToStep: (key: any) => void
  missingField?: boolean
  savedValues?: IHostPropertyManualInput[]
}

const SelectPropertyStep: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  savedValues,
}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyList, setPropertyList] = useState<any[]>([])
  const [selectedPropertyList, setSelectedPropertyList] = useState<IHostPropertyManualInput[]>([])
  const [pmsTypeSelectObj, setPmsTypeSelectObj] = useState<any>({})
  const [pmsTypeActive, setPmsTypeActive] = useState<boolean>(true)
  const [propertyCount, setPropertyCount] = useState<number | null>(null)
  const [selectedPMSType, setSelectedPMSType] = useState(null)
  const {showFailureDialog} = useDialog()
  const {
    data: pmsTypesData,
    isLoading: pmsTypeLoading,
    error: pmsTypeError,
  } = useQuery('Get PMS Types', () => ApiCalls.getPMSTypes(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
  const {
    data: pmsKeyData,
    isLoading: pmsKeyLoading,
    isFetching: pmsKeyFetching,
    error: pmsKeyError,
  } = useQuery(
    ['Get PMS Keys', user.userSection.hostId, selectedPMSType],
    () =>
      ApiCalls.getPMSKeysByHostId(user.userSection.hostId, selectedPMSType ? selectedPMSType : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: selectedPMSType ? true : false}
  )

  useEffect(() => {
    if (pmsTypesData) {
      let selectObj: any = {}
      pmsTypesData?.data?.data
        ?.filter((type: any) => type.isActive)
        .forEach((type: any) => {
          let value = type.id
          let key = type.name
          selectObj[key] = value
        })
      setPmsTypeSelectObj(selectObj)
    }
  }, [pmsTypesData])

  const onPropertySelect = async (data: any) => {
    var list = data?.selectedRowsData
    list.forEach((l: any) => {
      return (
        (l.cleaningProviderId = 1),
        (l.hasProQuoteRequest = false),
        (l.hasPlusQuoteRequest = false),
        (l.numberOfBathrooms = +l.numberOfBathrooms),
        (l.numberOfBedrooms = +l.numberOfBedrooms),
        (l.numberOfBeds = +l.numberOfBeds),
        (l.hostPropertyAutomation = {
          isAutomaticSchedule: true,
          isAutomaticPayment: false,
          isAutomaticCalendarSync: true,
          checkInTime: l.defaultCheckInTime,
          checkOutTime: l.defaultCheckOutTime,
          usePMS: true,
        }),
        (l.hostPropertyAccess = {
          unitAccessTypeId: 0,
          unitAccessValue: '',
          buildingAccessTypeId: 0,
          buildingAccessValue: '',
        }),
        (l.hostPropertyBedrooms = []),
        (l.hostPropertyServiceProvider = {
          hasStorage: false,
          storageDescription: '',
          storageInQuote: false,
          hasParking: false,
          parkingDescription: '',
          cleaningProviderId: 1,
          hasLaundry: false,
          hasDryer: false,
          laundryInQuote: false,
          laundryToBeDone: false,
          laundryMachineCycle: 0,
          laundryDryerCycle: 0,
          laundryLinenNumber: '',
          hasTrashDisposal: false,
          trashDays: '',
          propertySize: 0,
        })
      )
    })
    setSelectedPropertyList(list)
  }

  const fetchPropertyList = async (data: any) => {
    setPropertyLoading(true)
    setPropertyList([])
    try {
      let totalProperty = 0
      let requestData: any = {}
      //  !!! this logic does not belong here move it  !!!
      if (selectedPMSType === pmsTypeSelectObj['Hostaway']) {
        const hostawayRequest: HostawayRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromHostify(hostawayRequest)
        setPropertyList(responseData?.data)
        setPropertyCount(responseData?.count)
        totalProperty = responseData?.count
        requestData = hostawayRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Stayntouch']) {
        const stayntouchRequest: StayntouchRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromStayntouch(stayntouchRequest)
        setPropertyList(responseData?.data)
        setPropertyCount(responseData?.count)
        totalProperty = responseData?.count
        requestData = stayntouchRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Guesty']) {
        const guestyRequest: GuestyRequestV2 = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromGuesty(guestyRequest)
        setPropertyList(responseData?.data)
        setPropertyCount(responseData?.count)
      }
      if (selectedPMSType === pmsTypeSelectObj['MyVR']) {
        const myVrRequest: MyVrRequest = {
          token: data.token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMyVr(myVrRequest)
        setPropertyList(responseData?.data)
        setPropertyCount(responseData?.count)
      }
      if (selectedPMSType === pmsTypeSelectObj['Mews']) {
        const mewsRequest: MewsRequest = {
          client_token: data.client_token,
          access_token: data.access_token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMews(mewsRequest)
        setPropertyList(responseData?.data)
        setPropertyCount(responseData?.count)
      }
      setPmsTypeActive(false)
      setPropertyLoading(false)
    } catch (err: any) {
      setPropertyLoading(false)
      setPropertyList([])
      showFailureDialog('Please check the required fields again.')
      return err?.response?.data?.message
    }
  }

  if (pmsTypeLoading) {
    return <div />
  }

  const changeSelectedPropertyVariables = (type: string, index: number, value: any) => {
    var plist = selectedPropertyList
    if (type == 'sameDayBooking') {
      var find = plist.find((spl, i) => i == index)
      if (find != undefined) {
        find.sameDayBooking = value ? true : false
        setSelectedPropertyList(plist)
      }
    }

    if (type == 'automaticSchedule') {
      var find = plist.find((spl, i) => i == index)
      if (find != undefined) {
        if (find.hostPropertyAutomation != undefined) {
          find.hostPropertyAutomation.isAutomaticSchedule = value ? true : false
          setSelectedPropertyList(plist)
        }
      }
    }

    if (type == 'checkOutTime') {
      var find = plist.find((spl, i) => i == index)
      if (find != undefined) {
        if (find.hostPropertyAutomation != undefined) {
          find.hostPropertyAutomation.checkOutTime = value
          setSelectedPropertyList(plist)
        }
      }
    }

    if (type == 'checkInTime') {
      var find = plist.find((spl, i) => i == index)
      if (find != undefined) {
        if (find.hostPropertyAutomation != undefined) {
          find.hostPropertyAutomation.checkInTime = value
          setSelectedPropertyList(plist)
        }
      }
    }
  }

  const toggleAccordion = (index: string) => {
    useAccordionToggle(index)
  }

  const onNextStepClick = () => {
    if (selectedPropertyList.length == 0) {
      showFailureDialog('Please select at least one property!')
    } else {
      handleChangeHostProperty(selectedPropertyList)
      goToStep(2)
    }
  }

  return (
    <>
      <SelectBox
        searchBoxObj={pmsTypeSelectObj}
        onValueChanged={setSelectedPMSType}
        disabledText='Select a PMS Type'
      />
      {selectedPMSType === pmsTypeSelectObj['Hostaway'] ? (
        pmsKeyFetching ? (
          <div>Loading...</div>
        ) : (
          <HostawayForm
            keys={pmsKeyData?.data?.data[0]}
            fetchPropertyList={fetchPropertyList}
            propertyLoading={propertyLoading}
          />
        )
      ) : null}
      {selectedPMSType === pmsTypeSelectObj['Guesty'] ? (
        pmsKeyFetching ? (
          <div>Loading...</div>
        ) : (
          <GuestyForm
            keys={pmsKeyData?.data?.data[0]}
            fetchPropertyList={fetchPropertyList}
            propertyLoading={propertyLoading}
          />
        )
      ) : null}
      {selectedPMSType === pmsTypeSelectObj['MyVR'] ? (
        pmsKeyFetching ? (
          <div>Loading...</div>
        ) : (
          <MyVrForm
            keys={pmsKeyData?.data?.data[0]}
            fetchPropertyList={fetchPropertyList}
            propertyLoading={propertyLoading}
          />
        )
      ) : null}
      {selectedPMSType === pmsTypeSelectObj['Stayntouch'] ? (
        pmsKeyFetching ? (
          <div>Loading...</div>
        ) : (
          <StayntouchForm
            keys={pmsKeyData?.data?.data[0]}
            fetchPropertyList={fetchPropertyList}
            propertyLoading={propertyLoading}
          />
        )
      ) : null}
      {selectedPMSType === pmsTypeSelectObj['Mews'] ? (
        pmsKeyFetching ? (
          <div>Loading...</div>
        ) : (
          <Mews
            keys={pmsKeyData?.data?.data[0]}
            fetchPropertyList={fetchPropertyList}
            propertyLoading={propertyLoading}
          />
        )
      ) : null}
      {propertyList.length > 0 && (
        <div className='w-100 mb-20'>
          <PropertyListTable
            selectType='multiple'
            data={propertyList}
            onSelectionChanged={onPropertySelect}
          />
        </div>
      )}
      {selectedPropertyList.length > 0 && (
        <>
          <div className='row mb-5'>
            <h5 className='text-danger'>
              This is the property information we've pulled from your PMS system for the following
              location(s), please review for accuracy and adjust as needed:
            </h5>
          </div>
          <Accordion defaultActiveKey='0'>
            {selectedPropertyList.map((spl, index) => {
              return (
                <>
                  <div className='w-100'>
                    <h4 className='accordion-header d-flex'>
                      <AccordionToggle className='w-100' as='div' eventKey={"'" + index + "'"}>
                        <button className='btn btn-primary w-100 mb-5' type='button'>
                          {spl.address}
                        </button>
                      </AccordionToggle>
                    </h4>
                    <AccordionCollapse eventKey={"'" + index + "'"}>
                      <div className='w-100 mb-20'>
                        <div className='row w-100 mt-10'>
                          <div className='col-lg-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              Same Day Booking
                            </label>
                            <SwitchBox
                              mutedText
                              defaultChecked={spl.sameDayBooking}
                              text='When enabled, Check-in Time cannot be earlier then Check-out Time.'
                              onCheckboxChange={(v) => {
                                changeSelectedPropertyVariables('sameDayBooking', index, v)
                              }}
                            />

                            <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                              Automatic Scheduling
                            </label>
                            <SwitchBox
                              mutedText
                              defaultChecked={
                                spl.hostPropertyAutomation?.isAutomaticSchedule !== undefined
                                  ? spl.hostPropertyAutomation?.isAutomaticSchedule
                                  : true
                              }
                              text='When enabled, cleaners will be automatically sent cleaning requests for all of your checkouts.'
                              onCheckboxChange={(v) => {
                                changeSelectedPropertyVariables('automaticSchedule', index, v)
                              }}
                            />
                          </div>
                          <div className='d-flex flex-column col-lg-6'>
                            <strong>
                              Please select the check-in / check-out times of your property.
                            </strong>
                            <small className='text-muted'>
                              Assignments will be made based on the times you have chosen while
                              creating the cleaning schedule for your property.
                            </small>
                            <div className='row'>
                              <div className='w-50'>
                                {' '}
                                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                                  Check-out Time {<span style={{color: 'red'}}>*</span>}
                                </label>
                                <TimePicker
                                  onChange={(v) => {
                                    changeSelectedPropertyVariables('checkOutTime', index, v)
                                  }}
                                  defaultValue={spl.hostPropertyAutomation?.checkOutTime ?? '11:00'}
                                />
                              </div>
                              <div className='w-50'>
                                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                                  Check-in Time {<span style={{color: 'red'}}>*</span>}
                                </label>
                                <TimePicker
                                  onChange={(v) => {
                                    changeSelectedPropertyVariables('checkInTime', index, v)
                                  }}
                                  defaultValue={spl.hostPropertyAutomation?.checkInTime ?? '15:00'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionCollapse>
                  </div>
                </>
              )
            })}
          </Accordion>
        </>
      )}
      {propertyList.length > 0 && (
        <div className='row'>
          <div className='d-flex justify-content-end flex-row w-100 mt-6'>
            <ArrowButton type='forward' func={onNextStepClick} classNames='align-self-end' />
          </div>
        </div>
      )}
    </>
  )
}

export default SelectPropertyStep
