import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import ApiCalls from '../../../../network/ApiCalls';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import { decryptText } from '../../../../utils/util';
import StaffTable from './StaffTable';

type Props = {
    jobEventsActive: boolean
}

const StaffTab: React.FC<Props> = ({ jobEventsActive }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { data, isLoading, error } = useQuery(['Get Job Staffs By Job Id', jobId], () => ApiCalls.getJobStaffs(jobId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <StaffTable data={data?.data?.data} />
        </>
    )
}

export default StaffTab;