import React, {useEffect, useState} from 'react'
import {
  IHostPropertyICal,
  IHostPropertyInput,
  IPMSProperty,
} from '../../../../../interfaces/Property'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'
import dayjs from 'dayjs'
import SwitchBox from '../../../../../umut-components/Inputs/SwitchBox'
import TimePicker from '../../../../../umut-components/Inputs/TimePicker'
import {useQuery} from 'react-query'
import {PMSTypeName} from '../../../../../enums/PropertyEnum'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import PropertyTable from '../../../../add-property-pms/Table/PropertyTable'
import SelectBox from '../../../../../umut-components/Inputs/SelectBox'
import {HostawayForm} from '../../../../add-property-pms/Forms/HostawayForm'
import {GuestyForm} from '../../../../add-property-pms/Forms/GuestyForm'
import {MyVrForm} from '../../../../add-property-pms/Forms/MyVrForm'
import {StayntouchForm} from '../../../../add-property-pms/Forms/StayntouchForm'
import {
  GuestyRequestV2,
  HostawayRequest,
  MewsRequest,
  MyVrRequest,
  StayntouchRequest,
} from '../../../../../network/PostRequestModels/PropertyFromPMS'
import {Mews} from '../../../../add-property-pms/Forms/Mews'
const debounce = require('debounce')

type Props = {
  property: IHostPropertyInput | undefined
  propertyId: number
}

const Automation: React.FC<Props> = ({property, propertyId}) => {
  if (property?.isMarketplace) {
    property.cleaningProviderId = 4
  }

  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showSuccessPropertyDialog, showFailureDialog} = useDialog()
  const [propertyInfo, setPropertyInfo] = useState<IHostPropertyInput | undefined>(property)
  const [loading, setLoading] = useState(false)
  const [selectedICalUrls, setSelectedICalUrls] = useState<IHostPropertyICal[]>(
    propertyInfo && propertyInfo?.hostPropertyICals
      ? propertyInfo?.hostPropertyICals.length > 0
        ? propertyInfo?.hostPropertyICals
        : [{}]
      : [{}]
  )
  const [validTime, setValidTime] = useState<string | null>(null)
  const [icalErrorIndexes, setIcalErrorIndexes] = useState<number[]>([])
  const [deletedICals, setDeletedIcals] = useState<IHostPropertyICal[]>([])
  const [selectedICalUrlForDelete, setSelectedICalUrlForDelete] = useState<IHostPropertyICal>()
  const [showIcalDeleteModal, setShowIcalDeleteModal] = useState(false)
  const [pmsTypeSelectObj, setPmsTypeSelectObj] = useState<any>({})
  const [selectedPMSType, setSelectedPMSType] = useState(null)
  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyList, setPropertyList] = useState<any[]>([])

  const [pmsMapping, setPmsMapping] = useState<boolean>(false)
  const [changeRefLoading, setChangeRefLoading] = useState<boolean>(false)
  const [selectedPMS, setSelectedPMS] = useState<IPMSProperty | null>(null)

  const {data, isLoading, error} = useQuery(
    ['Get Property Detail', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId),
    {cacheTime: 400000, enabled: propertyId ? true : false}
  )

  const {
    data: pmsTypesData,
    isLoading: pmsTypeLoading,
    error: pmsTypeError,
  } = useQuery('Get PMS Types', () => ApiCalls.getPMSTypes(), {
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    data: pmsKeyData,
    isLoading: pmsKeyLoading,
    isFetching: pmsKeyFetching,
    error: pmsKeyError,
  } = useQuery(
    ['Get PMS Keys', user.userSection.hostId, selectedPMSType],
    () =>
      ApiCalls.getPMSKeysByHostId(user.userSection.hostId, selectedPMSType ? selectedPMSType : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: selectedPMSType ? true : false}
  )

  useEffect(() => {
    if (pmsTypesData) {
      let selectObj: any = {}
      pmsTypesData?.data?.data
        ?.filter((type: any) => type.isActive)
        .forEach((type: any) => {
          let value = type.id
          let key = type.name
          selectObj[key] = value
        })
      setPmsTypeSelectObj(selectObj)
    }
  }, [pmsTypesData])

  const {
    data: pmsData,
    isLoading: pmsLoading,
    error: pmsError,
    refetch: pmsDataFetch,
  } = useQuery(
    ['Get Pms information of the Property', propertyId],
    () => {
      switch (propertyInfo?.pmsTypeId) {
        case PMSTypeName.Guesty:
          //return ApiCalls.getPropertiesFromGuesty({ token: data?.data?.configJSON.token, hostId: user?.userSection?.hostId, pmsTypeId: data?.data?.pmsTypeId })
          return ApiCalls.getPropertiesFromGuesty({
            client_id: propertyInfo?.guestyConfig.client_id,
            client_secret: propertyInfo?.guestyConfig.client_secret,
            hostId: user?.userSection?.hostId,
            pmsTypeId: propertyInfo?.pmsTypeId,
          })
        case PMSTypeName.Hostaway:
          return ApiCalls.getPropertiesFromHostify({
            client_id: propertyInfo?.configJSON.client_id,
            client_secret: propertyInfo?.configJSON.client_secret,
            hostId: user?.userSection?.hostId,
            pmsTypeId: propertyInfo?.pmsTypeId,
          })
        case PMSTypeName.MyVr:
          return ApiCalls.getPropertiesFromMyVr({
            token: propertyInfo?.configJSON.token,
            hostId: user?.userSection?.hostId,
            pmsTypeId: propertyInfo?.pmsTypeId,
          })
        case PMSTypeName.Stayntouch:
        //
        default:
          break
      }
    },
    {
      cacheTime: 500000,
      enabled: (propertyInfo?.configJSON && pmsMapping) || selectedPMSType ? true : false,
    }
  )

  const changeCheckoutTime = (value: string) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAutomation: {...propertyInfo?.hostPropertyAutomation, checkOutTime: value},
    })
  }

  const changeCheckinTime = (value: string) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAutomation: {...propertyInfo?.hostPropertyAutomation, checkInTime: value},
    })
  }

  const handleChangeSameDayBooking = (value: boolean) => {
    setPropertyInfo({...propertyInfo, sameDayBooking: value})
  }

  const handleChangeAutomaticSchedule = (value: boolean) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAutomation: {
        ...propertyInfo?.hostPropertyAutomation,
        isAutomaticSchedule: value,
        isAutomaticCalendarSync: value,
      },
    })
  }

  const handleChangeUsePms = (value: boolean) => {
    setPropertyInfo({
      ...propertyInfo,
      hostPropertyAutomation: {
        ...propertyInfo?.hostPropertyAutomation,
        usePMS: value,
      },
    })
  }

  const handleChangeAutomaticPayment = (value: boolean) => {
    if (propertyInfo?.cleaningProviderId !== 4) {
      setPropertyInfo({
        ...propertyInfo,
        hostPropertyAutomation: {
          ...propertyInfo?.hostPropertyAutomation,
          isAutomaticPayment: value,
        },
      })
    }
  }

  const checkICalValid = (value: string, changeIndex: number) => {
    ApiCalls.validateICal(value)
      .then((res) => {
        if (res.data) {
          setIcalErrorIndexes(icalErrorIndexes.filter((index) => index !== changeIndex))
        } else {
          setIcalErrorIndexes([...icalErrorIndexes, changeIndex])
        }
      })
      .catch((err) => setIcalErrorIndexes([...icalErrorIndexes, changeIndex]))
  }
  const checkICalValidDebounce = debounce(checkICalValid, 600)

  const changeICalUrl = (value: string, changeIndex: number) => {
    setSelectedICalUrls(
      selectedICalUrls.map((iCal, index) =>
        index === changeIndex ? {...iCal, icalUrl: value} : iCal
      )
    )
    checkICalValidDebounce(value, changeIndex)
  }

  const addNewICalUrl = () => {
    setSelectedICalUrls([...selectedICalUrls, {icalUrl: ''}])
  }

  const editPmsReference = async () => {
    if (selectedPMS) {
      try {
        let reference = selectedPMS?.pmsReferenceId.toString()
        setChangeRefLoading(true)
        await ApiCalls.putPropertyPmsReference({pmsReferenceId: reference}, propertyId)
        setChangeRefLoading(false)
        setPmsMapping(false)
      } catch (error: any) {
        setChangeRefLoading(false)
      }
    }
  }

  const fetchPropertyList = async (data: any) => {
    setPropertyLoading(true)
    setPropertyList([])
    try {
      let totalProperty = 0
      let requestData: any = {}
      //  !!! this logic does not belong here move it  !!!
      if (selectedPMSType === pmsTypeSelectObj['Hostaway']) {
        const hostawayRequest: HostawayRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromHostify(hostawayRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = hostawayRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Stayntouch']) {
        const stayntouchRequest: StayntouchRequest = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromStayntouch(stayntouchRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = stayntouchRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Guesty']) {
        const guestyRequest: GuestyRequestV2 = {
          client_id: data.client_id,
          client_secret: data.client_secret,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromGuesty(guestyRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = guestyRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['MyVR']) {
        const myVrRequest: MyVrRequest = {
          token: data.token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMyVr(myVrRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = myVrRequest
      }
      if (selectedPMSType === pmsTypeSelectObj['Mews']) {
        const mewsRequest: MewsRequest = {
          client_token: data.client_token,
          access_token: data.access_token,
          hostId: user.userSection.hostId,
          pmsTypeId: selectedPMSType,
        }
        const {data: responseData} = await ApiCalls.getPropertiesFromMews(mewsRequest)
        setPropertyList(responseData?.data)
        totalProperty = responseData?.count
        requestData = mewsRequest
      }
      setPropertyLoading(false)
    } catch (err: any) {
      setPropertyLoading(false)
      setPropertyList([])
      showFailureDialog('Please check the required fields again.')
      return err?.response?.data?.message
    }
  }

  const deleteICalUrl = () => {
    var iCalx = selectedICalUrlForDelete
    const deletedList = deletedICals

    selectedICalUrls.map((iCal, index) => {
      if (iCal.id === iCalx?.id) {
        deletedList.push({...iCal, isDeleted: true})
      }
    })
    setDeletedIcals(deletedList)
    let newArray: any = selectedICalUrls.filter((iCal, index) => iCal.id !== iCalx?.id)
    setSelectedICalUrls(newArray.length > 0 ? newArray : [{icalUrl: ''}])
  }

  const changeSelectedPms = (data: IPMSProperty) => {
    setSelectedPMS(data)
  }

  const isPropertyValid = () => {
    if (
      !propertyInfo?.address ||
      !propertyInfo?.zipCode ||
      !propertyInfo?.latitude ||
      !propertyInfo?.longitude ||
      !propertyInfo?.country ||
      !propertyInfo?.state ||
      !propertyInfo?.city
    ) {
      showFailureDialog('Please fill Location information')
      return false
    }

    if (propertyInfo.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          propertyInfo?.hostPropertyAutomation?.checkInTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          propertyInfo?.hostPropertyAutomation?.checkInTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          propertyInfo?.hostPropertyAutomation?.checkOutTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          propertyInfo?.hostPropertyAutomation?.checkOutTime
            ? parseInt(propertyInfo.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (propertyInfo?.cleaningProviderId == 2 || propertyInfo?.cleaningProviderId == 3) {
      if (
        !propertyInfo?.hostPropertyAccess?.unitAccessTypeId ||
        !propertyInfo?.hostPropertyAccess?.unitAccessValue ||
        !propertyInfo?.hostPropertyAccess?.buildingAccessTypeId ||
        !propertyInfo?.hostPropertyAccess?.buildingAccessValue
      ) {
        showFailureDialog('Please fill access informations')
        return false
      }
    }

    if (
      propertyInfo.hasStorage &&
      (propertyInfo.hostPropertyStorages?.length === 0 ||
        !propertyInfo.hostPropertyStorages?.[0].storageDescription ||
        propertyInfo.hostPropertyStorages?.[0].storageDescription === '')
    ) {
      showFailureDialog('Please provide at least one storage description')
      return false
    }

    if (
      propertyInfo.hasParking &&
      (!propertyInfo.parkingDescription || propertyInfo.parkingDescription === '')
    ) {
      showFailureDialog('Please enter parking description.')
      return false
    }

    return true
  }

  const handleUpdateProperty = async () => {
    if (isPropertyValid() && propertyId) {
      setLoading(true)
      let name =
        (propertyInfo?.address ? propertyInfo?.address : '') +
        (propertyInfo?.suite ? '#' + propertyInfo?.suite : '')
      const requestData: IHostPropertyInput = {
        ...propertyInfo,
        name,
        hostId: user.userSection.hostId,
        cleaningProviderId:
          propertyInfo?.cleaningProviderId == 4 ? 2 : propertyInfo?.cleaningProviderId,
        isMarketplace: propertyInfo?.cleaningProviderId == 4 ? true : false,
        numberOfCleaner: 0,
        propertySize: propertyInfo?.propertySize != undefined ? +propertyInfo.propertySize : 0,
        parkingDescription: propertyInfo?.hasParking ? propertyInfo.parkingDescription : undefined,
        hasParking: propertyInfo?.hasParking !== undefined ? propertyInfo?.hasParking : false,
        hasDryer: propertyInfo?.hasLaundry
          ? propertyInfo?.hasDryer !== undefined
            ? propertyInfo?.hasDryer
            : false
          : false,
        hasLaundry: propertyInfo?.hasLaundry !== undefined ? propertyInfo?.hasLaundry : false,
        hasStorage: propertyInfo?.hasStorage !== undefined ? propertyInfo?.hasStorage : false,
        sameDayBooking:
          propertyInfo?.sameDayBooking !== undefined ? propertyInfo?.sameDayBooking : false,
        numberOfBedrooms: propertyInfo?.numberOfBedrooms ? propertyInfo?.numberOfBedrooms : 0,
        numberOfBathrooms: propertyInfo?.numberOfBathrooms ? propertyInfo?.numberOfBathrooms : 0,
        numberOfBeds: propertyInfo?.numberOfBeds ? propertyInfo?.numberOfBeds : 0,
        hostPropertyStorages: propertyInfo?.hasStorage
          ? propertyInfo?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          propertyInfo?.hostPropertyAccess &&
          Object.keys(propertyInfo?.hostPropertyAccess).length > 0
            ? propertyInfo.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...propertyInfo?.hostPropertyAutomation,
          isAutomaticSchedule:
            propertyInfo?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? propertyInfo?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //propertyInfo?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? propertyInfo?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS:
            propertyInfo?.hostPropertyAutomation?.usePMS !== undefined
              ? propertyInfo?.hostPropertyAutomation?.usePMS
              : false,
          checkInTime: propertyInfo?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: propertyInfo?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !propertyInfo?.hostPropertyAutomation?.usePMS ? selectedICalUrls : [],
      }
      ApiCalls.updateHostProperty(propertyId, requestData)
        .then((res) => {
          setLoading(false)
          showSuccessDialog('Property informations successfully updated.')
        })
        .catch((err) => {
          setLoading(false)
          if (
            err?.response?.data?.errors?.[0]?.message == 'icalUrl must be an URL address' ||
            err?.response?.data?.message == 'icalUrl must be an URL address'
          ) {
            showFailureDialog(
              'Error: Invalid iCal URL.',
              'Please enter a valid URL for the iCal feed. This should be a direct link to your calendar, ending with the file extension ".ics". Make sure there are no typos or extra characters in the URL.'
            )
          } else {
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          }
        })
    } else {
      showFailureDialog('Please fill all necessary fields')
    }
  }

  return (
    <div className='card'>
      <div className='card-body p-9'>
        <form className='form'>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Check-in / Check-out</div>
              <div>
                <small className='text-muted'>
                  Assignments will be made based on the times you have chosen while creating the
                  cleaning schedule for your property.
                </small>
              </div>
            </div>
            <div className='col-xl-9'>
              <label className='form-label fs-6 fw-semibold mt-2 mb-3 col-12'>
                Please select the check-in / check-out times of your property.
              </label>
              <div className='row'>
                <div className='col-xl-6 mb-3'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                    Check-out Time {<span style={{color: 'red'}}>*</span>}
                  </label>
                  <TimePicker
                    onChange={changeCheckoutTime}
                    defaultValue={propertyInfo?.hostPropertyAutomation?.checkOutTime ?? '11:00'}
                  />
                </div>
                <div className='col-xl-6 mb-3'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                    Check-in Time {<span style={{color: 'red'}}>*</span>}
                  </label>
                  <TimePicker
                    onChange={changeCheckinTime}
                    defaultValue={propertyInfo?.hostPropertyAutomation?.checkOutTime ?? '11:00'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Same Day Booking</div>
              <div>
                <small className='text-muted'>
                  When enabled, Check-in Time cannot be earlier then Check-out Time.
                </small>
              </div>
            </div>
            <div className='col-xl-9'>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e) => {
                    handleChangeSameDayBooking(e.target.checked)
                  }}
                  defaultChecked={propertyInfo?.sameDayBooking}
                />
              </div>
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Automatic Payment</div>
              <div>
                <small className='text-muted'>
                  With automatic payment, we pay the cleaner's account after the cleaning job is
                  done.
                </small>
              </div>
            </div>
            <div className='col-xl-9'>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e) => {
                    handleChangeAutomaticPayment(e.target.checked)
                  }}
                  disabled={propertyInfo?.cleaningProviderId == 4}
                  defaultChecked={
                    propertyInfo?.hostPropertyAutomation &&
                    propertyInfo?.hostPropertyAutomation?.isAutomaticPayment
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Automatic Scheduling</div>
              <div>
                <small className='text-muted'>
                  When enabled, cleaners will be automatically sent cleaning requests for all of
                  your checkouts.
                </small>
              </div>
            </div>
            <div className='col-xl-9'>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e) => {
                    handleChangeAutomaticSchedule(e.target.checked)
                  }}
                  defaultChecked={
                    propertyInfo?.hostPropertyAutomation &&
                    propertyInfo?.hostPropertyAutomation?.isAutomaticSchedule
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
          {propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync && (
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Use PMS</div>
                <div>
                  <small className='text-muted'>
                    When enabled, the scheduling of your property will be provided through the PMS
                    provider you will define. If not enabled, the calendaring will be provided
                    through iCal.
                  </small>
                </div>
              </div>
              <div className='col-xl-9'>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      handleChangeUsePms(e.target.checked)
                    }}
                    defaultChecked={propertyInfo?.hostPropertyAutomation?.usePMS}
                  />
                </div>
              </div>
            </div>
          )}
          {!propertyInfo?.hostPropertyAutomation?.usePMS &&
            propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync && (
              <div>
                {selectedICalUrls.map((iCal, index, arr) => {
                  if (!iCal.isDeleted) {
                    return (
                      <>
                        <div className='row'>
                          <strong className='col-12 mb-3 mt-3'>
                            ICal URL #{index + 1} {<span style={{color: 'red'}}>*</span>}
                          </strong>
                          <div className='col-12 mb-3'>
                            <div className='row'>
                              <div className='col-11'>
                                <input
                                  value={iCal.icalUrl}
                                  defaultValue={iCal.icalUrl}
                                  onChange={(e: any) => {
                                    changeICalUrl(e.target.value, index)
                                  }}
                                  className={'form-control '}
                                  type={'text'}
                                  autoComplete='off'
                                  required
                                />
                                {(iCal.icalUrl === '' || !iCal.icalUrl) && (
                                  <small className='text-danger'>
                                    Missing Field
                                    <br />
                                  </small>
                                )}
                                {icalErrorIndexes.includes(index) && (
                                  <small className='text-danger'>ICal is not valid!</small>
                                )}
                              </div>
                              <DeleteButton
                                deleteFn={() => {
                                  setSelectedICalUrlForDelete(iCal)
                                  setShowIcalDeleteModal(true)
                                }}
                                classNames='col-1 btn btn-link text-danger'
                              />
                            </div>
                          </div>
                          {index === arr.length - 1 ? (
                            <Button
                              style={{maxWidth: 1030}}
                              disabled={iCal.icalUrl === '' || !iCal.icalUrl}
                              type='button'
                              className='btn-success col-12 btn-sm m-2'
                              onClick={() => addNewICalUrl()}
                            >
                              Add new ICal
                            </Button>
                          ) : null}
                        </div>
                      </>
                    )
                  }
                })}
              </div>
            )}
          {propertyInfo?.hostPropertyAutomation?.usePMS &&
            propertyInfo?.hostPropertyAutomation?.isAutomaticCalendarSync && (
              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>PMS Mapping</div>
                  <div>
                    <small className='text-danger'>
                      By pressing the <b>"Start PMS Mapping"</b> button, you can start the mapping
                      of your property with your PMS provider.{' '}
                      <b>If you have already mapped your property, you can do it again.</b>
                    </small>
                  </div>
                </div>
                <div className='col-xl-9'>
                  <div>
                    <Button
                      onClick={() => {
                        setPmsMapping((prevState) => !prevState)
                      }}
                      className='btn-sm col-12 my-5'
                    >
                      {pmsMapping && pmsLoading ? 'Loading...' : 'Start PMS Mapping'}
                    </Button>
                    {pmsMapping && pmsLoading && (
                      <small className='text-danger p-0'>
                        These operations will take some time to complete, please wait.
                      </small>
                    )}
                  </div>
                  <div className='col-lg-12 p-5'>
                    {pmsMapping && (
                      <>
                        {propertyInfo?.pmsReferenceId && (
                          <>
                            <div className='col-6 row mt-5'>
                              <div className='col-6'>
                                <input
                                  disabled
                                  className='form-control'
                                  value={propertyInfo?.pmsReferenceId}
                                />
                              </div>
                            </div>
                            {pmsLoading ? (
                              <SkeletonTableLoading />
                            ) : (
                              <>
                                <PropertyTable
                                  selectType='single'
                                  data={pmsData?.data?.data}
                                  onSelectionChanged={(arg) =>
                                    changeSelectedPms(arg?.selectedRowsData?.[0])
                                  }
                                />
                                <div className='d-flex w-100 justify-content-end'>
                                  <Button
                                    disabled={changeRefLoading}
                                    onClick={editPmsReference}
                                    className='btn-sm'
                                  >
                                    Reference Transfer{' '}
                                    {changeRefLoading && <Spinner animation='border' size='sm' />}
                                  </Button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {!propertyInfo?.pmsReferenceId && (
                          <>
                            <div className='col-6 row'>
                              <div className='col-6'>
                                <SelectBox
                                  searchBoxObj={pmsTypeSelectObj}
                                  onValueChanged={setSelectedPMSType}
                                  disabledText='Select a PMS Type'
                                />
                                {selectedPMSType === pmsTypeSelectObj['Hostaway'] ? (
                                  pmsKeyFetching ? (
                                    <div>Loading...</div>
                                  ) : (
                                    <HostawayForm
                                      keys={pmsKeyData?.data?.data[0]}
                                      fetchPropertyList={fetchPropertyList}
                                      propertyLoading={propertyLoading}
                                    />
                                  )
                                ) : null}
                                {selectedPMSType === pmsTypeSelectObj['Guesty'] ? (
                                  pmsKeyFetching ? (
                                    <div>Loading...</div>
                                  ) : (
                                    <GuestyForm
                                      keys={pmsKeyData?.data?.data[0]}
                                      fetchPropertyList={fetchPropertyList}
                                      propertyLoading={propertyLoading}
                                    />
                                  )
                                ) : null}
                                {selectedPMSType === pmsTypeSelectObj['MyVR'] ? (
                                  pmsKeyFetching ? (
                                    <div>Loading...</div>
                                  ) : (
                                    <MyVrForm
                                      keys={pmsKeyData?.data?.data[0]}
                                      fetchPropertyList={fetchPropertyList}
                                      propertyLoading={propertyLoading}
                                    />
                                  )
                                ) : null}
                                {selectedPMSType === pmsTypeSelectObj['Stayntouch'] ? (
                                  pmsKeyFetching ? (
                                    <div>Loading...</div>
                                  ) : (
                                    <StayntouchForm
                                      keys={pmsKeyData?.data?.data[0]}
                                      fetchPropertyList={fetchPropertyList}
                                      propertyLoading={propertyLoading}
                                    />
                                  )
                                ) : null}
                                {selectedPMSType === pmsTypeSelectObj['Mews'] ? (
                                  pmsKeyFetching ? (
                                    <div>Loading...</div>
                                  ) : (
                                    <Mews
                                      keys={pmsKeyData?.data?.data[0]}
                                      fetchPropertyList={fetchPropertyList}
                                      propertyLoading={propertyLoading}
                                    />
                                  )
                                ) : null}
                              </div>
                            </div>
                            {propertyLoading ? (
                              <SkeletonTableLoading />
                            ) : (
                              <>
                                <PropertyTable
                                  selectType='single'
                                  data={propertyList}
                                  onSelectionChanged={(arg) =>
                                    changeSelectedPms(arg?.selectedRowsData?.[0])
                                  }
                                  onlyList
                                />
                                {propertyList.length > 0 && (
                                  <div className='d-flex w-100 justify-content-end'>
                                    <Button
                                      disabled={changeRefLoading}
                                      onClick={editPmsReference}
                                      className='btn-sm'
                                    >
                                      Reference Transfer{' '}
                                      {changeRefLoading && <Spinner animation='border' size='sm' />}
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
        </form>
      </div>
      <div className='card-footer d-flex justify-content-end'>
        <button className='btn btn-success' disabled={loading} onClick={handleUpdateProperty}>
          Save Changes {loading ? <Spinner size='sm' animation='border' /> : null}
        </button>
      </div>
    </div>
  )
}

export default Automation
