import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const TurnifyLoading: React.FC = () => {
    return (
        <div id="splash-screen" className="splash-screen">
            <img style={{ width: 250 }} src={toAbsoluteUrl("/media/logos/turnify-logo.png")} alt="Turnify logo" />
            <span>Loading...</span>
        </div>
    )
}

export default TurnifyLoading;