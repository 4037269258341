import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../../../redux/actionTypes/masterChecklistTypes'
import * as checklistActionCreators from '../../../../../redux/actionCreators/masterChecklistActionCreators'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import QuestionAddForm from '../../../../../umut-components/Forms/MasterChecklistForms/QuestionAddForm'

type Props = {checklistId: string}

const ChecklistSectionQuestionAddDialog: React.FC<Props> = ({checklistId}) => {
  const {questionAddDialog} = useSelector((state: RedusxAppState) => state.masterChecklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const closeModal = () => {
    dispatch(checklistActionCreators.closeQuestionAddDialog())
  }

  return (
    <Modal show={questionAddDialog.dialogOpen} size='lg' onHide={closeModal} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Checklist Item</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <QuestionAddForm checklistId={checklistId} />
      </Modal.Body>
    </Modal>
  )
}

export default ChecklistSectionQuestionAddDialog
