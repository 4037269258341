import ApiCalls from '../../network/ApiCalls'

import React from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { useSelector } from 'react-redux'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { PageTitle } from '../../../_metronic/layout/core'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import { Card } from 'react-bootstrap-v5'
import ForceFinishRequestTable from './forceFinishTable'
import { IForceFinishResponse } from '../../network/NetworkResponses/NetworkResponses'

const ForceFinishRequest = () =>{
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const {
        data,
        isLoading,
        error,
        refetch:refetchReferences,
    } = useQuery<AxiosResponse<IForceFinishResponse>>(['Get Force Finish Request'], () => ApiCalls.getForceFinishRequest(user?.userSection?.hostId),{cacheTime:50000})

    return(
        <>
       <PageTitle>Force Finish Request</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <ForceFinishRequestTable refetch={refetchReferences} data={data?.data.data} />
            )}
          </Card.Body>
       
        </Card>
      </div>
        </>
    )
}
export default ForceFinishRequest

