/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, useState } from 'react'
import { GuestyRequest, HostawayRequest, MyVrRequest } from '../../../network/PostRequestModels/PropertyFromPMS';

type Props = {
    fetchPropertyList: (data: HostawayRequest | GuestyRequest | MyVrRequest) => Promise<string | void>,
    propertyLoading: boolean,
    keys?: any
}

export const HostawayForm: React.FC<Props> = ({ fetchPropertyList, propertyLoading, keys }) => {
    const [clientId, setClientId] = useState<string | undefined>(keys?.configJSON?.client_id ?? undefined);
    const [clientSecret, setClientSecret] = useState<string | undefined>(keys?.configJSON?.client_secret ?? undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const getProperties = async () => {
        if (clientId && clientSecret) {
            setErrorMessage(null);
            const hostawayRequest: HostawayRequest = {
                client_id: clientId,
                client_secret: clientSecret
            }
            fetchPropertyList(hostawayRequest);
        }
        else {
            setErrorMessage('All fields are required');
        }
    }

    return (
        <form
            className='form w-100 mt-5'
            noValidate
            id='kt_hostaway_form'
        >
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Hostaway Client ID</label>
                <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    autoComplete='off'
                    value={clientId}
                    onChange={(e: any) => setClientId(e.target.value)}
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Hostaway Client Secret</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    value={clientSecret}
                    onChange={(e: any) => setClientSecret(e.target.value)}
                    type='password'
                    autoComplete='off'
                    className='form-control form-control-lg form-control-solid'
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>
            </div>
            {/* end::Form group */}

            {/* begin:: ErrorText */}
            <strong style={{ color: 'red' }}>{ }</strong>
            {/* end:: ErrorText */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='button'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    onClick={getProperties}
                    disabled={propertyLoading}
                >
                    {!propertyLoading && <span className='indicator-label'>Get Hostaway Properties</span>}
                    {propertyLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}