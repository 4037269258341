export enum AccountStatusTypes {
    ACTIVE = 1,
    INACTIVE = 2,
    EMAIL_NOT_VERIFIED = 4,
    DOCUMENT_PENDING = 5,
    EMAIL_VERIFIED = 6,
    REJECTED = 8
}
export const AccountStatusObj = {
    'Active': 1,
    'Inactive': 2,
    'EmailNotVerified': 4,
    'DocumentPending': 5,
    'EmailVerified': 6,
    'Rejected': 8,
}