import React, {useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {
  encryptText,
  toConvertedDate,
  onExporting,
  getDateFilter,
  setDxFilterPlaceholder,
} from '../../../../utils/util'
import {IJob} from '../../../../interfaces/Job'
import {JobStatusType} from '../../../../enums/JobEnum'
import ViewButton from '../../../../umut-components/Buttons/ViewButton'
import {useHistory} from 'react-router'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import config from '../../../../config/config.json'

type Props = {
  data: IJob[]
}

const JobTable: React.FC<Props> = ({data}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const displayEditButton = (cellData: any) => {
    return (
      <ViewButton
        iconWidth='20'
        iconHeight='20'
        viewFn={() => {
          history.push('/job-details/' + encryptText(cellData?.data?.id))
        }}
      />
    )
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.id) {
      history.push('/job-details/' + encryptText(cellData?.data?.id))
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Jobs' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='jobTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(50vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toJobDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Jobs')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column
          dataField='scheduledStart'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          cellRender={displayStatusType}
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='scheduledFinish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>
      </DataGrid>
    </div>
  )
}

export default JobTable
