import { put, call, all, fork, takeLatest, delay, takeEvery, select } from "redux-saga/effects";
import ApiCalls from "../../network/ApiCalls";
import { GetStafflistsResponse, GetStaffDetailResponse } from "../../network/NetworkResponses/NetworkResponses";

import * as staffActionCreators from "../actionCreators/staffActionCreators";
import * as staffActionTypes from "../actionTypes/staffActionTypes";

function* onGetStafflist({ hostId, roleId }: staffActionTypes.GetStaffList) {
    try {
        const result: GetStafflistsResponse = yield call(ApiCalls.getStaff, hostId, roleId);
        yield put(staffActionCreators.getStaffListSuccess(result.data.data));
    }
    catch (error: any) {
        yield put(staffActionCreators.getStaffListFailure(error?.response?.data?.message));
    }
}

function* watchOnGetStafflist() {
    yield takeLatest(staffActionTypes.GET_STAFF_LIST, onGetStafflist);
}

function* onGetStaffDetail({ userId }: staffActionTypes.GetStaffDetail) {
    try {
        const result: GetStaffDetailResponse = yield call(ApiCalls.getStaffDetails, userId);
        yield put(staffActionCreators.getStaffDetailSuccess(result.data));
    }
    catch (error: any) {
        yield put(staffActionCreators.getStaffDetailFailure(error?.response?.data?.message));
    }
}

function* watchOnGetStaffDetail() {
    yield takeLatest(staffActionTypes.GET_STAFF_DETAIL, onGetStaffDetail);
}

export default function* staffSaga() {
    yield all([fork(watchOnGetStafflist), fork(watchOnGetStaffDetail)]);
}