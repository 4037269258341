import {Dispatch, FC, useEffect, useState} from 'react'
import {map} from 'rxjs/operators'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {StaffAction} from '../../../../redux/actionTypes/staffActionTypes'
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import StaffOfferedForm from '../../../../umut-components/Forms/StaffOfferedForm'
import StaffPropertyForm from '../../../../umut-components/Forms/StaffPropertyForm'
import {IStaff} from '../../../../interfaces/Staff'
import HostStaffPropertyForm from '../../../../umut-components/Forms/HostStaffPropertyForm'

type Props = {staff: IStaff}
const AddPropertyDialog: FC<Props> = ({staff}) => {
  const {staffPropertyDialog, staffDetail} = useSelector((state: RedusxAppState) => state.staff)
  const dispatch = useDispatch<Dispatch<StaffAction>>()

  const closeModal = () => {
    staffPropertyDialog.refetch()
    dispatch(staffActionCreator.closeStaffPropertyDialog())
  }

  return (
    <Modal show={staffPropertyDialog.modalOpen} onHide={closeModal} size='xl' centered>
      <ModalHeader closeButton>
        <Modal.Title>
          {staffPropertyDialog.formType === 'allowed' ? 'Authorize' : 'Unauthorize '}{' '}
          {staff?.firstName + ' ' + staff?.lastName.substring(0, 1) + '.'} to work at the following
          properties:
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {staffPropertyDialog.modalOpen ? <HostStaffPropertyForm staff={staff} /> : null}
      </Modal.Body>
    </Modal>
  )
}

export default AddPropertyDialog
