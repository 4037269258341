import {CometChat} from '@cometchat-pro/chat'

const appID = '227187fc335d9a40'
const authKey = 'dc8f171410d6cb35f3df34a08902ad8316eba2d0'
const apiKey = 'f2933a6200901babaaa9fe75768b89abb051a892'
const region = 'eu'
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build()

export default function chatInit(userInfo) {
  CometChat.init(appID, appSetting).then(
    () => {
      if (CometChat.setSource) {
        CometChat.setSource('ui-kit', 'web', 'reactjs')
      }

      var uid = userInfo.userSectionType + '-' + userInfo.user.id
      var userName = userInfo.user.firstName + ' ' + userInfo.user.lastName

      CometChat.login(uid, authKey).then(
        (user) => {
          return 'SUCCESS'
        },
        (err2) => {
          if (err2.code == 'ERR_UID_NOT_FOUND') {
            var newUser = new CometChat.User(uid)
            newUser.setName(userName)
            CometChat.createUser(newUser, authKey).then(
              (user2) => {
                CometChat.login(uid, authKey).then(
                  (loggedInUser) => {
                    return 'SUCCESS'
                  },
                  (err4) => {
                    return err4.code
                  }
                )
              },
              (err3) => {
                return err3.code
              }
            )
          } else {
            return err2.code
          }
        }
      )
    },
    (err1) => {
      return err1.code
    }
  )
}
