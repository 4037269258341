import React, {useEffect, useState} from 'react'
import {IStaff} from '../../../../../interfaces/Staff'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import {encryptText} from '../../../../../utils/util'
import {KTSVG} from '../../../../../../_metronic/helpers'
import EditServiceRateDialog from './EditServiceRateDialog'

type Props = {staff: IStaff}

const Services: React.FC<Props> = ({staff}) => {
  const [services, setServices] = useState<any[]>([])
  const [selectedDropIndx, setSelectedDropIndx] = useState<any>()
  const [selectedService, setSelectedService] = useState<any>()
  const [showEditService, setShowEditService] = useState<boolean>(false)

  const {
    data: staffServices,
    isLoading: staffServicesLoading,
    error: staffServicesError,
    refetch: staffServicesRefetch,
  } = useQuery(
    ['Get staff services with rates', staff?.userId],
    () => ApiCalls.getStaffServicesWithRates(staff?.userId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: true,
      enabled: staff?.userId ? true : false,
    }
  )

  const openPropertyDetail = (hostPropertyId: number) => {
    window.open('/properties/detail/' + encryptText(hostPropertyId), '_blank')
  }

  useEffect(() => {
    if (!staffServicesLoading) {
      if (staffServices?.data != undefined && staffServices?.data?.data.length > 0) {
        setServices(staffServices?.data?.data)
      }
    }
  }, [staffServices, staffServicesLoading])

  const editStaffService = (service: any) => {
    setSelectedService(service)
    setShowEditService(true)
  }

  if (staffServicesLoading) {
    return <SkeletonTableLoading />
  }

  if (!staffServicesLoading && services.length == 0) {
    return (
      <div>
        <div className='ms-4 text-center'>
          <div className='fs-1 fw-bold text-gray-900 text-hover-primary mb-2'>Not Quotes Found</div>
          <div className='fw-semibold fs-3 text-muted'>
            Staff has no service offer defined for your properties.
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className='table-responsive pb-10 px-1'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 no-footer'>
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              <th className='min-w-200px'>Property</th>
              <th className='text-end min-w-100px'>#of Bedrooms</th>
              <th className='text-end min-w-100px'>#of Bathrooms</th>
              <th className='text-end min-w-100px'>Service Type</th>
              <th className='text-end min-w-100px'>Est. Duration</th>
              <th className='text-end min-w-100px'>Rate ($)</th>
              <th className='text-end'></th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {services.map((service, index) => {
              return (
                <tr className={index % 2 == 0 ? 'odd' : 'even'} key={'offer-row-' + index}>
                  <td className='text-start ps-1'>
                    <div
                      className='text-hover-primary cursor-pointer'
                      onClick={() => {
                        openPropertyDetail(service.hostPropertyId)
                      }}
                    >
                      {service.hostPropertyName}
                    </div>
                  </td>
                  <td className='text-end pe-0'>{service.numberOfBedrooms}</td>
                  <td className='text-end pe-0'>{service.numberOfBathrooms}</td>
                  <td className='text-end pe-0'>{service.serviceTypeName}</td>
                  <td className='text-end pe-0'>{service.duration + ' min.'}</td>
                  <td className='text-end pe-0'>{'$' + service.servicePrice}</td>
                  <td className='text-end position-relative'>
                    {service.staffType == 'myteam' && (
                      <>
                        <button
                          className='btn btn-sm btn-icon btn-bg-light btn-color-primary dropmenu'
                          onClick={() => {
                            if (selectedDropIndx == 'staff-service-action-' + service.offerId) {
                              setSelectedDropIndx(undefined)
                            } else {
                              setSelectedDropIndx('staff-service-action-' + service.offerId)
                            }
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/General/Other2.svg'
                            className='svg-icon-3 svg-icon-secondary dropmenu'
                            svgClassName='dropmenu mh-50px'
                          />
                        </button>
                        <div
                          className={
                            selectedDropIndx == 'staff-service-action-' + service.offerId
                              ? 'menu menu-sub menu-sub-dropdown align-items-end w-100px py-1 show'
                              : 'menu menu-sub menu-sub-dropdown align-items-end w-100px py-1'
                          }
                          style={{top: 60, position: 'absolute', right: 0}}
                        >
                          <div className='menu-item px-3'>
                            <div
                              className='menu-link px-3 text-primary'
                              onClick={() => {
                                editStaffService(service)
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {showEditService && (
        <EditServiceRateDialog
          service={selectedService}
          staff={staff}
          handleClose={() => {
            setShowEditService(false)
          }}
          refetch={staffServicesRefetch}
        />
      )}
    </>
  )
}

export default Services
