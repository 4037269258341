import React, {useState} from 'react'
import {IMarketplaceOfferedRate} from '../../../../interfaces/Marketplace'
import StarRatingView from '../../../Rating/StarRatingView'
import dayjs from 'dayjs'
import OfferDetailDialog from './OfferDetailDialog'

type Props = {
  offers: IMarketplaceOfferedRate[]
  refetch: () => void
}

const MarketplaceServiceOffers: React.FC<Props> = ({offers, refetch}) => {
  const [selectedOffer, setSelectedOffer] = useState<IMarketplaceOfferedRate | undefined | null>()
  const [showDetailDialog, setShowDetailDialog] = useState(false)

  const getOfferStatusColor = (statusId: number) => {
    if (statusId === 3) {
      return 'badge-light-success'
    } else if (statusId === 4) {
      return 'badge-light-danger'
    } else if (statusId === 2) {
      return 'badge-light-warning'
    } else {
      return 'badge-light-primary'
    }
  }

  const getVendorName = (text: string) => {
    var nameList = text.split(' ')
    var newName = ''
    for (const item of nameList) {
      newName += item.charAt(0).toUpperCase() + '**** '
    }
    return newName
  }

  const getOfferStatusName = (statusId: number) => {
    if (statusId === 3) {
      return 'Accepted'
    } else if (statusId === 4) {
      return 'Declined'
    } else if (statusId === 2) {
      return 'Pending'
    } else {
      return 'Provider Pending'
    }
  }

  const offerStatusAction = (offer: any) => {
    setSelectedOffer(offer)
    setShowDetailDialog(true)
  }

  const handleCloseDetailDialog = () => {
    setSelectedOffer(null)
    setShowDetailDialog(false)
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='w-10px pe-2 sorting_disabled'>#</th>
                  <th className='min-w-200px sorting'>Service Provider</th>
                  <th className='text-end min-w-100px sorting'>Rating</th>
                  <th className='text-end min-w-100px sorting'>Date</th>
                  <th className='text-end min-w-100px sorting'>Rate</th>
                  <th className='text-end min-w-100px sorting'>#of Staffs</th>
                  <th className='text-end min-w-100px sorting'>Status</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {offers.map((offer, index) => {
                  if (offer.offerStatusId > 1) {
                    return (
                      <tr
                        className={index % 2 == 0 ? 'odd cursor-pointer' : 'even cursor-pointer'}
                        key={'offer-row-' + index}
                        onClick={() => {
                          offerStatusAction(offer)
                        }}
                      >
                        <td>#{index}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px'>
                              <span className='symbol-label'></span>
                            </div>

                            <div className='ms-5'>
                              <div
                                className='text-gray-800 text-hover-primary fs-5 fw-bold'
                                data-kt-ecommerce-product-filter='product_name'
                              >
                                {getVendorName(offer.vendorName)}
                              </div>
                              <span className='text-gray-600 fs-8'>
                                {offer.checklistId > 0 ? offer.checklistName : 'Any Checklist'}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-end pe-0' data-order='rating-3'>
                          <div className='rating justify-content-end'>
                            <StarRatingView rate={offer.vendorRate} />
                          </div>
                        </td>
                        <td className='text-end pe-0'>
                          <span className='fw-bold'>
                            {offer.date ? dayjs(offer.date).format('MM/DD/YYYY') : '-'}
                          </span>
                        </td>
                        <td className='text-end pe-0'>${offer.offerPrice}</td>
                        <td className='text-end pe-0'>{offer.totalStaff}</td>
                        <td className='text-end pe-0'>
                          <div className={'badge ' + getOfferStatusColor(offer.offerStatusId)}>
                            {getOfferStatusName(offer.offerStatusId)}
                          </div>
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showDetailDialog && (
        <OfferDetailDialog
          offer={selectedOffer}
          handleClose={handleCloseDetailDialog}
          refetch={refetch}
        />
      )}
    </div>
  )
}

export default MarketplaceServiceOffers
