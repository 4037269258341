import { IOfferedService, IStaff, IStaffDetail } from '../../interfaces/Staff';
import * as actions from '../actionTypes/staffActionTypes';

export function getStaffList(hostId: number, roleId?: number): actions.GetStaffList {
    return {
        type: actions.GET_STAFF_LIST,
        hostId,
        roleId
    }
}

export function getStaffListSuccess(staffList: IStaff[]): actions.GetStaffListSuccess {
    return {
        type: actions.GET_STAFF_LIST_SUCCESS,
        staffList
    }
}

export function getStaffListFailure(errorText: string): actions.GetStaffListFailure {
    return {
        type: actions.GET_STAFF_LIST_FAILURE,
        errorText
    }
}

export function getStaffDetail(userId: number): actions.GetStaffDetail {
    return {
        type: actions.GET_STAFF_DETAIL,
        userId
    }
}

export function getStaffDetailSuccess(staff: IStaffDetail): actions.GetStaffDetailSuccess {
    return {
        type: actions.GET_STAFF_DETAIL_SUCCESS,
        staff
    }
}

export function getStaffDetailFailure(errorText: string): actions.GetStaffDetailFailure {
    return {
        type: actions.GET_STAFF_DETAIL_FAILURE,
        errorText
    }
}

export function openStaffAddDialog(staff: IStaff | null): actions.OpenStaffAddDialog {
    return {
        type: actions.OPEN_STAFF_ADD_DIALOG,
        staff
    }
}

export function closeStaffAddDialog(): actions.CloseStaffAddDialog {
    return {
        type: actions.CLOSE_STAFF_ADD_DIALOG,
    }
}

export function openStaffOfferedAddDialog(refetch: () => void, staffOfferedService?: IOfferedService): actions.OpenStaffOfferedAddDialog {
    return {
        type: actions.OPEN_STAFF_OFFERED_ADD_DIALOG,
        refetch,
        staffOfferedService
    }
}

export function closeStaffOfferedAddDialog(): actions.CloseStaffOfferedAddDialog {
    return {
        type: actions.CLOSE_STAFF_OFFERED_ADD_DIALOG,
    }
}

export function openStaffPropertyDialog(refetch: () => void, formType: "allowed" | "banned"): actions.OpenStaffPropertyDialog {
    return {
        type: actions.OPEN_STAFF_PROPERTY_DIALOG,
        refetch,
        formType
    }
}

export function closeStaffPropertyDialog(): actions.CloseStaffPropertyDialog {
    return {
        type: actions.CLOSE_STAFF_PROPERTY_DIALOG,
    }
}
export function openStaffPasswordDialog(staffId: any): actions.OpenStaffPasswordDialog {
    return {
        type: actions.OPEN_STAFF_PASSWORD_DIALOG,
        staffId
    }
}
export function closeStaffPasswordDialog(): actions.CloseStaffPasswordDialog {
    return {
        type: actions.CLOSE_STAFF_PASSWORD_DIALOG
    }
}
