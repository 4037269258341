import { Dispatch, FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IService } from '../../interfaces/ServiceType';
import ApiCalls from '../../network/ApiCalls';
import { StaffOfferedRequest } from '../../network/PostRequestModels';
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { decryptText } from '../../utils/util';
import { NumberInput } from '../Inputs/NumberInput';
import SelectInput from '../Inputs/SelectInput';

type Props = {
}
const StaffOfferedForm: FC<Props> = ({ }) => {
    let { staffId }: any = useParams();
    staffId = decryptText(staffId);
    const { staffOfferedDialog } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [types, setTypes] = useState([])
    const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null);
    const [unitSectionActive, setUnitSectionActive] = useState<boolean>(true);

    const { data: serviceTypes, isLoading, error } = useQuery('Offered Services Types', () => ApiCalls.getServiceTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const [offeredRequest, setOfferedRequest] = useState<StaffOfferedRequest>({
        unitSize: 0,
        servicePrice: staffOfferedDialog.staffOfferedService?.servicePrice,
        duration: staffOfferedDialog.staffOfferedService?.duration,
        // serviceTypeId: staffOfferedDialog.staffOfferedService?.serviceTypeId
        serviceTypeId: staffOfferedDialog.staffOfferedService?.serviceTypeId ? staffOfferedDialog.staffOfferedService?.serviceTypeId : 1,
    } as StaffOfferedRequest);
    const handleSubmit = async () => {
        if (offeredRequest.duration && offeredRequest.serviceTypeId) {
            try {
                setLoading(true);
                !staffOfferedDialog.staffOfferedService && await ApiCalls.addStaffOfferedService({ ...offeredRequest, hostUserId: parseInt(staffId) });
                staffOfferedDialog.staffOfferedService && await ApiCalls.updateStaffOfferedService(staffOfferedDialog.staffOfferedService.id, { ...offeredRequest, hostUserId: parseInt(staffId) });
                setLoading(false)
                staffOfferedDialog.refetch();
                dispatch(staffActionCreator.closeStaffOfferedAddDialog());
            } catch (error: any) {
                setLoading(false)
                setErrorMessage(error?.response?.data.message !== "Validation Failed" ? error?.response?.data.message : error?.response?.data?.errors?.[0]?.message)
            }
        }
        else {
            setErrorMessage('All fields required.');
        }
    }

    useEffect(() => {
        if (serviceTypes) {
            setTypes(serviceTypes?.data?.data.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [serviceTypes])

    useEffect(() => {
        if (offeredRequest.serviceTypeId && serviceTypes?.data?.data) {
            const selectedService: IService = serviceTypes?.data?.data?.find((service: IService) => service.id === offeredRequest.serviceTypeId)
            setSelectedServiceDescription(selectedService.description)
        }
    }, [offeredRequest.serviceTypeId, serviceTypes])


    return (
        <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12 w-100'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-6 '>Service Type {<span style={{ color: "red" }}>*</span>}</label>
                    <SelectInput disabled={!!staffOfferedDialog?.staffOfferedService?.serviceTypeId} defaultValue={offeredRequest.serviceTypeId} options={types} onValueChanged={(value) => { setOfferedRequest({ ...offeredRequest, serviceTypeId: value }) }} />
                    {selectedServiceDescription && <span className='text-muted m-1 '>{selectedServiceDescription}</span>}
                    <div></div>
                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Price (Optional) </label>
                    <NumberInput defaultValue={offeredRequest.servicePrice} isErrorMessageActive={false} min={1} max={1000} placeholder="Service Price" onValueChange={(text: number) => setOfferedRequest({ ...offeredRequest, servicePrice: text ? text : undefined })} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6 w-100'>Duration (mins) {<span style={{ color: "red" }}>*</span>}</label>
                    <span className="text-muted me-3">Approximate duration for completing this service</span>
                    <NumberInput defaultValue={offeredRequest.duration} min={1} placeholder="Duration" onValueChange={(text: number) => setOfferedRequest({ ...offeredRequest, duration: text })} />

                    <strong className='w-100 text-danger'></strong>

                    <div className='col mt-8 '>
                        <div className='d-flex col gap-3 justify-content-end'>
                            <button type='button' className='btn btn-success ' disabled={loading} onClick={handleSubmit}>Save Service {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default StaffOfferedForm
