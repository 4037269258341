import React, { Dispatch } from 'react';
import { useQuery } from 'react-query';
import ApiCalls from '../../../../network/ApiCalls';
import PropertyTable from './PropertyTable';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import { useDispatch, useSelector } from 'react-redux';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { useParams } from 'react-router-dom';
import { decryptText } from '../../../../utils/util';

type Props = {
}

const AllowedProperties: React.FC<Props> = () => {

    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    
    const { data: propertyData, isLoading: propertyLoading, error: propertyError, refetch } = useQuery(['Allowed Properties', staffDetail.staff?.userId], () => ApiCalls.getAllowedProperties(staffDetail.staff?.userId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: staffDetail.staff?.userId ? true : false });

    const clickAdd = () => {
        dispatch(staffActionCreator.openStaffPropertyDialog(refetch, "allowed"));
    }

    return (
        <div className='p-6'>
            <h4>Assigned Properties</h4>
            {propertyLoading ? <SkeletonTableLoading /> : <PropertyTable data={propertyData?.data?.data} refetch={refetch} propertyType="allowed" />}
            <div className='w-100 d-flex justify-content-end'>
                <button className='btn btn-success btn-sm mt-3' onClick={clickAdd}>Assign Properties</button>
            </div>
        </div>
    )
}

export default React.memo(AllowedProperties);