import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { IAddress } from '../../../interfaces/Address';
import { useDispatch, useSelector } from 'react-redux';
import { AddressAction } from '../../../redux/actionTypes/addressTypes';
import * as addressActionCreators from '../../../redux/actionCreators/addressActionCreators';
import UpdateButton from '../../../umut-components/Buttons/UpdateButton';
import DeleteButton from '../../../umut-components/Buttons/DeleteButton';
import { onExporting } from '../../../utils/util';
import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators';
import ApiCalls from '../../../network/ApiCalls';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import { useDialog } from '../../../contexts/DialogContext';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';

type Props = {
    data: IAddress[]
}

const AddressTable: React.FC<Props> = ({ data }) => {
    const history = useHistory();
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const dispatch = useDispatch<Dispatch<AddressAction>>();
    const deletDialogDispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showFailureDialog } = useDialog();
    const tableRef = useRef<DataGrid>(null)
    const displayEditButton = (cellData: any) => {
        return (
            <UpdateButton iconWidth="20" iconHeight="20" updateFn={() => { dispatch(addressActionCreators.openAddressDialog(cellData?.data)) }} />
        )
    }
    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                deletDialogDispatch(deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                        try {
                            await ApiCalls.deleteAddress(cellData?.data?.userAddressId);
                            dispatch(addressActionCreators.getUserAddresses(user?.user?.id));
                            deletDialogDispatch(deleteDialogActionCreators.closeDeleteDialog());
                        }
                        catch (err: any) {
                            showFailureDialog(err?.description?.data?.message);
                        }
                    },
                    `Are you sure you want to delete ${cellData?.data?.address}?`,
                    'Delete Address?'
                ))
            }} />
        )
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Addresses" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="addressTable"
                keyExpr="userAddressId"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                className='mt-6'
                ref={tableRef}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Addresses")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Addresses: {0}" column='address' summaryType='count' />
                </Summary>

                <Column dataField="address" minWidth={450} cssClass='cls' />
                <Column dataField="stateName" caption="State" minWidth={150} cssClass='cls' alignment="center" />
                <Column dataField="cityName" caption="City" minWidth={150} cssClass='cls' alignment="center" />
                <Column dataField="zipCode" minWidth={150} cssClass='cls' alignment="center" />
                <Column dataField="userAddressTypeName" caption="Type" minWidth={150} cssClass='cls' alignment="center" />
                <Column cellRender={displayEditButton} width={110} cssClass='cls' />
                <Column cellRender={displayDeleteButton} width={110} cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default React.memo(AddressTable);