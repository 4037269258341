export const Endpoints = {
  Users: {
    Login: 'users/login/host-user',
    Register: 'host-users/host-initial-registration',
    NewRegister: 'host-users/new-host-registration',
    ConfirmAccount: 'host-users/verify-host-user',
    GetUserDetails: 'users/me',
    EditUser: 'users/',
    GetUserDetailsById: 'users/',
    DeleteUserAccount: 'users/',
  },
  Host: {
    GetHostById: 'hosts/',
    GetHostUsers: 'host-users/',
    PostBillingInformation: 'hosts/billing-information',
    StripeAccountLink: 'hosts/account-link',
    GetForceFinish: 'hosts/force-finish-info/',
    AcceptInvite: 'host-users/accept-host-staff-invite',
    ICalPmsInfo: 'hosts/ical-pms-info/',
  },
  Job: {
    TodayJobSummary: 'jobs/host-today-job-summary/',
    WeeklyJobs: 'jobs/weekly-jobs/',
    TodayJobSummaryByProperty: 'jobs/host-property-today-job-summary/',
    WeeklyJobsByProperty: 'jobs/weekly-property-jobs/',
    GetJobs: 'jobs/',
    GetAllJobs: 'jobs/web',
    GetLiveJobs: 'jobs/live',
    GetJobsByStaffId: 'jobs/web?hostId=',
    GetJobsByPropertyId: 'jobs/web?hostPropertyId=',
    GetStaffJobsByHost: 'jobs/staff-by-host',
    GetJobsByUserAndRoleId: 'jobs/web?userId=',
    GetJobsByVendorId: 'jobs/web?vendorId=',
    AddJob: 'jobs',
    GetLateStartJobs: 'jobs?isLateStart=',
    GetLateFinishJobs: 'jobs?isLateFinish=',
    GetJobServices: 'job-services?jobId=',
    GetJobDetails: 'jobs/',
    AddJobService: 'job-services',
    DeleteJobService: 'job-services/',
    jobForceFinish: 'jobs/force-finish/',
    GetJobNotes: 'jobs/notes/',
    AddJobNote: 'job-notes',
    DeleteJobNote: 'job-notes/',
    GetJobTasks: 'job-tasks?jobId=',
    GetJobStaffs: 'job-staff?jobId=',
    AddJobTask: 'job-tasks',
    UpdateJobTask: 'job-tasks/',
    DeleteJobTask: 'job-tasks/',
    GetJobIssues: 'job-issue?jobId=',
    ResolveIssue: 'job-issue/resolve/',
    DeclineIssue: 'job-issue/decline/',
    AddJobIssue: 'job-issue',
    UpdateJobIssue: 'job-issue/',
    DeleteJobIssue: 'job-issue/',
    GetChecklistPhotos: 'jobs/get-checklist-photos?jobId=',
    GetAllIssueByPropertyId: 'job-issue?hostPropertyId=',
    GetJobReview: 'job-review?jobId=',
    AddJobReview: 'job-review',
    SendJobReview: 'job-review/send',
    DeleteReview: 'job-review/',
    CancelJob: 'jobs/',
    UpdateJobNote: 'job-notes/',
    UpdateJobServiceNote: 'job-services/updateNote/',
    UpdateJobTaskNote: 'job-tasks/updateNote/',
    SendVendorScore: 'jobs/vendor-score/',
    GetPropertyFutureJobSummary: 'jobs/property-future-summary/',
  },
  JobForceFinishRequest: {
    GetForce: 'job-force-finish-requests',
    PostApproveForce: 'job-force-finish-requests/accept/',
    PostDeclineForce: 'job-force-finish-requests/reject/',
  },
  Property: {
    PutPropertyPmsReference: 'host-properties/edit-pms-reference/',
    GetProperties: 'host-properties?hostId=',
    GetPropertiesFromGuesty: 'host-properties/get-properties-from-guesty',
    GetPropertiesFromHostify: 'host-properties/get-properties-from-hostaway',
    GetPropertiesFromStayntouch: 'host-properties/get-properties-from-stayntouch',
    GetPropertiesFromMyVr: 'host-properties/get-properties-from-myvr',
    GetPropertiesFromMews: 'host-properties/get-properties-from-mews',
    GetGuestyProperties: 'host-properties/get-guesty-properties',
    GetHostawayProperties: 'host-properties/get-hostaway-properties',
    GetStayntouchProperties: 'host-properties/get-stayntouch-properties',
    GetMyVrProperties: 'host-properties/get-myvr-properties',
    PostHostProperties: 'host-properties/create-many',
    UpdateHostPropertyName: 'host-properties/update-name',
    PostHostProperty: 'host-properties',
    CreateHostProperty: 'host-properties/add-manually',
    CreateNewHostProperty: 'host-properties/add-property-manually',
    UpdateHostProperty: 'host-properties/',
    UpdateHostPropertyPaymentInfo: 'host-properties/update-payment/',
    HostPropertyOtherQuestion: 'host-property-other-question',
    GetHostProperty: 'host-properties/',
    AllowedProperties: 'user-host-property-permission/accepted-properties/',
    BannedProperties: 'excluded-staff/',
    AcceptedProperties: 'user-host-property-permission/accepted-staffs/',
    AcceptedMyProperties: 'user-host-property-permission/accepted-my-staffs/',
    PutPriorityProperty: 'user-host-property-permission',
    UpdatePropertyUserPriorityByUser: 'user-host-property-permission/order-by-user',
    AddPropertyPermission: 'user-host-property-permission/create-many',
    DeletePropertyPermission: 'user-host-property-permission/',
    DeletePropertyPermissionByUser: 'user-host-property-permission/by-user',
    BanUserFromProperty: 'excluded-staff',
    DeleteBanUserFromProperty: 'excluded-staff/',
    HostPropertyAvailableServices: 'host-property-service-offer/available?hostPropertyId=',
    GetHostPropertyServiceOffers: 'host-property-service-offer?hostPropertyId=',
    GetHostPropertyServiceRates: 'host-property-service-rate/property/',
    GetHostPropertyServiceRateList: 'host-property-service-rate/property-rate-list/',
    GetHostPropertyServiceRatesOffersWithVariables: 'host-property-service-rate/offers',
    GetHostPropertyMarketplaceOffers: 'host-property-team/get-offers/',
    EditPropertyStatus: 'host-properties/',
    PutHostProperty: 'host-properties/',
    GetExcludedStaff: 'excluded-staff/',
    DeleteExcludedStaff: 'excluded-staff/',
    GetOffer: (param: string) => `host-properties/${param}/get-offer`,
    SyncCalendar: 'host-properties/sync-calendar/',
    AddPersonalPropertyManager: 'host-properties/add-personal-property-manager/',
    RemovePersonalPropertyManager: 'host-properties/remove-personal-property-manager/',
    UpdatePropertyToMarketplace: 'host-properties/update-to-marketplace',
    GetSubscriptionInfo: 'host-properties/subscription-info/',
    CancelSubscription: 'host-properties/cancel-subscription/',
  },
  Turnovers: {
    GetFutureTurnovers: 'hosts/get-future-turnovers/',
    GetPastTurnovers: 'hosts/get-past-turnovers/',
  },
  Marketplace: {
    GetRequests: 'marketplace/',
    CreateNewRequest: 'marketplace/create/',
    GetDetail: 'marketplace/detail/',
    UpdateRequest: 'marketplace/update/',
    AcceptDeclineOffer: 'marketplace-offer/accept-or-decline/',
    CreateJob: 'jobs/create-marketplace-job',
    CheckPropertyMarketplace: 'marketplace/check-property/',
    GetPropertyOffersByService: 'marketplace/get-property-offers-by-service/',
  },
  Checklist: {
    GetMasterChecklists: 'host-property-checklists',
    GetHostChecklists: 'host-property-checklists/host-checklist?hostId=',
    GetHostMasterChecklists: 'host-property-checklists/host-master-checklists/',
    GetHostPropertyChecklistsByService: 'host-property-checklists/property-by-service/',
    GetHostPropertyChecklists: 'host-property-checklists?hostPropertyId=',
    PostHostPropertyChecklist: 'host-property-checklists',
    EditChecklist: 'host-property-checklists/',
    UpdateMainChecklist: 'host-property-checklists/main-update/',
    GetChecklistItems: 'host-property-checklists/',
    AddChecklistSection: 'host-property-checklists/section',
    GetChecklistSectionTypes: 'checklist-section-types',
    SetSectionOrder: 'host-property-checklists/',
    SetQuestionOrder: 'host-property-checklists/',
    EditSection: 'host-property-checklists/section/',
    EditQuestion: 'host-property-checklists/question/',
    AddQuestion: 'host-property-checklists/section/question',
    EditQuestionPhoto: 'host-property-checklists/samplePhoto/',
    NewQuestionPhoto: 'host-property-checklists/samplePhoto',
    DeleteChecklist: 'host-property-checklists/',
    AssignChecklists: 'host-property-checklists/clone',
    DeleteSamplePhoto: 'host-property-checklists/delete-sample-photo/',
    DeleteSection: 'host-property-checklists/section/',
    DeleteQuestion: 'host-property-checklists/question/',
    PostCloneChecklist: 'host-property-checklists/clone',
    PostCloneSection: 'host-property-checklists/clone-section',
    PostCloneSectionQuestion: 'host-property-checklists/clone-section-question',
    UpdateFutureJobsChecklistsByProperty: 'host-property-checklists/update-jobs-checklists',
  },
  ProTeam: {
    GetProTeamList: 'host-property-team?hostId=',
    GetProTeamDetails: 'host-property-team/detail',
    GetProTeamUserProperties: 'host-property-team/user-properties',
  },
  Staff: {
    GetStaffs: 'host-users?hostId=',
    GetHostStaffs: 'host-users/all-staffs/',
    GetHostStaffByProperty: 'host-users/property-staffs/',
    CreateStaff: 'host-users/host-staff-create',
    GetStaffDetails: 'host-users/',
    GetVendorStaffsByPropertyId: 'vendor-users/by-property/',
    GetStaffByPropertyId: (param: number) => `host-users?hostId=${param}&hostPropertyId=`,
    AddStaffOfferedService: 'staff-offered-service',
    UpdateStaffOfferedService: 'staff-offered-service/',
    DeleteStaffOfferedservice: 'staff-offered-service/',
    AcceptInvite: 'host-users/accept-host-staff-invite',
    Forgotpassword: 'users/forgot-password',
    ResetPassword: 'users/reset-password',
    UpdateStaffStatus: 'host-users/editStatus',
    StaffStatusTypes: 'account-status-types',
    StaffChangePassword: 'users/change-password/',
    GetStaffByVendorId: 'vendor-users?vendorId=',
    GetMarketplaceStaffsByPropertyId: 'host-users/marketplace/',
    GetHostUserGroups: 'host-user-group',
    AddUserToHostUserGroup: 'host-user-group/add-user',
    CreateHostUserGroup: 'host-user-group/create',
    UpdateHostUserGroup: 'host-user-group/update',
    DeleteHostUserGroup: 'host-user-group/delete',
    BlockStaff: 'host-users/block-staff',
    GetStaffServicesWithRates: 'host-users/staff-services/',
    UpdateStaffServiceRate: 'host-users/update-staff-service-rate',
  },
  Manager: {
    GetManagerProperties: 'user-host-property-permission/manager-properties/',
    GetAcceptedProperties: 'user-host-property-permission/accepted-properties/',
    PostManagerProperties: 'user-host-property-permission/create-many',
    DeleteManagerProperties: 'user-host-property-permission?',
    GetManagerCities: 'manager-city-permission',
    PostManagerCities: 'manager-city-permission',
    DeleteManagerCities: 'manager-city-permission/',
  },
  Account: {
    GetAccountStatusType: 'account-status-types',
    StaffStatusTypes: 'account-status-types',
  },
  PMS: {
    GetPMSTypes: 'pms-types',
    GetPMSKeys: 'host-pms?hostId=',
  },
  Countries: {
    GetCountries: 'countries',
  },
  CleaningProviders: {
    GetCleaningProviders: 'cleaning-providers',
  },
  AccessTypes: {
    GetAccessTypes: 'access-types',
  },
  Roles: {
    GetStaffRoles: 'roles/staff-roles',
  },
  Service: {
    GetServiceTypes: 'service-types',
    GetServiceGroupsWithServices: 'service-groups/with-service-types',
  },
  ChecklistAnswerTypes: {
    GetChecklistAnswerTypes: 'checklist-answer-types',
  },
  Languages: {
    GetLanguages: 'languages',
  },
  Address: {
    GetAddressByUserId: 'users/addresses?userId=',
    CreateAddress: 'users/',
    UpdateAddress: 'users/',
    DeleteAddress: 'users/address/',
    GetAddressTypes: 'user-address-type',
  },
  OfferedServices: {
    GetOfferedServices: 'staff-offered-service?userId=',
    GetOfferedServicesByHostId: 'staff-offered-service/available/',
    GetOfferedStaffs: 'staff-offered-service?serviceTypeId=',
  },
  OffDays: {
    GetStaffOffDays: 'staff-off-days?userId=',
    PostStaffOffDays: 'staff-off-days',
    PostStaffOffTime: 'staff-off-time/',
    DeleteStaffOffTime: 'staff-off-time',
    GetStaffOffTime: 'staff-off-time?userId=',
    DeleteStaffOffDays: 'staff-off-days',
  },
  JobRatingTypes: {
    GetJobRatingTypes: 'job-rating-types',
  },
  Billing: {
    GetAllBillings: 'host-invoices',
    GetBillingsByHostId: 'host-invoices?hostId=',
    GetBillingsByJobId: 'host-invoices?jobId=',
    GetBillingDetail: 'host-invoices/',
    GetBillingInformation: 'hosts/billing-information',
    PostInvoiceCharge: 'host-invoices/',
    PostCustomerSource: 'hosts/customer-source',
    PutDefaultCustomerSource: 'hosts/set-default-source',
    DeleteCustomerSource: 'hosts/',
    GetPaymentSource: 'hosts/list-source',
    VerifyBilling: 'hosts/stripe-account-status',
    DisableStripe: 'hosts/stripe/disable',
    CheckHostHasCard: 'hosts/control-host-payment-methods',
  },
  Payment: {
    GetFailedPayments: 'hosts/failed-payments',
    MakePayment: 'hosts/stripe/make-payment/',
  },
  JobCancelTypes: {
    GetJobCancelTypes: 'job-cancel-types',
  },
  Search: {
    GetSearch: 'search/search?keyword=',
  },
  ICalValidation: {
    ValidateICall: 'host-properties/validate-ical',
  },
  OnBoarding: {
    GetOnBoarding: 'hosts/on-boarding-info/',
  },
  Notification: {
    GetNotifications: 'notifications',
    ReadNotification: 'notifications',
  },
  Map: {
    GetStates: 'states',
    GetCities: 'cities',
  },
  Feeds: {
    GetHostFeedSettings: 'host-property-feed-setting/',
    UpdateHostFeedSettings: 'host-property-feed-setting/update',
    GetHostFeeds: 'host-property-feed/get-by-host/',
    GetHostPropertyFeeds: 'host-property-feed/get-by-property/',
  },
}
